import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import queryString from "query-string";
import TermCondition from "../partials/TermCondition";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import { availableSolutions as solutions } from "./../../utils/constants";

const termC = ["RxPodcast"];
const rxpodcastPrice = {
  Basic: 89.99,
  Plus: 149.99,
  Pro: 199.99,
};

class ChoosePlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPlan: "",
      selectedSolution: [],
      finalPrice: 0,
      userInfo: {},
      inTrial: [],
      termArr: [],
      rxpodcastPlan: undefined,
      currentPlan: undefined,
      custom_url: false,
    };
  }

  componentDidMount() {
    this.getUserInfo();
    this.markLogin();
    if (queryString.parse(window.location.search).subPlan) {
      this.setState({
        rxpodcastPlan: queryString.parse(window.location.search).subPlan,
      });
    }
  }

  markLogin() {
    const newUser = {
      id: this.props.auth.user.id,
      login_flow: false,
    };
    axios
      .post("/api/user-update", newUser)
      .then((res) => {})
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  getUserInfo = () => {
    let filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "_id", order: "desc" },
    };
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        let { inTrial } = this.state;
        if (res.data.records[0].tabulaRx) {
          inTrial.push("TabulaRx");
        }
        if (res.data.records[0].rxminder) {
          inTrial.push("RxMinder");
        }
        if (res.data.records[0].doorstepRx) {
          inTrial.push("DoorstepRx");
        }
        this.setState(
          {
            userInfo: res.data.records[0],
            inTrial,
          },
          () => this.calculatePrice()
        );
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  calculatePrice = () => {
    if (queryString.parse(window.location.search).solution) {
      let { selectedSolution, rxpodcastPlan } = this.state;
      selectedSolution = [queryString.parse(window.location.search).solution];
      let finalPrice = 0;
      solutions.map((eachSol) => {
        if (selectedSolution.includes(eachSol.name)) {
          let solPrice =
            eachSol.name === "RxPodcast"
              ? rxpodcastPlan === "" || rxpodcastPlan === undefined
                ? 89.99
                : rxpodcastPrice[rxpodcastPlan]
              : eachSol.price;
          finalPrice = finalPrice + parseFloat(solPrice);
        }
      });
      if (
        selectedSolution.includes("RxPodcast") &&
        rxpodcastPlan === undefined
      ) {
        $("#rxpodcast-plan-modal").modal("show");
      }
      finalPrice = finalPrice.toFixed(2);
      this.setState({
        selectedSolution,
        finalPrice,
      });
    }
  };

  choosePlanRxPodcast = (plan) => {
    confirmAlert({
      title: "Awesome!",
      message: `You have selected ${plan} Plan`,
      buttons: [
        {
          label: "Back",
          onClick: () => $("#rxpodcast-plan-modal").modal("hide"),
        },
        {
          label: "Continue",
          onClick: () =>
            this.setState({ rxpodcastPlan: plan }, () =>
              this.updateCheckboxAfter(this.state.selectedSolution)
            ),
        },
      ],
      overlayClassName: "confirm-plan",
    });
  };

  updateCheckbox = (e) => {
    let { selectedSolution } = this.state;
    if (e.target.checked) {
      selectedSolution.push(e.target.id);
    } else {
      selectedSolution = selectedSolution.filter(
        (each) => each !== e.target.id
      );
    }
    if (e.target.id === "RxPodcast") {
      if (e.target.checked) {
        this.setState({ selectedSolution }, () =>
          $("#rxpodcast-plan-modal").modal("show")
        );
      } else {
        this.setState({ rxpodcastPlan: "" });
      }
    }
    if (this.state.currentPlan) {
      this.setState({ currentPlan: undefined }, () => {
        this.updateCheckboxAfter(selectedSolution);
      });
    } else {
      this.updateCheckboxAfter(selectedSolution);
    }
  };

  updateCheckboxAfter = (selectedSolution) => {
    $("#rxpodcast-plan-modal").modal("hide");
    let { rxpodcastPlan } = this.state;
    let finalPrice = 0;
    let free = [];
    let paid = [];
    solutions.map((each) => {
      if (each.free) {
        free.push(each.name);
      } else {
        paid.push(each.name);
      }
    });
    if (this.state.currentPlan) {
    } else {
      solutions.map((eachSol) => {
        if (
          selectedSolution.includes(eachSol.name) &&
          (this.state.inTrial.includes(eachSol.name) || !eachSol.free)
        ) {
          let solPrice =
            eachSol.name === "RxPodcast"
              ? rxpodcastPlan === "" || rxpodcastPlan === undefined
                ? 89.99
                : rxpodcastPrice[rxpodcastPlan]
              : eachSol.price;
          finalPrice = finalPrice + parseFloat(solPrice);
        }
      });
    }

    finalPrice = finalPrice.toFixed(2);
    this.setState({ selectedSolution, finalPrice });
  };

  checkTerms = () => {
    let { selectedSolution } = this.state;
    const termArr = selectedSolution.filter((value) => termC.includes(value));
    if (selectedSolution.length || this.state.currentPlan) {
      // if (termArr.length || this.state.currentPlan === "premium") {
      //   this.setState({ termArr }, () => $("#term-condition").modal("show"));
      // } else {
      this.navigateTo();
      // }
    } else {
      alert("Please choose atleast one solution.");
    }
  };

  navigateTo = () => {
    let { selectedSolution, finalPrice } = this.state;
    let growth = ["BonaRx", "AlereRx", "RxReach", "XPedicareRx"];
    let premium = ["RxCreator", "RxPodcast", "RxMinder"];
    let others = [
      "Marketing",
      "Marketing+",
      "Compliance",
      "CompareRx",
      "Audit",
      "MentoRx",
      "DoorstepRx",
    ];
    if (finalPrice > 0) {
      if (this.state.currentPlan) {
        window.location.href = `/card-info?plan=${
          this.state.currentPlan === "growth"
            ? "Growth"
            : this.state.currentPlan === "premium"
            ? "Premium"
            : "Essential"
        }`;
      } else if (
        selectedSolution.includes("TabulaRx") &&
        growth.filter((n) => selectedSolution.indexOf(n) !== -1).length > 0 &&
        premium.filter((n) => selectedSolution.indexOf(n) !== -1).length ===
          0 &&
        others.filter((n) => selectedSolution.indexOf(n) !== -1).length === 0
      ) {
        confirmAlert({
          title: "Suggestion!",
          message: `You have selected ${selectedSolution.join(
            ","
          )}. Select Growth Plan and Save!`,
          buttons: [
            {
              label: "No Thanks",
              onClick: () =>
                (window.location.href = `/card-info?solution=${selectedSolution.join(
                  "|"
                )}&rxpodcastPlan=${this.state.rxpodcastPlan}`),
            },
            {
              label: "Choose Plan",
              onClick: () => {},
            },
          ],
          overlayClassName: "confirm-plan",
        });
      } else if (
        selectedSolution.includes("RxMinder") &&
        premium.filter((n) => selectedSolution.indexOf(n) !== -1).length > 0 &&
        others.filter((n) => selectedSolution.indexOf(n) !== -1).length === 0
      ) {
        confirmAlert({
          title: "Suggestion!",
          message: `You have selected ${selectedSolution.join(
            ", "
          )}. Select Premium Plan and Save!`,
          buttons: [
            {
              label: "No Thanks",
              onClick: () =>
                (window.location.href = `/card-info?solution=${selectedSolution.join(
                  "|"
                )}&rxpodcastPlan=${this.state.rxpodcastPlan}`),
            },
            {
              label: "Choose Plan",
              onClick: () => {},
            },
          ],
          overlayClassName: "confirm-plan",
        });
      } else {
        window.location.href = `/card-info?solution=${selectedSolution.join(
          "|"
        )}&rxpodcastPlan=${this.state.rxpodcastPlan}`;
      }
    } else {
      window.location.href = "/landing";
    }
  };

  confirmPlan = (plan) => {
    this.setState(
      {
        selectedSolution: [],
        currentPlan: this.state.currentPlan === plan ? undefined : plan,
      },
      () => {
        this.setState({
          finalPrice:
            this.state.currentPlan === "premium"
              ? "219.99"
              : this.state.currentPlan === "growth"
              ? "89.99"
              : this.state.currentPlan === "essential"
              ? "179.99"
              : "",
        });
      }
    );
  };

  onCustomUrlChange = () => {
    if (this.state.currentPlan === "essential") {
      this.setState({
        currentPlan: undefined,
        finalPrice: "",
      });
    } else {
      this.setState({
        custom_url: !this.state.custom_url,
      });
    }
  };

  render() {
    let { userInfo, custom_url, finalPrice } = this.state;
    if (custom_url && this.state.currentPlan !== "essential") {
      finalPrice = parseFloat(finalPrice) + 39.99;
    }
    return (
      <>
        <header data-bs-theme="dark" style={{ paddingLeft: "0" }}>
          <div className="navbar navbar-dark bg-dark shadow-sm">
            <div className="container">
              <a
                href="/landing"
                className="navbar-brand d-flex align-items-center"
              >
                <img
                  src={
                    "https://tabula.psgarx.com/static/media/RxConnexion.80ee8ffc.png"
                  }
                  alt="Logo"
                  width={300}
                />
              </a>
              <button
                type="button"
                className="btn btn-dark me-2 right"
                onClick={() => this.props.logoutUser()}
              >
                Logout ({this.props.auth.user.name})
              </button>
            </div>
          </div>
        </header>
        <main
          style={{ paddingLeft: "0", minHeight: "calc(-195px + 100vh)" }}
          className="choose-plan-initial container"
        >
          <section className="text-center container">
            <div className="row py-lg-3">
              <button
                type="button"
                className="btn btn-outline-primary float-left"
                onClick={() => window.history.back()}
              >
                Back
              </button>
              <div className="col-lg-6 col-md-8 mx-auto">
                <h1 className="fw-light">
                  <b>Choose a Plan and Save</b>
                </h1>
              </div>
            </div>
          </section>
          <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
            <div
              className="col"
              onClick={() => this.confirmPlan("growth")}
              style={{ cursor: "pointer" }}
            >
              <div
                style={{
                  background: "#C0C0C0",
                  margin: "20px 40px",
                  borderRadius: "5px",
                  padding: "5px",
                  minHeight: "111px",
                }}
              >
                <div
                  style={{ width: "70%", float: "left", position: "relative" }}
                >
                  <input
                    type="checkbox"
                    onChange={(e) => this.confirmPlan("growth")}
                    name={"growth"}
                    id={"growth"}
                    disabled={userInfo["plan"] === "Growth"}
                    checked={this.state.currentPlan === "growth"}
                    style={{ position: "absolute", left: 0, cursor: "pointer" }}
                  />
                  <h3
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      marginTop: "18px",
                    }}
                  >
                    Growth
                  </h3>
                  <span style={{ color: "#fff", fontWeight: "bold" }}>
                    $89.99/mo
                  </span>
                </div>
                <div
                  style={{
                    width: "30%",
                    float: "left",
                    background: "#0071BC",
                    height: "100px",
                  }}
                ></div>
              </div>
            </div>
            <div
              className="col"
              onClick={() => this.setState({ selectedPlan: "essential" })}
              style={{ cursor: "pointer" }}
            >
              <div
                style={{
                  background: "rgb(119, 0, 169)",
                  margin: "20px 40px",
                  borderRadius: "5px",
                  padding: "5px",
                  minHeight: "111px",
                }}
              >
                <div
                  style={{ width: "70%", float: "left", position: "relative" }}
                >
                  <input
                    type="checkbox"
                    onChange={(e) => this.confirmPlan("essential")}
                    name={"essential"}
                    id={"essential"}
                    disabled={userInfo["plan"] === "Essential"}
                    checked={this.state.currentPlan === "essential"}
                    style={{ position: "absolute", left: 0, cursor: "pointer" }}
                  />
                  <h3
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      marginTop: "18px",
                    }}
                  >
                    Essential
                  </h3>
                  <span style={{ color: "#fff", fontWeight: "bold" }}>
                    $179.99/mo
                  </span>
                </div>
                <div
                  style={{
                    width: "30%",
                    float: "left",
                    background: "#0071BC",
                    height: "100px",
                  }}
                ></div>
              </div>
            </div>
            <div
              className="col"
              onClick={() => this.confirmPlan("premium")}
              style={{ cursor: "pointer" }}
            >
              <div
                style={{
                  background: "#FFD700",
                  margin: "20px 40px",
                  borderRadius: "5px",
                  padding: "5px",
                  minHeight: "111px",
                }}
              >
                <div
                  style={{ width: "70%", float: "left", position: "relative" }}
                >
                  <input
                    type="checkbox"
                    onChange={(e) => this.confirmPlan("premium")}
                    name={"premium"}
                    id={"premium"}
                    disabled={userInfo["plan"] === "Premium"}
                    checked={this.state.currentPlan === "premium"}
                    style={{ position: "absolute", left: 0, cursor: "pointer" }}
                  />
                  <h3
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      marginTop: "18px",
                    }}
                  >
                    Premium
                  </h3>
                  <span style={{ color: "#fff", fontWeight: "bold" }}>
                    $219.99/mo
                  </span>
                </div>
                <div
                  style={{
                    width: "30%",
                    float: "left",
                    background: "#0071BC",
                    height: "100px",
                  }}
                ></div>
              </div>
            </div>
          </div>
          <hr />
          <section className="text-center container">
            <div className="row py-lg-3">
              <div className="col-lg-6 col-md-8 mx-auto">
                <h1 className="fw-light">
                  <b>OR Select Solutions</b>
                </h1>
              </div>
            </div>
          </section>
          <div className="row row-cols-1 row-cols-md-3 mb-3 text-left">
            {solutions.map((each, i) => {
              return (
                <div
                  className={`col-4 solutions ${each.class} ${
                    !(
                      userInfo[each.subId] === undefined ||
                      userInfo[each.subId] === ""
                    ) && !this.state.selectedSolution.includes(each.name)
                      ? "grayed"
                      : ""
                  } `}
                  key={i}
                  data-toggle="tooltip"
                  title={each.tooltip}
                >
                  <div className="solutionWrapper">
                    {each.name === "xpedicare24" ? (
                      <>
                        <div className="innerSol">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              queryString.parse(window.location.search)
                                .solution &&
                              queryString.parse(window.location.search)
                                .solution === each.name
                                ? "return false"
                                : this.updateCheckbox(e)
                            }
                            name={each.name}
                            id={each.name}
                            disabled={
                              !(
                                userInfo[each.subId] === undefined ||
                                userInfo[each.subId] === ""
                              ) || each.disabled
                            }
                            checked={
                              this.state.selectedSolution.includes(each.name) ||
                              (this.state.currentPlan === "premium" &&
                                ["growth", "premium"].includes(each.class)) ||
                              this.state.currentPlan === each.class ||
                              (this.state.currentPlan === "essential" &&
                                each.essential)
                            }
                          />
                          {each.name === "xpedicare24"
                            ? "XpedicareRx24"
                            : each.name}{" "}
                          {each.hidePrice ? "" : `- $${each.price}/mo`}
                        </div>
                      </>
                    ) : (
                      <div className="innerSol">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            queryString.parse(window.location.search)
                              .solution &&
                            queryString.parse(window.location.search)
                              .solution === each.name
                              ? "return false"
                              : this.updateCheckbox(e)
                          }
                          name={each.name}
                          id={each.name}
                          disabled={
                            !(
                              userInfo[each.subId] === undefined ||
                              userInfo[each.subId] === ""
                            ) || each.disabled
                          }
                          checked={
                            this.state.selectedSolution.includes(each.name) ||
                            (this.state.currentPlan === "premium" &&
                              ["growth", "premium"].includes(each.class)) ||
                            this.state.currentPlan === each.class ||
                            (this.state.currentPlan === "essential" &&
                              each.essential)
                          }
                        />
                        {each.name === "xpedicare24"
                          ? "XpedicareRx24"
                          : each.name}{" "}
                        {each.hidePrice ? "" : `- $${each.price}/mo`}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="clear" />
          <div className="row">
            <div className={`col-12`}>
              <button
                type="button"
                className="btn btn-success btn-lg float-right"
                onClick={() => this.checkTerms()}
              >
                Continue to Checkout
              </button>
              &nbsp;&nbsp;
              {finalPrice > 0 ? (
                <button
                  type="button"
                  className="btn btn-lg btn-danger float-right"
                >
                  <b>Total - ${parseFloat(finalPrice).toFixed(2)}</b>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </main>

        <TermCondition
          userInfo={this.state.userInfo}
          solutions={this.state.termArr}
          navigateTo={this.navigateTo}
        />
        <div className="modal fade" id="rxpodcast-plan-modal" data-reset="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header" style={{ display: "block" }}>
                <div style={{ float: "left" }}>
                  <img
                    height="60"
                    alt="logo"
                    src={
                      "https://d3l9g5ay50k79j.cloudfront.net/logo/RxPodcast.png"
                    }
                  />
                </div>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h3 className="modal-title center" style={{ clear: "both" }}>
                  Select Plan
                </h3>
              </div>
              <div className="modal-body center">
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="center">
                        <img
                          src={
                            "https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/RxPodcast_Basic.png"
                          }
                          alt="Plan"
                          className="pointer"
                          onClick={() => this.choosePlanRxPodcast("Basic")}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="center">
                        <img
                          src={
                            "https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/RxPodcast_Plus.png"
                          }
                          alt="Plan"
                          className="pointer"
                          onClick={() => this.choosePlanRxPodcast("Plus")}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="center">
                        <img
                          src={
                            "https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/RxPodcast_Pro.png"
                          }
                          alt="Plan"
                          className="pointer"
                          onClick={() => this.choosePlanRxPodcast("Pro")}
                        />
                      </div>
                    </div>
                  </div>
                </>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => $("#rxpodcast-plan-modal").modal("hide")}
                  type="button"
                  className="btn btn-success btn-lg"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(ChoosePlan);
