import React, { Component } from "react";
import askAnything from "../../utils/askAnything.png";
class ChatBot extends Component {
  constructor() {
    super();

  }



  render() {
    return (
      <div
        className="nam login"
        style={{
          backgroundImage: `url(https://rxreachanalytis.s3.amazonaws.com/logo/RxConnexion_Login_Artwork.png)`,
        }}
        height={100}
      >
        <div className="container" style={{ minHeight: "calc(100vh - 120px)" }}>
          <div className="row">
            <div className="col-md-6 mx-auto mt-3 mb-1 card shadow-lg" style={{ width: "600px" }}>
              <img src={askAnything} style={{
                width: "80%",
                margin: "10px auto"
              }} />
              {/* <iframe height="100%" width={"100%"} url="https://www-alererx-com.filesusr.com/html/882b12_3e284e46b1e3583379cb65d8715be9f4.html" allow="fullscreen" /> */}
              <iframe src="/rxchatbotIframe" height={"600px"} width={"600px"} style={{ marginLeft: "13px", marginBottom: "10px" }}>
              </iframe>

            </div>
            <div className="col-md-7 mx-auto  mb-1" style={{}}>
              <center> <>Need to speak with our experts?  Call  <a href="tel:+18884428348">888-442-8348</a></></center>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default ChatBot
