import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PulseLoader from "react-spinners/PulseLoader";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import AddFaxCredits from "../partials/AddFaxCredits";
import moment from "moment";
import { logoutUser } from "../../actions/authActions";

class FaxCredits extends React.Component {
  constructor() {
    super();

    this.columns = [
      {
        key: "points",
        text: "Credits",
        className: "points",
        align: "left",
        sortable: true,
        color: true,
      },
      {
        key: "type",
        text: "Type",
        className: "type",
        align: "left",
        sortable: true,
      },
      {
        key: "date",
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
      },
      {
        key: "comment",
        text: "Comment",
        className: "comment",
        align: "left",
        sortable: true,
      },
    ];

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100],
      filename: "Points Ledger",
      no_data_text: "No Details found!",
      button: {
        excel: false,
        print: false,
        csv: false,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      cardNumber: "",
      expiry: "",
      cvv: "",
      _id: "",
      current_points: 0,
      errors: {},
      records: [],
      totalCount: 0,
      cardError: true,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "_id", order: "desc" },
      },
    };
  }

  componentDidMount() {
    this.getData();
    this.getCurrentPoints();
    var showPopup = window.location.search.split("show-popup=");
    if (showPopup.length > 1) {
      $("button.btn.btn-primary.float-right.mt-3.mr-2").trigger("click");
    }
  }

  getCurrentPoints = () => {
    let { filterData } = this.state;
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        this.setState({
          current_points: res.data.records[0].fax_points,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  getData() {
    this.setState({ loading: true });
    let filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "_id", order: "desc" },
    };
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post("/faxApi/fax-credits", filterData)
      .then((res) => {
        let result = [];
        for (let i = 0; i < res.data.records.length; i++) {
          let each = res.data.records[i];
          each.type = each.type === "1" ? "Credit" : "Used";
          each.date = each.date
            ? moment(each.date).add(5, "hours").format("L")
            : "";
          result.push(each);
        }
        this.setState({
          loading: false,
          records: result,
          totalCount: res.data.count,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  sendPoints = () => {
    this.getData();
    this.getCurrentPoints();
  };

  render() {
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/choose-plan?solution=TabulaRx";
    }
    const { errors } = this.state;
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h1 className="mt-2 text-primary">Fax Credits Usage</h1>
            <p className="help-text font-weight-bold h6">
              Available Credits: {this.state.current_points}{" "}
            </p>
            <button
              className="btn btn-success mt-3 mr-2"
              onClick={() => window.history.go(-1)}
            >
              {"<"} Go to Prescriber List
            </button>
            <button
              className="btn btn-primary float-right mt-3 mr-2"
              data-toggle="modal"
              data-target="#add-credits"
            >
              <FontAwesomeIcon icon={faPlus} />
              &nbsp;Get More Credits
            </button>
            <AddFaxCredits sendPoints={this.sendPoints} />
            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  pharmacy: state.pharmacy,
  groups: state.groups,
});

export default connect(mapStateToProps, { logoutUser })(FaxCredits);
