import React, { Component, Fragment } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { faPlus, faBackward } from "@fortawesome/free-solid-svg-icons";
import { Collapse } from 'react-collapse';
import CouponUpdateModal from "../partials/CouponUpdateModal";
import CouponAddModal from "../partials/CouponAddModal";

const json = {
    "Abilify": [
        "Abilify",
        "Abilify Maintena",
        "Abilify Mycite"
    ],
    "Abiraterone Acetate": [
        "Abiraterone Acetate"
    ],
    "Abraxane": [
        "Abraxane"
    ],
    "Absorbine": [
        "Absorbine Jr. Back Patch",
        "Absorbine Jr. Plus",
        "Absorbine Jr. Ultra Strength Pain Patch"
    ],
    "Absorica": [
        "Absorica",
        "Absorica LD"
    ],
    "Accrufer": [
        "Accrufer"
    ],
    "Accu-Chek": [
        "Accu-Check SmartView Test Strips",
        "Accu-Chek Aviva Plus Test Strip",
        "Accu-Chek Guide Me FREE Meter",
        "Accu-Chek Guide Me Strips",
        "Accu-Chek Guide Me Test Strips"
    ],
    "Accuretic": [
        "Accuretic"
    ],
    "Accutane": [
        "Accutane"
    ],
    "ACE Brand": [
        "ACE Brand Ankle and Foot Products",
        "ACE Brand Back Products",
        "ACE Brand Braces or Supports",
        "ACE Brand Elastic Bandages",
        "ACE Brand Elbow and Arm Products",
        "ACE Brand Knee and Calf Products",
        "ACE Brand Reusable Cold Compress",
        "ACE Brand Tape Wrap and Gear Products",
        "ACE Brand Wrist Hand and Thumb Products"
    ],
    "Acidil": [
        "Acidil"
    ],
    "AcipHex": [
        "AcipHex"
    ],
    "ACT": [
        "ACT Anticavity Products",
        "ACT Braces Care",
        "ACT Dry Mouth Lozenges",
        "ACT Dry Mouth Moisturizing Gum",
        "ACT Dry Mouth Mouthwash",
        "ACT Restoring",
        "ACT Total Care",
        "ACT Total Care Sensitive"
    ],
    "Actemra": [
        "Actemra"
    ],
    "Acthar Gel": [
        "Acthar Gel"
    ],
    "Actimmune": [
        "Actimmune"
    ],
    "Acuvue": [
        "Acuvue Brand Contact Lenses"
    ],
    "Adakveo": [
        "Adakveo"
    ],
    "Adcirca": [
        "Adcirca"
    ],
    "Addyi": [
        "Addyi"
    ],
    "Adempas": [
        "Adempas"
    ],
    "Adhansia XR": [
        "Adhansia XR"
    ],
    "Adipex-P": [
        "Adipex-P"
    ],
    "Adlyxin": [
        "Adlyxin"
    ],
    "Admelog": [
        "Admelog"
    ],
    "Advate": [
        "Advate"
    ],
    "Advil": [
        "Advil",
        "Advil Allergy and Congestion Relief",
        "Advil Allergy Sinus",
        "Advil Childrens Suspension Products",
        "Advil Cold and Sinus Caplets",
        "Advil Cold and Sinus LiquiGels",
        "Advil Dual Action",
        "Advil Infants Products",
        "Advil Junior Strength Products",
        "Advil Liqui-Gels products",
        "Advil Migraine",
        "Advil Multi Symptom Cold and Flu",
        "Advil PM Caplets",
        "Advil PM Liqui-Gels",
        "Advil Sinus Congestion and Pain"
    ],
    "Adynovate": [
        "Adynovate"
    ],
    "Adzenys XR-ODT": [
        "Adzenys XR-ODT"
    ],
    "Afinitor": [
        "Afinitor",
        "Afinitor Disperz"
    ],
    "Afrezza": [
        "Afrezza"
    ],
    "Afrin": [
        "Afrin Allergy Sinus",
        "Afrin No Drip Allergy Sinus",
        "Afrin No Drip Allergy Sinus Night",
        "Afrin No Drip Extra Moisturizing",
        "Afrin No Drip Night",
        "Afrin No Drip Original",
        "Afrin No Drip Severe Congestion",
        "Afrin Original",
        "Afrin Original Pump Mist",
        "Afrin Severe Congestion"
    ],
    "Afstyla": [
        "Afstyla"
    ],
    "Aimovig": [
        "Aimovig"
    ],
    "Air Optix": [
        "Air Optix Aqua",
        "Air Optix Aqua Multifocal",
        "Air Optix Color Contact Lenses",
        "Air Optix for Astigmatism",
        "Air Optix Night-Day Aqua",
        "Air Optix plus Hydraglyde",
        "Air Optix plus Hyrdraglyde Multifocal"
    ],
    "AirDuo": [
        "AirDuo Digihaler",
        "AirDuo RespiClick"
    ],
    "Airsupra Inhalation Aerosol": [
        "Airsupra Inhalation Aerosol"
    ],
    "Ajovy": [
        "Ajovy"
    ],
    "Aklief": [
        "Aklief"
    ],
    "Akynzeo": [
        "Akynzeo"
    ],
    "Alavert": [
        "Alavert Allergy",
        "Alavert D-12"
    ],
    "Alaway": [
        "Alaway Childrens Eye Itch Relief Drops",
        "Alaway Eye Itch Relief Drops",
        "Alaway Preservative Free Eye Drops"
    ],
    "Aldactone": [
        "Aldactone"
    ],
    "Aldurazyme": [
        "Aldurazyme"
    ],
    "Alecensa": [
        "Alecensa"
    ],
    "Aleve": [
        "Aleve",
        "Aleve Arthritis Cap",
        "Aleve Back and Muscle Pain",
        "Aleve PM",
        "Aleve-D Sinus and Cold",
        "AleveX Lotion",
        "AleveX Rollerball",
        "AleveX Spray"
    ],
    "Alimta": [
        "Alimta"
    ],
    "Alinia": [
        "Alinia"
    ],
    "Aliqopa": [
        "Aliqopa"
    ],
    "Alka-Seltzer": [
        "Alka-Seltzer Effervescent Tablet Products",
        "Alka-Seltzer Extra Strength Heartburn ReliefChews",
        "Alka-Seltzer Gum Products",
        "Alka-Seltzer Heartburn plus Gas ReliefChews",
        "Alka-Seltzer Heartburn Relief Gummies",
        "Alka-Seltzer Kids Tummy ReliefChews",
        "Alka-Seltzer Plus Maximum Strength Cold and Cough Liquid Gels",
        "Alka-Seltzer Plus Maximum Strength Cold and Cough PowerMax Liquid Gels",
        "Alka-Seltzer Plus Maximum Strength Cough and Mucus DM",
        "Alka-Seltzer Plus Maximum Strength Cough Mucus and Congestion PowerMax Liquid Gels",
        "Alka-Seltzer Plus Maximum Strength Day and Night Cold and Flu Liquid Gels",
        "Alka-Seltzer Plus Maximum Strength Day and Night Cold and Flu PowerMax Liquid Gels",
        "Alka-Seltzer Plus Maximum Strength Day and Night Cough Mucus and Congestion PowerMax Liquid Gels",
        "Alka-Seltzer Plus Maximum Strength Day and Night Sinus Congestion and Pain Powermax Liquid Gels",
        "Alka-Seltzer Plus Maximum Strength Day Cold and Flu PowerMax Liquid Gels",
        "Alka-Seltzer Plus Maximum Strength Night Cold and Flu PowerMax Liquid Gels",
        "Alka-Seltzer Plus Maximum Strength Severe Sinus Allergy and Cough Liquid Gels",
        "Alka-Seltzer Plus Maximum Strength Sinus Allergy and Cough PowerMax Liquid Gels",
        "Alka-Seltzer Plus Maximum Strength Sinus Congestion and Pain Powerfast Fizz Effervescent Tablet",
        "Alka-Seltzer Plus Maximum Strength Sinus Congestion and Pain Powermax Liquid Gels",
        "Alka-Seltzer Plus Severe Cold and Cough PowerFast Fizz Effervescent Tablet",
        "Alka-Seltzer Plus Severe Cold and Flu PowerFast Fizz Effervescent Tablet",
        "Alka-Seltzer Plus Severe Cold PowerFast Fizz Effervescent Tablet",
        "Alka-Seltzer Plus Severe Day and Night Cold and Flu Mix In Packets",
        "Alka-Seltzer Plus Severe Day and Night Cold PowerFast Fizz Effervescent Tablet",
        "Alka-Seltzer Plus Severe Day Cold and Flu Mix In Packets",
        "Alka-Seltzer Plus Severe Night Cold and Flu Mix In Packets",
        "Alka-Seltzer Plus Severe Night Cold PowerFast Fizz Effervescent Tablet",
        "Alka-Seltzer Plus Severe Non Drowsy Cold PowerFast Fizz Effervescent Tablet",
        "Alka-Seltzer Plus Severe Sinus Cold and Cough Liquid Gels",
        "Alka-Seltzer PM Heartburn Relief plus Sleep Support Gummies"
    ],
    "Allegra": [
        "Allegra 24HR Gelcaps",
        "Allegra Allergy 12 Hour",
        "Allegra Allergy 24 Hour",
        "Allegra Childrens Liquid",
        "Allegra Childrens Meltable Tablets",
        "Allegra-D 12 Hour",
        "Allegra-D 24 Hour"
    ],
    "Alphagan P": [
        "Alphagan P"
    ],
    "Alphanate": ["Alphanate"],
    "Alprolix": ["Alprolix"],
    "Alrex": ["Alrex"],
    "Altabax": ["Altabax"],
    "Altace": ["Altace"],
    "Altera Nebulizer": ["Altera Nebulizer"],
    "Alunbrig": ["Alunbrig"],
    "Alvesco": ["Alvesco"],
    "Always Sanitary Products": ["Always Sanitary Products"],
    "Ambrisentan 5mg Tab": ["Ambrisentan 5mg Tab"],
    "Ampyra": ["Ampyra"],
    "Amzeeq": ["Amzeeq"],
    "Analpram HC": ["Analpram HC"],
    "Anoro Ellipta": ["Anoro Ellipta"],
    "Antara": ["Antara"],
    "ApexiCon E": ["ApexiCon E"],
    "Apidra": ["Apidra", "Apidra SoloStar Pen"],
    "Aplenzin": ["Aplenzin"],
    "Apretude 200mg/ml": ["Apretude 200mg/ml"],
    "Aptiom": ["Aptiom"],
    "Aquaphor": [
        "Aquaphor Baby Diaper Rash Cream",
        "Aquaphor Baby Diaper Rash Paste",
        "Aquaphor Baby Healing Ointment",
        "Aquaphor Healing Ointment for Minor Wound Care Products",
        "Aquaphor Healing Ointment Products",
        "Aquaphor Itch Relief Ointment",
        "Aquaphor Lip Repair Products",
        "Aquaphor Ointment Body Spray"
    ],
    "Aralast NP": ["Aralast NP"],
    "Arazlo": ["Arazlo"],
    "Arikayce Kit": ["Arikayce Kit"],
    "Arimidex": ["Arimidex"],
    "ARISTADA": ["ARISTADA", "ARISTADA INITIO"],
    "ArmonAir Digihaler": ["ArmonAir Digihaler"],
    "Arnicare": [
        "Arnicare",
        "Arnicare Arthritis",
        "Arnicare Topical Products"
    ],
    "Arthriten": ["Arthriten"],
    "Arthrotec": ["Arthrotec"],
    "Asmanex": [
        "Asmanex HFA",
        "Asmanex Twisthaler"
    ],
    "Aspercreme": [
        "Aspercreme Arthritis Pain Relief Gel",
        "Aspercreme Lidocaine Dry Spray",
        "Aspercreme Lidocaine Foot Pain Creme",
        "Aspercreme Lidocaine Foot Pain Creme Diabetic Skin",
        "Aspercreme Lidocaine No-Mess Products",
        "Aspercreme Lidocaine Patch products",
        "Aspercreme Pain Relieving Creme",
        "Aspercreme Pain Relieving Creme with Lidocaine"
    ],
    "Astagraf XL": ["Astagraf XL"],
    "Astroglide Products": ["Astroglide Products"],
    "Atgam": ["Atgam"],
    "Atripla": ["Atripla"],
    "Atrovent HFA": ["Atrovent HFA"],
    "Aubagio": ["Aubagio"],
    "Auryxia": ["Auryxia"],
    "Austedo": ["Austedo"],
    "Auvi-Q": ["Auvi-Q"],
    "Avar Family Products": ["Avar Family Products"],
    "Avastin": ["Avastin"],
    "Aveed": ["Aveed"],
    "Aveeno": [
        "Aveeno 1 percent Hydrocortisone Anti Itch Cream",
        "Aveeno Anti Itch Lotion",
        "Aveeno Baby Cleansing Therapy Moisturizing Wash",
        "Aveeno Baby Eczema Therapy Moisturizing Cream",
        "Aveeno Baby Eczema Therapy Nighttime Balm",
        "Aveeno Baby Eczema Therapy Soothing Bath Treatment",
        "Aveeno Calm plus Restore Nourishing Oat Cleanser for Sensitive SKin",
        "Aveeno Calm plus Restore Oat Gel Moisturizer for Sensitive Skin",
        "Aveeno Calm plus Restore Triple Oat Serum for Sensitive Skin",
        "Aveeno Clear Complexion Cream Face Cleanser with Salicylic Acid",
        "Aveeno Clear Complexion Daily Cleansing Exfoliating Pads for Face",
        "Aveeno Clear Complexion Daily Moisturizer",
        "Aveeno Clear Complexion Foaming Salicylic Acid Face Cleanser",
        "Aveeno Daily Moisturizing Body Lotion for Dry Skin",
        "Aveeno Daily Moisturizing Body Wash",
        "Aveeno Eczema Therapy Daily Moisturizing Cream",
        "Aveeno Eczema Therapy Itch Relief Balm",
        "Aveeno Repairing CICA Foot Mask",
        "Aveeno Repairing CICA Hand Mask",
        "Aveeno Restorative Skin Therapy Itch Relief Balm",
        "Aveeno Restorative Skin Therapy Oat Repairing Cream",
        "Aveeno Restorative Skin Therapy Sulfate-Free Body Wash",
        "Aveeno Skin Relief Body Wash",
        "Aveeno Skin Relief Hand Cream",
        "Aveeno Skin Relief Moisture Repair Cream",
        "Aveeno Skin Relief Moisturizing Lotion",
        "Aveeno Skin Relief Overnight Cream",
        "Aveeno Soothing Bath Treatment",
        "Aveeno Soothing Body Wash"
    ],
    "Avenova 40mL Spray": ["Avenova 40mL Spray"],
    "Avonex": ["Avonex"],
    "Avsola": ["Avsola"],
    "Ayvakit": ["Ayvakit"],
    "AzaSite": ["AzaSite"],
    "Azedra": ["Azedra"],
    "AZO": [
        "AZO Bladder Control",
        "AZO Cranberry Products",
        "AZO Cycle Care and Comfort",
        "AZO D-Mannose",
        "AZO Dual Bladder Urinary and Vaginal Support",
        "AZO Men Bladder Control with Go-Less",
        "AZO Test Strip",
        "AZO Urinary Pain Relief",
        "AZO Urinary Tract Defense",
        "AZO Yeast Plus"
    ],
    "Azor": ["Azor"],
    "Azstarys": ["Azstarys"],
    "Azulfidine": ["Azulfidine"],
    "BACKAID Max": ["BACKAID Max"],
    "Bafiertam": ["Bafiertam"],
    "Balcoltra": ["Balcoltra"],
    "Balmex": [
        "Balmex AdultAdvantage",
        "Balmex Complete Protection Diaper Rash Cream",
        "Balmex Diaper Rash Cream with ActivGuard",
        "Balmex Multi-Purpose Healing Ointment"
    ],
    "Balneol": ["Balneol"],
    "Balversa": ["Balversa"],
    "Band-Aid Brand Products": ["Band-Aid Brand Products"],
    "Baqsimi": ["Baqsimi"],
    "Basaglar KwikPen": ["Basaglar KwikPen"],
    "Bavencio": ["Bavencio"],
    "Bayer Aspirin": [
        "Bayer Aspirin Products",
        "Bayer Aspirin Regimen Products",
        "Bayer Back and Body",
        "Bayer Chewable Aspirin"
    ],
    "Belbuca": ["Belbuca"],
    "Beleodaq": ["Beleodaq"],
    "Belsomra": ["Belsomra"],
    "Benadryl": [
        "Benadryl Allergy Childrens Chewables",
        "Benadryl Allergy Dye-Free Liqui-Gels",
        "Benadryl Allergy Plus Congestion",
        "Benadryl Allergy Ultratab",
        "Benadryl Childrens Allergy Dye-Free Liquid",
        "Benadryl Childrens Allergy Liquid",
        "Benadryl Childrens Itch Cooling Gel",
        "Benadryl Extra Strength Itch Relief Stick",
        "Benadryl Extra Strength Itch Stopping Cream",
        "Benadryl Extra Strength Itch Stopping Gel",
        "Benadryl Itch Cooling Spray Extra Strength",
        "Benadryl Original Strength Itch Stopping Cream"
    ],
    "Benefix": ["Benefix"],
    "Bengay": [
        "Bengay Greaseless Pain Relieving Cream",
        "Bengay Lidocaine Cream",
        "Bengay Ultra Strength Cream",
        "Bengay Ultra Strength Pain Relieving Patch",
        "Bengay Vanishing Scent Gel"
    ],
    "Benicar": ["Benicar", "Benicar HCT"],
    "Benlysta": ["Benlysta"],
    "Bensal HP": ["Bensal HP"],
    "Beovu": ["Beovu"],
    "Bepreve": ["Bepreve"],
    "Berinert": ["Berinert"],
    "Beser": ["Beser"],
    "Besivance": ["Besivance"],
    "Besremi": ["Besremi"],
    "Betaseron": ["Betaseron"],
    "Betimol": ["Betimol"],
    "Bevespi Aerosphere": ["Bevespi Aerosphere"],
    "BiDil": ["BiDil"],
    "Bijuva": ["Bijuva"],
    "Biktarvy": ["Biktarvy"],
    "Binosto": ["Binosto"],
    "Bionect Cream": ["Bionect Cream"],
    "Biotene": [
        "Biotene Dry Mouth Lozenge",
        "Biotene Dry Mouth Moisturizing Spray",
        "Biotene Dry Mouth Oral Rinse Products",
        "Biotene Oralbalance Moisturizing Gel",
        "Biotene Products"
    ],
    "Blenrep": ["Blenrep"],
    "Blincyto": ["Blincyto"],
    "Boiron Single Medicine Products": ["Boiron Single Medicine Products"],
    "Bonjesta": ["Bonjesta"],
    "Bosulif": ["Bosulif"],
    "Botox": ["Botox"],
    "BPH Blood Pressure Health": ["BPH Blood Pressure Health"],
    "Braftovi": ["Braftovi", "Braftovi and Mektovi"],
    "Brava Accessories": ["Brava Accessories"],
    "Breathe Right": [
        "Breathe Right Extra Strength Clear",
        "Breathe Right Extra Strength Tan",
        "Breathe Right Lavender",
        "Breathe Right Original Clear",
        "Breathe Right Original Tan"
    ],
    "BRENZAVVY (bexagliflozin) 20mg Tabs": ["BRENZAVVY (bexagliflozin) 20mg Tabs"],
    "Breo Ellipta": ["Breo Ellipta"],
    "Brexafemme": ["Brexafemme"],
    "Breyanzi": ["Breyanzi"],
    "Breztri Aerosphere": ["Breztri Aerosphere"],
    "Brilinta": ["Brilinta"],
    "Briviact": ["Briviact"],
    "Bromfed DM": ["Bromfed DM"],
    "BromSite": ["BromSite"],
    "Bronchitol": ["Bronchitol"],
    "Brovana": ["Brovana"],
    "Brukinsa": ["Brukinsa"],
    "Bryhali": ["Bryhali"],
    "Buprenorphine": ["Buprenorphine 2mg Tab", "Buprenorphine 8mg Tab", "Buprenorphine/Naloxone 2mg/0.5mg", "Buprenorphine/Naloxone 8mg/2mg"],
    "Bydureon BCise": ["Bydureon BCise"],
    "Bylvay": ["Bylvay"],
    "Bystolic": ["Bystolic"],
    "Cablivi": ["Cablivi"],
    "Cabometyx": ["Cabometyx"],
    "Caduet": ["Caduet"],
    "Calendula": ["Calendula"],
    "Calquence": ["Calquence"],
    "Cambia": ["Cambia"],
    "Camilia Teething Relief": ["Camilia Teething Relief"],
    "Caplyta": ["Caplyta"],
    "Caprelsa": ["Caprelsa"],
    "Carbaglu": ["Carbaglu"],
    "Carmex": [
        "Carmex Classic Lip Balm product",
        "Carmex Cold Sore Treatment",
        "Carmex Daily Care Stick",
        "Carmex Lip Comfort Care product"
    ],
    "CaroSpir": ["CaroSpir"],
    "Caverject Impulse": ["Caverject Impulse"],
    "Cayston": ["Cayston"],
    "Celebrex": ["Celebrex"],
    "CellCept": ["CellCept"],
    "Celontin": ["Celontin"],
    "Centrum": [
        "Centrum Adults",
        "Centrum Adults 50 plus Fresh Fruity Chewables",
        "Centrum Adults Fresh and Fruity Chewables",
        "Centrum Energy Whole Food Blend Supplement",
        "Centrum Kids",
        "Centrum Liquid Multivitamin",
        "Centrum Men",
        "Centrum Mental Focus Whole Food Blend Supplement",
        "Centrum Minis Adults 50 plus",
        "Centrum Minis Men 50 plus",
        "Centrum Minis Women 50 plus",
        "Centrum Multi plus Omega-3 Men 50 plus",
        "Centrum Multi plus Omega-3 Women 50 plus",
        "Centrum MultiGummies Adults",
        "Centrum MultiGummies Adults 50 plus",
        "Centrum MultiGummies Men",
        "Centrum MultiGummies Men 50 plus",
        "Centrum MultiGummies Multi plus Antioxidants",
        "Centrum MultiGummies Multi plus Beauty",
        "Centrum MultiGummies Multi plus Omega 3",
        "Centrum MultiGummies Women",
        "Centrum MultiGummies Women 50 plus",
        "Centrum Natural Beauty Whole Food Blend Supplement",
        "Centrum Silver Adults",
        "Centrum Silver Men",
        "Centrum Silver Women",
        "Centrum Specialist Energy Multivitamin",
        "Centrum Stress Mood Whole Food Blend Supplement",
        "Centrum Whole Food Blend Mens Multivatimin",
        "Centrum Whole Food Blend Sleep Supplement",
        "Centrum Whole Food Blend Womens Multivitamin",
        "Centrum Women"
    ],
    "Cepacol": [
        "Cepacol Extra Strength Sore Throat and Cough Lozenges",
        "Cepacol Extra Strength Sore Throat Lozenges",
        "Cepacol InstaMax Lozenges"
    ],
    "Cequa": ["Cequa"],
    "Ceracade Skin Emulsion": ["Ceracade Skin Emulsion"],
    "CeraVe": [
        "CeraVe Acne Control Cleanser",
        "CeraVe Acne Control Gel",
        "CeraVe Acne Foaming Cream Cleanser",
        "CeraVe Baby Healing Ointment",
        "CeraVe Diabetics Dry Skin Relief",
        "CeraVe Diabetics Dry Skin Relief Cleansing Wash",
        "CeraVe Eczema Relief Creamy Oil",
        "CeraVe Healing Ointment",
        "CeraVe Hydrocortisone Anti-Itch Cream",
        "CeraVe Itch Relief Moisturizing Cream",
        "CeraVe Itch Relief Moisturizing Lotion",
        "CeraVe Psoriasis Cleanser",
        "CeraVe Psoriasis Moisturizing Cream",
        "CeraVe Resurfacing Retinol Serum",
        "CeraVe SA Cleanser-Rough and Bumpy Skin",
        "CeraVe SA Cream-Rough and Bumpy Skin",
        "CeraVe SA Lotion-Rough and Bumpy Skin",
        "CeraVe Therapeutic Hand Cream"
    ],
    "Cerdelga": ["Cerdelga"],
    "CerefolinNAC": ["CerefolinNAC"],
    "Cerezyme": ["Cerezyme"],
    "Cetraxal": ["Cetraxal"],
    "Cetrotide": ["Cetrotide"],
    "Chantix": ["Chantix"],
    "Chestal": [
        "Chestal Childrens Cold and Cough",
        "Chestal Childrens Honey",
        "Chestal Cold and Cough",
        "Chestal Honey"
    ],
    "Childrens Delsym": ["Childrens Delsym 12 Hour Cough Products", "Childrens Delsym Cough plus Cold Night Time"],
    "Childrens Rhinocort Allergy Spray": ["Childrens Rhinocort Allergy Spray"],
    "Childrens Tylenol": [
        "Childrens Tylenol Chewables",
        "Childrens Tylenol Dissolve Powder Packs",
        "Childrens Tylenol Liquid Products"
    ],
    "Childrens Xyzal": ["Childrens Xyzal"],
    "Chromagen": ["Chromagen"],
    "CIBINQO (abrocitinib)": ["CIBINQO (abrocitinib)"],
    "Cimzia": ["Cimzia"],
    "Cinqair": ["Cinqair"],
    "Cinryze": ["Cinryze"],
    "Cinvanti": ["Cinvanti"],
    "Citracal": [
        "Citracal Calcium",
        "Citracal Maximum Plus",
        "Citracal Petites",
        "Citracal Regular",
        "Citracal Slow Release 1200"
    ],
    "CitraNatal": [
        "CitraNatal 90 DHA",
        "CitraNatal Assure",
        "CitraNatal B-Calm",
        "CitraNatal Bloom",
        "CitraNatal Harmony"
    ],
    "Claritin": [
        "Claritin Chewables 24HR",
        "Claritin Childrens Chewables",
        "Claritin Childrens Syrup 24-Hour",
        "Claritin Liqui-Gels 24HR",
        "Claritin RediTabs for Juniors",
        "Claritin RediTabs Products",
        "Claritin Tablets 24HR",
        "Claritin-D Products"
    ],
    "Clean and Clear": [
        "Clean and Clear Acne Triple Clear Exfoliating Scrub",
        "Clean and Clear Advantage Acne Control Products",
        "Clean and Clear Aloe Vera Acne Products",
        "Clean and Clear Continuous Control",
        "Clean and Clear Essentials Daily Acne Products",
        "Clean and Clear Persa-Gel 10"
    ],
    "Clear Care": [
        "Clear Care",
        "Clear Care Plus",
        "Clear Care Rinse and Go"
    ],
    "Clearasil": [
        "Clearasil Gentle Prevention Daily Products",
        "Clearasil Rapid Rescue Products",
        "Clearasil Stubborn Acne Control Products"
    ],
    "Clemastine Fumarate Syrup": ["Clemastine Fumarate Syrup"],
    "Clenpiq": ["Clenpiq"],
    "Climara Pro": ["Climara Pro"],
    "Clindacin": ["Clindacin"],
    "Clindagel": ["Clindagel"],
    "Clindesse": ["Clindesse"],
    "Clodan": ["Clodan"],
    "Cloderm": ["Cloderm"],
    "CloSYS Sensitive Fluoride": ["CloSYS Sensitive Fluoride"],
    "Coagadex": ["Coagadex"],
    "Colace": ["Colace"],
    "Coldcalm Liquid Doses": ["Coldcalm Liquid Doses"],
    "Colestid": ["Colestid"],
    "Colgate": [
        "Colgate Enamel Health Mouthwash",
        "Colgate Enamel Health Products",
        "Colgate Hydris Oral Rinse for Dry Mouth",
        "Colgate Kids Cavity Protection Toothpaste Products",
        "Colgate Renewal Toothpaste Products",
        "Colgate Sensitive Products",
        "Colgate Total Mouthwash for Gum Health",
        "Colgate Total Toothpaste Products"
    ],
    "ColicComfort": ["ColicComfort"],
    "Combigan": ["Combigan"],
    "CombiPatch": ["CombiPatch"],
    "Combivent Respimat": ["Combivent Respimat"],
    "Cometriq": ["Cometriq"],
    "Complera": ["Complera"],
    "Concept DHA": ["Concept DHA"],
    "Concerta": ["Concerta"],
    "Contac": [
        "Contac Cold Flu Cooling Relief",
        "Contac Cold Flu Day",
        "Contac Cold Flu Night",
        "Contac Cold+Flu Day and Nite Dual Formula Pack"
    ],
    "Contour Next": [
        "Contour Next EZ Meter",
        "Contour Next EZ Test Strip",
        "Contour Next One Meter",
        "Contour Next One Test Strip",
        "Contour Test Strip"
    ],
    "Contrave": ["Contrave"],
    "ConvaTec Ostomy Products": ["ConvaTec Ostomy Products"],
    "Conveen": [
        "Conveen Active",
        "Conveen Optima"
    ],
    "CoolSculpting": ["CoolSculpting"],
    "Copaxone": ["Copaxone"],
    "Copiktra": ["Copiktra"],
    "Cordran": ["Cordran"],
    "Coricidin HBP": [
        "Coricidin HBP Chest Congestion and Cough",
        "Coricidin HBP Cold and Flu",
        "Coricidin HBP Cough and Cold",
        "Coricidin HBP Maximum Strength Cold and Flu Day and Night",
        "Coricidin HBP Maximum Strength Cold Cough and Flu Liquid",
        "Coricidin HBP Maximum Strength Multi Symptom Flu",
        "Coricidin HBP Maximum Strength Nighttime Cold and Flu Liquid"
    ],
    "Corifact": ["Corifact"],
    "Corlanor": ["Corlanor"],
    "Cortizone-10": [
        "Cortizone-10 Anti-Itch Creme Products",
        "Cortizone-10 Anti-Itch Lotion for Diabetics Skin",
        "Cortizone-10 Anti-Itch Lotion for Psoriasis",
        "Cortizone-10 Anti-Itch Ointment",
        "Cortizone-10 Cooling Relief Anti-Itch Gel",
        "Cortizone-10 Easy Relief Applicator Anti-Itch Liquid",
        "Cortizone-10 Feminine Relief Anti-Itch Creme",
        "Cortizone-10 Intensive Healing Eczema Lotion",
        "Cortizone-10 Overnight Itch Relief"
    ],
    "Corvite FE": ["Corvite FE"],
    "Cosamin": ["Cosamin ASU", "Cosamin Avoca", "Cosamin DS"],
    "Cosentyx": ["Cosentyx"],
    "Cosopt PF": ["Cosopt PF"],
    "Cotellic": ["Cotellic"],
    "Cotempla XR-ODT": ["Cotempla XR-ODT"],
    "Coxanto": ["Coxanto 300mg Capsules"],
    "Creon": ["Creon"],
    "Cresemba": ["Cresemba"],
    "Crestor": ["Crestor"],
    "Crinone": ["Crinone"],
    "Cuprimine": ["Cuprimine"],
    "Cutaquig": ["Cutaquig"],
    "Cuvitru": ["Cuvitru"],
    "Cuvposa": ["Cuvposa"],
    "Cycloset": ["Cycloset"],
    "Cyramza": ["Cyramza"],
    "Cystadane": ["Cystadane"],
    "Cystadrops": ["Cystadrops"],
    "Cytomel": ["Cytomel"],
    "Cytotec": ["Cytotec"],
    "Dailies AquaComfort Plus": [
        "Dailies AquaComfort Plus Contact Lenses",
        "DAILIES Aquacomfort Plus Multifocal Contact lenses",
        "DAILIES Aquacomfort Plus Toric Contact Lenses"
    ],
    "DAILIES COLORS": ["DAILIES COLORS Contact Lenses"],
    "DAILIES TOTAL1": [
        "DAILIES TOTAL1 Contact Lenses",
        "DAILIES TOTAL1 Multifocal Contact Lenses"
    ],
    "Daliresp": ["Daliresp"],
    "Dalvance": ["Dalvance"],
    "Danyelza": ["Danyelza"],
    "Daraprim": ["Daraprim"],
    "Darzalex": ["Darzalex", "Darzalex Faspro"],
    "Daypro": ["Daypro"],
    "Daytrana": ["Daytrana"],
    "Dayvigo": ["Dayvigo"],
    "Deferasirox": ["Deferasirox"],
    "Deferiprone": ["Deferiprone"],
    "Delstrigo": ["Delstrigo"],
    "Delsym": [
        "Delsym 12 Hour Cough Products",
        "Delsym Cough and Chest Suppressants DM",
        "Delsym Cough plus Cold Night Time"
    ],
    "Delzicol": ["Delzicol"],
    "Demser": ["Demser"],
    "Depakote": [
        "Depakote",
        "Depakote ER",
        "Depakote Sprinkle"
    ],
    "Depend": [
        "Depend Fit-Flex Underwear for Men",
        "Depend Fit-Flex Underwear for Women",
        "Depend Guards for Men",
        "Depend Night Defense Underwear for Men",
        "Depend Night Defense Underwear for Women",
        "Depend Protection Plus Underwear for Men",
        "Depend Protection Plus Underwear for Women",
        "Depend Protection with Tabs Unisex Briefs",
        "Depend Real Fit Briefs for Men",
        "Depend Shields for Men",
        "Depend Silhouette Brief Products for Women",
        "Depend Unisex Night Defense Underpads"
    ],
    "Deplin": ["Deplin"],
    "Depo": [
        "Depo-Estradiol",
        "Depo-Medrol",
        "Depo-SubQ Provera 104"
    ],
    "DerMend": [
        "DerMend Fragile Skin Moisturizing Formula Cream",
        "DerMend Moisturizing Anti-Itch Lotion",
        "DerMend Moisturizing Bruise Formula Cream"
    ],
    "Descovy": ["Descovy"],
    "Desitin": [
        "Desitin Daily Defense",
        "Desitin Maximum Strength Original Paste",
        "Desitin Multi-Purpose Ointment"
    ],
    "Desonide": ["Desonide"],
    "Detrol LA": ["Detrol LA"],
    "Dexilant": ["Dexilant"],
    "Diabetic Tussin": [
        "Diabetic Tussin Chest Congestion",
        "Diabetic Tussin Cough and Chest Congestion",
        "Diabetic Tussin Maximum Strength Cough and Chest Congestion DM",
        "Diabetic Tussin Night Time Cold and Flu"
    ],
    "Differin": [
        "Differin Daily Deep Cleanser",
        "Differin Daily Refreshing Cleanser",
        "Differin Gel",
        "Differin Gentle Cleanser",
        "Differin Oil Absorbing Moisturizer with SPF 30",
        "Differin Restorative Night Moisturizer",
        "Differin Soothing Moisturizer"
    ],
    "Dificid": ["Dificid"],
    "Diflucan": ["Diflucan"],
    "Dilantin": ["Dilantin"],
    "Diovan": ["Diovan", "Diovan HCT"],
    "Diurex Products": ["Diurex Products"],
    "Divigel": ["Divigel"],
    "Domeboro": [
        "Domeboro Cooling Gel",
        "Domeboro Medicated Soak"
    ],
    "Doryx MPC": ["Doryx MPC"],
    "Dovato": ["Dovato"],
    "Doxycycline": ["Doxycycline"],
    "Dr. Scholls": [
        "Dr. Scholls Arthritis Pain Reliever",
        "Dr. Scholls Blister Cushions with Duragel",
        "Dr. Scholls Bunion Cushions",
        "Dr. Scholls Bunion Cushions with Duragel",
        "Dr. Scholls Callus Cushions with Duragel",
        "Dr. Scholls Callus Removers with Duragel",
        "Dr. Scholls Clear Away Fast-Acting Liquid Wart Remover",
        "Dr. Scholls Clear Away Plantar Wart Remover",
        "Dr. Scholls Clear Away Wart Remover with Duragel",
        "Dr. Scholls Corn Cushions",
        "Dr. Scholls Corn Cushions with Duragel",
        "Dr. Scholls Corn Removers Soft Felt Pads",
        "Dr. Scholls Corn Removers with Duragel",
        "Dr. Scholls Dual Action Freeze Away Wart Remover",
        "Dr. Scholls Extra Thick Callus Removers",
        "Dr. Scholls Freeze Away Max Wart Remover",
        "Dr. Scholls Freeze Away Wart Remover",
        "Dr. Scholls Ingrown Toenail Pain Reliever",
        "Dr. Scholls Liquid Corn-Callus Remover",
        "Dr. Scholls Molefoam Padding Strips",
        "Dr. Scholls Moleskin Plus Padding Roll",
        "Dr. Scholls Moleskin Plus Padding Strips",
        "Dr. Scholls Odor-X Antifungal Spray Powder",
        "Dr. Scholls Odor-X Probiotic Extract Formula Foot Spray",
        "Dr. Scholls Odor-X Ultra Odor-Fighting Foot Powder",
        "Dr. Scholls Odor-X Ultra Odor-Fighting Spray Powder",
        "Dr. Scholls Odor-X Ultra Sweat-Absorbing Foot Powder",
        "Dr. Scholls One Step Corn Removers",
        "Dr. Scholls Severe Cracked Heel Balm",
        "Dr. Scholls Ultra Exfoliating Foot Products",
        "Dr. Scholls Ultra Hydrating Foot Mask Products"
    ],
    "Duavee": ["Duavee"],
    "Duexis": ["Duexis"],
    "Dulcolax": [
        "Dulcolax Laxative",
        "Dulcolax Liquid Laxative",
        "Dulcolax Pink Laxative Tablets",
        "Dulcolax Pink Stool Softener",
        "Dulcolax Soft Chews",
        "Dulcolax Stool Softener",
        "Dulcolax Suppositories"
    ],
    "Dulera": ["Dulera"],
    "Duobrii": ["Duobrii"],
    "Dupixent": ["Dupixent"],
    "Durolane": ["Durolane"],
    "Durysta": ["Durysta"],
    "Dyanavel XR": ["Dyanavel XR"],
    "Dysport": ["Dysport"],
    "Edarbi": ["Edarbi", "Edarbyclor"],
    "Edex": ["Edex"],
    "Edurant": ["Edurant"],
    "Effexor XR": ["Effexor XR"],
    "Egrifta SV": ["Egrifta SV"],
    "Elaprase": ["Elaprase"],
    "Elelyso": ["Elelyso"],
    "Elestrin": ["Elestrin"],
    "Eletone": ["Eletone"],
    "Eliquis": ["Eliquis"],
    "Elitek": ["Elitek"],
    "Elmiron": ["Elmiron"],
    "Eloctate": ["Eloctate"],
    "Elzonris": ["Elzonris"],
    "Embrace PRO": ["Embrace PRO BGM Strips", "Embrace PRO Talk BGM Kit"],
    "Embrace Talk": ["Embrace Talk BGM Kit", "Embrace Talk BGM Strips"],
    "Emflaza": ["Emflaza"],
    "Emgality": ["Emgality"],
    "Empaveli": ["Empaveli"],
    "Empliciti": ["Empliciti"],
    "EMSAM": ["EMSAM"],
    "Emtriva": ["Emtriva"],
    "Emverm": ["Emverm"],
    "Enbrel": ["Enbrel"],
    "Endari": ["Endari"],
    "Endometrin": ["Endometrin"],
    "Enemeez": ["Enemeez", "Enemeez Plus"],
    "Enfagrow Premium Toddler Transitions": ["Enfagrow Premium Toddler Transitions"],
    "Enfamil": [
        "Enfamil 24",
        "Enfamil A.R.",
        "Enfamil Dietary Needs Formulas",
        "Enfamil EnfaCare",
        "Enfamil Enspire Products",
        "Enfamil Gentlease",
        "Enfamil Infant Formula",
        "Enfamil NeuroPro EnfaCare Formula",
        "Enfamil Nutramigen with Enflora LGG",
        "Enfamil Pregestimil DHA and ARA",
        "Enfamil Premature",
        "Enfamil Premium Infant",
        "Enfamil Premium Newborn",
        "Enfamil ProSobee",
        "Enfamil Reguline"
    ],
    "Enhertu": ["Enhertu"],
    "Enspryng": ["Enspryng"],
    "Enstilar": ["Enstilar"],
    "Ensure": [
        "Ensure Clear Drink Products",
        "Ensure Compact Shake Products",
        "Ensure Complete Shake Products",
        "Ensure Enlive Shake Products",
        "Ensure High Protein Bars",
        "Ensure High Protein Shake Products",
        "Ensure Light Shake Products",
        "Ensure Max Protein Shake Products",
        "Ensure Original Nutrition Powder",
        "Ensure Original Shake Products",
        "Ensure Plant Based Protein Shake",
        "Ensure Plus Shake Products",
        "Ensure Pre Surgery Clear Carbohydrate",
        "Ensure Rapid Hydration Powder Products",
        "Ensure Surgery Immunonutrition Shake"
    ],
    "EnteraGam": ["EnteraGam"],
    "Entresto": ["Entresto"],
    "Entty": ["Entty"],
    "Entyvio": ["Entyvio"],
    "Envarsus XR": ["Envarsus XR"],
    "Epaned": ["Epaned"],
    "Epclusa": ["Epclusa"],
    "EpiCeram": ["EpiCeram Skin Barrier Emulsion"],
    "Epidiolex": ["Epidiolex"],
    "Epiduo Forte": ["Epiduo Forte"],
    "Epinephrine": ["Epinephrine"],
    "EpiPen 2-Pak": ["EpiPen 2-Pak", "EpiPen Jr 2-Pak"],
    "Eprontia": ["Eprontia"],
    "Equetro": ["Equetro"],
    "Erbitux": ["Erbitux"],
    "Ergomar": ["Ergomar"],
    "Erivedge": ["Erivedge"],
    "Erleada": ["Erleada"],
    "Erythromycin Family Products": ["Erythromycin Family Products"],
    "Esbriet": ["Esbriet"],
    "Esperoct": ["Esperoct"],
    "Estring": ["Estring"],
    "EstroGel": ["EstroGel"],
    "Estroven": [
        "Estroven Complete Multi-Symptom Menopause Relief",
        "Estroven Menopause Relief plus Energy",
        "Estroven Menopause Relief plus Mood",
        "Estroven Menopause Relief plus Sleep",
        "Estroven Menopause Relief plus Weight",
        "Estroven Perimenopause Relief plus Weight"
    ],
    "Eucerin": [
        "Eucerin Advanced Cleansing Body and Face Cleanser",
        "Eucerin Advanced Repair Cream Products",
        "Eucerin Advanced Repair Lotion",
        "Eucerin Baby Eczema Relief Cream Body Wash",
        "Eucerin Baby Eczema Relief Cream Products",
        "Eucerin Baby Eczema Relief Flare-Up Treatment",
        "Eucerin Daily Hydration Cream Products",
        "Eucerin Daily Hydration Lotion Products",
        "Eucerin Eczema Relief Cream Body Wash",
        "Eucerin Eczema Relief Cream Products",
        "Eucerin Intensive Repair Lotion",
        "Eucerin Itch Relief Intensive Calming Lotion",
        "Eucerin Original Healing Cream",
        "Eucerin Original Healing Lotion",
        "Eucerin Roughness Relief Cream",
        "Eucerin Roughness Relief Lotion",
        "Eucerin Roughness Relief Spot Treatment",
        "Eucerin Skin Calming Body Wash",
        "Eucerin Skin Calming Cream",
        "Eucerin Skin Calming Itch Relief Lotion",
        "Eucerin Skin Calming Lotion"
    ],
    "Eucrisa": ["Eucrisa"],
    "Euflexxa": ["Euflexxa"],
    "Evamist": ["Evamist"],
    "Evekeo": ["Evekeo", "Evekeo ODT"],
    "Evenity": ["Evenity"],
    "Evkeeza": ["Evkeeza"],
    "Evomela": ["Evomela"],
    "Evrysdi": ["Evrysdi"],
    "Excedrin": [
        "Excedrin Extra Strength",
        "Excedrin Migraine",
        "Excedrin PM Headache",
        "Excedrin Tension Headache"
    ],
    "Exelderm": ["Exelderm"],
    "Exjade": ["Exjade"],
    "Exkivity": ["Exkivity"],
    "Exondys 51": ["Exondys 51"],
    "Extavia": ["Extavia"],
    "Eylea": ["Eylea"],
    "Eysuvis": ["Eysuvis"],
    "Fabior": ["Fabior"],
    "Fabrazyme": ["Fabrazyme"],
    "Fanapt": ["Fanapt"],
    "Fareston": ["Fareston"],
    "Farxiga": ["Farxiga"],
    "Farydak": ["Farydak"],
    "Fasenra": ["Fasenra"],
    "Faslodex": ["Faslodex"],
    "Feiba": ["Feiba", "Feiba VH"],
    "Feldene": ["Feldene"],
    "Fem pH": ["Fem pH"],
    "Femara": ["Femara"],
    "Fensolvi": ["Fensolvi"],
    "Feosol": [
        "Feosol Complete with Bifera",
        "Feosol Natural Release",
        "Feosol Original"
    ],
    "FeRiva": ["FeRiva 21-7", "FeRiva FA"],
    "Ferralet 90": ["Ferralet 90"],
    "Ferriprox": ["Ferriprox"],
    "Fetzima": ["Fetzima"],
    "Fiasp": ["Fiasp", "Fiasp FlexTouch"],
    "Fiber Choice": [
        "Fiber Choice Bone Health Chewable Tablets",
        "Fiber Choice Chewable Tablets",
        "Fiber Choice Fruity Bites",
        "Fiber Choice Metabolism and Energy Fruity Bites"
    ],
    "Finacea": ["Finacea"],
    "Fintepla": ["Fintepla"],
    "Firazyr": ["Firazyr"],
    "Firdapse": ["Firdapse"],
    "First Response": [
        "First Response Daily Digital Ovulation Test",
        "First Response Early Result Pregnancy Test",
        "First Response Gold Digital Pregnancy Test",
        "First Response Ovulation 7 Count plus 1 Pregnancy Test",
        "First Response Pre-Seed Lubricant",
        "First Response Pregnancy and Confirm Test",
        "First Response Rapid Result Pregnancy Test"
    ],
    "Flagyl": ["Flagyl"],
    "Flarex": ["Flarex"],
    "Flector": ["Flector"],
    "Flintstones": [
        "Flintstones Chewable Multivitamin Products",
        "Flintstones Gummies Supplements Products",
        "Flintstones Natural Fruit Bites Multivitamin",
        "Flintstones SuperBeans Multivitamin"
    ],
    "Flonase": [
        "Flonase Allergy Relief - Childrens",
        "Flonase Allergy Relief Nasal Spray",
        "Flonase Sensimist Allergy Relief",
        "Flonase Sensimist Allergy Relief - Childrens"
    ],
    "Florastor": ["Florastor", "Florastor Kids", "FlorastorSelect"],
    "Fluoroplex": ["Fluoroplex"],
    "Fluoxetine HCI": ["Fluoxetine HCI"],
    "Focalin XR": ["Focalin XR"],
    "Follistim AQ": ["Follistim AQ Cartridge"],
    "Folotyn": ["Folotyn"],
    "Forteo": ["Forteo"],
    "Fotivda": ["Fotivda"],
    "FreeStyle": [
        "FreeStyle Freedom Lite Blood Glucose Monitoring System",
        "FreeStyle InsuLinx Glucose Monitoring System",
        "FreeStyle Libre 14 day System",
        "FreeStyle Libre 2 System",
        "FreeStyle Lite Glucose Monitoring System",
        "FreeStyle Precision Neo Blood Glucose Monitoring System"
    ],
    "Fulphila": ["Fulphila"],
    "FungiCURE": ["FungiCURE Products"],
    "Fusilev": ["Fusilev"],
    "Fusion": ["Fusion", "Fusion Plus", "Fusion Sprinkles"],
    "Futuro": [
        "Futuro Compression Socks or Hosiery Products",
        "Futuro Support or Brace Products"
    ],
    "Fycompa": ["Fycompa"],
    "Gammagard Liquid": ["Gammagard Liquid"],
    "Gamunex-C": ["Gamunex-C"],
    "Ganirelix Acetate": ["Ganirelix Acetate"],
    "Gas-X": ["Gas-X Chewables", "Gas-X Softgels"],
    "Gasalia": ["Gasalia"],
    "Gattex": ["Gattex"],
    "Gavreto": ["Gavreto"],
    "Gazyva": ["Gazyva"],
    "Gelclair": ["Gelclair"],
    "Gelsyn-3": ["Gelsyn-3"],
    "Gemtesa": ["Gemtesa"],
    "Genadur": ["Genadur"],
    "Genotropin": ["Genotropin"],
    "GenTeal": ["GenTeal"],
    "Genvoya": ["Genvoya"],
    "Geodon": ["Geodon"],
    "Geritol": ["Geritol Multivitamin", "Geritol Tonic"],
    "Gilenya": ["Gilenya"],
    "Gilotrif": ["Gilotrif"],
    "Gimoti": ["Gimoti"],
    "Givlaari": ["Givlaari"],
    "Glatiramer Acetate": ["Glatiramer Acetate"],
    "Glatopa": ["Glatopa"],
    "Gleevec": ["Gleevec"],
    "Glucagon": ["Glucagon"],
    "Glucerna": [
        "Glucerna 30g Protein Shake",
        "Glucerna Hunger Smart Powder",
        "Glucerna Hunger Smart Shakes",
        "Glucerna Shakes",
        "Glucerna Snack Bars",
        "Glucerna Snack Shakes"
    ],
    "Glucotrol": ["Glucotrol"],
    "Glynase": ["Glynase"],
    "Glyset": ["Glyset"],
    "Glyxambi": ["Glyxambi"],
    "Gocovri": ["Gocovri"],
    "Gold Bond": [
        "Gold Bond Advanced Healing Ointment",
        "Gold Bond Anti-Itch Lotion",
        "Gold Bond Clear Body Powder Spray",
        "Gold Bond Cracked Foot Skin Relief",
        "Gold Bond Extra Strength Body Lotion",
        "Gold Bond Friction Defense",
        "Gold Bond Medicated Eczema Relief Cream",
        "Gold Bond Medicated Extra Strength Body Powder",
        "Gold Bond Medicated Maximum Strength Foot Powder",
        "Gold Bond Medicated Original Strength Body Powder",
        "Gold Bond Multi-Symptom Psoriasis Relief",
        "Gold Bond No Mess Body Powder Spray",
        "Gold Bond No Mess Foot Powder",
        "Gold Bond Pain and Itch Relief Cream with Lidocaine",
        "Gold Bond Rapid Relief Anti-Itch Cream",
        "Gold Bond Therapeutic Foot Cream",
        "Gold Bond Ultimate Cracked Skin Relief Cream",
        "Gold Bond Ultimate Crepe Corrector Age Defense",
        "Gold Bond Ultimate Diabetics Dry Skin Relief Foot Cream",
        "Gold Bond Ultimate Eczema Relief",
        "Gold Bond Ultimate Psoriasis Relief Cream",
        "Gold Bond Ultimate Rough and Bumpy Skin"
    ],
    "Gonal-F": ["Gonal-F"],
    "Gralise": ["Gralise"],
    "Granix": ["Granix"],
    "Grastek": ["Grastek"],
    "Gvoke": ["Gvoke HypoPen", "Gvoke PFS"],
    "Gynazole 1": ["Gynazole 1"],
    "Haegarda": ["Haegarda"],
    "Halaven": ["Halaven"],
    "Halcion": ["Halcion"],
    "Halog Products": ["Halog Products"],
    "Harvoni": ["Harvoni"],
    "Head and Shoulders": [
        "Head and Shoulders Aloe Vera Anti-Dandruff Products",
        "Head and Shoulders Apple Cider Vinegar Products",
        "Head and Shoulders Classic Clean Anti-Dandruff Products",
        "Head and Shoulders Clinical Dandruff Defense Intensive Itch Relief",
        "Head and Shoulders Clinical Itch Relief Mist",
        "Head and Shoulders Clinical Moisturizing Scalp Cream",
        "Head and Shoulders Clinical Strength Dandruff Defense plus",
        "Head and Shoulders Coconut Anti-Dandruff Products",
        "Head and Shoulders Deep Moisture Products",
        "Head and Shoulders Dry Scalp Care with Almond Oil Products",
        "Head and Shoulders Green Apple Anti-Dandruff Products",
        "Head and Shoulders Itchy Scalp Care Anti-Dandruff Products",
        "Head and Shoulders Mens Advanced Series Products",
        "Head and Shoulders Mens Refreshing Menthol Anti-Dandruff Products",
        "Head and Shoulders Tea Tree Anti-Dandruff Products",
        "Head and Shoulders Volume Boost Anti-Dandruff Products",
        "Head and Shoulders White Charcoal Anti-Dandruff Products"
    ],
    "Heliocare Products": ["Heliocare Products"],
    "Hemabate": ["Hemabate"],
    "Hemady": ["Hemady"],
    "Hemangeol": ["Hemangeol"],
    "HemCalm": ["HemCalm Ointment", "HemCalm Suppositories", "HemCalm Tablets"],
    "Hemlibra": ["Hemlibra"],
    "Hemocyte": ["Hemocyte F", "Hemocyte Plus"],
    "Hemofil-M": ["Hemofil-M"],
    "Herceptin": ["Herceptin", "Herceptin Hylecta"],
    "Herzuma": ["Herzuma"],
    "Hizentra": ["Hizentra"],
    "Hollister": [
        "Hollister Continence Care Products",
        "Hollister Critical Care Products",
        "Hollister Ostomy Care Products",
        "Hollister Wound Care Products"
    ],
    "Horizant": ["Horizant"],
    "Humalog": [
        "Humalog Junior KwikPen",
        "Humalog KwikPen",
        "Humalog Mix 50-50",
        "Humalog Mix 50-50 KwikPen",
        "Humalog Mix 75-25",
        "Humalog Mix 75-25 Pen",
        "Humalog U-100",
        "Humalog U-200 KwikPen"
    ],
    "Humate-P": ["Humate-P"],
    "Humira": ["Humira"],
    "Humulin": [
        "Humulin 70-30",
        "Humulin 70-30 Kwikpen",
        "Humulin N",
        "Humulin R U-100",
        "Humulin R U-500",
        "Humulin R U-500 KwikPen"
    ],
    "HyQvia": ["HyQvia"],
    "Hysingla ER": ["Hysingla ER"],
    "I-Caps": ["I-Caps Eye Multivitamin Formula", "I-Caps Eye Vitamin AREDS Formula"],
    "Ibrance": ["Ibrance"],
    "Icatibant": ["Icatibant"],
    "Iclusig": ["Iclusig"],
    "IcyHot": [
        "IcyHot Advanced Cream",
        "IcyHot Advanced Patch",
        "IcyHot Back Patch",
        "IcyHot Balm",
        "IcyHot Cream",
        "IcyHot Dry Spray",
        "IcyHot Foam",
        "IcyHot Lidocaine Cream",
        "IcyHot Lidocaine Dry Spray",
        "IcyHot Lidocaine No Mess Roll-on",
        "IcyHot Lidocaine Patch",
        "IcyHot No Mess Roll-On",
        "IcyHot Power Gel",
        "IcyHot Vanishing Scent Gel"
    ],
    "Idelvion": ["Idelvion"],
    "Idhifa": ["Idhifa"],
    "Ilaris": ["Ilaris"],
    "Ilumya": ["Ilumya"],
    "Iluvien": ["Iluvien"],
    "Imbruvica": ["Imbruvica"],
    "Imcivree": ["Imcivree"],
    "Imfinzi": ["Imfinzi"],
    "Imlygic": ["Imlygic"],
    "Imodium": [
        "Imodium A-D Caplet",
        "Imodium A-D Children's",
        "Imodium A-D Oral Solution",
        "Imodium A-D Softgel",
        "Imodium Multi-Symptom Relief"
    ],
    "Impeklo": ["Impeklo"],
    "Imvexxy": ["Imvexxy"],
    "Increlex": ["Increlex"],
    "Inderal": ["Inderal LA", "Inderal XL"],
    "Inflectra": ["Inflectra"],
    "Ingrezza": ["Ingrezza"],
    "Injectafer": ["Injectafer"],
    "Inlyta": ["Inlyta"],
    "InnoPran": ["InnoPran XL"],
    "Inqovi": ["Inqovi"],
    "Inrebic": ["Inrebic"],
    "Insulin Aspart": ["Insulin Aspart", "Insulin Aspart Protamine and Insulin Aspart Mix 70/30"],
    "Integra": ["Integra", "Integra F", "Integra Plus"],
    "Intelence": ["Intelence"],
    "Intrarosa": ["Intrarosa"],
    "Invega": [
        "Invega Hafyera",
        "Invega Sustenna",
        "Invega Trinza"
    ],
    "Inveltys": ["Inveltys"],
    "Invokamet": ["Invokamet", "Invokamet XR"],
    "Invokana": ["Invokana"],
    "Iressa": ["Iressa"],
    "Irospan 24-6": ["Irospan 24-6"],
    "Isentress": ["Isentress", "Isentress HD"],
    "Istalol": ["Istalol"],
    "Isturisa": ["Isturisa"],
    "Ixinity": ["Ixinity"],
    "Jadenu": ["Jadenu", "Jadenu Sprinkle"],
    "Jakafi": ["Jakafi"],
    "Janumet": ["Janumet", "Janumet XR"],
    "Januvia": ["Januvia"],
    "Jardiance": ["Jardiance"],
    "Jatenzo": ["Jatenzo"],
    "Jelmyto": ["Jelmyto"],
    "Jemperli": ["Jemperli"],
    "Jentadueto": ["Jentadueto", "Jentadueto XR"],
    "Jevtana": ["Jevtana"],
    "Jivi": ["Jivi"],
    "Jornay PM": ["Jornay PM"],
    "Jublia": ["Jublia"],
    "Juluca": ["Juluca"],
    "Juvederm": [
        "Juvederm Ultra Plus XC",
        "Juvederm Ultra XC",
        "Juvederm Volbella XC",
        "Juvederm Vollure XC",
        "Juvederm Voluma XC"
    ],
    "Jynarque": ["Jynarque"],
    "Kadcyla": ["Kadcyla"],
    "Kalbitor": ["Kalbitor"],
    "Kaletra": ["Kaletra"],
    "Kalydeco": ["Kalydeco"],
    "Kanjinti": ["Kanjinti"],
    "Kanuma": ["Kanuma"],
    "Karbinal ER": ["Karbinal ER"],
    "Katerzia": ["Katerzia"],
    "Kerasal": [
        "Kerasal Fungal Nail Renewal Treatment",
        "Kerasal Intensive Foot Repair",
        "Kerasal Multi-Purpose Nail Repair"
    ],
    "Kerendia": ["Kerendia"],
    "Kesimpta": ["Kesimpta"],
    "Ketodan": ["Ketodan"],
    "Keveyis": ["Keveyis"],
    "Kevzara": ["Kevzara"],
    "Keytruda": ["Keytruda"],
    "Khapzory": ["Khapzory"],
    "Kimyrsa": ["Kimyrsa"],
    "Kineret": ["Kineret"],
    "Kisqali": ["Kisqali", "Kisqali and Femara"],
    "Kitabis Pak": ["Kitabis Pak"],
    "Klisyri": ["Klisyri"],
    "Kloxxado": ["Kloxxado"],
    "Kogenate FS": ["Kogenate FS"],
    "Kombiglyze XR": ["Kombiglyze XR"],
    "Korlym": ["Korlym"],
    "Koselugo": ["Koselugo"],
    "Kovaltry": ["Kovaltry"],
    "Kristalose": ["Kristalose"],
    "Krystexxa": ["Krystexxa"],
    "Kuvan": ["Kuvan"],
    "Kybella": ["Kybella"],
    "Kynmobi": ["Kynmobi"],
    "Kyprolis": ["Kyprolis"],
    "Lacrisert": ["Lacrisert"],
    "Lactaid": [
        "Lactaid Fast Act Caplets",
        "Lactaid Fast Act Chewables",
        "Lactaid Original Strength"
    ],
    "Lampit": ["Lampit"],
    "Lantus": [
        "Lantus",
        "Lantus SoloSTAR Pen",
        "Lantus U-100"
    ],
    "Latisse": ["Latisse"],
    "Latuda": ["Latuda"],
    "Lazanda": ["Lazanda"],
    "Lemtrada": ["Lemtrada"],
    "Lenvima": ["Lenvima"],
    "Letairis": ["Letairis"],
    "Levemir": ["Levemir"],
    "Levoxyl": ["Levoxyl"],
    "Lexette": ["Lexette"],
    "Lexiva": ["Lexiva"],
    "Libtayo": ["Libtayo"],
    "Lil Critters Gummie Vitamin Products": ["Lil Critters Gummie Vitamin Products"],
    "Liletta": ["Liletta"],
    "Linzess": ["Linzess"],
    "Lipitor": ["Lipitor"],
    "Listerine": [
        "Listerine Gum Therapy Antiseptic Mouthwash",
        "Listerine Naturals Fluoride Free Antiseptic Mouthwash",
        "Listerine Original Antiseptic Mouthwash Products",
        "Listerine Sensitivity Mouthwash",
        "Listerine Ultra Clean Mouthwash Products"
    ],
    "Livalo": ["Livalo"],
    "Livmarli": ["Livmarli"],
    "Livtencity": ["Livtencity"],
    "Lo Loestrin Fe": ["Lo Loestrin Fe"],
    "Lokelma": ["Lokelma"],
    "Lomaira": ["Lomaira"],
    "Lonhala Magnair": ["Lonhala Magnair"],
    "Lonsurf": ["Lonsurf"],
    "Lopid": ["Lopid"],
    "Loprox": ["Loprox"],
    "Lorbrena": ["Lorbrena"],
    "Loreev XR": ["Loreev XR"],
    "Lotemax": [
        "Lotemax Gel",
        "Lotemax Ointment",
        "Lotemax SM",
        "Lotemax Suspension"
    ],
    "Lotrimin": [
        "Lotrimin AF Athletes Foot Antifungal Cream",
        "Lotrimin AF Athletes Foot Antifungal Powder",
        "Lotrimin AF Athletes Foot Daily Prevention Medicated Foot Powder",
        "Lotrimin AF Jock Itch Antifungal Cream",
        "Lotrimin AF Ringworm Cream",
        "Lotrimin Daily Sweat and Odor Control Medicated Foot Powder",
        "Lotrimin Ultra Athletes Foot Cream",
        "Lotrimin Ultra Jock Itch Cream"
    ],
    "Lotronex": ["Lotronex"],
    "Lubriderm": [
        "Lubriderm Advanced Therapy Lotion Products",
        "Lubriderm Daily Moisture Lotion Products",
        "Lubriderm Intense Skin Repair Lotion",
        "Lubriderm Mens Products",
        "Lubriderm Therapeutic Lotion Products"
    ],
    "Lucemyra": ["Lucemyra"],
    "Lucentis": ["Lucentis"],
    "Lumakras": ["Lumakras"],
    "Lumify": ["Lumify"],
    "Lumigan": ["Lumigan"],
    "Lumizyme": ["Lumizyme"],
    "Lupron Depot": [
        "Lupron Depot",
        "Lupron Depot-PED"
    ],
    "Luzu": ["Luzu"],
    "Lybalvi": ["Lybalvi"],
    "Lynparza": ["Lynparza"],
    "Lyrica": ["Lyrica"],
    "Lyumjev": [
        "Lyumjev",
        "Lyumjev U-100 KwikPen",
        "Lyumjev U-200 KwikPen"
    ],
    "Macrilen": ["Macrilen"],
    "Makena": ["Makena"],
    "Margenza": ["Margenza"],
    "Marqibo": ["Marqibo"],
    "Mavenclad": ["Mavenclad"],
    "Mavyret": ["Mavyret"],
    "Mayzent": ["Mayzent"],
    "Mederma": [
        "Mederma Advanced Scar Gel",
        "Mederma for Kids",
        "Mederma PM Intensive Overnight Scar Cream",
        "Mederma Quick Dry Oil",
        "Mederma Scar Cream Plus SPF 30",
        "Mederma Stretch Marks Therapy"
    ],
    "Mekinist": ["Mekinist"],
    "Mektovi": ["Mektovi"],
    "Menest": ["Menest"],
    "Menopur": ["Menopur"],
    "Mepsevii": ["Mepsevii"],
    "Metamucil": [
        "Metamucil Fiber Capsules",
        "Metamucil Fiber Gummies",
        "Metamucil Fiber MultiGrain Wafers",
        "Metamucil Fiber Powder",
        "Metamucil Fiber Singles"
    ],
    "Metanx": ["Metanx"],
    "MicroSkin Ostomy Pouching Systems": ["MicroSkin Ostomy Pouching Systems"],
    "Midol": ["Midol Complete Products", "Midol Long Lasting Relief"],
    "MimyX": ["MimyX"],
    "Minolira": ["Minolira"],
    "MiraLAX": ["MiraLAX", "MiraLAX Mix-In Pax"],
    "Mitigare": ["Mitigare"],
    "Monistat": [
        "Monistat 1 Pre-filled ointment",
        "Monistat 3-Day Treatment Combination Pack with Ovule",
        "Monistat 3-Day Treatment Combination Pack with pre-filled cream",
        "Monistat 3-Day Treatment Combination Pack with suppositories",
        "Monistat 3-Day Treatment Cream",
        "Monistat 7-Day Treatment",
        "Monistat 7-Day Treatment Combination Pack"
    ],
    "Monjuvi": ["Monjuvi"],
    "Monoferric": ["Monoferric"],
    "Morgidox": ["Morgidox"],
    "Motegrity": ["Motegrity"],
    "Motofen": ["Motofen"],
    "Motrin": [
        "Motrin Childrens Chewable Tablets",
        "Motrin Childrens Oral Suspension",
        "Motrin IB Caplet",
        "Motrin IB Liquid Gels",
        "Motrin IB Migraine Liquid Gels",
        "Motrin Infants",
        "Motrin PM"
    ],
    "Mounjaro": ["Mounjaro Injection"],
    "Movantik": ["Movantik"],
    "Mucinex": [
        "Mucinex Childrens Cold and Flu",
        "Mucinex Childrens Cough Mini-Melts",
        "Mucinex Childrens FreeFrom Products",
        "Mucinex Childrens Multi-Symptom",
        "Mucinex Extended-Release Bi-Layer Tablets",
        "Mucinex Fast-Max Caplets",
        "Mucinex Fast-Max DM MAX Products",
        "Mucinex Fast-Max Liqui-Gels Products",
        "Mucinex Fast-Max Liquid Products",
        "Mucinex Nightshift Products",
        "Mucinex Severe Congestion Relief Nasal Spray",
        "Mucinex Sinus-Max Caplet Products",
        "Mucinex Sinus-Max Liqui-Gels"
    ],
    "Multaq": ["Multaq"],
    "Multi-Betic Diabetes Multi-Vitamin": ["Multi-Betic Diabetes Multi-Vitamin"],
    "Muse": ["Muse"],
    "Mvasi": ["Mvasi"],
    "Mycapssa": ["Mycapssa"],
    "Mycobutin": ["Mycobutin"],
    "Mydayis": ["Mydayis"],
    "Myfembree": ["Myfembree"],
    "Myfortic": ["Myfortic"],
    "Myobloc": ["Myobloc"],
    "Myrbetriq": ["Myrbetriq"],
    "Mytesi": ["Mytesi"],
    "Naftin": ["Naftin"],
    "Nalfon": ["Nalfon"],
    "Namzaric": ["Namzaric"],
    "Naphcon A": ["Naphcon A"],
    "Naprelan": ["Naprelan"],
    "Narcan": ["Narcan"],
    "Nasacort": [
        "Nasacort Allergy 24 HR Spray",
        "Nasacort Childrens Allergy 24HR"
    ],
    "Nascobal": ["Nascobal"],
    "Natazia": ["Natazia"],
    "Natesto": ["Natesto"],
    "Nauzene": ["Nauzene Chewables", "Nauzene Kids"],
    "Nayzilam": ["Nayzilam"],
    "NeevoDHA": ["NeevoDHA"],
    "Neo-Synalar": ["Neo-Synalar"],
    "Neoral": ["Neoral"],
    "Neosporin": [
        "Neosporin Burn Relief and First Aid Antibiotic Ointment",
        "Neosporin First Aid Antiseptic Foaming Liquid for Kids",
        "Neosporin Neo To Go Products",
        "Neosporin Original Ointment",
        "Neosporin plus Pain Itch and Scar",
        "Neosporin Plus Pain Relief Cream",
        "Neosporin Plus Pain Relief Ointment",
        "Neosporin Three Ingredient Antibiotic Ointment"
    ],
    "Nerlynx": ["Nerlynx"],
    "Nestabs": ["Nestabs", "Nestabs DHA", "Nestabs ONE"],
    "Neuac": ["Neuac"],
    "Neulasta": ["Neulasta", "Neulasta Onpro Kit"],
    "Neupogen": ["Neupogen"],
    "Neupro": ["Neupro"],
    "Neutrogena": [
        "Neutrogena Body Clear Products",
        "Neutrogena Clear Pore Products",
        "Neutrogena Deep Moisture Products",
        "Neutrogena Gentle Products",
        "Neutrogena Hydro Boost Products",
        "Neutrogena Naturals Products",
        "Neutrogena Oil Free Products",
        "Neutrogena Rapid Clear Acne Products",
        "Neutrogena Skin Balancing Products",
        "Neutrogena Stubborn Acne Treatment Products",
        "Neutrogena T-Gel Hair and Scalp Products"
    ],
    "New-Skin": [
        "New-Skin All-in-One Liquid Bandage",
        "New-Skin All-in-One Liquid Spray Bandage",
        "New-Skin Liquid Bandage Botanicals",
        "New-Skin Liquid Bandage Sensitive Skin",
        "New-Skin Scar Fade"
    ],
    "Nexavar": ["Nexavar"],
    "Nexcare": [
        "Nexcare Acne Blemish Covers",
        "Nexcare Bandages",
        "Nexcare Eye Patch Products",
        "Nexcare Hot and Cold Compress Products",
        "Nexcare Skin Crack Care",
        "Nexcare Tape and Wrap Products",
        "Nexcare Wound and Dressing Products"
    ],
    "Nexium": ["Nexium"],
    "Nexletol": ["Nexletol"],
    "Nexlizet": ["Nexlizet"],
    "Nextstellis": ["Nextstellis"],
    "Nexviazyme": ["Nexviazyme"],
    "Nicadan": ["Nicadan"],
    "Nicaprin": ["Nicaprin"],
    "NicoDerm CQ": ["NicoDerm CQ Step 1", "NicoDerm CQ Step 2", "NicoDerm CQ Step 3"],
    "Nicomide": ["Nicomide"],
    "Nicorette": ["Nicorette Coated Lozenge", "Nicorette Gum", "Nicorette Lozenge", "Nicorette Mini Lozenge"],
    "Nicotrol": ["Nicotrol"],
    "Niferex": ["Niferex"],
    "Ninlaro": ["Ninlaro"],
    "NitroMist": ["NitroMist"],
    "Nityr": ["Nityr"],
    "Nivestym": ["Nivestym"],
    "Nocdurna": ["Nocdurna"],
    "Nolix": ["Nolix"],
    "NonyX Nail Gel": ["NonyX Nail Gel"],
    "Norditropin": ["Norditropin"],
    "Norel AD": ["Norel AD"],
    "Norethindrone Acetate": ["Norethindrone Acetate"],
    "Noritate": ["Noritate"],
    "Norpace": ["Norpace"],
    "Northera": ["Northera"],
    "Norvasc": ["Norvasc"],
    "Norvir": ["Norvir"],
    "Nourianz": ["Nourianz"],
    "Novarel": ["Novarel"],
    "Novoeight": ["Novoeight"],
    "NovoFine": ["NovoFine Needles", "NovoFine Plus 32G"],
    "Novolin": ["Novolin 70-30", "Novolin 70-30 FlexPen", "Novolin N", "Novolin R"],
    "NovoLog": ["NovoLog", "NovoLog Mix 70-30"],
    "NovoPen Echo": ["NovoPen Echo"],
    "NovoSeven RT": ["NovoSeven RT"],
    "NovoTwist 32G": ["NovoTwist 32G"],
    "Nplate": ["Nplate"],
    "Nubeqa": ["Nubeqa"],
    "Nucala": ["Nucala"],
    "Nucynta": ["Nucynta", "Nucynta ER"],
    "Nuedexta": ["Nuedexta"],
    "Nulibry": ["Nulibry"],
    "Nulojix": ["Nulojix"],
    "Nuplazid": ["Nuplazid"],
    "Nurtec ODT": ["Nurtec ODT"],
    "Nutramigen": ["Nutramigen with Enflora LGG", "Nutramigen with Enflora LGG Toddler"],
    "Nutropin AQ": ["Nutropin AQ", "Nutropin AQ NuSpin"],
    "Nuvail": ["Nuvail"],
    "Nuvessa": ["Nuvessa"],
    "Nuvigil": ["Nuvigil"],
    "Nuwiq": ["Nuwiq"],
    "OB Complete": ["OB Complete One", "OB Complete Petite", "OB Complete Premier"],
    "Obizur": ["Obizur"],
    "Ocaliva": ["Ocaliva"],
    "Ocrevus": ["Ocrevus"],
    "Ocuvite": [
        "Ocuvite Adult 50 Eye Vitamins",
        "Ocuvite Blue Light",
        "Ocuvite Eye Health Formula",
        "Ocuvite Eye Health Gummies",
        "Ocuvite Eye Performance",
        "Ocuvite Eye+Multi",
        "Ocuvite Lutein 25",
        "Ocuvite Lutien",
        "Ocuvite Lutien and Zeaxanthin"
    ],
    "Odactra": ["Odactra"],
    "Odefsey": ["Odefsey"],
    "Odomzo": ["Odomzo"],
    "Ofev": ["Ofev"],
    "Ogivri": ["Ogivri"],
    "Olumiant": ["Olumiant"],
    "Omeclamox-Pak": ["Omeclamox-Pak"],
    "Omidria": ["Omidria"],
    "Omnipod": ["Omnipod"],
    "Omnitrope": ["Omnitrope"],
    "One-A-Day": [
        "One-A-Day Adult Multivitamin products",
        "One-A-Day Kids Multivitamin products",
        "One-A-Day Mens Multivitamin products",
        "One-A-Day Prenatal Multivitamin products",
        "One-A-Day Teen Multivitamin products",
        "One-A-Day Womens Multivitamin products"
    ],
    "OneTouch": [
        "OneTouch Ultra",
        "OneTouch Verio",
        "OneTouch Verio Flex",
        "OneTouch Verio Gold",
        "OneTouch Verio Reflect Meter"
    ],
    "Onexton": ["Onexton"],
    "Onfi": ["Onfi"],
    "Ongentys": ["Ongentys"],
    "Onglyza": ["Onglyza"],
    "Onivyde": ["Onivyde"],
    "Onureg": ["Onureg"],
    "Opdivo": ["Opdivo", "Opdivo Yervoy Regimen"],
    "Opsumit": ["Opsumit"],
    "Opti-Free": [
        "Opti-Free Express Rewetting Drops",
        "Opti-Free PureMoist Contact Lens Solution",
        "Opti-Free PureMoist Rewetting Drops",
        "Opti-Free Replenish Contact Lens Solution",
        "Opti-Free Replenish Rewetting Drops"
    ],
    "Opzelura": ["Opzelura"],
    "Oracea": ["Oracea"],
    "Oralair": ["Oralair"],
    "Orencia": ["Orencia"],
    "Orenitram": ["Orenitram"],
    "Orfadin": ["Orfadin"],
    "Orgovyx": ["Orgovyx"],
    "Oriahnn": ["Oriahnn"],
    "Orilissa": ["Orilissa"],
    "Orkambi": ["Orkambi"],
    "Orphengesic Forte": ["Orphengesic Forte"],
    "Ortikos": ["Ortikos"],
    "Oscillo": ["Oscillo", "Oscillococcinum"],
    "Osphena": ["Osphena"],
    "Ostaway x-Bag": ["Ostaway x-Bag"],
    "Osteo Bi-Flex": [
        "Osteo Bi-Flex Ease",
        "Osteo Bi-Flex One Per Day",
        "Osteo Bi-Flex Pain Relieving Cream",
        "Osteo Bi-Flex Thera-Soak Epsom Salt",
        "Osteo Bi-Flex Triple Strength Products"
    ],
    "Otezla": ["Otezla"],
    "Otovel": ["Otovel"],
    "Otrexup": ["Otrexup"],
    "Ovace Plus Products": ["Ovace Plus Products"],
    "Ovidrel": ["Ovidrel"],
    "Oxistat": ["Oxistat"],
    "Oxtellar XR": ["Oxtellar XR"],
    "OxyContin": ["OxyContin"],
    "Ozempic": ["Ozempic", "Ozempic 0.25 mg", "Ozempic 0.5 mg"],
    "Ozurdex": ["Ozurdex"],
    "Padcev": ["Padcev"],
    "Palforzia": ["Palforzia"],
    "Palynziq": ["Palynziq"],
    "Pancreaze": ["Pancreaze"],
    "Pandel": ["Pandel"],
    "Panhematin": ["Panhematin"],
    "PanOxyl": ["PanOxyl Acne Cleansing Bar", "PanOxyl Acne Creamy Wash", "PanOxyl Acne Foaming Wash"],
    "Parodontax": ["Parodontax Active Gum Repair", "Parodontax Complete Protection Products"],
    "Pataday": ["Pataday Extra Strength Once Daily Relief", "Pataday Once Daily Relief", "Pataday Twice Daily Relief"],
    "PediaSure": [
        "PediaSure Enteral 1.0 Cal",
        "PediaSure Enteral 1.0 Cal w-Fiber",
        "PediaSure Enteral 1.5 Cal",
        "PediaSure Enteral 1.5 Cal w-Fiber",
        "PediaSure Grow and Gain",
        "PediaSure Grow and Gain Organic",
        "PediaSure Grow and Gain Shake Mix",
        "PediaSure Grow and Gain with Fiber",
        "PediaSure Harvest",
        "PediaSure Peptide 1.0 Cal",
        "PediaSure Peptide 1.5 Cal",
        "PediaSure Pro",
        "PediaSure SideKicks"
    ],
    "Pemazyre": ["Pemazyre"],
    "Pennsaid": ["Pennsaid"],
    "Pepaxto": ["Pepaxto"],
    "Pepcid": [
        "Pepcid AC Maximum Strength",
        "Pepcid AC Original Strength",
        "Pepcid Complete",
        "Pepcid Complete On-The-Go"
    ],
    "Perforomist": ["Perforomist"],
    "Perjeta": ["Perjeta"],
    "Perseris": ["Perseris"],
    "Pertzye": ["Pertzye"],
    "Pfizerpen": ["Pfizerpen"],
    "Phesgo": ["Phesgo"],
    "Phexxi": ["Phexxi"],
    "Phillips": [
        "Phillips Colon Health Probiotic Capsules",
        "Phillips Fiber Good Gummies",
        "Phillips Laxative Caplets",
        "Phillips Milk of Magnesia Products",
        "Phillips Pro-Regularity",
        "Phillips Stool Softner Liquid Gels"
    ],
    "Pifeltro": ["Pifeltro"],
    "Piqray": ["Piqray"],
    "Plan B One-Step": ["Plan B One-Step"],
    "Plegridy": ["Plegridy"],
    "Plenvu": ["Plenvu"],
    "Plexion Products": ["Plexion Products"],
    "Poise Products": ["Poise Products"],
    "Polident": [
        "Polident 3-Minute Denture Cleanser",
        "Polident Clean and Protect for Partials",
        "Polident Dentu-Creme Cleanser",
        "Polident Overnight Whitening",
        "Polident Pro Guard and Retainer Anti-Bacterial Daily Cleanser Tablets",
        "Polident Pro Guard and Retainer Foam Cleanser",
        "Polident Pro Guard and Retainer Paste Cleanser",
        "Polident Pro Guard and Retainer Starter Kit",
        "Polident ProPartials Foam Cleanser",
        "Polident ProPartials Mouthwash",
        "Polident ProPartials Toothpaste",
        "Polident Smokers",
        "Polident Ultra Power Denture Cleanser"],
    "Polivy": ["Polivy"],
    "Polysporin": ["Polysporin"],
    "Ponvory": ["Ponvory"],
    "Portrazza": ["Portrazza"],
    "Poteligeo": ["Poteligeo"],
    "Pradaxa": ["Pradaxa"],
    "Praluent": ["Praluent"],
    "Pramosone": ["Pramosone"],
    "Pred Forte": ["Pred Forte"],
    "Premarin": ["Premarin"],
    "Premphase": ["Premphase"],
    "Prempro": ["Prempro"],
    "Prena1": ["Prena1 Pearl", "Prena1 True"],
    "Prenate": [
        "Prenate Chewables",
        "Prenate DHA",
        "Prenate Elite",
        "Prenate Enhance",
        "Prenate Essential with Quatrefolic",
        "Prenate Mini",
        "Prenate Pixie",
        "Prenate Restore"
    ],
    "Preparation H": [
        "Preparation H Cooling Gel",
        "Preparation H Medicated Wipes",
        "Preparation H Medicated Wipes for Women",
        "Preparation H Multi-Symptom Pain Relief Cream",
        "Preparation H Ointment",
        "Preparation H Rapid Relief Totables Wipes",
        "Preparation H Rapid Relief with Lidocaine Cream",
        "Preparation H Soothing Relief Anti-Itch Cream",
        "Preparation H Soothing Relief Cleansing and Cooling Wipes",
        "Preparation H Soothing Relief Cooling Spray",
        "Preparation H Suppositories",
        "Preparation H Totables"
    ],
    "PreserVision": [
        "PreserVision Eye Vitamin AREDS 2 Formula and Multivitamin",
        "PreserVision Eye Vitamin AREDS 2 Formula Chewables",
        "PreserVision Eye Vitamin AREDS 2 Formula MiniGels",
        "PreserVision Eye Vitamin AREDS Formula Soft Gels",
        "PreserVision Eye Vitamin AREDS Formula Tablets",
        "PreserVision Eye Vitamin AREDS Lutien Formula Soft Gels"
    ],
    "Prevacid 24HR": ["Prevacid 24HR"],
    "Prevail Incontinence Products": ["Prevail Incontinence Products"],
    "Prevymis": ["Prevymis"],
    "Prezcobix": ["Prezcobix"],
    "Prezista": ["Prezista"],
    "Prialt": ["Prialt"],
    "Prilosec": ["Prilosec OTC", "Prilosec Packets"],
    "PrimaCare": ["PrimaCare"],
    "Primlev": ["Primlev"],
    "Pristiq": ["Pristiq"],
    "Privigen": ["Privigen"],
    "ProAir Digihaler": ["ProAir Digihaler"],
    "ProCentra": ["ProCentra"],
    "ProCort": ["ProCort"],
    "Prograf": ["Prograf"],
    "Prolensa": ["Prolensa"],
    "Prolia": ["Prolia"],
    "Promacta": ["Promacta"],
    "Prosacea": ["Prosacea"],
    "Protonix": ["Protonix"],
    "Provenge": ["Provenge"],
    "Provera": ["Provera"],
    "Provida": ["Provida DHA", "Provida OB"],
    "Psoriasin Products": ["Psoriasin Products"],
    "Pulmicort Flexhaler": ["Pulmicort Flexhaler"],
    "Pulmozyme": ["Pulmozyme"],
    "PureVision2": [
        "PureVision2 Contact Lenses",
        "PureVision2 for Astigmatism",
        "PureVision2 for Presbyopia"
    ],
    "Qbrelis": ["Qbrelis"],
    "Qbrexza": ["Qbrexza"],
    "Qdolo": ["Qdolo"],
    "Qelbree": ["Qelbree"],
    "QNASL": ["QNASL"],
    "Qsymia": ["Qsymia"],
    "Qtern": ["Qtern"],
    "Qudexy XR": ["Qudexy XR"],
    "Quillichew ER": ["Quillichew ER"],
    "Quillivant XR": ["Quillivant XR"],
    "Qulipta": ["Qulipta"],
    "Qutenza": ["Qutenza 8% Topical Patch"],
    "Quzyttir": ["Quzyttir"],
    "QVAR RediHaler": ["QVAR RediHaler"],
    "Radicava": ["Radicava"],
    "Ragwitek": ["Ragwitek"],
    "Rapamune": ["Rapamune"],
    "Rasuvo": ["Rasuvo"],
    "Rayos": ["Rayos"],
    "Rebif": ["Rebif"],
    "Reblozyl": ["Reblozyl"],
    "Rebyota": ["Rebyota Rectal"],
    "Recombinate": ["Recombinate"],
    "RectiCare": [
        "RectiCare Advanced Hemorrhoidal Cream",
        "RectiCare Anorectal Cream Products",
        "RectiCare Medicated Anorectal Wipes"
    ],
    "RediTrex": ["RediTrex"],
    "Refresh": [
        "Refresh Celluvisc",
        "Refresh Classic",
        "Refresh Digital",
        "Refresh Digital Preservative Free",
        "Refresh Lacri-Lube Eye Ointment",
        "Refresh Liquigel",
        "Refresh Optive Advanced",
        "Refresh Optive Advanced Preservative Free",
        "Refresh Optive Eye Drops",
        "Refresh Optive Gel Drops",
        "Refresh Optive Mega-3 Preservative Free",
        "Refresh Optive Preservative Free",
        "Refresh P.M. Ointment",
        "Refresh Plus",
        "Refresh Relieva",
        "Refresh Relieva for Contacts",
        "Refresh Relieva PF Multidose",
        "Refresh Relieva Preservative Free",
        "Refresh Tears"
    ],
    "ReliOn": [
        "ReliOn Novolin 70/30 Insulin FlexPen",
        "ReliOn Novolin 70/30 Insulin Vial",
        "ReliOn Novolin N Insulin FlexPen",
        "ReliOn Novolin N Insulin Vial",
        "ReliOn Novolin R Insulin FlexPen",
        "ReliOn Novolin R Insulin Vial",
        "ReliOn Novolog"
    ],
    "Relistor": ["Relistor"],
    "Relizorb": ["Relizorb"],
    "Relpax": ["Relpax"],
    "Remicade": ["Remicade"],
    "Remodulin": ["Remodulin"],
    "Renflexis": ["Renflexis"],
    "Renvela": ["Renvela"],
    "Repatha": ["Repatha"],
    "RepHresh": ["RepHresh", "RepHresh Pro-B"],
    "Replens": ["Replens"],
    "Restasis": ["Restasis", "Restasis Multidose"],
    "Restora": ["Restora", "Restora RX", "Restora Sprinkles"],
    "Restylane": [
        "Restylane",
        "Restylane Contour",
        "Restylane Defyne",
        "Restylane Kysse",
        "Restylane Lyft",
        "Restylane Refyne",
        "Restylane Silk"
    ],
    "Retevmo": ["Retevmo"],
    "Rethymic": ["Rethymic"],
    "Retin-A MICRO": ["Retin-A MICRO"],
    "Retrovir": ["Retrovir"],
    "Revatio": ["Revatio"],
    "Revcovi": ["Revcovi"],
    "Rexulti": ["Rexulti"],
    "Reyvow": ["Reyvow"],
    "RhinAllergy": ["RhinAllergy"],
    "Rhinocort": ["Rhinocort"],
    "Rhofade": ["Rhofade"],
    "Rhopressa": ["Rhopressa"],
    "Riabni": ["Riabni"],
    "RID": [
        "RID Daily Defense Shampoo and Conditioner",
        "RID Home Lice Bedbug and Dust Mite Spray Kit",
        "RID Lice Treatment Complete Kit",
        "RID SUPER MAX Complete Lice Elimination Kit"
    ],
    "Rinvoq": ["Rinvoq"],
    "Riomet ER": ["Riomet ER"],
    "Risperdal Consta": ["Risperdal Consta"],
    "Rituxan": ["Rituxan", "Rituxan Hycela"],
    "Rixubis": ["Rixubis"],
    "Robitussin": [
        "Robitussin 12 Hour Cough and Mucus Extended Release Tablet",
        "Robitussin 12 Hour Cough Relief",
        "Robitussin Children's Cough and Chest Congestion DM",
        "Robitussin Children's Cough and Cold CF",
        "Robitussin Childrens DM Day Night Pack",
        "Robitussin Childrens Elderberry Cough plus Chest Congestion DM",
        "Robitussin Childrens Extended Release 12 Hour Cough Relief",
        "Robitussin Childrens Honey Cough and Chest Congestion DM",
        "Robitussin Childrens Naturals Cough Relief Products",
        "Robitussin Cough and Chest Congestion DM",
        "Robitussin Cough plus Chest Congestion DM",
        "Robitussin Cough plus Chest Congestion DM Liquid Filled Capsules",
        "Robitussin DM Day Night Pack",
        "Robitussin Elderberry Cough plus Congestion DM",
        "Robitussin Honey Cough and Chest Congestion DM",
        "Robitussin Honey Nighttime Cough DM",
        "Robitussin Long-Acting CoughGels",
        "Robitussin Multi-Symptom Cold CF",
        "Robitussin Naturals Cough Relief Products",
        "Robitussin Nighttime Cough DM",
        "Robitussin Severe Cough plus Sore Throat",
        "Robitussin Severe Multi-Symptom Cough Cold Flu",
        "Robitussin Severe Multi-Symptom Cough Cold Flu Nighttime",
        "Robitussin Severe Multi-Symptom Cough Cold Flu Pack",
        "Robitussin Sugar-Free Cough+Chest Congestion DM"
    ],
    "Rocklatan": ["Rocklatan"],
    "Rogaine": [
        "Rogaine for Men Solution",
        "Rogaine for Men Unscented Foam",
        "Rogaine for Woman Unscented Foam",
        "Rogaine for Women Solution"
    ],
    "Rolaids": [
        "Rolaids Advanced Antacid Plus Anti Gas Chewable Tablets",
        "Rolaids Advanced Antacid Plus Anti Gas Softchews",
        "Rolaids Extra Strength Chewable Tablets",
        "Rolaids Ultra Strength Chewable Tablets",
        "Rolaids Ultra Strength Softchews"
    ],
    "Rosadan": ["Rosadan"],
    "Roszet": ["Roszet"],
    "Rozlytrek": ["Rozlytrek"],
    "Rubraca": ["Rubraca"],
    "Ruconest": ["Ruconest"],
    "Rukobia": ["Rukobia"],
    "Ruxience": ["Ruxience"],
    "Ruzurgi": ["Ruzurgi"],
    "Rybelsus": ["Rybelsus"],
    "Rybrevant": ["Rybrevant"],
    "Rydapt": ["Rydapt"],
    "Rylaze": ["Rylaze"],
    "Rytary": ["Rytary"],
    "Saizen": ["Saizen"],
    "Samsca": ["Samsca"],
    "Sancuso": ["Sancuso"],
    "Sandimmune": ["Sandimmune"],
    "Sandostatin LAR Depot": ["Sandostatin LAR Depot"],
    "Santyl": ["Santyl"],
    "Saphnelo": ["Saphnelo"],
    "Saphris": ["Saphris"],
    "Sarclisa": ["Sarclisa"],
    "Sarna": ["Sarna Original Lotion", "Sarna Sensitive Maximum Strength Lotion"],
    "Savaysa": ["Savaysa"],
    "Savella": ["Savella"],
    "Saxenda": ["Saxenda"],
    "Scemblix": ["Scemblix"],
    "Sculptra": ["Sculptra"],
    "Secuado": ["Secuado"],
    "Segluromet": ["Segluromet"],
    "Select-OB DHA": ["Select-OB DHA"],
    "SelRx Shampoo": ["SelRx Shampoo"],
    "Selsun Blue": [
        "Selsun Blue 2 in 1 Antidandruff Shampoo and Conditioner",
        "Selsun Blue 3 in 1 Antidandruff Shampoo",
        "Selsun Blue Full and Thick Antidandruff Shampoo",
        "Selsun Blue Itchy Dry Scalp Antidandruff Shampoo",
        "Selsun Blue Medicated Antidandruff Shampoo",
        "Selsun Blue Moisturizing Antidandruff Shampoo",
        "Selsun Blue Naturals Antidandruff Shampoo"
    ],
    "Selzentry": ["Selzentry"],
    "Semglee": ["Semglee"],
    "Sensodyne": [
        "Sensodyne Complete Protection Products",
        "Sensodyne Essential Care Products",
        "Sensodyne Full Protection",
        "Sensodyne ProNamel Products",
        "Sensodyne Rapid Relief Products",
        "Sensodyne Repair and Protect Deep Repair Products",
        "Sensodyne Sensitivity and Gum Products",
        "Sensodyne Tartar Control"
    ],
    "SenSura": [
        "SenSura-Colostomy Pouches",
        "SenSura-Ileostomy Pouches",
        "SenSura-Urostomy Pouches"
    ],
    "Seroquel XR": ["Seroquel XR"],
    "Serostim": ["Serostim"],
    "Sevenfact": ["Sevenfact"],
    "Seysara": ["Seysara"],
    "Signifor LAR": ["Signifor LAR"],
    "Siklos": ["Siklos"],
    "Silenor": ["Silenor"],
    "Siliq": ["Siliq"],
    "Simbrinza": ["Simbrinza"],
    "Similac": [
        "Similac Advance Infant Formula Products",
        "Similac Alimentum Products",
        "Similac for Spit-Up Products",
        "Similac for Supplementation",
        "Similac Go and Grow Milk Based Toddler Drink Products",
        "Similac Go and Grow Organic Toddler Products",
        "Similac Go and Grow Sensitive Toddler Drink Products",
        "Similac Go and Grow Toddler Drink Products",
        "Similac NeoSure Infant Formula Products",
        "Similac Organic Infant Formula Products",
        "Similac Pro-Advance Infant Formula Products",
        "Similac Pro-Sensitive Infant Formula Products",
        "Similac Pro-Total Comfort Infant Formula Products",
        "Similac Pure Bilss Products",
        "Similac Sensitive Infant Formula Products",
        "Similac Soy Infant Formula"
    ],
    "Simponi": ["Simponi", "Simponi Aria"],
    "Sitavig": ["Sitavig"],
    "Sivextro": ["Sivextro"],
    "Skelaxin": ["Skelaxin"],
    "Skyrizi": ["Skyrizi"],
    "Skytrofa": ["Skytrofa"],
    "SleepCalm": ["SleepCalm"],
    "SlowMag MG": ["SlowMag MG Calm plus Sleep", "SlowMag MG Muscle plus Heart"],
    "Slynd": ["Slynd"],
    "SofLens": ["SofLens Daily Disposables by Bausch and Lomb"],
    "Soliqua": ["Soliqua"],
    "Soliris": ["Soliris"],
    "Solosec": ["Solosec"],
    "Soltamox": ["Soltamox"],
    "Somatuline Depot": ["Somatuline Depot"],
    "Soolantra": ["Soolantra"],
    "Sorilux": ["Sorilux"],
    "Sotyku 6mg Tabs": ["Sotyku 6mg Tabs"],
    "Sotylize": ["Sotylize"],
    "Sovaldi": ["Sovaldi"],
    "SpeediCath": ["SpeediCath Compact for Women", "SpeediCath Straight Tip for Men"],
    "Spevigo Injection": ["Spevigo Injection"],
    "Spinraza": ["Spinraza"],
    "Spiriva": ["Spiriva HandiHaler", "Spiriva Inhalers", "Spiriva Respimat"],
    "Spravato": ["Spravato"],
    "Spritam": ["Spritam"],
    "Sprix": ["Sprix"],
    "Sprycel": ["Sprycel"],
    "Steglatro": ["Steglatro"],
    "Steglujan": ["Steglujan"],
    "Stelara": ["Stelara"],
    "Stendra": ["Stendra"],
    "Sting-Kill Products": ["Sting-Kill Products"],
    "Stiolto Respimat": ["Stiolto Respimat"],
    "Stivarga": ["Stivarga"],
    "Stopain Cold": [
        "Stopain Cold Extra Strength Roll-On",
        "Stopain Cold Extra Strength Spray",
        "Stopain Cold Pain Relieving Gel"
    ],
    "Strensiq": ["Strensiq"],
    "Stribild": ["Stribild"],
    "Sublocade": ["Sublocade"],
    "Suboxone": ["Suboxone"],
    "Subsys": ["Subsys"],
    "Subvenite": ["Subvenite"],
    "Sudafed": [
        "Sudafed Childrens PE Cold and Cough",
        "Sudafed Childrens PE Nasal Decongestant",
        "Sudafed Congestion Products",
        "Sudafed PE Head Congestion and Pain Relief",
        "Sudafed PE Head Congestion plus Flu Severe",
        "Sudafed PE Head Congestion plus Mucus",
        "Sudafed PE Sinus Congestion",
        "Sudafed PE Sinus Congestion Day - Night",
        "Sudafed PE Sinus Pressure Pain",
        "Sudafed Sinus 12 Hour Pressure plus Pain"
    ],
    "Sumadan": ["Sumadan"],
    "Summers Eve": ["Summers Eve Cleansing Cloths"],
    "Sundown Vitamin and Supplement Products": ["Sundown Vitamin and Supplement Products"],
    "Sunosi": ["Sunosi"],
    "Supartz FX": ["Supartz FX"],
    "Super Poligrip": [
        "Super Poligrip Comfort Seal Strip",
        "Super Poligrip Cushion and Comfort",
        "Super Poligrip Extra Care",
        "Super Poligrip Extra Strength Powder",
        "Super Poligrip Free",
        "Super Poligrip Original",
        "Super Poligrip Ultra Fresh"
    ],
    "Supprelin LA": ["Supprelin LA"],
    "Suprep Bowel Prep Kit": ["Suprep Bowel Prep Kit"],
    "Sustol": ["Sustol"],
    "Susvimo": ["Susvimo"],
    "Sutab": ["Sutab"],
    "Sutent": ["Sutent"],
    "Sylvant": ["Sylvant"],
    "Symdeko": ["Symdeko"],
    "Symjepi": ["Symjepi"],
    "Symlin": ["Symlin"],
    "Sympazan": ["Sympazan"],
    "Symproic": ["Symproic"],
    "Symtuza": ["Symtuza"],
    "Synagis": ["Synagis"],
    "Synalar": ["Synalar"],
    "Synarel": ["Synarel"],
    "Syndros": ["Syndros"],
    "Synjardy": ["Synjardy", "Synjardy XR"],
    "Synthroid": ["Synthroid"],
    "Syprine": ["Syprine"],
    "Systane": [
        "Systane Balance",
        "Systane Contacts Lubricant Eye Drops",
        "Systane Day and Night Eye Drops",
        "Systane Gel Eye Drops",
        "Systane Hydration Preservative-Free",
        "Systane Lubricant Eye Drops",
        "Systane Preservative-Free Formula",
        "Systane Ultra",
        "Systane Ultra Preservative-Free"
    ],
    "Tabrecta": ["Tabrecta"],
    "Tafinlar": ["Tafinlar"],
    "Tagrisso": ["Tagrisso"],
    "Takhzyro": ["Takhzyro"],
    "Talicia": ["Talicia"],
    "Taltz": ["Taltz"],
    "Talzenna": ["Talzenna"],
    "Tandem": ["Tandem", "Tandem Plus"],
    "TaperDex": ["TaperDex"],
    "Targadox": ["Targadox"],
    "Targretin": ["Targretin"],
    "TASCENSO ODT": ["TASCENSO ODT orally disintegrating tablets"],
    "Tasigna": ["Tasigna"],
    "Tavalisse": ["Tavalisse"],
    "Tavneos": ["Tavneos"],
    "Taytulla": ["Taytulla"],
    "Tazorac": ["Tazorac"],
    "Tecentriq": ["Tecentriq"],
    "Tecfidera": ["Tecfidera"],
    "Temixys": ["Temixys"],
    "Tena Personal Care Products": ["Tena Personal Care Products"],
    "Tenormin": ["Tenormin"],
    "Tepmetko": ["Tepmetko"],
    "Tessalon": ["Tessalon"],
    "Test 1mg": ["Test 1mg"],
    "Texacort": ["Texacort"],
    "Theraflu": [
        "Theraflu Cough Relief",
        "Theraflu Day/Night Hot Liquid Powder Value Pack",
        "Theraflu Daytime Severe Cold / Cough",
        "Theraflu ExpressMax Day/Night Value Pack",
        "Theraflu ExpressMax Daytime Severe Cold / Cough",
        "Theraflu ExpressMax Nighttime Severe Cold / Cough",
        "Theraflu ExpressMax Severe Cold / Cough Value Pack",
        "Theraflu ExpressMax Severe Cold / Flu",
        "Theraflu Flu/Sore Throat",
        "Theraflu Multi-Symptom Severe Cold",
        "Theraflu Nighttime Multi-Symptom Severe Cold",
        "Theraflu Nighttime Severe Cold / Cough",
        "Theraflu Sinus / Pain"
    ],
    "TheraTears": [
        "TheraTears Dry Eye Therapy Eye Drops",
        "TheraTears Dry Eye Therapy Single Use Vials",
        "TheraTears Extra Dry Eye Therapy Eye Drops",
        "TheraTears Extra Dry Eye Therapy Single Use Vials",
        "TheraTears Eye Nutrition Supplement",
        "TheraTears Liquid Gel Nighttime Dry Eye Therapy",
        "TheraTears Sterilid Antimicrobial"
    ],
    "ThermaCare": ["ThermaCare Heat Wraps"],
    "ThroatCalm": ["ThroatCalm"],
    "Thyquidity": ["Thyquidity"],
    "Thyrogen": ["Thyrogen"],
    "Tibsovo": ["Tibsovo"],
    "Tiglutik": ["Tiglutik"],
    "Tikosyn": ["Tikosyn"],
    "Timoptic in Ocudose": ["Timoptic in Ocudose"],
    "Tirosint": ["Tirosint", "Tirosint-SOL"],
    "TissueBlue": ["TissueBlue"],
    "Tivicay": ["Tivicay", "Tivicay PD"],
    "Tivose": ["Tivose"],
    "Tobi": ["Tobi", "Tobi Podhaler"],
    "TobraDex ST": ["TobraDex ST"],
    "Tolsura": ["Tolsura"],
    "Topamax": ["Topamax"],
    "Topiramate": ["Topiramate"],
    "Tosymra": ["Tosymra"],
    "Toujeo": ["Toujeo", "Toujeo Solostar"],
    "Tovet": ["Tovet"],
    "Tracleer": ["Tracleer"],
    "Tradjenta": ["Tradjenta"],
    "Tranquility Premium Protection Products": ["Tranquility Premium Protection Products"],
    "Trazimera": ["Trazimera"],
    "Trecator": ["Trecator"],
    "Trelegy Ellipta": ["Trelegy Ellipta"],
    "Tremfya": ["Tremfya"],
    "Tresiba": ["Tresiba", "Tresiba U-100", "Tresiba U-100 FlexTouch", "Tresiba U-200 FlexTouch"],
    "Tretten": ["Tretten"],
    "Treximet": ["Treximet"],
    "Trezix": ["Trezix"],
    "Tri-Luma": ["Tri-Luma"],
    "Triaminic": [
        "Triaminic Cough and Congestion",
        "Triaminic Cough and Sore Throat",
        "Triaminic Day Time Cold and Cough",
        "Triaminic Multi-Symptom Fever",
        "Triaminic Night Time Cold and Cough"
    ],
    "Tribenzor": ["Tribenzor"],
    "Trijardy XR": ["Trijardy XR"],
    "Trikafta": ["Trikafta"],
    "Triluma": ["Triluma"],
    "Trintellix": ["Trintellix"],
    "Triptodur": ["Triptodur"],
    "Tritocin": ["Tritocin"],
    "Triumeq": ["Triumeq"],
    "Trizivir": ["Trizivir"],
    "Trodelvy": ["Trodelvy"],
    "Trokendi XR": ["Trokendi XR"],
    "TruBiotics Daily Probiotic Supplement": ["TruBiotics Daily Probiotic Supplement"],
    "Trudhesa": ["Trudhesa"],
    "True Metrix BGM": ["True Metrix BGM"],
    "Trulance": ["Trulance"],
    "Trulicity": ["Trulicity"],
    "Truseltiq": ["Truseltiq"],
    "Truvada": ["Truvada"],
    "Truxima": ["Truxima"],
    "Tudorza Pressair": ["Tudorza Pressair"],
    "Turalio": ["Turalio"],
    "Tuzistra XR": ["Tuzistra XR"],
    "Twirla": ["Twirla"],
    "Tybost": ["Tybost"],
    "Tygacil": ["Tygacil"],
    "Tykerb": ["Tykerb"],
    "Tylenol": [
        "Tylenol 8 Hr Arthritis Pain",
        "Tylenol 8 Hr Muscle Aches and Pain Caplets",
        "Tylenol Cold plus Flu Severe Products",
        "Tylenol Cold plus Head Congestion",
        "Tylenol Cold Plus Liquid Products",
        "Tylenol ColdMax Products",
        "Tylenol Extra Strength Caplets",
        "Tylenol Extra Strength Coated Tablets",
        "Tylenol Extra Strength Cold and Flu Multi Action Products",
        "Tylenol Extra Strength Dissolve Packs for Adults",
        "Tylenol Liquid Medicine for Children and Adults",
        "Tylenol PM Extra Strength Liquid",
        "Tylenol PM Products",
        "Tylenol Rapid Release Gels",
        "Tylenol Regular Strength Liquid Gels",
        "Tylenol Regular Strength Tablets",
        "Tylenol Simply Sleep",
        "Tylenol Sinus Products"
    ],
    "Tymlos": ["Tymlos"],
    "Tyrvaya": ["Tyrvaya"],
    "Tysabri": ["Tysabri"],
    "Tyvaso": ["Tyvaso"],
    "Ubrelvy": ["Ubrelvy"],
    "Uceris": ["Uceris"],
    "Udenyca": ["Udenyca"],
    "Ukoniq": ["Ukoniq"],
    "Ultomiris": ["Ultomiris"],
    "Ultravate": ["Ultravate"],
    "Unisom": [
        "Unisom PM Pain",
        "Unisom Simple Slumbers",
        "Unisom SleepGels",
        "Unisom SleepMelts",
        "Unisom SleepMinis",
        "Unisom SleepTabs"
    ],
    "Unithroid": ["Unithroid"],
    "Uptravi": ["Uptravi"],
    "Uribel": ["Uribel"],
    "Uricalm": ["Uricalm", "Uricalm Max"],
    "Urogesic Blue": ["Urogesic Blue"],
    "Utopic Cream": ["Utopic Cream"],
    "Valchlor": ["Valchlor"],
    "Valtoco": ["Valtoco"],
    "Varubi": ["Varubi"],
    "Vascepa": ["Vascepa"],
    "Vasculera": ["Vasculera"],
    "Vazalore": ["Vazalore"],
    "Vectibix": ["Vectibix"],
    "Velphoro": ["Velphoro"],
    "Veltin": ["Veltin"],
    "Vemlidy": ["Vemlidy"],
    "Venclexta": ["Venclexta"],
    "Verdeso": ["Verdeso"],
    "Veregen": ["Veregen"],
    "Verquvo": ["Verquvo"],
    "Verzenio": ["Verzenio"],
    "VGo": ["VGo"],
    "Viagra": ["Viagra"],
    "Viberzi": ["Viberzi"],
    "VICKS Products": [
        "VICKS Childrens Products",
        "VICKS DayQuil Products",
        "Vicks Night Time FluTherapy SEVERE Cold and Flu Treatment",
        "Vicks Non-Drowsy FluTherapy SEVERE Cold and Flu - Day Time",
        "VICKS NyQuil Products",
        "VICKS Sinex Products",
        "VICKS Vapo Products",
        "VICKS VapoCOOL Products"
    ],
    "Victoza": ["Victoza 1.2mg|1.8 Injection"],
    "Viibryd": ["Viibryd"],
    "Viltepso": ["Viltepso"],
    "Vimovo": ["Vimovo"],
    "Vimpat": ["Vimpat"],
    "Viracept": ["Viracept"],
    "Viread": ["Viread"],
    "Visine Products": [
        "Visine AC Itchy Eye Relief Astringent/Redness Reliever Eye Drops",
        "Visine Advanced Redness plus Irritation Relief",
        "Visine Allergy Eye Relief Multi-Action Antihistamine and Redness Reliever",
        "Visine Dry Eye Relief Products",
        "Visine For Contacts Lubricating plus Rewetting Drops",
        "Visine Maximum Strength Redness Relief Formula",
        "Visine Original Redness Relief",
        "Visine Red Eye Comfort Eye Drops",
        "Visine Red Eye Hydrating Comfort Eye Drops",
        "Visine Red Eye Total Comfort Multi-Symptom Eye Drops",
        "Visine Totality"
    ],
    "Vistaril": ["Vistaril"],
    "Vitafol Products": [
        "Vitafol",
        "Vitafol Fe plus",
        "Vitafol Gummies",
        "Vitafol Nano",
        "Vitafol One",
        "Vitafol Ultra",
        "Vitafol ULTRA-FirstStep",
        "Vitafol-OB DHA"
    ],
    "Vitafusion": [
        "Vitafusion Gummy Immune Support Products",
        "Vitafusion Gummy Kids Products",
        "Vitafusion Gummy Mens Multivitamin",
        "Vitafusion Gummy MultiVites",
        "Vitafusion Gummy Prenatal Vitamins",
        "Vitafusion Gummy Vitamin Products",
        "Vitafusion Gummy Womens Multivitamin Products"
    ],
    "vitaMedMD": ["vitaMedMD - One Rx", "vitaMedMD - RediChew Rx"],
    "vitaPearl": ["vitaPearl"],
    "vitaTrue": ["vitaTrue"],
    "Vitrakvi": ["Vitrakvi"],
    "Vivarin": ["Vivarin"],
    "Vivitrol": ["Vivitrol"],
    "Vizimpro": ["Vizimpro"],
    "Voltaren Arthritis Pain": ["Voltaren Arthritis Pain"],
    "Vonvendi": ["Vonvendi"],
    "Vosevi": ["Vosevi"],
    "Votrient": ["Votrient"],
    "Voxzogo": ["Voxzogo"],
    "VPRIV": ["VPRIV"],
    "Vraylar": ["Vraylar"],
    "Vtama 1% Cream": ["Vtama 1% Cream"],
    "Vumerity": ["Vumerity"],
    "Vyepti": ["Vyepti"],
    "Vyleesi": ["Vyleesi"],
    "Vyndamax": ["Vyndamax"],
    "Vyndaqel": ["Vyndaqel"],
    "Vytone Cream": ["Vytone Cream"],
    "Vyvanse": ["Vyvanse"],
    "Vyzulta": ["Vyzulta"],
    "Wakix": ["Wakix"],
    "Wegovy": ["Wegovy"],
    "Welchol": ["Welchol"],
    "Wellbutrin XL": ["Wellbutrin XL"],
    "Wilate": ["Wilate"],
    "Winlevi": ["Winlevi"],
    "Wixela Inhub": ["Wixela Inhub"],
    "Wynzora": ["Wynzora"],
    "Xadago": ["Xadago"],
    "Xalatan": ["Xalatan"],
    "Xalkori": ["Xalkori"],
    "Xanax": ["Xanax"],
    "Xarelto": ["Xarelto"],
    "Xatmep": ["Xatmep"],
    "Xeljanz": ["Xeljanz", "Xeljanz XR"],
    "Xelpros": ["Xelpros"],
    "Xembify": ["Xembify"],
    "Xenazine": ["Xenazine"],
    "Xenleta": ["Xenleta"],
    "Xeomin": ["Xeomin"],
    "Xepi": ["Xepi"],
    "Xerese": ["Xerese"],
    "Xermelo": ["Xermelo"],
    "Xgeva": ["Xgeva"],
    "Xhance": ["Xhance"],
    "Xiaflex": ["Xiaflex"],
    "Xifaxan": ["Xifaxan"],
    "Xigduo XR": ["Xigduo XR"],
    "Xiidra": ["Xiidra"],
    "Ximino": ["Ximino"],
    "Xofigo": ["Xofigo"],
    "Xofluza": ["Xofluza"],
    "Xolair": ["Xolair"],
    "Xolegel": ["Xolegel"],
    "Xospata": ["Xospata"],
    "Xpovio": ["Xpovio"],
    "Xtampza ER": ["Xtampza ER"],
    "Xtandi": ["Xtandi"],
    "Xulane": ["Xulane"],
    "Xultophy 100/3.6 Insulin": ["Xultophy 100/3.6 Insulin"],
    "Xyntha Solofuse": ["Xyntha Solofuse"],
    "Xyosted": ["Xyosted"],
    "Xyrem": ["Xyrem"],
    "Xywav": ["Xywav"],
    "Xyzal": ["Xyzal"],
    "Yervoy": ["Yervoy"],
    "Yondelis": ["Yondelis"],
    "Yonsa": ["Yonsa"],
    "Yupelri": ["Yupelri"],
    "Zaditor": ["Zaditor"],
    "Zarxio": ["Zarxio"],
    "Zegalogue": ["Zegalogue"],
    "Zegerid OTC": ["Zegerid OTC"],
    "Zejula": ["Zejula"],
    "Zelboraf": ["Zelboraf"],
    "Zemaira": ["Zemaira"],
    "Zembrace SymTouch": ["Zembrace SymTouch"],
    "Zenpep": ["Zenpep"],
    "Zenzedi": ["Zenzedi"],
    "Zeposia": ["Zeposia"],
    "Zepzelca": ["Zepzelca"],
    "Zerviate": ["Zerviate"],
    "Zestril": ["Zestril"],
    "Zevalin": ["Zevalin"],
    "Ziagen": ["Ziagen"],
    "Ziana": ["Ziana"],
    "Ziextenzo": ["Ziextenzo"],
    "Zilxi": ["Zilxi"],
    "ZImhi 5mg/0.5ml Inj": ["ZImhi 5mg/0.5ml Inj", "Zimhi 5mg/0.5ml Inj (Naloxone)"],
    "Zinplava": ["Zinplava"],
    "Zioptan": ["Zioptan"],
    "Zipsor": ["Zipsor"],
    "Zirabev": ["Zirabev"],
    "Zirgan": ["Zirgan"],
    "Zithranol Shampoo": ["Zithranol Shampoo"],
    "Zoladex": ["Zoladex"],
    "Zolgensma": ["Zolgensma"],
    "Zoloft": ["Zoloft"],
    "ZolpiMist": ["ZolpiMist"],
    "Zomig": ["Zomig", "Zomig-ZMT"],
    "Zontivity": ["Zontivity"],
    "Zortress": ["Zortress"],
    "Zovirax": ["Zovirax"],
    "ZTlido": ["ZTlido"],
    "Zubsolv": ["Zubsolv"],
    "Zulresso": ["Zulresso"],
    "Zuplenz": ["Zuplenz"],
    "Zydelig": ["Zydelig"],
    "Zykadia": ["Zykadia"],
    "Zylet": ["Zylet"],
    "Zynlonta": ["Zynlonta"],
    "Zypitamag": ["Zypitamag"],
    "Zyrtec": [
        "Zyrtec",
        "Zyrtec Allergy-Children's",
        "Zyrtec Childrens Dissolve Tabs",
        "Zyrtec Dissolve Tabs",
        "Zyrtec Liquid Gels",
        "Zyrtec Soothing Non-Medicated Face Wipes",
        "Zyrtec-D"
    ],
    "Zytiga": ["Zytiga"],
    "ZzzQuil": [
        "ZzzQuil Alcohol Free Liquid",
        "ZzzQuil Calming Vanilla Cherry",
        "ZzzQuil LiquiCaps",
        "ZzzQuil Night Pain GelTabs",
        "ZzzQuil Night Pain Liquid",
        "ZzzQuil Ultra",
        "ZzzQuil Warming Berry Liquid"
    ]

}

class CouponsOpen extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "brand",
                text: "Brand",
                className: "brand",
                align: "left",
                sortable: true,
                openLink: true
            },
            {
                key: "copay",
                text: "Copay",
                className: "copay",
                align: "left",
                sortable: true,
            },

            {
                key: "bin",
                text: "BIN",
                className: "bin",
                align: "left",
                sortable: true,
            },
            {
                key: "rxpcn",
                text: "RXPCN",
                className: "rxpcn",
                align: "left",
                sortable: true,
            },
            {
                key: "copay_max",
                text: "Copay Max",
                className: "copay_max",
                align: "left",
                sortable: true,
                currency: true
            },
            {
                key: "lifetime_uses",
                text: "Lifetime Uses",
                className: "lifetime_uses",
                align: "left",
                sortable: true,
            },
            {
                key: "max_saving_transaction",
                text: "Maximum Saving",
                className: "max_saving_transaction",
                align: "left",
                sortable: true,
                currency: true
            },
            {
                key: "description",
                text: "Description",
                className: "description",
                align: "left",
                sortable: true,
            }

        ];

        if (this.props.auth.user.superadmin) {
            this.columns.push({
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-coupon-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            })
        }

        this.config = {
            page_size: 20000,
            length_menu: [20000, 50, 100],
            filename: "Coupon List",
            no_data_text: 'No record found!',
            button: {
                excel: false,
                // print: false,
                print: false,
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        if (this.props.auth.user.superadmin) {
            this.config.button.csv = true
        }

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20000,
                sort_order: { column: "dea", order: "asc" }
            },
            currentRecord: {
            },
            brand: undefined,
            loading: false,
            isFilterOpen: true,
            linkd: {}
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    getData = () => {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.brand !== '' && { brand: this.state.brand }),
        }
        let recrdData = [
            {
                "_id": "6297bcabe0e0db6c2572aabe",
                "link": "https://www.abilify.com/using-the-savings-card.aspx",
                "description": "Abilify Savings Card: Eligible commercially insured patients may pay as little as $5 per 30-day supply; for additional information and to activate your card contact the program at 888-922-4543.",
                "brand": "Abilify",
                "copay": "Yes",
                "bin": "15251",
                "rxpcn": "0",
                "copay_max": "0.00",
                "lifetime_uses": "12",
                "max_saving_transaction": null,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aabe"
            },
            {
                "_id": "6297bcabe0e0db6c2572aabf",
                "link": "https://www.abilifymaintenahcp.com/?_ga=2.180525991.141578399.1595866109-1079957182.1595866109",
                "description": "Abilify Maintena Samples: Your healthcare provider may request samples by visiting the website or calling 844-873-2866.",
                "brand": "Abilify Maintena",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aabf"
            },
            {
                "_id": "6297bcabe0e0db6c2572aac0",
                "link": "https://www.abilifymaintena.com/bipolar-I/support-savings",
                "description": "Abilify Maintena Savings Card: Eligible commercially insured patients may pay as little as $10 per month with a maximum savings of $1400 per month; annual maximum benefit of $8,000; for additional information contact the program at 888-591-9812.",
                "brand": "Abilify Maintena",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "8000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1400,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aac0"
            },
            {
                "_id": "6297bcabe0e0db6c2572aac1",
                "link": "https://www.abilifymycite.com/support",
                "description": "Abilify Mycite Instant Copay Program: Eligible commercially insured patients may pay as little as $5 per month for the Abilify Mycite Kit; for more information contact the program at 844-692-4834.",
                "brand": "Abilify Mycite",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aac1"
            },
            {
                "_id": "6297bcabe0e0db6c2572aac2",
                "link": "https://www.abirateronesavings.com/patient-assistance/",
                "description": "Abiraterone Instant Savings Card: Eligible commercially insured patients may pay $5 per 30-day prescription with savings of up to $500 per fill; for additional information contact the program at 855-820-3230.",
                "brand": "Abiraterone Acetate",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "6000",
                "lifetime_uses": "12",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aac2"
            },
            {
                "_id": "6297bcabe0e0db6c2572aac5",
                "link": "https://www.absorbinejr.com",
                "description": "Register your email address to receive special offers for Absorbine Jr.; registration link is found at the bottom of the webpage.",
                "brand": "Absorbine Jr. Plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aac5"
            },
            {
                "_id": "6297bcabe0e0db6c2572aac3",
                "link": "https://www.bmsaccesssupport.bmscustomerconnect.com/patient/financial-support",
                "description": "Abraxane BMS Oncology Co-pay Assistance Program: Eligible commercially insured patients' may pay a $0 copay per infusion with savings of up to $10,000 per calendar year; for additional information contact the program at 800-861-0048.",
                "brand": "Abraxane",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aac3"
            },
            {
                "_id": "6297bcabe0e0db6c2572aac4",
                "link": "https://www.absorbinejr.com",
                "description": "Register your email address to receive special offers for Absorbine Jr.; registration link is found at the bottom of the webpage.",
                "brand": "Absorbine Jr. Back Patch",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aac4"
            },
            {
                "_id": "6297bcabe0e0db6c2572aac8",
                "link": "https://www.absoricald.com/savings-and-support",
                "description": "Absorica LD Copay Card Program: Eligible commercially insured patients may pay as little as $0 on each 30-capsule prescription; for additional information contact the program at 855-688-6815.",
                "brand": "Absorica LD",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "5340",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aac8"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaca",
                "link": "https://shop.accu-chek.com/eShop/subscriptions-2",
                "description": "Accu-Chek SimplePay: Patients may pay no more than $69.99 per month for up to 300 strips and receive a FREE meter (free meter only available with the first order); for additional information contact the program at 800-858-8072.",
                "brand": "Accu-Check SmartView Test Strips",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaca"
            },
            {
                "_id": "6297bcabe0e0db6c2572aacf",
                "link": "https://www.pfizermedicalinformation.com/en-us/accuretic",
                "description": "Accuretic Samples: Your healthcare provider may request samples; for additional information contact the program at 866-706-2400.",
                "brand": "Accuretic",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aacf"
            },
            {
                "_id": "6297bcabe0e0db6c2572aac6",
                "link": "https://www.absorbinejr.com",
                "description": "Register your email address to receive special offers for Absorbine Jr.; registration link is found at the bottom of the webpage.",
                "brand": "Absorbine Jr. Ultra Strength Pain Patch",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aac6"
            },
            {
                "_id": "6297bcabe0e0db6c2572aacc",
                "link": "https://hcp.accu-chek.com/accu-chek-delivers",
                "description": "Accu-Chek Samples: Your healthcare provider may order samples and free meter vouchers by contacting Roche Diabetes Care at 800-778-7505 or accuchekdelivers@roche.com.",
                "brand": "Accu-Chek Guide Me Test Strips",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aacc"
            },
            {
                "_id": "6297bcabe0e0db6c2572aad4",
                "link": "https://www.acebrand.com/3M/en_US/ace-brand/offers/",
                "description": "Sign-up with ACE Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "ACE Brand Elastic Bandages",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aad4"
            },
            {
                "_id": "6297bcabe0e0db6c2572aad9",
                "link": "https://www.acebrand.com/3M/en_US/ace-brand/offers/",
                "description": "Sign-up with ACE Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "ACE Brand Wrist Hand and Thumb Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aad9"
            },
            {
                "_id": "6297bcabe0e0db6c2572aade",
                "link": "https://www.actoralcare.com/act-for-adults/sign-up-save/",
                "description": "Save $1 on any one ACT product by registering your email address on the website; one coupon per purchase; coupon expires one month after printing.",
                "brand": "ACT Anticavity Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aade"
            },
            {
                "_id": "6297bcabe0e0db6c2572aae3",
                "link": "https://www.actoralcare.com/act-for-adults/sign-up-save/",
                "description": "Save $1 on any one ACT product by registering your email address on the website; one coupon per purchase; coupon expires one month after printing.",
                "brand": "ACT Dry Mouth Lozenges",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aae3"
            },
            {
                "_id": "6297bcabe0e0db6c2572aae8",
                "link": "https://actoralcareprofessional.com/samples-and-savings/",
                "description": "ACT Product Samples: Dental professionals may request free samples & coupons for use in their office by signing up for the ACT Professional Support Program.",
                "brand": "ACT Restoring",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aae8"
            },
            {
                "_id": "6297bcabe0e0db6c2572aad1",
                "link": "https://www.acebrand.com/3M/en_US/ace-brand/offers/",
                "description": "Sign-up with ACE Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "ACE Brand Ankle and Foot Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aad1"
            },
            {
                "_id": "6297bcabe0e0db6c2572aad6",
                "link": "https://www.acebrand.com/3M/en_US/ace-brand/offers/",
                "description": "Sign-up with ACE Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "ACE Brand Knee and Calf Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aad6"
            },
            {
                "_id": "6297bcabe0e0db6c2572aacb",
                "link": "https://www.accu-chek.com/special-offers",
                "description": "Accu-Chek SimplePay: Patients may pay no more than $69.99 per month for up to 300 strips and receive a FREE meter (free meter only available with the first order); for additional information contact the program at 800-858-8072.",
                "brand": "Accu-Chek Aviva Plus Test Strip",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aacb"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaed",
                "link": "https://actoralcareprofessional.com/samples-and-savings/",
                "description": "ACT Product Samples: Dental professionals may request free samples & coupons for use in their office by signing up for the ACT Professional Support Program.",
                "brand": "ACT Total Care Sensitive",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaed"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaf2",
                "link": "https://www.acuvue.com/membership-benefits",
                "description": "MyACUVUE Rewards Program: Sign-up and receive up to $250 in rewards per year with proof of an Acuvue purchase; program also includes customized reminders and exclusive offers.",
                "brand": "Acuvue Brand Contact Lenses",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaf2"
            },
            {
                "_id": "6297bcabe0e0db6c2572aad0",
                "link": "https://rxaccutane.com/patient-savings/",
                "description": "Accutane Patient Savings Card: Eligible commercially insured patients may pay as little as $0 per prescription; card may be used 12 times; for additional information contact the program at 480-434-6670.",
                "brand": "Accutane",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aad0"
            },
            {
                "_id": "6297bcabe0e0db6c2572aac9",
                "link": "https://portal.trialcard.com/accrufercopay/",
                "description": "Accrufer CoPay Savings Card: Eligible commercially insured patients will pay a little as $10 and no more than $25 per prescription; for additional information contact the program at 855-382-2533.",
                "brand": "Accrufer",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aac9"
            },
            {
                "_id": "6297bcabe0e0db6c2572aadb",
                "link": "https://www.boironusa.com/request-a-sample-form/",
                "description": "Acidil Samples: Healthcare professionals may request free samples for their practice by filling out a form on the website.",
                "brand": "Acidil",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aadb"
            },
            {
                "_id": "6297bcabe0e0db6c2572aae0",
                "link": "https://www.actoralcare.com/act-for-adults/sign-up-save/",
                "description": "Save $1 on any one ACT product by registering your email address on the website; one coupon per purchase; coupon expires one month after printing.",
                "brand": "ACT Braces Care",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aae0"
            },
            {
                "_id": "6297bcabe0e0db6c2572aae5",
                "link": "https://actoralcareprofessional.com/samples-and-savings/",
                "description": "ACT Product Samples: Dental professionals may request free samples & coupons for use in their office by signing up for the ACT Professional Support Program.",
                "brand": "ACT Dry Mouth Moisturizing Gum",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aae5"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaea",
                "link": "https://www.actoralcare.com/act-for-adults/sign-up-save/",
                "description": "Save $1 on any one ACT product by registering your email address on the website; one coupon per purchase; coupon expires one month after printing.",
                "brand": "ACT Total Care",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaea"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaef",
                "link": "https://acthar.com/acthar-patient-support#TnCcontent",
                "description": "Acthar Commercial Co-pay Program: Eligible commercially insured patients pay $0 copay with a maximum savings of $25,000 per calendar year; for additional information contact the program at 888-435-2284.",
                "brand": "Acthar Gel",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaef"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaf4",
                "link": "https://www.adcirca.com/patient/adcirca-copay-assistance-program.aspx",
                "description": "Adcirca Co-pay Assistance Program: Eligible commercially patients may pay as little as $5 per prescription on each of up to 12 prescriptions; maximum savings of up to $800 per monthly fill; enrollment in the program can be renewed each year; for additional information contact the program at 877-864-8437.",
                "brand": "Adcirca",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "9600",
                "lifetime_uses": "12",
                "max_saving_transaction": 800,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaf4"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaf7",
                "link": "https://addyi.com/getting-addyi/",
                "description": "Addyi Instant Savings Coupon: Most uninsured patients may save on their monthly prescriptions when using the coupon; for additional information contact the program at 855-280-0581.",
                "brand": "Addyi",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaf7"
            },
            {
                "_id": "6297bcabe0e0db6c2572aafc",
                "link": "https://www.adlyxin.com/copay-savings",
                "description": "Adlyxin Savings Card: Eligible commercially insured patients NEW to the Sanofi Rx Savings Program may pay no more than $15 on each of up to 12 prescriptions with savings of up to $700 per fill in a 12-month period; for additional information contact the program at 866-255-8661.",
                "brand": "Adlyxin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "8400",
                "lifetime_uses": "12",
                "max_saving_transaction": 700,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aafc"
            },
            {
                "_id": "6297bcabe0e0db6c2572aad5",
                "link": "https://www.acebrand.com/3M/en_US/ace-brand/offers/",
                "description": "Sign-up with ACE Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "ACE Brand Elbow and Arm Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aad5"
            },
            {
                "_id": "6297bcabe0e0db6c2572aada",
                "link": "https://www.boironusa.com/acidil-coupon/",
                "description": "$2 coupon off Acidil with registration; 1 coupon per purchase.",
                "brand": "Acidil",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aada"
            },
            {
                "_id": "6297bcabe0e0db6c2572aace",
                "link": "https://hcp.accu-chek.com/accu-chek-delivers",
                "description": "UNIVERSAL ID: 373824350  GROUP: 40026479",
                "brand": "Accu-Chek Guide Me FREE Meter",
                "copay": "No",
                "bin": "610524",
                "rxpcn": "1016",
                "copay_max": "0.00",
                "lifetime_uses": "0",
                "max_saving_transaction": null,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aace"
            },
            {
                "_id": "6297bcabe0e0db6c2572aadf",
                "link": "https://actoralcareprofessional.com/samples-and-savings/",
                "description": "ACT Product Samples: Dental professionals may request free samples & coupons for use in their office by signing up for the ACT Professional Support Program.",
                "brand": "ACT Anticavity Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aadf"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaf9",
                "link": "https://www.adhansiaxr.com/",
                "description": "Adhansia XR Savings Card: Eligible commercially insured patients may pay as little as $15 on each prescription; for additional information contact the program at 866-420-7719.",
                "brand": "Adhansia XR",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaf9"
            },
            {
                "_id": "6297bcabe0e0db6c2572aae4",
                "link": "https://www.actoralcare.com/act-for-adults/sign-up-save/",
                "description": "Save $1 on any one ACT product by registering your email address on the website; one coupon per purchase; coupon expires one month after printing.",
                "brand": "ACT Dry Mouth Moisturizing Gum",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aae4"
            },
            {
                "_id": "6297bcabe0e0db6c2572aafe",
                "link": "https://www.admelog.com/savings",
                "description": "Admelog Valyou Savings Program: Eligible uninsured cash-paying patients will pay $99 per monthly supply of up to 10 vials or packs of SoloStar pens per fill or up to 5 packs of Max SoloStar pens per fill; for additional information contact the program at 833-813-0190.",
                "brand": "Admelog",
                "copay": "No",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aafe"
            },
            {
                "_id": "6297bcabe0e0db6c2572aae9",
                "link": "https://www.actoralcare.com/act-for-adults/sign-up-save/",
                "description": "Save $1 on any one ACT product by registering your email address on the website; one coupon per purchase; coupon expires one month after printing.",
                "brand": "ACT Restoring",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aae9"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab03",
                "link": "https://respiratory.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any one Advil Cold & Allergy product with sign up.",
                "brand": "Advil Allergy Sinus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab03"
            },
            {
                "_id": "6297bcabe0e0db6c2572aac7",
                "link": "https://www.absorica.com/savings",
                "description": "Absorica Copay Card Program: Eligible commercially insured patients may pay as little as $25 on each prescription; valid for 5 fills; for additional information contact the program at 855-820-9189.",
                "brand": "Absorica",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "5",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aac7"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab08",
                "link": "https://childrens.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any Children's Advil product with sign up.",
                "brand": "Advil Infants Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab08"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab0d",
                "link": "https://pm.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any one AdvilPM product with sign up.",
                "brand": "Advil PM Caplets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab0d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab12",
                "link": "https://adzenysxrodt.com/#rxconnect-section",
                "description": "Adzenys XR-ODT Savings Card: Eligible commercially insured patients/RX not covered pay $50 prescription; for additional information contact the program at 888-298-8792.",
                "brand": "Adzenys XR-ODT",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab12"
            },
            {
                "_id": "6297bcabe0e0db6c2572aad3",
                "link": "https://www.acebrand.com/3M/en_US/ace-brand/offers/",
                "description": "Sign-up with ACE Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "ACE Brand Braces or Supports",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aad3"
            },
            {
                "_id": "6297bcabe0e0db6c2572aad8",
                "link": "https://www.acebrand.com/3M/en_US/ace-brand/offers/",
                "description": "Sign-up with ACE Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "Ace Brand Tape Wrap and Gear Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aad8"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaee",
                "link": "https://racopay.com/actemra",
                "description": "Actemra Co-pay Card Program: Eligible commercially insured patients may pay $5 per prescription with savings of up to $15,000 calendar year; for additional information contact the program at 855-722-6729.",
                "brand": "Actemra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaee"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaf3",
                "link": "https://copay.novartisoncology.com/",
                "description": "Adakveo Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Adakveo",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaf3"
            },
            {
                "_id": "6297bcabe0e0db6c2572aacd",
                "link": "https://www.accu-chek.com/special-offers",
                "description": "Accu-Chek SimplePay: Patients may pay no more than $69.99 per month for up to 300 strips and receive a FREE meter (free meter only available with the first order); for additional information contact the program at 800-858-8072.",
                "brand": "Accu-Chek Guide Me Test Strips",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aacd"
            },
            {
                "_id": "6297bcabe0e0db6c2572aadd",
                "link": "https://aciphex.com",
                "description": "Aciphex Program: Eligible commercially insured patients may pay $20 per prescription with savings of up to $55 per monthly fill; for additional information contact the program at �877-274-3244.",
                "brand": "AcipHex",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "660",
                "lifetime_uses": "12",
                "max_saving_transaction": 55,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aadd"
            },
            {
                "_id": "6297bcabe0e0db6c2572aae2",
                "link": "https://actoralcareprofessional.com/samples-and-savings/",
                "description": "ACT Product Samples: Dental professionals may request free samples & coupons for use in their office by signing up for the ACT Professional Support Program.",
                "brand": "ACT Dry Mouth Lozenges",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aae2"
            },
            {
                "_id": "6297bcabe0e0db6c2572aad2",
                "link": "https://www.acebrand.com/3M/en_US/ace-brand/offers/",
                "description": "Sign-up with ACE Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "ACE Brand Back Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aad2"
            },
            {
                "_id": "6297bcabe0e0db6c2572aad7",
                "link": "https://www.acebrand.com/3M/en_US/ace-brand/offers/",
                "description": "Sign-up with ACE Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "ACE Brand Reusable Cold Compress",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aad7"
            },
            {
                "_id": "6297bcabe0e0db6c2572aadc",
                "link": "https://aciphex.com",
                "description": "Aciphex Program: Eligible uninsured/cash-paying patients may save up to $55 per monthly fill; for additional information contact the program at �877-274-3244.",
                "brand": "AcipHex",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "660",
                "lifetime_uses": "12",
                "max_saving_transaction": 55,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aadc"
            },
            {
                "_id": "6297bcabe0e0db6c2572aae1",
                "link": "https://actoralcareprofessional.com/samples-and-savings/",
                "description": "ACT Product Samples: Dental professionals may request free samples & coupons for use in their office by signing up for the ACT Professional Support Program.",
                "brand": "ACT Braces Care",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aae1"
            },
            {
                "_id": "6297bcabe0e0db6c2572aae7",
                "link": "https://actoralcareprofessional.com/samples-and-savings/",
                "description": "ACT Product Samples: Dental professionals may request free samples & coupons for use in their office by signing up for the ACT Professional Support Program.",
                "brand": "ACT Dry Mouth Mouthwash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aae7"
            },
            {
                "_id": "6297bcabe0e0db6c2572aae6",
                "link": "https://www.actoralcare.com/act-for-adults/sign-up-save/",
                "description": "Save $1 on any one ACT product by registering your email address on the website; one coupon per purchase; coupon expires one month after printing.",
                "brand": "ACT Dry Mouth Mouthwash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aae6"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaeb",
                "link": "https://actoralcareprofessional.com/samples-and-savings/",
                "description": "ACT Product Samples: Dental professionals may request free samples & coupons for use in their office by signing up for the ACT Professional Support Program.",
                "brand": "ACT Total Care",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaeb"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaec",
                "link": "https://www.actoralcare.com/act-for-adults/sign-up-save/",
                "description": "Save $1 on any one ACT product by registering your email address on the website; one coupon per purchase; coupon expires one month after printing.",
                "brand": "ACT Total Care Sensitive",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaec"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaf1",
                "link": "https://www.acuvue.com/get-contacts/free-trial-contact-lenses",
                "description": "Acuvue Contact Lens FREE Offer: Register your email address to receive a certificate for a FREE pair of Acuvue contact lenses from your eyecare professional.",
                "brand": "Acuvue Brand Contact Lenses",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaf1"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaf6",
                "link": "https://addyi.com/getting-addyi/",
                "description": "Addyi Savings Card: Eligible commercially insured patients may pay as little as $20 for their monthly prescriptions when using the coupon; for additional information contact the program at 855-280-0581",
                "brand": "Addyi",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaf6"
            },
            {
                "_id": "6297bcabe0e0db6c2572aafb",
                "link": "https://www.adlyxin.com/copay-savings",
                "description": "Adlyxin Co-Pay Program: Eligible commercially insured patients who are currently enrolled in the Sanofi Rx Savings Program and have used their card for Toujeo or Lantus may pay no more than $0 per prescription with savings of up to $700 per fill in a 12-month period; for additional information contact the program at 866-255-8661.",
                "brand": "Adlyxin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "8400",
                "lifetime_uses": "12",
                "max_saving_transaction": 700,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aafb"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab00",
                "link": "https://www.advate.com/resources",
                "description": "Advate Freedom of Choice Free-Trial Program: Eligible NEW patients may receive 6 free trial doses; for additional information contact the program at  888-229-8379.",
                "brand": "Advate",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab00"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab05",
                "link": "https://respiratory.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any one Advil Cold & Allergy product with sign up.",
                "brand": "Advil Cold and Sinus Caplets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab05"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab0a",
                "link": "https://www.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any one Advil product with sign up.",
                "brand": "Advil Liqui-Gels products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab0a"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaf0",
                "link": "https://www.horizonbyyourside.com/actimmune/patient/financial-assistance",
                "description": "Horizon Co-Pay Assistance Program for Actimmune: Eligible commercially insured patients may receive coverage for their co-pay and co-insurance amounts automatically by enrolling in the program; for additional information contact the program at 877-305-7704.",
                "brand": "Actimmune",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaf0"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaf5",
                "link": "https://addyi.com/getting-addyi/",
                "description": "Addyi FREE Home Delivery: Eligible patients may have their prescriptions shipped directly to their homes for FREE and guaranteed for the lowest price; for additional information contact the program at 844-746-5745.",
                "brand": "Addyi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaf5"
            },
            {
                "_id": "6297bcabe0e0db6c2572aafa",
                "link": "https://www.adipex.com/",
                "description": "Adipex-P Prescription Savings Card: Eligible patients may receive up to 25% off each prescription with a maximum savings of $32 per fill; limit of 2 fills per month; for additional information contact the program at 833-378-7363.",
                "brand": "Adipex-P",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "768",
                "lifetime_uses": "24",
                "max_saving_transaction": 32,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aafa"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaff",
                "link": "https://www.advate.com/resources",
                "description": "Advate Takeda HSC CoPay Assistance Program: Eligible commercially insured patients' eligible out-of-pocket costs to be covered 100% by the program; for additional information contact the program at 888-229-8379.",
                "brand": "Advate",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaff"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab01",
                "link": "https://www.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any one Advil product with sign up.",
                "brand": "Advil",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab01"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab06",
                "link": "https://respiratory.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any one Advil Cold & Allergy product with sign up.",
                "brand": "Advil Cold and Sinus LiquiGels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab06"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab0b",
                "link": "https://www.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any one Advil product with sign up.",
                "brand": "Advil Migraine",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab0b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab10",
                "link": "https://www.adynovate.com/resources",
                "description": "Adynovate Takeda HSC CoPay Assistance Program: Eligible commercially insured patients' eligible out-of-pocket costs to be covered 100% by the program; for additional information contact the program at 888-229-8379.",
                "brand": "Adynovate",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab10"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab15",
                "link": "https://adzenysxrodt.com/",
                "description": "Adzenys XR-ODT Aytu RxConnect Pharmacy Network Program: Eligible commercially insured patients/RX not covered may pay as little as $10 per 30-day prescription when using a Aytu RxConnect Pharmacy; for additional information contact the program at 877-675-6590.",
                "brand": "Adzenys XR-ODT",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab15"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab17",
                "link": "https://copay.novartisoncology.com/",
                "description": "Afinitor Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Afinitor",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab17"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab1c",
                "link": "https://afrezza.com/savings-program/",
                "description": "Afrezza Savings Card: Eligible commercially insured patients may pay as little as $15 per monthly fill with a maximum savings of up to $1500 per month; for additional information contact the program at 844-323-7399.",
                "brand": "Afrezza",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "18000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1500,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab1c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab21",
                "link": "https://www.afrin.com/coupons",
                "description": "Save now with a $1 coupon on any one Afrin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Afrin No Drip Night",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab21"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab26",
                "link": "https://www.afrin.com/coupons",
                "description": "Save now with a $1 coupon on any one Afrin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Afrin Severe Congestion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab26"
            },
            {
                "_id": "6297bcabe0e0db6c2572aaf8",
                "link": "https://www.adempas-us.com/aim-access-and-financial-support",
                "description": "Adempas Aim Co-Pay Assistance Program: Eligible commercially insured patients may save up to 100% off co-pays or coinsurance directly to the insurer; for additional information contact the program at 855-423-3672.",
                "brand": "Adempas",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aaf8"
            },
            {
                "_id": "6297bcabe0e0db6c2572aafd",
                "link": "https://www.admelog.com/savings",
                "description": "Admelog Valyou Savings Rebate: Eligible uninsured cash-paying patients may be able to submit a request for a rebate up to the amount of savings earned with the Savings Card if their pharmacy does not accept the Savings Card; or additional information contact the program at 866-390-5622.",
                "brand": "Admelog",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aafd"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab2b",
                "link": "https://www.aimovigaccesscard.com/",
                "description": "Aimovig Bridge to Commercial Coverage Offer: Eligible commercially patients/RX not covered or commercially insured patients who requires prior authorization from their insurance company may receive up to 3 doses over 90 days (whichever occurs first) for FREE from the first prescription filled under this offer; for additional information contact the program at 833-246-6844.",
                "brand": "Aimovig",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab2b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab1a",
                "link": "https://afrezza.com/paying-for-afrezza/",
                "description": "Afrezza Medicare Part D Low Income Subsidy: Patients who have Medicare Part D coverage may be eligible to apply for the Part D Low Income Subsidy and once accepted to the program may pay $9.20 or less per monthly prescription; contact the program directly for questions or to sign-up.",
                "brand": "Afrezza",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab1a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab30",
                "link": "https://airoptix.myalcon.com/contact-lenses/air-optix/free-trial/",
                "description": "Air Optix Free Trial: Receive a FREE trial and other special offers with membership to the Alcon EyeFile;�register your email address to join; coupon to be redeemed at your eye care professional's office; for additional information contact the program at 800-241-5999.",
                "brand": "Air Optix Night-Day Aqua",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab30"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab1f",
                "link": "https://www.afrin.com/coupons",
                "description": "Save now with a $1 coupon on any one Afrin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Afrin No Drip Allergy Sinus Night",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab1f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab35",
                "link": "https://www.myairduo.com",
                "description": "AirDuo RespiClick Copay Card: Eligible uninsured patients may save $100 per fill of name brand medication or save $25 per fill of the generic; for additional information contact the program at 833-378-7362.",
                "brand": "AirDuo RespiClick",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab35"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab24",
                "link": "https://www.afrin.com/coupons",
                "description": "Save now with a $1 coupon on any one Afrin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Afrin Original",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab24"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab29",
                "link": "https://www.afstyla.com/support",
                "description": "Afstyla Free Trial Offer: Insured patients (both commercial and Medicare/Medicaid) who have never received a free trial of Afstyla before are eligible to receive a free 30-day trial; commercially insured patients experiencing insurance delays may receive an additional 30 day supply; for additional information contact the program at 800-676-4266.",
                "brand": "Afstyla",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab29"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab2e",
                "link": "https://airoptix.myalcon.com/contact-lenses/air-optix/free-trial/",
                "description": "Air Optix Colors Free Trial: Receive a FREE trial and other special offers with membership to the Alcon EyeFile;�register your email address to join; coupon to be redeemed at your eye care professional's office; for additional information contact the program at 855-344-6871.",
                "brand": "Air Optix Color Contact Lenses",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab2e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab02",
                "link": "https://respiratory.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any one Advil Cold & Allergy product with sign up.",
                "brand": "Advil Allergy and Congestion Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab02"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab07",
                "link": "https://www.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any one Advil product with sign up.",
                "brand": "Advil Dual Action",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab07"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab0c",
                "link": "https://respiratory.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any one Advil Cold & Allergy product with sign up.",
                "brand": "Advil Multi Symptom Cold and Flu",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab0c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab3a",
                "link": "https://www.akynzeosavingsprogram.com/",
                "description": "Akynzeo Savings Card: Eligible commercially insured patients may pay as little as $0 copay per prescription with a savings of up to $150; for additional information contact the program at 844-357-4668.",
                "brand": "Akynzeo",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab3a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab3f",
                "link": "https://www.alaway.com",
                "description": "Register your email address to save on any one Alaway Eye Drops; 1 coupon per purchase.",
                "brand": "Alaway Preservative Free Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab3f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab44",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Alecensa Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Alecensa",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab44"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab11",
                "link": "https://www.adynovate.com/resources",
                "description": "Adynovate Freedom of Choice Free-Trial Program: Eligible NEW patients may receive 8 free doses of medication; for additional information contact the program at 888-229-8379.",
                "brand": "Adynovate",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab11"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab16",
                "link": "https://adzenysxrodt.com/",
                "description": "Adzenys XR-ODT Aytu RxConnect Pharmacy Network Program: Eligible uninsured/cash-paying patients may save $100 on each 30-day prescription when using a Aytu RxConnect Pharmacy; for additional information contact the program at 877-675-6590.",
                "brand": "Adzenys XR-ODT",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab16"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab1b",
                "link": "https://afrezza.com/paying-for-afrezza/",
                "description": "Afrezza Patient Direct Program: Eligible cash-paying patients may pay as little as as little as $99 per monthly prescription when enrolled in the program; maximum of 3 boxes per fill monthly; for additional information contact the program at 844-323-7399",
                "brand": "Afrezza",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab1b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab20",
                "link": "https://www.afrin.com/coupons",
                "description": "Save now with a $1 coupon on any one Afrin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Afrin No Drip Extra Moisturizing",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab20"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab25",
                "link": "https://www.afrin.com/coupons",
                "description": "Save now with a $1 coupon on any one Afrin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Afrin Original Pump Mist",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab25"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab33",
                "link": "https://www.digihaler.com/#savings",
                "description": "AirDuo Digihaler Savings Program: Eligible commercially insured patients may pay as little as $20 per prescription; for more information contact the program at 866-955-9463.",
                "brand": "AirDuo Digihaler",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab33"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab38",
                "link": "https://www.aklief.com/savings",
                "description": "Aklief CareConnect Patient Savings Card: Eligible commercially insured patients may pay no more than $20 per prescription (45g bottle); for additional information contact the program at 855-280-0543.",
                "brand": "Aklief",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab38"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab3d",
                "link": "https://www.alaway.com",
                "description": "Register your email address to save on any one Alaway Eye Drops; 1 coupon per purchase.",
                "brand": "Alaway Childrens Eye Itch Relief Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab3d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab42",
                "link": "https://www.aldurazyme.com/patients",
                "description": "Aldurazyme CareConnectPSS Co-Pay Assistance Program: Eligible commercially insured patients may receive assistance through the program to pay for eligible, out-of-pocket expenses such as co-pays, coinsurance, and deductibles; for additional information contact the program at 800-745-4447, option 3.",
                "brand": "Aldurazyme",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab42"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab49",
                "link": "https://www.aleve.com/save/coupon",
                "description": "Save $1 on any one Aleve PM product (20 count or larger); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Aleve PM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab49"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab47",
                "link": "https://www.aleve.com/save/coupon",
                "description": "Save $1 on any one Aleve product (40 count or larger); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Aleve Arthritis Cap",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab47"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab4e",
                "link": "https://www.lillyoncologysupport.com/alimta-financial-support",
                "description": "Alimta Savings Card: Eligible commercially insured patients may pay as little as $25 per dose with a maximum savings of up to $25,000 per 12-month enrollment period; for additional information contact the program at 866-472-8663.",
                "brand": "Alimta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.34,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab4e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab4c",
                "link": "https://www.aleve.com/save/coupon",
                "description": "Save $1 on any one AleveX product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "AleveX Rollerball",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab4c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab53",
                "link": "https://www.alkaseltzer.com/original/coupon",
                "description": "Save $1 on any one Alka-Seltzer product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Alka-Seltzer Effervescent Tablet Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab53"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab58",
                "link": "https://www.alkaseltzer.com/original/coupon",
                "description": "Save $1 on any one Alka-Seltzer product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Alka-Seltzer Kids Tummy ReliefChews",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab58"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab5d",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Maximum Strength Day and Night Cold and Flu Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab5d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab0f",
                "link": "https://respiratory.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any one Advil Cold & Allergy product with sign up.",
                "brand": "Advil Sinus Congestion and Pain",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab0f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab14",
                "link": "https://adzenysxrodt.com/",
                "description": "Adzenys XR-ODT Aytu RxConnect Pharmacy Network Program: Eligible commercially insured patients may pay $0 per 30-day prescription when using a Aytu RxConnect Pharmacy; for additional information contact the program at 877-675-6590.",
                "brand": "Adzenys XR-ODT",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab14"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab19",
                "link": "https://www.us.afinitor.com/tsc-seizures/patient-support/financial-resources/",
                "description": "Afinitor Disperz Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $0 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Afinitor Disperz",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab19"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab1e",
                "link": "https://www.afrin.com/coupons",
                "description": "Save now with a $1 coupon on any one Afrin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Afrin No Drip Allergy Sinus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab1e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab23",
                "link": "https://www.afrin.com/coupons",
                "description": "Save now with a $1 coupon on any one Afrin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Afrin No Drip Severe Congestion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab23"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab28",
                "link": "https://www.afstyla.com/support",
                "description": "Afstyla My Access Co-Pay Program: Eligible commercially insured patients may receive up to $12,000 in annual savings; for additional information contact the program at 800-676-4266.",
                "brand": "Afstyla",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab28"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab2d",
                "link": "https://airoptix.myalcon.com/contact-lenses/air-optix/free-trial/",
                "description": "Air Optix Free Trial: Receive a FREE trial and other special offers with membership to the Alcon EyeFile;�register your email address to join; coupon to be redeemed at your eye care professional's office; for additional information contact the program at 800-241-5999.",
                "brand": "Air Optix Aqua Multifocal",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab2d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab32",
                "link": "https://airoptix.myalcon.com/contact-lenses/air-optix/free-trial/",
                "description": "Air Optix Free Trial: Receive a FREE trial and other special offers with membership to the Alcon EyeFile;�register your email address to join; coupon to be redeemed at your eye care professional's office; for additional information contact the program at 800-241-5999.",
                "brand": "Air Optix plus Hyrdraglyde Multifocal",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab32"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab37",
                "link": "https://www.aklief.com/savings",
                "description": "Aklief CareConnect Patient Savings Card: Eligible uninsured/cash-paying patients may pay no more than $105 per prescription (45g bottle); for additional information contact the program at 855-280-0543.",
                "brand": "Aklief",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab37"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab3c",
                "link": "https://www.alavert.com/coupons/",
                "description": "Save $3 on your next purchase of any Alavert D-12 product; one coupon per purchase, coupon expires 1 month after printing.",
                "brand": "Alavert D-12",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab3c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab2a",
                "link": "https://www.aimovigaccesscard.com/",
                "description": "Aimovig Ally Access Card: Eligible commercially insured patients may pay as little as $5 per month per prescription with annual maximum benefit; patient must re-enroll every 12 months; for additional information contact the program at 833-246-6844.",
                "brand": "Aimovig",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab2a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab2f",
                "link": "https://airoptix.myalcon.com/contact-lenses/air-optix/free-trial/",
                "description": "Air Optix Free Trial: Receive a FREE trial and other special offers with membership to the Alcon EyeFile;�register your email address to join; coupon to be redeemed at your eye care professional's office; for additional information contact the program at 800-241-5999.",
                "brand": "Air Optix for Astigmatism",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab2f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab34",
                "link": "https://www.myairduo.com",
                "description": "AirDuo RespiClick Copay Card: Eligible commercially insured patients may pay as little as $10 for name brand or generic prescription fill; for additional information contact the program at 833-378-7362.",
                "brand": "AirDuo RespiClick",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab34"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab39",
                "link": "https://www.akynzeosavingsprogram.com/",
                "description": "Akynzeo Mail-In Rebate: If the pharmacy was unable to process the Savings Card eligible commercially insured patients may request a rebate by completing a rebate form; for additional information contact the program at 844-357-4668.",
                "brand": "Akynzeo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab39"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab3e",
                "link": "https://www.alaway.com",
                "description": "Register your email address to save on any one Alaway Eye Drops; 1 coupon per purchase.",
                "brand": "Alaway Eye Itch Relief Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab3e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab43",
                "link": "https://www.copayassistancenow.com/eligibility",
                "description": "Alecensa Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Alecensa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab43"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab04",
                "link": "https://childrens.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any Children's Advil product with sign up.",
                "brand": "Advil Childrens Suspension Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab04"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab09",
                "link": "https://childrens.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any Children's Advil product with sign up.",
                "brand": "Advil Junior Strength Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab09"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab0e",
                "link": "https://pm.advil.com/offers-coupons",
                "description": "Receive a coupon and other exclusive offers on any one AdvilPM product with sign up.",
                "brand": "Advil PM Liqui-Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab0e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab41",
                "link": "https://www.aldurazyme.com/patients",
                "description": "Aldurazyme Co-pay Assistance Program: Eligible commercially insured patients may be able to receive co-pay assistance while enrolled in this program; patient must fill out enrollment form; for additional information contact the program at 800-745-4447, option 3.",
                "brand": "Aldurazyme",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab41"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab46",
                "link": "https://hcp-samples.bayer.com",
                "description": "Aleve Samples: Healthcare providers can register online to order free samples for their practice.",
                "brand": "Aleve",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab46"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab4b",
                "link": "https://www.aleve.com/save/coupon",
                "description": "Save $1 on any one AleveX product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "AleveX Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab4b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab50",
                "link": "https://www.alinia.com/reduce-alinia-cost/",
                "description": "Alinia (Tablets) Co-Pay Savings Card: Eligible commercially insured patients may pay as little as $0 co-pay per prescription with savings of up to $300 per fill; for additional information contact the program at 813-282-8544.",
                "brand": "Alinia",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab50"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab55",
                "link": "https://www.alkaseltzer.com/original/coupon",
                "description": "Save $1 on any one Alka-Seltzer product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Alka-Seltzer Gum Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab55"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab5a",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Maximum Strength Cold and Cough PowerMax Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab5a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab13",
                "link": "https://adzenysxrodt.com/#rxconnect-section",
                "description": "Adzenys XR-ODT Savings Offer: Eligible commercially insured patients may pay as little as $15 per prescription; for additional information contact the program at 888-298-8792.",
                "brand": "Adzenys XR-ODT",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab13"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab18",
                "link": "https://www.us.afinitor.com/tsc-seizures/patient-support/financial-resources/",
                "description": "Afinitor Disperz Free Trial Offer: Eligible commercially insured patients may  recevie a FREE 7-day supply shipped directly to their home while coverage is being determined; for additional information contact the program at 877-577-7756.",
                "brand": "Afinitor Disperz",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab18"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab1d",
                "link": "https://www.afrin.com/coupons",
                "description": "Save now with a $1 coupon on any one Afrin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Afrin Allergy Sinus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab1d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab22",
                "link": "https://www.afrin.com/coupons",
                "description": "Save now with a $1 coupon on any one Afrin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Afrin No Drip Original",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab22"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab27",
                "link": "https://www.afstyla.com/support",
                "description": "Afstyla CSL Behring Assurance Program: Eligible commercially insured patients may earn points for each monthly prescription; points may be used in the event of a lapse in coverage; for additional information contact the program at 800-676-4266.",
                "brand": "Afstyla",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab27"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab2c",
                "link": "https://airoptix.myalcon.com/contact-lenses/air-optix/free-trial/",
                "description": "Air Optix Free Trial: Receive a FREE trial and other special offers with membership to the Alcon EyeFile;�register your email address to join; coupon to be redeemed at your eye care professional's office; for additional information contact the program at 800-241-5999.",
                "brand": "Air Optix Aqua",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab2c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab31",
                "link": "https://airoptix.myalcon.com/contact-lenses/air-optix/free-trial/",
                "description": "Air Optix Free Trial: Receive a FREE trial and other special offers with membership to the Alcon EyeFile;�register your email address to join; coupon to be redeemed at your eye care professional's office; for additional information contact the program at 800-241-5999.",
                "brand": "Air Optix plus Hydraglyde",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab31"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab36",
                "link": "https://www.ajovy.com/savings/",
                "description": "Ajovy Savings Offer: Eligible commercially insured patients may pay as little as $5 per prescription; for additional information contact the program at 800-671-3674.",
                "brand": "Ajovy",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab36"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab3b",
                "link": "https://www.alavert.com/coupons/",
                "description": "Save $3 on your next purchase of any Alavert Allergy product; one coupon per purchase, coupon expires 1 month after printing.",
                "brand": "Alavert Allergy",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab3b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab40",
                "link": "https://www.pfizermedicalinformation.com/en-us/aldactone",
                "description": "Aldactone Samples: Your healthcare provider may order samples by visiting the PfizerPro website or calling 800-505-4426.",
                "brand": "Aldactone",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab40"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab45",
                "link": "https://www.aleve.com/save/coupon",
                "description": "Save $1 on any one Aleve product (40 count or larger); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Aleve",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab45"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab4a",
                "link": "https://www.aleve.com/save/coupon",
                "description": "Save $1 on any one Aleve-D product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Aleve-D Sinus and Cold",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab4a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab4f",
                "link": "https://www.alinia.com/reduce-alinia-cost/",
                "description": "Alinia (Oral Suspension) Co-Pay Savings Card: Eligible commercially insured patients may pay as little as $0 co-pay per prescription with savings of up to $75 per fill; for additional information contact the program at 813-282-8544.",
                "brand": "Alinia",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab4f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab54",
                "link": "https://www.alkaseltzer.com/original/coupon",
                "description": "Save $1 on any one Alka-Seltzer product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Alka-Seltzer Extra Strength Heartburn ReliefChews",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab54"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab59",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Maximum Strength Cold and Cough Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab59"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab5e",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Maximum Strength Day and Night Cold and Flu PowerMax Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab5e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab48",
                "link": "https://www.aleve.com/save/coupon",
                "description": "Save $1 on any one Aleve product (40 count or larger); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Aleve Back and Muscle Pain",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab48"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab63",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Maximum Strength Severe Sinus Allergy and Cough Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab63"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab4d",
                "link": "https://www.aleve.com/save/coupon",
                "description": "Save $1 on any one AleveX product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "AleveX Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab4d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab68",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Severe Cold and Flu PowerFast Fizz Effervescent Tablet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab68"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab52",
                "link": "https://www.aliqopa-us.com/financial-access",
                "description": "Aliqopa Temporary Assistance Program: Eligible commercially insured patients whose coverage has been delayed or who experience a temporary lapse in coverage may receive free medication; for additional information contact the program at 833-254-7672.",
                "brand": "Aliqopa",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab52"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab57",
                "link": "https://www.alkaseltzer.com/original/coupon",
                "description": "Save $1 on any one Alka-Seltzer product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Alka-Seltzer Heartburn Relief Gummies",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab57"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab5c",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Maximum Strength Cough Mucus and Congestion PowerMax Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab5c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab61",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Maximum Strength Day Cold and Flu PowerMax Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab61"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab66",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Maximum Strength Sinus Congestion and Pain Powermax Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab66"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab6b",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Severe Day and Night Cold PowerFast Fizz Effervescent Tablet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab6b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab70",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Severe Sinus Cold and Cough Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab70"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab51",
                "link": "https://www.aliqopa-us.com/financial-access",
                "description": "Aliqopa $0 Co-pay Program: Eligible commercially insured patients pay as little as as $0 co-pay per prescription with savings of up to $25,000 per year; for additional information contact the program at 833-254-7672 option 2.",
                "brand": "Aliqopa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab51"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab62",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Maximum Strength Night Cold and Flu PowerMax Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab62"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab56",
                "link": "https://www.alkaseltzer.com/original/coupon",
                "description": "Save $1 on any one Alka-Seltzer product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Alka-Seltzer Heartburn plus Gas ReliefChews",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab56"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab67",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Severe Cold and Cough PowerFast Fizz Effervescent Tablet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab67"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab75",
                "link": "https://www.allegra.com/en-us/allergy-medicine-coupons/",
                "description": "Save $3 on any one Children's Allegra product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Allegra Childrens Liquid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab75"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab6c",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Severe Day Cold and Flu Mix In Packets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab6c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab7a",
                "link": "https://www.alphanate.com/en/hcp/support/factors-for-health",
                "description": "Alphanate Free Trial Program: Eligible NEW patients to medication may receive a free trial prescription; for additional information contact the program at 844-639-2286.",
                "brand": "Alphanate",
                "copay": "",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab7a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab71",
                "link": "https://www.alkaseltzer.com/original/coupon",
                "description": "Save $1 on any one Alka-Seltzer product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Alka-Seltzer PM Heartburn Relief plus Sleep Support Gummies",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab71"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab5b",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Maximum Strength Cough and Mucus DM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab5b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab60",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Maximum Strength Day and Night Sinus Congestion and Pain Powermax Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab60"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab65",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Maximum Strength Sinus Congestion and Pain Powerfast Fizz Effervescent Tablet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab65"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab6a",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Severe Day and Night Cold and Flu Mix In Packets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab6a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab6f",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Severe Non Drowsy Cold PowerFast Fizz Effervescent Tablet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab6f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab76",
                "link": "https://www.allegra.com/en-us/allergy-medicine-coupons/",
                "description": "Save $3 on any one Children's Allegra product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Allegra Childrens Meltable Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab76"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab74",
                "link": "https://www.allegra.com/en-us/allergy-medicine-coupons/",
                "description": "Save $4 on any one Allegra product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Allegra Allergy 24 Hour",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab74"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab79",
                "link": "https://www.savewithays.com/",
                "description": "Alphagan P Savings Card: Eligible commercially insured patients pay as little as $30 per 30-day prescription; offer may be used for 13 fills; for additional information contact the program at 833-342-5297.",
                "brand": "Alphagan P",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab79"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab6d",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Severe Night Cold and Flu Mix In Packets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab6d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab72",
                "link": "https://www.allegra.com/en-us/allergy-medicine-coupons/",
                "description": "Save $4 on any one Allegra product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Allegra 24HR Gelcaps",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab72"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab77",
                "link": "https://www.allegra.com/en-us/allergy-medicine-coupons/",
                "description": "Save $4 on any one Allegra product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Allegra-D 12 Hour",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab77"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab7e",
                "link": "https://www.alprolix.com/resources/financial-assistance",
                "description": "Alprolix Copay Program: Eligible commercially insured patients may save up to $20,000 per calendar year on co-payments or co-insurance; for additional information contact the program at 855-692-5776.",
                "brand": "Alprolix",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab7e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab83",
                "link": "https://www.almiralladvantage.com/",
                "description": "Altabax Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $35 per prescription when using a retail pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Altabax",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0.00",
                "lifetime_uses": "0",
                "max_saving_transaction": null,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab83"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab7c",
                "link": "https://www.alprolix.com/resources/financial-assistance",
                "description": "Alprolix Factor Acces Program: Program provides continued vital treatments during a lapse in third-party private health insurance; for additional information contact the program at 855-692-5776.",
                "brand": "Alprolix",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab7c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab88",
                "link": "https://www.takedaoncologycopay.com",
                "description": "Alunbrig Takeda Oncology Co-pay Assistance Program Rebate: If a commercially insured patient is unable to take advantage of the assistance program at their local pharmacy they may be eligible for a rebate; for additional information contact the program at 844-817-6468.",
                "brand": "Alunbrig",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab88"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab81",
                "link": "https://almiralladvantage.com",
                "description": "Altabax Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $15 per prescription when using a network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Altabax",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0.00",
                "lifetime_uses": "0",
                "max_saving_transaction": null,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab81"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab8d",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings on Always products with registration.",
                "brand": "Always Sanitary Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab8d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab86",
                "link": "https://www.caystonhcp.com/programs/cayston-access-program",
                "description": "Altera Co-pay Coupon Program: Eligible cash-pay patients on Cayston may save on their prescriptions for Altera; for additional information contact the program at 877-722-9786.",
                "brand": "Altera Nebulizer",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab86"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab92",
                "link": "https://analpram.com",
                "description": "Analpram HC Instant Savings Offer: Eligible cash-paying patients may save up to $100 per prescription; for additional information contact the program at 844-732-3521.",
                "brand": "Analpram HC",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab92"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab8b",
                "link": "https://www.alvesco.us/walgreens-card-copay",
                "description": "Alvesco Walgreens $0 Co-Pay Program: Eligible commercially insured patients may pay $0 per fill with no maximum benefit per fill; for additional information contact the program at 844-749-1023",
                "brand": "Alvesco",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab8b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab97",
                "link": "https://gskpro.com/en-us/request-samples-login-page/?resource=%2Fcontent%2Fcf-pharma%2Fhealth-hcpportal%2Fen_US%2Fhcp%2Fhome%2Fqpharma-saml-page.html",
                "description": "Anoro Ellipta FREE Samples: Your healthcare provider may be able to order free samples by visiting GSK Pro and creating an account or by calling 888-593-5977.",
                "brand": "Anoro Ellipta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab97"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab90",
                "link": "https://www.amzeeq.com/acne-patient-resources#savings",
                "description": "Amzeeq Savings Card: Eligible commercially insured patients may pay as little as $35 on each of up to 12 prescriptions; for additional information contact the program at 855-965-1334.",
                "brand": "Amzeeq",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab90"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab9c",
                "link": "https://www.apexicon.com/apx_web_resources_savings.shtml",
                "description": "ApexiCon E Cream Samples: Healthcare providers may fax a sample request form to 844-896-5307.",
                "brand": "ApexiCon E",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab9c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab95",
                "link": "https://analpram.com/",
                "description": "Analpram HC Instant Savings Offer Rebate: If the pharmacy is unable to process the Savings Offer cash-paying patients may submit a rebate request in order to reimbursed their out-of-pocket cost over $100; rebate instructions are found at the bottom of the Savings Card; for additional information contact the program at 844-732-3521.",
                "brand": "Analpram HC",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab95"
            },
            {
                "_id": "6297bcabe0e0db6c2572aba1",
                "link": "https://www.apidra.com/savings",
                "description": "Apidra Valyou Savings Program: Eligible uninsured and cash-paying patients will pay $99 per monthly supply of up to 10 vials or packs of SoloStar pens per fill or up to 5 packs of Max SoloStar pens per fill; for additional information contact the program at 855-984-6302.",
                "brand": "Apidra SoloStar Pen",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aba1"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab9a",
                "link": "http://antararx.com/patient/rmprogram.html",
                "description": "Antara $0 Co-Pay Savings Card: Eligible commercially insured patients may pay $0 co-pay per prescription for unlimited number of uses; for additional information contact the program at 866-587-4617.",
                "brand": "Antara",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab9a"
            },
            {
                "_id": "6297bcabe0e0db6c2572aba6",
                "link": "https://www.aplenzin.com/hcp/savings",
                "description": "Aplenzin Samples: Healthcare providers may request samples by filling out a form online.",
                "brand": "Aplenzin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aba6"
            },
            {
                "_id": "6297bcabe0e0db6c2572abab",
                "link": "https://www.aptiom.com/coverage-and-savings/aptiom-savings/",
                "description": "Aptiom 14-Day Trial Voucher: Eligible patients may receive a 14-day free trial with a valid prescription; for additional information contact the program at 800-657-7613.",
                "brand": "Aptiom",
                "copay": "No",
                "bin": "610524",
                "rxpcn": "1016",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abab"
            },
            {
                "_id": "6297bcabe0e0db6c2572abb0",
                "link": "https://www.aquaphorus.com/sign-up/",
                "description": "Sign up for exclusive savings offers available from Aquaphor.",
                "brand": "Aquaphor Healing Ointment Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abb0"
            },
            {
                "_id": "6297bcabe0e0db6c2572abb5",
                "link": "https://www.orthorxaccess.com/",
                "description": "Arazlo Rx Access Program: Eligible commercially insured patients/RX not covered  may pay no more than $65 per fills; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Arazlo",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abb5"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab7b",
                "link": "https://www.alphanate.com/en/patients/support-and-resources/factors-for-health",
                "description": "Alphanate $0 Copay Assistance Program: Eligible commercially insured patients may pay $0 on out-of-pocket expenses not covered or partially covered by insurance; for additional information contact the program at 844-693-2286.",
                "brand": "Alphanate",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "2000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab7b"
            },
            {
                "_id": "6297bcabe0e0db6c2572abba",
                "link": "https://www.aristada.com/copay-savings",
                "description": "Aristada Co-pay Savings Program: Eligible commercially insured patients may pay as little as $10 per prescription; for more information contact the program at 866-274-7823.",
                "brand": "ARISTADA",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abba"
            },
            {
                "_id": "6297bcabe0e0db6c2572abbf",
                "link": "https://www.boironusa.com/arnicare-gel-2-6oz-or-4-1oz-coupon/",
                "description": "$2 coupon for any Arnicare gel product (2.6 oz or 4.1 oz); 1 coupon per purchase.",
                "brand": "Arnicare",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abbf"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab80",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Alrex Bausch + Lomb Access Program: Eligible commercially insured patients may pay no more than $25 for each prescription; for additional information contact the program 866-693-4880.",
                "brand": "Alrex",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab80"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab85",
                "link": "https://www.caystonhcp.com/programs/cayston-access-program",
                "description": "Altera Co-pay Coupon Program: Eligible commercially insured patients on Cayston may pay as little as $10 per prescription with savings of up to $430 per fill; patients are eligible to receive up to 2 per year; for additional information contact the program at 877-722-9786.",
                "brand": "Altera Nebulizer",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "860",
                "lifetime_uses": "2",
                "max_saving_transaction": 430,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab85"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab8a",
                "link": "https://www.alvesco.us/walgreens-card-copay",
                "description": "Alvesco Walgreens $0 Co-Pay Program: Eligible commercially insured patients/RX not covered & cash-paying patients pay $50 for 1-2 inhalers, $100 for 3-4 inhalers, $150 for 5-6 inhalers; for additional information contact the program at 844-749-1023.",
                "brand": "Alvesco",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab8a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab8f",
                "link": "https://ampyra.com/prescription/free-trial",
                "description": "Ampyra Free Trial Program: Receive a 60-day FREE trial prescription; patients who have received a prescription within the last 12 months are not eligible; for additional information contact the program at 888-881-1918.",
                "brand": "Ampyra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab8f"
            },
            {
                "_id": "6297bcabe0e0db6c2572abc4",
                "link": "https://www.alva-amco.com/contact",
                "description": "Consumers may fill out a request form for Arthriten product coupons.",
                "brand": "Arthriten",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abc4"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab9f",
                "link": "https://www.apidra.com/savings",
                "description": "Apidra $0 Co-Pay Program Mail-in Rebate: Most eligible commercially insured patients using a mail-order pharmacy may submit a rebate request for up $100 per prescription; must activate the copay card first; for additional information contact the program at 855-984-6302.",
                "brand": "Apidra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab9f"
            },
            {
                "_id": "6297bcabe0e0db6c2572abc9",
                "link": "https://www.organonconnect.com",
                "description": "Asmanex Twisthaler Samples: Your healthcare provider may request FREE samples by logging onto healthcare provider portal; for additional information contact the program at 866-719-1691.",
                "brand": "Asmanex Twisthaler",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abc9"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab94",
                "link": "https://analpram.com/hcp",
                "description": "Analpram HC Samples: Healthcare providers may request free samples for their practice by filling out a form online or faxing an order form to 781-843-7932; order forms can also be sent via email to Analpramsamples@sebelapharma.com.",
                "brand": "Analpram HC",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab94"
            },
            {
                "_id": "6297bcabe0e0db6c2572abce",
                "link": "https://aspercreme.com/coupons/",
                "description": "Save $1 on any Aspercreme product (excluding 1 patch. 1.25 oz cream and trial & travel sizes); one coupon per purchase.",
                "brand": "Aspercreme Lidocaine Dry Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abce"
            },
            {
                "_id": "6297bcabe0e0db6c2572aba4",
                "link": "https://www.apidra.com/savings",
                "description": "Apidra $0 Co-Pay Savings Program: Eligible commercially insured patients may pay $0 co-pay with savings of up to $100 per prescription; for additional assistance contact the program at 855-984-6302.",
                "brand": "Apidra SoloStar Pen",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aba4"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab99",
                "link": "https://www.anoro.com/savings-support/savings/eligibility/",
                "description": "Anoro Ellipta Savings Offer: Eligible commercially insured patients may pay as little as $0 per prescription (1-3 inhalers) with a maximum savings of up to $150 per month; for additional information contact the program at 888-825-5249.",
                "brand": "Anoro Ellipta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab99"
            },
            {
                "_id": "6297bcabe0e0db6c2572abd3",
                "link": "https://aspercreme.com/coupons/",
                "description": "Save $1 on any Aspercreme product (excluding 1 patch. 1.25 oz cream and trial & travel sizes); one coupon per purchase.",
                "brand": "Aspercreme Pain Relieving Creme",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abd3"
            },
            {
                "_id": "6297bcabe0e0db6c2572aba9",
                "link": "https://www.aptiom.com/coverage-and-savings/aptiom-savings/",
                "description": "Aptiom High-Deductible Discount Card: Eligible commercially insured patients with a high-deductible insurance plan may pay as little as $35 per prescription with savings of up to $500 on each of up to three 30-day fills; for additional information contact the program at 855-820-0071.",
                "brand": "Aptiom",
                "copay": "Yes",
                "bin": "610254",
                "rxpcn": "Loyalty",
                "copay_max": "1500",
                "lifetime_uses": "3",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aba9"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab9e",
                "link": "https://www.apidra.com/savings",
                "description": "Apidra Valyou Savings Program: Eligible uninsured and cash-paying patients will pay $99 per monthly supply of up to 10 vials or packs of SoloStar pens per fill or up to 5 packs of Max SoloStar pens per fill; for additional information contact the program at 855-984-6302.",
                "brand": "Apidra",
                "copay": "No",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab9e"
            },
            {
                "_id": "6297bcabe0e0db6c2572aba3",
                "link": "https://www.apidra.com/savings",
                "description": "Apidra Valyou Savings Rebate: Eligible uninsured and cash-paying patients using a mail-order pharmacy can submit a request for a rebate up to the amount of savings earned with the Savings Card; or additional information contact the program at 855-984-6302.",
                "brand": "Apidra SoloStar Pen",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aba3"
            },
            {
                "_id": "6297bcabe0e0db6c2572aba8",
                "link": "https://www.sunovionprofile.com/aptiom/request-samples/",
                "description": "Aptiom Samples: Your healthcare provider may request samples by logging onto the website.",
                "brand": "Aptiom",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aba8"
            },
            {
                "_id": "6297bcabe0e0db6c2572abd8",
                "link": "https://www.pfizermedicalinformation.com/en-us/atgam?section=indications-usage",
                "description": "Atgam Samples: Healthcare providers may request samples by logging onto the PfizerPro website or calling 800-505-4426.",
                "brand": "Atgam",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abd8"
            },
            {
                "_id": "6297bcabe0e0db6c2572abad",
                "link": "https://www.aquaphorus.com/sign-up/",
                "description": "Sign up for exclusive savings offers available from Aquaphor.",
                "brand": "Aquaphor Baby Diaper Rash Paste",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abad"
            },
            {
                "_id": "6297bcabe0e0db6c2572abae",
                "link": "https://www.aquaphorus.com/sign-up/",
                "description": "Sign up for exclusive savings offers available from Aquaphor.",
                "brand": "Aquaphor Baby Healing Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abae"
            },
            {
                "_id": "6297bcabe0e0db6c2572abb2",
                "link": "https://www.aquaphorus.com/sign-up/",
                "description": "Sign up for exclusive savings offers available from Aquaphor.",
                "brand": "Aquaphor Lip Repair Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abb2"
            },
            {
                "_id": "6297bcabe0e0db6c2572abdd",
                "link": "https://www.auryxia.com/iron-deficiency-anemia/patient/access-support/",
                "description": "Auryxia Copay Coupon: Eligible commercially insured patients may pay $0 per 30-day prescription with savings of up to $500 per fill of 90 tablets or less; for additional information contact the program at 844-865-8726.",
                "brand": "Auryxia",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abdd"
            },
            {
                "_id": "6297bcabe0e0db6c2572abb3",
                "link": "https://www.aquaphorus.com/sign-up/",
                "description": "Sign up for exclusive savings offers available from Aquaphor.",
                "brand": "Aquaphor Ointment Body Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abb3"
            },
            {
                "_id": "6297bcabe0e0db6c2572abb8",
                "link": "https://www.arikayce.com/support-programs/#Savings-and-Financial-Support",
                "description": "Arikayce Co-Pay Savings Program: Eligible commercially insured patients may pay $0 co-pay per month with a savings of $8000 per month; maximum benefit of $32,000 per calendar year; for additional information contact the program at 833-274-5273.",
                "brand": "Arikayce Kit",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "32000",
                "lifetime_uses": "12",
                "max_saving_transaction": 8000,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abb8"
            },
            {
                "_id": "6297bcabe0e0db6c2572abbd",
                "link": "https://www.aristada.com/copay-savings",
                "description": "Aristada Initio Co-pay Savings Program Rebate: Eligible commercially insured patients may frequest a rebate if they paid out of pocket for their prescription after activating their Copay Card; for more information contact the program at 800-657-7613.",
                "brand": "ARISTADA INITIO",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abbd"
            },
            {
                "_id": "6297bcabe0e0db6c2572abc2",
                "link": "https://www.boironusa.com/arnicare-arthritis-coupon/",
                "description": "$2 coupon for Arnicare arthritis product; 1 coupon per purchase.",
                "brand": "Arnicare Arthritis",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abc2"
            },
            {
                "_id": "6297bcabe0e0db6c2572abc7",
                "link": "https://www.organonconnect.com",
                "description": "Asmanex HFA Samples: Your healthcare provider may request FREE samples by logging onto healthcare provider portal; for additional information contact the program at 866-719-1691.",
                "brand": "Asmanex HFA",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abc7"
            },
            {
                "_id": "6297bcabe0e0db6c2572abb7",
                "link": "https://www.orthorxaccess.com",
                "description": "Arazlo Rx Access Program: Eligible commercially insured patients pay $25 per prescription; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Arazlo",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abb7"
            },
            {
                "_id": "6297bcabe0e0db6c2572abcc",
                "link": "https://aspercreme.com/coupons/",
                "description": "Save $1 on any Aspercreme product (excluding 1 patch. 1.25 oz cream and trial & travel sizes); one coupon per purchase.",
                "brand": "Aspercreme Arthritis Pain Relief Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abcc"
            },
            {
                "_id": "6297bcabe0e0db6c2572abbc",
                "link": "https://www.aristada.com/copay-savings",
                "description": "Aristada Initio Co-pay Savings Program: Eligible commercially insured patients may pay as little as $10 per prescription; for more information contact the program at 866-274-7823.",
                "brand": "ARISTADA INITIO",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abbc"
            },
            {
                "_id": "6297bcabe0e0db6c2572abc1",
                "link": "https://www.boironusa.com/arnicare-cream-coupon/",
                "description": "$2 coupon for any Arnicare cream product; 1 coupon per purchase.",
                "brand": "Arnicare",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abc1"
            },
            {
                "_id": "6297bcabe0e0db6c2572abc6",
                "link": "https://www.asmanex.com/asmanex-hfa/",
                "description": "Asmanex HFA Savings Coupon: Eligible commercially insured patients may pay as little as $15 on each of up to 12 prescriptions for savings of up to $90 per month; for additional information contact the program at 877-264-2454.",
                "brand": "Asmanex HFA",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1080",
                "lifetime_uses": "12",
                "max_saving_transaction": 90,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abc6"
            },
            {
                "_id": "6297bcabe0e0db6c2572abcb",
                "link": "https://www.asmanex.com/asmanex-twisthaler/",
                "description": "Asmanex Twisthaler Savings Coupon Rebate: If a commercially insured patient is unable to use the Savings Coupon at their retail or mail-order pharmacy they can call McKesson at 877-264-2454 to request a Direct Member Reimbursement form; patient must call  within 30 days of purchase and keep their receipt.",
                "brand": "Asmanex Twisthaler",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1080",
                "lifetime_uses": "12",
                "max_saving_transaction": 90,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abcb"
            },
            {
                "_id": "6297bcabe0e0db6c2572abd0",
                "link": "https://aspercreme.com/coupons/",
                "description": "Save $1 on any Aspercreme product (excluding 1 patch. 1.25 oz cream and trial & travel sizes); one coupon per purchase.",
                "brand": "Aspercreme Lidocaine Foot Pain Creme  Diabetic Skin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abd0"
            },
            {
                "_id": "6297bcabe0e0db6c2572abd5",
                "link": "https://www.astagrafxl.com/savings-info",
                "description": "Astagraf XL Copay Card Program: Eligible commercially insured patients may pay as little as $0 per prescription and save up to $3000 annually; card valid for up to 12 months from date of enrollment; must re-enroll each year for additional information contact the program at 866-790-7659.",
                "brand": "Astagraf XL",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "3000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abd5"
            },
            {
                "_id": "6297bcabe0e0db6c2572abda",
                "link": "https://www.atrovent.com/registration",
                "description": "Atrovent HFA Offers: Patients may register their email address to receive updates, savings offers and more information about Atrovent HFA; for more information contact the program at 800-243-0127.",
                "brand": "Atrovent HFA",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abda"
            },
            {
                "_id": "6297bcabe0e0db6c2572abdf",
                "link": "https://www.auvi-q.com/get-auvi-q",
                "description": "Auvi-Q Direct Delivery Service Patient Program: Eligible commercially insured patients may pay as little as $0 per prescription and medication will be shipped to the patient's home; for additional information contact the program at 844-357-3968.",
                "brand": "Auvi-Q",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abdf"
            },
            {
                "_id": "6297bcabe0e0db6c2572abe4",
                "link": "https://www.aveedusa.com/paying-for-aveed/",
                "description": "Aveed Copay Assistance Program: Eligible cash-paying patients may enroll in the program to assist with the costs associated with the administration of Aveed; for additional information contact the program at 800-381-2638.",
                "brand": "Aveed",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abe4"
            },
            {
                "_id": "6297bcabe0e0db6c2572abd1",
                "link": "https://aspercreme.com/coupons/",
                "description": "Save $1 on any Aspercreme product (excluding 1 patch. 1.25 oz cream and trial & travel sizes); one coupon per purchase.",
                "brand": "Aspercreme Lidocaine No-Mess Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abd1"
            },
            {
                "_id": "6297bcabe0e0db6c2572abe9",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Baby Cleansing Therapy Moisturizing Wash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abe9"
            },
            {
                "_id": "6297bcabe0e0db6c2572abd6",
                "link": "https://www.astagrafxl.com/savings-info",
                "description": "Astagraf XL Astellas Copay Program Mail-In Rebate: If your pharmacy does not accept the savings card or you use a mail order pharmacy you may complete and submit the mail-order form to receive your proper savings; for additional information contact the program at 855-510-4545.",
                "brand": "Astagraf XL",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abd6"
            },
            {
                "_id": "6297bcabe0e0db6c2572abdb",
                "link": "https://www.aubagio.com/cost",
                "description": "Aubagio One Start Program: Eligible commercially insured patients can begin treatment within 5 business days of being prescribed their medication under this program while waiting for their insurance and if needed patients can receive their medication at no cost for up to 1 year while benefits are being verified; for additional information contact the program at 855-676-6326.",
                "brand": "Aubagio",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abdb"
            },
            {
                "_id": "6297bcabe0e0db6c2572abe0",
                "link": "https://www.auvi-q.com/get-auvi-q",
                "description": "Auvi-Q Savings Offer at Walgreens: Eligible commercially insured patients may pay as little as $0 per prescription when filling their prescription at a Walgreens pharmacy; for additional information contact the program at 844-357-3968.",
                "brand": "Auvi-Q",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abe0"
            },
            {
                "_id": "6297bcabe0e0db6c2572abe5",
                "link": "https://www.aveedusa.com/paying-for-aveed/",
                "description": "Aveed Copay Assistance Program: Eligible commercially insured patients may pay $0 copay with savings of up to $300 per injection; for additional information contact the program at 800-381-2638.",
                "brand": "Aveed",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abe5"
            },
            {
                "_id": "6297bcabe0e0db6c2572abea",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Baby Eczema Therapy Moisturizing Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abea"
            },
            {
                "_id": "6297bcabe0e0db6c2572abef",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Calm plus Restore Triple Oat Serum for Sensitive Skin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abef"
            },
            {
                "_id": "6297bcabe0e0db6c2572abf4",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Daily Moisturizing Body Lotion for Dry Skin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abf4"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab7f",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Alrex Bausch + Lomb Access Program: Eligible cash-paying and commercially insured/RX not covered patients may pay no more than $60 per 5 mL bottle/$90 per 10 mL bottle prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Alrex",
                "copay": "Yes",
                "bin": "1958",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab7f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab84",
                "link": "https://www.pfizermedicalinformation.com/en-us/altace-capsules",
                "description": "Altace Samples: Your healthcare provider may request samples online by visiting the PfizerPro website or calling 800-505-4426.",
                "brand": "Altace",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab84"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab89",
                "link": "https://www.alvesco.us/alvesco-card-copay",
                "description": "Alvesco Savings Card: Eligible commercially insured patients may pay as little as $5 on each of up to 12 prescription per calendar year with a savings of $85 per fill; for additional information contact the program at 877-264-2440.",
                "brand": "Alvesco",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1020",
                "lifetime_uses": "12",
                "max_saving_transaction": 85,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab89"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab8e",
                "link": "https://ampyra.com/prescription/coverage",
                "description": "Ampyra Co-Pay Program: Eligible commercially insured patients may pay as little as $10 per month with savings of up to $1000 per prescription; annual maximum savings of $12,000; for additional information contact the program at 888-881-1918.",
                "brand": "Ampyra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1000,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab8e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab93",
                "link": "https://analpram.com",
                "description": "Analpram HC Instant Savings Offer: Eligible commercially insured patients may pay as little as $30 per prescription; for additional information contact the program at 844-732-3521.",
                "brand": "Analpram HC",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab93"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab5f",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Maximum Strength Day and Night Cough Mucus and Congestion PowerMax Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab5f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab64",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Maximum Strength Sinus Allergy and Cough PowerMax Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab64"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab69",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Severe Cold PowerFast Fizz Effervescent Tablet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab69"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab6e",
                "link": "https://www.alkaseltzer.com/plus/sign-and-save",
                "description": "Save $1 on any Alka-Seltzer Plus product by registering your email address; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Alka-Seltzer Plus Severe Night Cold PowerFast Fizz Effervescent Tablet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab6e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab73",
                "link": "https://www.allegra.com/en-us/allergy-medicine-coupons/",
                "description": "Save $4 on any one Allegra product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Allegra Allergy 12 Hour",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab73"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab78",
                "link": "https://www.allegra.com/en-us/allergy-medicine-coupons/",
                "description": "Save $4 on any one Allegra product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Allegra-D 24 Hour",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab78"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab7d",
                "link": "https://www.alprolix.com/resources/financial-assistance",
                "description": "Alprolix Free Trial Plus Program: Eligible patients receive a FREE 30-day supply with a valid prescription from your healthcare provider; for additional information contact the program at 855-692-5776.",
                "brand": "Alprolix",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab7d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab82",
                "link": "https://almiralladvantage.com",
                "description": "Altabax Almirall Advantage Savings Card: Eligible commercially insured patients/RX not covered may pay no more than $50 per prescription when using a network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Altabax",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab82"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab87",
                "link": "https://www.takedaoncologycopay.com",
                "description": "Alunbrig Takeda Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay as little as $0 per prescription with savings of up to $25,000 per year; for additional information contact the program at 844-217-6468.",
                "brand": "Alunbrig",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab87"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab8c",
                "link": "https://covispharma.hcpordering.com/landingPage?compDetail=1246-1000&privacyLink=https%3A%2F%2Fhcp.alvesco.us%2Fprivacy-policy&copyRightClientName=Covis+Pharma+GmbH+&mapCode=&copyRightSuffix=PP-ALV-US-00059+10%2F2021&footerLabel=Alvesco%3Csup%3E%26reg%3C%2Fsup%3E+is+a+registered+trademark+of+Covis+Pharma+GmbH.&termsLink=https%3A%2F%2Fhcp.alvesco.us%2Flegal-statement&clientSpecificFontAwesome=1246-1000&copy=%3Csup%3E%26copy%3B%3C%2Fsup%3E",
                "description": "Alvesco Samples: Healthcare providers may request samples for their practice by logging into the website.",
                "brand": "Alvesco",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab8c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab91",
                "link": "https://www.amzeeq.com/acne-patient-resources#savings",
                "description": "Amzeeq Savings Card: Eligible commercially insured/Rx not covered patients may pay $95 on each of up to 12 prescriptions; for additional information contact the program at 855-965-1334.",
                "brand": "Amzeeq",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab91"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab96",
                "link": "https://analpram.com/",
                "description": "Analpram HC Instant Savings Offer Rebate: If the pharmacy is unable to process the Savings Offer commercially insured patients may submit a rebate request in order to reimbursed their out-of-pocket cost over $30; rebate instructions are found at the bottom of the Savings Card; for additional information contact the program at 844-732-3521.",
                "brand": "Analpram HC",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab96"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab9b",
                "link": "https://www.apexicon.com/apx_web_resources_savings.shtml",
                "description": "ApexiCon E Cream PharmaDerm RxSaver Program: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 844-396-8097.",
                "brand": "ApexiCon E",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab9b"
            },
            {
                "_id": "6297bcabe0e0db6c2572aba0",
                "link": "https://www.apidra.com/savings",
                "description": "Apidra Valyou Savings Rebate: Eligible uninsured and cash-paying patients using a mail-order pharmacy can submit a request for a rebate up to the amount of savings earned with the Savings Card; or additional information contact the program at 855-984-6302.",
                "brand": "Apidra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aba0"
            },
            {
                "_id": "6297bcabe0e0db6c2572aba5",
                "link": "https://www.aplenzin.com/savings",
                "description": "Aplenzin Copay Savings Program: Eligible commercially insured patients will pay the first $5 on each of up to 13 30-day prescription; for additional information contact the program at 844-556-3476.",
                "brand": "Aplenzin",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aba5"
            },
            {
                "_id": "6297bcabe0e0db6c2572abe2",
                "link": "https://www.copayassistancenow.com/eligibility",
                "description": "Avastin Genentech Oncology Co-pay Assistance Program:  Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Avastin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abe2"
            },
            {
                "_id": "6297bcabe0e0db6c2572abe7",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno 1 percent Hydrocortisone Anti Itch Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abe7"
            },
            {
                "_id": "6297bcabe0e0db6c2572abec",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Baby Eczema Therapy Soothing Bath Treatment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abec"
            },
            {
                "_id": "6297bcabe0e0db6c2572abf1",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Clear Complexion Daily Cleansing Exfoliating Pads for Face",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abf1"
            },
            {
                "_id": "6297bcabe0e0db6c2572abee",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Calm plus Restore Oat Gel Moisturizer for Sensitive Skin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abee"
            },
            {
                "_id": "6297bcabe0e0db6c2572abf3",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Clear Complexion Foaming Salicylic Acid Face Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abf3"
            },
            {
                "_id": "6297bcabe0e0db6c2572abf8",
                "link": "https://www.aveenomd.com/samples",
                "description": "Aveeno Samples: Healthcare providers may order samples of select Aveeno products for their practice by visiting the website.",
                "brand": "Aveeno Eczema Therapy Itch Relief Balm",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abf8"
            },
            {
                "_id": "6297bcabe0e0db6c2572abfd",
                "link": "https://www.aveenomd.com/samples",
                "description": "Aveeno Samples: Healthcare providers may order samples of select Aveeno products for their practice by visiting the website.",
                "brand": "Aveeno Restorative Skin Therapy Oat Repairing Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abfd"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac02",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Skin Relief Moisture Repair Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac02"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab98",
                "link": "https://www.anoro.com/savings-support/savings/eligibility/",
                "description": "Anoro Ellipta Savings Offer: Eligible cash-paying patients and commercially insured patients without coverage for RX may save up to $100 on each 30-day prescription; for additional information contact the program at 888-825-5249.",
                "brand": "Anoro Ellipta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab98"
            },
            {
                "_id": "6297bcabe0e0db6c2572ab9d",
                "link": "https://www.apidra.com/savings",
                "description": "Apidra $0 Co-Pay Savings Program: Eligible commercial/private insured patients may pay $0 co-pay with savings of up to $100 per prescription; for additional assistance contact the program at 855-984-6302.",
                "brand": "Apidra",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ab9d"
            },
            {
                "_id": "6297bcabe0e0db6c2572aba2",
                "link": "https://www.apidra.com/savings",
                "description": "Apidra $0 Co-Pay Program Mail-in Rebate: Most eligible commercially insured patients using a mail-order pharmacy may submit a rebate request for up $100 per prescription; must activate the copay card first; for additional information contact the program at 855-984-6302.",
                "brand": "Apidra SoloStar Pen",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aba2"
            },
            {
                "_id": "6297bcabe0e0db6c2572aba7",
                "link": "https://www.aplenzin.com/savings",
                "description": "Aplenzin Copay Savings Program: Eligible commercially insured patients/RX not covered will pay the first $100 on each of up to 13 30-day prescription; for additional information contact the program at 844-556-3476.",
                "brand": "Aplenzin",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aba7"
            },
            {
                "_id": "6297bcabe0e0db6c2572abac",
                "link": "https://www.aquaphorus.com/sign-up/",
                "description": "Sign up for exclusive savings offers available from Aquaphor.",
                "brand": "Aquaphor Baby Diaper Rash Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abac"
            },
            {
                "_id": "6297bcabe0e0db6c2572abb1",
                "link": "https://www.aquaphorus.com/sign-up/",
                "description": "Sign up for exclusive savings offers available from Aquaphor.",
                "brand": "Aquaphor Itch Relief Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abb1"
            },
            {
                "_id": "6297bcabe0e0db6c2572abb6",
                "link": "https://www.orthorxaccess.com/",
                "description": "Arazlo Rx Access Program: Eligible uninsured/cash-pay patients may pay up to $65 per fill; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Arazlo",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abb6"
            },
            {
                "_id": "6297bcabe0e0db6c2572abbb",
                "link": "https://www.aristada.com/copay-savings",
                "description": "Aristada Co-pay Savings Program Rebate: Eligible commercially insured patients may frequest a rebate if they paid out of pocket for their prescription after activating their Copay Card; for more information contact the program at 800-657-7613.",
                "brand": "ARISTADA",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abbb"
            },
            {
                "_id": "6297bcabe0e0db6c2572abc0",
                "link": "https://www.boironusa.com/request-a-sample-form/",
                "description": "Arnicare Gel Samples: Healthcare professionals may request FREE samples by filling out a form online.",
                "brand": "Arnicare",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abc0"
            },
            {
                "_id": "6297bcabe0e0db6c2572abc5",
                "link": "https://www.pfizermedicalinformation.com/en-us/arthrotec",
                "description": "Arthrotec Samples: Your healthcare provider may request samples; for additional information contact the program at 866-706-2400.",
                "brand": "Arthrotec",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abc5"
            },
            {
                "_id": "6297bcabe0e0db6c2572abca",
                "link": "https://www.asmanex.com/asmanex-twisthaler/",
                "description": "Asmanex Twisthaler Savings Coupon: Eligible commercially insured  patients may pay as little as $15 on each of up to 12 prescriptions for savings of up to $90 per month; for additional information contact the program at 877-264-2454.",
                "brand": "Asmanex Twisthaler",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1080",
                "lifetime_uses": "12",
                "max_saving_transaction": 90,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abca"
            },
            {
                "_id": "6297bcabe0e0db6c2572abcf",
                "link": "https://aspercreme.com/coupons/",
                "description": "Save $1 on any Aspercreme product (excluding 1 patch. 1.25 oz cream and trial & travel sizes); one coupon per purchase.",
                "brand": "Aspercreme Lidocaine Foot Pain Creme",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abcf"
            },
            {
                "_id": "6297bcabe0e0db6c2572abd4",
                "link": "https://aspercreme.com/coupons/",
                "description": "Save $1 on any Aspercreme product (excluding 1 patch. 1.25 oz cream and trial & travel sizes); one coupon per purchase.",
                "brand": "Aspercreme Pain Relieving Creme with Lidocaine",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abd4"
            },
            {
                "_id": "6297bcabe0e0db6c2572abaa",
                "link": "https://www.aptiom.com/coverage-and-savings/aptiom-savings/",
                "description": "Aptiom Savings Card: Eligible patients may pay as little as $10 on each of up to 12 prescriptions with savings of up to $75 per month; offer good for 12 prescriptions per calendar year; for additional information contact the program at 844-427-8466.",
                "brand": "Aptiom",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "900",
                "lifetime_uses": "12",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abaa"
            },
            {
                "_id": "6297bcabe0e0db6c2572abaf",
                "link": "https://www.aquaphorus.com/sign-up/",
                "description": "Sign up for exclusive savings offers available from Aquaphor.",
                "brand": "Aquaphor Healing Ointment for Minor Wound Care Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abaf"
            },
            {
                "_id": "6297bcabe0e0db6c2572abb4",
                "link": "https://www.aralastnp.com",
                "description": "Aralast NP OnePath Co-Pay Assistance Program: Eligible commercially insured patients may be able may save on certain out-of-pocket treatment costs; for additional information contact the program at 866-888-0660.",
                "brand": "Aralast NP",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abb4"
            },
            {
                "_id": "6297bcabe0e0db6c2572abb9",
                "link": "https://www.arimidex.com/about-arimidex-patient-direct/",
                "description": "Arimidex Direct Program: Eligible patients may pay only $60 per 30-day supply or $180 for 90-day supply, including free shipping; please note this is a mail order program; for additional information contact the program at 855-250-2483.",
                "brand": "Arimidex",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abb9"
            },
            {
                "_id": "6297bcabe0e0db6c2572abbe",
                "link": "https://www.digihaler.com/#savings",
                "description": "Armonair Digihaler Savings Program: Eligible commercially insured patients may pay as little as $20 per prescription; for more information contact the program at 866-955-9463.",
                "brand": "ArmonAir Digihaler",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abbe"
            },
            {
                "_id": "6297bcabe0e0db6c2572abc3",
                "link": "https://www.boironusa.com/arnicare-roll-on-coupon/",
                "description": "$2 coupon for Arnicare Roll-On product; 1 coupon per purchase.",
                "brand": "Arnicare Topical Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abc3"
            },
            {
                "_id": "6297bcabe0e0db6c2572abc8",
                "link": "https://www.asmanex.com/asmanex-hfa/",
                "description": "Asmanex HFA Savings Coupon Rebate: If a commercially insured patient is unable to use the Savings Coupon at their retail or mail-order pharmacy they can call McKesson at 877-264-2454 to request a Direct Member Reimbursement form; patient must call within 30 days of purchase and keep their receipt.",
                "brand": "Asmanex HFA",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1080",
                "lifetime_uses": "12",
                "max_saving_transaction": 90,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abc8"
            },
            {
                "_id": "6297bcabe0e0db6c2572abcd",
                "link": "https://aspercreme.com/coupons/",
                "description": "Save $2 on any Aspercreme Arthritis product; one coupon per purchase.",
                "brand": "Aspercreme Arthritis Pain Relief Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abcd"
            },
            {
                "_id": "6297bcabe0e0db6c2572abd2",
                "link": "https://aspercreme.com/coupons/",
                "description": "Save $1 on any Aspercreme product (excluding 1 patch. 1.25 oz cream and trial & travel sizes); one coupon per purchase.",
                "brand": "Aspercreme Lidocaine Patch products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abd2"
            },
            {
                "_id": "6297bcabe0e0db6c2572abd7",
                "link": "https://astroglide.com/sample",
                "description": "Receive a FREE sample of any one Astroglide Product; 1 sample per household; must be at least 18 years old to receive sample.",
                "brand": "Astroglide Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abd7"
            },
            {
                "_id": "6297bcabe0e0db6c2572abd9",
                "link": "https://www.gileadadvancingaccess.com/financial-support/gilead-copay-card",
                "description": "Atripla Advancing Access Co-Pay Program: Eligible commercially insured patients save up to $6000 in co-pay costs per year with no monthly limits; for additional information contact the program at 800-226-2056.",
                "brand": "Atripla",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "ACCESS",
                "copay_max": "6000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abd9"
            },
            {
                "_id": "6297bcabe0e0db6c2572abde",
                "link": "https://www.austedo.com/tardive-dyskinesia/support",
                "description": "Austedo Copay Program: Eligible commercially insured patients may pay $0 copay per 30-day fill; for additional information contact the program at 800-887-8100. FREE 30 DAY TRIAL",
                "brand": "Austedo",
                "copay": "0",
                "bin": "004682",
                "rxpcn": "CN",
                "copay_max": "0.00",
                "lifetime_uses": "12",
                "max_saving_transaction": 8400,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abde"
            },
            {
                "_id": "6297bcabe0e0db6c2572abe3",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Avastin Genentech Oncology Co-pay Assistance Program Rebate:  Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Avastin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abe3"
            },
            {
                "_id": "6297bcabe0e0db6c2572abe8",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Anti Itch Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abe8"
            },
            {
                "_id": "6297bcabe0e0db6c2572abdc",
                "link": "https://www.aubagio.com/cost",
                "description": "Aubagio $0 Co-Pay Program: Eligible commercially insured patients will have pay as little as $0 co-pay per prescription; for additional information contact the program at 855-676-6326.",
                "brand": "Aubagio",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abdc"
            },
            {
                "_id": "6297bcabe0e0db6c2572abe1",
                "link": "https://avarinfo.com/about-avar/",
                "description": "Avar Savings Card: Eligible commercially insured patients may pay as little as $20 per fill on each of up to 12 prescriptions; for additional information contact the program at 347-766-9883.",
                "brand": "Avar Family Products",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abe1"
            },
            {
                "_id": "6297bcabe0e0db6c2572abf6",
                "link": "https://www.aveenomd.com/samples",
                "description": "Aveeno Samples: Healthcare providers may order samples of select Aveeno products for their practice by visiting the website.",
                "brand": "Aveeno Eczema Therapy Daily Moisturizing Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abf6"
            },
            {
                "_id": "6297bcabe0e0db6c2572abfb",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Repairing CICA Hand Mask",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abfb"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac00",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Skin Relief Body Wash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac00"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac05",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Skin Relief Moisturizing Lotion for Extra Dry Skin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac05"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac0a",
                "link": "https://www.avonex.com/en_us/home/support-and-events/financial-information.html",
                "description": "Avonex Biogen Copay Program: Eligible commercially insured patients may pay $0 copay per prescription; for additional information contact the 800-456-2255.",
                "brand": "Avonex",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac0a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac0f",
                "link": "https://yourblueprint.com/financial-assistance/",
                "description": "Ayvakit Dose Exchange Program: Eligible commercially insured patients may be able to trade in their remaining medication at no cost if their healthcare provider changes their dosage; for additional information contact the program at 888-258-7768.",
                "brand": "Ayvakit",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac0f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac14",
                "link": "https://www.azoproducts.com/sign-up-for-azo-offers",
                "description": "Register your email address to receive special offers on AZO products; 1 coupon per purchase.",
                "brand": "AZO Bladder Control",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac14"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac19",
                "link": "https://www.azoproducts.com/sign-up-for-azo-offers",
                "description": "Register your email address to receive special offers on AZO products; 1 coupon per purchase.",
                "brand": "AZO Dual Bladder Urinary and Vaginal Support",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac19"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac1e",
                "link": "https://www.azoprofessional.com/samples",
                "description": "AZO Samples: Healthcare providers may request samples by filling out the form on the webpage.",
                "brand": "AZO Urinary Pain Relief Maximum Strength",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac1e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac23",
                "link": "https://azstarys.com/savings-and-support/",
                "description": "Azstarys Copay Savings Card: Eligible commercially insured patients pay $0 for their 1st prescription and then pay as little as $50 per each subsequent fill; for additional information contact the program at 800-910-8432.",
                "brand": "Azstarys",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac23"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac28",
                "link": "https://www.balcoltra.com/balcoltrasavings/",
                "description": "Balcoltra Savings Program: Eligible uninsured/cash-pay patients will save on their prescription fills; patient should check with their pharmacist for their exact discount amount; for additional information contact the program at 877-838-3846.",
                "brand": "Balcoltra",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac28"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac2d",
                "link": "https://balmex.com",
                "description": "Save $2 on any one Balmex diaper rash product; 1 coupon per purchase.",
                "brand": "Balmex Complete Protection Diaper Rash Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac2d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac32",
                "link": "https://www.balversa.com/support-resources/cost-support",
                "description": "Balversa Janssen CarePath Savings Program: Eligible commercially insured patients may pay no more than $5 per fill with a maximum savings of up to $25,000 per calendar year; for additional information contact the program at 877-227-3728.",
                "brand": "Balversa",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac32"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac37",
                "link": "https://www.basaglar.com/support/savings",
                "description": "Basaglar Savings Card: Eligible commercially insured patients may pay as little as $5 per prescription (1 carton of 5 Kwikpens) with savings of up to $150 per month; annual maximum savings of $1800; for additional information contact the program at 800-545-5979.",
                "brand": "Basaglar KwikPen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac37"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac3c",
                "link": "https://www.bayeraspirin.com/offers/",
                "description": "Save $1 off any Bayer Aspirin product 50ct or larger; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Bayer Back and Body",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac3c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac41",
                "link": "https://www.belsomra.com/savings-coupon/",
                "description": "Belsomra Savings Coupon: Eligible commercially insured patients may pay as little as $30 per prescription on each of up to 12 fills for a maximum savings of $150 per fill; for additional information contact the program at 800-672-6372.",
                "brand": "Belsomra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac41"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac46",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Instantly download a coupon for savings any one Benadryl product (excludes trial & travel size); 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Benadryl Allergy Dye-Free Liqui-Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac46"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac4b",
                "link": "https://mycareclubrewards.com/benadryl/?utm_medium=partner&utm_source=benadryl.com&utm_content=footer&utm_campaign=cc_main&_gl=1*l2ngid*_ga*MTE2MzcxNjA1Mi4xNjM0MzE1NjA4*_ga_13VEM6N66E*MTYzNDMxNTYwNi4xLjAuMTYzNDMxNTYwNi42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Benadryl products with registration.",
                "brand": "Benadryl Allergy Ultratab",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac4b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac07",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Soothing Bath Treatment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac07"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac0c",
                "link": "https://yourblueprint.com/financial-assistance/",
                "description": "Ayvakit Coverage Interruption Program: Eligible commercially insured patients may be able to receive a temporary supply if your insurance has changed or have another temporary interruption in insurance; for additional information contact the program at 888-258-7768.",
                "brand": "Ayvakit",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac0c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac11",
                "link": "http://www.azasite.com/consumer/page1.html",
                "description": "AzaSite Savings Coupon: Eligible patients may save $40 per prescription fill; for additional information contact the program at 800-657-7613.",
                "brand": "AzaSite",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac11"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac16",
                "link": "https://www.azoproducts.com/sign-up-for-azo-offers",
                "description": "Register your email address to receive special offers on AZO products; 1 coupon per purchase.",
                "brand": "AZO Cranberry Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac16"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac1b",
                "link": "https://www.azoproducts.com/sign-up-for-azo-offers",
                "description": "Register your email address to receive special offers on AZO products; 1 coupon per purchase.",
                "brand": "AZO Test Strip",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac1b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac20",
                "link": "https://www.azoproducts.com/sign-up-for-azo-offers",
                "description": "Register your email address to receive special offers on AZO products; 1 coupon per purchase.",
                "brand": "AZO Yeast Plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac20"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac25",
                "link": "https://www.pfizermedicalinformation.com/en-us/azulfidine",
                "description": "Azulfidine Samples: Your healthcare provider may order samples by visiting the PfizerPro website or calling 800-505-4426.",
                "brand": "Azulfidine",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac25"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac2a",
                "link": "https://www.balcoltra.com/hcp/order-samples-for-your-practice/",
                "description": "Balcoltra Samples: Healthcare professionals may order samples for their office by submitting an order form online.",
                "brand": "Balcoltra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac2a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac2f",
                "link": "https://balmex.com",
                "description": "Save $2 on any one Balmex diaper rash product; 1 coupon per purchase.",
                "brand": "Balmex Multi-Purpose Healing Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac2f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac50",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Save $1 on any one Benadryl product (excludes trial & travel size); 1 coupon per purchase.",
                "brand": "Benadryl Childrens Allergy Liquid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac50"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac55",
                "link": "https://mycareclubrewards.com/benadryl/?utm_medium=partner&utm_source=benadryl.com&utm_content=footer&utm_campaign=cc_main&_gl=1*l2ngid*_ga*MTE2MzcxNjA1Mi4xNjM0MzE1NjA4*_ga_13VEM6N66E*MTYzNDMxNTYwNi4xLjAuMTYzNDMxNTYwNi42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Benadryl products with registration.",
                "brand": "Benadryl Childrens Itch Cooling Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac55"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac5a",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Save $1 on any one Benadryl product (excludes trial & travel size); 1 coupon per purchase.",
                "brand": "Benadryl Extra Strength Itch Stopping Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac5a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac5f",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Save $1 on any one Benadryl product (excludes trial & travel size); 1 coupon per purchase.",
                "brand": "Benadryl Original Strength Itch Stopping Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac5f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac64",
                "link": "https://mycareclubrewards.com/bengay/?utm_medium=partner&utm_source=bengay.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1xoul7s*_ga*MTc3MDUxNTg0OS4xNjMzMDE2OTQ4*_ga_13VEM6N66E*MTYzMzEwMjEzMC4yLjEuMTYzMzEwMjE1NS4zNQ..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Bengay products with registration.",
                "brand": "Bengay Ultra Strength Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac64"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac69",
                "link": "https://benicar.com/savings",
                "description": "Benicar HCT Pre-activated Savings Card: Eligible commercially insured patients may pay as little as $5 per 30-day prescription; for additional information contact the program 877-264-2440.",
                "brand": "Benicar HCT",
                "copay": "Yes",
                "bin": "610254",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac69"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac6e",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Bepreve Bausch + Lomb Access Program: Eligible commercially insured patients may pay no more than $25 for each prescription; for additional information contact the program 866-693-4880.",
                "brand": "Bepreve",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac6e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac73",
                "link": "https://www.besivancepro.com/savings-support-resources?utm_source=google&utm_medium=ppc&utm_content=PatientSavings&utm_campaign=SiteLinks&gclid=CjwKCAjw5p_8BRBUEiwAPpJO6z04K1A3dMHqVrJaQlRaVaEkm3oek64MARR6LtiMTY0NyL2ZjQ_KjBoCAqEQAvD_BwE",
                "description": "Besivance Free Samples: Your healthcare provider must register to the Bausch Sample Vault program to request free samples; for additional information contact the program at 855-770-0191.",
                "brand": "Besivance",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac73"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac78",
                "link": "https://www.betaseron.com/betaplus-support-program/financial-support",
                "description": "Betaseron ZERO Dollar Copay Program: Eligible commercially insured patients may pay $0 per month for as long as patient is prescribed Betaseron; maximum copay assistance is $14,500 per calendar year; for additional information contact the program at 844-788-1470.",
                "brand": "Betaseron",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "14500",
                "lifetime_uses": "12",
                "max_saving_transaction": 1208.33,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac78"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac34",
                "link": "https://www.baqsimi.com/patient-support",
                "description": "Baqsimi Savings Card: Eligible commercially insured patients may pay as little as $25 on refills for up to 2 devices (1 two-pack or 2 one-packs); for additional information contact the program at 800-545-5979.",
                "brand": "Baqsimi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac34"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac39",
                "link": "https://www.bayeraspirin.com/offers/",
                "description": "Save $1 off any Bayer Aspirin product 50ct or larger; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Bayer Aspirin Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac39"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac3e",
                "link": "https://www.belbuca.com/savings",
                "description": "Belbuca Copay Card: Eligible patients may pay $0 copay for the first months prescription and pay no more than $25 per prescription thereafter; for additional information contact the program at 855-923-5282.",
                "brand": "Belbuca",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac3e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac43",
                "link": "https://www.belsomra.com/savings-coupon/",
                "description": "Belsomra Savings Coupon: Eligible commercially insured patients/RX not covered may save up to $150 per 30-day prescription fill; offer valid for 12 fills; for additional information contact the program at 800-672-6372.",
                "brand": "Belsomra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac43"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac48",
                "link": "https://mycareclubrewards.com/benadryl/?utm_medium=partner&utm_source=benadryl.com&utm_content=footer&utm_campaign=cc_main&_gl=1*l2ngid*_ga*MTE2MzcxNjA1Mi4xNjM0MzE1NjA4*_ga_13VEM6N66E*MTYzNDMxNTYwNi4xLjAuMTYzNDMxNTYwNi42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Benadryl products with registration.",
                "brand": "Benadryl Allergy Dye-Free Liqui-Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac48"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac4d",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Save $1 on any one Benadryl product (excludes trial & travel size); 1 coupon per purchase.",
                "brand": "Benadryl Childrens Allergy Dye-Free Liquid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac4d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac52",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Save $1 on any one Benadryl product (excludes trial & travel size); 1 coupon per purchase.",
                "brand": "Benadryl Childrens Allergy Plus Congestion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac52"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac57",
                "link": "https://mycareclubrewards.com/benadryl/?utm_medium=partner&utm_source=benadryl.com&utm_content=footer&utm_campaign=cc_main&_gl=1*l2ngid*_ga*MTE2MzcxNjA1Mi4xNjM0MzE1NjA4*_ga_13VEM6N66E*MTYzNDMxNTYwNi4xLjAuMTYzNDMxNTYwNi42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Benadryl products with registration.",
                "brand": "Benadryl Extra Strength Itch Relief Stick",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac57"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac5c",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Save $1 on any one Benadryl product (excludes trial & travel size); 1 coupon per purchase.",
                "brand": "Benadryl Itch Cooling Spray Extra Strength",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac5c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac61",
                "link": "https://www.benefix.com/resources/financial-support",
                "description": "Benefix Pfizer Factor Savings Card: Eligible commercially insured patients may save up to $12,000 per calendar year; for additional information contact the program at 844-989-4366.",
                "brand": "Benefix",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac61"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac66",
                "link": "https://mycareclubrewards.com/bengay/?utm_medium=partner&utm_source=bengay.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1xoul7s*_ga*MTc3MDUxNTg0OS4xNjMzMDE2OTQ4*_ga_13VEM6N66E*MTYzMzEwMjEzMC4yLjEuMTYzMzEwMjE1NS4zNQ..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Bengay products with registration.",
                "brand": "Bengay Vanishing Scent Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac66"
            },
            {
                "_id": "6297bcabe0e0db6c2572abed",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Calm plus Restore Nourishing Oat Cleanser for Sensitive SKin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abed"
            },
            {
                "_id": "6297bcabe0e0db6c2572abf2",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Clear Complexion Daily Moisturizer",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abf2"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac6b",
                "link": "https://www.benlysta.com/benlysta-savings-and-support/coverage/",
                "description": "Benlysta Co-pay Card Program: Eligible patients may pay as little as $0 per monthly prescription with savings of up to $15,000 per year; for additional information contact the program at 877-423-6597.",
                "brand": "Benlysta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac6b"
            },
            {
                "_id": "6297bcabe0e0db6c2572abf7",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Eczema Therapy Daily Moisturizing Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abf7"
            },
            {
                "_id": "6297bcabe0e0db6c2572abfc",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Restorative Skin Therapy Itch Relief Balm",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abfc"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac70",
                "link": "https://www.berinert.com/patients/support-services/berinert-connect",
                "description": "Berinert CSL Behring Assurance Program: Eligible commercially insured patients may continue to receive their medication during a lapse in insurance coverage; for additional information contact the program at 877-236-4423.",
                "brand": "Berinert",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac70"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac01",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Skin Relief Hand Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac01"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac75",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Besivance Bausch + Lomb Access Program: Eligible commercially insured patients may pay no more than $25 for each prescription; for additional information contact the program 866-693-4880.",
                "brand": "Besivance",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac75"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac06",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Skin Relief Overnight Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac06"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac7a",
                "link": "https://www.betimol.com",
                "description": "Betimol Free Samples: Your healthcare provider may request Free Samples by registering; for additional information contact the program at 800-932-5676.",
                "brand": "Betimol",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac7a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac7f",
                "link": "https://arborpatientdirect.com",
                "description": "BiDil Arbor Patient Direct: Eligible patients may pay as little as $40 plus shipping for a 30-day supply or $120 for a 90-day supply with FREE shipping by enrolling in the program; for additional information contact the program at 844-289-3981.",
                "brand": "BiDil",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac7f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac84",
                "link": "https://www.gileadadvancingaccess.com/financial-support/gilead-copay-card",
                "description": "Biktarvy Advancing Access Co-pay Program: Eligible commercially insured patients may save up to a maximum of $7200 per year with no monthly limits; for additional information contact the program at 800-226-2056.",
                "brand": "Biktarvy",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "ACCESS",
                "copay_max": "7200",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac84"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac89",
                "link": "https://www.biotene.com/save-now/",
                "description": "Biotene Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Biotene Dry Mouth Lozenge",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac89"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac8e",
                "link": "https://www.blenrep.com/savings-and-support/",
                "description": "Blenrep GSK Co-pay Program: Eligible patients may receive financial assistance up to $100 per medication cost & administration; maximum annual savings of $26,000; for more information contact the program at 844-447-5662.",
                "brand": "Blenrep",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "26000",
                "lifetime_uses": "0",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac8e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac93",
                "link": "https://www.bonjesta.com/bonjesta-savings",
                "description": "Bonjesta At Home Program: Eligible cash-paying patients pay $60 for 30 tablets and $99 for 60 tablets, plus receive free home delivery; for additional information contact the program at 800-716-4663.",
                "brand": "Bonjesta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac93"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac98",
                "link": "https://www.pfizeroncologytogether.com/patient/signup",
                "description": "Bosulif Pfizer Oncology Together Co-Pay Savings Program (oral products): Eligible commercially insured patients may pay as little as $0 per prescription with savings of up to $25,000 per calendar year; for additional information contact the program at 877-744-5675.",
                "brand": "Bosulif",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac98"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac7d",
                "link": "https://bidil.com/hcp/patient-savings-support#ezRx",
                "description": "BiDil E-Z Rx Program: Eligible commercially insured patients may pay just $10 for each monthly supply (up to 90 tablets) at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "BiDil",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac7d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac82",
                "link": "https://savings.bijuva.com/terms-and-conditions",
                "description": "Bijuva Cash Pay Program: Eligible commercially insured patients/RX not covered and cash-paying patients may pay as little as $75 per 30-day prescription or no more than $225 per 90-day prescription; for additional information contact the program at 844-536-6711.",
                "brand": "Bijuva",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac82"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac87",
                "link": "https://binostosavings.com/card",
                "description": "Binosto Co-Pay Card Program: Eligible patients may pay $40 per monthly prescription with a savings of $100; for additional information contact the program at 877-204-1013,",
                "brand": "Binosto",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac87"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac8c",
                "link": "https://www.biotene.com/save-now/",
                "description": "Biotene Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Biotene Oralbalance Moisturizing Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac8c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac91",
                "link": "https://www.boironusa.com/single-medicines-coupon/",
                "description": "$2 coupon off Boiron Blue Tube Single Medications with registration; 1 coupon per purchase.",
                "brand": "Boiron Single Medicine Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac91"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac96",
                "link": "https://hcp.bonjesta.com/#orderingsamples",
                "description": "Bonjesta Samples: Healthcare professionals may request samples by faxing an order form to 973-644-2386.",
                "brand": "Bonjesta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac96"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac0b",
                "link": "https://www.avsola.com/patient/amgen-support-and-resources",
                "description": "Avsola Co-pay Program: Eligible commercially insured patients may pay as little as $5 out-of-pocket for each treatment; for additional information contact the program at 866-264-2778.",
                "brand": "Avsola",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac0b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac10",
                "link": "https://www.mysamplecloset.com/akornglaucomasamples",
                "description": "AzaSite Samples: Your healthcare provider may request Free Samples by registering to MySampleCloset.com; for additional information contact the program at 800-932-5676.",
                "brand": "AzaSite",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac10"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac15",
                "link": "https://www.azoprofessional.com/samples",
                "description": "AZO Samples: Healthcare providers may request samples by filling out the form on the webpage.",
                "brand": "AZO Cranberry Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac15"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac1a",
                "link": "https://www.azoproducts.com/sign-up-for-azo-offers",
                "description": "Register your email address to receive special offers on AZO products; 1 coupon per purchase.",
                "brand": "AZO Men Bladder Control with Go-Less",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac1a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac1f",
                "link": "https://www.azoproducts.com/products/azo-yeast-plus",
                "description": "Register your email address to receive special offers on AZO products; 1 coupon per purchase.",
                "brand": "AZO Urinary Tract Defense",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac1f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac24",
                "link": "https://azstarys.com/savings-and-support/",
                "description": "Azstarys Copay Savings Card: Eligible commercially insured patients/RX not covered pay $0 for their 1st prescription and then pay as little as $75 per each subsequent fill; for additional information contact the program at 800-910-8432.",
                "brand": "Azstarys",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac24"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac29",
                "link": "https://svc.opushealth.com/balcoltrasavings/",
                "description": "Balcoltra Savings Program: Eligible commercially insured patients pay only $21 per prescription with savings of up to $170 per month; for additional information contact the program at 877-838-3846.",
                "brand": "Balcoltra",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "2040",
                "lifetime_uses": "12",
                "max_saving_transaction": 170,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac29"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac2e",
                "link": "https://balmex.com/balmex-coupon/",
                "description": "Save $2 on any one Balmex diaper rash product; 1 coupon per purchase.",
                "brand": "Balmex Diaper Rash Cream with ActivGuard",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac2e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac33",
                "link": "https://mycareclubrewards.com/band-aid/?utm_medium=partner&utm_source=band-aid.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1uimwm5*_ga*ODY1NjY3Njk1LjE2MzMzNjIwMjY.*_ga_13VEM6N66E*MTYzMzM2MjAyNi4xLjEuMTYzMzM2MjM0Ni40NA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Band-Aid products with registration.",
                "brand": "Band-Aid Brand Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac33"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac38",
                "link": "https://www.coverone.com/en/Co-Pay_Assistance.html",
                "description": "Bavencio CoverOne Co-pay Card: Eligible commercially insured patients may pay $0 per prescription with savings of up to $30,000 per calendar year; for additional information contact the program at 844-826-8371.",
                "brand": "Bavencio",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "1016",
                "copay_max": "30000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac38"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac3d",
                "link": "https://www.bayeraspirin.com/offers/",
                "description": "Save $1 off any Bayer Aspirin product 50ct or larger; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Bayer Chewable Aspirin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac3d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac9b",
                "link": "https://www.alle.com/upgrade",
                "description": "Botox Alle Points Program: Register and earn points redeemable for dollars OFF certain in-office treatments; also receive personalized special offers; for additional information contact the program at 888-912-1572.",
                "brand": "Botox",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac9b"
            },
            {
                "_id": "6297bcabe0e0db6c2572aca0",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Braftovi Pfizer Oncology Together Co-Pay Savings Program Rebate (oral products): Eligible commercially insured patients may submit a request for a rebate in connection with this offer if their pharmacy does not accept the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Braftovi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aca0"
            },
            {
                "_id": "6297bcabe0e0db6c2572aca5",
                "link": "https://www.breatheright.com/get-a-coupon/",
                "description": "Receive $2 coupon for any Breathe Right product; 1 coupon per purchase; coupon expires one month after printing.",
                "brand": "Breathe Right Extra Strength Clear",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aca5"
            },
            {
                "_id": "6297bcabe0e0db6c2572acaa",
                "link": "https://www.breatheright.com/get-a-coupon/",
                "description": "Receive $2 coupon for any Breathe Right product; 1 coupon per purchase; coupon expires one month after printing.",
                "brand": "Breathe Right Original Tan",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acaa"
            },
            {
                "_id": "6297bcabe0e0db6c2572acaf",
                "link": "https://www.breztri.com/savings-support.html",
                "description": "Breztri Zero Pay Card: Eligible cash-paying patients may save $100 per 30-day supply (1 inhaler); for more information contact the program at 833-458-0440.",
                "brand": "Breztri Aerosphere",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acaf"
            },
            {
                "_id": "6297bcabe0e0db6c2572acb4",
                "link": "https://www.brilinta.com/brilinta-coupon-savings/commercial-insurance.html",
                "description": "Brilinta Savings Card: Eligible commercially insured patients may pay as low as $5 per 30-day supply with savings of up to $200 per prescription; for additional information contact the program at 888-512-7454.",
                "brand": "Brilinta",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "2400",
                "lifetime_uses": "12",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acb4"
            },
            {
                "_id": "6297bcabe0e0db6c2572acb9",
                "link": "https://www.briviact.com/savings-card",
                "description": "Briviact Patient Savings Card: Eligible commercially insured patients may pay as little as $10 per 30-day supply with a maximum benefit of $1300 per calendar year; for additional information contact the program at 888-786-5879.",
                "brand": "Briviact",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "1300",
                "lifetime_uses": "12",
                "max_saving_transaction": 108.33,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acb9"
            },
            {
                "_id": "6297bcabe0e0db6c2572acbe",
                "link": "https://bronchitol.com/savings-and-support/",
                "description": "Bronchitol $0 Copay Assistance Program: Eligible commercially insured patients pay as little as $0 per prescription up to a monthly maximum of $860; for additional information contact the program at 888-865-1222.",
                "brand": "Bronchitol",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10320",
                "lifetime_uses": "12",
                "max_saving_transaction": 860,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acbe"
            },
            {
                "_id": "6297bcabe0e0db6c2572acc3",
                "link": "https://www.orthorxaccess.com/",
                "description": "Bryhali Rx Access Program: Eligible commercially insured patients pay $25 per prescription; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Bryhali",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acc3"
            },
            {
                "_id": "6297bcabe0e0db6c2572acc8",
                "link": "https://www.bydureon.com/bydureon-bcise/savings-and-support.html#savings-card",
                "description": "Bydureon BCise Savings Card: Eligible cash-paying patients may save up to $150 per 28-day supply prescription; for additional information contact the program at 866-680-9081.",
                "brand": "Bydureon BCise",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acc8"
            },
            {
                "_id": "6297bcabe0e0db6c2572accd",
                "link": "https://bystolic.rx-comp.com",
                "description": "Bystolic Savings Card: Most eligible commercially insured patients pay as little as $35 per 30-day prescription fill; offer valid 12 times;  for additional information contact the program at 800-572-5252.",
                "brand": "Bystolic",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572accd"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac42",
                "link": "https://www.belsomra.com/savings-coupon/",
                "description": "Belsomra Free Trial Offer: Eligible patients may receive 1 FREE 10-tablet trial with voucher; for additional information contact the program at 800-672-6372.",
                "brand": "Belsomra",
                "copay": "No",
                "bin": "610524",
                "rxpcn": "1016",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac42"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac47",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Save $1 on any one Benadryl product (excludes trial & travel size); 1 coupon per purchase.",
                "brand": "Benadryl Allergy Dye-Free Liqui-Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac47"
            },
            {
                "_id": "6297bcabe0e0db6c2572acd2",
                "link": "https://www.caduet.com/savings",
                "description": "Caduet Savings Card: Eligible patients may pay as little as $4 per 30-day prescription with savings of up to $1800 per year; for additional information contact the program at 877-223-8384.",
                "brand": "Caduet",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acd2"
            },
            {
                "_id": "6297bcabe0e0db6c2572acd7",
                "link": "https://www.cambiarx.com/hcp/get-in-touch/",
                "description": "Cambia Scripts Rx Program: Eligible commercially insured patients may pay a $0 copay per prescription when using this program; patients should contact their healthcare professional to take advantage of this offer; for additional information contact the program at 844-546-8634.",
                "brand": "Cambia",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acd7"
            },
            {
                "_id": "6297bcabe0e0db6c2572acdc",
                "link": "https://www.caplytahcp.com/resources-support-savings",
                "description": "Caplyta Samples: Healthcare providers may request samples for their office online or by calling 888-252-4824.",
                "brand": "Caplyta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acdc"
            },
            {
                "_id": "6297bcabe0e0db6c2572ace1",
                "link": "https://www.mycarmex.com",
                "description": "Sign-up with Carmex to receive special offers by entering your email address on the homepage.",
                "brand": "Carmex Classic Lip Balm product",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ace1"
            },
            {
                "_id": "6297bcabe0e0db6c2572ace6",
                "link": "https://www.pfizermedicalinformation.com/en-us/caverject-impulse-alprostadil",
                "description": "Caverject Impulse Samples: Healthcare providers may be able to order samples by logging onto the PfizerPro website or calling 800-505-4426.",
                "brand": "Caverject Impulse",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ace6"
            },
            {
                "_id": "6297bcabe0e0db6c2572aceb",
                "link": "https://www.cellcept.com/patient/cellcept-copay-card.html",
                "description": "Cellcept Co-Pay Card Program: Eligible commercially insured patients may pay no more than $15 per monthly prescription with savings of up to $10,000 per year; card needs to be renewed every year; for additional information contact the program at 833-235-5237.",
                "brand": "CellCept",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "12",
                "max_saving_transaction": 833.34,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aceb"
            },
            {
                "_id": "6297bcabe0e0db6c2572acf0",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Adults Fresh and Fruity Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acf0"
            },
            {
                "_id": "6297bcabe0e0db6c2572acf5",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Mental Focus Whole Food Blend Supplement",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acf5"
            },
            {
                "_id": "6297bcabe0e0db6c2572acfa",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Multi plus Omega-3 Women 50 plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acfa"
            },
            {
                "_id": "6297bcabe0e0db6c2572acff",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum MultiGummies Multi plus Antioxidants",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acff"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad04",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Natural Beauty Whole Food Blend Supplement",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad04"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad09",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Stress Mood Whole Food Blend Supplement",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad09"
            },
            {
                "_id": "6297bcabe0e0db6c2572abe6",
                "link": "https://www.aveedusa.com/resources/",
                "description": "Aveed Copay Assistance Program Rebate: Eligible patients may submit a rebate request form if they already paid in full for treatment while eligible for the program but not enrolled; for additional information contact the program at 800-381-2638.",
                "brand": "Aveed",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abe6"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad0e",
                "link": "https://www.rbhcprofessional.com/s/contact-us",
                "description": "Cepacol Samples: Healthcare providers may order samples for their office online.",
                "brand": "Cepacol Extra Strength Sore Throat and Cough Lozenges",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad0e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad13",
                "link": "https://ceracade.com/ceracade-savings-card-verification/",
                "description": "Ceracade Savings Card: Eligible commercially insured patients may pay as little as $20 per prescription; card can be used 12 times; for additional information contact the program at 888-786-5876.",
                "brand": "Ceracade Skin Emulsion",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad13"
            },
            {
                "_id": "6297bcabe0e0db6c2572abeb",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Baby Eczema Therapy Nighttime Balm",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abeb"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad18",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Diabetics Dry Skin Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad18"
            },
            {
                "_id": "6297bcabe0e0db6c2572abf0",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Clear Complexion Cream Face Cleanser with Salicylic Acid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abf0"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad1d",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Itch Relief Moisturizing Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad1d"
            },
            {
                "_id": "6297bcabe0e0db6c2572abf5",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Daily Moisturizing Body Wash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abf5"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad22",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe SA Cleanser-Rough and Bumpy Skin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad22"
            },
            {
                "_id": "6297bcabe0e0db6c2572abfa",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Repairing CICA Foot Mask",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abfa"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad27",
                "link": "https://www.cerefolinnac.com/",
                "description": "CerefolinNAC Brand Direct Health Program: Eligible patients may pay no more than $58 per month regardless of insurance ($174 per 90-day supply); 90-day prescriptions shipped and delivered to your home free; for additional information contact the program at 866-331-6440.",
                "brand": "CerefolinNAC",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad27"
            },
            {
                "_id": "6297bcabe0e0db6c2572abff",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Restorative Skin Therapy Sulfate-Free Body Wash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abff"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac04",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Skin Relief Moisturizing Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac04"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac09",
                "link": "https://avenova.com/",
                "description": "Register your email on the website for future offers and savings towards your purchase of Avenova 40mL Spray.",
                "brand": "Avenova 40mL Spray",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac09"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac0e",
                "link": "https://yourblueprint.com/financial-assistance/",
                "description": "Ayvakit Co-pay Assistance Program: Eligible commercially insured patients may be eligible to pay as little as $0 per prescription; for additional information contact the program at 888-258-7768.",
                "brand": "Ayvakit",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac0e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac13",
                "link": "https://www.azoprofessional.com/samples",
                "description": "AZO Samples: Healthcare providers may request coupons by filling out the form on the webpage.",
                "brand": "AZO Bladder Control",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac13"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac18",
                "link": "https://www.azoproducts.com/sign-up-for-azo-offers",
                "description": "Register your email address to receive special offers on AZO products; 1 coupon per purchase.",
                "brand": "AZO D-Mannose",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac18"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac1d",
                "link": "https://www.azoproducts.com/sign-up-for-azo-offers",
                "description": "Register your email address to receive special offers on AZO products; 1 coupon per purchase.",
                "brand": "AZO Urinary Pain Relief Maximum Strength",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac1d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac22",
                "link": "https://azor.com/savings",
                "description": "Azor Pre-activated Savings Card: Eligible commercially insured patients may pay as little as $5 per month per calendar year; for additional information contact the program 877-264-2440.",
                "brand": "Azor",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac22"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac27",
                "link": "https://www.bafiertam.com/#patient_support",
                "description": "Bafiertam $0 Copay Program: Eligible commercially insured patients enrolled in the program may pay as little as $0 per prescription; for additional information contact the program at 855-322-6637.",
                "brand": "Bafiertam",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac27"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac2c",
                "link": "https://balmexadult.com/institutional/",
                "description": "Balmex Adult Samples: Healthcare providers may request samples by filling out an online form.",
                "brand": "Balmex AdultAdvantage",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac2c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac31",
                "link": "https://www.balneol.com/en/hcps/doctors-request-samples",
                "description": "Balneol Samples: Healthcare professionals may request samples for their office by filling out an order form online.",
                "brand": "Balneol",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac31"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac36",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Basaglar KwikPen: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Basaglar KwikPen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac36"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac3b",
                "link": "https://www.bayeraspirin.com/offers/",
                "description": "Save $1 off any Bayer Aspirin product 50ct or larger; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Bayer Aspirin Regimen Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac3b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad2c",
                "link": "https://www.wraser-direct.com/",
                "description": "Cetraxal WraSer-Direct Universal Coupon Program: Eligible commercially insured patients may pay no more than $15 per prescription when using a WraSer Pharmacy Partner; for additional information contact the program at 601-605-0664.",
                "brand": "Cetraxal",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad2c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad31",
                "link": "https://www.boironusa.com/chestal-coupon/",
                "description": "$2 coupon for any Chestal cough syrup product with registration; 1 coupon per purchase.",
                "brand": "Chestal Childrens Cold and Cough",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad31"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad36",
                "link": "https://www.rbhcprofessional.com/s/resource-center?brand=Delsym",
                "description": "Delsym Samples: Healthcare providers may order samples for their office online.",
                "brand": "Childrens Delsym 12 Hour Cough Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad36"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad3b",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Childrens Tylenol Liquid Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad3b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad40",
                "link": "https://www.cinqair.com/getting-cinqair",
                "description": "Cinqair Cost Support Program: Eligible commercially insured patients may be eligible to save on their out-of-pocket costs for their medication and its administration with savings of up to $10,000 per year; for additional information contact the program at 844-838-2211.",
                "brand": "Cinqair",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad40"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac40",
                "link": "https://www.merckconnect.com/services/request-samples-vouchers-coupons/",
                "description": "Belsomra Samples: Your healthcare provider may request samples by logging into the Merck Connect website or calling 866-237-4286.",
                "brand": "Belsomra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac40"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac45",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Save $1 on any one Benadryl product (excludes trial & travel size); 1 coupon per purchase.",
                "brand": "Benadryl Allergy Childrens Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac45"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac4a",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Save $1 on any one Benadryl product (excludes trial & travel size); 1 coupon per purchase.",
                "brand": "Benadryl Allergy Plus Congestion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac4a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac4f",
                "link": "https://mycareclubrewards.com/benadryl/?utm_medium=partner&utm_source=benadryl.com&utm_content=footer&utm_campaign=cc_main&_gl=1*l2ngid*_ga*MTE2MzcxNjA1Mi4xNjM0MzE1NjA4*_ga_13VEM6N66E*MTYzNDMxNTYwNi4xLjAuMTYzNDMxNTYwNi42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Benadryl products with registration.",
                "brand": "Benadryl Childrens Allergy Dye-Free Liquid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac4f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac54",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Save $1 on any one Benadryl product (excludes trial & travel size); 1 coupon per purchase.",
                "brand": "Benadryl Childrens Itch Cooling Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac54"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad45",
                "link": "https://www.citracal.com",
                "description": "Register your email address and receive a $2 coupon for any one Citracal product (70 count or larger); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Citracal Maximum Plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad45"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad4a",
                "link": "https://www.citranatal.com/",
                "description": "CitraNatal Savings Card: Eligible commercially insured patients may pay as little as $25 per 30-day prescription; for additional information contact the program at 347-480-1771.",
                "brand": "CitraNatal Assure",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad4a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad4f",
                "link": "https://www.claritin.com/savings",
                "description": "Save $2 off any one Claritin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Claritin Chewables 24HR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad4f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad54",
                "link": "https://www.claritin.com/savings",
                "description": "Save $2 off any one Claritin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Claritin Liqui-Gels 24HR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad54"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad59",
                "link": "https://hcp-samples.bayer.com/product-samples/claritin",
                "description": "Claritin Product Samples: Healthcare providers can register online to order free samples for their practice.",
                "brand": "Claritin RediTabs Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad59"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad5e",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Clean & Clear products with registration.",
                "brand": "Clean and Clear Acne Triple Clear Exfoliating Scrub",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad5e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac59",
                "link": "https://mycareclubrewards.com/benadryl/?utm_medium=partner&utm_source=benadryl.com&utm_content=footer&utm_campaign=cc_main&_gl=1*l2ngid*_ga*MTE2MzcxNjA1Mi4xNjM0MzE1NjA4*_ga_13VEM6N66E*MTYzNDMxNTYwNi4xLjAuMTYzNDMxNTYwNi42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Benadryl products with registration.",
                "brand": "Benadryl Extra Strength Itch Stopping Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac59"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad63",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Clean & Clear products with registration.",
                "brand": "Clean and Clear Persa-Gel 10",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad63"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad68",
                "link": "https://www.clearasil.us",
                "description": "Sign up for exclusive savings and newsletters from Clearasil.",
                "brand": "Clearasil Rapid Rescue Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad68"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad6d",
                "link": "https://www.climarapro.com/save-climara-pro",
                "description": "Climara Pro Bayer Savings Card Program: Eligible cash-paying patients may save up to $75 per prescription per month; for additional assistance contact the program at 866-203-3503.",
                "brand": "Climara Pro",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad6d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad72",
                "link": "https://www.clindesse.com/samples.html",
                "description": "Clindesse Free Sample Request: Download and print online form to take to your healthcare provider who may fax (888-320-0688) or email form (samples@foundcare.com); for additional information contact Customer Service at 877-291-1122.",
                "brand": "Clindesse",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad72"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad77",
                "link": "https://mycoagadex.medmonk.com",
                "description": "Coagadex Copay Support Program: Eligible commercially insured patients may save up to $12,000 per calendar year on out-of-pocket expenses; for additional information contact the program at 844-427-5872.",
                "brand": "Coagadex",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad77"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad7c",
                "link": "https://www.boironusa.com/coldcalm-liquid-doses-coupon/",
                "description": "Receive a $2 coupon for Coldcalm Liquid Dose with registration; 1 coupon per purchase.",
                "brand": "Coldcalm Liquid Doses",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad7c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac5e",
                "link": "https://mycareclubrewards.com/benadryl/?utm_medium=partner&utm_source=benadryl.com&utm_content=footer&utm_campaign=cc_main&_gl=1*l2ngid*_ga*MTE2MzcxNjA1Mi4xNjM0MzE1NjA4*_ga_13VEM6N66E*MTYzNDMxNTYwNi4xLjAuMTYzNDMxNTYwNi42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Benadryl products with registration.",
                "brand": "Benadryl Original Strength Itch Stopping Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac5e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac63",
                "link": "https://mycareclubrewards.com/bengay/?utm_medium=partner&utm_source=bengay.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1xoul7s*_ga*MTc3MDUxNTg0OS4xNjMzMDE2OTQ4*_ga_13VEM6N66E*MTYzMzEwMjEzMC4yLjEuMTYzMzEwMjE1NS4zNQ..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Bengay products with registration.",
                "brand": "Bengay Lidocaine Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac63"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac68",
                "link": "https://benicar.com/savings",
                "description": "Benicar Pre-activated Savings Card: Eligible commercially insured patients may pay as little as $5 per 30-day prescription; for additional information contact the program 877-264-2440.",
                "brand": "Benicar",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac68"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac6d",
                "link": "https://www.beovuhcp.com/product-access#product-returns",
                "description": "Beovu Co-Pay Patient Support: Eligible commercially insured patients may pay as little as $0 per treatment with a maximum savings of $12,000 annually; for additional information your healthcare provider must contact 888-612-3688.",
                "brand": "Beovu",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac6d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac72",
                "link": "https://www.medimetriks.com/prescription-brands/beser-lotion-kit-beser-lotion",
                "description": "Beser Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Beser",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac72"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac77",
                "link": "https://www.betaseron.com/betaplus-support-program/financial-support",
                "description": "Betaseron Beta Bridge Program: Eligible commercially insured patients may keep their therapy going while insurance coverage issues are resolved; program may provide up to 12-months prescriptions at no cost; for additional information contact the program at 844-788-1470.",
                "brand": "Betaseron",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac77"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac7c",
                "link": "https://www.bevespi.com/bevespi-coupon.html",
                "description": "Bevespi Zero-Pay Program: Eligible commercially insured patients may pay $0 per prescription; maximum savings limit of $100 per 30-day supply (1 inhaler); for additional information contact the program at 877-469-1844.",
                "brand": "Bevespi Aerosphere",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac7c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac81",
                "link": "https://www.bidil.com/hcp/patient-savings-support#ezRx",
                "description": "BiDil E-Z Rx Program: Eligible cash-paying patients may pay just $50 for each monthly supply at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "BiDil",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac81"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac86",
                "link": "http://www.binosto.com/new/content/mission-rx-support-program-your-%03convenient-pharmacy-home-delivery",
                "description": "Binosto Copay Card: Eligible patients pay as little as $15 per prescription; for additional information contact the program at 877-204-1013.",
                "brand": "Binosto",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 60,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac86"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac4c",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Save $1 on any one Benadryl product (excludes trial & travel size); 1 coupon per purchase.",
                "brand": "Benadryl Allergy Ultratab",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac4c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac51",
                "link": "https://mycareclubrewards.com/benadryl/?utm_medium=partner&utm_source=benadryl.com&utm_content=footer&utm_campaign=cc_main&_gl=1*l2ngid*_ga*MTE2MzcxNjA1Mi4xNjM0MzE1NjA4*_ga_13VEM6N66E*MTYzNDMxNTYwNi4xLjAuMTYzNDMxNTYwNi42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Benadryl products with registration.",
                "brand": "Benadryl Childrens Allergy Plus Congestion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac51"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac56",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Save $1 on any one Benadryl product (excludes trial & travel size); 1 coupon per purchase.",
                "brand": "Benadryl Extra Strength Itch Relief Stick",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac56"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac5b",
                "link": "https://mycareclubrewards.com/benadryl/?utm_medium=partner&utm_source=benadryl.com&utm_content=footer&utm_campaign=cc_main&_gl=1*l2ngid*_ga*MTE2MzcxNjA1Mi4xNjM0MzE1NjA4*_ga_13VEM6N66E*MTYzNDMxNTYwNi4xLjAuMTYzNDMxNTYwNi42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Benadryl products with registration.",
                "brand": "Benadryl Extra Strength Itch Stopping Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac5b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac60",
                "link": "https://www.benefix.com/resources/financial-support",
                "description": "BeneFix Trial Prescription Program: Eligible commercially insured patients who are prescribed this medication for the first time may be eligible to receive a 1-month trial supply up to 20,000 IU; for additional information contact the program at 844-989-4366.",
                "brand": "Benefix",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac60"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac65",
                "link": "https://mycareclubrewards.com/bengay/?utm_medium=partner&utm_source=bengay.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1xoul7s*_ga*MTc3MDUxNTg0OS4xNjMzMDE2OTQ4*_ga_13VEM6N66E*MTYzMzEwMjEzMC4yLjEuMTYzMzEwMjE1NS4zNQ..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Bengay products with registration.",
                "brand": "Bengay Ultra Strength Pain Relieving Patch",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac65"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac6a",
                "link": "https://benicar.com/savings",
                "description": "Benicar HCT Pre-activated Savings Card: Eligible uninsured patients may receive $25 off the retail price per 30-day prescription fill; for additional information contact the program 877-264-2440.",
                "brand": "Benicar HCT",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "300",
                "lifetime_uses": "12",
                "max_saving_transaction": 25,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac6a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac6f",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Bepreve Bausch + Lomb Access Program: Eligible cash-paying and commercially insured/RX not covered patients may pay no more than $60 per 5 mL bottle/$90 per 10 mL bottle prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Bepreve",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac6f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac74",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Besivance Bausch + Lomb Access Program: Eligible cash-paying and commercially insured/RX not covered patients may pay no more than $60 per prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Besivance",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac74"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad81",
                "link": "https://www.colgate.com/en-us/smiles/special-offers",
                "description": "Sign up to receive coupons, special offers and other valuable information from Colgate.",
                "brand": "Colgate Kids Cavity Protection Toothpaste Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad81"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad86",
                "link": "https://www.boironusa.com/coupons/",
                "description": "$2 coupon off ColicComfort with registration; 1 coupon per purchase.",
                "brand": "ColicComfort",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad86"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad8b",
                "link": "https://www.cometriq.com/support",
                "description": "Cometriq EASE Co-Pay Program: Eligible commercially patients may pay $0 per month with a yearly savings of up to $25,000; for additional information contact the program at 844-900-3273.",
                "brand": "Cometriq",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.33,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad8b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad90",
                "link": "https://www.contac.com/coupons/",
                "description": "Save $1 on any one Contac (excludes Cold+Flu 8 count); limit one coupon per purchase.",
                "brand": "Contac Cold Flu Cooling Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad90"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad95",
                "link": "https://ascensiaconcierge.archwayconnect.com/login",
                "description": "Contour Test Strips Samples: Your healthcare provider may be able to  order FREE samples of Contour Test Strips by registering with Contour next Concierge website.",
                "brand": "Contour Next EZ Test Strip",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad95"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad9a",
                "link": "https://ascensiaconcierge.archwayconnect.com/login",
                "description": "Contour Test Strips Samples: Your healthcare provider may be able to  order FREE samples of Contour Test Strips by registering with Contour next Concierge website.",
                "brand": "Contour Test Strip",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad9a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad9f",
                "link": "https://products.coloplast.us/products/continence-care/#%26currentPage%3D1%26facetStrings%3DAllowSamplingFacet%3ATrue%3BProductBrands%3AConveen%25C2%25AE",
                "description": "Receive FREE samples of Conveen products; sign up required; for additional information contact the program at 888-726-7872.",
                "brand": "Conveen Optima",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad9f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ada4",
                "link": "https://almiralladvantage.com/",
                "description": "Cordran (Ointment) Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $35 per prescription when using an out-of-network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Cordran",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ada4"
            },
            {
                "_id": "6297bcabe0e0db6c2572ada9",
                "link": "https://almiralladvantage.com/",
                "description": "Cordran (Ointment) Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $15 per prescription when using an in-network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Cordran",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ada9"
            },
            {
                "_id": "6297bcabe0e0db6c2572adae",
                "link": "https://almiralladvantage.com",
                "description": "Cordran (Cream) Advantage Savings Card: Eligible commercially insured patients may pay no more than $15 per prescription at an in-network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Cordran",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adae"
            },
            {
                "_id": "6297bcabe0e0db6c2572adb3",
                "link": "https://www.coricidinhbp.com/coupons/",
                "description": "Save on any Coricidin HBP product by registering your email address; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Coricidin HBP Maximum Strength Cold and Flu Day and Night",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adb3"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac9d",
                "link": "https://www.alva-amco.com/contact",
                "description": "Consumers may fill out a request form for BPH product coupons.",
                "brand": "BPH Blood Pressure Health",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac9d"
            },
            {
                "_id": "6297bcabe0e0db6c2572aca2",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Braftovi+Mektovi Co-Pay Savings Program: Eligible commercially insured patients whose pharmacy does not accept the Savings Card may submit a  rebate for the out-of-pocket cost paid for their prescription; for additional information contact the program at 877-744-5675.",
                "brand": "Braftovi and Mektovi",
                "copay": "No",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.33,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aca2"
            },
            {
                "_id": "6297bcabe0e0db6c2572aca7",
                "link": "https://www.breatheright.com/samples-sign-up/",
                "description": "Get a FREE sample of a Breathe Right product in the mail with registration; one-time offer.",
                "brand": "Breathe Right Extra Strength Tan",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aca7"
            },
            {
                "_id": "6297bcabe0e0db6c2572acac",
                "link": "https://www.brexafemme.com/",
                "description": "Brexafemme Savings Program: Eligible cash-paying patients may pay $120 per prescription; for additional information contact the program at 844-431-9894.",
                "brand": "Brexafemme",
                "copay": "Yes",
                "bin": "004682",
                "rxpcn": "CN",
                "copay_max": "0.00",
                "lifetime_uses": "0",
                "max_saving_transaction": null,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acac"
            },
            {
                "_id": "6297bcabe0e0db6c2572acb1",
                "link": "https://www.breztri.com/breztri-zero-pay.html",
                "description": "Breztri Zero Pay Card: Eligible commercially insured patients may pay as little as $0 per 30-day supply (1 inhaler); for more information contact the program at 833-458-0440.",
                "brand": "Breztri Aerosphere",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acb1"
            },
            {
                "_id": "6297bcabe0e0db6c2572abf9",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Eczema Therapy Itch Relief Balm",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abf9"
            },
            {
                "_id": "6297bcabe0e0db6c2572abfe",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Restorative Skin Therapy Oat Repairing Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572abfe"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac03",
                "link": "https://www.aveenomd.com/samples",
                "description": "Aveeno Samples: Healthcare providers may order samples of select Aveeno products for their practice by visiting the website.",
                "brand": "Aveeno Skin Relief Moisturizing Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac03"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac08",
                "link": "https://mycareclubrewards.com/aveeno/?utm_medium=partner&utm_source=aveeno.com&utm_content=header&utm_campaign=cc_main&_gl=1*f3u8oz*_ga*MTYzNTUzODA5Ny4xNjI2Mjg4MjU0*_ga_13VEM6N66E*MTYyNjI4ODI1My4xLjAuMTYyNjI4ODI1My42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Aveeno products with registration.",
                "brand": "Aveeno Soothing Body Wash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac08"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac0d",
                "link": "https://yourblueprint.com/financial-assistance/",
                "description": "Ayvakit QuickStart Program: Eligible commercially insured patients may be eligible to get a temporary supply at no cost while waiting for insurance to approve coverage; for additional information contact the program at 888-258-7768.",
                "brand": "Ayvakit",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac0d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac12",
                "link": "https://www.azedra.com/support-program",
                "description": "Azedra Service Connection Program: Eligible commercially insured patients may save on out-of-pocket costs per prescription; for additional information contact the program at 844-293-3721.",
                "brand": "Azedra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac12"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac17",
                "link": "https://www.azoproducts.com/sign-up-for-azo-offers",
                "description": "Register your email address to receive special offers on AZO products; 1 coupon per purchase.",
                "brand": "AZO Cycle Care and Comfort",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac17"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac1c",
                "link": "https://www.azoproducts.com/sign-up-for-azo-offers",
                "description": "Register your email address to receive special offers on AZO products; 1 coupon per purchase.",
                "brand": "AZO Urinary Pain Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac1c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac21",
                "link": "https://azor.com/savings",
                "description": "Azor Pre-activated Savings Card: Eligible uninsured patients may receive $25 off the retail price for each monthly prescription fill per calendar year; for additional information contact the program at 877-264-2440.",
                "brand": "Azor",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac21"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac26",
                "link": "https://www.alva-amco.com/contact",
                "description": "Coupon requests may be made online for BACKAID Max; for additional information contact 800-792-2582.",
                "brand": "BACKAID Max",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac26"
            },
            {
                "_id": "6297bcabe0e0db6c2572acb6",
                "link": "https://www.brilinta.com/brilinta-coupon-savings/commercial-insurance.html",
                "description": "Brilinta Mail Order Prescription Rebate: Eligible cash-paying patients can save up to $300 off each 90-day supply when using a mail-order pharmacy; for more information contact the program at 888-462-3705.",
                "brand": "Brilinta",
                "copay": "No",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1200",
                "lifetime_uses": "4",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acb6"
            },
            {
                "_id": "6297bcabe0e0db6c2572acbb",
                "link": "https://www.bromsite.com/patient/savings-and-support/",
                "description": "BromSite Patients Savings Card: Eligible commercially insured patients pay pay as little as $35 on each of up to 4 prescriptions; for additional information contact the program at 855-379-2324.",
                "brand": "BromSite",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "400",
                "lifetime_uses": "4",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acbb"
            },
            {
                "_id": "6297bcabe0e0db6c2572acc0",
                "link": "https://www.sunovionprofile.com/register/",
                "description": "Brovana Samples: Your healthcare provider may register for a Sunovion Profile account to request samples; for additional information contact the program at 888-394-7377.",
                "brand": "Brovana",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acc0"
            },
            {
                "_id": "6297bcabe0e0db6c2572acc5",
                "link": "https://www.orthorxaccess.com/",
                "description": "Bryhali Rx Access Program: Eligible uninsured/cash-pay patients pay $65 per prescription; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Bryhali",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acc5"
            },
            {
                "_id": "6297bcabe0e0db6c2572acca",
                "link": "https://www.bydureon.com/bydureon-bcise/savings-and-support.html#savings-card",
                "description": "Bydureon BCise Savings Card: Eligible commercially insured patients/RX not covered may save up to $150 per 28-day supply prescription; for additional information contact the program at 866-680-9081.",
                "brand": "Bydureon BCise",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acca"
            },
            {
                "_id": "6297bcabe0e0db6c2572accf",
                "link": "https://www.cablivi.com/attp/cablivi-patient-solutions",
                "description": "Cablivi Copay Assistance Program: Eligible commercially insured patients may save on out-of-pocket, copay or coinsurance costs each year; for additional information contact the program at 855-724-7222.",
                "brand": "Cablivi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572accf"
            },
            {
                "_id": "6297bcabe0e0db6c2572acd4",
                "link": "https://www.boironusa.com/calendula-gel-coupon/",
                "description": "$2 off coupon on Calendula gel; must fill out form; 1 coupon per purchase.",
                "brand": "Calendula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acd4"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac2b",
                "link": "https://balmexadult.com/balmex-coupon/",
                "description": "Save $2 on any one Balmex Adult product; 1 coupon per purchase.",
                "brand": "Balmex AdultAdvantage",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac2b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac30",
                "link": "https://www.balneol.com/en/special-offers",
                "description": "Save $2 on any Balneol product; 1 coupon per purchase.",
                "brand": "Balneol",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac30"
            },
            {
                "_id": "6297bcabe0e0db6c2572adb8",
                "link": "https://www.corlanor.com/corlanor-copay-card/",
                "description": "Corlanor Savings Card: Eligible commercially insured patients may pay no more than $20 with savings of up to $160 per prescription; maximum savings of $2600 per year; for additional information contact the program at 844-626-7526.",
                "brand": "Corlanor",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 160,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adb8"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac35",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Basaglar KwikPen: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Basaglar KwikPen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac35"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac3a",
                "link": "https://hcp-samples.bayer.com/product-samples/bayer-aspirin",
                "description": "Bayer Aspirin Product Samples: Healthcare providers can register online to order free samples for their practice.",
                "brand": "Bayer Aspirin Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac3a"
            },
            {
                "_id": "6297bcabe0e0db6c2572adbd",
                "link": "https://www.cortizone10.com/special-offers.html",
                "description": "$1 off coupon on any Cortizone-10 product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Cortizone-10 Cooling Relief Anti-Itch Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adbd"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac3f",
                "link": "https://acrotechpatientaccess.com/copay-assistance-services/",
                "description": "Beleodaq STAR Copay Assistance Program: Eligible commercially insured patients will pay $0 copay for the 1st date of service and a $25 copay for  subsequent dates of service; maximum savings of $10,000 per calendar year; for additional information contact the program at 888-537-8277.",
                "brand": "Beleodaq",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac3f"
            },
            {
                "_id": "6297bcabe0e0db6c2572adc2",
                "link": "https://obcomplete.com/coupons/",
                "description": "Corvite FE Savings Card: Eligible patients may pay $10 per 30-day prescription with savings of up to $50 per fill; for additional information contact the program at 844-727-5540.",
                "brand": "Corvite FE",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "600",
                "lifetime_uses": "12",
                "max_saving_transaction": 50,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adc2"
            },
            {
                "_id": "6297bcabe0e0db6c2572adc7",
                "link": "https://readysetcosentyx.com/",
                "description": "Cosentyx Samples: Your healthcare provider may order samples by calling 866-318-6977.",
                "brand": "Cosentyx",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adc7"
            },
            {
                "_id": "6297bcabe0e0db6c2572adcc",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Cotellic Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Cotellic",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adcc"
            },
            {
                "_id": "6297bcabe0e0db6c2572add1",
                "link": "https://cotemplaxrodt.com/#rxconnect-section",
                "description": "Cotempla XR-ODT Aytu RxConnect Pharmacy Network Program: Eligible uninsured/cash-paying patients may save $100 on each 30-day prescription when using a Aytu RxConnect Pharmacy; for additional information contact the program at 877-675-6590.",
                "brand": "Cotempla XR-ODT",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572add1"
            },
            {
                "_id": "6297bcabe0e0db6c2572add6",
                "link": "https://www.crestor.com/savings-resources/savings-offers.html#menu1",
                "description": "Crestor Mail Order Rebate for Mail-Order Pharmacy: Eligible commercially insured patients may pay as little as $3 with savings up to $130 per 30-day supply, $260 per 60-day supply and $390 per 90-day supply; for additional information contact the program at 800-236-9933.",
                "brand": "Crestor",
                "copay": "No",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1560",
                "lifetime_uses": "12",
                "max_saving_transaction": 130,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572add6"
            },
            {
                "_id": "6297bcabe0e0db6c2572addb",
                "link": "https://www.allergansavingscard.com/crinone",
                "description": "Crinone Patient Savings Program: Eligible commercially insured patients may pay $15 per prescription with savings of up to $200 per 30-day supply; for additional information contact the program at 844-415-0680.",
                "brand": "Crinone",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1200",
                "lifetime_uses": "6",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572addb"
            },
            {
                "_id": "6297bcabe0e0db6c2572acd9",
                "link": "https://www.cambiarx.com/mobile-cambia/",
                "description": "Cambia Savings Card: Eligible commercially insured patients may pay as little as $0 per prescription; for additional information contact the program at 844-546-8634.",
                "brand": "Cambia",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acd9"
            },
            {
                "_id": "6297bcabe0e0db6c2572acde",
                "link": "https://www.caprelsa.com/pt_resources_financial_assistance.asp",
                "description": "Caprelsa Interim Access Program: Eligible commercially insured patients who are experiencing a delay in their coverage may be able to receive a short term supply of medication free of charge; for more information contact the program at 800-367-4999.",
                "brand": "Caprelsa",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acde"
            },
            {
                "_id": "6297bcabe0e0db6c2572ace3",
                "link": "https://www.mycarmex.com",
                "description": "Sign-up with Carmex to receive special offers by entering your email address on the homepage.",
                "brand": "Carmex Daily Care Stick",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ace3"
            },
            {
                "_id": "6297bcabe0e0db6c2572ace8",
                "link": "https://www.caystonhcp.com/programs/cayston-access-program",
                "description": "Cayston Co-Pay Coupon Program: Eligible cash-pay patients may save on their prescriptions when using the program; for additional information contact the program at 877-722-9786.",
                "brand": "Cayston",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ace8"
            },
            {
                "_id": "6297bcabe0e0db6c2572aced",
                "link": "https://www.pfizermedicalinformation.com/en-us/celontin",
                "description": "Celontin Samples: Your healthcare provider may request samples by contacting PfizerPro via the website or calling 800-505-4426.",
                "brand": "Celontin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aced"
            },
            {
                "_id": "6297bcabe0e0db6c2572acf2",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Kids",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acf2"
            },
            {
                "_id": "6297bcabe0e0db6c2572acf7",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Minis Men 50 plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acf7"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac44",
                "link": "https://mycareclubrewards.com/benadryl/?utm_medium=partner&utm_source=benadryl.com&utm_content=footer&utm_campaign=cc_main&_gl=1*l2ngid*_ga*MTE2MzcxNjA1Mi4xNjM0MzE1NjA4*_ga_13VEM6N66E*MTYzNDMxNTYwNi4xLjAuMTYzNDMxNTYwNi42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Benadryl products with registration.",
                "brand": "Benadryl Allergy Childrens Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac44"
            },
            {
                "_id": "6297bcabe0e0db6c2572acfc",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum MultiGummies Adults 50 plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acfc"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad01",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum MultiGummies Multi plus Omega 3",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad01"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad06",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Silver product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Silver Men",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad06"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad0b",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Whole Food Blend Sleep Supplement",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad0b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad10",
                "link": "https://www.rbhcprofessional.com/s/contact-us",
                "description": "Cepacol Samples: Healthcare providers may order samples for their office online.",
                "brand": "Cepacol InstaMax Lozenges",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad10"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac79",
                "link": "https://www.betimol.com",
                "description": "Betimol Patient Savings Card: Eligible patients pay as little as $20 with a savings of $65 per prescription; offer valid for 36 fills; for more information contact the program at 877-505-6985.",
                "brand": "Betimol",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 65,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac79"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac7e",
                "link": "https://www.bidil.com/hcp/patient-savings-support#savingsCard",
                "description": "BiDil Patient Savings Card: Eligible commercially insured patients pay $0 for the 1st prescription (up to 90 tablets) and as little as $25 for subsequent fills; for additional information contact the program at 877-264-2440.",
                "brand": "BiDil",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac7e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac83",
                "link": "https://savings.bijuva.com",
                "description": "Bijuva Savings Card Program: Eligible commercially insured patients may pay as little as $35 per 30-day prescription or no more than $105 per 90-day prescription; for additional information contact the program at 844-536-6711.",
                "brand": "Bijuva",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac83"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac88",
                "link": "https://epihealth.com/rebate/",
                "description": "Bionect Patient Access Card: Eligible commercially insured patients may pay as little as $0 per fill; for additional information contact the program at 855-631-2485.",
                "brand": "Bionect Cream",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac88"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac8d",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/biotene/overview/",
                "description": "Biotene Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Biotene Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac8d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac92",
                "link": "https://www.bonjesta.com/bonjesta-savings",
                "description": "Bonjesta Copay Savings Card: Eligible commercially insured patients may pay as little as $40 per prescription; for additional information contact the program at 800-250-5195.",
                "brand": "Bonjesta",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac92"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac97",
                "link": "https://www.bonjesta.com/bonjesta-savings#evoucherrx",
                "description": "Bonjesta eVoucherRx Program: Eligible patients may bring their prescription to an eligible pharmacy and savings will automatically be applied; for additional information contact the program at 800-250-5195.",
                "brand": "Bonjesta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac97"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac9c",
                "link": "https://www.botoxsavingsprogram.com",
                "description": "Botox Savings Program: Eligible commercially insured patients may be reimbursed up to $1000 per treatment with a maximum savings limit of $4000 per year; offer valid for up to 5 treatments over a 12-month period; for additional information contact the program at 800-442-6869.",
                "brand": "Botox",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "4000",
                "lifetime_uses": "5",
                "max_saving_transaction": 1000,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac9c"
            },
            {
                "_id": "6297bcabe0e0db6c2572aca1",
                "link": "https://www.braftovimektovi.com/financial-assistance",
                "description": "Braftovi+Mektovi Co-Pay Savings Program: Eligible commercially insured patients may pay $0 copay per month with a maximum savings of up to $25,000 per calendar year; for additional information contact the program at 877-744-5675.",
                "brand": "Braftovi and Mektovi",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.33,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aca1"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad15",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Acne Control Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad15"
            },
            {
                "_id": "6297bcabe0e0db6c2572aca6",
                "link": "https://www.breatheright.com/get-a-coupon/",
                "description": "Receive $2 coupon for any Breathe Right product; 1 coupon per purchase; coupon expires one month after printing.",
                "brand": "Breathe Right Extra Strength Tan",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aca6"
            },
            {
                "_id": "6297bcabe0e0db6c2572acab",
                "link": "https://gskpro.com/en-us/request-samples-login-page/?resource=%2Fcontent%2Fcf-pharma%2Fhealth-hcpportal%2Fen_US%2Fhcp%2Fhome%2Fqpharma-saml-page.html",
                "description": "Breo Ellipta FREE Samples: Your healthcare provider may be able to order free samples by visiting GSK Pro and creating an account or by calling 888-593-5977.",
                "brand": "Breo Ellipta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acab"
            },
            {
                "_id": "6297bcabe0e0db6c2572acb0",
                "link": "https://www.breztri.com/breztri-zero-pay.html",
                "description": "Breztri Zero Pay Card: Eligible commercially insured patients/RX not covered may pay as little as $40 per 30-day supply (1 inhaler); maximum savings limit of $594.68 per 30-day supply (1 inhaler); for more information contact the program at 833-458-0440.",
                "brand": "Breztri Aerosphere",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "7136.16",
                "lifetime_uses": "12",
                "max_saving_transaction": 594.68,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acb0"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad1a",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Eczema Relief Creamy Oil",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad1a"
            },
            {
                "_id": "6297bcabe0e0db6c2572acb5",
                "link": "https://www.brilinta.com/brilinta-coupon-savings/commercial-insurance.html",
                "description": "Brilinta Mail Order Prescription Rebate: Eligible commercially insured patients can save on their out-of-pocket costs that exceed $5 on each 90-day supply; patient must have used a mail-order pharmacy for their prescription fill; maximum savings of $600 per prescription; for more information contact the program at 888-462-3705.",
                "brand": "Brilinta",
                "copay": "No",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "2760",
                "lifetime_uses": "4",
                "max_saving_transaction": 600,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acb5"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad1f",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Psoriasis Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad1f"
            },
            {
                "_id": "6297bcabe0e0db6c2572acba",
                "link": "http://bromfeddm.com/",
                "description": "Bromfed DM Savings Coupon: Eligible patients may save up to $10 off your next prescription on a 4 oz or larger bottle; coupon expires 1 month after printing; for additional information contact the program at 866-788-9622.",
                "brand": "Bromfed DM",
                "copay": "Yes",
                "bin": "15251",
                "rxpcn": "PRX2000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 10,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acba"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad24",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe SA Lotion-Rough and Bumpy Skin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad24"
            },
            {
                "_id": "6297bcabe0e0db6c2572acbf",
                "link": "https://bronchitol.com/savings-and-support/",
                "description": "Bronchitol QuickStart Program: Eligible commercially insured patients who are new to Bronchitol and pass a Tolerance Test will receive a free supply of medication; for additional information contact the program at 888-865-1222.",
                "brand": "Bronchitol",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acbf"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad29",
                "link": "https://www.cerezyme.com/access-copay-assistance",
                "description": "Cerezyme Sanofi Genzyme Co-Pay Assistance Program: Program will cover 100% of out-of-pocket costs for eligible commercially insured patients; for additional information contact the program at 800-745-4447, option 3.",
                "brand": "Cerezyme",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad29"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad2e",
                "link": "https://www.emdseronofertility.com/en/patient/affording-treatment/medication-savings.html",
                "description": "Cetrotide GO Direct Rebate Program: Eligible self-pay patients may be able to receive a 10% discount via rebate off your out-of-pocket costs; for additional information contact the program at 866-441-9463.",
                "brand": "Cetrotide",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad2e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac49",
                "link": "https://mycareclubrewards.com/benadryl/?utm_medium=partner&utm_source=benadryl.com&utm_content=footer&utm_campaign=cc_main&_gl=1*l2ngid*_ga*MTE2MzcxNjA1Mi4xNjM0MzE1NjA4*_ga_13VEM6N66E*MTYzNDMxNTYwNi4xLjAuMTYzNDMxNTYwNi42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Benadryl products with registration.",
                "brand": "Benadryl Allergy Plus Congestion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac49"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad33",
                "link": "https://www.boironusa.com/chestal-coupon/",
                "description": "$2 coupon for any Chestal cough syrup product with registration; 1 coupon per purchase.",
                "brand": "Chestal Cold and Cough",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad33"
            },
            {
                "_id": "6297bcabe0e0db6c2572acc4",
                "link": "https://www.orthorxaccess.com/",
                "description": "Bryhali Rx Access Program: Eligible commercially insured patients/RX not covered may pay no more than $65 per fill; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Bryhali",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acc4"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad38",
                "link": "https://mycareclubrewards.com/Rhinocort/?utm_medium=partner&utm_source=rhinocort.com&utm_content=footer&utm_campaign=cc_main&_gl=1*gszgd4*_ga*MjAwOTYxNTY1My4xNjM2OTk3MjUw*_ga_13VEM6N66E*MTYzNzE2NDI1OC42LjEuMTYzNzE2NDMxNC40*_fplc*OE14YnVzeWJOMCUyQk96SnduNmxaZ1RxVXFPakFib0JodGlMZklVTVV4JTJCYlJMdG1ReTliV1p0NiUyRjZCTjJxb1NtTzNvJTJGNGtJMmdFV25NM0xndzg4Z1h6JTJCTUNvRm9Na2pKaCUyRkNVVVBybXlOV3pYVVlqTzM4aWR2MlZDejJCZVBnJTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Rhinocort products with registration.",
                "brand": "Childrens Rhinocort Allergy Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad38"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac4e",
                "link": "https://mycareclubrewards.com/benadryl/?utm_medium=partner&utm_source=benadryl.com&utm_content=footer&utm_campaign=cc_main&_gl=1*l2ngid*_ga*MTE2MzcxNjA1Mi4xNjM0MzE1NjA4*_ga_13VEM6N66E*MTYzNDMxNTYwNi4xLjAuMTYzNDMxNTYwNi42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Benadryl products with registration.",
                "brand": "Benadryl Childrens Allergy Dye-Free Liquid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac4e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad3d",
                "link": "https://chromagenrx.com/savings/",
                "description": "Chromagen eVoucherRx Program: Eligible patients pay no more than $18 per prescription; for additional information contact the program at 888-612-8466.",
                "brand": "Chromagen",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad3d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac53",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Save $1 on any one Benadryl product (excludes trial & travel size); 1 coupon per purchase.",
                "brand": "Benadryl Childrens Itch Cooling Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac53"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad42",
                "link": "https://www.heronconnect.com/CINVANTI/Programs-and-Enrollment",
                "description": "Cinvanti Heron Connect Copay Assistance Program: Eligible cash-paying patients may save $150 per prescription; maximum savings of $1800 per calendar year; your healthcare provider must complete enrollment form; for additional information contact the program at 844-437-6611.",
                "brand": "Cinvanti",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "0",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad42"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac58",
                "link": "https://www.benadryl.com/save-on-benadryl",
                "description": "Save $1 on any one Benadryl product (excludes trial & travel size); 1 coupon per purchase.",
                "brand": "Benadryl Extra Strength Itch Stopping Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac58"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad47",
                "link": "https://https://www.citracal.com/coupon",
                "description": "Register your email address and receive a $2 coupon for any one Citracal product (70 count or larger); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Citracal Regular",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad47"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac5d",
                "link": "https://mycareclubrewards.com/benadryl/?utm_medium=partner&utm_source=benadryl.com&utm_content=footer&utm_campaign=cc_main&_gl=1*l2ngid*_ga*MTE2MzcxNjA1Mi4xNjM0MzE1NjA4*_ga_13VEM6N66E*MTYzNDMxNTYwNi4xLjAuMTYzNDMxNTYwNi42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Benadryl products with registration.",
                "brand": "Benadryl Itch Cooling Spray Extra Strength",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac5d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad4c",
                "link": "https://www.citranatal.com/",
                "description": "CitraNatal Savings Card: Eligible commercially insured patients may pay as little as $25 per 30-day prescription; for additional information contact the program at 347-480-1771.",
                "brand": "CitraNatal Bloom",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad4c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac62",
                "link": "https://mycareclubrewards.com/bengay/?utm_medium=partner&utm_source=bengay.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1xoul7s*_ga*MTc3MDUxNTg0OS4xNjMzMDE2OTQ4*_ga_13VEM6N66E*MTYzMzEwMjEzMC4yLjEuMTYzMzEwMjE1NS4zNQ..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Bengay products with registration.",
                "brand": "Bengay Greaseless Pain Relieving Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac62"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad51",
                "link": "https://hcp-samples.bayer.com/product-samples/claritin",
                "description": "Claritin Product Samples: Healthcare providers can register online to order free samples for their practice.",
                "brand": "Claritin Childrens Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad51"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac67",
                "link": "https://benicar.com/savings",
                "description": "Benicar Pre-activated Savings Card: Eligible uninsured patients may save up to $25 per 30-day prescription fill; for additional information contact the program 877-264-2440.",
                "brand": "Benicar",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "300",
                "lifetime_uses": "12",
                "max_saving_transaction": 25,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac67"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac6c",
                "link": "https://epihealth.com/rebate/",
                "description": "Bensal HP Patient Access Card: Eligible commercially insured patients may pay as little as $0 per fill; for additional information contact the program at 855-631-2485.",
                "brand": "Bensal HP",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac6c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad56",
                "link": "https://www.claritin.com/savings",
                "description": "Save $2 off any one Childrens/Junior Claritin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Claritin RediTabs for Juniors",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad56"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac71",
                "link": "https://www.berinert.com/patients/support-resources",
                "description": "Berinert Co-Pay Program: Eligible commercially insured patients may save up to $12,000 per year on co-pay expenses; for additional information contact the program at 877-236-4423.",
                "brand": "Berinert",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000.00",
                "lifetime_uses": "0",
                "max_saving_transaction": null,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac71"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad5b",
                "link": "https://www.claritin.com/savings",
                "description": "Save $2 off any one Claritin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Claritin Tablets 24HR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad5b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad60",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Clean & Clear products with registration.",
                "brand": "Clean and Clear Aloe Vera Acne Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad60"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad65",
                "link": "https://www.eyefile.com/TheEyeSolution/Register/0?sid=CLRCR0513",
                "description": "Clear Care Products: Save $2 and receive future exclusive offers with membership to the THEeyeSOLUTION; 1 coupon per purchase.",
                "brand": "Clear Care Plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad65"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad6a",
                "link": "https://www.clenpiq.com/hcp/coverage/",
                "description": "Clenpiq Savings Coupon: Eligible cash-paying patients may save $40 per prescription; for additional information contact the program at 800-485-6490.",
                "brand": "Clenpiq",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0.00",
                "lifetime_uses": "0",
                "max_saving_transaction": 40,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad6a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad6f",
                "link": "https://www.orthorxaccess.com/",
                "description": "Clindagel Rx Access Program: Eligible commercially insured patients may pay $25 per prescription; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Clindagel",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad6f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad74",
                "link": "https://www.medimetriks.com/prescription-brands/clodan-kit-clodan",
                "description": "Clodan Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Clodan",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad74"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad79",
                "link": "https://colacecapsules.com/healthcare-professionals/",
                "description": "Colace Samples: Healthcare providers may register to order samples online for their practice.",
                "brand": "Colace 2-IN-1 Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad79"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad7e",
                "link": "https://smiles.colgate.com/page/content/signup/",
                "description": "Sign up to receive coupons, special offers and other valuable information from Colgate.",
                "brand": "Colgate Enamel Health Mouthwash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad7e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad83",
                "link": "https://www.colgate.com/en-us/smiles/special-offers",
                "description": "Sign up to receive coupons, special offers and other valuable information from Colgate.",
                "brand": "Colgate Sensitive Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad83"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac76",
                "link": "https://pharmaessentiasource.com/financial-assistance/",
                "description": "Besremi Copay and Coinsurance Assistance Program: Eligible commercially insured patients may pay as little as $0 per monthly prescription; for additional information contact the program at 800-700-5053.",
                "brand": "Besremi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac76"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad88",
                "link": "http://www.combipatch.com/myoffer.php",
                "description": "Combipatch Copay Savings Card: Eligible patients may pay as little as $25 per prescription; offer valid for 6 fills; for additional information contact the program at 844-492-9701.",
                "brand": "CombiPatch",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0.00",
                "lifetime_uses": "6",
                "max_saving_transaction": null,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad88"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac7b",
                "link": "https://www.bevespi.com/bevespi-coupon.html",
                "description": "Bevespi Zero-Pay Program: Eligible cash-paying & uninsured patients may save up to $100 per 30-day supply (1 inhaler); for additional information contact the program at 877-469-1844.",
                "brand": "Bevespi Aerosphere",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac7b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad8d",
                "link": "https://uspco.com/product_literature.aspx?pid=51&tab=Voucher",
                "description": "Concept DHA Pharmacy Voucher: Eligible patients may save up to 80% at participating pharmacies; for additional information contact the program at  888-602-2978.",
                "brand": "Concept DHA",
                "copay": "No",
                "bin": "600428",
                "rxpcn": "1500000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad8d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac80",
                "link": "https://www.bidil.com/hcp/patient-savings-support#savingsCard",
                "description": "BiDil Patients Savings Card: Eligible cash-paying patients pay $0 for the 1st prescription (up to 90 tablets) and as little as $60 for subsequent fills; for additional information contact the program at 877-264-2440.",
                "brand": "BiDil",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac80"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac85",
                "link": "http://www.binosto.com/new/content/mission-rx-support-program-your-%03convenient-pharmacy-home-delivery",
                "description": "Binosto Mission Rx Support Program: A FREE prescription service that delivers medication directly to your front door at no additional cost other than your co-pay; for additional information contact the program at 877-460-4611.",
                "brand": "Binosto",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac85"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad92",
                "link": "https://www.contac.com/coupons/",
                "description": "Save $1 on any one Contac (excludes Cold+Flu 8 count); limit one coupon per purchase.",
                "brand": "Contac Cold Flu Night",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad92"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac8a",
                "link": "https://www.biotene.com/save-now/",
                "description": "Biotene Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Biotene Dry Mouth Moisturizing Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac8a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad97",
                "link": "https://www.ascensiadiabetes.com/meters-and-strips-savings/save-on-test-strips-contour-choice-card/",
                "description": "Contour Choice Card: Eligible commercially insured patients pay the 1st $25 and can save up to $105 of remaining copay based upon prescription quantity and insurance coverage; for additional information contact the program at 855-226-3931.",
                "brand": "Contour Next One Test Strip",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 105,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad97"
            },
            {
                "_id": "6297bcabe0e0db6c2572acc9",
                "link": "https://www.bydureon.com/bydureon-bcise/savings-and-support.html#savings-card",
                "description": "Bydureon BCise Savings Card: Eligible commercially insured patients may pay as low as $0 for each 28-day supply prescription with savings of up to $300 per fill; for additional information contact the program at 866-680-9081.",
                "brand": "Bydureon BCise",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3600",
                "lifetime_uses": "12",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acc9"
            },
            {
                "_id": "6297bcabe0e0db6c2572acce",
                "link": "https://bystolic.rx-comp.com",
                "description": "Bystolic Savings Card Rebate: If a commercially insured patient uses a mail-order pharmacy or a retail pharmacy does not accept the Savings card the patient may be able to request a refund; for additional information contact the program at 800-572-5252.",
                "brand": "Bystolic",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acce"
            },
            {
                "_id": "6297bcabe0e0db6c2572acd3",
                "link": "https://www.boironcalendula.com/calendula-coupons/",
                "description": "Get $2 off coupon on any 2.5 oz Calendula Cream; must fill out form; 1 coupon per purchase.",
                "brand": "Calendula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acd3"
            },
            {
                "_id": "6297bcabe0e0db6c2572acd8",
                "link": "https://www.cambiarx.com/hcp/get-in-touch/",
                "description": "Cambia Samples: Healthcare providers may request samples by filling on an online form.",
                "brand": "Cambia",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acd8"
            },
            {
                "_id": "6297bcabe0e0db6c2572acdd",
                "link": "https://www.caplyta.com/cost-savings",
                "description": "Caplyta Copay Savings Card Program: Eligible commercially insured patients may pay as little as $0 for 1st prescription and no more than $15 for subsequent fills up to the maximum benefit of $600; for additional information contact the program at 800-639-4047.",
                "brand": "Caplyta",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "600",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acdd"
            },
            {
                "_id": "6297bcabe0e0db6c2572ace2",
                "link": "https://www.mycarmex.com",
                "description": "Sign-up with Carmex to receive special offers by entering your email address on the homepage.",
                "brand": "Carmex Cold Sore Treatment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ace2"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac8f",
                "link": "https://amgenfirststep.com/register-card",
                "description": "Blincyto Amgen First Step Program: Eligible commercially insured patients may save on their out-of-pocket medication costs; for additional information contact the program at 888-427-7478.",
                "brand": "Blincyto",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac8f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac94",
                "link": "https://www.bonjesta.com/bonjesta-savings",
                "description": "Bonjesta CoPay Savings Card: Eligible cash-paying/Rx not covered patients may pay as little as $100 per 30-tablets or $195 per 60-tablets; for additional information contact the program at 800-250-5195.",
                "brand": "Bonjesta",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac94"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac99",
                "link": "https://www.bosulif.com/support-and-financial-assistance#terms_and_conditions",
                "description": "Bosulif Pfizer Oncology Together Mail-In Rebate: If a pharmacy does not accept the Savings Card the patient may submit a request for a rebate in connection with the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Bosulif",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.34,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac99"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac9e",
                "link": "https://www.braftovi.com/financial-assistance",
                "description": "Braftovi Pfizer Oncology Together Co-Pay Savings Program (oral products): Eligible commercially insured patients may pay as little as $0 per treatment with savings of up to $25,000 per calendar year; for additional information contact the program at 877-744-5675.",
                "brand": "Braftovi",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.34,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac9e"
            },
            {
                "_id": "6297bcabe0e0db6c2572aca3",
                "link": "https://products.coloplast.us/products/ostomy-care/#%26currentPage%3D1%26searchphrase%3Dbrava%26facetStrings%3DAllowSamplingFacet%3ATrue",
                "description": "Receive FREE samples of Brava products; sign up required; for additional information contact the program at 888-726-7872.",
                "brand": "Brava Accessories",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aca3"
            },
            {
                "_id": "6297bcabe0e0db6c2572aca8",
                "link": "https://www.breatheright.com/get-a-coupon/",
                "description": "Receive $2 coupon for any Breathe Right product; 1 coupon per purchase; coupon expires one month after printing.",
                "brand": "Breathe Right Lavender",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aca8"
            },
            {
                "_id": "6297bcabe0e0db6c2572acad",
                "link": "https://www.brexafemme.com/",
                "description": "Brexafemme Savings Card: Eligible commercially insured patients may pay as little as $30 per prescription; for additional information contact the program at 844-431-9894.   Text SAVE to BREXA",
                "brand": "Brexafemme",
                "copay": "Yes",
                "bin": "004682",
                "rxpcn": "CN",
                "copay_max": "30.00",
                "lifetime_uses": "0",
                "max_saving_transaction": null,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acad"
            },
            {
                "_id": "6297bcabe0e0db6c2572acb2",
                "link": "https://breztrihcp.com/resources.html",
                "description": "Breztri Samples: Healthcare providers may be able to request samples by  logging into the website.",
                "brand": "Breztri Aerosphere",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acb2"
            },
            {
                "_id": "6297bcabe0e0db6c2572acb7",
                "link": "https://www.azmedcoupons.com",
                "description": "Brilinta Free Trial Offer: Eligible patients may receive a free 30-day supply (up to 60 tablets); for additional information contact the program at 844-274-5468.",
                "brand": "Brilinta",
                "copay": "No",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acb7"
            },
            {
                "_id": "6297bcabe0e0db6c2572acbc",
                "link": "https://www.bromsite.com/patient/savings-and-support/",
                "description": "BromSite Patients Savings Card: Eligible insured/Rx not coverved patients may pay as little as $60 on each of up to 4 prescriptions; for additional information contact the program at 855-379-2324.",
                "brand": "BromSite",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "400",
                "lifetime_uses": "4",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acbc"
            },
            {
                "_id": "6297bcabe0e0db6c2572acc1",
                "link": "https://www.brukinsa.com/mybeigene-patient-support",
                "description": "Brukinsa myBeiGene Bridge Supply Program: Eligible commercially insured patients experiencing a delay in coverage lasting longer than 5 days may be eligible for a 15-day supply of medication; for additional information contact the program at 833-234-4363.",
                "brand": "Brukinsa",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acc1"
            },
            {
                "_id": "6297bcabe0e0db6c2572acc6",
                "link": "https://www.bydureon.com/bydureon-bcise/savings-and-support.html",
                "description": "Bydureon BCise Mail-In Rebate: If your mail-order pharmacy does not accept the Savings Card you may complete and submit the mail-in form along with your original receipt to receive your proper savings; every monthly fill requires a new mail-in form; for additional information contact the program directly at 855-292-5968.",
                "brand": "Bydureon BCise",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acc6"
            },
            {
                "_id": "6297bcabe0e0db6c2572ace7",
                "link": "https://www.caystonhcp.com/programs/cayston-access-program",
                "description": "Cayston Co-Pay Coupon Program: Eligible commercially insured patients may pay no more that $10 per prescription fill with an annual savings of up to $8000; no monthly savings maximum; valid for 7 fills per calendar year; for additional information contact the program at 877-722-9786.",
                "brand": "Cayston",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "8000",
                "lifetime_uses": "7",
                "max_saving_transaction": 1142.85,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ace7"
            },
            {
                "_id": "6297bcabe0e0db6c2572acec",
                "link": "https://www.patientrebateonline.com/patientrebate/welcome.html",
                "description": "Cellcept Rebate: Eligible commercially insured patients may request a rebate if their pharmacy cannot process the Savings Card; for additional information contact the program at 833-235-5237.",
                "brand": "CellCept",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "12",
                "max_saving_transaction": 833.34,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acec"
            },
            {
                "_id": "6297bcabe0e0db6c2572acf1",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Energy Whole Food Blend Supplement",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acf1"
            },
            {
                "_id": "6297bcabe0e0db6c2572acf6",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Minis Adults 50 plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acf6"
            },
            {
                "_id": "6297bcabe0e0db6c2572acfb",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum MultiGummies Adults",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acfb"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad00",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum MultiGummies Multi plus Beauty",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad00"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad05",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Silver product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Silver Adults",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad05"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad9c",
                "link": "https://contrave.com/save/",
                "description": "Contrave Free Home Delivery Program: Eligible patients may subscribe to the program and receive monthly prescriptions at home for $99 per month; for additional information contact the program at 800-630-3214.",
                "brand": "Contrave",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad9c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad0a",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Whole Food Blend Mens Multivatimin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad0a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ada1",
                "link": "https://www.copaxone.com/shared-solutions/copay-assistance",
                "description": "Copaxone Co-Pay Solutions: Eligible commercially insured patients may pay as little as $0 per prescription fill; for additional information contact the program at 800-887-8100.",
                "brand": "Copaxone",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ada1"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad0f",
                "link": "https://www.rbhcprofessional.com/s/contact-us",
                "description": "Cepacol Samples: Healthcare providers may order samples for their office online.",
                "brand": "Cepacol Extra Strength Sore Throat Lozenges",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad0f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ada6",
                "link": "https://almiralladvantage.com/",
                "description": "Cordran (Lotion) Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $35 per prescription at an out-of-network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Cordran",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ada6"
            },
            {
                "_id": "6297bcabe0e0db6c2572adab",
                "link": "https://almiralladvantage.com",
                "description": "Cordran (Cream) Advantage Savings Card: Eligible commercially insured patients/RX not covered may pay no more than $50 per prescription at an in-network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Cordran",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adab"
            },
            {
                "_id": "6297bcabe0e0db6c2572adb0",
                "link": "https://www.coricidinhbp.com/coupons/",
                "description": "Save on any Coricidin HBP product by registering your email address; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Coricidin HBP Cold and Flu",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adb0"
            },
            {
                "_id": "6297bcabe0e0db6c2572adb5",
                "link": "https://www.coricidinhbp.com/coupons/",
                "description": "Save on any Coricidin HBP product by registering your email address; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Coricidin HBP Maximum Strength Multi Symptom Flu",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adb5"
            },
            {
                "_id": "6297bcabe0e0db6c2572adba",
                "link": "https://www.cortizone10.com/special-offers.html",
                "description": "$1 off coupon on any Cortizone-10 product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Cortizone-10 Anti-Itch Lotion for Diabetics Skin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adba"
            },
            {
                "_id": "6297bcabe0e0db6c2572adbf",
                "link": "https://www.cortizone10.com/special-offers.html",
                "description": "$1 off coupon on any Cortizone-10 product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Cortizone-10 Feminine Relief Anti-Itch Creme",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adbf"
            },
            {
                "_id": "6297bcabe0e0db6c2572adc4",
                "link": "https://www.cosamin.com/drstartcosamin-form",
                "description": "Healthcare providers may request $5 coupons for any one Cosamin products for their patients by filling out a form online.",
                "brand": "Cosamin Avoca",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adc4"
            },
            {
                "_id": "6297bcabe0e0db6c2572adc9",
                "link": "https://cosoptpf.com/consumer/index.html",
                "description": "Cosopt PF Patient Savings Card: Eligible patients pay as little as $20 with a savings of $65 per prescription; offer valid for 36 fills; for more information contact the program at 877-505-6985.",
                "brand": "Cosopt PF",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 65,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adc9"
            },
            {
                "_id": "6297bcabe0e0db6c2572adce",
                "link": "https://cotemplaxrodt.com/#rxconnect-section",
                "description": "Cotempla XR-ODT Aytu RxConnect Pharmacy Network Program: Eligible commercially insured patients/RX not covered may pay as little as $10 per 30-day prescription when using a Aytu RxConnect Pharmacy; for additional information contact the program at 877-675-6590.",
                "brand": "Cotempla XR-ODT",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adce"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac8b",
                "link": "https://www.biotene.com/save-now/",
                "description": "Biotene Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Biotene Dry Mouth Oral Rinse Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac8b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac90",
                "link": "https://amgenfirststep.com",
                "description": "Blincyto Amgen First Step Program Rebate: Eligible commercially insured patients who have already paid for treatment may request a rebate check; for additional information contact the program at 888-657-8371.",
                "brand": "Blincyto",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac90"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac95",
                "link": "https://www.bonjesta.com/bonjesta-savings",
                "description": "Bonjesta At Home Mail Order Program: Eligible commercially insured patients may pay as little as $40 per prescription and receive free home delivery plus additional services; for additional information contact the program at 844-716-4663.",
                "brand": "Bonjesta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac95"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac9a",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Bosulif Pfizer Oncology Together Co-Pay Savings Program Rebate (oral products): Eligible commercially insured patients may submit a request for a rebate in connection with this offer if their pharmacy does not accept the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Bosulif",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac9a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ac9f",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Braftovi Pfizer Oncology Together Mail-In Rebate: If a pharmacy does not accept the Savings Card the patient may submit a request for a rebate in connection with the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Braftovi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.33,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ac9f"
            },
            {
                "_id": "6297bcabe0e0db6c2572add3",
                "link": "https://www.creon.com/hcp/contact-a-rep",
                "description": "Creon Samples: Healthcare providers may visit the website to request samples.",
                "brand": "Creon",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572add3"
            },
            {
                "_id": "6297bcabe0e0db6c2572add8",
                "link": "https://www.crestor.com/savings-resources/savings-offers.html",
                "description": "Crestor Savings Card: Eligible commercially insured patients may pay as little as $3 with savings up to $130 per 30-day supply, $260 per 60-day supply and $390 per 90-day supply; offer good dor 12 uses; for additional information contact the program at 855-687-2151.",
                "brand": "Crestor",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1560",
                "lifetime_uses": "12",
                "max_saving_transaction": 130,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572add8"
            },
            {
                "_id": "6297bcabe0e0db6c2572addd",
                "link": "https://www.cutaquigus.com/patient-support-and-resources/copay-reimbursement-assistance/",
                "description": "Cutaquig Co-Pay Assistance Program: Eligible commercially insured patients may save up to $12,500 per calendar year on out-of-pocket costs; for additional information contact the program at 833-382-7686.",
                "brand": "Cutaquig",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572addd"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad14",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Acne Control Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad14"
            },
            {
                "_id": "6297bcabe0e0db6c2572ade2",
                "link": "https://cycloset.copaysavingsprogram.com/",
                "description": "Cycloset Co-Pay Savings Coupon: Eligible commercially insured patients may pay $0 co-pay per prescription; for additional information contact the program at 866-686-0049.",
                "brand": "Cycloset",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ade2"
            },
            {
                "_id": "6297bcabe0e0db6c2572accb",
                "link": "https://www.bydureon.com/bydureon-bcise/savings-and-support.html#mail-order",
                "description": "Bydureon BCise Mail-In Rebate: If your mail-order pharmacy does not accept the Savings Card you may complete and submit the mail-in form along with your original receipt to receive your proper savings; every monthly fill requires a new mail-in form; for additional information contact the program directly at 855-292-5968.",
                "brand": "Bydureon BCise",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3600",
                "lifetime_uses": "12",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572accb"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad19",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Diabetics Dry Skin Relief Cleansing Wash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad19"
            },
            {
                "_id": "6297bcabe0e0db6c2572ade7",
                "link": "https://www.pfizermedicalinformation.com/en-us/cytotec",
                "description": "Cytotec Samples: Your Healthcare provider may request samples by logging into the PfizerPro website or calling 800-505-4426.",
                "brand": "Cytotec",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ade7"
            },
            {
                "_id": "6297bcabe0e0db6c2572acd0",
                "link": "https://www.cabometyx.com/financial-support",
                "description": "Cabometyx EASE Co-pay Program: Eligible commercially insured patients may pay no more than $0 per month with savings of up to $25,000 per calendar year; for additional information contact the program at 844-900-3273.",
                "brand": "Cabometyx",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.34,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acd0"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad1e",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Itch Relief Moisturizing Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad1e"
            },
            {
                "_id": "6297bcabe0e0db6c2572adec",
                "link": "https://www.dailies.com/dailies-free-trial",
                "description": "Dailies Contact Lenses: Receive a FREE 5-day trial and other special offers with membership to the Alcon EyeFile; register your email address to join;coupon to be redeemed at your eye care professional's office; for additional information contact customer service at 800-862-5266.",
                "brand": "DAILIES TOTAL1 Contact Lenses",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adec"
            },
            {
                "_id": "6297bcabe0e0db6c2572adf1",
                "link": "https://ymabsconnect.com",
                "description": "Danyelza Y-mAbs Connect Co-pay Program: Eligible commercially insured patients may pay $0 per infusion; patients may receive assistance for a total of 12 months from the date of enrollment; maximum savings of $15,000; for additional information contact the program at 833-339-6227.",
                "brand": "Danyelza",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adf1"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad23",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe SA Cream-Rough and Bumpy Skin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad23"
            },
            {
                "_id": "6297bcabe0e0db6c2572acd5",
                "link": "https://www.myaccess360.com/patient/calquence-acalabrutinib/patient-affordability.html",
                "description": "Calquence Access 360 Co-pay Savings Program: Eligible commercially insured patients may pay $0 per 30-day supply with savings of up to $26,000 per year; for additional information contact the program at 844-275-2360.",
                "brand": "Calquence",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "26000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2166.67,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acd5"
            },
            {
                "_id": "6297bcabe0e0db6c2572adf6",
                "link": "https://www.janssencarepath.com/patient/darzalex-faspro/cost-support",
                "description": "Darzalex Faspro Janssen CarePath Savings Program: Most eligible commercially insured patients may pay no more than $5 per infusion with a maximum benefit of $20,000 per calendar year; for additional information contact the program at 844-553-2792.",
                "brand": "Darzalex Faspro",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adf6"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad28",
                "link": "https://www.cerezyme.com/access-copay-assistance",
                "description": "Cerezyme CareConnectPSS Co-Pay Assistance Program: Eligible commercially insured patients may receive assistance through the program to pay for eligible, out-of-pocket expenses such as co-pays, coinsurance, and deductibles; for additional information contact the program at 800-745-4447, option 3.",
                "brand": "Cerezyme",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad28"
            },
            {
                "_id": "6297bcabe0e0db6c2572acda",
                "link": "https://www.boironusa.com/coupons/",
                "description": "$2 coupon off Camilia Teething Relief (30 dose) with registration; 1 coupon per purchase.",
                "brand": "Camilia Teething Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acda"
            },
            {
                "_id": "6297bcabe0e0db6c2572adfb",
                "link": "https://zydususa.com/deferasirox/",
                "description": "Derefasirox Patient Savings Card: Eligible commercially insured patients may pay as little as $0 with savings of up to $100 per 30-day prescription; for additional information contact the program at 877-993-8779.",
                "brand": "Deferasirox",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "12000",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adfb"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae00",
                "link": "https://www.delsym.com/",
                "description": "Click on the Special Offers tab at the top of the website to print a $5 coupon off any Delsym product; you will be redirected to coupons.com to print your coupon; 1 coupon per purchase.",
                "brand": "Delsym 12 Hour Cough Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae00"
            },
            {
                "_id": "6297bcabe0e0db6c2572aca4",
                "link": "https://products.coloplast.us/coloplast/ostomy-care/brava/brava-lubricating-deodorant/",
                "description": "Brava Lubricating Deodorant: Patients may order a FREE sample on the website; 1 time offer; for additional information contact the program at 800-533-0464.",
                "brand": "Brava Accessories",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aca4"
            },
            {
                "_id": "6297bcabe0e0db6c2572aca9",
                "link": "https://www.breatheright.com/get-a-coupon/",
                "description": "Receive $2 coupon for any Breathe Right product; 1 coupon per purchase; coupon expires one month after printing.",
                "brand": "Breathe Right Original Clear",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aca9"
            },
            {
                "_id": "6297bcabe0e0db6c2572acae",
                "link": "https://www.breyanzi.com/support/cell-therapy-360/",
                "description": "Breyanzi Copay Program: Eligible commercially insured patients save on their out-of-pocket costs up to $10,000; for additional information contact the program at 888-805-4555.",
                "brand": "Breyanzi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acae"
            },
            {
                "_id": "6297bcabe0e0db6c2572acb3",
                "link": "https://www.brilinta.com/brilinta-coupon-savings/commercial-insurance.html",
                "description": "Brilinta Savings Card: Eligible cash-paying patients may save up to $100 per 30-day supply prescription; for additional information contact the program at 888-512-7454.",
                "brand": "Brilinta",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acb3"
            },
            {
                "_id": "6297bcabe0e0db6c2572acdf",
                "link": "https://www.caprelsa.com/pt_resources_financial_assistance.asp",
                "description": "Caprelsa Co-Pay Assistance Program: Eligible commercially insured patients may save on out-of-pocket costs up to the program maxium per calendar year; for more information contact the program at 800-367-4999.",
                "brand": "Caprelsa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acdf"
            },
            {
                "_id": "6297bcabe0e0db6c2572ace4",
                "link": "https://www.mycarmex.com",
                "description": "Sign-up with Carmex to receive special offers by entering your email address on the homepage.",
                "brand": "Carmex Lip Comfort Care product",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ace4"
            },
            {
                "_id": "6297bcabe0e0db6c2572ace9",
                "link": "https://www.celebrex.com/savings",
                "description": "Celebrex Savings Card: Eligible patients may pay as little as $4 per 30-day prescription with savings of up $2800 per year; for additional information contact the program at 855-612-1956.",
                "brand": "Celebrex",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "2800",
                "lifetime_uses": "12",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ace9"
            },
            {
                "_id": "6297bcabe0e0db6c2572acee",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Adults",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acee"
            },
            {
                "_id": "6297bcabe0e0db6c2572acf3",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Liquid Multivitamin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acf3"
            },
            {
                "_id": "6297bcabe0e0db6c2572acb8",
                "link": "https://www.brilintahcp.com/acs.html",
                "description": "Brilinta Samples: Healthcare providers may request samples for their practice by signing into the websire.",
                "brand": "Brilinta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acb8"
            },
            {
                "_id": "6297bcabe0e0db6c2572acf8",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Minis Women 50 plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acf8"
            },
            {
                "_id": "6297bcabe0e0db6c2572acfd",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum MultiGummies Men",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acfd"
            },
            {
                "_id": "6297bcabe0e0db6c2572acbd",
                "link": "https://www.bromsite.com/patient/savings-and-support/",
                "description": "BromSite Patients Savings Card: Eligible Medicare D patients may pay as little as $60 on each of up to 4 prescriptions; patient must acknowledge  they will not seek any prescription coverage or reimbursement from their insurer; for for additional information contact the program at 855-379-2324.",
                "brand": "BromSite",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "844",
                "lifetime_uses": "4",
                "max_saving_transaction": 211,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acbd"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad02",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum MultiGummies Women",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad02"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad07",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Silver product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Silver Women",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad07"
            },
            {
                "_id": "6297bcabe0e0db6c2572acc2",
                "link": "https://www.brukinsa.com/mybeigene-patient-support",
                "description": "Brukinsa myBeiGene Co-pay Program: Eligible commercially insured patients may pay as little as $0 per prescription with savings of up to $25,000 per year; for additional information contact the program at 833-234-4363.",
                "brand": "Brukinsa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acc2"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad0c",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Whole Food Blend Womens Multivitamin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad0c"
            },
            {
                "_id": "6297bcabe0e0db6c2572acc7",
                "link": "https://www.azmedcoupons.com",
                "description": "Bydureon BCise Free Trial Offer: Eligible patients may receive a free 28-day supply; for additional information contact the program at 866-680-9081.",
                "brand": "Bydureon BCise",
                "copay": "No",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acc7"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad11",
                "link": "https://cequa.com/cost",
                "description": "Cequa Support Pharmacy: Eligible commercially insured patients may pay as low as $5 per 1-month supply OR $10 per 3-month supply; free home delivery available; for additional information contact the program at 833-442-3782.",
                "brand": "Cequa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 90,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad11"
            },
            {
                "_id": "6297bcabe0e0db6c2572accc",
                "link": "https://albireoassist.com",
                "description": "Bylvay Co-pay Assistance: Eligible commercially insured patients may pay as little as $0 per prescription with a maximum savings of $20,000 per calendar year; for additional information contact the program at 855-252-4736.",
                "brand": "Bylvay",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572accc"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad16",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Acne Foaming Cream Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad16"
            },
            {
                "_id": "6297bcabe0e0db6c2572acd1",
                "link": "https://www.caduet.com/savings-terms",
                "description": "Caduet Savings Card Rebate: Eligible patients may submit a request for a rebate if their pharmacy does not accept the Savings Card; for additional information contact the program at 877-223-8384.",
                "brand": "Caduet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acd1"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad1b",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Healing Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad1b"
            },
            {
                "_id": "6297bcabe0e0db6c2572acd6",
                "link": "https://www.azmedcoupons.com",
                "description": "Calquence Free Trial Offer: NEW patients may receive a free 30-day supply; for more information contact the program at 844-275-2360.",
                "brand": "Calquence",
                "copay": "No",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acd6"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad20",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Psoriasis Moisturizing Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad20"
            },
            {
                "_id": "6297bcabe0e0db6c2572acdb",
                "link": "https://www.boironusa.com/request-a-sample-form/",
                "description": "Camilia Samples: Healthcare professionals may request FREE samples for their office by filling out the form on the website.",
                "brand": "Camilia Teething Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acdb"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad25",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Therapeutic Hand Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad25"
            },
            {
                "_id": "6297bcabe0e0db6c2572ace0",
                "link": "https://www.carbaglu.com/dtc/nags-deficiency-support/",
                "description": "Carbaglu Co-Pay Support Program: Eligible patients may get help with insurance copay and co-insurance costs; for additional information contact the program the program at 888-454-8860.",
                "brand": "Carbaglu",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ace0"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad2a",
                "link": "https://wraser-samples.com/collections/all",
                "description": "Cetraxal FREE Samples: Your healthcare provider may request samples for their practice by creating an online account.",
                "brand": "Cetraxal",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad2a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae05",
                "link": "https://delzicol.copaysavingsprogram.com/",
                "description": "Delzicol Savings Card: Eligible commercially insured patients may pay as little as $10 on each of up to 12 prescriptions; for additional information contact the program at 855-706-8716.",
                "brand": "Delzicol",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae05"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad2f",
                "link": "https://www.chantix.com/support-for-taking-chantix/chantix-savings",
                "description": "Chantix Mail-In Rebate: If your pharmacy does not accept the Savings Card or you use a mail-order pharmacy you may be able to submit a request for a rebate from the program; information needed for the rebate is found on page 2 of the Savings Card offer; for additional assistance contact the program at 800-746-4678.",
                "brand": "Chantix",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1050",
                "lifetime_uses": "6",
                "max_saving_transaction": 175,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad2f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae0a",
                "link": "https://www.depakote.com/mail-order-savings",
                "description": "Depakote ER Mail-Order Rebate: Eligible commercially insured patients using a mail-order pharmacy may submit a rebate request; for additional information contact the program at 800-364-4767.",
                "brand": "Depakote ER",
                "copay": "No",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "2400",
                "lifetime_uses": "24",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae0a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad34",
                "link": "https://www.boironusa.com/chestal-coupon/",
                "description": "$2 coupon for any Chestal cough syrup product with registration; 1 coupon per purchase.",
                "brand": "Chestal Honey",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad34"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad39",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Childrens Tylenol Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad39"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad3e",
                "link": "https://chromagenrx.com/healthcare-professionals/",
                "description": "Chromagen Samples: Healthcare providers may order samples for their practice by filling out an online form or calling 888-612-8466.",
                "brand": "Chromagen",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad3e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad43",
                "link": "https://www.heronconnect.com/CINVANTI/Overview",
                "description": "Cinvanti Heron Connect Copay Assistance Program: Eligible commercially insured patients may pay $0 copay per prescription saving up to $200 per treatment; your healthcare provider must complete enrollment form; for additional information contact the program at 844-437-6611.",
                "brand": "Cinvanti",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad43"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad48",
                "link": "https://www.citracal.com",
                "description": "Register your email address and receive a $2 coupon for any one Citracal product (70 count or larger); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Citracal Slow Release 1200",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad48"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae0f",
                "link": "https://www.depend.com/en-us/coupons?modal=true",
                "description": "Sign up for latest coupons, information on promotions, special discounts and free offers for any Depend products.",
                "brand": "Depend Fit-Flex Underwear for Women",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae0f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad4d",
                "link": "https://www.citranatal.com/",
                "description": "CitraNatal Savings Card: Eligible commercially insured patients may pay as little as $25 per 30-day prescription; for additional information contact the program at 347-480-1771.",
                "brand": "CitraNatal Harmony",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad4d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad52",
                "link": "https://hcp-samples.bayer.com/product-samples/claritin",
                "description": "Claritin Product Samples: Healthcare providers can register online to order free samples for their practice.",
                "brand": "Claritin Childrens Syrup 24-Hour",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad52"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad57",
                "link": "https://hcp-samples.bayer.com/product-samples/claritin",
                "description": "Claritin Product Samples: Healthcare providers can register online to order free samples for their practice.",
                "brand": "Claritin RediTabs for Juniors",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad57"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad5c",
                "link": "https://hcp-samples.bayer.com/product-samples/claritin",
                "description": "Claritin Product Samples: Healthcare providers can register online to order free samples for their practice.",
                "brand": "Claritin-D Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad5c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad61",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Clean & Clear products with registration.",
                "brand": "Clean and Clear Continuous Control",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad61"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad66",
                "link": "https://www.eyefile.com/TheEyeSolution/Register/0?sid=CLRCR0513",
                "description": "Clear Care Products: Save $2 and receive future exclusive offers with membership to the THEeyeSOLUTION; 1 coupon per purchase.",
                "brand": "Clear Care Rinse and Go",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad66"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad6b",
                "link": "https://www.clenpiq.com/hcp/coverage/",
                "description": "Clenpiq Savings Coupon: Eligible commercially insured patients may pay as little as $40 per prescription; for additional information contact the program at 800-485-6490.",
                "brand": "Clenpiq",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0.00",
                "lifetime_uses": "0",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad6b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad70",
                "link": "https://www.orthorxaccess.com/",
                "description": "Clindagel Rx Access Program: Eligible commercially insured patients/RX not covered may pay no more than $65 per fill; valid for 2 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Clindagel",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "2",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad70"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad75",
                "link": "https://epihealth.com/rebate/",
                "description": "Cloderm Patient Access Card: Eligible commercially insured patients may pay as little as $0 per fill; for additional information contact the program at 855-631-2485.",
                "brand": "Cloderm",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad75"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad7a",
                "link": "https://colacecapsules.com/healthcare-professionals/",
                "description": "Colace Samples: Healthcare providers may register to order samples online for their practice.",
                "brand": "Colace Clear",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad7a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad7f",
                "link": "https://www.colgate.com/en-us/smiles/special-offers",
                "description": "Sign up to receive coupons, special offers and other valuable information from Colgate.",
                "brand": "Colgate Enamel Health Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad7f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad84",
                "link": "https://smiles.colgate.com/page/content/signup/",
                "description": "Sign up to receive coupons, special offers and other valuable information from Colgate.",
                "brand": "Colgate Total Mouthwash for Gum Health",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad84"
            },
            {
                "_id": "6297bcabe0e0db6c2572ace5",
                "link": "https://carospirsavings.com/patient",
                "description": "CaroSpir EasyPay Program: Eligible patients may save up to $75 per 30-day prescription or $225 per 90-day prescription; for additional information contact the program at 844-567-9503.",
                "brand": "CaroSpir",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "900",
                "lifetime_uses": "12",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ace5"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad89",
                "link": "https://www.combivent.com/savings/card",
                "description": "Combivent Respimat Savings Card: Eligible commercially insured patients may pay as little as $10 per 30-day prescription with a savings of up to $50 per 30-day supply for up to 6 prescriptions per calendar year; for additional information contact the program at 800-867-1052.",
                "brand": "Combivent Respimat",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 50,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad89"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad8e",
                "link": "https://uspco.com/product_literature.aspx?pid=51&tab=Contact",
                "description": "Concept DHA Samples: Healthcare providers may request free samples for their practice by filling out a form online.",
                "brand": "Concept DHA",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad8e"
            },
            {
                "_id": "6297bcabe0e0db6c2572acea",
                "link": "https://www.celebrex.com/savings-terms",
                "description": "Celebrex Savings Card Rebate: If the pharmacy does not accept the Savings Card eligible patients may be able to submit a request for a rebate in connection with the Savings Card; for additional information contact the program at 855-612-1956.",
                "brand": "Celebrex",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "2800",
                "lifetime_uses": "12",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acea"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad93",
                "link": "https://www.contac.com/coupons/",
                "description": "Save $1 on any one Contac (excludes Cold+Flu 8 count); limit one coupon per purchase.",
                "brand": "Contac Cold+Flu Day and Nite Dual Formula Pack",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad93"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad98",
                "link": "https://www.ascensiadiabetes.com/meters-and-strips-savings/save-on-test-strips-contour-choice-card/",
                "description": "Contour Choice Card: Eligible cash-paying patients pay $25 and can save up to $66 per 100 test strips prescription; for additional information contact the program at 855-226-3931.",
                "brand": "Contour Next One Test Strip",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 66,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad98"
            },
            {
                "_id": "6297bcabe0e0db6c2572acef",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Adults 50 plus Fresh  Fruity Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acef"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad9d",
                "link": "https://www.convatec.com/forms/request-samples-global/",
                "description": "ConvaTec Products: Eligible patients may request a FREE sample of a wide range of secure pouches and skin barriers; for additional information contact the program at 800-422-8811.",
                "brand": "ConvaTec Ostomy Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad9d"
            },
            {
                "_id": "6297bcabe0e0db6c2572acf4",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Men",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acf4"
            },
            {
                "_id": "6297bcabe0e0db6c2572acf9",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Multi plus Omega-3 Men 50 plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acf9"
            },
            {
                "_id": "6297bcabe0e0db6c2572acfe",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum MultiGummies Men 50 plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572acfe"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad2d",
                "link": "https://www.emdseronofertility.com/en/patient/affording-treatment/medication-savings.html",
                "description": "Cetrotide Compassionate Care Program: Eligible patients may save up to 75% off the available self-pay price for their medication at a participating pharmacy; patients may use the program for multiple cycles each year;  patients must reapply each year; for additional information contact the program at 855-541-5926.",
                "brand": "Cetrotide",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad2d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad03",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum MultiGummies Women 50 plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad03"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad32",
                "link": "https://www.boironusa.com/chestal-coupon/",
                "description": "$2 coupon for any Chestal cough syrup product with registration; 1 coupon per purchase.",
                "brand": "Chestal Childrens Honey",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad32"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad08",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Specialist Energy Multivitamin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad08"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad37",
                "link": "https://www.rbhcprofessional.com/s/resource-center?brand=Delsym",
                "description": "Delsym Samples: Healthcare providers may order samples for their office online.",
                "brand": "Childrens Delsym Cough plus Cold Night Time",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad37"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad3c",
                "link": "https://www.xyzal.com/coupons/",
                "description": "Save $3 on any one Children's XYZAL product; one coupon per purchase.",
                "brand": "Childrens Xyzal",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad3c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad41",
                "link": "https://www.cinryze.com/financial-support",
                "description": "Cinryze OnePath Co-Pay Assistance Program: Eligible commercially insured patients may save on certain out-of-pocket treatment costs; for additional information contact the program at 866-888-0660.",
                "brand": "Cinryze",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad41"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad0d",
                "link": "https://www.centrum.com/coupons/",
                "description": "Save $2 off any one Centrum Multivitamin product (60ct or larger) with sign up; 1 coupon per purchase.",
                "brand": "Centrum Women",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad0d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad46",
                "link": "https://www.citracal.com",
                "description": "Register your email address and receive a $2 coupon for any one Citracal product (70 count or larger); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Citracal Petites",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad46"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad4b",
                "link": "https://www.citranatal.com/",
                "description": "CitraNatal Savings Card: Eligible commercially insured patients may pay as little as $25 per 30-day prescription; for additional information contact the program at 347-480-1771.",
                "brand": "CitraNatal B-Calm",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad4b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad12",
                "link": "https://www.cequa.com/cost",
                "description": "Cequa Copay Program: Eligible commercially insured patients may pay as little as $10 for 60 vials (1 box); for additional information contact the program at 855-268-1426.",
                "brand": "Cequa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad12"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad50",
                "link": "https://www.claritin.com/savings",
                "description": "Save $2 off any one Childrens/Junior Claritin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Claritin Childrens Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad50"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad17",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Baby Healing Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad17"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad55",
                "link": "https://hcp-samples.bayer.com/product-samples/claritin",
                "description": "Claritin Product Samples: Healthcare providers can register online to order free samples for their practice.",
                "brand": "Claritin Liqui-Gels 24HR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad55"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad5a",
                "link": "https://hcp-samples.bayer.com/product-samples/claritin",
                "description": "Claritin Product Samples: Healthcare providers can register online to order free samples for their practice.",
                "brand": "Claritin Tablets 24HR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad5a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad1c",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Hydrocortisone Anti-Itch Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad1c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad5f",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Clean & Clear products with registration.",
                "brand": "Clean and Clear Advantage Acne Control Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad5f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad21",
                "link": "https://www.cerave.com/coupons",
                "description": "$4 off any CeraVe product when you register your email address; 1 coupon per purchase",
                "brand": "CeraVe Resurfacing Retinol Serum",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad21"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad64",
                "link": "https://www.eyefile.com/TheEyeSolution/Register/0?sid=CLRCR0513",
                "description": "Clear Care Products: Save $2 and receive future exclusive offers with membership to the THEeyeSOLUTION; 1 coupon per purchase.",
                "brand": "Clear Care",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad64"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad26",
                "link": "https://www.cerdelga.com/access-copay-assistance",
                "description": "Cerdelga CareConnectPSS Copay Program: Eligible commercially insured patients may receive assisance to pay out-of-pocket, drug-related expenses, including copays, coinsurance, and deductibles; for additional information contact the program at 800-745-4447, option 3.",
                "brand": "Cerdelga",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad26"
            },
            {
                "_id": "6297bcabe0e0db6c2572ada2",
                "link": "https://copiktra.com/patient-assistance/",
                "description": "Copiktra Co-Pay Program: Eligible commercially insured patients may pay as little as $5 per monthly prescription with a savings of $25,000 per calendar year; for additional information contact the program at 844-973-2872.",
                "brand": "Copiktra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.33,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ada2"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad2b",
                "link": "https://www.wraser-direct.com/",
                "description": "Cetraxal WraSer-Direct Universal Coupon Program: Eligible commercially insured patients/RX not covered may pay no more than $20 per prescription when using a WraSer Pharmacy Partner; for additional information contact the program at 601-605-0664.",
                "brand": "Cetraxal",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad2b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad69",
                "link": "https://www.clearasil.us",
                "description": "Sign up for exclusive savings and newsletters from Clearasil.",
                "brand": "Clearasil Stubborn Acne Control Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad69"
            },
            {
                "_id": "6297bcabe0e0db6c2572ada7",
                "link": "https://almiralladvantage.com/",
                "description": "Cordran (Cream) Advantage Savings Card: Eligible commercially insured patients may pay no more than $35 per prescription at an out-of-network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Cordran",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ada7"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad30",
                "link": "https://www.chantix.com/support-for-taking-chantix/chantix-savings",
                "description": "Chantix Savings Card: Eligible commercially insured patients may pay as little as $0 per 30-day supply with a savings of up to $175 per fill; maximum savings of $1050 per year; card may be redeemed 6 times within the calendar year; for additional assistance contact the program at 800-746-4678.",
                "brand": "Chantix",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "1050",
                "lifetime_uses": "6",
                "max_saving_transaction": 175,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad30"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad6e",
                "link": "https://www.medimetriks.com/prescription-brands/clindacin-pac-clindacin-p",
                "description": "Clindacin Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Clindacin",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad6e"
            },
            {
                "_id": "6297bcabe0e0db6c2572adac",
                "link": "https://almiralladvantage.com/",
                "description": "Cordran (Tape) Almirall Advantage Savings Card: Eligible commercially insured patients/Rx not covered may pay no more than $150 per prescription; for additional information contact the program at 888-591-9860.",
                "brand": "Cordran",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adac"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad73",
                "link": "https://clindesse.com/samples.html/",
                "description": "Clindesse Patient Savings Program: Eligible patients may pay no more than $25 with savings of up to $75 per prescription; for additional information contact the program at 844-492-9820.",
                "brand": "Clindesse",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad73"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad35",
                "link": "https://www.delsym.com/",
                "description": "Click on the Special Offers tab at the top of the website to print a $5 coupon off any Delsym product; you will be redirected to coupons.com to print your coupon; 1 coupon per purchase.",
                "brand": "Childrens Delsym 12 Hour Cough Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad35"
            },
            {
                "_id": "6297bcabe0e0db6c2572adb1",
                "link": "https://www.coricidinhbp.com/coupons/",
                "description": "Save  on any Coricidin HBP product by registering your email address; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Coricidin HBP Cough and Cold",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adb1"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad78",
                "link": "https://colacecapsules.com/healthcare-professionals/",
                "description": "Colace Samples: Healthcare providers may register to order samples online for their practice.",
                "brand": "Colace",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad78"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad3a",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Childrens Tylenol Dissolve Powder Packs",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad3a"
            },
            {
                "_id": "6297bcabe0e0db6c2572adb6",
                "link": "https://www.coricidinhbp.com/coupons/",
                "description": "Save on any Coricidin HBP product by registering your email address; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Coricidin HBP Maximum Strength Nighttime Cold and Flu Liquid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adb6"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad3f",
                "link": "https://www.cimzia.com/co-pay",
                "description": "Cimzia CIMplicity Savings Card: Eligible commercially insured patients may pay $0 out-of-pocket costs with savings of up to $15,000 per calendar year; for additional information contact the program at 866-424-6942 option 1.",
                "brand": "Cimzia",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad3f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad44",
                "link": "https://www.citracal.com",
                "description": "Register your email address and receive a $2 coupon for any one Citracal product (70 count or larger); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Citracal Calcium",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad44"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad49",
                "link": "https://www.citranatal.com/",
                "description": "CitraNatal Savings Card: Eligible commercially insured patients may pay as little as $25 per 30-day prescription; for additional information contact the program at 347-480-1771.",
                "brand": "CitraNatal 90 DHA",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad49"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad4e",
                "link": "https://hcp-samples.bayer.com/product-samples/claritin",
                "description": "Claritin Product Samples: Healthcare providers can register online to order free samples for their practice.",
                "brand": "Claritin Chewables 24HR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad4e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad53",
                "link": "https://www.claritin.com/savings",
                "description": "Save $2 off any one Childrens/Junior Claritin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Claritin Childrens Syrup 24-Hour",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad53"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad58",
                "link": "https://www.claritin.com/savings",
                "description": "Save $2 off any one Claritin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Claritin RediTabs Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad58"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad5d",
                "link": "https://www.claritin.com/savings",
                "description": "Save $2 off any one Claritin D product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Claritin-D Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad5d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad62",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Clean & Clear products with registration.",
                "brand": "Clean and Clear Essentials Daily Acne Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad62"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad67",
                "link": "https://www.clearasil.us",
                "description": "Sign up for exclusive savings and newsletters from Clearasil.",
                "brand": "Clearasil Gentle Prevention Daily Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad67"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad6c",
                "link": "https://www.climarapro.com/save-climara-pro",
                "description": "Climara Pro Bayer Savings Card Program: Eligible commercially insured patients may pay as little as $0 co-pay per prescription with savings of up to $125 per fill; for additional assistance contact the program at 866-203-3503.",
                "brand": "Climara Pro",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad6c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad71",
                "link": "https://www.orthorxaccess.com/",
                "description": "Clindagel Rx Access Program: Eligible uninsured/cash-pay patients may pay $65 per prescription; valid for 2 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Clindagel",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "2",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad71"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad76",
                "link": "https://closys.com/pages/patient-coupons",
                "description": "CloSYS Product Samples: Doctors may order free samples and coupons for their patients by using the online form.",
                "brand": "CloSYS Sensitive Fluoride",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad76"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad7b",
                "link": "https://www.boironusa.com/request-a-sample-form/",
                "description": "ColdCalm Kids Samples: Healthcare professionals may request FREE samples by filling out a form online.",
                "brand": "Coldcalm Liquid Doses",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad7b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad80",
                "link": "https://smiles.colgate.com/page/content/signup/",
                "description": "Sign up to receive coupons, special offers and other valuable information from Colgate.",
                "brand": "Colgate Hydris Oral Rinse for Dry Mouth",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad80"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad85",
                "link": "https://www.colgate.com/en-us/smiles/special-offers",
                "description": "Sign up to receive coupons, special offers and other valuable information from Colgate.",
                "brand": "Colgate Total Toothpaste Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad85"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad8a",
                "link": "https://www.combivent.com/savings/card",
                "description": "Combivent Respimat RespiPoints: Eligible patients may register with the program to earn points to redeem digital gift cards; for additional information contact the program at 203-957-3781.",
                "brand": "Combivent Respimat",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad8a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad8f",
                "link": "https://concerta.net",
                "description": "Concerta Savings Program: Eligible commercially insured patients pay as little as $4 per 30-day supply; maximum savings of $150 per fill and $1800 per calendar year; for more information contact the program at 800-526-7736.",
                "brand": "Concerta",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad8f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad94",
                "link": "https://www.ascensiadiabetes.com/meters-and-strips-savings/free-contour-next-ez-meter/",
                "description": "FREE Contour Next EZ Meter: Eligible private/commercially insured patients may receive a free meter with a minimum purchase of 50-count test strips; for additional information contact the program at 800-348-8100.",
                "brand": "Contour Next EZ Meter",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad94"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad99",
                "link": "https://ascensiaconcierge.archwayconnect.com/login",
                "description": "Contour Test Strips Samples: Your healthcare provider may be able to  order FREE samples of Contour Test Strips by registering with Contour next Concierge website.",
                "brand": "Contour Next One Test Strip",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad99"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad9e",
                "link": "https://products.coloplast.us/products/continence-care/#%26currentPage%3D1%26facetStrings%3DAllowSamplingFacet%3ATrue%3BProductBrands%3AConveen%25C2%25AE",
                "description": "Receive FREE samples of Conveen products; sign up required; for additional information contact the program at 888-726-7872.",
                "brand": "Conveen Active",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad9e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ada3",
                "link": "https://copiktra.com/patient-assistance/",
                "description": "Copiktra Secura Bio Quickstart Program: Eligible commercially insured patients may receive their 1st cycle at no cost if they are experiencing an insurance delay of 5 days or more; for additional information contact the program at 844-973-2872.",
                "brand": "Copiktra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ada3"
            },
            {
                "_id": "6297bcabe0e0db6c2572ada8",
                "link": "https://almiralladvantage.com/",
                "description": "Cordran (Tape) Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $35 per prescription; for additional information contact the program at 888-591-9860.",
                "brand": "Cordran",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ada8"
            },
            {
                "_id": "6297bcabe0e0db6c2572adad",
                "link": "https://almiralladvantage.com/",
                "description": "Cordran (Lotion) Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $15 per prescription at an in-network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Cordran",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adad"
            },
            {
                "_id": "6297bcabe0e0db6c2572adb2",
                "link": "https://www.coricidinhbp.com/coupons/",
                "description": "Save on any Coricidin HBP product by registering your email address; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Coricidin HBP Cough and Cold",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adb2"
            },
            {
                "_id": "6297bcabe0e0db6c2572adbb",
                "link": "https://www.cortizone10.com/special-offers.html",
                "description": "$1 off coupon on any Cortizone-10 product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Cortizone-10 Anti-Itch Lotion for Psoriasis",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adbb"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad7d",
                "link": "https://www.pfizermedicalinformation.com/en-us/colestid-granules",
                "description": "Colestid Samples: Your healthcare provider may order samples by visiting the PfizerPro website or calling 800-505-4426.",
                "brand": "Colestid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad7d"
            },
            {
                "_id": "6297bcabe0e0db6c2572adc0",
                "link": "https://www.cortizone10.com/special-offers.html",
                "description": "$1 off coupon on any Cortizone-10 product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Cortizone-10 Intensive Healing Eczema Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adc0"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad82",
                "link": "https://www.colgate.com/en-us/smiles/special-offers",
                "description": "Sign up to receive coupons, special offers and other valuable information from Colgate.",
                "brand": "Colgate Renewal Toothpaste Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad82"
            },
            {
                "_id": "6297bcabe0e0db6c2572adc5",
                "link": "https://www.cosamin.com/drstartcosamin-form",
                "description": "Healthcare providers may request $5 coupons for any one Cosamin products for their patients by filling out a form online.",
                "brand": "Cosamin DS",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adc5"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad87",
                "link": "https://www.savewithays.com/",
                "description": "Combigan Savings Card: Eligible commercially insured patients pay as little as $30 per 30-day prescription; offer may be used for 13 fills; for additional information contact the program at 833-342-5297.",
                "brand": "Combigan",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad87"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad8c",
                "link": "https://www.gileadadvancingaccess.com/financial-support/gilead-copay-card",
                "description": "Complera Advancing Access Co-pay Program: Eligible commercially insured patients may save up to a maximum of $6000 per year with no monthly limits; for additional information contact the program at 800-226-2056.",
                "brand": "Complera",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "ACCESS",
                "copay_max": "6000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad8c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad91",
                "link": "https://www.contac.com/coupons/",
                "description": "Save $1 on any one Contac (excludes Cold+Flu 8 count); limit one coupon per purchase.",
                "brand": "Contac Cold Flu Day",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad91"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad96",
                "link": "https://www.ascensiadiabetes.com/meters-and-strips-savings/free-contour-next-one-meter/",
                "description": "FREE Contour Next One Meter: Eligible private/commercially insured patients may receive a free meter with a minimum purchase of 50-count test strips; for additional information contact the program at 800-348-8100.",
                "brand": "Contour Next One Meter",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad96"
            },
            {
                "_id": "6297bcabe0e0db6c2572ad9b",
                "link": "https://contrave.com/save/",
                "description": "Contrave Savings Coupon Card: Eligible patients may pay $114.99 per monthly prescription; for additional information contact the program at 800-905-5576.",
                "brand": "Contrave",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ad9b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ada0",
                "link": "https://www.alle.com/upgrade",
                "description": "CoolSculpting Alle Points Program: Register and earn points redeemable for dollars OFF certain in-office treatments; also receive personalized special offers; for additional information contact the program at 888-912-1572.",
                "brand": "CoolSculpting",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ada0"
            },
            {
                "_id": "6297bcabe0e0db6c2572ada5",
                "link": "https://almiralladvantage.com/",
                "description": "Cordran (Ointment) Almirall Advantage Savings Card: Eligible commercially insured patients/RX not covered may pay no more than $50 per prescription when using an in-network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Cordran",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ada5"
            },
            {
                "_id": "6297bcabe0e0db6c2572adaa",
                "link": "https://almiralladvantage.com/",
                "description": "Cordran (Lotion) Almirall Advantage Savings Card: Eligible commercially insured patients/RX not covered may pay no more than $50 per prescription at an out-of-network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Cordran",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adaa"
            },
            {
                "_id": "6297bcabe0e0db6c2572adaf",
                "link": "https://www.coricidinhbp.com/coupons/",
                "description": "Save on any Coricidin HBP product by registering your email address; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Coricidin HBP Chest Congestion and Cough",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adaf"
            },
            {
                "_id": "6297bcabe0e0db6c2572adb4",
                "link": "https://www.coricidinhbp.com/coupons/",
                "description": "Save on any Coricidin HBP product by registering your email address; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Coricidin HBP Maximum Strength Cold Cough and Flu Liquid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adb4"
            },
            {
                "_id": "6297bcabe0e0db6c2572adb9",
                "link": "https://www.cortizone10.com/special-offers.html",
                "description": "$1 off coupon on any Cortizone-10 product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Cortizone-10 Anti-Itch Creme Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adb9"
            },
            {
                "_id": "6297bcabe0e0db6c2572adbe",
                "link": "https://www.cortizone10.com/special-offers.html",
                "description": "$1 off coupon on any Cortizone-10 product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Cortizone-10 Easy Relief Applicator Anti-Itch Liquid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adbe"
            },
            {
                "_id": "6297bcabe0e0db6c2572adc3",
                "link": "https://www.cosamin.com/drstartcosamin-form",
                "description": "Healthcare providers may request $5 coupons for any one Cosamin products for their patients by filling out a form online.",
                "brand": "Cosamin ASU",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adc3"
            },
            {
                "_id": "6297bcabe0e0db6c2572adc8",
                "link": "https://www.cosentyx.com/treatment-cost",
                "description": "Cosentyx Covered Until You're Covered Program: Eligible commercially insured patients whose insurance has denied coverage may receive medication for up to 2 years at no cost; for additional information contact the program at 844-267-3689.",
                "brand": "Cosentyx",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adc8"
            },
            {
                "_id": "6297bcabe0e0db6c2572adcd",
                "link": "https://cotemplaxrodt.com/#rxconnect-section",
                "description": "Cotempla XR-ODT Aytu RxConnect Pharmacy Network Program: Eligible commercially insured patients may pay $0 per 30-day prescription when using a Aytu RxConnect Pharmacy; for additional information contact the program at 877-675-6590.",
                "brand": "Cotempla XR-ODT",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adcd"
            },
            {
                "_id": "6297bcabe0e0db6c2572add2",
                "link": "https://www.creon.com/cfcareforward",
                "description": "Creon CFCareFoward Co-pay Card: Eligible commercially insured patients may pay as little as $0 for their monthly prescription; for additional information contact the program at 855-227-3493.",
                "brand": "Creon",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572add2"
            },
            {
                "_id": "6297bcabe0e0db6c2572add7",
                "link": "https://www.crestor.com/savings-resources/savings-offers.html#menu1",
                "description": "Crestor Mail Order Rebate for Mail-Order Pharmacy: Eligible uninsured patients may save up to $130 per 30-day supply on each of up to 12 prescriptions; for additional information contact the program at 800-236-9933.",
                "brand": "Crestor",
                "copay": "No",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1560",
                "lifetime_uses": "12",
                "max_saving_transaction": 130,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572add7"
            },
            {
                "_id": "6297bcabe0e0db6c2572addc",
                "link": "https://www.wdrxaccess.com",
                "description": "Cuprimine WD Rx Access Program: Eligible commercially insured patients may pay as little as a $5 copay per monthly prescription; for additional information contact the program at 888-607-7267.",
                "brand": "Cuprimine",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572addc"
            },
            {
                "_id": "6297bcabe0e0db6c2572ade1",
                "link": "https://www.salix.com/therapeutic-areas/hcp-focus",
                "description": "Cycloset Samples: Your healthcare provider may request by logging onto the website.",
                "brand": "Cycloset",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ade1"
            },
            {
                "_id": "6297bcabe0e0db6c2572ade6",
                "link": "https://www.pfizermedicalinformation.com/en-us/cytomel",
                "description": "Cytomel Samples: Your healthcare provider may request samples by visiting the PfizerPro website or calling 800-505-4426.",
                "brand": "Cytomel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ade6"
            },
            {
                "_id": "6297bcabe0e0db6c2572adca",
                "link": "https://cosoptpf.com/consumer/index.html",
                "description": "Cosopt PF FREE Samples: Your healthcare provider may request free samples of Cosopt PF by completing the online form; for additional information contact the program at 800-932-5676.",
                "brand": "Cosopt PF",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adca"
            },
            {
                "_id": "6297bcabe0e0db6c2572adcf",
                "link": "https://cotemplaxrodt.com/#rxconnect-section",
                "description": "Cotempla XR-ODT Savings Card: Eligible commercially insured patients/RX not covered pay $50 prescription; for additional information contact the program at 888-298-8792.",
                "brand": "Cotempla XR-ODT",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adcf"
            },
            {
                "_id": "6297bcabe0e0db6c2572add4",
                "link": "https://www.activatethecard.com/cresemba/",
                "description": "Cresemba Patient Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription for savings of up to a maximum of $4000 per calendar year; for additional information contact the program at 855-332-5616.",
                "brand": "Cresemba",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "4000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572add4"
            },
            {
                "_id": "6297bcabe0e0db6c2572add9",
                "link": "https://www.crestor.com",
                "description": "Crestor Savings Card: Eligible uninsured patients may save up to $130 per 30-day supply on each of up to 12 prescriptions; for additional information contact the program at 855-687-2151.",
                "brand": "Crestor",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1560",
                "lifetime_uses": "12",
                "max_saving_transaction": 130,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572add9"
            },
            {
                "_id": "6297bcabe0e0db6c2572adde",
                "link": "https://www.cuvitru.com/support-and-resources/hello-cuvitru",
                "description": "Cuvitru HelloCUVITRU Free Trial Offer: Eligible new patients 2 years of age or older may receive their 1st 4 infusions at no cost and supplies needed for administration; for additional information contact the program at 866-888-0660.",
                "brand": "Cuvitru",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adde"
            },
            {
                "_id": "6297bcabe0e0db6c2572ade3",
                "link": "https://www.lillyoncologysupport.com/cyramza-financial-support",
                "description": "Cyramza Savings Card: Eligible commercially insured patients may pay as little as $25 per dose with a maximum savings of up to $25,000; for additional information contact the program at 866-472-8663.",
                "brand": "Cyramza",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.34,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ade3"
            },
            {
                "_id": "6297bcabe0e0db6c2572ade8",
                "link": "https://www.dailies.com/dailies-free-trial",
                "description": "Dailies Contact Lenses: Receive a FREE 5-day trial and other special offers with membership to the Alcon EyeFile; register your email address to join;coupon to be redeemed at your eye care professional's office; for additional information contact customer service at 800-862-5266.",
                "brand": "Dailies AquaComfort Plus Contact Lenses",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ade8"
            },
            {
                "_id": "6297bcabe0e0db6c2572ade0",
                "link": "https://sservices.trialcard.com/Coupon/cuvposa",
                "description": "Cuvposa Patient Co-Pay Program: Eligible commercially insured patients may pay $0 on out-of-pockets costs with savings of up to $100 per 30-day prescription; for additional information contact the program at 855-740-3040.",
                "brand": "Cuvposa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ade0"
            },
            {
                "_id": "6297bcabe0e0db6c2572ade5",
                "link": "https://www.cystadrops.com/getting-cystadrops/#paying",
                "description": "Cystadrops Copay Assistance Program: Eligible patients may receive help paying for their copays and coinsurance through the program; for more information contact the program at 866-925-6212.",
                "brand": "Cystadrops",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ade5"
            },
            {
                "_id": "6297bcabe0e0db6c2572adea",
                "link": "https://www.dailies.com/dailies-free-trial",
                "description": "Dailies Contact Lenses: Receive a FREE 5-day trial and other special offers with membership to the Alcon EyeFile; register your email address to join;coupon to be redeemed at your eye care professional's office; for additional information contact customer service at 800-862-5266.",
                "brand": "DAILIES Aquacomfort Plus Toric Contact Lenses",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adea"
            },
            {
                "_id": "6297bcabe0e0db6c2572adef",
                "link": "https://www.daliresp.com/savings-program.html",
                "description": "Daliresp Savings Program: Eligible cash-paying patients may save up to $100 per 30-day supply; for additional information contact the program at 866-459-2015.",
                "brand": "Daliresp",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adef"
            },
            {
                "_id": "6297bcabe0e0db6c2572adf4",
                "link": "https://www.janssencarepath.com/patient/darzalex/cost-support",
                "description": "Darzalex Janssen CarePath Savings Program Rebate: Most eligible commercially insured patients may submit a request for rebate if the provider or pharmacy does not accept the Savings Program card; for additional information contact the program at 844-553-2792.",
                "brand": "Darzalex",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adf4"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae14",
                "link": "https://www.depend.com/en-us/coupons?modal=true",
                "description": "Sign up for latest coupons, information on promotions, special discounts and free offers for any Depend products.",
                "brand": "Depend Night Defense Underwear for Women",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae14"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae19",
                "link": "https://www.depend.com/en-us/samples",
                "description": "Receive FREE sample packs for Men's Depend products with registration; for additional information contact the program at 877-413-3736.",
                "brand": "Depend Real Fit Briefs for Men",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae19"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae1e",
                "link": "https://www.depend.com/en-us/coupons?modal=true",
                "description": "Sign up for latest coupons, information on promotions, special discounts and free offers for any Depend products.",
                "brand": "Depend Unisex Night Defense Underpads",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae1e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae23",
                "link": "https://www.pfizermedicalinformation.com/en-us/depo-subq-provera-104",
                "description": "Depo-SubQ Provera 104 Samples: Healthcare providers may request samples by visiting the PfizerPro website or calling 800-505-4426.",
                "brand": "Depo-SubQ Provera 104",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae23"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae28",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Desitin products with registration.",
                "brand": "Desitin Daily Defense",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae28"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae2d",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Desonide Artesa Labs Advantage Card: Eligible commercially insured patients may pay $0 per prescription; for additional information contact the program 877-264-2440.",
                "brand": "Desonide",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae2d"
            },
            {
                "_id": "6297bcabe0e0db6c2572adf9",
                "link": "http://www.daytrana.com/#sod",
                "description": "Daytrana Co-Pay Savings Offer: Eligible commercially insured patients pay as little as $20 for each prescription of 30 patches with savings of up to $80 on each of up to 12 fills; for additional information contact the program at 866-849-4512.",
                "brand": "Daytrana",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "960",
                "lifetime_uses": "12",
                "max_saving_transaction": 80,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adf9"
            },
            {
                "_id": "6297bcabe0e0db6c2572adfe",
                "link": "https://www.delstrigo.com/savings-support/",
                "description": "Delstrigo Savings Coupon: Eligible commercially insured patients may pay as little as $0 per month on their out-of-pocket�costs such as co-pays, co-insurance and deductibles; maximum savings of $6800; coupon may be redeemed once every 21 days; for additional information contact program at 800-672-6372.",
                "brand": "Delstrigo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6800",
                "lifetime_uses": "12",
                "max_saving_transaction": 566.67,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adfe"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae03",
                "link": "https://www.rbhcprofessional.com/s/resource-center?brand=Delsym",
                "description": "Delsym Samples: Healthcare providers may order samples for their office online.",
                "brand": "Delsym Cough and Chest Suppressants DM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae03"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae08",
                "link": "https://www.depakote.com/mail-order-savings",
                "description": "Depakote Mail-Order Rebate: Eligible commercially insured patients using a mail-order pharmacy may submit a rebate request; for additional information contact the program at 800-364-4767.",
                "brand": "Depakote",
                "copay": "No",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "2400",
                "lifetime_uses": "24",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae08"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae0d",
                "link": "https://www.depend.com/en-us/coupons?modal=true",
                "description": "Sign up for latest coupons, information on promotions, special discounts and free offers for any Depend products.",
                "brand": "Depend Fit-Flex Underwear for Men",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae0d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae32",
                "link": "https://www.dexilant.com/savings",
                "description": "Dexilant Instant Savings Card: Eligible commercially insured patients pay no more than $20 per 30-day prescription with savings of up to $55; for additional information contact the customer service center at 866-279-5598.",
                "brand": "Dexilant",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "1016",
                "copay_max": "660",
                "lifetime_uses": "12",
                "max_saving_transaction": 55,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae32"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae37",
                "link": "https://diabetictussin.com/",
                "description": "$1 off any Diabetic Tussin product; limit 1 coupon per purchase.",
                "brand": "Diabetic Tussin Cough and Chest Congestion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae37"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae3c",
                "link": "https://differin.com/save",
                "description": "Save $2.50 on any one Differin Gel product; coupon expires 1 month after; 1 coupon per purchase.",
                "brand": "Differin Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2.5,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae3c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae41",
                "link": "https://www.dificid.com",
                "description": "Dificid Savings Coupon: Eligible commercially insured patients may pay as little as $50 on each prescription of up to 20 tablets with maximum total benefit of up to $3400; offer valid for up to 4 prescription fills; for additional information contact the program at 877-264-2454.",
                "brand": "Dificid",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "3400",
                "lifetime_uses": "4",
                "max_saving_transaction": 850,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae41"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae46",
                "link": "https://www.diovan.com/copay-faqs",
                "description": "Diovan Co-Pay Card Program Rebate: Eligible commercially insured patients may submit a rebate request online if their pharmacy does not accept the savings card; for additional information contact the program at 877-699-9975.",
                "brand": "Diovan",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae46"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae12",
                "link": "https://www.depend.com/en-us/coupons?modal=true",
                "description": "Sign up for latest coupons, information on promotions, special discounts and free offers for any Depend products.",
                "brand": "Depend Guards for Men",
                "copay": "",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae12"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae17",
                "link": "https://www.depend.com/en-us/coupons?modal=true",
                "description": "Sign up for latest coupons, information on promotions, special discounts and free offers for any Depend products.",
                "brand": "Depend Protection with Tabs Unisex Briefs",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae17"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae1c",
                "link": "https://www.depend.com/en-us/samples",
                "description": "Receive FREE sample packs for Women's Depend Silhouette products with registration; for additional information contact the program at 877-413-3736.",
                "brand": "Depend Silhouette Brief Products for Women",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae1c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae21",
                "link": " https://www.pfizermedicalinformation.com/en-us/depo-estradiol",
                "description": "Depo-Estradiol Samples: Healthcare providers may request samples by visiting the PfizerPro website or calling 800-505-4426.",
                "brand": "Depo-Estradiol",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae21"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae26",
                "link": "http://www.dermend.com/coupons/",
                "description": "DerMend Products Coupon: Save up to $3 on any one product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "DerMend Moisturizing Bruise Formula Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae26"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae2b",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Desonide Artesa Labs Advantage Program: Eligible commercially insured/Rx not covered patients may pay as little as $45 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Desonide",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae2b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae30",
                "link": "https://www.detrolla.com/savings-terms",
                "description": "Detrol LA Savings Card Rebate: If the pharmacy does not accept the Savings Card eligilbe patients may submit a request for a rebate; for additional information contact the program at 855-541-5932.",
                "brand": "Detrol LA",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae30"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae35",
                "link": "https://diabetictussin.com/",
                "description": "$1 off any Diabetic Tussin product; limit 1 coupon per purchase.",
                "brand": "Diabetic Tussin Chest Congestion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae35"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae3a",
                "link": "https://differin.com/save",
                "description": "Save $1.75 on any one Differin Cleanser or Moisturizer product; coupon expires 1 month after; 1 coupon per purchase.",
                "brand": "Differin Daily Deep Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1.75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae3a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae3f",
                "link": "https://differin.com/save",
                "description": "Save $1.75 on any one Differin Cleanser or Moisturizer product; coupon expires 1 month after; 1 coupon per purchase.",
                "brand": "Differin Restorative Night Moisturizer",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1.75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae3f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae44",
                "link": "https://www.dilantin.com/savings-terms",
                "description": "Dilantin Savings Card Rebate: If the pharmacy does not accept the Savings Card eligible commercially insured patients may be able to submit a request for a rebate in connection with the Savings Offer; for additional information contact the program at 866-590-9400.",
                "brand": "Dilantin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "240",
                "lifetime_uses": "12",
                "max_saving_transaction": 20,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae44"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae49",
                "link": "https://www.alva-amco.com/contact",
                "description": "Consumers may fill out a request form for Diurex product coupons.",
                "brand": "Diurex Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae49"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae4e",
                "link": "http://www.doryx.com/#copay",
                "description": "Doryx MPC Mayne Pharma Patient Savings Card: Eligible commercially insured patients/RX not covered may have a copay of $65 per prescription; for additional information contact the program at 347-442-7919.",
                "brand": "Doryx MPC",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae4e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae53",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Arthritis Pain Reliever",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae53"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae4b",
                "link": "https://www.divigel.com/start-saving/",
                "description": "Divigel Patient Savings Program: Eligible patients may no more than $25 on each 30-day supply fill; for additional information contact the program at 844-727-5540.",
                "brand": "Divigel",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae4b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae50",
                "link": "https://myviivcard.com/rebate-form-instructions.html",
                "description": "Dovato ViiVConnect Rebate: Eligible commercially insured patients may be eligible for a rebate for the amount paid out of pocket per prescription if the pharmacy does not accept the savings card; rebate may be completed online or by downloading and mailing in rebate form; for additional information contact the program at 866-747-1170.",
                "brand": "Dovato",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6250",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae50"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae55",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Bunion Cushions",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae55"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae5a",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Clear Away Plantar Wart Remover",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae5a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae5f",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Corn Removers with Duragel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae5f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae64",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Ingrown Toenail Pain Reliever",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae64"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae58",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Callus Removers with Duragel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae58"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae69",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Odor-X Antifungal Spray Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae69"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae5d",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Corn Cushions with Duragel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae5d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae62",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Freeze Away Max Wart Remover",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae62"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae6e",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls One Step Corn Removers",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae6e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae67",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Moleskin Plus Padding Roll",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae67"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae73",
                "link": "https://www.duavee.com/savings-card",
                "description": "Duavee Savings Card: Eligible commercially insured patients may pay as little as $25 with a savings of up to $70 per monthly prescription; for additional assistance contact the program at 866-881-2545.",
                "brand": "Duavee",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "840",
                "lifetime_uses": "12",
                "max_saving_transaction": 70,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae73"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae6c",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Odor-X Ultra Odor-Fighting Spray Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae6c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae78",
                "link": "https://www.dulcolax.com/coupons",
                "description": "Save $2 on any one Dulcolax product (excluding 10 count or smaller); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Dulcolax Pink Laxative Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae78"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae7d",
                "link": "https://www.organonconnect.com/dulera/coupons-samples/",
                "description": "Dulera Samples, Vouchers & Coupons: Your healthcare provider may make requests for samples, vouchers and/or coupons by logging into the Merck Connect website or by calling 866-237-4286.",
                "brand": "Dulera",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae7d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae71",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Ultra Hydrating Foot Mask Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae71"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae82",
                "link": "https://www.orthorxaccess.com/",
                "description": "Duobrii Rx Access Program: Eligible commercially insured patients/RX not covered  may pay no more than $65 per fill; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Duobrii",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae82"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae76",
                "link": "https://www.dulcolax.com/coupons",
                "description": "Save $2 on any one Dulcolax product (excluding 10 count or smaller); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Dulcolax Laxative",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae76"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae7b",
                "link": "https://www.dulcolax.com/coupons",
                "description": "Save $2 on any one Dulcolax product (excluding 10 count or smaller); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Dulcolax Stool Softener",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae7b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae87",
                "link": "https://coupon.trisadhd.com",
                "description": "Dyanavel XR Savings Card: Eligible cash-paying patients may save on their monthly prescriptions; for additional information contact the program at 888-840-7006.",
                "brand": "Dyanavel XR",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae87"
            },
            {
                "_id": "6297bcabe0e0db6c2572adeb",
                "link": "https://colors.myalcon.com",
                "description": "Dailies Colors Free Trial: Receive a FREE trial and other special offers with  membership to the Alcon EyeFile; register your email address to join;coupon to be redeemed at your eye care professional's office; for additional information contact customer service at 855-344-6871.",
                "brand": "DAILIES COLORS Contact Lenses",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adeb"
            },
            {
                "_id": "6297bcabe0e0db6c2572adf0",
                "link": "https://www.dalvance.com/reimbursement/patient-savings-program",
                "description": "Dalvance Connects Co-pay Assistance Program: Eligible commercially insured patients may receive financial assistance with their out-of-pocket costs up to a maximum benefit of $2000 per calendar year; for additional information contact the program at 855-387-2824.",
                "brand": "Dalvance",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "2000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adf0"
            },
            {
                "_id": "6297bcabe0e0db6c2572adf5",
                "link": "https://www.janssencarepath.com/patient/darzalex-faspro/cost-support",
                "description": "Darzalex Faspro Janssen CarePath Savings Program Rebate: Most eligible commercially insured patients may submit a request for rebate if the provider or pharmacy does not accept the Savings Program card; for additional information contact the program at 844-553-2792.",
                "brand": "Darzalex Faspro",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adf5"
            },
            {
                "_id": "6297bcabe0e0db6c2572adfa",
                "link": "https://www.dayvigo.com/prescription-savings",
                "description": "Dayvigo Instant Savings Card: Eligible commercially insured patients may pay as little as $30 per month; offer can be used 12 times per year; for additional information contact the program directly at 866-422-9844.",
                "brand": "Dayvigo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adfa"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae80",
                "link": "https://www.orthorxaccess.com/",
                "description": "Duobrii Rx Access Program: Eligible uninsured/cash-pay patients pay $65 per prescription; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Duobrii",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae80"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae8c",
                "link": "https://arborpatientdirect.com",
                "description": "Edarbi Arbor Patient Direct: Eligible patients may pay as little as $40 plus shipping for a 30-day supply or $120 for a 90-day supply with FREE shipping by enrolling in the program; for additional information contact the program at 844-289-3981.",
                "brand": "Edarbi",
                "copay": "",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae8c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae91",
                "link": "https://www.edarbi.com/edarbi-edarbyclor-patient-savings-support#savingsCard",
                "description": "Edarbyclor E-Z Rx Program: Eligible cash-paying patients pay $55 per prescription at participating independent pharmacies; for additional information contact the program at 877-264-2440.",
                "brand": "Edarbyclor",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae91"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae85",
                "link": "https://www.oakneepainrelief.com/reimbursement-services/",
                "description": "Durolane BV360 Reimbursement Solution: Your healthcare provider may enroll you in the program by calling 833-692-8360 or by logging into the program portal website.",
                "brand": "Durolane",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae85"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae96",
                "link": "https://www.edex.com/savings-and-support.php",
                "description": "Edex Savings Card: Eligible patients may pay no more than $15 per prescription with savings of up to $75 per fill; offer applies to each of the patient's next 12 prescriptions written for 6 or more injections; for additional information contact the program at 888-203-7915.",
                "brand": "Edex",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae96"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae8a",
                "link": "https://www.ipsencares.com/dysport-patient-support/",
                "description": "Dysport Ipsen Cares Copay Assistance Program: Eligible commercially insured patients may pay $0 per prescription with a maximum savings of $5000 per calendar year; program reset every January 1st; for additional information contact the program at 866-435-5677.",
                "brand": "Dysport",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "4",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae8a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae8f",
                "link": "https://www.edarbi.com/edarbi-edarbyclor-patient-savings-support#savingsCard",
                "description": "Edarbi E-Z Rx Program: Eligible commercially insured patients may pay just $15 per prescription at participating independent pharmacies; for additional information contact the program at 877-264-2440.",
                "brand": "Edarbi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae8f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae9b",
                "link": "https://www.therapatientsupport.com/#/egrifta/patient",
                "description": "Egrifta SV Co-Pay Assistance Program: Eligible commercially insured patients may save on their prescriptions by enrolling in the program; contact a Patient Support Specialist for additional information at 833-238-4372.",
                "brand": "Egrifta SV",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae9b"
            },
            {
                "_id": "6297bcabe0e0db6c2572adff",
                "link": "https://www.delstrigo.com/savings-support/",
                "description": "Delstrigo Savings Coupon Rebate: If a commercially insured patient is unable to redeem the savings card at their retail or mail-order pharmacy the patient should contact the program within 30 days to request a Direct Member Reimbursement form for a rebate; for additional information contact program at 877-264-2454.",
                "brand": "Delstrigo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6800",
                "lifetime_uses": "12",
                "max_saving_transaction": 566.67,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adff"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae04",
                "link": "https://www.delsym.com/",
                "description": "Click on the Special Offers tab at the top of the website to print a $5 coupon off any Delsym product; you will be redirected to coupons.com to print your coupon; 1 coupon per purchase.",
                "brand": "Delsym Cough plus Cold Night Time",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae04"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae09",
                "link": "https://www.depakote.com/savings-program",
                "description": "Depakote ER Savings Card: Eligible commercially insured patients may pay as little as $5 per month on your prescriptions with savings of up to $100 per fill; card be used 2 times per month; for additional information contact the program at 800-364-4767.",
                "brand": "Depakote ER",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "2400",
                "lifetime_uses": "24",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae09"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae0e",
                "link": "https://www.depend.com/en-us/samples",
                "description": "Receive FREE sample packs for Men's Depend products with registration; for additional information contact the program at 877-413-3736.",
                "brand": "Depend Fit-Flex Underwear for Men",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae0e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae13",
                "link": "https://www.depend.com/en-us/coupons?modal=true",
                "description": "Sign up for latest coupons, information on promotions, special discounts and free offers for any Depend products.",
                "brand": "Depend Night Defense Underwear for Men",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae13"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae18",
                "link": "https://www.depend.com/en-us/coupons?modal=true",
                "description": "Sign up for latest coupons, information on promotions, special discounts and free offers for any Depend products.",
                "brand": "Depend Real Fit Briefs for Men",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae18"
            },
            {
                "_id": "6297bcabe0e0db6c2572aea0",
                "link": "https://www.eliquis.bmscustomerconnect.com/afib/savings-and-support?cid=v_1357031",
                "description": "Eliquis Co-pay Card: Eligible patients may pay no more than $10 per 30-day supply for up to 24 months with an annual savings of $3800; for additional information contact the program at 855-354-7847.",
                "brand": "Eliquis",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "3800",
                "lifetime_uses": "12",
                "max_saving_transaction": 316.67,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aea0"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae94",
                "link": "https://www.edarbi.com/edarbi-edarbyclor-patient-savings-support#savingsCard",
                "description": "Edarbyclor Instant Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 877-264-2440.",
                "brand": "Edarbyclor",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae94"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae99",
                "link": "https://www.effexorxr.com/savings-card",
                "description": "Effexor XR Savings Card: Eligible patients may pay as little as $4 per monthly prescription with savings of up to $1800 per year; for additional information contact the program at 855-488-0750.",
                "brand": "Effexor XR",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae99"
            },
            {
                "_id": "6297bcabe0e0db6c2572aea5",
                "link": "https://www.sanoficareassist.com/elitek/copay",
                "description": "Elitek CareASSIST Copay Program Reimbursement Program: Eligible commercially insured patients may submit a reimbursement form if their pharmacy or provider does not participate in the Savings Program; for additional information contact the program at 833-930-2273.",
                "brand": "Elitek",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aea5"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae9e",
                "link": "https://www.activatethecard.com/viatrisadvocate/elestrin/welcome.html",
                "description": "Elestrin Copay Card: Eligible commercially insured patients may pay as little as $0 and save up to $25 per month on each 30-day supply with savings of up to $300 per calendar year; for additional information contact the program at 800-657-7613.",
                "brand": "Elestrin",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "300",
                "lifetime_uses": "12",
                "max_saving_transaction": 25,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae9e"
            },
            {
                "_id": "6297bcabe0e0db6c2572aea3",
                "link": "https://www.eliquis.com/eliquis/hcp/resources#request-samples",
                "description": "Eliquis Samples: Healthcare providers may be eligible to have free samples delivered to their office.",
                "brand": "Eliquis",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aea3"
            },
            {
                "_id": "6297bcabe0e0db6c2572aea8",
                "link": "https://www.eloctate.com/resources/financial-assistance",
                "description": "Eloctate Factor Access Program: Program provides continued vital treatments during a lapse in third-party private health insurance; for additional information contact the program at 855-693-5628.",
                "brand": "Eloctate",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aea8"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae1d",
                "link": "https://www.depend.com/en-us/coupons?modal=true",
                "description": "Sign up for latest coupons, information on promotions, special discounts and free offers for any Depend products.",
                "brand": "Depend Silhouette Brief Products for Women",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae1d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae22",
                "link": "https://www.pfizermedicalinformation.com/en-us/depo-medrol-single-dose-vial",
                "description": "Depo-Medrol�Samples: Your healthcare provider may request samples by contacting PfizerPro online or by calling 800-505-4426.",
                "brand": "Depo-Medrol",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae22"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae27",
                "link": "https://www.gileadadvancingaccess.com/financial-support/gilead-copay-card",
                "description": "Descovy Advancing Access Co-pay Program: Eligible commercially insured patients may save up to a maximum of $7200 per year with no monthly savings limit; for additional information contact the program at 800-226-2056.",
                "brand": "Descovy",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "ACCESS",
                "copay_max": "7200",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae27"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae2c",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Desonide Gel Artesa Labs Advantage Program: Eligible commercially insured patients may pay as little as $0 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Desonide",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae2c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae31",
                "link": "https://www.patientrebateonline.com/patientrebate/welcome.html",
                "description": "Dexilant Instant Savings Card Rebate: Eligible patients using a mail-order pharmacy may submit a request for a rebate in the savings amount; for additional information contact the customer service center at 866-279-5598.",
                "brand": "Dexilant",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "660",
                "lifetime_uses": "12",
                "max_saving_transaction": 55,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae31"
            },
            {
                "_id": "6297bcabe0e0db6c2572aead",
                "link": "https://www.emgality.com/savings",
                "description": "Emgality Savings Card: Eligible commercially insured patients may pay $0 per monthly prescription; offer valid for 12 fills; for additional information contact the program at 833-364-2548.",
                "brand": "Emgality",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aead"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeb2",
                "link": "https://www.gileadadvancingaccess.com/financial-support/gilead-copay-card",
                "description": "Emtriva Advancing Access Co-pay Program: Eligible commercially insured patients may save up to $300 per month with a maximum savings of $3600 per year; for additional information contact the program at 800-226-2056.",
                "brand": "Emtriva",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "ACCESS",
                "copay_max": "3600",
                "lifetime_uses": "12",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeb2"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeb7",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Endometrin OneHeart Program: Eligible commercially insured & cash-paying patients may be eligible to receive a $200 rebate for out-of-pocket costs incurred when using Endometrin for their COS cycle between June 1, 2020 � December 31, 2021; for additional information contact the program at 888-337-7464.",
                "brand": "Endometrin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeb7"
            },
            {
                "_id": "6297bcabe0e0db6c2572aebc",
                "link": "https://enemeez.com/request-samples/",
                "description": "Enemeez FREE Samples: Eligible patients may receive FREE samples of any one Enemeez product; for additional information contact the program at 888-273-9734 ext 208.",
                "brand": "Enemeez",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aebc"
            },
            {
                "_id": "6297bcabe0e0db6c2572aec1",
                "link": "https://www.enfamil.com/baby-formula-coupons-samples/",
                "description": "Join Enfamil Family Beginnings and receive up to $400 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Dietary Needs Formulas",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aec1"
            },
            {
                "_id": "6297bcabe0e0db6c2572adb7",
                "link": "https://www.corifact.com/csl-behring-commitment/default.aspx",
                "description": "Corifact CSL Behring Assurance: Eligible commercially insured patients may earn points for each monthly prescription; points may be used in the event of a lapse in coverage; for additional information contact the program at 800-676-4266.",
                "brand": "Corifact",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adb7"
            },
            {
                "_id": "6297bcabe0e0db6c2572adbc",
                "link": "https://www.cortizone10.com/special-offers.html",
                "description": "$1 off coupon on any Cortizone-10 product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Cortizone-10 Anti-Itch Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adbc"
            },
            {
                "_id": "6297bcabe0e0db6c2572aec6",
                "link": "https://www.enfamil.com/baby-formula-coupons-samples/",
                "description": "Join Enfamil Family Beginnings and receive up to $400 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Infant Formula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aec6"
            },
            {
                "_id": "6297bcabe0e0db6c2572adc1",
                "link": "https://www.cortizone10.com/special-offers.html",
                "description": "$1 off coupon on any Cortizone-10 product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Cortizone-10 Overnight Itch Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adc1"
            },
            {
                "_id": "6297bcabe0e0db6c2572aecb",
                "link": "https://www.enfamil.com/enfacare-drive-to-thrive-support-program/",
                "description": "Join the Drive to Thrive Support Program and receive up to $70 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Premature 30",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aecb"
            },
            {
                "_id": "6297bcabe0e0db6c2572aed0",
                "link": "https://www.enfamil.com/enfacare-drive-to-thrive-support-program/",
                "description": "Join the Drive to Thrive Support Program and receive up to $70 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Premature with Iron 24",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aed0"
            },
            {
                "_id": "6297bcabe0e0db6c2572aed5",
                "link": "https://www.enhertu4u.com/patient/affording-your-medicine.html",
                "description": "Enhertu Patient Savings Program: Eligible commercially insured patients may pay as little as $0 per prescription with savings of up to $26,000 per year; offer can be used for the cost of the drug itself and may also cover up to $100 in infusion costs per administration in some states; for additional information contact the program at 833-364-3788.",
                "brand": "Enhertu",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "26000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aed5"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeda",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure Compact Shake Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeda"
            },
            {
                "_id": "6297bcabe0e0db6c2572aedf",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure Light Shake Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aedf"
            },
            {
                "_id": "6297bcabe0e0db6c2572aee4",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure Plus Shake Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aee4"
            },
            {
                "_id": "6297bcabe0e0db6c2572aee9",
                "link": "https://enteragam.com/healthcare-professionals/contact-us/",
                "description": "EnteraGam Samples: Samples may be requested by filling out the online form.",
                "brand": "EnteraGam",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aee9"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeee",
                "link": "https://burketherapeutics.com/entty-spray-emulsion",
                "description": "Entty Spray Savings: Eligible commercially insured/RX not covered and cash-paying patients may save on their prescriptions when using the savings program; for additional information contact the program at 501-620-4449.",
                "brand": "Entty",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeee"
            },
            {
                "_id": "6297bcabe0e0db6c2572aef3",
                "link": "https://azuritysolutions.com/patients/epaned/#product",
                "description": "Epaned eVoucherRX Program: Eligible commercially insured patients may pay no more than $30 per prescription with savings of up to $230 per fill at a participating pharmacy; for additional information contact the program at 844-472-2032.",
                "brand": "Epaned",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 230,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aef3"
            },
            {
                "_id": "6297bcabe0e0db6c2572aef8",
                "link": "https://www.epidiolex.com/support-and-resources",
                "description": "Epidiolex Copay Savings Program: Eligible commercially insured patients will pay $0 for prescriptions and refills until 12/31/21; for additional information contact the program at 833-426-4243.",
                "brand": "Epidiolex",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aef8"
            },
            {
                "_id": "6297bcabe0e0db6c2572adc6",
                "link": "https://www.cosentyx.com/treatment-cost",
                "description": "Cosentyx Connect Co-Pay Program: Eligible commercially insured patients may pay $0 per prescription with an annual savings of up to $16,000; for additional information contact the program at 844-267-3689.",
                "brand": "Cosentyx",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "16000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adc6"
            },
            {
                "_id": "6297bcabe0e0db6c2572aefd",
                "link": "https://epinephrineautoinject.com",
                "description": "Epinephrine Auto-Injector Savings Program (Brand Name Adrenaclick): Eligible cash-paying patients may save up to $10 per prescription; for additional information contact the program at 855-449-4712.",
                "brand": "Epinephrine",
                "copay": "No",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 10,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aefd"
            },
            {
                "_id": "6297bcabe0e0db6c2572adcb",
                "link": "https://www.copayassistancenow.com/eligibility",
                "description": "Cotellic Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Cotellic",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adcb"
            },
            {
                "_id": "6297bcabe0e0db6c2572af02",
                "link": "https://www.activatethecard.com/viatrisadvocate/mygenericEAI/welcome.html?_ga=2.36252515.276121865.1579134457-1618483530.1579134457",
                "description": "Mylan Epinephrine Auto-Injector Savings Card (Brand Name EpiPen Jr. 2-Pack): Eligible commercially insured patients without coverage for the RX may receive $25 off each prescription on each of up to 3 fills (6 auto-injectors) per calendar year; for additional information contact the program at 800-657-7613.",
                "brand": "EpiPen Jr 2-Pak",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "75",
                "lifetime_uses": "3",
                "max_saving_transaction": 25,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af02"
            },
            {
                "_id": "6297bcabe0e0db6c2572af07",
                "link": "https://equetro.com/patients",
                "description": "Equetro Patient Discount Card: Eligible patients may pay as little as $20 per prescription; for offer click Download Discount Card button; for additional information contact the program at 866-297-6945.",
                "brand": "Equetro",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af07"
            },
            {
                "_id": "6297bcabe0e0db6c2572add0",
                "link": "https://cotemplaxrodt.com/#rxconnect-section",
                "description": "Cotempla XR-ODT Savings Card: Eligible commercially insured patients may pay as little as $15 per prescription; for additional information contact the program at 888-298-8792.",
                "brand": "Cotempla XR-ODT",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572add0"
            },
            {
                "_id": "6297bcabe0e0db6c2572add5",
                "link": "https://www.crestor.com/savings-resources/savings-offers.html",
                "description": "Crestor Savings Card: Eligible commercially insured patients/RX not covered may pay as little as $3 with savings up to $130 per 30-day supply, $260 per 60-day supply and $390 per 90-day supply; offer can be used 12 times; for additional information contact the program at 855-687-2151.",
                "brand": "Crestor",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1560",
                "lifetime_uses": "12",
                "max_saving_transaction": 130,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572add5"
            },
            {
                "_id": "6297bcabe0e0db6c2572af0c",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Erivedge Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Erivedge",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af0c"
            },
            {
                "_id": "6297bcabe0e0db6c2572adda",
                "link": "https://www.crestor.com/savings-resources/savings-offers.html#menu1",
                "description": "Crestor Mail Order Rebate for Mail-Order Pharmacy: Eligible commercially insured patients/RX not covered may pay as little as $3 with savings up to $130 per 30-day supply, $260 per 60-day supply and $390 per 90-day supply; for additional information contact the program at 800-236-9933.",
                "brand": "Crestor",
                "copay": "No",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1560",
                "lifetime_uses": "12",
                "max_saving_transaction": 130,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adda"
            },
            {
                "_id": "6297bcabe0e0db6c2572af11",
                "link": "https://esbrietcopay.com/enroll-first-step",
                "description": "Esbriet $5 Co-pay Program: Eligible commercially insured patients may pay no more that $5 per 30-day supply with a yearly limit of $25,000; for additional information contact the program at 877-780-4958.",
                "brand": "Esbriet",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.34,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af11"
            },
            {
                "_id": "6297bcabe0e0db6c2572addf",
                "link": "https://www.cuvitru.com/support-and-resources/onepath",
                "description": "Cuvitru OnePath Co-Pay Assistance Program: Eligible commercially insured patients may be able may save on certain out-of-pocket treatment costs; for additional information contact the program at 866-888-0660.",
                "brand": "Cuvitru",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572addf"
            },
            {
                "_id": "6297bcabe0e0db6c2572af16",
                "link": "https://estrogelsavings.com",
                "description": "EstroGel Instant Savings Coupon: Eligible patients may pay $25 per canister on each of up to 12 prescriptions; maximum savings of $35 per canister; for additional information contact the program at 877-204-1013.",
                "brand": "EstroGel",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "420",
                "lifetime_uses": "12",
                "max_saving_transaction": 35,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af16"
            },
            {
                "_id": "6297bcabe0e0db6c2572ade4",
                "link": "https://www.cystadane.com/patients/getting-cystadane/",
                "description": "Cystadane Copay Assistance Program: Eligible commercially insured patients may get help with copayments and co-insurance; for additional information contact the program at 888-487-4703.",
                "brand": "Cystadane",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ade4"
            },
            {
                "_id": "6297bcabe0e0db6c2572af1b",
                "link": "https://www.estroven.com/sign-up-for-offers/",
                "description": "Sign up to the mailing list and exclusive offers for Estroven products.",
                "brand": "Estroven Menopause Relief plus Mood",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af1b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ade9",
                "link": "https://www.dailies.com/dailies-free-trial",
                "description": "Dailies Contact Lenses: Receive a FREE 5-day trial and other special offers with membership to the Alcon EyeFile; register your email address to join;coupon to be redeemed at your eye care professional's office; for additional information contact customer service at 800-862-5266.",
                "brand": "DAILIES Aquacomfort Plus Multifocal Contact lenses",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ade9"
            },
            {
                "_id": "6297bcabe0e0db6c2572af20",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Advanced Cleansing Body and Face Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af20"
            },
            {
                "_id": "6297bcabe0e0db6c2572adee",
                "link": "https://www.daliresp.com/savings-program.html",
                "description": "Daliresp Savings Program: Eligible commercially insured patients may pay as little as $25 per 30-day supply; for additional information contact the program at 866-459-2015.",
                "brand": "Daliresp",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adee"
            },
            {
                "_id": "6297bcabe0e0db6c2572af25",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Baby Eczema Relief Cream Body Wash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af25"
            },
            {
                "_id": "6297bcabe0e0db6c2572adf3",
                "link": "https://www.janssencarepath.com/patient/darzalex/cost-support",
                "description": "Darzalex Janssen CarePath Savings Program: Most eligible commercially insured patients may pay no more than $5 per dose with a maximum benefit of $20,000 per calendar year; for additional information contact the program at 844-553-2792.",
                "brand": "Darzalex",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adf3"
            },
            {
                "_id": "6297bcabe0e0db6c2572af2a",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Daily Hydration Cream Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af2a"
            },
            {
                "_id": "6297bcabe0e0db6c2572adf8",
                "link": "http://www.daytrana.com/#sod",
                "description": "Daytrana Co-Pay Savings Offer: Eligible cash-paying patients may pay no more than $20 for each prescription of 30 patches with savings of up to $80 on each of up to 12 fills; for additional information contact the program at 866-849-4512.",
                "brand": "Daytrana",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "960",
                "lifetime_uses": "12",
                "max_saving_transaction": 80,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adf8"
            },
            {
                "_id": "6297bcabe0e0db6c2572af2f",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Eczema Relief Cream Body Wash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af2f"
            },
            {
                "_id": "6297bcabe0e0db6c2572adfd",
                "link": "https://www.delstrigo.com/savings-support/",
                "description": "Delstrigo FREE Trial Offer: Eligible patients may receive a FREE 30-tablet supply; for additional information contact the program at 800-672-6372.",
                "brand": "Delstrigo",
                "copay": "No",
                "bin": "610524",
                "rxpcn": "1016",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adfd"
            },
            {
                "_id": "6297bcabe0e0db6c2572af34",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Intensive Repair Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af34"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae02",
                "link": "https://www.delsym.com/",
                "description": "Click on the Special Offers tab at the top of the website to print a $5 coupon off any Delsym product; you will be redirected to coupons.com to print your coupon; 1 coupon per purchase.",
                "brand": "Delsym Cough and Chest Suppressants DM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae02"
            },
            {
                "_id": "6297bcabe0e0db6c2572af39",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Original Healing Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af39"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae07",
                "link": "https://www.depakote.com/savings-program",
                "description": "Depakote Savings Card: Eligible commercially insured patients may pay as little as $5 per month on your prescriptions with savings of up to $100 per fill; card be used 2 times per month; for additional information contact the program at 800-364-4767.",
                "brand": "Depakote",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "2400",
                "lifetime_uses": "24",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae07"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae0c",
                "link": "https://www.depakote.com/savings-program",
                "description": "Depakote Sprinkle Savings Card: Eligible commercially insured patients may pay as little as $5 per month on your prescriptions with savings of up to $100 per fill; card be used 2 times per month; for additional information contact the program at 800-364-4767.",
                "brand": "Depakote Sprinkle",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "2400",
                "lifetime_uses": "24",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae0c"
            },
            {
                "_id": "6297bcabe0e0db6c2572af3e",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Roughness Relief Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af3e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae11",
                "link": "https://www.depend.com/en-us/samples",
                "description": "Receive FREE sample packs of Men's Depend Guards and Shields�products with registration; for additional assistance contact 877-413-3736.",
                "brand": "Depend Guards for Men",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae11"
            },
            {
                "_id": "6297bcabe0e0db6c2572af43",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Skin Calming Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af43"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae16",
                "link": "https://www.depend.com/en-us/coupons?modal=true",
                "description": "Sign up for latest coupons, information on promotions, special discounts and free offers for any Depend products.",
                "brand": "Depend Protection Plus Underwear for Women",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae16"
            },
            {
                "_id": "6297bcabe0e0db6c2572af48",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Skin Calming Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af48"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae1b",
                "link": "https://www.depend.com/en-us/coupons?modal=true",
                "description": "Sign up for latest coupons, information on promotions, special discounts and free offers for any Depend products.",
                "brand": "Depend Shields for Men",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae1b"
            },
            {
                "_id": "6297bcace0e0db6c2572af4d",
                "link": "https://evamist.com",
                "description": "Evamist Savings Program: Eligible patients may pay no more than $25 with savings of up to $70 per prescription; for additional information contact the program at 844-415-0672.",
                "brand": "Evamist",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 70,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af4d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae20",
                "link": "https://www.deplin.com/brand-direct-health",
                "description": "Deplin Brand Direct Health Program: Eligible patients are offered a low price per fill regardless of insurance; 90-day prescriptions shipped and delivered to your home free; for additional information contact the program at 866-331-6440.",
                "brand": "Deplin",
                "copay": "",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae20"
            },
            {
                "_id": "6297bcace0e0db6c2572af52",
                "link": "https://evenitysupport.com/",
                "description": "",
                "brand": "Evenity",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "8000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af52"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae25",
                "link": "http://www.dermend.com/coupons/",
                "description": "DerMend Products Coupon: Save up to $3 on any one product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "DerMend Moisturizing Anti-Itch Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae25"
            },
            {
                "_id": "6297bcace0e0db6c2572af57",
                "link": "https://www.excedrin.com/products/coupons/",
                "description": "Sign-up and save $1 on any one Excedrin product; must click on an article to download coupon; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Excedrin Extra Strength",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af57"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae2a",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Desitin products with registration.",
                "brand": "Desitin Multi-Purpose Ointment",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae2a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae2f",
                "link": "https://www.detrolla.com/Savings",
                "description": "Detrol LA Savings Card: Eligible patients may pay as little as $0 per prescription with savings of up to $150 per fill and up to $1800 annually; for additional information contact the program at 855-541-5932.",
                "brand": "Detrol LA",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae2f"
            },
            {
                "_id": "6297bcace0e0db6c2572af5c",
                "link": "https://www.copay.novartisoncology.com/",
                "description": "Exjade Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Exjade",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af5c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae34",
                "link": "https://www.dexilant.com/hcp/samples-and-support",
                "description": "Dexilant Samples: Healthcare professionals may request samples for their practice by logging onto the website.",
                "brand": "Dexilant",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae34"
            },
            {
                "_id": "6297bcace0e0db6c2572af61",
                "link": "https://eylea.us/support/co-pay-card",
                "description": "Eylea Co-Pay Card Program: Eligible commercially insured patients may pay as little as $0 per treatment with savings of up to $15,000 per year limit; for additional information contact the program at 855-395-3248.",
                "brand": "Eylea",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af61"
            },
            {
                "_id": "6297bcace0e0db6c2572af66",
                "link": "https://www.fabrazyme.com/resources-and-support#co-pay-assistance-program",
                "description": "Fabrazyme CareConnectPSS Copay Assistance Program: Eligible commercially insured patients may receive assistance paying for out-of-pocket drug and infusion-related expenses including co-pays, deductibles and co-insurance; for additional information contact the program at 800-745-4447, option 3.",
                "brand": "Fabrazyme",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af66"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae39",
                "link": "https://diabetictussin.com/",
                "description": "$1 off any Diabetic Tussin product; limit 1 coupon per purchase.",
                "brand": "Diabetic Tussin Night Time Cold and Flu",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae39"
            },
            {
                "_id": "6297bcace0e0db6c2572af6b",
                "link": "https://www.farxiga.com/savings-support/register",
                "description": "Farxiga Mail-In Rebate: Eligible commercially insured patients may receive a rebate check if their mail order pharmacy does not accept the Farxiga Savings Card by completing a rebate form and mailing in their original mail-order receipt; for additional information contact the program at 800-236-9933.",
                "brand": "Farxiga",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "2100",
                "lifetime_uses": "12",
                "max_saving_transaction": 175,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af6b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae3e",
                "link": "https://differin.com/save",
                "description": "Save $1.75 on any one Differin Cleanser or Moisturizer product; coupon expires 1 month after; 1 coupon per purchase.",
                "brand": "Differin Oil Absorbing Moisturizer with SPF 30",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1.75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae3e"
            },
            {
                "_id": "6297bcace0e0db6c2572af70",
                "link": "https://www.farxiga-hcp.com/savings",
                "description": "Farxiga Samples: Healthcare providers may request samples for their practice by logging into the website.",
                "brand": "Farxiga",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af70"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae43",
                "link": "https://www.dilantin.com/savings",
                "description": "Dilantin Savings Card: Eligible commercially insured patients may save up to $20 per 30-day prescription fill with a maximum savings of up to $240 per year; for additional information contact the program at 866-590-9400.",
                "brand": "Dilantin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "240",
                "lifetime_uses": "12",
                "max_saving_transaction": 20,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae43"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae48",
                "link": "https://www.diovan.com/copay-faqs",
                "description": "Diovan HCT Co-Pay Card Program Rebate: Eligible commercially insured patients may submit a rebate request online if their pharmacy does not accept the savings card; for additional information contact the program at 877-699-9975.",
                "brand": "Diovan HCT",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae48"
            },
            {
                "_id": "6297bcace0e0db6c2572af75",
                "link": "https://www.hematologysupportpro.com/financial-assistance#coPayAssistance",
                "description": "Feiba Free Trial Program: Eligible NEW patients may receive up to 15 free trial doses of medication; scroll down to see offer; for additional information contact the program at 888-229-8379.",
                "brand": "Feiba",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af75"
            },
            {
                "_id": "6297bcace0e0db6c2572af7a",
                "link": "https://www.copay.novartisoncology.com/?name=kisqali-and-femara",
                "description": "Femara Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Femara",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af7a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae4d",
                "link": "https://domeboro.com/coupon/",
                "description": "Save $4 on any one Domeboro product; 1 coupon per purchase.",
                "brand": "Domeboro Medicated Soak",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae4d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae52",
                "link": "https://www.galdermacc.com/patients",
                "description": "Doxycycline CareConnect Patient Savings Card: Eligible commercially insured patients may pay $0 per prescription; for additional information contact the program at 855-280-0543.",
                "brand": "Doxycycline",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae52"
            },
            {
                "_id": "6297bcace0e0db6c2572af7f",
                "link": "https://www.feosol.com/en/save-big-with-coupons",
                "description": "Save $2 with coupon on any one Feosol product; 1 coupon per purchase.",
                "brand": "Feosol Original",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af7f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae57",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Callus Cushions with Duragel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae57"
            },
            {
                "_id": "6297bcace0e0db6c2572af84",
                "link": "https://ferralet.com/ferralet-90-savings/",
                "description": "Ferralet 90 Savings Coupon: Eligible patients may pay as little as $25 per prescription with savings of up to $40 per fill; for additional information contact the program at 877-934-1122.",
                "brand": "Ferralet 90",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "480",
                "lifetime_uses": "12",
                "max_saving_transaction": 40,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af84"
            },
            {
                "_id": "6297bcace0e0db6c2572af89",
                "link": "https://www.mynovoinsulin.com/insulin-products/novolog/savings.html",
                "description": "Fiasp Novo Nordisk Instant Savings Card: Eligible commercially insured patients may pay no more than $25 per 30-day prescription with savings of up to $100 per fill; offer valid 24 times after card activation; for additional information contact the program at 877-304-6855.",
                "brand": "Fiasp",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af89"
            },
            {
                "_id": "6297bcace0e0db6c2572af8e",
                "link": "https://www.fiberchoice.com",
                "description": "Register your email address with Fiber Choice to receive special offers, promotions, and more.",
                "brand": "Fiber Choice Fruity Bites",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af8e"
            },
            {
                "_id": "6297bcace0e0db6c2572af93",
                "link": "https://www.firazyr.com/hcp/resources/free-sample",
                "description": "Firazyr Sample Program: Eligible patients may receive 1 syringe sample at no cost; healthcare provider must complete the online order form; for additional information contact the program at 866-888-0660.",
                "brand": "Firazyr",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af93"
            },
            {
                "_id": "6297bcace0e0db6c2572af98",
                "link": "https://www.womenshealthcaresolutions.com/patient-material-request",
                "description": "First Response Product Free Samples: Your healthcare provider may request samples for you for any one listed Women's product; for additional information contact a representative at 800-487-6526.",
                "brand": "First Response Early Result Pregnancy Test",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af98"
            },
            {
                "_id": "6297bcace0e0db6c2572af9d",
                "link": "https://www.womenshealthcaresolutions.com/patient-material-request",
                "description": "First Response Product Free Samples: Your healthcare provider may request samples for you for any one listed Women's product; for additional information contact a representative at 800-487-6526.",
                "brand": "First Response Rapid Result Pregnancy Test",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af9d"
            },
            {
                "_id": "6297bcace0e0db6c2572afa2",
                "link": "https://www.flector.com/patientsavings.html",
                "description": "Flector Copay Savings Card: Eligible patients may pay as little as $4 per prescription with a savings of $116; for additional information contact customer service at 844-247-0961.",
                "brand": "Flector",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 116,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afa2"
            },
            {
                "_id": "6297bcace0e0db6c2572afa7",
                "link": "https://www.flintstonesvitamins.com/promo/flintstones-vitamins-coupon",
                "description": "$1 coupon off any Flintstones Multivitamin Product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Flintstones SuperBeans Multivitamin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afa7"
            },
            {
                "_id": "6297bcace0e0db6c2572afac",
                "link": "https://www.florastor.com/coupons.html",
                "description": "Save $4 on your next Florastor product purchase; 1 coupon per purchase.",
                "brand": "Florastor",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afac"
            },
            {
                "_id": "6297bcace0e0db6c2572afb1",
                "link": "https://almiralladvantage.com/",
                "description": "Fluoroplex Almirall Advantage Savings Card: Eligible commercially insured patients/RX not covered may pay no more than $50 per prescription when using a network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Fluoroplex",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afb1"
            },
            {
                "_id": "6297bcace0e0db6c2572afb6",
                "link": "https://www.organonconnect.com/follistim/dosing-administration/general-dosing-information/",
                "description": "Follistim AQ Cartridge Free Samples: Your healthcare provider may request FREE samples by logging onto healthcare provider portal; for additional information contact the program at 866-719-1691.",
                "brand": "Follistim AQ Cartridge",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afb6"
            },
            {
                "_id": "6297bcace0e0db6c2572afbb",
                "link": "https://www.fotivda.com/",
                "description": "Fotivda Aveo Ace Co-pay Assistance Program: Eligible commercially insured patients may pay as little as $0 per prescription with an annual savings limit of $40,000 per year; for more information contact the program at 833-368-4832.",
                "brand": "Fotivda",
                "copay": "Yes",
                "bin": "16151",
                "rxpcn": "BNRX",
                "copay_max": "40000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afbb"
            },
            {
                "_id": "6297bcace0e0db6c2572afc0",
                "link": "https://www.myfreestyle.com/offers#i_have_insurance",
                "description": "FreeStyle Promise Program: Receive a FREE FreeStyle Lite Meter and automatic savings on test strips; for additional information contact the program at 888-522-5226.",
                "brand": "FreeStyle Lite Glucose Monitoring System",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afc0"
            },
            {
                "_id": "6297bcace0e0db6c2572afc5",
                "link": "https://www.alva-amco.com/contact",
                "description": "Consumers may fill out a request form for FungiCURE product coupons.",
                "brand": "FungiCURE Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afc5"
            },
            {
                "_id": "6297bcace0e0db6c2572afca",
                "link": "https://uspco.com/product_literature.aspx?pid=55&tab=Contact",
                "description": "Fusion Plus Samples: Healthcare providers may request samples for their practice by filling out a form online.",
                "brand": "Fusion Plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afca"
            },
            {
                "_id": "6297bcace0e0db6c2572afcf",
                "link": "https://www.futuro-usa.com/3M/en_US/futuro-us/offers/enewsletter/",
                "description": "Subscribe to the Futuro Brand Email Newsletter and receive special offers, coupons and free samples.",
                "brand": "Futuro Support or Brace Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afcf"
            },
            {
                "_id": "6297bcace0e0db6c2572afd4",
                "link": "https://www.gamunex-c.com/en/hcp/gamunex-financial-support",
                "description": "Gamunex-C Copay Assistance Program: Eligible commercially insured patients may save up to $10,000 per calendar year towards copays, coinsurance or deductibles; for additional information contact the program at 888-694-2686.",
                "brand": "Gamunex-C",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "2500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afd4"
            },
            {
                "_id": "6297bcace0e0db6c2572afd9",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Ganirelix Heart Beat Program: Eligible patients seeking fertility preservation prior to cancer treatment may receive fertility medications at no cost with program; please see website for full qualification requirements; for additional information contact the program at 888-347-3415.",
                "brand": "Ganirelix Acetate",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afd9"
            },
            {
                "_id": "6297bcace0e0db6c2572afde",
                "link": "https://www.copayassistancenow.com/eligibility",
                "description": "Gavreto Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Gavreto",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afde"
            },
            {
                "_id": "6297bcace0e0db6c2572afe3",
                "link": "https://www.copayassistancenow.com/patients",
                "description": "Gazyva Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 copay per prescription and receive savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Gazyva",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afe3"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeaa",
                "link": "https://elzonris.com/access",
                "description": "Elzonris Stemline Commercial Co-Pay Program: Eligible commercially insured patients may may pay as little as $0 per prescription with a maxium savings of $25,000 per calendar year; for additional information contact the program at 833-478-3654.",
                "brand": "Elzonris",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeaa"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeaf",
                "link": "https://empaveli.com/apellisassist/",
                "description": "Empaveli Apellis Co-Pay Program: Eligible commercially insured patients may receive financial assistance to help cover co-pay and co-insurance costs; for additional information contact the program 866-692-7527.",
                "brand": "Empaveli",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeaf"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeb4",
                "link": "https://www.emverm.com/savings-program/",
                "description": "Emverm Savings Card: Eligible patients may pay as little as $5 on out-of-pocket costs on each of up to 12 prescriptions; for additional information contact the program at 877-264-2440.",
                "brand": "Emverm",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeb4"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeb9",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Endometrin Patient Savings Card: Eligible commercially insured patients may save up to $30 every 2 weeks; for additional information contact the program at 833-226-3082.",
                "brand": "Endometrin",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": " OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 30,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeb9"
            },
            {
                "_id": "6297bcabe0e0db6c2572aebe",
                "link": "https://www.enfamil.com/toddler-sample-request/",
                "description": "Enfagrow Samples: Register your email address in order to receive a FREE sample of Enfagrow.",
                "brand": "Enfagrow Premium Toddler Transitions",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aebe"
            },
            {
                "_id": "6297bcabe0e0db6c2572aec3",
                "link": "https://www.enfamil.com/enspire-family-beginnings/?slug=self_enroll",
                "description": "Join Enfamil Enspire Family Beginnings and receive valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Enspire Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aec3"
            },
            {
                "_id": "6297bcabe0e0db6c2572aec8",
                "link": "https://www.enfamil.com/nutramigen-savings-and-support-program/",
                "description": "Join Nutramigen Savings Program and save up to $130  in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Nutramigen with Enflora LGG",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aec8"
            },
            {
                "_id": "6297bcabe0e0db6c2572aecd",
                "link": "https://www.enfamil.com/enfacare-drive-to-thrive-support-program/",
                "description": "Join the Drive to Thrive Support Program and receive up to $70 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Premature Low Iron 20",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aecd"
            },
            {
                "_id": "6297bcabe0e0db6c2572aed2",
                "link": "https://www.enfamil.com/enfacare-drive-to-thrive-support-program/",
                "description": "Join the Drive to Thrive Support Program and receive up to $70 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Premium Newborn",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aed2"
            },
            {
                "_id": "6297bcabe0e0db6c2572aed7",
                "link": "https://www.enstilar.com/patient-savings-program",
                "description": "Enstilar Leo Pharma Connect Co-Pay Savings Card: Eligible commercially insured patients/RX not covered pay $75 per fill; offer valid for a 1 can (60g) prescription only; offer valid for 12 prescription fills per calendar year; maximum savings of $6000 per calendar year; for additional assistance contact the program at 877-678-7494.",
                "brand": "Enstilar",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6000",
                "lifetime_uses": "12",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aed7"
            },
            {
                "_id": "6297bcabe0e0db6c2572aedc",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure Enlive Shake Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aedc"
            },
            {
                "_id": "6297bcabe0e0db6c2572aee1",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure Original Nutrition Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aee1"
            },
            {
                "_id": "6297bcabe0e0db6c2572aee6",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure Rapid Hydration Powder Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aee6"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeeb",
                "link": "https://www.entresto.com/financial-support",
                "description": "Entresto Free Trial Offer: Eligible patients may receive a one month supply for FREE; for additional information contact the program at 888-368-7378.",
                "brand": "Entresto",
                "copay": "No",
                "bin": "601341",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeeb"
            },
            {
                "_id": "6297bcabe0e0db6c2572aef0",
                "link": "https://getrxsolutions.com/enrollment/envarsusxr/enrollment.page",
                "description": "Envarsus XR 30-day Free Trial: Eligible patients may receive a 30-day prescription fill at no cost to you with registration; for additional information contact the program at 844-728-3479.",
                "brand": "Envarsus XR",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aef0"
            },
            {
                "_id": "6297bcabe0e0db6c2572aef5",
                "link": "https://www.epclusa.com/sign-up-eligibility",
                "description": "Epclusa Co-Pay Coupon Program: Eligible patients may pay no more than $5; offer is valid for 6 months after first use; for additional information contact then program at 844-437-2587.",
                "brand": "Epclusa",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "EPCLUSA",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aef5"
            },
            {
                "_id": "6297bcabe0e0db6c2572aefa",
                "link": "https://www.galdermacc.com/patients",
                "description": "Epiduo Forte CareConnect Patient Savings Card: Eligible commercially insured patients may pay $20 per prescription; for additional information contact the program at 855-280-0543.",
                "brand": "Epiduo Forte",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aefa"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeff",
                "link": "https://www.activatethecard.com/viatrisadvocate/mygenericEAI/welcome.html?_ga=2.36252515.276121865.1579134457-1618483530.1579134457#tnc",
                "description": "Mylan Epinephrine Auto-Injector Savings Card (Brand Name EpiPen 2-Pack): Eligible commercially insured patients without coverage for the RX may receive $25 off each prescription on each of up to 3 fills (6 auto-injectors) per calendar year; for additional information contact the program at 800-657-7613.",
                "brand": "EpiPen 2-Pak",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "75",
                "lifetime_uses": "3",
                "max_saving_transaction": 25,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeff"
            },
            {
                "_id": "6297bcabe0e0db6c2572af04",
                "link": "https://www.activatethecard.com/viatrisadvocate/mygenericEAI/welcome.html?_ga=2.36252515.276121865.1579134457-1618483530.1579134457#tnc",
                "description": "Mylan Epinephrine Auto-Injector Savings Card (Brand Name EpiPen Jr 2-Pack): Eligible commercially insured patients may receive $25 off each prescription on each of up to 3 fills (6 auto-injectors) per calendar year; for additional information contact the program at 800-657-7613.",
                "brand": "EpiPen Jr 2-Pak",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "75",
                "lifetime_uses": "3",
                "max_saving_transaction": 25,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af04"
            },
            {
                "_id": "6297bcabe0e0db6c2572af09",
                "link": "https://www.ergomar.com/patient/#savings-program",
                "description": "Ergomar Savings Program: Eligible patients may pay as little as $0 per prescription with maximum savings of $800 per fill; for additional information contact the program at 877-264-2440.",
                "brand": "Ergomar",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "9600",
                "lifetime_uses": "12",
                "max_saving_transaction": 800,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af09"
            },
            {
                "_id": "6297bcabe0e0db6c2572af0e",
                "link": "https://www.janssencarepath.com/patient/erleada/cost-support",
                "description": "Erleada Janssen CarePath Savings Program Rebate: If the pharmacy cannot process the Savings Card the patient may submit a request for a rebate; for additional information contact the program at 833-375-3232.",
                "brand": "Erleada",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af0e"
            },
            {
                "_id": "6297bcabe0e0db6c2572af13",
                "link": "https://www.esperoct.com/resources-for-you/product-support.html",
                "description": "Esperoct Trial Prescription Program: Eligible commercially insured/uninsured patients may be eligible to receive a limited supply of free product; for more information contact the program 844-668-6732.",
                "brand": "Esperoct",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af13"
            },
            {
                "_id": "6297bcabe0e0db6c2572af18",
                "link": "https://estrogel.com/for-healthcare-providers/",
                "description": "EstroGel Samples: Healthcare providers may request samples for their office by calling 855-786-0738.",
                "brand": "EstroGel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af18"
            },
            {
                "_id": "6297bcabe0e0db6c2572af1d",
                "link": "https://www.estroven.com/sign-up-for-offers/",
                "description": "Sign up to the mailing list and exclusive offers for Estroven products.",
                "brand": "Estroven Menopause Relief plus Weight",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af1d"
            },
            {
                "_id": "6297bcabe0e0db6c2572af22",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Advanced Repair Cream Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af22"
            },
            {
                "_id": "6297bcabe0e0db6c2572af27",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Baby Eczema Relief Cream Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af27"
            },
            {
                "_id": "6297bcabe0e0db6c2572af2c",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Daily Hydration Lotion Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af2c"
            },
            {
                "_id": "6297bcabe0e0db6c2572af31",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Eczema Relief Cream Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af31"
            },
            {
                "_id": "6297bcabe0e0db6c2572af36",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Itch Relief Intensive Calming Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af36"
            },
            {
                "_id": "6297bcabe0e0db6c2572af3b",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Roughness Relief Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af3b"
            },
            {
                "_id": "6297bcabe0e0db6c2572af40",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Roughness Relief Spot Treatment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af40"
            },
            {
                "_id": "6297bcabe0e0db6c2572af45",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Skin Calming Itch Relief Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af45"
            },
            {
                "_id": "6297bcabe0e0db6c2572af4a",
                "link": "https://www.eucrisa.com/eucrisa-4-you",
                "description": "Eucrisa Copay Savings Card: Eligible commercially insured patients may pay as little as $10 per fill with savings of up to $970 per tube and saving of up to $3880 per year; for additional information contact the program at 877-548-1739.",
                "brand": "Eucrisa",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "3880",
                "lifetime_uses": "0",
                "max_saving_transaction": 970,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af4a"
            },
            {
                "_id": "6297bcace0e0db6c2572af4f",
                "link": "https://www.evekeo.com",
                "description": "Evekeo Instant Savings Card: Eligible cash-paying patients may may pay no more than $75 per 30-day prescription; for additional information contact the program at 855-558-1630.",
                "brand": "Evekeo",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af4f"
            },
            {
                "_id": "6297bcace0e0db6c2572af54",
                "link": "https://acrotechpatientaccess.com/copay-assistance-services/",
                "description": "Evomela STAR Copay Assistance Program: Eligible commercially insured patients will pay $0 copay for the 1st date of service and a $25 copay for  subsequent dates of service; maximum savings of $10,000 per calendar year; for additional information contact the program at 888-537-8277.",
                "brand": "Evomela",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af54"
            },
            {
                "_id": "6297bcace0e0db6c2572af59",
                "link": "https://www.excedrin.com",
                "description": "Sign-up and save $1 on any one Excedrin product; must click on an article to download coupon; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Excedrin PM Headache",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af59"
            },
            {
                "_id": "6297bcabe0e0db6c2572aded",
                "link": "https://www.dailies.com/dailies-free-trial",
                "description": "Dailies Contact Lenses: Receive a FREE 5-day trial and other special offers with membership to the Alcon EyeFile; register your email address to join; coupon to be redeemed at your eye care professional's office; for additional information contact customer service at 800-862-5266.",
                "brand": "DAILIES TOTAL1 Multifocal Contact Lenses",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aded"
            },
            {
                "_id": "6297bcace0e0db6c2572af5e",
                "link": "https://www.takedaoncologycopay.com",
                "description": "Exkivity Takeda Oncology Co-Pay Assistance Program Rebate: Eligible commercially insured patients may be eligible to receive a rebate if they were unable to take advantage of the savings at their pharmacy while already enrolled in the program; for additional information contact the program at 844-817-6468.\r\n\r\n",
                "brand": "Exkivity",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af5e"
            },
            {
                "_id": "6297bcabe0e0db6c2572adf2",
                "link": "https://www.daraprimdirect.com/#support",
                "description": "Daraprim Direct Copay Program: Eligible commercially insured patients may pay as little as $10 per prescription with a savings of up to $13,500 per fill and $25,000 per calendar year; for additional information contact the program at 877-258-2033.",
                "brand": "Daraprim",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 13500,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adf2"
            },
            {
                "_id": "6297bcabe0e0db6c2572adf7",
                "link": "https://www.pfizermedicalinformation.com/en-us/daypro",
                "description": "Daypro Samples: Your healthcare provider may request samples by contacting PfizerPro online or by calling 800-505-4426.",
                "brand": "Daypro",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adf7"
            },
            {
                "_id": "6297bcabe0e0db6c2572adfc",
                "link": "https://www.tarocares.com/patients-caregivers/",
                "description": "Deferiprone TaroCares Co-Pay Support Program: Eligible commercially insured patients may pay as little as $0 per month with savings of up to $5000 per calendar year; for additional information contact the program at 888-292-0744.",
                "brand": "Deferiprone",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572adfc"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae01",
                "link": "https://www.rbhcprofessional.com/s/resource-center?brand=Delsym",
                "description": "Delsym Samples: Healthcare providers may order samples for their office online.",
                "brand": "Delsym 12 Hour Cough Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae01"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae06",
                "link": "https://www.valeantcoverageplus.com/demser/",
                "description": "Demser Valeant Coverage Plus Program Co-Pay Savings Offer: Eligible commercially insured patients may pay no more than $25 per month in out-of-pocket costs on their monthly prescription; for additional assistance contact the program at 888-607-7267.",
                "brand": "Demser",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae06"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae0b",
                "link": "https://www.depakote.com/mail-order-savings",
                "description": "Depakote Sprinkle Mail-Order Rebate: Eligible commercially insured patients using a mail-order pharmacy may submit a rebate request; for additional information contact the program at 800-364-4767.",
                "brand": "Depakote Sprinkle",
                "copay": "No",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "2400",
                "lifetime_uses": "24",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae0b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae10",
                "link": "https://www.depend.com/en-us/samples",
                "description": "Receive FREE sample packs for Women's Depend Fit Flex products with registration; for additional information contact the program at 877-413-3736.",
                "brand": "Depend Fit-Flex Underwear for Women",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae10"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae15",
                "link": "https://www.depend.com/en-us/coupons?modal=true",
                "description": "Sign up for latest coupons, information on promotions, special discounts and free offers for any Depend products.",
                "brand": "Depend Protection Plus Underwear for Men",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae15"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae1a",
                "link": "https://www.depend.com/en-us/samples",
                "description": "Receive FREE sample packs of Men's Depend Guards and Shields�products with registration; for additional assistance contact 877-413-3736.",
                "brand": "Depend Shields for Men",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae1a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae1f",
                "link": "https://www.depend.com/en-us/samples",
                "description": "Receive FREE sample packs for Depend Underpad products with registration; for additional information contact the program at 877-413-3736.",
                "brand": "Depend Unisex Night Defense Underpads",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae1f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae24",
                "link": "http://www.dermend.com/coupons/",
                "description": "DerMend Products Coupon: Save up to $3 on any one product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "DerMend Fragile Skin Moisturizing Formula Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae24"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae29",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Desitin products with registration.",
                "brand": "Desitin Maximum Strength Original Paste",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae29"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae2e",
                "link": "https://www.pfizerpro.com/request-samples",
                "description": "Detrol LA Samples: Your healthcare provider may request samples by visiting the PfizerPro website or calling 800-505-4426.",
                "brand": "Detrol LA",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae2e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae33",
                "link": "https://www.dexilant.com/savings",
                "description": "Dexilant Instant Savings Card: Eligible uninsured patients pay no more than $55 per 30-day fill; for additional information contact the customer service center at 866-279-5598.",
                "brand": "Dexilant",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "1016",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae33"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae38",
                "link": "https://diabetictussin.com/",
                "description": "$1 off any Diabetic Tussin product; limit 1 coupon per purchase.",
                "brand": "Diabetic Tussin Maximum Strength Cough and Chest Congestion DM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae38"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae3d",
                "link": "https://differin.com/save",
                "description": "Save $1.75 on any one Differin Cleanser or Moisturizer product; coupon expires 1 month after; 1 coupon per purchase.",
                "brand": "Differin Gentle Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1.75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae3d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae42",
                "link": "https://www.pfizermedicalinformation.com/en-us/diflucan",
                "description": "Diflucan Samples: Your healthcare provider may request samples by contacting PfizerPro online or calling 800-505-4426.",
                "brand": "Diflucan",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae42"
            },
            {
                "_id": "6297bcace0e0db6c2572af63",
                "link": "https://www.eysuvis.com/patient-access/",
                "description": "Eysuvis I-Save Patient Access Program: Eligible commercially insured patients may pay as little as $40 per prescription; or additional information contact the program at 877-805-1873.",
                "brand": "Eysuvis",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af63"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae47",
                "link": "https://www.diovan.com/index.jsp?usertrack.filter_applied=true&NovaId=6002860716330473971",
                "description": "Diovan Co-Pay Card Program: Eligible commercially insured patients may pay as little as $10 per month with savings of up to $75 per 30 tablets; for additional information contact the program at 877-699-9975.",
                "brand": "Diovan HCT",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "LOYALTY",
                "copay_max": "900",
                "lifetime_uses": "12",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae47"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae4c",
                "link": "https://domeboro.com/coupon/",
                "description": "Save $4 on any one Domeboro product; 1 coupon per purchase.",
                "brand": "Domeboro Cooling Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae4c"
            },
            {
                "_id": "6297bcace0e0db6c2572af68",
                "link": "https://www.fanaptpro.com",
                "description": "Fanapt Savings Card: Eligible commercially patients may pay as little as $0 copay per month within a calendar year; for additional information contact the program at 866-318-1969.",
                "brand": "Fanapt",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "2400",
                "lifetime_uses": "12",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af68"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae51",
                "link": "https://myviivcard.com",
                "description": "Dovato ViiVConnect Savings Card: Eligible patients pay $0 per prescription with savings of up to $6250 per year with no monthly limit; for additional information contact the program at 844-588-3288.",
                "brand": "Dovato",
                "copay": "Yes",
                "bin": "610626",
                "rxpcn": "1016",
                "copay_max": "6250",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae51"
            },
            {
                "_id": "6297bcace0e0db6c2572af6d",
                "link": "https://www.farxiga.com/savings-support/hero",
                "description": "Farxiga Savings Card: Eligible cash-paying patients may save a maximum of up to $150 per monthly fill; for additional information contact the program at 844-631-3978.",
                "brand": "Farxiga",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af6d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae56",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Bunion Cushions with Duragel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae56"
            },
            {
                "_id": "6297bcace0e0db6c2572af72",
                "link": "https://farydak.com",
                "description": "Farydak Secura Care Quickstart Program: If a patient is experiencing an insurance delay of 5 days or more they may be eligible for the first cycle of  medication for FREE; for additional information contact the program at 844-973-2872.",
                "brand": "Farydak",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af72"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae5b",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Clear Away Wart Remover with Duragel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae5b"
            },
            {
                "_id": "6297bcace0e0db6c2572af77",
                "link": "https://www.pfizermedicalinformation.com/en-us/feldene",
                "description": "Feldene Samples: Your healthcare provider may request samples by visiting the PfizerPro website or calling 800-505-4426.",
                "brand": "Feldene",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af77"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae60",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Dual Action Freeze Away Wart Remover",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae60"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae65",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Liquid Corn-Callus Remover",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae65"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae6a",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Odor-X Probiotic Extract Formula Foot Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae6a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae6f",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Severe Cracked Heel Balm",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae6f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae74",
                "link": "https://www.duexis.com/save-on-duexis",
                "description": "Duexis HorizonCares Program: Eligible commercially insured patients may pay as little as $0 per prescription and receive FREE home delivery of their medication when using the program; for additional information contact the program at 866-323-1490.",
                "brand": "Duexis",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae74"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae79",
                "link": "https://www.dulcolax.com/coupons",
                "description": "Save $2 on any one Dulcolax product (excluding 10 count or smaller); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Dulcolax Pink Stool Softener",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae79"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae7e",
                "link": "https://mprsetrial.mckesson.com/7873/?_ga=2.99158937.6680671.1607627935-455867878.1607627935#",
                "description": "Dulera Savings Coupon Rebate: If a commercially insured patient is unable to use the Savings Coupon at their retail or mail-order pharmacy they can call McKesson at 877-264-2454 to request a Direct Member Reimbursement form; patient must call within 30 days of purchase and keep their receipt.",
                "brand": "Dulera",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1080",
                "lifetime_uses": "12",
                "max_saving_transaction": 90,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae7e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae83",
                "link": "https://www.dupixent.com/support-savings/copay-card",
                "description": "Dupixent MyWay Copay Card: Eligible commercially insured patients may pay $0 copay for their prescriptions with a maximum savings of $13,000 per year; for additional information contact the program at 844-387-4936.",
                "brand": "Dupixent",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "13000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae83"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae88",
                "link": "https://coupon.trisadhd.com",
                "description": "Dyanavel XR Savings Card: Eligible commercially insured patients may pay as little as $20 on each of up to 12 prescription fills; for additional information contact the program at 888-840-7006.",
                "brand": "Dyanavel XR",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae88"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae8d",
                "link": "https://www.edarbi.com/edarbi-edarbyclor-patient-savings-support#savingsCard",
                "description": "Edarbi E-Z Rx Program: Eligible cash-paying patients may pay $55 per prescription at participating independent pharmacies; for additional information contact the program at 877-264-2440.",
                "brand": "Edarbi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae8d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae92",
                "link": "https://arborpatientdirect.com",
                "description": "Edarbyclor Arbor Patient Direct: Eligible patients may pay as little as $40 plus shipping for a 30-day supply or $120 for a 90-day supply with FREE shipping by enrolling in the program; for additional information contact the program at 844-289-3981.",
                "brand": "Edarbyclor",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae92"
            },
            {
                "_id": "6297bcace0e0db6c2572af7c",
                "link": "https://fensolvi.com/?faq=22#total-solutions-terms",
                "description": "Fensolvi Co-pay Assistance Program: Eligible commercially insured patients may pay as little as $5 per prescription; maximum savings of $10,000 per calendar year; for additional information contact the program at 866-336-7658.",
                "brand": "Fensolvi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af7c"
            },
            {
                "_id": "6297bcace0e0db6c2572af81",
                "link": "https://feriva21-7.com/savings/",
                "description": "FeRiva 21/7 eVoucherRx Program: Eligible patients may pay no more than $30 per prescription at participating pharmacies; for additional information contact the program at 888-612-8466.",
                "brand": "FeRiva 21-7",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 60,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af81"
            },
            {
                "_id": "6297bcace0e0db6c2572af86",
                "link": "https://www.fetzima.com/depression-resources/savings-card",
                "description": "Fetzima Savings Program: Eligible commercially insured patients may pay as little as $10 per 30-day supply on each of up to 12 prescriptions; for additional information contact the program at 855-439-2801.",
                "brand": "Fetzima",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1740",
                "lifetime_uses": "12",
                "max_saving_transaction": 145,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af86"
            },
            {
                "_id": "6297bcace0e0db6c2572af8b",
                "link": "https://www.novomedlink.com/diabetes/samples.html",
                "description": "Fiasp Samples: Healthcare providers may submit a sample request every 30 days.",
                "brand": "Fiasp FlexTouch",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af8b"
            },
            {
                "_id": "6297bcace0e0db6c2572af90",
                "link": "https://www.finaceafoam.com/patient-savings-program",
                "description": "Finacea Leo Pharma Connect Co-pay Savings Card: Eligible commercially insured patients may pay as little as $20; offer valid for 12 prescription fills per calendar year; maximum savings of $1200 per calendar year; for additional assistance contact the program at 877-678-7494.",
                "brand": "Finacea",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af90"
            },
            {
                "_id": "6297bcace0e0db6c2572af95",
                "link": "https://www.yourcatalystpathways.com/personalized-support/financial-assistance.html",
                "description": "Firdapse Catalyst Pathways My Firdapse Therapeutic Dose Program: Eligible commercially insured patients who are NEW to this treatment may receive their first dose in 7 business days once the program receives the signed enrollment form from the patient's doctor;  for additional information contact the program at 833-422-8259.",
                "brand": "Firdapse",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af95"
            },
            {
                "_id": "6297bcace0e0db6c2572af9a",
                "link": "https://www.womenshealthcaresolutions.com/patient-material-request",
                "description": "First Response Product Free Samples: Your healthcare provider may request samples for you for any one listed Women's product; for additional information contact a representative at 800-487-6526.",
                "brand": "First Response Ovulation 7 Count plus 1 Pregnancy Test",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af9a"
            },
            {
                "_id": "6297bcace0e0db6c2572af9f",
                "link": "https://myflarex.com/copay-program/",
                "description": "Flarex Copay Savings Program: Eligible Medicare patients, cash-paying patients and commercially insured patients/RX not covered pay $59 per fill; for additional information contact the program at 866-747-0976.",
                "brand": "Flarex",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af9f"
            },
            {
                "_id": "6297bcace0e0db6c2572afa4",
                "link": "https://www.flintstonesvitamins.com/promo/flintstones-vitamins-coupon",
                "description": "$1 coupon off any Flintstones Multivitamin Product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Flintstones Chewable Multivitamin Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afa4"
            },
            {
                "_id": "6297bcace0e0db6c2572afa9",
                "link": "https://www.flonase.com/coupons/",
                "description": "Flonase Program: Join the and receive a $2-5 coupon for any 1 product  and earn points for future savings; 1 coupon per purchase.",
                "brand": "Flonase Allergy Relief Nasal Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afa9"
            },
            {
                "_id": "6297bcace0e0db6c2572afae",
                "link": "https://www.florastor.com/coupons.html",
                "description": "Save $4 on your next Florastor product purchase; 1 coupon per purchase.",
                "brand": "FlorastorSelect Gut Boost",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afae"
            },
            {
                "_id": "6297bcace0e0db6c2572afb3",
                "link": "http://www.fluoxetine60.com/",
                "description": "Fluoxetine 60mg Savings Program: Most commercially insured patients pay no more than $28 per month on each prescription; for additional information contact the program at 844-728-3479.",
                "brand": "Fluoxetine HCI",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 155,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afb3"
            },
            {
                "_id": "6297bcace0e0db6c2572afb8",
                "link": "https://www.forteo.com/savings#forteo-copay-card",
                "description": "Forteo Co-pay Card: Eligible patients may pay as little as $4 per 28-day supply; maximum annual savings of $9000; for additional information contact the program at 877-267-0513.",
                "brand": "Forteo",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "9000",
                "lifetime_uses": "12",
                "max_saving_transaction": 750,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afb8"
            },
            {
                "_id": "6297bcace0e0db6c2572afbd",
                "link": "https://www.myfreestyle.com/offers#i_have_insurance",
                "description": "FreeStyle Promise Program: Receive a FREE FreeStyle InsuLinx Meter and automatic savings on test strips; for additional information contact the program at 888-522-5226.",
                "brand": "FreeStyle InsuLinx Glucose Monitoring System",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afbd"
            },
            {
                "_id": "6297bcace0e0db6c2572afc2",
                "link": "https://www.myfreestyle.com/offers#i_have_insurance",
                "description": "FreeStyle Promise Program: Receive a FREE FreeStyle Precision Neo Meter and automatic savings on test strips; for additional information contact the program at 888-522-5226.",
                "brand": "FreeStyle Precision Neo Blood Glucose Monitoring System",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afc2"
            },
            {
                "_id": "6297bcace0e0db6c2572afc7",
                "link": "https://uspco.com/product_literature.aspx?pid=54&tab=Voucher",
                "description": "Fusion Pharmacy Voucher: Eligible patients may save up to 80% at participating pharmacies; for additional information contact the program at  888-602-2978.",
                "brand": "Fusion",
                "copay": "No",
                "bin": "600428",
                "rxpcn": "5100000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afc7"
            },
            {
                "_id": "6297bcace0e0db6c2572afcc",
                "link": "https://uspco.com/product_literature.aspx?pid=435&tab=Contact",
                "description": "Fusion Sprinkles Samples: Healthcare providers may request samples for their practice by filling out a form online.",
                "brand": "Fusion Sprinkles",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afcc"
            },
            {
                "_id": "6297bcace0e0db6c2572afd1",
                "link": "https://www.eisaireimbursement.com/patient/fycompa/financial-assistance",
                "description": "Fycompa Instant Savings Card: Eligible commercially insured patients may pay as little as $10 per monthly prescription with a maximum savings of up to $1300 per year; for additional information contact the program at 855-347-2448.",
                "brand": "Fycompa",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "1300",
                "lifetime_uses": "12",
                "max_saving_transaction": 108.33,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afd1"
            },
            {
                "_id": "6297bcace0e0db6c2572afd6",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Ganirelix OneHeart Program: Eligible commercially insured & cash-paying patients may be eligible to receive a $200 rebate for out-of-pocket costs incurred when using Ganirelix for their COS cycle between June 1, 2020 � December 31, 2021; for additional information contact the program at 888-337-7464.",
                "brand": "Ganirelix Acetate",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afd6"
            },
            {
                "_id": "6297bcace0e0db6c2572afdb",
                "link": "https://www.gas-x.com/coupon/",
                "description": "Register your email address to receive a coupon for savings on any one Gas-X product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Gas-X Softgels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afdb"
            },
            {
                "_id": "6297bcace0e0db6c2572afe0",
                "link": "https://yourblueprint.com/financial-assistance/",
                "description": "Gavreto Coverage Interruption Program: Eligible commercially insured patients may be able to receive a temporary supply if your insurance has changed or have another temporary interruption in insurance; for additional information contact the program at 888-258-7768.",
                "brand": "Gavreto",
                "copay": "",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afe0"
            },
            {
                "_id": "6297bcace0e0db6c2572afe5",
                "link": "https://www.oakneepainrelief.com/reimbursement-services/",
                "description": "Gelsyn-3 BV360 Reimbursement Solution: Your healthcare provider may enroll you in the program by calling 833-692-8360 or by logging into the program portal website.",
                "brand": "Gelsyn-3",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afe5"
            },
            {
                "_id": "6297bcace0e0db6c2572afea",
                "link": "https://www.eyefile.com/theeyesolution/register",
                "description": "Save $2 on your next purchase of any one GenTeal Eye Drops Product (15 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "GenTeal",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afea"
            },
            {
                "_id": "6297bcace0e0db6c2572afef",
                "link": "https://www.geritol.com/coupons",
                "description": "Save $1 on any one Geritol product; limit 1 coupon per purchase.",
                "brand": "Geritol Tonic",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afef"
            },
            {
                "_id": "6297bcace0e0db6c2572aff4",
                "link": "https://www.gimotirx.com/copay-assistance",
                "description": "Gimoti Copay Assistance Program: Eligible commercially insured patients may pay as little as $0 per prescription; for more information contact the program at 833-444-6684.",
                "brand": "Gimoti",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572aff4"
            },
            {
                "_id": "6297bcace0e0db6c2572aff9",
                "link": "https://copay.novartisoncology.com/",
                "description": "Gleevec Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Gleevec",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572aff9"
            },
            {
                "_id": "6297bcace0e0db6c2572affe",
                "link": "https://glucernastore.com",
                "description": "Glucerna Hunger Smart Subscription: Sign up for the program and receive a FREE week of Glucerna Hunger Smart Shakes (only pay the shipping); after 7 days the subscription begins (30 shakes for $45; 60 shakes for $85) with FREE shipping; for additional information contact the program at 877-457-0524.",
                "brand": "Glucerna Hunger Smart Shakes",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572affe"
            },
            {
                "_id": "6297bcace0e0db6c2572b003",
                "link": "https://www.pfizerpro.com/request-samples",
                "description": "Glynase Prestab�Samples: Your healthcare provider may order samples by contacting PfizerPro via the website or by calling 800-505-4426.",
                "brand": "Glynase",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b003"
            },
            {
                "_id": "6297bcace0e0db6c2572b008",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Advanced Healing Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b008"
            },
            {
                "_id": "6297bcace0e0db6c2572b00d",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Friction Defense",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b00d"
            },
            {
                "_id": "6297bcace0e0db6c2572b012",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Medicated Maximum Strength Foot Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b012"
            },
            {
                "_id": "6297bcace0e0db6c2572b017",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product; coupon expires 1 month after printing.",
                "brand": "Gold Bond Pain and Itch Relief Cream with Lidocaine",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b017"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae97",
                "link": "https://www.janssencarepath.com/patient/edurant/cost-support",
                "description": "Edurant Janssen CarePath Savings Program: Most eligible commercially insured patients may pay $0 per fill on out-of-pocket costs with savings of up to $7500 per calendar year; for additional assistance contact the program at 866-836-0114.",
                "brand": "Edurant",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "7500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae97"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae9c",
                "link": "https://www.elaprase.com/getting-started-support/onepath",
                "description": "Elaprase OnePath Co-Pay Assistance Program: Eligible commercially insured patients may be able may save on certain out-of-pocket treatment costs; for additional information contact the program at 866-888-0660.",
                "brand": "Elaprase",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae9c"
            },
            {
                "_id": "6297bcabe0e0db6c2572aea1",
                "link": "https://www.eliquis.com/eliquis/eliquis/hcp/resources#termsandconditions",
                "description": "Eliquis FREE 30-day Trial: Receive a 30-day supply Free (up to 74 tables); limited to one use per patient per lifetime; request one from your healthcare provider; for additional information contact the program at 855-354-7847.",
                "brand": "Eliquis",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aea1"
            },
            {
                "_id": "6297bcabe0e0db6c2572aea6",
                "link": "https://www.orthoelmiron.com/coupon",
                "description": "Elmiron EarlyAssist Savings Card: Eligible commercially insured patients  pay $5 per fill for a maximum savings of $150; maximum annual savings of $1800 per calendar year; for additional information contact the program at 800-526-7736.",
                "brand": "Elmiron",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "0",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aea6"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeab",
                "link": "https://live-emflaza-patient-2020.pantheonsite.io/access-financial-support/ptc-cares-assistance-programs/",
                "description": "Emflaza Bridge Program: Eligible patients may continue to receive their medication free of charge while waiting for insurance coverage verification; for additional information contact the program at 844-478-2227.",
                "brand": "Emflaza",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeab"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeb0",
                "link": "https://www.bmsaccesssupport.bmscustomerconnect.com/patient/financial-support",
                "description": "Empliciti BMS Oncology Co-Pay Assistance Program: Eligible commercially insured patients may pay no more than $25 per infusion with savings of up to $25,000 per year; for additional information contact the program at 800-861-0048.",
                "brand": "Empliciti",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeb0"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeb5",
                "link": "https://www.enbrel.com/financial-support",
                "description": "Enbrel Co-pay Card: Eligible commercially insured patients may pay as little $5 per month up to a maximum program benefit; for additional information contact the program at 888-436-2735.",
                "brand": "Enbrel",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeb5"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeba",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Endometrin Heart Beat Program: Eligible patients seeking fertility preservation prior to cancer treatment may receive fertility medications at no cost with program; please see website for full qualification requirements; for additional information contact the program at 888-347-3415.",
                "brand": "Endometrin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeba"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae5c",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Corn Cushions",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae5c"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae61",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Extra Thick Callus Removers",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae61"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae66",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Molefoam Padding Strips",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae66"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae6b",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Odor-X Ultra Odor-Fighting Foot Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae6b"
            },
            {
                "_id": "6297bcabe0e0db6c2572aebf",
                "link": "https://www.enfamil.com/enfacare-drive-to-thrive-support-program/",
                "description": "Join the Drive to Thrive Program and receive up to $70 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil 24",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aebf"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae70",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Ultra Exfoliating Foot Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae70"
            },
            {
                "_id": "6297bcabe0e0db6c2572aec4",
                "link": "https://www.enfamil.com/baby-formula-coupons-samples/",
                "description": "Join Enfamil Family Beginnings and receive up to $400 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Gentlease",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aec4"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae75",
                "link": "https://www.duexis.com/save-on-duexis",
                "description": "Duexis Co-Pay Card: Eligible patients may pay as little as $0 per prescription with savings of up to $1200 per 30-day supply (minimum of 90-tablets); for additional information contact the program at 855-250-6335.",
                "brand": "Duexis",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "14400",
                "lifetime_uses": "12",
                "max_saving_transaction": 1200,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae75"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae7a",
                "link": "https://www.dulcolax.com/coupons",
                "description": "Save $2 on any one Dulcolax product (excluding 10 count or smaller); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Dulcolax Soft Chews",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae7a"
            },
            {
                "_id": "6297bcabe0e0db6c2572aec9",
                "link": "https://www.enfamil.com/baby-formula-coupons-samples/",
                "description": "Join Enfamil Family Beginnings and receive up to $400 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Pregestimil DHA and ARA",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aec9"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae7f",
                "link": "https://www.dulera.com/",
                "description": "Dulera Savings Coupon: Eligible commercially insured patients may pay as little as $15 on each of up to 12 prescriptions for savings of up to $90 per month; for additional information contact the program at 877-264-2454.",
                "brand": "Dulera",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1080",
                "lifetime_uses": "12",
                "max_saving_transaction": 90,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae7f"
            },
            {
                "_id": "6297bcabe0e0db6c2572aece",
                "link": "https://www.enfamil.com/enfacare-drive-to-thrive-support-program/",
                "description": "Join the Drive to Thrive Support Program and receive up to $70 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Premature Low Iron 24",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aece"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae84",
                "link": "https://www.dupixent.com/support-savings/copay-card",
                "description": "Dupixent MyWay Copay Card Rebate: Eligible commercially insured patients may be eligible for a rebate if their copay card was not accepted or their prescription was filled before they were enrolled in the program; for additional information contact the program at 855-314-8944.",
                "brand": "Dupixent",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "13000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae84"
            },
            {
                "_id": "6297bcabe0e0db6c2572aed3",
                "link": "https://www.enfamil.com/baby-formula-coupons-samples/",
                "description": "Join Enfamil Family Beginnings and receive up to $400 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil ProSobee",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aed3"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae89",
                "link": "https://www.aspirerewards.com/register",
                "description": "Dysport ASPIRE Galderma Rewards: Join today and receive a $60 treatment certificate instantly; other valuable discounts and rebates with sign up; for additional information contact customer service at 844-527-7473.",
                "brand": "Dysport",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae89"
            },
            {
                "_id": "6297bcabe0e0db6c2572aed8",
                "link": "https://www.enstilar.com/patient-savings-program",
                "description": "Enstilar Leo Pharma Connect Co-Pay Savings Card: Eligible commercially insured patients may pay as little as $20 per prescription fill; offer valid for 12 fills per calendar year; maximum savings of $6000 per calendar year; for additional assistance contact the program at 877-678-7494.",
                "brand": "Enstilar",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "6000",
                "lifetime_uses": "12",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aed8"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae8e",
                "link": "https://www.edarbi.com/edarbi-edarbyclor-patient-savings-support#savingsCard",
                "description": "Edarbi Instant Savings Card: Eligible cash-paying patients may pay no more than $60 per prescription; for additional information contact the program at 877-264-2440.",
                "brand": "Edarbi",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae8e"
            },
            {
                "_id": "6297bcabe0e0db6c2572aedd",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure High Protein Bars",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aedd"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae93",
                "link": "https://www.edarbi.com/edarbi-edarbyclor-patient-savings-support#savingsCard",
                "description": "Edarbyclor Instant Savings Card: Eligible cash-paying patients may pay no more than $60 per prescription; for additional information contact the program at 877-264-2440.",
                "brand": "Edarbyclor",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae93"
            },
            {
                "_id": "6297bcabe0e0db6c2572aee2",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure Original Shake Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aee2"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae98",
                "link": "https://www.janssencarepath.com/patient/edurant/cost-support",
                "description": "Edurant Janssen CarePath Savings Program Rebate: Eligible commercially insured patients may submit a rebate request if a pharmacy is unable to process the Savings Card; for additional assistance contact the program at 866-836-0114.",
                "brand": "Edurant",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "7500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae98"
            },
            {
                "_id": "6297bcabe0e0db6c2572aee7",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure Surgery Immunonutrition Shake",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aee7"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae9d",
                "link": "https://www.elelyso.com/personal-support",
                "description": "Elelyso GPS Co-Pay Program: Eligible commercially insured patients may pay $0 out-of-pocket costs per prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 855-353-5976.",
                "brand": "Elelyso",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae9d"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeec",
                "link": "https://www.entresto.com/financial-support",
                "description": "Entresto Co-Pay Card Rebate: Eligible commercially insured patients may submit a request for a rebate if their pharmacy does not accept the Savings card or if they use a mail-order pharmacy; for additional information contact the program at 888-368-7378.",
                "brand": "Entresto",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3250",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeec"
            },
            {
                "_id": "6297bcabe0e0db6c2572aea2",
                "link": " www.patientrebateonline.com",
                "description": "Eliquis Co-pay Card Rebate: If your pharmacy does not accept the Co-pay Card or you use a mail-order pharmacy a rebate can be requested for the savings amount; call 866-279-4730 for a rebate form or visit  www.patientrebateonline.com.",
                "brand": "Eliquis",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3800",
                "lifetime_uses": "24",
                "max_saving_transaction": 316.67,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aea2"
            },
            {
                "_id": "6297bcabe0e0db6c2572aef1",
                "link": "https://www.envarsusxr.com/savings-support/envarsus-xr-co-pay-card/",
                "description": "Envarsus XR $0 Co-pay Card: Eligible commercially insured patients may pay as little as $0 per monthly prescription; maximum savings of $8550 per year; for additional information contact the program at 855-591-9134.",
                "brand": "Envarsus XR",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "8550",
                "lifetime_uses": "12",
                "max_saving_transaction": 712.5,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aef1"
            },
            {
                "_id": "6297bcabe0e0db6c2572aea7",
                "link": "https://www.eloctate.com/resources/financial-assistance",
                "description": "Eloctate Copay Program: Eligible commercially insured patients may save up to $20,000 per calendar year on co-payments or co-insurance; for additional information contact the program at 855-693-5628.",
                "brand": "Eloctate",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aea7"
            },
            {
                "_id": "6297bcabe0e0db6c2572aef6",
                "link": "https://www.epiceramrx.com/samples.html",
                "description": "EpiCeram Samples: Your healthcare provider may request samples by visiting the website.",
                "brand": "EpiCeram Skin Barrier Emulsion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aef6"
            },
            {
                "_id": "6297bcabe0e0db6c2572aefb",
                "link": "https://epinephrineautoinject.com/",
                "description": "Epinephrine Auto-Injector Savings Program (Brand Name Adrenaclick): Eligible commercially insured patients may pay $0 copay with savings of up to $10 per prescription; for additional information contact the program at 855-449-4712.",
                "brand": "Epinephrine",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 10,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aefb"
            },
            {
                "_id": "6297bcabe0e0db6c2572af00",
                "link": "https://www.activatethecard.com/viatrisadvocate/epipen/welcome.html?_ga=2.41511777.276121865.1579134457-1618483530.1579134457#tnc",
                "description": "EpiPen Savings Card: Eligible commercially insured patients without coverage for the RX may save up to $300 per prescription; maximum of 3 cartons per prescription; savings card can be used for a total of 6 cartons per calendar year; for additional information contact the program at 800-657-7613.",
                "brand": "EpiPen 2-Pak",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af00"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeac",
                "link": "https://live-emflaza-patient-2020.pantheonsite.io/access-financial-support/ptc-cares-assistance-programs/",
                "description": "Emflaza Copay Assistance Program: Eligible commercially insured patients may qualify for copay assistance with out-of-pocket costs; for additional information contact the program at 844-478-2227.",
                "brand": "Emflaza",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeac"
            },
            {
                "_id": "6297bcabe0e0db6c2572af05",
                "link": "https://www.activatethecard.com/viatrisadvocate/epipen/welcome.html?_ga=2.41511777.276121865.1579134457-1618483530.1579134457#tnc",
                "description": "EpiPen Savings Card: Eligible commercially insured patients may save up to $300 per prescription; maximum of 3 cartons per prescription; savings card can be used for a total of 6 cartons per calendar year; for additional information contact the program at 800-657-7613.",
                "brand": "EpiPen Jr 2-Pak",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af05"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeb1",
                "link": "https://www.activatethecard.com/viatrisadvocate/emsam/welcome.html",
                "description": "EMSAM Savings Card: Eligible commercially insured patients may pay as little as $20 with savings of up to $600 per monthly prescription fill; savings of up to $7200 per calendar year; for additional information contact the program at 800-657-7613.",
                "brand": "EMSAM",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "7200",
                "lifetime_uses": "12",
                "max_saving_transaction": 600,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeb1"
            },
            {
                "_id": "6297bcabe0e0db6c2572af0a",
                "link": "https://www.ergomar.com/hcp/",
                "description": "Ergomar Samples: Healthcare providers may request samples for their office by downloading an order form and faxing it to 844-300-9408 or emailing TerSera@knipper.com.",
                "brand": "Ergomar",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af0a"
            },
            {
                "_id": "6297bcabe0e0db6c2572af0f",
                "link": "https://www.hcpsample.com/app/index.html?cc=hcpsample&roomid=21&ac=4038",
                "description": "Erleada Samples: Your healthcare provider may order FREE samples on the website.",
                "brand": "Erleada",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af0f"
            },
            {
                "_id": "6297bcabe0e0db6c2572af14",
                "link": "https://www.esperoct.com/resources-for-you/product-support.html",
                "description": "Esperoct Co-pay Assistance Program: Eligible commercially insured patients are eligible for up to $12,000 in co-pay/coinsurance assistance per calendar year; for more information contact the program 844-668-6732.",
                "brand": "Esperoct",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af14"
            },
            {
                "_id": "6297bcabe0e0db6c2572af19",
                "link": "https://www.estroven.com/sign-up-for-offers/",
                "description": "Sign up to the mailing list and exclusive offers for Estroven products.",
                "brand": "Estroven Complete Multi-Symptom Menopause Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af19"
            },
            {
                "_id": "6297bcabe0e0db6c2572af1e",
                "link": "https://www.estroven.com/sign-up-for-offers/",
                "description": "Sign up to the mailing list and exclusive offers for Estroven products.",
                "brand": "Estroven Perimenopause Relief plus Weight",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af1e"
            },
            {
                "_id": "6297bcabe0e0db6c2572af23",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Advanced Repair Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af23"
            },
            {
                "_id": "6297bcabe0e0db6c2572af28",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Baby Eczema Relief Flare-Up Treatment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af28"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeb6",
                "link": "https://www.endarirx.com/copay-assistance",
                "description": "Endari Commercial Copayment Assistance Program: Eligible commercially insured patients pay a $10 monthly copay; for additional information contact the program at 877-420-6493.",
                "brand": "Endari",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeb6"
            },
            {
                "_id": "6297bcabe0e0db6c2572af2d",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Daily Hydration Lotion Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af2d"
            },
            {
                "_id": "6297bcabe0e0db6c2572af32",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Eczema Relief Cream Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af32"
            },
            {
                "_id": "6297bcabe0e0db6c2572af37",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Original Healing Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af37"
            },
            {
                "_id": "6297bcabe0e0db6c2572aebb",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Endometrin Heart for Heroes Program: Eligible veterans may receive fertility medications at no cost with program; please see website for qualification requirements; for additional information contact the program at 800-515-3784.",
                "brand": "Endometrin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aebb"
            },
            {
                "_id": "6297bcabe0e0db6c2572af3c",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Roughness Relief Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af3c"
            },
            {
                "_id": "6297bcabe0e0db6c2572aec0",
                "link": "https://www.enfamil.com/baby-formula-coupons-samples/",
                "description": "Join Enfamil Family Beginnings and receive up to $400 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil A.R.",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aec0"
            },
            {
                "_id": "6297bcabe0e0db6c2572af41",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Skin Calming Body Wash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af41"
            },
            {
                "_id": "6297bcabe0e0db6c2572aec5",
                "link": "https://www.enfamil.com/baby-formula-coupons-samples/",
                "description": "Join Enfamil Family Beginnings and receive up to $400 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Infant Formula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aec5"
            },
            {
                "_id": "6297bcabe0e0db6c2572af46",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Skin Calming Itch Relief Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af46"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeca",
                "link": "https://www.enfamil.com/enfacare-drive-to-thrive-support-program/",
                "description": "Join the Enfamil Drive to Thrive Program and receive up to $70 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Premature",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeca"
            },
            {
                "_id": "6297bcace0e0db6c2572af4b",
                "link": "https://www.eucrisa.com/eucrisa-4-you",
                "description": "Eucrisa Copay Savings Card: Eligible patients with insurance/RX not covered may pay $100 per fill with savings of up to $970 per tube and savings of up to $3880 per year; for additional information contact the program at 877-548-1739.",
                "brand": "Eucrisa",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "3880",
                "lifetime_uses": "4",
                "max_saving_transaction": 970,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af4b"
            },
            {
                "_id": "6297bcace0e0db6c2572af50",
                "link": "https://www.evekeoodt.com/prescription-savings/signup",
                "description": "Evekeo ODT Copay Savings Program: Eligible commercially insured patients may pay no more than $30 per 30-day prescription; for additional information contact the program at 855-558-1630.",
                "brand": "Evekeo ODT",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af50"
            },
            {
                "_id": "6297bcabe0e0db6c2572aecf",
                "link": "https://www.enfamil.com/enfacare-drive-to-thrive-support-program/",
                "description": "Join the Drive to Thrive Support Program and receive up to $70 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Premature with Iron 20",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aecf"
            },
            {
                "_id": "6297bcace0e0db6c2572af55",
                "link": "https://www.evrysdicopay.com/",
                "description": "Evrysdi Co-pay Program: Eligible commercially insured patients may pay as little as $5 per prescription with an annual savings of $25,000; for additional information contact the program at 833-387-9734.",
                "brand": "Evrysdi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.33,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af55"
            },
            {
                "_id": "6297bcace0e0db6c2572af5a",
                "link": "https://www.excedrin.com",
                "description": "Sign-up and save $1 on any one Excedrin product; must click on an article to download coupon; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Excedrin Tension Headache",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af5a"
            },
            {
                "_id": "6297bcabe0e0db6c2572aed4",
                "link": "https://www.enfamil.com/baby-formula-coupons-samples/",
                "description": "Join Enfamil Family Beginnings and receive up to $400 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Reguline",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aed4"
            },
            {
                "_id": "6297bcace0e0db6c2572af5f",
                "link": "https://www.exondys51.com/getting-support/how-sarepta-case-managers-help",
                "description": "Exondys 51 Patient Co-Pay Assistance Program: Eligible commercially insured patients may receive assistance with some out-of-pocket costs related to receiving therapy; for additional information contact the program at 888-727-3782.",
                "brand": "Exondys 51",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af5f"
            },
            {
                "_id": "6297bcace0e0db6c2572af64",
                "link": "https://www.fabior.com/patient/mayne-pharma-patient-savings-card/",
                "description": "Fabior Mayne Pharma Patient Savings Card: Eligible commercially insured patients may have a copay of $0 per prescription; for additional information contact the program at 347-442-7919.",
                "brand": "Fabior",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af64"
            },
            {
                "_id": "6297bcace0e0db6c2572af69",
                "link": "https://www.fanaptpro.com",
                "description": "Fanapt Samples: Healthcare providers may order samples for their office by calling 877-824-1475.",
                "brand": "Fanapt",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af69"
            },
            {
                "_id": "6297bcace0e0db6c2572af6e",
                "link": "https://www.farxiga-hcp.com/savings",
                "description": "Farxiga Mail-In Rebate: Eligible cash-paying patients may receive a rebate check if their mail order pharmacy does not accept the Farxiga Savings Card by completing a rebate form and mailing in their original mail-order receipt; for additional information contact the program at 800-236-9933.",
                "brand": "Farxiga",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af6e"
            },
            {
                "_id": "6297bcabe0e0db6c2572aed9",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure Clear Drink Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aed9"
            },
            {
                "_id": "6297bcace0e0db6c2572af73",
                "link": "https://www.myaccess360.com/patient/fasenra-benralizumab/patient-affordability.html",
                "description": "Fasenra Access 360 Co-pay Savings Program: Eligible commercially insured patients pay $0 per injection with savings of up to $13,000 per year; out-of-pocket costs covered can include the cost of the product itself and/or the cost of injection of the product (program maximum of $100 per infusion assistance) in certain states; for additional information contact the program at 844-275-2360.",
                "brand": "Fasenra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "13000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af73"
            },
            {
                "_id": "6297bcace0e0db6c2572af78",
                "link": "http://wcpharma.com/patient-savings/",
                "description": "Fem pH Women's Choice Savings Coupon: Eligible uninsured/cash-paying patients may pay no more than $50 per prescription; for additional information contact the program at 800-664-1490.",
                "brand": "Fem pH",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af78"
            },
            {
                "_id": "6297bcace0e0db6c2572af7d",
                "link": "https://www.feosol.com/en/save-big-with-coupons",
                "description": "Save $2 with coupon on any one Feosol product; 1 coupon per purchase.",
                "brand": "Feosol Complete with Bifera",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af7d"
            },
            {
                "_id": "6297bcabe0e0db6c2572aede",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure High Protein Shake Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aede"
            },
            {
                "_id": "6297bcace0e0db6c2572af82",
                "link": "https://ferivafa.com/savings/",
                "description": "FeRiva FA eVoucherRx Program:  Eligible patients may pay no more than $30 per month at participating pharmacies; for additional information contact the program at 888-612-8466.",
                "brand": "FeRiva FA",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af82"
            },
            {
                "_id": "6297bcace0e0db6c2572af87",
                "link": "https://www.fetzimahcp.com/physician-resources/savings",
                "description": "Fetzima Samples: Healthcare providers may request samples online or by faxing a form to 877-619-5796.",
                "brand": "Fetzima",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af87"
            },
            {
                "_id": "6297bcabe0e0db6c2572aee3",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure Plant Based Protein Shake",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aee3"
            },
            {
                "_id": "6297bcace0e0db6c2572af8c",
                "link": "https://www.fiberchoice.com",
                "description": "Register your email address with Fiber Choice to receive special offers, promotions, and more.",
                "brand": "Fiber Choice Bone Health Chewable Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af8c"
            },
            {
                "_id": "6297bcabe0e0db6c2572aee8",
                "link": "https://enteragam.com/patients/enteragam-hub/",
                "description": "EnteraGam Hub Savings Offers: Eligible patients can sign-up for the program to find savings offers for the medication; for additional information contact the program at 844-368-3721.",
                "brand": "EnteraGam",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aee8"
            },
            {
                "_id": "6297bcace0e0db6c2572af91",
                "link": "https://www.fintepla.com/financial-support/cost-and-copay/",
                "description": "Fintepla Zogenix Copay Support Program: Eligible patients may pay no more than $25 in out-of-pocket costs for their prescriptions; for additional information contact the program at 877-964-3649.",
                "brand": "Fintepla",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af91"
            },
            {
                "_id": "6297bcace0e0db6c2572af96",
                "link": "https://www.yourcatalystpathways.com/personalized-support/financial-assistance.html",
                "description": "Firdapse Catalyst Pathways Copay Assistance Program: Eligible commercially insured patients may pay $0 per month per prescription; for additional information contact the program at 833-422-8259.",
                "brand": "Firdapse",
                "copay": "",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af96"
            },
            {
                "_id": "6297bcace0e0db6c2572af9b",
                "link": "https://www.womenshealthcaresolutions.com/patient-material-request",
                "description": "First Response Product Free Samples: Your healthcare provider may request samples for you for any one listed Women's product; for additional information contact a representative at 800-487-6526.",
                "brand": "First Response Pre-Seed Lubricant",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af9b"
            },
            {
                "_id": "6297bcace0e0db6c2572afa0",
                "link": "https://myflarex.com/copay-program/",
                "description": "Flarex Copay Savings Program: Eligible commercially insured patients pay $49 per fill; for additional information contact the program at 866-747-0976.",
                "brand": "Flarex",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afa0"
            },
            {
                "_id": "6297bcace0e0db6c2572afa5",
                "link": "https://www.flintstonesvitamins.com/promo/flintstones-vitamins-coupon",
                "description": "$1 coupon off any Flintstones Multivitamin Product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Flintstones Gummies Supplements Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afa5"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeed",
                "link": "https://burketherapeutics.com/entty-spray-emulsion",
                "description": "Entty Spray Savings: Eligible commercially insured patients may pay as little as $0 per prescription; for additional information contact the program at 501-620-4449.",
                "brand": "Entty",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeed"
            },
            {
                "_id": "6297bcace0e0db6c2572afaa",
                "link": "https://www.flonase.com/coupons/",
                "description": "Flonase Program: Join the and receive a $2-5 coupon for any 1 product  and earn points for future savings; 1 coupon per purchase.",
                "brand": "Flonase Sensimist Allergy Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afaa"
            },
            {
                "_id": "6297bcace0e0db6c2572afaf",
                "link": "https://www.florastor.com/coupons.html",
                "description": "Save $4 on your next Florastor product purchase; 1 coupon per purchase.",
                "brand": "FlorastorSelect Immunity Boost",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afaf"
            },
            {
                "_id": "6297bcabe0e0db6c2572aef2",
                "link": "https://www.envarsusxr.com/savings-support/envarsus-xr-co-pay-card/",
                "description": "Envarsus XR 0$ Co-Pay Card Reimbursement: Eligible commercially insured may receive a rebate for their copay after filling their prescription at the pharmacy if the pharmacy was unable to process the Copay Card; for additional information contact the program at 855-591-9134.",
                "brand": "Envarsus XR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "8550",
                "lifetime_uses": "12",
                "max_saving_transaction": 712.5,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aef2"
            },
            {
                "_id": "6297bcace0e0db6c2572afb4",
                "link": "https://www.focalinxr.com/index.jsp?usertrack.filter_applied=true&NovaId=3831502980504985206",
                "description": "Focalin XR Savings Card: Eligible commercially insured patients may pay the first $10 on each of up to 12 prescriptions with a savings of up to $60 per fill; annual savings of up to $720; for additional assistance contact the program at 866-877-4633.",
                "brand": "Focalin XR",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "720",
                "lifetime_uses": "12",
                "max_saving_transaction": 60,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afb4"
            },
            {
                "_id": "6297bcabe0e0db6c2572aef7",
                "link": "https://www.epiceramrx.com/patients.html",
                "description": "EpiCeram Primus Care Direct Mail-order Program: Eligible patients may pay no more than $20 per prescription; free home delivery available; for additional information contact the program at 888-546-2735.",
                "brand": "EpiCeram Skin Barrier Emulsion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aef7"
            },
            {
                "_id": "6297bcace0e0db6c2572afb9",
                "link": "https://www.fotivda.com/",
                "description": "Fotivda QuickStart Program: Eligible commercially insured patients may receive medication if experiencing insurance delays; for more information contact the program at 833-368-4832.",
                "brand": "Fotivda",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afb9"
            },
            {
                "_id": "6297bcace0e0db6c2572afbe",
                "link": "https://www.freestyle.abbott/us-en/myfreestyle.html",
                "description": "MyFreeStyle Program: Receive a FREE FreeStyle Libre Meter & sensor with sign-up; for additional information contact the program at 855-632-8658.",
                "brand": "FreeStyle Libre 14 day System",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afbe"
            },
            {
                "_id": "6297bcace0e0db6c2572afc3",
                "link": "https://www.fulphila.com/en/financial-support",
                "description": "Fulphila Mylan Advocate Co-pay Assistance Program: Eligible commercially insured patients may pay $0 copay per prescription; maximum savings of up to $10,000 per year; for additional information contact the program at 833-695-2623.",
                "brand": "Fulphila",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afc3"
            },
            {
                "_id": "6297bcabe0e0db6c2572aefc",
                "link": "https://www.cvs.com/content/epipen-alternative",
                "description": "Adrenaclick Available at CVS: Eligible cash-paying patients can purchase the authorized generic for Adrenaclick at a cash price of $109.99 for a two-pack; save an additional $10 when you use the savings coupon from drug manufacturer (https://sservices.trialcard.com/Coupon/Epinephrine)",
                "brand": "Epinephrine",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aefc"
            },
            {
                "_id": "6297bcace0e0db6c2572afc8",
                "link": "https://uspco.com/product_literature.aspx?pid=54&tab=Contact",
                "description": "Fusion Samples: Healthcare providers may request samples for their practice by filling out a form online.",
                "brand": "Fusion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afc8"
            },
            {
                "_id": "6297bcabe0e0db6c2572af01",
                "link": "https://www.activatethecard.com/viatrisadvocate/mygenericEAI/welcome.html?_ga=2.36252515.276121865.1579134457-1618483530.1579134457#tnc",
                "description": "Mylan Epinephrine Auto-Injector Savings Card (Brand Name EpiPen 2-Pack): Eligible commercially insured patients may receive $25 off each prescription on each of up to 3 fills (6 auto-injectors) per calendar year; for additional information contact the program at 800-657-7613.",
                "brand": "EpiPen 2-Pak",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "75",
                "lifetime_uses": "3",
                "max_saving_transaction": 25,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af01"
            },
            {
                "_id": "6297bcace0e0db6c2572afcd",
                "link": "https://www.futuro-usa.com/3M/en_US/futuro-us/offers/enewsletter/",
                "description": "Subscribe to the Futuro Brand Email Newsletter and receive special offers, coupons and free samples.",
                "brand": "Futuro Compression Socks or Hosiery Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afcd"
            },
            {
                "_id": "6297bcace0e0db6c2572afd2",
                "link": "https://www.eisaireimbursement.com/patient/fycompa/financial-assistance",
                "description": "Fycompa Instant Savings Card: Eligible cash-paying patients may save up to $60 per monthly prescription with a maximum savings of up to $720 per year; for additional information contact the program at 855-347-2448.",
                "brand": "Fycompa",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "720",
                "lifetime_uses": "12",
                "max_saving_transaction": 60,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afd2"
            },
            {
                "_id": "6297bcace0e0db6c2572afd7",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Ganirelix HEARTFELT Co-Pay Assistance Program: Eligible commercially insured patients whose COS cycles were canceled due to COVID or a natural disaster may qualify for a $250 rebate towards their copay costs already paid; timeframes for canceled cycles may be found on the application as well as timeframes for new cycles to begin; for additional information contact the program at 800-515-3784.",
                "brand": "Ganirelix Acetate",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "250",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afd7"
            },
            {
                "_id": "6297bcabe0e0db6c2572af06",
                "link": "https://azuritysolutions.com/patients/eprontia/#product",
                "description": "Eprontia eVocherRx Program: Eligible commercially insured patients may pay as little as $0 per monthly fill with a maximum savings of $250 at a participating pharmacy; for additional information contact the program at 844-472-2032.",
                "brand": "Eprontia",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af06"
            },
            {
                "_id": "6297bcace0e0db6c2572afdc",
                "link": "https://www.boironusa.com/gasalia-coupon/",
                "description": "$2 coupon off Gasalia with registration; 1 coupon per purchase.",
                "brand": "Gasalia",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afdc"
            },
            {
                "_id": "6297bcace0e0db6c2572afe1",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Gavreto Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Gavreto",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afe1"
            },
            {
                "_id": "6297bcace0e0db6c2572afe6",
                "link": "https://gemtesa.com/savings-program",
                "description": "Gemtesa Simple Savings Card: Eligible commercially insured patients/RX not covered may pay $95 per fill up to 12 times after activating the card; for additional information contact the program at 833-876-8268.",
                "brand": "Gemtesa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afe6"
            },
            {
                "_id": "6297bcace0e0db6c2572afeb",
                "link": "https://www.gileadadvancingaccess.com/financial-support/gilead-copay-card",
                "description": "Genvoya Advancing Access Co-pay Program: Eligible commercially insured patients may save up to a maximum of $7200 per year with no monthly limits; for additional information contact the program at 800-226-2056.",
                "brand": "Genvoya",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "ACCESS",
                "copay_max": "7200",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afeb"
            },
            {
                "_id": "6297bcace0e0db6c2572aff0",
                "link": "https://gilenya.com/ms-pill/co-pay",
                "description": "Gilenya Go Program: Most patients on Medicare Part D may pay $0 per month on their prescription; for additional information contact the program at 800-445-3692.",
                "brand": "Gilenya",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572aff0"
            },
            {
                "_id": "6297bcace0e0db6c2572aff5",
                "link": "https://www.gimotirx.com/copay-assistance",
                "description": "Gimoti Copay Assistance Program: Eligible commercially insured patients/RX not covered and cash-paying patients may pay $50 per prescription; for more information contact the program at 833-444-6684.",
                "brand": "Gimoti",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572aff5"
            },
            {
                "_id": "6297bcabe0e0db6c2572af0b",
                "link": "https://www.copayassistancenow.com/eligibility",
                "description": "Erivedge Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Erivedge",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af0b"
            },
            {
                "_id": "6297bcabe0e0db6c2572af10",
                "link": "https://arborpatientdirect.com",
                "description": "Erythromycin Family of Products Arbor Patient Direct: Payment will be reduced by up to 95% off the retail price for eligible patients by enrolling in the program; for additional information contact the program at 844-289-3981.",
                "brand": "Erythromycin Family Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af10"
            },
            {
                "_id": "6297bcabe0e0db6c2572af15",
                "link": "https://www.estring.com/save-on-estring",
                "description": "Estring Co-pay Card: Eligible patients may pay as little as $45 per prescription with savings of up $100 on each up to 4 fills; maximum annual savings of $400;  for additional information contact customer service at 800-631-1181.",
                "brand": "Estring",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "400",
                "lifetime_uses": "4",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af15"
            },
            {
                "_id": "6297bcabe0e0db6c2572af1a",
                "link": "https://www.estroven.com/sign-up-for-offers/",
                "description": "Sign up to the mailing list and exclusive offers for Estroven products.",
                "brand": "Estroven Menopause Relief plus Energy",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af1a"
            },
            {
                "_id": "6297bcabe0e0db6c2572af1f",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Advanced Cleansing Body and Face Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af1f"
            },
            {
                "_id": "6297bcabe0e0db6c2572af24",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Advanced Repair Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af24"
            },
            {
                "_id": "6297bcabe0e0db6c2572af29",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Baby Eczema Relief Flare-Up Treatment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af29"
            },
            {
                "_id": "6297bcabe0e0db6c2572af2e",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Eczema Relief Cream Body Wash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af2e"
            },
            {
                "_id": "6297bcabe0e0db6c2572af33",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Intensive Repair Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af33"
            },
            {
                "_id": "6297bcabe0e0db6c2572af38",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Original Healing Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af38"
            },
            {
                "_id": "6297bcabe0e0db6c2572af3d",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Roughness Relief Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af3d"
            },
            {
                "_id": "6297bcabe0e0db6c2572af42",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Skin Calming Body Wash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af42"
            },
            {
                "_id": "6297bcabe0e0db6c2572af47",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Skin Calming Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af47"
            },
            {
                "_id": "6297bcace0e0db6c2572af4c",
                "link": "https://www.euflexxa.com/euflexxa-support/#/copay-relief-program",
                "description": "Euflexxa Patient Copay Relief Program: Eligible commercially insured patients receiving 3 injections may save up to $50 for their 1st injection and up to $75 for their 2nd injection; for more information contact the program at 833-280-9558.",
                "brand": "Euflexxa",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "200",
                "lifetime_uses": "3",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af4c"
            },
            {
                "_id": "6297bcace0e0db6c2572af51",
                "link": "https://www.evekeoodt.com/prescription-savings/signup",
                "description": "Evekeo ODT Copay Savings Program: Eligible uninsured patients may pay no more than $75 per 30-day prescription; for additional information contact the program at 855-558-1630.",
                "brand": "Evekeo ODT",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af51"
            },
            {
                "_id": "6297bcace0e0db6c2572af56",
                "link": "https://evrysdicopay.com/forms",
                "description": "Evrysdi Co-pay Program Rebate: Eligible commercially insured patients may request a rebate if they paid the pharmacy out-of-pocket for their prescription; for additional information contact the program at 800-636-0316.",
                "brand": "Evrysdi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.33,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af56"
            },
            {
                "_id": "6297bcace0e0db6c2572af5b",
                "link": "https://www.exelderm.com/patient-savings-program.php",
                "description": "Exelderm Patient Savings Card: Eligible commercially insured patients may pay as little as $0 on each of up to 12 prescriptions; for additional information contact the program at 888-786-5876.",
                "brand": "Exelderm",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af5b"
            },
            {
                "_id": "6297bcace0e0db6c2572af60",
                "link": "https://www.extavia.com/info/Treatment/How-is-extavia-cost.jsp?usertrack.filter_applied=true&NovaId=3831502975509189036",
                "description": "Extavia Patient Co-Pay Savings Program: Eligible commercially insured patients may pay as little as $0 per prescription on out-of-pocket costs up to $9,300 per year; for additional information contact the program at 866-398-2842.",
                "brand": "Extavia",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "9300",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af60"
            },
            {
                "_id": "6297bcace0e0db6c2572af65",
                "link": "https://www.fabior.com/patient/mayne-pharma-patient-savings-card/",
                "description": "Fabior Mayne Pharma Patient Savings Card: Eligible commercially insured patients/RX not covered may pay as little as $65 per prescription; for additional information contact the program at 347-442-7919.",
                "brand": "Fabior",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af65"
            },
            {
                "_id": "6297bcace0e0db6c2572af6a",
                "link": "https://www.patientrxsolutions.com/fareston-copay/",
                "description": "Fareston Copay Assistance Card: Eligible patients may pay as little as $20 per 30-day supply prescription with savings of up to $150 per fills for of up to 12 months; for additional information contact the program at 877-251-4952.",
                "brand": "Fareston",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af6a"
            },
            {
                "_id": "6297bcace0e0db6c2572af6f",
                "link": "https://www.farxiga.com/savings-support/hero",
                "description": "Farxiga Savings Card: Eligible commercially insured patients may pay as low as $0 per 30-day supply; maximum savings limit of $175 per 30-day supply; for additional information contact the program at 844-631-3978.",
                "brand": "Farxiga",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "2100",
                "lifetime_uses": "12",
                "max_saving_transaction": 175,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af6f"
            },
            {
                "_id": "6297bcace0e0db6c2572af74",
                "link": "https://www.myaccess360.com/patient/faslodex-fulvestrant/patient-affordability.html",
                "description": "Faslodex Access 360 Co-pay Savings Program: Eligible commercially insured patients may pay $0 per dose with savings of up to $6000 per year; for additional information contact the program at 844-275-2360.",
                "brand": "Faslodex",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af74"
            },
            {
                "_id": "6297bcace0e0db6c2572af79",
                "link": "http://wcpharma.com/patient-savings/",
                "description": "Fem pH Women's Choice Savings Coupon: Eligible commercially insured patients may pay no more than $35 per prescription; for additional information contact the program at 800-664-1490.",
                "brand": "Fem pH",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af79"
            },
            {
                "_id": "6297bcace0e0db6c2572af7e",
                "link": "https://www.feosol.com/en/save-big-with-coupons",
                "description": "Save $2 with coupon on any one Feosol product; 1 coupon per purchase.",
                "brand": "Feosol Natural Release",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af7e"
            },
            {
                "_id": "6297bcace0e0db6c2572af83",
                "link": "https://avionrx.com/healthcare-professionals/",
                "description": "FeRiva FA Samples: Your healthcare provider may request FREE samples by filling out a sample request form online or by calling 888-612-8466.",
                "brand": "FeRiva FA",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af83"
            },
            {
                "_id": "6297bcace0e0db6c2572af88",
                "link": "https://www.novocare.com/insulin/my99insulin.html?src=100001849",
                "description": "Fiasp My$99Insulin Card: Eligible patients pay $99 per monthly prescription (up to 3 vials or 2 packs of pens) of Novo Nordisk insulin products; offer may be used once a month during a calendar year; for additional information contact the program at 888-910-0632.",
                "brand": "Fiasp",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af88"
            },
            {
                "_id": "6297bcace0e0db6c2572af8d",
                "link": "https://www.fiberchoice.com",
                "description": "Register your email address with Fiber Choice to receive special offers, promotions, and more.",
                "brand": "Fiber Choice Chewable Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af8d"
            },
            {
                "_id": "6297bcace0e0db6c2572af92",
                "link": "https://www.finteplahcp.com/hcp-resources/",
                "description": "Fintepla Zogenix Echocardiogram Copay Support Program: Eligible patients may as little as $0 for copays, deductibles, and/or coinsurance for Fintepla and associated echocardiograms; for additional information contact the program at 877-964-3649.",
                "brand": "Fintepla",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af92"
            },
            {
                "_id": "6297bcace0e0db6c2572af97",
                "link": "https://www.womenshealthcaresolutions.com/patient-material-request",
                "description": "First Response Product Free Samples: Your healthcare provider may request samples for you for any one listed Women's product; for additional information contact a representative at 800-487-6526.",
                "brand": "First Response Daily Digital Ovulation Test",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af97"
            },
            {
                "_id": "6297bcace0e0db6c2572af9c",
                "link": "https://www.womenshealthcaresolutions.com/patient-material-request",
                "description": "First Response Product Free Samples: Your healthcare provider may request samples for you for any one listed Women's product; for additional information contact a representative at 800-487-6526.",
                "brand": "First Response Pregnancy and Confirm Test",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af9c"
            },
            {
                "_id": "6297bcace0e0db6c2572afa1",
                "link": "https://www.flector.com/healthcare-professional-resources.html",
                "description": "Flector FREE Samples: Your healthcare provider may receive free samples for their practice; for additional assistance contact the program at 877-446-9809.",
                "brand": "Flector",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afa1"
            },
            {
                "_id": "6297bcace0e0db6c2572afa6",
                "link": "https://www.flintstonesvitamins.com/promo/flintstones-vitamins-coupon",
                "description": "$1 coupon off any Flintstones Multivitamin Product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Flintstones Natural Fruit Bites Multivitamin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afa6"
            },
            {
                "_id": "6297bcace0e0db6c2572afab",
                "link": "https://www.flonase.com/coupons/",
                "description": "Flonase Program: Join the and receive a $2-5 coupon for any 1 product  and earn points for future savings; 1 coupon per purchase.",
                "brand": "Flonase Sensimist Allergy Relief - Childrens",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afab"
            },
            {
                "_id": "6297bcace0e0db6c2572afb0",
                "link": "https://almiralladvantage.com/",
                "description": "Fluoroplex Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $15 per prescription when using a network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Fluoroplex",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afb0"
            },
            {
                "_id": "6297bcace0e0db6c2572afb5",
                "link": "https://www.focalinxr.com/copay-faqs",
                "description": "Focalin XR Savings Card Rebate: If your pharmacy does not accept the Savings Card or you forgot to present the Savings Card you a patient reimbursement form may be submitted within 90 days of the fill for a rebate; for additional assistance contact the program at 866-877-4633.",
                "brand": "Focalin XR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "720",
                "lifetime_uses": "12",
                "max_saving_transaction": 60,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afb5"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae36",
                "link": "https://diabetictussin.com/",
                "description": "$1 off any Diabetic Tussin product; limit 1 coupon per purchase.",
                "brand": "Diabetic Tussin Chest Congestion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae36"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae3b",
                "link": "https://differin.com/save",
                "description": "Save $1.75 on any one Differin Cleanser or Moisturizer product; coupon expires 1 month after; 1 coupon per purchase.",
                "brand": "Differin Daily Refreshing Cleanser",
                "copay": "",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1.75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae3b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae40",
                "link": "https://differin.com/save",
                "description": "Save $1.75 on any one Differin Cleanser or Moisturizer product; coupon expires 1 month after; 1 coupon per purchase.",
                "brand": "Differin Soothing Moisturizer",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1.75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae40"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae45",
                "link": "https://www.diovan.com/index.jsp?usertrack.filter_applied=true&NovaId=6002860716330473971",
                "description": "Diovan Co-Pay Card Program: Eligible commercially insured patients may pay as little as $10 per month with savings of up to $75 per 30 tablets; for additional information contact the program at 877-699-9975.",
                "brand": "Diovan",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "LOYALTY",
                "copay_max": "900",
                "lifetime_uses": "12",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae45"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae4a",
                "link": "https://www.divigel.com/divigel-offers/",
                "description": "Divigel Samples: Healthcare providers may fax their sample request form to 866-315-4753.",
                "brand": "Divigel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae4a"
            },
            {
                "_id": "6297bcace0e0db6c2572afe8",
                "link": "https://www.medimetriks.com/prescription-brands/genadur",
                "description": "Genadur Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Genadur",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afe8"
            },
            {
                "_id": "6297bcace0e0db6c2572afed",
                "link": "https://www.geodon.com/savings-terms",
                "description": "Geodon Savings Card Rebate: Eligible patients may submit a request for a rebate if their pharmacy does not accept the Savings Card; for additional information contact the program at 888-779-2134.",
                "brand": "Geodon",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afed"
            },
            {
                "_id": "6297bcace0e0db6c2572aff2",
                "link": "https://gilenya.com/ms-pill/co-pay",
                "description": "Gilenya Medical Co-Pay Support Program: Most eligible commercially insured patients may receive support for pretests and first-dose observation; for additional information contact the program at 800-445-3692.",
                "brand": "Gilenya",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572aff2"
            },
            {
                "_id": "6297bcace0e0db6c2572aff7",
                "link": "https://www.glatirameracetate.com/en/patient-support",
                "description": "Glatiramer Acetate Viatris Advocate Co-pay Program: Eligible commercially insured patients may pay as low as $0 per monthly prescription with savings of up to $9000 per 12-month period; for additional information contact the program at 844-695-2667.",
                "brand": "Glatiramer Acetate",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "9000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572aff7"
            },
            {
                "_id": "6297bcace0e0db6c2572affc",
                "link": "https://glucerna.com/signup",
                "description": "Sign up with Glucerna Everyday Progress and receive savings of up to $24 on Glucerna products and much more.",
                "brand": "Glucerna Hunger Smart Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572affc"
            },
            {
                "_id": "6297bcace0e0db6c2572b001",
                "link": "https://glucerna.com/signup",
                "description": "Sign up with Glucerna Everyday Progress and receive savings of up to $24 on Glucerna products and much more.",
                "brand": "Glucerna Snack Shakes",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b001"
            },
            {
                "_id": "6297bcace0e0db6c2572b006",
                "link": "https://www.gocovri.com/getting-gocovri#free-trial-option",
                "description": "Gocovri Free 4-Week Trial Program: New eligible patients may receive a 28-day supply of medication at no cost; for additional information contact the program at 844-462-6874.",
                "brand": "Gocovri",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b006"
            },
            {
                "_id": "6297bcace0e0db6c2572b00b",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Cracked Foot Skin Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b00b"
            },
            {
                "_id": "6297bcace0e0db6c2572b010",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Medicated Extra Strength Body Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b010"
            },
            {
                "_id": "6297bcace0e0db6c2572b015",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond No Mess Body Powder Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b015"
            },
            {
                "_id": "6297bcace0e0db6c2572b01a",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Therapeutic Foot Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b01a"
            },
            {
                "_id": "6297bcace0e0db6c2572b01f",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond Ultimate lotions and cream; coupon expires 1 month after printing.",
                "brand": "Gold Bond Ultimate Crepe Corrector Age Defense",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b01f"
            },
            {
                "_id": "6297bcace0e0db6c2572b01c",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product; coupon expires 1 month after printing.",
                "brand": "Gold Bond Ultimate Cracked Skin Relief Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b01c"
            },
            {
                "_id": "6297bcace0e0db6c2572b021",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Ultimate Diabetics Dry Skin Relief Hand Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b021"
            },
            {
                "_id": "6297bcace0e0db6c2572b026",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Ultimate Psoriasis Relief Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b026"
            },
            {
                "_id": "6297bcace0e0db6c2572b02b",
                "link": "https://www.granixrx.com/resources",
                "description": "Granix Savings program: Eligible commercially insured patients may pay as little as $0 per month on co-pay and out-of-pocket fees; maximum savings of $14,000 per calendar year; for additional information contact the program at 844-218-0443.",
                "brand": "Granix",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "14000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b02b"
            },
            {
                "_id": "6297bcace0e0db6c2572b030",
                "link": "https://www.gvokeglucagon.com/savings-and-support",
                "description": "Gvoke HypoPen Copay Card: Eligible commercially insured patients may pay as little as $0 per prescription; for additional information contact the program at 877-694-8653.",
                "brand": "Gvoke HypoPen",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b030"
            },
            {
                "_id": "6297bcace0e0db6c2572b035",
                "link": "https://www.eisaireimbursement.com/patient/halaven/financial-assistance",
                "description": "Halaven $0 Co-Pay Program: Eligible commercially insured patients may pay as little as $0 per prescription with a maxium savings of up to $18,000 per year; for additional information contact the program at 866-613-4724.",
                "brand": "Halaven",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "18000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b035"
            },
            {
                "_id": "6297bcace0e0db6c2572b03a",
                "link": "https://www.pggoodeveryday.com/signup/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Aloe Vera Anti-Dandruff Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b03a"
            },
            {
                "_id": "6297bcace0e0db6c2572b03f",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Clinical Moisturizing Scalp Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b03f"
            },
            {
                "_id": "6297bcace0e0db6c2572b044",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Green Apple Anti-Dandruff Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b044"
            },
            {
                "_id": "6297bcace0e0db6c2572b049",
                "link": "https://www.pggoodeveryday.com/signup/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Tea Tree Anti-Dandruff Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b049"
            },
            {
                "_id": "6297bcace0e0db6c2572b04e",
                "link": "https://acrotechpatientaccess.com",
                "description": "Hemady STAR Copay Assistance Program: Eligible commercially insured patients will pay $0 copay for the 1st date of service and a $25 copay for  subsequent dates of service; maximum savings of $10,000 per calendar year; for additional information contact the program at 888-537-8277.",
                "brand": "Hemady",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b04e"
            },
            {
                "_id": "6297bcace0e0db6c2572b053",
                "link": "https://hemlibracopay.com/forms",
                "description": "Hemlibra Co-pay Program Rebate: Eligible commercially insured patients may request reimbursement if they paid in full for copays or co-insurances; for additional information contact the program at 844-436-2672.",
                "brand": "Hemlibra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b053"
            },
            {
                "_id": "6297bcace0e0db6c2572b058",
                "link": "https://www.copayassistancenow.com/eligibility",
                "description": "Herceptin Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Herceptin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b058"
            },
            {
                "_id": "6297bcace0e0db6c2572b024",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Ultimate Eczema Relief Hand Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b024"
            },
            {
                "_id": "6297bcace0e0db6c2572b029",
                "link": "https://www.emdseronofertility.com/en/patient/affording-treatment/medication-savings.html",
                "description": "Gonal-F Compassionate Care Program: Eligible patients may save up to 75% off the available self-pay price for their medication at a participating pharmacy; patients may use the program for multiple cycles each year;  patients must reapply each year; for additional information contact the program at 855-541-5926.",
                "brand": "Gonal-F",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b029"
            },
            {
                "_id": "6297bcace0e0db6c2572b02e",
                "link": "https://www.alksavings.com/cb/access/crp/aLkYd2j78Vbb/fid.jsp?q=51G5OI5442CUDK4PLWTLX5&f=IMPX6IG-274EMWRGK6802#/app/layout/home",
                "description": "Grastek ALK Savings Card: Eligible commercially insured patients may pay as little as $25 per 30-day prescription if using an ALK Network Pharmacy; valid for 12 uses or $3,200 per year (whichever comes first); for additional information contact the program at 800-325-7354.",
                "brand": "Grastek",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3200",
                "lifetime_uses": "12",
                "max_saving_transaction": 265,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b02e"
            },
            {
                "_id": "6297bcace0e0db6c2572b033",
                "link": "https://gynazole.com/samples.html/#savings",
                "description": "Gynazole 1 Patient Savings Program: Eligible patients may pay no more than $25 with savings of up to $75 per prescription; or additional information contact the program directly at 844-492-9820.",
                "brand": "Gynazole 1",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b033"
            },
            {
                "_id": "6297bcace0e0db6c2572b038",
                "link": "https://www.harvoni.com/support-and-savings/co-pay-coupon-registration",
                "description": "Harvoni Co-pay Coupon: Eligible patients may pay as little as $5 per prescription per month for up to 6 months; for additional information contact the program at 855-769-7284.",
                "brand": "Harvoni",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b038"
            },
            {
                "_id": "6297bcace0e0db6c2572b03d",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Clinical Dandruff Defense Intensive Itch Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b03d"
            },
            {
                "_id": "6297bcace0e0db6c2572b042",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Deep Moisture Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b042"
            },
            {
                "_id": "6297bcace0e0db6c2572b047",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join  P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Mens Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b047"
            },
            {
                "_id": "6297bcace0e0db6c2572b04c",
                "link": "https://heliocare.com/coupon/",
                "description": "Receive a $3 coupon for HelioCare by filling out the online form and be eligible to receive future promotions and savings; 1 coupon per purchase.",
                "brand": "Heliocare Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b04c"
            },
            {
                "_id": "6297bcace0e0db6c2572b051",
                "link": "https://www.boironusa.com/hemcalm-coupon/",
                "description": "$2 coupon for any HemCalm product; 1 coupon per purchase.",
                "brand": "HemCalm Suppositories",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b051"
            },
            {
                "_id": "6297bcace0e0db6c2572b056",
                "link": "https://uspco.com/product_literature.aspx?pid=210&tab=Contact",
                "description": "Hemocyte Plus Samples: healthcare providers may request samples for their practice by filling out information online.",
                "brand": "Hemocyte Plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b056"
            },
            {
                "_id": "6297bcace0e0db6c2572b05d",
                "link": "https://www.hizentra.com/free-trial",
                "description": "Hizentra Free Trial Program: Eligible commercially insured patients may be able to receive a 1-month supply of medicine, infusion equipment and nurse training for FREE; a healthcare provider must request the FREE trial for the patient; for additional information contact the program at 877-355-4447.",
                "brand": "Hizentra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b05d"
            },
            {
                "_id": "6297bcace0e0db6c2572b062",
                "link": "https://www.hollister.com/en/samplecheckoutpage",
                "description": "Hollister Ostomy Product Samples: Request FREE samples of Hollister Ostomy products by completing the online form; may request multiple product samples; for additional information contact the program at 888-808-7456.",
                "brand": "Hollister Ostomy Care Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b062"
            },
            {
                "_id": "6297bcace0e0db6c2572b067",
                "link": "https://www.horizant.com/request-rep",
                "description": "Horizant Samples: Healthcare providers may request samples by filling out a form online.",
                "brand": "Horizant",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b067"
            },
            {
                "_id": "6297bcace0e0db6c2572b06c",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program Humalog Mix 50-50: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog Mix 50-50",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b06c"
            },
            {
                "_id": "6297bcace0e0db6c2572b071",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog Mix 75-25: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog Mix 75-25",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b071"
            },
            {
                "_id": "6297bcace0e0db6c2572b076",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog U-200 KwikPen: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog U-200 KwikPen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b076"
            },
            {
                "_id": "6297bcace0e0db6c2572b07b",
                "link": "https://www.humira.com/humira-complete/cost-and-copay",
                "description": "Humira Complete Savings Card: Eligible commercially insured patients may pay as little as $5 per month with this savings card; maximum program benefit of $6,000 per calendar year; for additional information contact the program at 800-448-6472.",
                "brand": "Humira",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6000",
                "lifetime_uses": "12",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b07b"
            },
            {
                "_id": "6297bcace0e0db6c2572b080",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humulin N: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humulin N",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b080"
            },
            {
                "_id": "6297bcace0e0db6c2572b085",
                "link": "https://www.humulin.com/savings-support",
                "description": "Humulin R U-500 Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription on each of up to 12 fills; maximum annual savings of $8400; for additional information contact the program at 800-545-5979.",
                "brand": "Humulin R U-500",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "8400",
                "lifetime_uses": "12",
                "max_saving_transaction": 700,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b085"
            },
            {
                "_id": "6297bcace0e0db6c2572b08a",
                "link": "https://www.hyqvia.com/support-resources/hellohyqvia-free-trial",
                "description": "Hyqvia HelloHYQVIA Free Trial Offer: New patients receive the first 3 infusions along with the supplies needed for administration for FREE; for additional information contact the program at 855-250-5111.",
                "brand": "HyQvia",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b08a"
            },
            {
                "_id": "6297bcace0e0db6c2572b05b",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Herceptin Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Herceptin Hylecta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b05b"
            },
            {
                "_id": "6297bcace0e0db6c2572b08f",
                "link": "https://www.pfizeroncologytogether.com/patient/signup",
                "description": "Ibrance Pfizer Oncology Together Co-pay Savings Program (oral products): Eligible commercially insured patients may pay as little as $0 per prescription with savings of up to $25,000 per calendar year; for additional information contact the program at 877-744-5675.",
                "brand": "Ibrance",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b08f"
            },
            {
                "_id": "6297bcace0e0db6c2572b094",
                "link": "https://www.takedaoncologycopay.com",
                "description": "Iclusig Takeda Oncology Co-pay Assistance Program Rebate: If a commercially insured patient is unable to take advantage of the assistance program at their local pharmacy they may be eligible for a rebate; for additional information contact the program at 844-817-6468.",
                "brand": "Iclusig",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b094"
            },
            {
                "_id": "6297bcace0e0db6c2572b099",
                "link": "https://www.icyhot.com/coupons/",
                "description": "Save $1 on any one IcyHot product (excludes 1 count patch products and 1.25 oz cream products); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "IcyHot Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b099"
            },
            {
                "_id": "6297bcace0e0db6c2572b09e",
                "link": "https://www.icyhot.com/coupons/",
                "description": "Save $1 on any one IcyHot product (excludes 1 count patch products and 1.25 oz cream products); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "IcyHot Lidocaine No Mess Roll-on",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b09e"
            },
            {
                "_id": "6297bcace0e0db6c2572b0a3",
                "link": "https://www.idelvion.com/savings-support/co-pay",
                "description": "Idelvion Co-Pay Support Program: Eligible commercially insured patients may save up to $12,000 per year on their out-of pocket expenses; for additional information contact the program 800-676-4266.",
                "brand": "Idelvion",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0a3"
            },
            {
                "_id": "6297bcace0e0db6c2572b0a8",
                "link": "https://www.ilumya.com/help-from-our-team/#copay-card",
                "description": "Ilumya Copay Program: Eligible commercially insured patients may pay no more than $5 per dose; maximum benefit of $16,000 per calendar year; for additional information contact the program at 855-445-8692.",
                "brand": "Ilumya",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "16000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0a8"
            },
            {
                "_id": "6297bcace0e0db6c2572b0ad",
                "link": "https://www.myaccess360.com/patient/imfinzi-durvalumab/patient-affordability.html",
                "description": "Imfinzi Access 360 Co-pay Savings Program: Eligible commercially insured patients may pay no more than $0 per infusion with savings of up to $26,000 per year; out-of-pocket costs covered can include the cost of the product itself and/or the cost of infusion of the product (program maximum of $100 per infusion) in certain states; for additional information contact the program at 844-275-2360.",
                "brand": "Imfinzi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "26000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0ad"
            },
            {
                "_id": "6297bcace0e0db6c2572b0b2",
                "link": "https://mycareclubrewards.com/imodium/?utm_medium=partner&utm_source=imodium.com&utm_content=footer&utm_campaign=cc_main&_gl=1*rj31n4*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjY1MDQ3OC41LjEuMTYzNjY1MDUzMC44*_fplc*SyUyQnh2V1hwcnlYTG1ONHJlN0g5NjE5OE93WG1hd21tWkI3RktEcGtsdTMlMkZtU3hsVGxjZ1VVMU92MUVzclZEakU5JTJGYXJPJTJGSXVqbkZBUWFNazRYa0dSZkE2YkdLWGxKdzdjNGhVaFplV3JzNTg3aVpJUEhOTmRYJTJGRmlId2RIdyUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Imodium A-D products with registration.",
                "brand": "Imodium A-D Oral Solution",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0b2"
            },
            {
                "_id": "6297bcace0e0db6c2572b0b7",
                "link": "https://savings.imvexxy.com/",
                "description": "Imvexxy Savings Program: Eligible commercially insured patients may pay as little as $35 on each of up to twelve (12) 30-day prescriptions; for additional information contact the program at 844-208-0002.",
                "brand": "Imvexxy",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0b7"
            },
            {
                "_id": "6297bcace0e0db6c2572b0bc",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Infants Tylenol Liquid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0bc"
            },
            {
                "_id": "6297bcace0e0db6c2572b0c1",
                "link": "https://www.pfizeroncologytogether.com/patient/signup",
                "description": "Inlyta Pfizer Oncology Together Co-Pay Savings Program (oral products): Eligible commercially insured patients may pay as little as $0 per prescription with savings of up to $25,000 per calendar year; for additional information contact the program at 877-744-5675.",
                "brand": "Inlyta",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0c1"
            },
            {
                "_id": "6297bcace0e0db6c2572b0c6",
                "link": "https://www.taihopatientsupport.com/financial-support",
                "description": "Inqovi Taiho Oncology Patient Support Co-pay Assistance Program: Eligible commercially insured patients may pay $0 per treatment cycle; for additional information contact the program at 844-824-4648.",
                "brand": "Inqovi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0c6"
            },
            {
                "_id": "6297bcace0e0db6c2572b0cb",
                "link": "https://uspco.com/product_literature.aspx?pid=65&tab=Voucher",
                "description": "Integra Pharmacy Voucher: Eligible patients may save up to 80% at participating pharmacies; for additional information contact the program at  888-602-2978.",
                "brand": "Integra",
                "copay": "No",
                "bin": "600428",
                "rxpcn": "5100000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0cb"
            },
            {
                "_id": "6297bcace0e0db6c2572b0d0",
                "link": "https://www.janssencarepath.com/patient/intelence/cost-support",
                "description": "Intelence Janssen CarePath Savings Program Rebate: If the pharmacy cannot process the Savings Card the patient may submit a request for a rebate; for additional information contact the program at 866-836-0114.",
                "brand": "Intelence",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "7500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0d0"
            },
            {
                "_id": "6297bcace0e0db6c2572b0d5",
                "link": "https://www.invegasustennahcp.com/#outpatient-externallink-popup",
                "description": "Invega Sustenna Outpatient Sample Program: Your healthcare provider may order FREE samples; providers can be directed to the website by clicking on the Patient Support drop-down menu for Outpatient Sample Program; for additional information contact the program at 855-463-1912.",
                "brand": "Invega Sustenna",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0d5"
            },
            {
                "_id": "6297bcace0e0db6c2572b0da",
                "link": "https://portal.trialcard.com/kala/inveltys/",
                "description": "Inveltys Copay Card: Eligible cash-paying patients may pay as little as $60 per prescription; offer valid for up to 6 prescriptions; for additional information contact the program at 877-454-8063.",
                "brand": "Inveltys",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0da"
            },
            {
                "_id": "6297bcace0e0db6c2572b060",
                "link": "https://www.hollister.com/en/samplecheckoutpage",
                "description": "Hollister Continence Product Samples: Request FREE samples of Hollister Continence products by completing the online form; may request multiple product samples; for additional information contact the program at 888-808-7456.",
                "brand": "Hollister Continence Care Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b060"
            },
            {
                "_id": "6297bcace0e0db6c2572b0df",
                "link": "https://www.myaccess360.com/patient/iressa-gefitinib/patient-affordability.html",
                "description": "Iressa Access 360 Co-pay Savings Program: Eligible commercially insured patients may pay $0 per 28-day supply with savings of up to $26,000 per year; for additional information contact the program at 844-275-2360.",
                "brand": "Iressa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "26000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2166.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0df"
            },
            {
                "_id": "6297bcace0e0db6c2572b065",
                "link": "https://www.horizant.com/savings",
                "description": "Horizant Copay Savings Card: Eligible cash-paying patients may pay $0 for their 1st prescription and then pay no more than $75 per fill (300/600 mg) prescriptions; for additional information contact the program at 855-700-2990.",
                "brand": "Horizant",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b065"
            },
            {
                "_id": "6297bcace0e0db6c2572b0e4",
                "link": "https://www.isentress.com/what-is-isentress/?src=google&med=cpc&camp=Isentress_Isentress_BRND_NA_ENGM_EXCT_TEXT_MULTI&adgrp=Isentress+General&kw=isentress+hd&gclid=CjwKCAiA_9r_BRBZEiwAHZ_v1yuPUl1AXfqO8Zk5R0rm4dAMfMIiCjTX4q9oAt_Z3B5Uv4IltAnFXxoCZ44QAvD_BwE&gclsrc=aw.ds",
                "description": "Isentress HD Savings Coupon: Eligible commercially insured patients may save on their out-of-pocket costs such as co-pays, co-insurance and deductibles; maximum savings of $6800; coupon may be redeemed once every 21 days; for additional information contact program at 800-672-6372.",
                "brand": "Isentress HD",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "6800",
                "lifetime_uses": "12",
                "max_saving_transaction": 566.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0e4"
            },
            {
                "_id": "6297bcace0e0db6c2572b06a",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog KwikPen: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog KwikPen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b06a"
            },
            {
                "_id": "6297bcace0e0db6c2572b06f",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog Mix 50-50 KwikPen: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog Mix 50-50 KwikPen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b06f"
            },
            {
                "_id": "6297bcace0e0db6c2572b074",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog U-100: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog U-100",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b074"
            },
            {
                "_id": "6297bcace0e0db6c2572b079",
                "link": "https://www.humate-p.com/support-and-resources",
                "description": "Humate-P Co-pay Assistance Program: Eligible commercially insured patients may save up to $12,000 for out-of-pocket expenses per year; for additional information contact the program at 800-676-4266.",
                "brand": "Humate-P",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b079"
            },
            {
                "_id": "6297bcace0e0db6c2572b07e",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humulin 70/30 KwikPen: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humulin 70-30 Kwikpen",
                "copay": "Yes",
                "bin": "18884",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b07e"
            },
            {
                "_id": "6297bcace0e0db6c2572b083",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humulin R U-100: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humulin R U-100",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b083"
            },
            {
                "_id": "6297bcace0e0db6c2572b088",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humulin R U-500 KwikPen: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humulin R U-500 KwikPen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b088"
            },
            {
                "_id": "6297bcace0e0db6c2572b08d",
                "link": "https://www.eyefile.com/theeyesolution/register",
                "description": "Save $2 on your next purchase of any one I-Caps Eye Multivitamin Product with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "I-Caps Eye Multivitamin Formula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b08d"
            },
            {
                "_id": "6297bcace0e0db6c2572b092",
                "link": "https://www.tevagenerics.com/featured-products/icatibant-injection-copay-card",
                "description": "Icatibant Savings Card: Eligible uninsured patients may pay as little as $0 per prescription; for additional information contact the program at 855-889-7732.",
                "brand": "Icatibant",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b092"
            },
            {
                "_id": "6297bcace0e0db6c2572b097",
                "link": "https://www.icyhot.com/coupons/",
                "description": "Save $1 on any one IcyHot product (excludes 1 count patch products and 1.25 oz cream products); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "IcyHot Back Patch",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b097"
            },
            {
                "_id": "6297bcace0e0db6c2572b09c",
                "link": "https://www.icyhot.com/coupons/",
                "description": "Save $1 on any one IcyHot product (excludes 1 count patch products and 1.25 oz cream products); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "IcyHot Lidocaine Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b09c"
            },
            {
                "_id": "6297bcace0e0db6c2572b0a1",
                "link": "https://www.icyhot.com/coupons/",
                "description": "Save $1 on any one IcyHot product (excludes 1 count patch products and 1.25 oz cream products); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "IcyHot Power Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0a1"
            },
            {
                "_id": "6297bcace0e0db6c2572b0a6",
                "link": "https://www.bmsaccesssupport.bmscustomerconnect.com/patient/financial-support",
                "description": "Idhifa BMS Oncology Co-pay Assistance Program: Eligible commercially insured patients' may pay a $10 copay per 30-day prescription with savings of up to $15,000 per calendar year; for additional information contact the program at 800-861-0048.",
                "brand": "Idhifa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0a6"
            },
            {
                "_id": "6297bcace0e0db6c2572b0ab",
                "link": "https://www.imbruvica.com/imbruvica-by-your-side/",
                "description": "Imbruvica Copay Program: Eligible commercially insured patients may pay no more than $10 per prescription with savings of up to $24,600 per calendar year; for additional information contact the program at 888-968-7743.",
                "brand": "Imbruvica",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "24600",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0ab"
            },
            {
                "_id": "6297bcace0e0db6c2572b0e9",
                "link": "https://www.ixinity.com/support-savings/ixinity-savings-program/",
                "description": "Ixinity Savings Card: Eligible commercially insured patients may save up to $12,000 per year; for additional information contact the program at 855-494-6489.",
                "brand": "Ixinity",
                "copay": "Yes",
                "bin": "21940",
                "rxpcn": "PYS",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0e9"
            },
            {
                "_id": "6297bcace0e0db6c2572b0ee",
                "link": "https://www.jakafi.com/polycythemia-vera/incytecares-support",
                "description": "Jakafi Patient Copay/Coinsurance Assistance: Eligible commercially insured patients may pay as little as $0 per month with savings of up to $25,000 per year; for additional information contact the program at 855-452-5234.",
                "brand": "Jakafi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 11977,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0ee"
            },
            {
                "_id": "6297bcace0e0db6c2572b0f3",
                "link": "https://www.merckconnect.com/janumet/dosing-administration/",
                "description": "Janumet XR Samples: Your healthcare provider may request samples, vouchers and coupons for their patients; for additional information your healthcare provider may contact the program at 866-237-4286.",
                "brand": "Janumet XR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0f3"
            },
            {
                "_id": "6297bcace0e0db6c2572b0f8",
                "link": "https://www.jatenzo.com/patient-support/",
                "description": "Jatenzo Savings Card: Eligible commercially insured patients pay as little as $0 per monthly prescription with a maximum savings of up to $2000 per calendar year; for additional information contact the program at 844-269-2795.",
                "brand": "Jatenzo",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "2000",
                "lifetime_uses": "12",
                "max_saving_transaction": 166.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0f8"
            },
            {
                "_id": "6297bcace0e0db6c2572b0fd",
                "link": "https://www.sanoficareassist.com/jevtana/copay",
                "description": "Jevtana CareASSIST Copay Program: Eligible commercially insured patients may pay as little as $0 for co-insurance, co-pay and deductibles; maxium savings of $25,000 per year; for additional information contact the program at 833-930-2273, Option 1.",
                "brand": "Jevtana",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0fd"
            },
            {
                "_id": "6297bcace0e0db6c2572b102",
                "link": "https://copaysupport.bayer.com",
                "description": "Jivi $0 Product Co-Pay Program: Eligible commercially insured patients may receive up to $12,000 in assistance per year; for more information contact the program at 647-245-5619.",
                "brand": "Jivi",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b102"
            },
            {
                "_id": "6297bcace0e0db6c2572b0b0",
                "link": "https://mycareclubrewards.com/imodium/?utm_medium=partner&utm_source=imodium.com&utm_content=footer&utm_campaign=cc_main&_gl=1*rj31n4*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjY1MDQ3OC41LjEuMTYzNjY1MDUzMC44*_fplc*SyUyQnh2V1hwcnlYTG1ONHJlN0g5NjE5OE93WG1hd21tWkI3RktEcGtsdTMlMkZtU3hsVGxjZ1VVMU92MUVzclZEakU5JTJGYXJPJTJGSXVqbkZBUWFNazRYa0dSZkE2YkdLWGxKdzdjNGhVaFplV3JzNTg3aVpJUEhOTmRYJTJGRmlId2RIdyUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Imodium A-D products with registration.",
                "brand": "Imodium A-D Caplet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0b0"
            },
            {
                "_id": "6297bcace0e0db6c2572b107",
                "link": "https://www.orthorxaccess.com/",
                "description": "Jublia Rx Access Program: Eligible uninsured/cash-pay patients may pay up to $130 per prescription; valid for 12 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Jublia",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b107"
            },
            {
                "_id": "6297bcace0e0db6c2572b0b5",
                "link": "https://www.activatethecard.com/viatrisadvocate/impeklo/welcome.html#tnc",
                "description": "Impeklo Savings Card: Eligible commercially insured patients may pay as little as $0 per fill and save up to $350 per fill (limit one unit per fill); maximum savings of $4200 per calendar year; for additional information contact the program at 800-657-7613.",
                "brand": "Impeklo",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "4200",
                "lifetime_uses": "0",
                "max_saving_transaction": 350,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0b5"
            },
            {
                "_id": "6297bcace0e0db6c2572b10c",
                "link": "https://www.alle.com/upgrade",
                "description": "Juvederm Ultra XC Alle Points Program: Register and earn points redeemable for dollars OFF certain in-office treatments; also receive personalized special offers; for additional information contact the program at 888-912-1572.",
                "brand": "Juvederm Ultra XC",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b10c"
            },
            {
                "_id": "6297bcace0e0db6c2572b111",
                "link": "https://www.copayassistancenow.com/eligibility",
                "description": "Kadcyla Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Kadcyla",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b111"
            },
            {
                "_id": "6297bcace0e0db6c2572b0ba",
                "link": "https://copaycard.inderalla.com/",
                "description": "Inderal LA Copay Savings Program: Eligible patients may pay as little as $0 on copays for prescription sizes 28-59 capsules (save up to $100), 60-89 capsules (save up to $200) or 90+ capsules (save up to $300); for additional information contact the program at 888-846-0234.",
                "brand": "Inderal LA",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0ba"
            },
            {
                "_id": "6297bcace0e0db6c2572b116",
                "link": "https://amgenfirststep.com/register-card",
                "description": "Kanjiniti Amgen First Step Program: Eligible commercially insured patients may save on their out-of-pocket medication costs; for additional information contact the program at 888-427-7478.",
                "brand": "Kanjinti",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b116"
            },
            {
                "_id": "6297bcace0e0db6c2572b0bf",
                "link": "https://www.ingrezza.com/savings-resources",
                "description": "Ingrezza Savings Program: Eligible commercially insured patients may pay $0 copay on their monthly prescriptions; for additional information contact the program at 855-252-0152.",
                "brand": "Ingrezza",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0bf"
            },
            {
                "_id": "6297bcace0e0db6c2572b11b",
                "link": "https://karbinaler.com",
                "description": "Karbinal ER Savings Offer: Eligible commercially insured patients/RX not covered pay $50 prescription; for additional information contact the program at 888-298-8792.",
                "brand": "Karbinal ER",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b11b"
            },
            {
                "_id": "6297bcace0e0db6c2572b0c4",
                "link": "https://www.innopranxl.com/",
                "description": "InnoPran XL eVoucherRx Program: Most eligible patients may pay $0 on future prescriptions at participating pharmacies; savings of up to $300 per prescription; for additional information contact the program at 800-434-1121.",
                "brand": "InnoPran XL",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0c4"
            },
            {
                "_id": "6297bcace0e0db6c2572b120",
                "link": "https://www.kerasal.com/pages/coupons",
                "description": "Save $2 on Kerasal Intensive Foot Repair; one coupon per purchase.",
                "brand": "Kerasal Intensive Foot Repair",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b120"
            },
            {
                "_id": "6297bcace0e0db6c2572b0c9",
                "link": "https://www.novocare.com/insulin/my99insulin.html?src=100001849",
                "description": "Insulin�Aspart Protamine and Insulin Aspart�Injectable Suspension My$99Insulin Card: Eligible patients pay $99 per monthly prescription (up to 3 vials or 2 packs of pens) of Novo Nordisk insulin products; offer may be used once a month during a calendar year; for additional information contact the program at 888-910-0632.",
                "brand": "Insulin Aspart Protamine and Insulin Aspart Mix 70/30",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0c9"
            },
            {
                "_id": "6297bcace0e0db6c2572b125",
                "link": "https://www.kesimpta.com/patient-support/financial-resources/",
                "description": "Kesimpta $0 Access Card: Eligible commercially insured patients may pay as little as $0 per prescriptions when enrolled in the program; $18,000 maximum savings per calendar year; for more information contact the program at 855-537-4678.",
                "brand": "Kesimpta",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "18000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b125"
            },
            {
                "_id": "6297bcace0e0db6c2572b12a",
                "link": "https://www.merckaccessprogram-keytruda.com/hcc/the-merck-copay-assistance-program/",
                "description": "Keytruda Merck Co-Pay Assistance Program: Eligible commercially insured patients pay $25 per infusion with a maximum benefit of $25,000 per calendar year; for additional information contact the program at 855-257-3932.",
                "brand": "Keytruda",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b12a"
            },
            {
                "_id": "6297bcace0e0db6c2572b0ce",
                "link": "https://uspco.com/product_literature.aspx?pid=67&tab=Voucher",
                "description": "Integra Plus Pharmacy Voucher: Eligible patients may save up to 80% at participating pharmacies; for additional information contact the program at  888-602-2978.",
                "brand": "Integra Plus",
                "copay": "No",
                "bin": "600428",
                "rxpcn": "5100000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0ce"
            },
            {
                "_id": "6297bcace0e0db6c2572b12f",
                "link": "https://www.us.kisqali.com/metastatic-breast-cancer/patient-support/financial-resources/",
                "description": "Kisqali 5-Treatment Cycle Access: Eligible commercially insured patients who are still waiting for coverage may obtain an additional 5 treatment supply; for additional information contact the program at 800-282-7630.",
                "brand": "Kisqali",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b12f"
            },
            {
                "_id": "6297bcace0e0db6c2572b0d3",
                "link": "https://www.janssencarepath.com/patient/invega-hafyera/cost-support",
                "description": "Invega Hafyera Janssen CarePath Savings Program: Eligible commercially insured patients may pay $10 per dose with a maximum savings of $8,000  per calendar year or 2 doses whichever comes first; for additional information contact the program at 877-227-3728.",
                "brand": "Invega Hafyera",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "8000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0d3"
            },
            {
                "_id": "6297bcace0e0db6c2572b134",
                "link": "https://kitabis.com/patient-access/CoPayAssistance",
                "description": "Kitabis Pak Co-Pay Assistance Program: Eligible commercially insured patients are eligible for savings of up to $1440 per prescription; for additional information contact the program at 844-548-2247.",
                "brand": "Kitabis Pak",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1440,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b134"
            },
            {
                "_id": "6297bcace0e0db6c2572b0d8",
                "link": "https://www.janssencarepath.com/patient/invega-trinza/cost-support",
                "description": "Invega Trinza Janssen CarePath Savings Program: Eligible commercially insured patients may pay no more than $10 per dose on each of up to 4 doses or a maximum savings limit of $8000, whichever comes 1st, per calendar year; for additional information contact customer service at 877-227-3728.",
                "brand": "Invega Trinza",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "8000",
                "lifetime_uses": "4",
                "max_saving_transaction": 2000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0d8"
            },
            {
                "_id": "6297bcace0e0db6c2572b139",
                "link": "https://www.kogenatefs.com/accessing-kogenate",
                "description": "Kogenate FS Loyalty Program: Eligible commercially insured patients may receive medication at no cost for up to 4 months if they are experiencing gaps or changes in their insurance coverage; the patients physician must fill out a form for enrollment; for additional information contact the program at 800-288-8374.",
                "brand": "Kogenate FS",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b139"
            },
            {
                "_id": "6297bcace0e0db6c2572b13e",
                "link": "https://www.korlym.com/hcp/access-support/support-program-access-reimbursement-korlym-spark/",
                "description": "Korlym Copay Support through SPARK: Eligible patients may receive copay and deductible support; for additional information contact the program at 855-456-7596.",
                "brand": "Korlym",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b13e"
            },
            {
                "_id": "6297bcace0e0db6c2572b0dd",
                "link": "https://www.invokana.com/savings-and-cost-support",
                "description": "Invokamet XR Janssen CarePath Savings Program: Most commercially insured patients may pay $0 copay per month with savings of up to $3000 per calendar year; for additional information contact the program at 877-468-6526.",
                "brand": "Invokamet XR",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0dd"
            },
            {
                "_id": "6297bcace0e0db6c2572b143",
                "link": "https://copaysupport.bayer.com/faqs",
                "description": "Kovaltry $0 Co-Pay Program Rebate: If the pharmacy does not accept the offer eligible commercially insured patients may receive a rebate for their out-of-pocket cost; for additional information contact the program at 647-245-5619.",
                "brand": "Kovaltry",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b143"
            },
            {
                "_id": "6297bcace0e0db6c2572b0e2",
                "link": "https://www.activatethecard.com/7967/",
                "description": "Isentress Savings Coupon: Eligible commercially insured patients may save on their out-of-pocket�costs such as co-pays, co-insurance and deductibles; maximum savings of $6800; coupon may be redeemed once every 21 days; for additional information contact program at 800-672-6372.",
                "brand": "Isentress",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "6800",
                "lifetime_uses": "12",
                "max_saving_transaction": 566.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0e2"
            },
            {
                "_id": "6297bcace0e0db6c2572b148",
                "link": "https://www.kuvan.com/getting-and-taking-kuvan/try-kuvan/",
                "description": "30-day FREE Trial of Kuvan: Eligible patients may receive 1 FREE 30-day prescription; for additional information contact the program at 866-906-6100.",
                "brand": "Kuvan",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b148"
            },
            {
                "_id": "6297bcace0e0db6c2572b0e7",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Istalol Bausch + Lomb Access Program: Eligible cash-paying and commercially insured/RX not covered patients may pay no more than $60 per 2.5 mL bottle/$90 per 5 mL bottle prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Istalol",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0e7"
            },
            {
                "_id": "6297bcace0e0db6c2572b14d",
                "link": "https://amgenfirststep.com",
                "description": "Kyprolis Amgen First Step Program Rebate: Eligible commercially insured patients who have already paid for treatment may request a rebate check; for additional information contact the program at 888-657-8371.",
                "brand": "Kyprolis",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b14d"
            },
            {
                "_id": "6297bcace0e0db6c2572b0ec",
                "link": "https://copay.novartisoncology.com/",
                "description": "Jadenu Sprinkle Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Jadenu Sprinkle",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0ec"
            },
            {
                "_id": "6297bcace0e0db6c2572b152",
                "link": "https://mycareclubrewards.com/lactaid/?utm_medium=partner&utm_source=lactaid.com&utm_content=tout&utm_campaign=cc_main&_gl=1*1jjrjz9*_ga*MjAwOTYxNTY1My4xNjM2OTk3MjUw*_ga_13VEM6N66E*MTYzNzE2ODg0Ny43LjEuMTYzNzE2ODg5MS4xNg..*_fplc*JTJGRSUyRmRGbFkxdmElMkZPQ0FTRDE3Y2JCOFhieW1QaXp2ekFkSFRHWjZ4NzE1Q2p0JTJGeTU4d2JuM1lFVmcxQjVranklMkJuRXBWcGkyUzdqQVpqTVpnRjhFZlJzb3N6YTRlRGJGSlNTZHQ3R0p5aFVoMjFlRGZjaHJNY1pjS1VUdnB4QSUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Lactaid products with registration.",
                "brand": "Lactaid Original Strength",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b152"
            },
            {
                "_id": "6297bcace0e0db6c2572b0f1",
                "link": "https://www.merckconnect.com/services/request-samples-vouchers-coupons/",
                "description": "Janumet Samples: Your healthcare provider may request samples, vouchers and coupons for their patients; for additional information your healthcare provider may contact the program at 866-237-4286.",
                "brand": "Janumet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0f1"
            },
            {
                "_id": "6297bcace0e0db6c2572b0f6",
                "link": "https://www.januvia.com/special-offers/",
                "description": "Januvia Savings Coupon: Eligible commercially insured patients may pay as little as $5 on each of up to 12 prescriptions with savings of up to $150 per month; saving coupon may be redeemed once every 21 days; for additional information contact the program at 877-264-2454",
                "brand": "Januvia",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0f6"
            },
            {
                "_id": "6297bcace0e0db6c2572b0fb",
                "link": "https://www.jentadueto.com/savings-card",
                "description": "Jentadueto Savings Card: Eligible commercially insured patients may pay as little as $10 per month with a maximum savings of $150 per fill; for additional information contact the program at 888-879-0466.",
                "brand": "Jentadueto",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0fb"
            },
            {
                "_id": "6297bcace0e0db6c2572b100",
                "link": "https://www.jivi-us.com/copay-support/",
                "description": "Jivi Loyalty Program: Eligible commercially insured patients may receive medication at no cost for up to 4 months if they are experiencing gaps or changes in their insurance coverage; the patients physician must fill out a form for enrollment; for additional information contact the program at 800-288-8374.",
                "brand": "Jivi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b100"
            },
            {
                "_id": "6297bcace0e0db6c2572b105",
                "link": "https://www.jornaypm.com/savings",
                "description": "Jornay Copay Savings Program: Eligible commercially insured patients/RX not covered pay $0 for their 1st prescription of 30 capsules and then $75 per each subsequent fills; valid for 26 uses per year; for additional information at 877-938-4766.",
                "brand": "Jornay PM",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b105"
            },
            {
                "_id": "6297bcace0e0db6c2572b10a",
                "link": "https://myviivcard.com",
                "description": "Juluca ViiVConnect Savings Card: Eligible patients pay $0 per prescription with savings of up to $6250 per year with no monthly limit; for additional information contact the program at 844-588-3288.",
                "brand": "Juluca",
                "copay": "Yes",
                "bin": "610626",
                "rxpcn": "1016",
                "copay_max": "6250",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b10a"
            },
            {
                "_id": "6297bcace0e0db6c2572b10f",
                "link": "https://www.alle.com/upgrade",
                "description": "Juvederm Voluma XC Alle Points Program: Register and earn points redeemable for dollars OFF certain in-office treatments; also receive personalized special offers; for additional information contact the program at 888-912-1572.",
                "brand": "Juvederm Voluma XC",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b10f"
            },
            {
                "_id": "6297bcace0e0db6c2572b114",
                "link": "https://www.kaletra.com/",
                "description": "Kaletra Savings Program: Eligible commercially insured patients may save up to $400 per month on qualified prescriptions with savings of up to $4800 per year; for additional information contact the program at 866-525-3872.",
                "brand": "Kaletra",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 400,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b114"
            },
            {
                "_id": "6297bcace0e0db6c2572b119",
                "link": "https://karbinaler.com",
                "description": "Karbinal ER Aytu RxConnect Pharmacy Network Program: Eligible commercially insured patients/RX not covered may pay as little as $10 per 30-day prescription when using a Aytu RxConnect Pharmacy; for additional information contact the program at 877-675-6590.",
                "brand": "Karbinal ER",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b119"
            },
            {
                "_id": "6297bcace0e0db6c2572b11e",
                "link": "https://azuritysolutions.com/patients/katerzia/#product",
                "description": "Katerzia eVoucherRx Program: Eligible commercially insured patients may pay no more $30 per prescription with savings of up to $230 per fill at a participating pharmacy; for additional information contact the program at 844-472-2032.",
                "brand": "Katerzia",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 230,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b11e"
            },
            {
                "_id": "6297bcace0e0db6c2572b123",
                "link": "https://www.kerendiasavings.com",
                "description": "Kerendia Savings Card: Eligible commercially insured patients may pay as little as $10 per prescription with a savings of $3000 per year; for additional information contact the program at 908-731-8240.",
                "brand": "Kerendia",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b123"
            },
            {
                "_id": "6297bcace0e0db6c2572b128",
                "link": "https://www.kevzara.com/starting-kevzara/kevzaraconnect-copay-card/",
                "description": "Kevzara Connect Copay Card: Eligible commercially insured patients may pay $0 copay per month with savings of up to $15,000 per calendar year; for additional information contact the program at 844-218-0444.",
                "brand": "Kevzara",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b128"
            },
            {
                "_id": "6297bcace0e0db6c2572b12d",
                "link": "https://www.kineretrx.com/ra/kineret-support#fa-anchor",
                "description": "Kineret Copay Program: Eligible commercially insured patients may pay as little as $0 per monthly prescription with a maxium savings of $13,000 per calendar year;",
                "brand": "Kineret",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "13000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1083.33,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b12d"
            },
            {
                "_id": "6297bcace0e0db6c2572b132",
                "link": "https://www.us.kisqali.com/metastatic-breast-cancer/patient-support/financial-resources/",
                "description": "Kisqali Femara 5-Treatment Cycle Access: Eligible commercially insured patients who are still waiting for coverage may obtain an additional 5 treatment supply; for additional information contact the program at 800-282-7630.",
                "brand": "Kisqali and Femara",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b132"
            },
            {
                "_id": "6297bcace0e0db6c2572b137",
                "link": "https://kloxxado.com/",
                "description": "Kloxxado Savings Card: Eligible commercially insured patients may pay $0 per box up to 2 boxes per redemption and up to 4 boxes per month; for additional information contact the program at 877-259-6893.  Cash PT pays $45.50",
                "brand": "Kloxxado",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "0.00",
                "lifetime_uses": "12",
                "max_saving_transaction": null,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b137"
            },
            {
                "_id": "6297bcace0e0db6c2572b13c",
                "link": "https://www.kombiglyzexr.com/savings-support/kombiglyze-coupon.html",
                "description": "Kombiglyze XR Instant Savings Card: Eligible cash-paying patients will save up to $150 per prescription; for additional information contact the program at 855-907-3197",
                "brand": "Kombiglyze XR",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b13c"
            },
            {
                "_id": "6297bcace0e0db6c2572b141",
                "link": "https://copaysupport.bayer.com",
                "description": "Kovaltry $0 Co-Pay Program:  Eligible commercially insured patients may receive up to $12,000 in savings per year; for additional information contact the program at 647-245-5619.",
                "brand": "Kovaltry",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b141"
            },
            {
                "_id": "6297bcace0e0db6c2572b146",
                "link": "https://www.kristalose.com",
                "description": "Kristalose Mail-In Rebate: If the pharmacy is unable to process the Savings Card eligible patients can still take advantage of the offer; patients may download and redeem a new rebate offer each month; for additional information contact the program at 855-584-6194.",
                "brand": "Kristalose",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1500",
                "lifetime_uses": "12",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b146"
            },
            {
                "_id": "6297bcace0e0db6c2572b14b",
                "link": "https://www.kynmobi.com/savings/",
                "description": "Kynmobi Copay Savings Card: Eligible cash-paying patients may pay as little as $195 per 30-count carton; for additional information contact the program at 844-596-6624.",
                "brand": "Kynmobi",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b14b"
            },
            {
                "_id": "6297bcace0e0db6c2572b150",
                "link": "https://mycareclubrewards.com/lactaid/?utm_medium=partner&utm_source=lactaid.com&utm_content=tout&utm_campaign=cc_main&_gl=1*1jjrjz9*_ga*MjAwOTYxNTY1My4xNjM2OTk3MjUw*_ga_13VEM6N66E*MTYzNzE2ODg0Ny43LjEuMTYzNzE2ODg5MS4xNg..*_fplc*JTJGRSUyRmRGbFkxdmElMkZPQ0FTRDE3Y2JCOFhieW1QaXp2ekFkSFRHWjZ4NzE1Q2p0JTJGeTU4d2JuM1lFVmcxQjVranklMkJuRXBWcGkyUzdqQVpqTVpnRjhFZlJzb3N6YTRlRGJGSlNTZHQ3R0p5aFVoMjFlRGZjaHJNY1pjS1VUdnB4QSUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Lactaid products with registration.",
                "brand": "Lactaid Fast Act Caplets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b150"
            },
            {
                "_id": "6297bcace0e0db6c2572b155",
                "link": "https://www.lantus.com/sign-up-for-savings",
                "description": "Lantus Valyou Savings Program: Eligible uninsured cash-paying patients will pay $99 per monthly supply of up to 10 vials or packs of SoloStar pens per fill or up to 5 packs of Max SoloStar pens per fill; for additional information contact the program at 833-813-0190.",
                "brand": "Lantus",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b155"
            },
            {
                "_id": "6297bcace0e0db6c2572b15a",
                "link": "https://www.lantus.com/hcp/copay-and-coverage",
                "description": "Lantus Sanofi Copay Program Rebate: Eligible commercially insured patients using a mail-order pharmacy can submit a request for a rebate up to the amount of savings earned with the Savings Card; or additional information contact the program at 866-390-5622.",
                "brand": "Lantus SoloSTAR Pen",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b15a"
            },
            {
                "_id": "6297bcace0e0db6c2572b15f",
                "link": "https://www.lantus.com/hcp/copay-and-coverage",
                "description": "Lantus Sanofi Copay Program Rebate: Eligible commercially insured patients using a mail-order pharmacy can submit a request for a rebate up to the amount of savings earned with the Savings Card; or additional information contact the program at 866-390-5622.",
                "brand": "Lantus U-100",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b15f"
            },
            {
                "_id": "6297bcace0e0db6c2572b164",
                "link": "https://lazanda.com",
                "description": "Lazanda Savings Card: Eligible commercially insured patients may pay no more than $25 per prescription with savings of up to $1000 per fill; offer valid for 14 fills per year;  for additional information contact the program at 844-309-3835.",
                "brand": "Lazanda",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "14000",
                "lifetime_uses": "14",
                "max_saving_transaction": 1000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b164"
            },
            {
                "_id": "6297bcace0e0db6c2572b169",
                "link": "https://zydususa.com/ambrisentan/",
                "description": "Ambrisentan (Generic) Co-Pay Card Program: Eligible commercially insured patients may pay no more than $5 per prescription with savings of up to $100 per 30-day fill; for additional information contact the program at 844-728-3479.",
                "brand": "Letairis",
                "copay": "Yes",
                "bin": "17920",
                "rxpcn": "55101202",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b169"
            },
            {
                "_id": "6297bcace0e0db6c2572b16e",
                "link": "https://myviivcard.com",
                "description": "Lexiva ViiVConnect Savings Card: Eligible commercially insured patients pay $0 per prescription with savings of up to $4800 per year with no monthly limits; for additional information contact the program at 844-588-3288.",
                "brand": "Lexiva",
                "copay": "Yes",
                "bin": "610626",
                "rxpcn": "1016",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b16e"
            },
            {
                "_id": "6297bcace0e0db6c2572b173",
                "link": "https://www.lilettacard.com/start",
                "description": "Liletta Patient Savings Card: Eligible commercially patients may pay $100 for a 52 mg prescription with a maximum savings of $750 per fill; for additional information contact the program at 855-706-4508.",
                "brand": "Liletta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 750,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b173"
            },
            {
                "_id": "6297bcace0e0db6c2572b178",
                "link": "https://mycareclubrewards.com/listerine/?utm_medium=partner&utm_source=listerine.com&utm_content=banner&utm_campaign=cc_main&_gl=1*1srhw77*_ga*MjAwOTYxNTY1My4xNjM2OTk3MjUw*_ga_13VEM6N66E*MTYzNzA4NTM0NS41LjEuMTYzNzA4NjM4MC41OA..*_fplc*NklTRGQ1UjNacSUyQkh0aCUyRlVLJTJGelFxeUZEZVJvWSUyQk5pd1RDcWVwakRqbmZURSUyQndhcyUyRkZmV2ltZ0c5R3pqOFNBR0gxRmRZQ25oSEk2eWhMNUhXNHVyRkliTDE5bWFZcyUyRnNiRTVSZldXaDJ2MDh6d1RlRHlCVzFHblphJTJCa2dkdyUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Listerine products with registration.",
                "brand": "Listerine Gum Therapy Antiseptic Mouthwash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b178"
            },
            {
                "_id": "6297bcace0e0db6c2572b17d",
                "link": "https://www.livalorx.com/savings/coupon/",
                "description": "Livalo Savings Card: Eligible commercially insured patients may pay as little as $18 per 30-day supply with savings of up to $75 per prescription fill; for additional information contact the program at 844-567-9504.",
                "brand": "Livalo",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "900",
                "lifetime_uses": "12",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b17d"
            },
            {
                "_id": "6297bcace0e0db6c2572b182",
                "link": "https://www.allergansavingscard.com/loloestrin",
                "description": "Lo Loestrin Fe Savings Card: Eligible commercially insured patients may pay no more than $25 per month on each of up to 13 fills; for additional information contact the program at 855-439-2817.",
                "brand": "Lo Loestrin Fe",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b182"
            },
            {
                "_id": "6297bcace0e0db6c2572b187",
                "link": "https://lomaira.com/money-saving-offer/",
                "description": "Lomaira Lo-Cost Access Savings Card: Eligible patients may pay no more than $.50 per tablet for duration of treatment; minimum 30-tablet prescription; for additional information contact the program at 844-566-2472.",
                "brand": "Lomaira",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b187"
            },
            {
                "_id": "6297bcace0e0db6c2572b18c",
                "link": "https://www.medimetriks.com/prescription-brands/loprox-cream-kit",
                "description": "Loprox (Cream Kit) Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Loprox",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b18c"
            },
            {
                "_id": "6297bcace0e0db6c2572b191",
                "link": "https://www.loreevxr.com",
                "description": "Loreev XR Copay Card: Eligible commercially insured patients may pay as little as $20 per monthly prescription with a savings of up to $200 per fill; for additional information contact the program at 844-240-3657.\r\n",
                "brand": "Loreev XR",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b191"
            },
            {
                "_id": "6297bcace0e0db6c2572b196",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Lotemax (Ointment) Bausch + Lomb Access Program: Eligible commercially insured patients may pay no more than $25 for each prescription; for additional information contact the program 866-693-4880.",
                "brand": "Lotemax Ointment",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b196"
            },
            {
                "_id": "6297bcace0e0db6c2572b19b",
                "link": "https://www.bauschaccessprogram.com",
                "description": "Lotemax Suspension Bausch + Lomb Access Program: Eligible commercially insured patients may pay no more than $25 per prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Lotemax Suspension",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b19b"
            },
            {
                "_id": "6297bcace0e0db6c2572b1a0",
                "link": "https://www.lotrimin.com/coupon",
                "description": "Sign up and save on any one Lotrimin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Lotrimin AF Jock Itch Antifungal Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1a0"
            },
            {
                "_id": "6297bcace0e0db6c2572b1a5",
                "link": "https://lotronex.com/lotronex-savings-program/",
                "description": "Lotronex Savings Program Card: Most eligible patients will pay no more than $15 per month with savings of up to $500 per prescription; for additional information contact customer service at 844-732-3521 opt 2.",
                "brand": "Lotronex",
                "copay": "Yes",
                "bin": "600471",
                "rxpcn": "7777",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1a5"
            },
            {
                "_id": "6297bcace0e0db6c2572b1aa",
                "link": "https://mycareclubrewards.com/lubriderm/?utm_medium=partner&utm_source=lubriderm.com&utm_content=header&utm_campaign=cc_main&_gl=1*10t4k0d*_ga*MTMzNjU1MjM2My4xNjIxMjg5NDk4*_ga_13VEM6N66E*MTYyMTI4OTQ5Ny4xLjEuMTYyMTI4OTg5My43",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Lubriderm products with registration.",
                "brand": "Lubriderm Therapeutic Lotion Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1aa"
            },
            {
                "_id": "6297bcace0e0db6c2572b1af",
                "link": "https://www.amgenfirststep.com/?referID=AA360",
                "description": "Lumakras Amgen First Step Program Rebate: Eligible commercially insured patients who have already paid for treatment may request a rebate check; for additional information contact the program at 888-657-8371.",
                "brand": "Lumakras",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1af"
            },
            {
                "_id": "6297bcace0e0db6c2572b1b4",
                "link": "https://www.lumizyme.com/patients/patient_services/co-pay_assistance_program",
                "description": "Lumizyme Sanofi Genzyme Co-Pay Assistance Program: Program will cover 100% of out-of-pocket costs (up to a program maximum) for eligible commercially insured patients; once enrolled in the program patients are automatically re-enrolled each calendar year; for additional information contact the program at 800-745-4447, option 3.",
                "brand": "Lumizyme",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1b4"
            },
            {
                "_id": "6297bcace0e0db6c2572b1b9",
                "link": "https://www.lupronped.com/lupron-support-plus.html#savings-card",
                "description": "Lupron Depot-Ped Instant Savings Card: Eligible commercially insured patients pay as little as $10 for 1-month dosing with a savings of up to $250 per prescription and $3,000 per year; pay as little as $10 for 3-month dosing with a savings up to $1,000 per prescription with savings of up to $4,000 per year; for additional information contact the program at 877-832-9755.",
                "brand": "Lupron Depot-PED",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1b9"
            },
            {
                "_id": "6297bcace0e0db6c2572b1be",
                "link": "https://www.pfizerpro.com/product/lyrica/hcp/support/copay-savings",
                "description": "Lyrica Co-Pay Savings Card: Eligible commercially insured patients may pay as little as $4 per prescription with savings of up to $250 monthly (maximum annual savings of $3000); for additional information contact the program at 866-954-1475.",
                "brand": "Lyrica",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1be"
            },
            {
                "_id": "6297bcace0e0db6c2572b1c3",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Lyumjev U-100 KwikPen: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Lyumjev U-100 KwikPen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1c3"
            },
            {
                "_id": "6297bcace0e0db6c2572b1c8",
                "link": "https://www.margenza.com",
                "description": "Margenza Copay Assistance Program: Eligible commercially insured patients may pay $0 per copay or coinsurance up to a maximum of $26,000 during a 12-month period; for additional information contact the program directly at 844-633-6469.",
                "brand": "Margenza",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "26000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1c8"
            },
            {
                "_id": "6297bcace0e0db6c2572b1cd",
                "link": "https://www.mayzent.com/financial-support#MayzentAccessCard",
                "description": "Mayzent Bridge Program: Eligible commercially patients may receive up to 12 months of free medication while waiting for insurance coverage; for additional information contact the program at 877-629-9368.",
                "brand": "Mayzent",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1cd"
            },
            {
                "_id": "6297bcace0e0db6c2572b1d2",
                "link": "https://www.mederma.com/coupons/",
                "description": "Save $5 off any Mederma Stretch Mark Therapy product; one coupon per purchase.",
                "brand": "Mederma Quick Dry Oil",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1d2"
            },
            {
                "_id": "6297bcace0e0db6c2572b1d7",
                "link": "https://www.pfizeroncologytogether.com/patient/signup",
                "description": "Mektovi Pfizer Oncology Together Co-Pay Savings Program (oral products): Eligible commercially insured patients may pay as little as $0 per treatment with savings of up to $25,000 per calendar year; for additional information contact the program at 877-744-5675.",
                "brand": "Mektovi",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1d7"
            },
            {
                "_id": "6297bcace0e0db6c2572b1dc",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Menopur Heart Beat Program: Eligible patients seeking fertility preservation prior to cancer treatment may receive fertility medications at no cost with program; please see website for full qualification requirements; for additional information contact the program at 888-347-3415.",
                "brand": "Menopur",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1dc"
            },
            {
                "_id": "6297bcace0e0db6c2572b1e1",
                "link": "https://www.metamucil.com/en-us/offers",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Metamucil Products!",
                "brand": "Metamucil Fiber Capsules",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1e1"
            },
            {
                "_id": "6297bcace0e0db6c2572b1e6",
                "link": "https://www.metamucil.com/en-us/offers",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Metamucil Products!",
                "brand": "Metamucil Fiber MultiGrain Wafers",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1e6"
            },
            {
                "_id": "6297bcace0e0db6c2572b1eb",
                "link": "https://www.metanx.com",
                "description": "Metanx Brand Direct Health Program: Eligible patients may pay no more than $58 per month regardless of insurance ($174 per 90-day supply); 90-day prescriptions shipped and delivered to your home free; for additional information contact the program at 866-331-6440.",
                "brand": "Metanx",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1eb"
            },
            {
                "_id": "6297bcace0e0db6c2572b1f0",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "MimyX Artesa Labs Advantage Program:Eligible commercially insured/Rx not covered patients may pay as little as $45 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "MimyX",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1f0"
            },
            {
                "_id": "6297bcace0e0db6c2572b1f5",
                "link": "https://www.miralax.com",
                "description": "Register your email address to receive a coupon for any one MiraLAX product; coupons expires 1 month after printing; 1 coupon per purchase.",
                "brand": "MiraLAX Mix-In Pax",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1f5"
            },
            {
                "_id": "6297bcace0e0db6c2572b1fa",
                "link": "https://hcp.monistat.com/coupons",
                "description": "Monistat Product Coupons: Healthcare providers may order coupons for their practice.",
                "brand": "Monistat 3-Day Treatment Combination Pack with pre-filled cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1fa"
            },
            {
                "_id": "6297bcace0e0db6c2572b1ff",
                "link": "https://www.mymissionsupport.com/patient/financial-assistance#copay-assistance-program",
                "description": "Monjuvi My Mission Support Copay Program: Eligible commercially insured patients may pay as little as $0 per prescription; program provides assistance up to $25,000 per calendar year; for more information contact the program at 855-421-6172.",
                "brand": "Monjuvi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1ff"
            },
            {
                "_id": "6297bcace0e0db6c2572b204",
                "link": "https://motofen.com/patient-savings/",
                "description": "Motofen Savings Card: Eligible patients may pay as little as $0 per prescription; for additional information contact the program at 844-218-0448.",
                "brand": "Motofen",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b204"
            },
            {
                "_id": "6297bcace0e0db6c2572b209",
                "link": "https://mycareclubrewards.com/motrin/?utm_medium=partner&utm_source=motrin.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1sghl7t*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjY0NzMxOC40LjEuMTYzNjY0NzMzMy40NQ..*_fplc*WWt2VDMxc2E1JTJCbWhGdlZndzlCTmt6TENXRFQxc0JuWkglMkY2TDRFQnl0SUdXYzc4QmEySTM4dnJhRmE3ak1nSTRqR0RrWHk5bjQzWWFRVDFKSmc0blc5ZVVyVHI2UUVkZURxenBQalBMZllaRjlLSnUxeXduUzdxMFF5Y1VWQSUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Motrin products with registration.",
                "brand": "Motrin IB Migraine Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b209"
            },
            {
                "_id": "6297bcace0e0db6c2572b20e",
                "link": "https://www.rbhcprofessional.com/s/",
                "description": "Mucinex Samples: Healthcare providers may register to order samples for their practice.",
                "brand": "Mucinex Childrens Cold and Flu",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b20e"
            },
            {
                "_id": "6297bcace0e0db6c2572b213",
                "link": "https://www.rbhcprofessional.com/s/",
                "description": "Mucinex Samples: Healthcare providers may register to order samples for their practice.",
                "brand": "Mucinex Childrens FreeFrom Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b213"
            },
            {
                "_id": "6297bcace0e0db6c2572b218",
                "link": "https://www.mucinex.com/pages/savings",
                "description": "Register your email address to receive a $5 coupon for any Mucinex product and future savings offers.",
                "brand": "Mucinex Fast-Max Caplets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b218"
            },
            {
                "_id": "6297bcace0e0db6c2572b21d",
                "link": "https://www.rbhcprofessional.com/s/",
                "description": "Mucinex Samples: Healthcare providers may register to order samples for their practice.",
                "brand": "Mucinex Fast-Max Liqui-Gels Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b21d"
            },
            {
                "_id": "6297bcace0e0db6c2572b222",
                "link": "https://www.rbhcprofessional.com/s/",
                "description": "Mucinex Samples: Healthcare providers may register to order samples for their practice.",
                "brand": "Mucinex Severe Congestion Relief Nasal Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b222"
            },
            {
                "_id": "6297bcace0e0db6c2572b227",
                "link": "https://www.rbhcprofessional.com/s/",
                "description": "Mucinex Samples: Healthcare providers may register to order samples for their practice.",
                "brand": "Mucinex Sinus-Max Liqui-Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b227"
            },
            {
                "_id": "6297bcace0e0db6c2572b22c",
                "link": "https://www.activatethecard.com/viatrisadvocate/muserx/welcome.html",
                "description": "Muse Savings Card: Eligible commercially insured patients may pay as little as $20 on your 1st prescription and as little as $40 for each subsequent fill; maximum savings of $600 per calendar year; for additional information contact the program at 800-657-7613.",
                "brand": "Muse",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "600",
                "lifetime_uses": "12",
                "max_saving_transaction": 50,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b22c"
            },
            {
                "_id": "6297bcace0e0db6c2572b231",
                "link": "https://www.mydayis.com/prescription-support/copay-program",
                "description": "Mydayis Savings Card: Eligible patients may pay as little as $30 per prescription with savings of up to $60 per fill; for additional information contact the program at 844-492-9895.",
                "brand": "Mydayis",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "720",
                "lifetime_uses": "12",
                "max_saving_transaction": 60,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b231"
            },
            {
                "_id": "6297bcace0e0db6c2572b236",
                "link": "https://www.myobloc.com/Cervical-Dystonia/patient-resources#copay",
                "description": "Myobloc Copay Program: Eligible commercially insured patients may receive up to $4,000 each year to help with out-of-pocket expenses for medication and injection procedures; for additional information contact the program at 888-461-2255, Option 3.",
                "brand": "Myobloc",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "4000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b236"
            },
            {
                "_id": "6297bcace0e0db6c2572b23b",
                "link": "https://mytesi.com/napocares/",
                "description": "Mytesi Quick Start Prescription: Eligible commercially insured patients may receive a 15-day supply of medication if experiencing a delay in insurance coverage; patient may be eligible for 1 refill if needed;  for additional information contact the program at 888-527-6276.",
                "brand": "Mytesi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b23b"
            },
            {
                "_id": "6297bcace0e0db6c2572b240",
                "link": "http://www.xspirerx.com/ordering-information/",
                "description": "Nalfon Samples: Healthcare professionals may order sampled for their practice by sending an email to customerservice@xspirerx.com or by faxing a request to 601-510-9318.\r\n",
                "brand": "Nalfon",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b240"
            },
            {
                "_id": "6297bcace0e0db6c2572b245",
                "link": "http://www.naprelanus.com/patient-savings-program.html",
                "description": "Naprelan Patient Savings Card: Eligible commercially insured patients may pay as low as $28 per prescription; for additional information contact the program at 877-447-7979.",
                "brand": "Naprelan",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b245"
            },
            {
                "_id": "6297bcace0e0db6c2572b24a",
                "link": "https://www.nascobal.com/vitaminb12/#patient_resources",
                "description": "Nascobal Copay Card: Eligible patients may pay as little as $0 per month; for additional information contact the program at 866-439-3685.",
                "brand": "Nascobal",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1560",
                "lifetime_uses": "12",
                "max_saving_transaction": 130,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b24a"
            },
            {
                "_id": "6297bcace0e0db6c2572b24f",
                "link": "https://www.natesto.com/support-and-resources/#save-on-natesto",
                "description": "Natesto Cash Option Program: Eligible commercially insured patients/RX not covered may pay $140 per prescription of no less than 20 days (2 dispensers) and not to exceed 30 days (3 dispensers) per prescription dispensed for up to 12 refills; for additional information contact the program at 855-390-0162.",
                "brand": "Natesto",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b24f"
            },
            {
                "_id": "6297bcace0e0db6c2572b254",
                "link": "https://www.alvaprofessional.com/stomach-discomfort",
                "description": "Nauzene Product Samples: Your healthcare provider may request samples by filling out a form online.",
                "brand": "Nauzene Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b254"
            },
            {
                "_id": "6297bcace0e0db6c2572b259",
                "link": "https://www.neevodha.com/healthcare-professionals",
                "description": "NeevoDHA Samples: Heathcare providers may order samples for their office.",
                "brand": "NeevoDHA",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b259"
            },
            {
                "_id": "6297bcace0e0db6c2572b25e",
                "link": "https://mycareclubrewards.com/neosporin/?utm_medium=partner&utm_source=neosporin.com&utm_content=header&utm_campaign=cc_main&_gl=1*1lx5xvo*_ga*MjA5MDY2NzQ5OS4xNjMzMzU4MTUw*_ga_13VEM6N66E*MTYzMzM1ODE1MC4xLjAuMTYzMzM1ODE1MC42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neosporin products with registration.",
                "brand": "Neosporin First Aid Antiseptic Foaming Liquid for Kids",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b25e"
            },
            {
                "_id": "6297bcace0e0db6c2572b263",
                "link": "https://mycareclubrewards.com/neosporin/?utm_medium=partner&utm_source=neosporin.com&utm_content=header&utm_campaign=cc_main&_gl=1*1lx5xvo*_ga*MjA5MDY2NzQ5OS4xNjMzMzU4MTUw*_ga_13VEM6N66E*MTYzMzM1ODE1MC4xLjAuMTYzMzM1ODE1MC42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neosporin products with registration.",
                "brand": "Neosporin Plus Pain Relief Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b263"
            },
            {
                "_id": "6297bcace0e0db6c2572b268",
                "link": "http://wcpharma.com/patient-savings/",
                "description": "Nestabs Women's Choice Savings Coupon: Eligible commercially insured patients may pay no more than $35 per prescription; for additional information contact the program at 800-664-1490.",
                "brand": "Nestabs",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b268"
            },
            {
                "_id": "6297bcace0e0db6c2572b26d",
                "link": "http://wcpharma.com/patient-savings/",
                "description": "Nestabs ONE Women's Choice Savings Coupon: Eligible commercially insured patients may pay no more than $35 per prescription; for additional information contact the program at 800-664-1490.",
                "brand": "Nestabs ONE",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b26d"
            },
            {
                "_id": "6297bcace0e0db6c2572b272",
                "link": "https://amgenfirststep.com",
                "description": "Neulasta Onpro Kit Amgen First Step Program Rebate: Eligible commercially insured patients who have already paid for treatment may request a rebate check; for additional information contact the program at 888-657-8371.",
                "brand": "Neulasta Onpro Kit",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b272"
            },
            {
                "_id": "6297bcace0e0db6c2572b277",
                "link": "https://www.neutrogena.com/offers.html",
                "description": "Sign up and receive 15% off 1st purchase plus free shipping on purchases $25 or more when you shop online for any one Neutrogena Product.",
                "brand": "Neutrogena Body Clear Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b277"
            },
            {
                "_id": "6297bcace0e0db6c2572b27c",
                "link": "https://www.neutrogena.com/offers.html",
                "description": "Sign up and receive 15% off 1st purchase plus free shipping on purchases $25 or more when you shop online for any one Neutrogena Product.",
                "brand": "Neutrogena Gentle Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b27c"
            },
            {
                "_id": "6297bcace0e0db6c2572b281",
                "link": "https://www.neutrogena.com/offers.html",
                "description": "Sign up and receive 15% off 1st purchase plus free shipping on purchases $25 or more when you shop online for any one Neutrogena Product.",
                "brand": "Neutrogena Naturals Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b281"
            },
            {
                "_id": "6297bcace0e0db6c2572b286",
                "link": "https://www.neutrogena.com/care-club.html",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neutrogena products with registration.",
                "brand": "Neutrogena Rapid Clear Acne Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b286"
            },
            {
                "_id": "6297bcace0e0db6c2572b28b",
                "link": "https://www.neutrogena.com/offers.html",
                "description": "Sign up and receive 15% off 1st purchase plus free shipping on purchases $25 or more when you shop online for any one Neutrogena Product.",
                "brand": "Neutrogena T-Gel Hair and Scalp Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b28b"
            },
            {
                "_id": "6297bcace0e0db6c2572b290",
                "link": "https://newskinproducts.com/pages/coupons",
                "description": "Save $2 on any New Skin product; 1 coupon per purchase.",
                "brand": "New-Skin Liquid Bandage Sensitive Skin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b290"
            },
            {
                "_id": "6297bcace0e0db6c2572b295",
                "link": "https://www.nexcare.com/3M/en_US/nexcare/deals-offers/",
                "description": "Sign-up with Nexcare Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "Nexcare Eye Patch Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b295"
            },
            {
                "_id": "6297bcace0e0db6c2572b29a",
                "link": "https://www.purplepill.com/savings.html",
                "description": "Nexium Savings Card: Eligible cash-paying patients may save up to $125 per 30-day prescription; for additional information contact the program at 855-687-2144.",
                "brand": "Nexium",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1500",
                "lifetime_uses": "12",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b29a"
            },
            {
                "_id": "6297bcace0e0db6c2572b29f",
                "link": "https://www.nexlizet.com/nexletol/copay-card",
                "description": "Nexletol Co-Pay Card: Eligible commercially insured patients may pay as little as $10 for up to a 3-month prescription; for additional information contact the program at 855-699-8814.",
                "brand": "Nexletol",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b29f"
            },
            {
                "_id": "6297bcace0e0db6c2572b2a4",
                "link": "https://www.nexviazyme.com/#isi",
                "description": "Nexviazyme Copay Program: Eligible commercially insured patients may have 100% of eligible out-of-pocket drug costs covered up to the program maximum; for addition al information contact the program at 800-745-4447, option 3.",
                "brand": "Nexviazyme",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2a4"
            },
            {
                "_id": "6297bcace0e0db6c2572b2a9",
                "link": "https://www.nicorette.com/coupons.html",
                "description": "Save $5 on any NicoDerm CQ Product (14 count or larger) when you sign-up online with your email address; 1 coupon per purchase.",
                "brand": "NicoDerm CQ Step 1",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2a9"
            },
            {
                "_id": "6297bcace0e0db6c2572b2ae",
                "link": "https://www.nicorette.com/coupons.html",
                "description": "Save $5 on any Nicorette Product (72 count or larger) when you sign-up online with your email address; 1 coupon per purchase.",
                "brand": "Nicorette Coated Lozenge",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2ae"
            },
            {
                "_id": "6297bcace0e0db6c2572b2b3",
                "link": "https://niferex.com/healthcare-professionals/",
                "description": "Niferex  Samples: Healthcare providers may order samples for their practice by filling out an online form or calling 888-612-8466.",
                "brand": "Niferex",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2b3"
            },
            {
                "_id": "6297bcace0e0db6c2572b2b8",
                "link": "https://www.nityr.us/patient/support/patient-liason-services",
                "description": "Nityr Copay Support: Eligible commercially insured patients may pay a $0 copay per prescription; for additional information contact the program at 800-847-8714.",
                "brand": "Nityr",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2b8"
            },
            {
                "_id": "6297bcace0e0db6c2572b2bd",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Nolix Artesa Labs Advantage Program: Eligible commercially insured/Rx not covered patients may pay as little as $45 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Nolix",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2bd"
            },
            {
                "_id": "6297bcace0e0db6c2572b2c2",
                "link": "https://www.norditropin.com/how-we-can-help/product-support.html",
                "description": "Norditropin JumpStart Program: Eligible patients may receive FREE medication if there will be a delay in getting insurance coverage; this program can also be used in any other gap in therapy such as a change in financial situation or unexpected situations; for additional information contact the program at 888-668-6444.",
                "brand": "Norditropin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2c2"
            },
            {
                "_id": "6297bcace0e0db6c2572b2c7",
                "link": "https://www.pfizerpro.com",
                "description": "Norpace Samples: Your healthcare provider may request samples from PfizerPro by visting the website or calling 800-505-4426.",
                "brand": "Norpace",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2c7"
            },
            {
                "_id": "6297bcace0e0db6c2572b2cc",
                "link": "https://norvir.com/savings",
                "description": "Norvir Savings Card Rebate Form: Eligible commercially insured patients may submit a rebate request form if they are unable to use the savings card at their pharmacy; for additional information contact the program at 800-364-4767.",
                "brand": "Norvir",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2cc"
            },
            {
                "_id": "6297bcace0e0db6c2572b2d1",
                "link": "https://www.novoeight.com/how-we-can-help/product-support.html",
                "description": "Novoeight Co-pay Assistance Program: Eligible commercially insured patients may pay as little as $0 per fill and may save up to $12,000 per calendar year; for additional information contact the program at 844-668-6732.",
                "brand": "Novoeight",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2d1"
            },
            {
                "_id": "6297bcace0e0db6c2572b2d6",
                "link": "https://www.novocare.com/insulin/my99insulin.html?src=100001849",
                "description": "Novolin70/30 FlexPen My$99Insulin Card: Eligible patients pay $99 per monthly prescription (up to 3 vials or 2 packs of pens) of Novo Nordisk insulin products; offer may be used once a month during a calendar year; for additional information contact the program at 888-910-0632.",
                "brand": "Novolin 70-30 FlexPen",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2d6"
            },
            {
                "_id": "6297bcace0e0db6c2572b2db",
                "link": "https://www.mynovoinsulin.com/insulin-products/novolog/savings.html",
                "description": "NovoLog Novo Nordisk Instant Savings Card: Eligible commercially insured patients may pay no more than $25 per 30-day prescription with savings of up to $100 per fill; offer valid 24 times after card activation; for additional information contact the program at 877-304-6855.",
                "brand": "NovoLog Mix 70-30",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2db"
            },
            {
                "_id": "6297bcace0e0db6c2572b2e0",
                "link": "https://amgenfirststep.com/register-card",
                "description": "Nplate Amgen First Step Program: Eligible commercially insured patients may save on their out-of-pocket medication costs; for additional information contact the program at 888-427-7478.",
                "brand": "Nplate",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2e0"
            },
            {
                "_id": "6297bcace0e0db6c2572b2e5",
                "link": "https://www.nucala.com/severe-asthma/savings-and-support/co-pay-program/",
                "description": "Nucala $0 Co-pay Program: Eligible patients may pay $0 copay with savings of up to $15,000 per year; for additional information contact the program at 844-468-2252.",
                "brand": "Nucala",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2e5"
            },
            {
                "_id": "6297bcace0e0db6c2572b2ea",
                "link": "https://www.nulibry.com/forging-bridges-affordability-options",
                "description": "Nulibry Copay Assistance Program: Eligible commercially insured patients may save up to $100,000 per year; for additional information contact the program at 888-552-7434.",
                "brand": "Nulibry",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "100000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2ea"
            },
            {
                "_id": "6297bcace0e0db6c2572b2ef",
                "link": "https://www.enfamil.com/nutramigen-savings-and-support-program/",
                "description": "Join Nutramigen Savings and Support and save up to $130 with valuable offers, coupons and free samples with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Nutramigen with Enflora LGG",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2ef"
            },
            {
                "_id": "6297bcace0e0db6c2572b2f4",
                "link": "https://www.nuvessa.com",
                "description": "Nuvessa Copay Card: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 844-558-2650.",
                "brand": "Nuvessa",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2f4"
            },
            {
                "_id": "6297bcace0e0db6c2572b2f9",
                "link": "https://www.pfizercopay.com/",
                "description": "Nyvepria Pfizer Oncology Together Co-Pay Savings Program for Injectables: Eligible commercially insured patients may pay as little as $0 per treatment; maximum savings limits per calendar year apply; for more information contact the program at 877-744-5675.",
                "brand": "Nyvepria",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2f9"
            },
            {
                "_id": "6297bcace0e0db6c2572b2fe",
                "link": "http://obcomplete.com/coupons/",
                "description": "OB Complete Savings Card: Eligible patients may pay $10 per 30-day prescription with savings of up to $50 per fill; for additional information contact the program at 844-727-5540.",
                "brand": "OB Complete Premier",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "600",
                "lifetime_uses": "12",
                "max_saving_transaction": 50,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2fe"
            },
            {
                "_id": "6297bcace0e0db6c2572b303",
                "link": "https://www.ocuvite.com/buy-now",
                "description": "Receive a $3 coupon off any one Ocuvite product; email address required; 1 coupon per purchase.",
                "brand": "Ocuvite Blue Light",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b303"
            },
            {
                "_id": "6297bcace0e0db6c2572b308",
                "link": "https://www.ocuvite.com/buy-now",
                "description": "Receive a $3 coupon off any one Ocuvite product; email address required; 1 coupon per purchase.",
                "brand": "Ocuvite Lutein 25",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b308"
            },
            {
                "_id": "6297bcace0e0db6c2572b30d",
                "link": "https://www.alksavings.com/cb/access/crp/aLkYd2j78Vbb/fid.jsp?q=51G5OI5442CUDK4PLWTLX5&f=IMPX6IG-274EMWRGK6802#/app/layout/home",
                "description": "Odactra ALK Savings Card Program: Eligible commercially insured patients/RX not covered may pay $100 per 30-day prescription if using a retail pharmacy; valid for 12 uses or $3,200 per year (whichever comes first); for additional information contact the program at 800-325-7354.",
                "brand": "Odactra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3200",
                "lifetime_uses": "12",
                "max_saving_transaction": 265,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b30d"
            },
            {
                "_id": "6297bcace0e0db6c2572b312",
                "link": "https://www.ogivri.com/en/patient-assitant/copay",
                "description": "Ogivri MylandAdvocate Co-pay Assistance Program: Eligible commercially insured patients may save a maximum of $25,000 per 12-month period; for additional information contact the program at 833-695-2623.",
                "brand": "Ogivri",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.34,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b312"
            },
            {
                "_id": "6297bcace0e0db6c2572b317",
                "link": "https://www.omnipod.com/is-omnipod-right-for-me",
                "description": "Omnipod Insulin Management System FREE Trial: Receive a free 30-day trial with sign-up; for additional information contact the program at 800-591-3455.",
                "brand": "Omnipod",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b317"
            },
            {
                "_id": "6297bcace0e0db6c2572b31c",
                "link": "https://www.oneaday.com/coupons",
                "description": "Save $1 on any one One-A-Day product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "One-A-Day Kids Multivitamin products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b31c"
            },
            {
                "_id": "6297bcace0e0db6c2572b321",
                "link": "https://www.oneaday.com/coupons",
                "description": "Save $1 on any one One-A-Day product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "One-A-Day Womens Multivitamin products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b321"
            },
            {
                "_id": "6297bcace0e0db6c2572b326",
                "link": "https://store.orderfront.com/OneTouch/Account/LogOn?ReturnUrl=%2fOneTouch",
                "description": "OneTouch Verio Samples: Healthcare providers may request FREE samples online.",
                "brand": "OneTouch Verio Flex",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b326"
            },
            {
                "_id": "6297bcace0e0db6c2572b32b",
                "link": "https://www.orthorxaccess.com/",
                "description": "Onexton Rx Access Program: Eligible uninsured/cash-pay patients may pay $65 per fill; valid for 2 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Onexton",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "2",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b32b"
            },
            {
                "_id": "6297bcace0e0db6c2572b330",
                "link": "https://www.panfoundation.org/",
                "description": "PAN Foundation",
                "brand": "Onglyza",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0.00",
                "lifetime_uses": "12",
                "max_saving_transaction": null,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b330"
            },
            {
                "_id": "6297bcace0e0db6c2572b335",
                "link": "https://www.bmsaccesssupport.bmscustomerconnect.com/patient/financial-support",
                "description": "Onureg BMS Oncology Co-Pay Assistance Program: Eligible commercially insured patients may pay no more than $10 per 30-day supply with a savings of up to $15,000 per calendar year; for additional information contact the program at 800-861-0048.",
                "brand": "Onureg",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b335"
            },
            {
                "_id": "6297bcace0e0db6c2572b33a",
                "link": "https://pah.janssencarepath.com/patient/opsumit/patient-support.html",
                "description": "Opsumit Janssen PAH Link Program: Eligible commercially insured patients who are experiencing an insurance delay of more than 5 business days  or have been denied coverage for your treatment may receive medication at no cost until coverage has been received; for additional information contact the program at 866-228-3546.",
                "brand": "Opsumit",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b33a"
            },
            {
                "_id": "6297bcace0e0db6c2572b33f",
                "link": "https://www.eyefile.com/theeyesolution/Register",
                "description": "Save $2 on your next purchase of any one Opti-Free Replenish Rewetting Drops (10 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Opti-Free Replenish Rewetting Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b33f"
            },
            {
                "_id": "6297bcace0e0db6c2572b344",
                "link": "https://copay.oralair.com/",
                "description": "Oralair Co-Pay Assistance Card: Eligible commercially insured patients may pay as little as $15 per 30-day prescription; card valid up to 10 times; for additional information contact the program 866-885-7520.",
                "brand": "Oralair",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "10",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b344"
            },
            {
                "_id": "6297bcace0e0db6c2572b349",
                "link": "https://www.orenitram.com/cost-assistance-resources#assist",
                "description": "Orenitram Co-Pay Assistance Card Program: Most eligible commercially insured patients may pay no more than $5 per monthly prescription with a maximum savings of $5000; for additional information contact the program at 877-864-8437.",
                "brand": "Orenitram",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "60000",
                "lifetime_uses": "12",
                "max_saving_transaction": 5000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b349"
            },
            {
                "_id": "6297bcace0e0db6c2572b34e",
                "link": "https://www.orgovyx.com/support-and-resources/#paying-for-orgovyx",
                "description": "Orgovym Free Trial Program: Eligible NEW patients may receive a 2-month supply of medication at no cost; for additional information contact the program at 833-674-6899.",
                "brand": "Orgovyx",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "2",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b34e"
            },
            {
                "_id": "6297bcace0e0db6c2572b353",
                "link": "https://www.vertexgps.com/about-vertex-gps#overviewVid",
                "description": "Orkambi Vertex GPS Financial Assistance Program: Eligible commercially insured patients may pay as little as $15 per prescription; for additional information contact the program at 877-752-5933 (press 2).",
                "brand": "Orkambi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b353"
            },
            {
                "_id": "6297bcace0e0db6c2572b358",
                "link": "https://www.boironusa.com/request-a-sample-form/",
                "description": "Oscillo Samples: Healthcare professionals may request FREE samples by filling out a form online.",
                "brand": "Oscillo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b358"
            },
            {
                "_id": "6297bcace0e0db6c2572b35d",
                "link": "https://www.osphena.com/savings",
                "description": "Osphena At Home Delivery Program: Eligible commercially insured patients pay as little as $35 for 30 tablets or $90 for 90 tablets; for additional information contact the program at 844-716-4663.",
                "brand": "Osphena",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b35d"
            },
            {
                "_id": "6297bcace0e0db6c2572b362",
                "link": "https://www.osteobiflex.com/ambassadors-club/",
                "description": "Osteo Bi-Flex Products: Join the Ambassador�s Club to receive coupons based on your past purchases.",
                "brand": "Osteo Bi-Flex Thera-Soak Epsom Salt",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b362"
            },
            {
                "_id": "6297bcace0e0db6c2572b367",
                "link": "https://www.wraser-direct.com/",
                "description": "Otovel WraSer-Direct Universal Coupon Program: Eligible commercially insured patients may pay no more than $15 per prescription when using a WraSer Pharmacy Partner; for additional information contact the program at 601-605-0664.",
                "brand": "Otovel",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b367"
            },
            {
                "_id": "6297bcace0e0db6c2572b36c",
                "link": "https://ovace.com",
                "description": "Ovace Savings Card: Eligible commercially insured patients may pay as little as $20 per fill on each of up to 12 prescriptions; for additional information contact the program at 347-766-9883.",
                "brand": "Ovace Plus Products",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b36c"
            },
            {
                "_id": "6297bcace0e0db6c2572b371",
                "link": "https://www.oxtellarxr.com/co-pay-savings-program",
                "description": "Oxtellar XR Co-pay Savings Program: Eligible patients may pay $0 each prescription with savings of up to $250; for additional information contact the program at 866-398-0833.",
                "brand": "Oxtellar XR",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b371"
            },
            {
                "_id": "6297bcace0e0db6c2572b376",
                "link": "https://www.novomedlink.com/diabetes/samples.html",
                "description": "Ozempic Samples: Healthcare providers may submit a sample request every 30 days.",
                "brand": "Ozempic 0.5 mg",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b376"
            },
            {
                "_id": "6297bcace0e0db6c2572b37b",
                "link": "https://www.palynziq.com/find-pku-support#copay",
                "description": "Palynziq Co-Pay Assistance Program: Eligible commercially insured patients may pay $0 on each of up to 3 prescriptions fills; for additional information contact the program at 833-758-2273.",
                "brand": "Palynziq",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "3",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b37b"
            },
            {
                "_id": "6297bcace0e0db6c2572b380",
                "link": "https://www.activatethecard.com/7974/",
                "description": "Pandel Ani Derm Copay Savings Program: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 844-396-8097.",
                "brand": "Pandel",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b380"
            },
            {
                "_id": "6297bcace0e0db6c2572b385",
                "link": "https://www.parodontax.com/coupon/",
                "description": "Register your email address to receive a coupon for any parodontax toothpaste product.",
                "brand": "Parodontax Active Gum Repair",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b385"
            },
            {
                "_id": "6297bcace0e0db6c2572b38a",
                "link": "https://www.eyefile.com/TheEyeSolution/Register/0?sid=CLRCR0513",
                "description": "Save $5 on your next purchase of Pataday products with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Pataday Once Daily Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b38a"
            },
            {
                "_id": "6297bcace0e0db6c2572b38f",
                "link": "https://pediasure.com/resources-and-rewards/pediasure-coupons",
                "description": "Sign up for the PediaSure Support2Grow program and save up to $100 with receive special offers.",
                "brand": "PediaSure Enteral 1.5 Cal w-Fiber",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b38f"
            },
            {
                "_id": "6297bcace0e0db6c2572b394",
                "link": "https://pediasure.com/resources-and-rewards/pediasure-coupons",
                "description": "Sign up for the PediaSure Support2Grow program and save up to $100 with receive special offers.",
                "brand": "PediaSure Harvest",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b394"
            },
            {
                "_id": "6297bcace0e0db6c2572b399",
                "link": "https://www.incytecares.com/pemazyre/home.aspx?_ga=2.223391707.1747778669.1587394937-2123654978.1587394937",
                "description": "Pemazyre IncyteCARES Temporary Access Program: Eligible commercially insured patient may receive a FREE 30-day supply if experiencing a delay in coverage; for additional information contact the program at 855-452-5234.",
                "brand": "Pemazyre",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b399"
            },
            {
                "_id": "6297bcace0e0db6c2572b39e",
                "link": "https://mycareclubrewards.com/pepcid/?utm_medium=partner&utm_source=pepcid.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1f8mxe6*_ga*MTc3MDUxNTg0OS4xNjMzMDE2OTQ4*_ga_13VEM6N66E*MTYzMzAxNjk0OC4xLjEuMTYzMzAxNzAzOC41OQ..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Pepcid products with registration.",
                "brand": "Pepcid AC Maximum Strength",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b39e"
            },
            {
                "_id": "6297bcace0e0db6c2572b3a3",
                "link": "https://www.copayassistancenow.com/eligibility",
                "description": "Perjeta Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Perjeta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3a3"
            },
            {
                "_id": "6297bcace0e0db6c2572b3a8",
                "link": "https://www.pertzyecf.com/chiesi-caredirect/",
                "description": "Pertzye Free Trial Program: Eligible patients may receive a free 21-day supply directly shipped to your home; for additional information contact the program at 855-883-1461.",
                "brand": "Pertzye",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3a8"
            },
            {
                "_id": "6297bcace0e0db6c2572b3ad",
                "link": "https://www.phillipsdigestive.com/coupon/",
                "description": "Register your email address to receive a coupon for any one Phillips' product; 1 coupon per purchase.",
                "brand": "Phillips Colon Health Probiotic Capsules",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3ad"
            },
            {
                "_id": "6297bcace0e0db6c2572b3b2",
                "link": "https://www.phillipsdigestive.com/coupon/",
                "description": "Register your email address to receive a coupon for any one Phillips' product; 1 coupon per purchase.",
                "brand": "Phillips Stool Softner Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3b2"
            },
            {
                "_id": "6297bcace0e0db6c2572b3b7",
                "link": "https://planb.inmarrebates.com/#/offers",
                "description": "Plan B One-Step Rebate: Receive $10 back on this product when you take and submit a picture of your receipt; your rebate will be sent to your to your PayPal account; for additional information contact 877-322-8355.",
                "brand": "Plan B One-Step",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "2",
                "max_saving_transaction": 10,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3b7"
            },
            {
                "_id": "6297bcace0e0db6c2572b3bc",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Plexion Artesa Labs Advantage Program: Eligible commercially insured/Rx not covered patients may pay as little as $45 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Plexion Products",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3bc"
            },
            {
                "_id": "6297bcace0e0db6c2572b3c1",
                "link": "https://www.polident.com/en-us/save-now/polident-coupon/",
                "description": "Save $1 on any one Polident product with registration; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Polident 3-Minute Denture Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3c1"
            },
            {
                "_id": "6297bcace0e0db6c2572b3c6",
                "link": "https://www.polident.com/en-us/save-now/polident-coupon/",
                "description": "Save $1 on any one Polident product with registration; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Polident Overnight Whitening",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3c6"
            },
            {
                "_id": "6297bcace0e0db6c2572b3cb",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/products/ortho-cleanser/",
                "description": "Polident ProGuard Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Polident Pro Guard and Retainer Foam Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3cb"
            },
            {
                "_id": "6297bcace0e0db6c2572b3d0",
                "link": "https://www.polident.com/en-us/save-now/propartial-options/",
                "description": "Save $1 on your purchase of any Polident ProPartial product by registering your email address; one coupon per purchase.",
                "brand": "Polident ProPartials Foam Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3d0"
            },
            {
                "_id": "6297bcace0e0db6c2572b3d5",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/products/propartial/",
                "description": "Polident ProPartials Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Polident ProPartials Mouthwash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3d5"
            },
            {
                "_id": "6297bcace0e0db6c2572b3da",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/overview/?utm_source=google&utm_medium=cpc&utm_term=super%20poligrip&utm_campaign=GS%20-%20Branded_Poligrip%20EX_DP&gclid=Cj0KCQiA962BBhCzARIsAIpWEL0O5zEg5LMkWP-xbEDatBaEd896PMS8BoY0ynLITYi-fwgKKWPSrcUaAiBqEALw_wcB&gclsrc=aw.ds",
                "description": "Polident Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Polident Ultra Power Denture Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3da"
            },
            {
                "_id": "6297bcace0e0db6c2572b3df",
                "link": "https://www.ponvory.com/cost-and-savings#private-commercial-insurance",
                "description": "Ponvory Janssen CarePath Pretest Rebate Program: Eligible commercially insured patients may pay $0 after rebate for pretests and/or 1st dose monitoring when starting treatment; program limited to initial pretests and monitoring with a $1,500 maximum program benefit per lifetime; program does not cover the cost of medication; for more information contact the program at 877-227-3728.",
                "brand": "Ponvory",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3df"
            },
            {
                "_id": "6297bcace0e0db6c2572b3e4",
                "link": "https://www.praluent.com/starting-and-paying-for-praluent-rx/",
                "description": "Praluent MyPraluent Copay Card Rebate: Eligible commercially insured patients may be eligible for a rebate if they paid for deductibles, copays or  coinsurance out-of-pocket;for more information contact the program at 844-772-5836.",
                "brand": "Praluent",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3500",
                "lifetime_uses": "12",
                "max_saving_transaction": 291.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3e4"
            },
            {
                "_id": "6297bcace0e0db6c2572b3e9",
                "link": "https://sebelapharma.com/savings-programs/",
                "description": "Pramosone Co-Pay Savings Card Rebate: If the pharmacy is unable to process the Savings Card cash-paying patients may submit a rebate request in order to reimbursed their out-of-pocket cost over $75; rebate instructions are found at the bottom of the Savings Card; for additional information contact the program at 844-728-3479.",
                "brand": "Pramosone",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3e9"
            },
            {
                "_id": "6297bcace0e0db6c2572b3ee",
                "link": "https://www.premarin.com/premarin-savings",
                "description": "Premarin Savings Card Rebate (Tablets): If using a mail-order pharmacy or a pharmacy does not accept the Savings Card eligible commercially insured patients may submit a rebate; scroll down to Terms & Conditions to review the rebate instructions; for additional information contact the program at 866-410-3700.",
                "brand": "Premarin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "660",
                "lifetime_uses": "12",
                "max_saving_transaction": 55,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3ee"
            },
            {
                "_id": "6297bcace0e0db6c2572b3f3",
                "link": "https://savings.vitamedmdrx.com/activate/index.html",
                "description": "Prena1 True vitaMedMD Savings Program: Eligible commercially insured patients may pay $25 or less per 30-day prescription; for additional information contact the program at 855-993-2665.",
                "brand": "Prena1 True",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3f3"
            },
            {
                "_id": "6297bcace0e0db6c2572b3f8",
                "link": "https://avionrx.com/healthcare-professionals/",
                "description": "Prenate Elite Samples: Healthcare providers may order samples for their practice by filling out a form online or calling 888-612-8466.",
                "brand": "Prenate Elite",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3f8"
            },
            {
                "_id": "6297bcace0e0db6c2572b3fd",
                "link": "https://prenate.com/patient-savings/",
                "description": "Prenate Essential eVoucherRx Program: Eligible patients pay no more than $20 per prescription fill at participating pharmacies; for additional information contact the program at 888-612-8466.",
                "brand": "Prenate Essential with Quatrefolic",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3fd"
            },
            {
                "_id": "6297bcace0e0db6c2572b157",
                "link": "https://www.lantus.com/hcp/copay-and-coverage",
                "description": "Lantus Sanofi Copay Program Rebate: Eligible commercially insured patients using a mail-order pharmacy can submit a request for a rebate up to the amount of savings earned with the Savings Card; or additional information contact the program at 866-390-5622.",
                "brand": "Lantus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b157"
            },
            {
                "_id": "6297bcace0e0db6c2572b15c",
                "link": "https://www.lantus.com/hcp/samples-and-resources/resources-for-patients",
                "description": "Lantus SoloSTAR Pen Samples: Healthcare providers may request samples for their practice by registering online and filling out an order request.",
                "brand": "Lantus SoloSTAR Pen",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b15c"
            },
            {
                "_id": "6297bcace0e0db6c2572b161",
                "link": "https://www.alle.com/upgrade",
                "description": "Latisse Alle Points Program: Register and earn points redeemable for dollars OFF certain in-office treatments; also receive personalized special offers; for additional information contact the program at 888-912-1572.",
                "brand": "Latisse",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b161"
            },
            {
                "_id": "6297bcace0e0db6c2572b166",
                "link": "https://www.lemtrada.com/resources-and-support/financial-support",
                "description": "Lemtrada Co-Pay Program: Eligible commercially insured patients may pay $0 per prescription and up to $100 per day for charges related to administration of infusion; for additional information contact the program at 855-676-6326.",
                "brand": "Lemtrada",
                "copay": "Yes",
                "bin": "543210",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b166"
            },
            {
                "_id": "6297bcace0e0db6c2572b16b",
                "link": "https://www.pfizerpro.com/request-samples",
                "description": "Levoxyl Samples: Healthcare providers may order samples by visiting the PfizerPro website or calling 800-505-4426.",
                "brand": "Levoxyl",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b16b"
            },
            {
                "_id": "6297bcace0e0db6c2572b170",
                "link": "https://www.libtayo.com/cscc/libtayo-surround",
                "description": "Libtayo Surround Commercial Copay Program: Eligible commercially insured patients may pay $0 copay per prescription with savings of up to $25,000 per year; for additional assistance contact the program at 877-542-8296.",
                "brand": "Libtayo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b170"
            },
            {
                "_id": "6297bcace0e0db6c2572b175",
                "link": "https://www.abbvieaccess.com/linzess",
                "description": "Linzess Samples: Healthcare providers may request samples for their practice by logging onto the website or submitting an order form via fax to 877-477-1258.",
                "brand": "Linzess",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b175"
            },
            {
                "_id": "6297bcace0e0db6c2572b17a",
                "link": "https://mycareclubrewards.com/listerine/?utm_medium=partner&utm_source=listerine.com&utm_content=banner&utm_campaign=cc_main&_gl=1*1srhw77*_ga*MjAwOTYxNTY1My4xNjM2OTk3MjUw*_ga_13VEM6N66E*MTYzNzA4NTM0NS41LjEuMTYzNzA4NjM4MC41OA..*_fplc*NklTRGQ1UjNacSUyQkh0aCUyRlVLJTJGelFxeUZEZVJvWSUyQk5pd1RDcWVwakRqbmZURSUyQndhcyUyRkZmV2ltZ0c5R3pqOFNBR0gxRmRZQ25oSEk2eWhMNUhXNHVyRkliTDE5bWFZcyUyRnNiRTVSZldXaDJ2MDh6d1RlRHlCVzFHblphJTJCa2dkdyUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Listerine products with registration.",
                "brand": "Listerine Original Antiseptic Mouthwash Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b17a"
            },
            {
                "_id": "6297bcace0e0db6c2572b17f",
                "link": "https://www.livalorx.com/savings/coupon/",
                "description": "Livalo Savings Card Rebate: Eligible commercially insured/cash-paying patients may request a rebate if they use a mail order pharmacy; or additional information contact the program at 844-567-9504.",
                "brand": "Livalo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "900",
                "lifetime_uses": "12",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b17f"
            },
            {
                "_id": "6297bcace0e0db6c2572b184",
                "link": "https://www.lokelma-hcp.com/access-savings.html",
                "description": "Lokelma Free Trial Offer: Eligible patients may receive one 30-day supply for FREE; for additional information contact the program at 866-494-8080.",
                "brand": "Lokelma",
                "copay": "No",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b184"
            },
            {
                "_id": "6297bcace0e0db6c2572b189",
                "link": "https://www.sunovionprofile.com/lonhala-magnair/physician-patient-resources/savings-programs/",
                "description": "Lonhala Magnair Free Trial Offer: Eligible patients may receive a 30-day free trial; limit 1 voucher per patient per year; for additional information contact the program at 844-276-8262.",
                "brand": "Lonhala Magnair",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b189"
            },
            {
                "_id": "6297bcace0e0db6c2572b18e",
                "link": "https://www.lorbrena.com/financial-assistance-info",
                "description": "Lorbrena Pfizer Oncology Together Mail-In Rebate: If a pharmacy does not accept the Savings Card the patient may submit a request for a rebate in connection with the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Lorbrena",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.33,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b18e"
            },
            {
                "_id": "6297bcace0e0db6c2572b193",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Lotemax (Gel) Bausch + Lomb Access Program: Eligible commercially insured patients may pay no more than $25 per prescription; for additional information contact the program 866-693-4880.",
                "brand": "Lotemax Gel",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b193"
            },
            {
                "_id": "6297bcace0e0db6c2572b198",
                "link": "https://bauschlombpartd.copaysavingsprogram.com",
                "description": "Lotemax SM Part D Coupon Program:Eligible patients may pay no more than $60 for each prescription fill; patients must have Medicare Part D or a Medicare Advantage prescription drug plan; for additional information contact the program at 800-795-1091.",
                "brand": "Lotemax SM",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b198"
            },
            {
                "_id": "6297bcace0e0db6c2572b19d",
                "link": "https://www.lotrimin.com/coupon",
                "description": "Sign up and save on any one Lotrimin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Lotrimin AF Athletes Foot Antifungal Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b19d"
            },
            {
                "_id": "6297bcace0e0db6c2572b1a2",
                "link": "https://www.lotrimin.com/coupon",
                "description": "Sign up and save on any one Lotrimin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Lotrimin Daily Sweat and Odor Control Medicated Foot Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1a2"
            },
            {
                "_id": "6297bcace0e0db6c2572b1a7",
                "link": "https://mycareclubrewards.com/lubriderm/?utm_medium=partner&utm_source=lubriderm.com&utm_content=header&utm_campaign=cc_main&_gl=1*10t4k0d*_ga*MTMzNjU1MjM2My4xNjIxMjg5NDk4*_ga_13VEM6N66E*MTYyMTI4OTQ5Ny4xLjEuMTYyMTI4OTg5My43",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Lubriderm products with registration.",
                "brand": "Lubriderm Daily Moisture Lotion Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1a7"
            },
            {
                "_id": "6297bcace0e0db6c2572b1ac",
                "link": "https://lucemyra.com/savings-program-terms-and-conditions/",
                "description": "Lucemyra eVoucherRx Program: Eligible commercially insured patients may pay as little as $0 per prescription fill at participating pharmacies;  for additional information contact the program at 800-388-2316.",
                "brand": "Lucemyra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1ac"
            },
            {
                "_id": "6297bcace0e0db6c2572b1b1",
                "link": "https://www.lumifydrops.com",
                "description": "Register your email to receive a coupon for your next purchase of Lumify; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Lumify",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1b1"
            },
            {
                "_id": "6297bcace0e0db6c2572b1b6",
                "link": "https://www.luprongyn.com/savings-and-support",
                "description": "Lupron Depot Savings Card (Endometriosis Patients): Eligible commercially insured patients with Endometriosis may pay as little as $10 per dose with savings up to $125 per 3.75 mg dose; for additional assistance contact the program at 855-587-7663.",
                "brand": "Lupron Depot",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1b6"
            },
            {
                "_id": "6297bcace0e0db6c2572b1bb",
                "link": "https://www.lybalvi.com",
                "description": "Lybalvi Co-pay Savings Program: Eligible commercially insured patients may pay $0 for the 1st 3 fills then pay as little as $20 per 30-day supply; maximum savings of $450 per 30-day fill; for additional information contact the program at 855-820-9624.",
                "brand": "Lybalvi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1bb"
            },
            {
                "_id": "6297bcace0e0db6c2572b1c0",
                "link": "https://www.lyumjev.com/savings",
                "description": "Lyumjev Savings Card: Eligible commercially insured patients may pay as little as $25 per 30-day prescription with a maximum savings of $100 per fill; offer for up to 12 months of medication; for additional information contact the program at 800-545-5979.",
                "brand": "Lyumjev",
                "copay": "",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1c0"
            },
            {
                "_id": "6297bcace0e0db6c2572b1c5",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Lyumjev U-200 KwikPen: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Lyumjev U-200 KwikPen",
                "copay": "No",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1c5"
            },
            {
                "_id": "6297bcace0e0db6c2572b1ca",
                "link": "https://www.mavenclad.com/en/support-and-resources/financial-assistance.html",
                "description": "Mavenclad Co-pay Assistance Program: Eligible commercially insured patients may pay as little as $0 per prescription; for additional information contact the program at 877-447-3243.",
                "brand": "Mavenclad",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1ca"
            },
            {
                "_id": "6297bcace0e0db6c2572b1cf",
                "link": "https://www.mederma.com/coupons/",
                "description": "Save $3 off any Mederma for Kids product; one coupon per purchase.",
                "brand": "Mederma for Kids",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1cf"
            },
            {
                "_id": "6297bcace0e0db6c2572b1d4",
                "link": "https://www.mederma.com/coupons/",
                "description": "Save $5 off any Mederma Stretch Mark Therapy product; one coupon per purchase.",
                "brand": "Mederma Stretch Marks Therapy",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1d4"
            },
            {
                "_id": "6297bcace0e0db6c2572b1d9",
                "link": "https://www.pfizermedicalinformation.com/en-us/menest?section=indications-usage",
                "description": "Menest Samples: Your healthcare provider may order samples by visiting the PfizerPro website or calling 800-505-4426.",
                "brand": "Menest",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1d9"
            },
            {
                "_id": "6297bcace0e0db6c2572b1de",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Menopur Heart for Heroes Program: Eligible veterans may receive fertility medications at no cost with program; please see website for qualification requirements; for additional information contact the program at 800-515-3784.",
                "brand": "Menopur",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1de"
            },
            {
                "_id": "6297bcace0e0db6c2572b1e3",
                "link": "https://www.metamucil.com/en-us/two-week-challenge",
                "description": "Save $2 off any Metamucil Fiber Product by registering your email; one coupon per purchase.",
                "brand": "Metamucil Fiber Gummies",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1e3"
            },
            {
                "_id": "6297bcace0e0db6c2572b1e8",
                "link": "https://www.metamucil.com/en-us/two-week-challenge",
                "description": "Save $2 off any Metamucil Fiber Product by registering your email; one coupon per purchase.",
                "brand": "Metamucil Fiber Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1e8"
            },
            {
                "_id": "6297bcace0e0db6c2572b402",
                "link": "https://avionrx.com/healthcare-professionals/",
                "description": "Prenate Pixie Samples: Healthcare providers may order samples for their practice by filling out a form online or calling 888-612-8466.",
                "brand": "Prenate Pixie",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b402"
            },
            {
                "_id": "6297bcace0e0db6c2572b1ed",
                "link": "https://www.midol.com/coupon",
                "description": "$1 coupon off any Midol product; 1 coupon per purchase.",
                "brand": "Midol Complete Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1ed"
            },
            {
                "_id": "6297bcace0e0db6c2572b407",
                "link": "https://www.preparationh.com/coupons-and-special-offers/",
                "description": "Register your email address to receive a coupon for any one Preparation H product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Preparation H Medicated Wipes for Women",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b407"
            },
            {
                "_id": "6297bcace0e0db6c2572b40c",
                "link": "https://www.preparationh.com/coupons-and-special-offers/",
                "description": "Register your email address to receive a coupon for any one Preparation H product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Preparation H Soothing Relief Anti-Itch Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b40c"
            },
            {
                "_id": "6297bcace0e0db6c2572b411",
                "link": "https://www.preservision.com/Get-Coupons/Get-My-Coupon",
                "description": "$4 coupon off any PreserVision Eye Vitamin and Mineral Supplement 60 count or larger; 1 coupon per purchase.",
                "brand": "PreserVision Eye Vitamin AREDS 2 Formula and Multivitamin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b411"
            },
            {
                "_id": "6297bcace0e0db6c2572b416",
                "link": "https://www.preservision.com/eyecare-professionals/samples-coupons",
                "description": "PreserVision Samples: Eyecare professionals may request samples of PreserVision Eye Vitamins for their practice by calling 844-258-4837.",
                "brand": "PreserVision Eye Vitamin AREDS 2 Formula Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b416"
            },
            {
                "_id": "6297bcace0e0db6c2572b41b",
                "link": "https://www.preservision.com/eyecare-professionals/samples-coupons",
                "description": "PreserVision Samples: Eyecare professionals may request samples of PreserVision Eye Vitamins for their practice by calling 844-258-4837.",
                "brand": "PreserVision Eye Vitamin AREDS Formula Soft Gels",
                "copay": "",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b41b"
            },
            {
                "_id": "6297bcace0e0db6c2572b420",
                "link": "https://www.prevacid24hr.com/coupons",
                "description": "Save $3 on any one Prevacid 24HR product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Prevacid 24HR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b420"
            },
            {
                "_id": "6297bcace0e0db6c2572b1f2",
                "link": "https://epihealth.com/rebate/",
                "description": "Minolira Patient Access Card: Eligible commercially insured patients may pay as little as $0 per fill; for additional information contact the program at 855-631-2485.",
                "brand": "Minolira",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1f2"
            },
            {
                "_id": "6297bcace0e0db6c2572b425",
                "link": "https://www.janssencarepath.com/patient/prezcobix/cost-support",
                "description": "Prezcobix Janssen CarePath Savings Program: Most eligible commercially insured patients may pay $0 per fill on out-of-pocket costs with savings of up to $7500 per calendar year; for additional assistance contact the program at 866-836-0114.",
                "brand": "Prezcobix",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "7500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b425"
            },
            {
                "_id": "6297bcace0e0db6c2572b1f7",
                "link": "https://mitigare.triplefin.com/copay/download_card_info.aspx",
                "description": "Mitigare True Blue Savings Card Rebate: Eligible patients may submit a rebate request via mail if the pharmacy does not accept the Savings card; for additional information contact the program at 877-259-6893.",
                "brand": "Mitigare",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "600",
                "lifetime_uses": "12",
                "max_saving_transaction": 50,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1f7"
            },
            {
                "_id": "6297bcace0e0db6c2572b42a",
                "link": "https://prilosecotc.com/en-us/two-week-challenge",
                "description": "Sign-up for the Prilosec 14 Day Challenge and receive a coupon for any one Prilosec OTC Product (excluding trial/travel size); limit one coupon per purchase; coupon expires one month after printing.",
                "brand": "Prilosec OTC",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b42a"
            },
            {
                "_id": "6297bcace0e0db6c2572b42f",
                "link": "https://www.pristiq.com/savings",
                "description": "Pristiq Savings Card: Eligible patients may pay as little as $4 per 30-day prescription fill with a savings of up to $1080 per year; for additional information contact the program at 855-440-6852.",
                "brand": "Pristiq",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1080",
                "lifetime_uses": "12",
                "max_saving_transaction": 90,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b42f"
            },
            {
                "_id": "6297bcace0e0db6c2572b434",
                "link": "http://wcpharma.com/patient-savings/",
                "description": "ProCort Women's Choice Savings Coupon: Eligible uninsured/cash-paying patients may pay no more than $75 per prescription; for additional information contact the program at 800-664-1490.",
                "brand": "ProCort",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b434"
            },
            {
                "_id": "6297bcace0e0db6c2572b439",
                "link": "https://bauschlombpartd.copaysavingsprogram.com",
                "description": "Prolensa Part D Coupon Program: Eligible patients may pay no more than $60 for each prescription fill; patients must have Medicare Part D or a Medicare Advantage prescription drug plan; for additional information contact the program at 800-795-1091.",
                "brand": "Prolensa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b439"
            },
            {
                "_id": "6297bcace0e0db6c2572b43e",
                "link": "https://www.alvaprofessional.com/skin-conditions",
                "description": "Prosacea Product Samples: Your healthcare provider may request samples by filling out a form online.",
                "brand": "Prosacea",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b43e"
            },
            {
                "_id": "6297bcace0e0db6c2572b443",
                "link": "https://uspco.com/product_literature.aspx?pid=53&tab=Contact",
                "description": "Provida DHA Samples: Healthcare providers may request samples for their practice by filling out a form online.",
                "brand": "Provida DHA",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b443"
            },
            {
                "_id": "6297bcace0e0db6c2572b448",
                "link": "https://www.alvaprofessional.com/skin-conditions",
                "description": "Psoriasin Product Samples: Your healthcare provider may request samples by filling out a form online.",
                "brand": "Psoriasin Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b448"
            },
            {
                "_id": "6297bcace0e0db6c2572b44d",
                "link": "https://www.bausch.com/our-products/contact-lenses/lenses-for-nearsighted-farsighted/purevision2-contact-lenses/free-trial",
                "description": "PureVision2 Contact Lenses Free Offer: Patients may print a Free Trial Lens Certificate to receive a FREE pair of PureVision2 contact lenses from their eyecare professional; for additional information contact the program at 800-553-5340.",
                "brand": "PureVision2 Contact Lenses",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b44d"
            },
            {
                "_id": "6297bcace0e0db6c2572b452",
                "link": "https://qdolo.com/patient-home/savings-support/",
                "description": "Qdolo Patient Savings Card: Eligible patients may pay as little as $20 per monthly prescription fill; for additional information contact the program at 833-469-6229.",
                "brand": "Qdolo",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b452"
            },
            {
                "_id": "6297bcace0e0db6c2572b1fc",
                "link": "https://hcp.monistat.com/coupons",
                "description": "Monistat Product Coupons: Healthcare providers may order coupons for their practice.",
                "brand": "Monistat 3-Day Treatment Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1fc"
            },
            {
                "_id": "6297bcace0e0db6c2572b457",
                "link": "https://www.qnasl.com/Savings",
                "description": "QNASL Savings Program: Eligible commercially insured patients may pay as little as $15 per device for prescription; for additional information contact the program at 844-492-9703.",
                "brand": "QNASL",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 98.1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b457"
            },
            {
                "_id": "6297bcace0e0db6c2572b45c",
                "link": "https://www.qtern.com/savings-coupon.html#email-rebate",
                "description": "Qtern Mail-In Rebate: Eligible commercially insured patients may receive a rebate check if their mail order pharmacy does not accept the Qtern Savings Card by completing a rebate form; for more information contact the program at 800-236-9933.",
                "brand": "Qtern",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3240",
                "lifetime_uses": "12",
                "max_saving_transaction": 270,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b45c"
            },
            {
                "_id": "6297bcace0e0db6c2572b461",
                "link": "https://coupon.trisadhd.com",
                "description": "Quillivant XR Co-pay Card: Eligible cash-paying patients may save on their monthly prescriptions; for additional information contact the program at 888-840-7006.",
                "brand": "Quillivant XR",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b461"
            },
            {
                "_id": "6297bcace0e0db6c2572b466",
                "link": "https://www.quzyttir.com/reimbursement-support/",
                "description": "Quzyttir Co-pay Program: Eligible commercially insured patients may pay as little as $25 per fill with a maximum savings of $300 per fill; maximum benefit of $1200 per calendar year; savings provided as patient rebates; for additional information contact the program at 866-789-9884.",
                "brand": "Quzyttir",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1200",
                "lifetime_uses": "4",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b466"
            },
            {
                "_id": "6297bcace0e0db6c2572b201",
                "link": "https://www.medimetriks.com/prescription-brands/morgidox-1x100-kit-morgidox-2x100-kit",
                "description": "Morgidox Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Morgidox",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b201"
            },
            {
                "_id": "6297bcace0e0db6c2572b206",
                "link": "https://mycareclubrewards.com/motrin/?utm_medium=partner&utm_source=motrin.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1sghl7t*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjY0NzMxOC40LjEuMTYzNjY0NzMzMy40NQ..*_fplc*WWt2VDMxc2E1JTJCbWhGdlZndzlCTmt6TENXRFQxc0JuWkglMkY2TDRFQnl0SUdXYzc4QmEySTM4dnJhRmE3ak1nSTRqR0RrWHk5bjQzWWFRVDFKSmc0blc5ZVVyVHI2UUVkZURxenBQalBMZllaRjlLSnUxeXduUzdxMFF5Y1VWQSUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Motrin products with registration.",
                "brand": "Motrin Childrens Oral Suspension",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b206"
            },
            {
                "_id": "6297bcace0e0db6c2572b20b",
                "link": "https://mycareclubrewards.com/motrin/?utm_medium=partner&utm_source=motrin.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1sghl7t*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjY0NzMxOC40LjEuMTYzNjY0NzMzMy40NQ..*_fplc*WWt2VDMxc2E1JTJCbWhGdlZndzlCTmt6TENXRFQxc0JuWkglMkY2TDRFQnl0SUdXYzc4QmEySTM4dnJhRmE3ak1nSTRqR0RrWHk5bjQzWWFRVDFKSmc0blc5ZVVyVHI2UUVkZURxenBQalBMZllaRjlLSnUxeXduUzdxMFF5Y1VWQSUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Motrin products with registration.",
                "brand": "Motrin PM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b20b"
            },
            {
                "_id": "6297bcace0e0db6c2572b210",
                "link": "https://www.mucinex.com/pages/savings",
                "description": "Register your email address to receive a $5 coupon for any Mucinex product and future savings offers.",
                "brand": "Mucinex Childrens Cough Mini-Melts",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b210"
            },
            {
                "_id": "6297bcace0e0db6c2572b215",
                "link": "https://www.mucinex.com/pages/savings",
                "description": "Register your email address to receive a $5 coupon for any Mucinex product and future savings offers.",
                "brand": "Mucinex Childrens Multi-Symptom",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b215"
            },
            {
                "_id": "6297bcace0e0db6c2572b21a",
                "link": "https://www.mucinex.com/pages/savings",
                "description": "Register your email address to receive a $5 coupon for any Mucinex product and future savings offers.",
                "brand": "Mucinex Fast-Max DM MAX Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b21a"
            },
            {
                "_id": "6297bcace0e0db6c2572b21f",
                "link": "https://www.rbhcprofessional.com/s/",
                "description": "Mucinex Samples: Healthcare providers may register to order samples for their practice.",
                "brand": "Mucinex Fast-Max Liquid Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b21f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae4f",
                "link": "http://www.doryx.com/#copay",
                "description": "Doryx MPC Mayne Pharma Patient Savings Card: Eligible commercially insured patients may have a copay of $0 per prescription; for additional information contact the program at 347-442-7919.",
                "brand": "Doryx MPC",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae4f"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae54",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Blister Cushions with Duragel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae54"
            },
            {
                "_id": "6297bcace0e0db6c2572b224",
                "link": "https://www.mucinex.com/pages/savings",
                "description": "Register your email address to receive a $5 coupon for any Mucinex product and future savings offers.",
                "brand": "Mucinex Sinus-Max Caplet Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b224"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae59",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Clear Away Fast-Acting Liquid Wart Remover",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae59"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae5e",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Corn Removers Soft Felt Pads",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae5e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae63",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Freeze Away Wart Remover",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae63"
            },
            {
                "_id": "6297bcace0e0db6c2572b229",
                "link": "https://www.multaq.com/savings",
                "description": "Multaq Savings Card: Eligible cash-paying patients may save up to $150 per monthly fill on 13 fills; for additional information contact the program at 855-699-8685.",
                "brand": "Multaq",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1950",
                "lifetime_uses": "13",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b229"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae68",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Moleskin Plus Padding Strips",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae68"
            },
            {
                "_id": "6297bcace0e0db6c2572b22e",
                "link": "https://amgenfirststep.com",
                "description": "Mvasi Amgen First Step Program Rebate: Eligible commercially insured patients who have already paid for treatment may request a rebate check; for additional information contact the program at 888-657-8371.",
                "brand": "Mvasi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b22e"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae6d",
                "link": "https://www.drscholls.com/t/coupons/",
                "description": "Dr. Scholls Products: Register your email address for offers to be sent to your inbox.",
                "brand": "Dr. Scholls Odor-X Ultra Sweat-Absorbing Foot Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae6d"
            },
            {
                "_id": "6297bcace0e0db6c2572b233",
                "link": "https://www.myfembree.com/commercial-insurance/",
                "description": "Myfembree Copay Assistance Program: Eligible commercially insured patients may pay as little as $5 per monthly fill with a maximum savings of $2000 per calendar year; for additional information contact the program at 833-693-3627.",
                "brand": "Myfembree",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "2000",
                "lifetime_uses": "12",
                "max_saving_transaction": 166.66,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b233"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae72",
                "link": "https://www.duavee.com/savings-card",
                "description": "Duavee Savings Card Mail-In Rebate: Eligible commercially insured patients using a mail-order pharmacy or if their pharmacy does not accept the Savings Card may receive a rebate up to $70 after paying for their prescription in full; for additional information contact the program at 866-881-2545.",
                "brand": "Duavee",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "840",
                "lifetime_uses": "12",
                "max_saving_transaction": 70,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae72"
            },
            {
                "_id": "6297bcace0e0db6c2572b238",
                "link": "https://www.myrbetriq.com/momentum-program/",
                "description": "Myrbetriq Momentum Savings Card: Eligible commercially insured patients may receive their 1st prescription FREE and pay as little as $20 for each subsequent prescription; maximum savings of $840 for 12 uses; for additional information contact the program at 800-477-6472.",
                "brand": "Myrbetriq",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "840",
                "lifetime_uses": "12",
                "max_saving_transaction": 70,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b238"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae77",
                "link": "https://www.dulcolax.com/coupons",
                "description": "Save $2 on any one Dulcolax product (excluding 10 count or smaller); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Dulcolax Liquid Laxative",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae77"
            },
            {
                "_id": "6297bcace0e0db6c2572b23d",
                "link": "https://naftin.com",
                "description": "Naftin Gel Co-Pay Savings Program: Eligible commercially insured patients may pay no more than $22 per prescription; savings card is good for 6 uses; for additional information contact the program at 888-296-1852.",
                "brand": "Naftin",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "3000",
                "lifetime_uses": "6",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b23d"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae7c",
                "link": "https://www.dulcolax.com/coupons",
                "description": "Save $2 on any one Dulcolax product (excluding 10 count or smaller); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Dulcolax Suppositories",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae7c"
            },
            {
                "_id": "6297bcace0e0db6c2572b242",
                "link": "https://namzaric.copaysavingsprogram.com/activation",
                "description": "Namzaric Savings Program: Eligible commercially insured patients may pay as little as $20 per 30-day supply; offer may be used 12 times; for additional information contact the program 855-618-0302.",
                "brand": "Namzaric",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b242"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae81",
                "link": "https://www.orthorxaccess.com/",
                "description": "Duobrii Rx Access Program: Eligible commercially insured patients pay $25 per prescription; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Duobrii",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae81"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae86",
                "link": "https://www.durysta.com/?utm_source=google_durysta_dtc_branded_cpc&gclid=CjwKCAjw8cCGBhB6EiwAgORey-xpqX9lRSajpxtmBtoOkbd7U5lm4jR8Jpcllk7QsPDIHY8wPxJ6oBoCXJUQAvD_BwE",
                "description": "Durysta Savings Program: Eligible commercially insured patients may pay as little as $20 for a Durysta implant; maximum benefit of $1000 per eye; for additional information contact your healthcare provider or call 800-678-1605.",
                "brand": "Durysta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "2000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae86"
            },
            {
                "_id": "6297bcace0e0db6c2572b247",
                "link": "https://www.narcan.com/patients/how-to-get-narcan/",
                "description": "Narcan Pharmacy Direct Program: Eligible patients may receive medication without a prescription and at a reduced price through a participating pharmacies; for additional information contact the program at 844-462-7226.",
                "brand": "Narcan",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b247"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae8b",
                "link": "https://www.ipsencares.com/dysport-patient-support/",
                "description": "Dysport Ipsen Cares Copay Assistance Program: Eligible cash-pay patients may save $1250 per prescription with a maximum savings of $5000 per calendar year; program reset every January 1st; for additional information contact the program at 866-435-5677.",
                "brand": "Dysport",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "4",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae8b"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae90",
                "link": "https://www.edarbi.com/edarbi-edarbyclor-patient-savings-support#savingsCard",
                "description": "Edarbi Instant Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 877-264-2440.",
                "brand": "Edarbi",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae90"
            },
            {
                "_id": "6297bcace0e0db6c2572b46b",
                "link": "https://www.alksavings.com/cb/access/crp/aLkYd2j78Vbb/fid.jsp?q=51G5OI5442CUDK4PLWTLX5&f=IMPX6IG-274EMWRGK6802#/app/layout/home",
                "description": "Ragwitek ALK Savings Card Program: Eligible commercially insured patients may pay as little as $50 per 30-day prescription if using a retail pharmacy; valid for 12 uses or $3,200 per year (whichever comes first); for additional information contact the program at 800-325-7354.",
                "brand": "Ragwitek",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3200",
                "lifetime_uses": "12",
                "max_saving_transaction": 265,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b46b"
            },
            {
                "_id": "6297bcace0e0db6c2572b470",
                "link": "https://www.rasuvo.com/patient-resources/prescription-savings/",
                "description": "Rasuvo Core Connections Dose Modification Program: Commercially insured patients may benefit from this program if their doctor needs to adjust their dose before they are due for a refill; for additional information contact the program at 855-336-3322.",
                "brand": "Rasuvo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b470"
            },
            {
                "_id": "6297bcace0e0db6c2572b475",
                "link": "https://www.hematologysupport.com/financial-assistance",
                "description": "Recombinate Takeda HSC CoPay Assistance Program: Eligible commercially insured patients' eligible out-of-pocket costs to be covered 100% by the program; for additional information contact the program at 888-229-8379.",
                "brand": "Recombinate",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b475"
            },
            {
                "_id": "6297bcace0e0db6c2572b47a",
                "link": "https://www.recticare.com/doctors/",
                "description": "RectiCare Product Samples: Healthcare providers may order samples for their practice by filling out the online form.",
                "brand": "RectiCare Medicated Anorectal Wipes",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b47a"
            },
            {
                "_id": "6297bcace0e0db6c2572b47f",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Digital",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b47f"
            },
            {
                "_id": "6297bcace0e0db6c2572b484",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Optive Advanced Preservative Free",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b484"
            },
            {
                "_id": "6297bcace0e0db6c2572b489",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh P.M. Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b489"
            },
            {
                "_id": "6297bcace0e0db6c2572b48e",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Relieva PF Multidose",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b48e"
            },
            {
                "_id": "6297bcace0e0db6c2572b24c",
                "link": "https://www.natazia.com/save-natazia",
                "description": "Natazia Bayer Savings Card Program: Eligible commercially insured patients may pay as little as $0 co-pay per prescription with savings of up to $125 per fill; for additional assistance contact the program at 866-203-3503.",
                "brand": "Natazia",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b24c"
            },
            {
                "_id": "6297bcace0e0db6c2572b493",
                "link": "https://ec4ea071-fbcb-424f-b1c2-cf62cecd02ee.filesusr.com/ugd/72e81f_ff8712ca24fb46138474f91660fcfdec.pdf",
                "description": "ReliOn Novolin N FlexPen Savings: Eligible patients may save from 58% to 75% off the cash price per�prescription; offer available only at Walmart; for additional information contact your nearest Walmart Pharmacy.",
                "brand": "ReliOn Novolin N Insulin FlexPen",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b493"
            },
            {
                "_id": "6297bcace0e0db6c2572b251",
                "link": "https://www.natesto.com/support-and-resources/#save-on-natesto",
                "description": "Natesto Savings Card: Eligible commercially insured patients may pay as little as $0 per monthly fill; maximum savings of $3500 per year; for additional information contact the program at 855-390-0162.",
                "brand": "Natesto",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3500",
                "lifetime_uses": "12",
                "max_saving_transaction": 291.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b251"
            },
            {
                "_id": "6297bcace0e0db6c2572b498",
                "link": "https://www.relistor.com/hcp/savings-and-resources#resourcesSec2",
                "description": "Relistor Samples: Your healthcare provider may request samples by visting the website.",
                "brand": "Relistor",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b498"
            },
            {
                "_id": "6297bcace0e0db6c2572b256",
                "link": "https://www.alva-amco.com/contact",
                "description": "Consumers may fill out a request form for Nauzene product coupons.",
                "brand": "Nauzene Kids",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b256"
            },
            {
                "_id": "6297bcace0e0db6c2572b49d",
                "link": "https://www.relpax.com/savings",
                "description": "Relpax Savings Card: Eligible patients pay as little as $4 per 30-day prescription with savings of up to $3000 per year; for additional information contact the program at 800-926-5334.",
                "brand": "Relpax",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b49d"
            },
            {
                "_id": "6297bcace0e0db6c2572b25b",
                "link": "https://www.saveonmyprescription.com/copay.jsp",
                "description": "Neoral 30-day FREE trial: Eligible patients may receive a 30-day supply free; valid for one-time use only; for additional information contact the program at 877-952-1000.",
                "brand": "Neoral",
                "copay": "No",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b25b"
            },
            {
                "_id": "6297bcace0e0db6c2572b4a2",
                "link": "http://www.renvalue.com",
                "description": "Renvela RenValue Copay Card: Eligible uninsured patients may save up to $300 on each of up to 12 prescriptions; for additional information contact the program at 866-257-4605.",
                "brand": "Renvela",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3600",
                "lifetime_uses": "12",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4a2"
            },
            {
                "_id": "6297bcace0e0db6c2572b260",
                "link": "https://mycareclubrewards.com/neosporin/?utm_medium=partner&utm_source=neosporin.com&utm_content=header&utm_campaign=cc_main&_gl=1*1lx5xvo*_ga*MjA5MDY2NzQ5OS4xNjMzMzU4MTUw*_ga_13VEM6N66E*MTYzMzM1ODE1MC4xLjAuMTYzMzM1ODE1MC42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neosporin products with registration.",
                "brand": "Neosporin Original Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b260"
            },
            {
                "_id": "6297bcace0e0db6c2572b4a7",
                "link": "https://www.womenshealthcaresolutions.com/patient-material-request",
                "description": "RepHresh Pro-B Free Samples: Your healthcare provider may request FREE Feminine Health Kit with product samples, patient coupons and valuable patient materials.",
                "brand": "RepHresh Pro-B",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4a7"
            },
            {
                "_id": "6297bcace0e0db6c2572b265",
                "link": "https://nerlynx.com/access-and-support/access-programs",
                "description": "Nerlynx Co-Pay Savings Program: Eligible commercially insured patients may pay as little as $10 per prescription; for additional information contact the program at 855-816-5421.",
                "brand": "Nerlynx",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b265"
            },
            {
                "_id": "6297bcace0e0db6c2572b4ac",
                "link": "https://www.restasis.com/savings",
                "description": "Restasis Multidose My Tears, My Rewards Savings Card: Eligible patients may pay as little as $0 per 30-day prescription with a savings of $250 per fill; for additional information contact program at 844-469-8327.",
                "brand": "Restasis Multidose",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4ac"
            },
            {
                "_id": "6297bcace0e0db6c2572b4b1",
                "link": "https://uspco.com/product_literature.aspx?pid=434&tab=Voucher",
                "description": "Restora Sprinkles Pharmacy Voucher: Eligible patients may save up to 80% at participating pharmacies; for additional information contact the program at 888-602-2978.",
                "brand": "Restora Sprinkles",
                "copay": "No",
                "bin": "600428",
                "rxpcn": "5100000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4b1"
            },
            {
                "_id": "6297bcace0e0db6c2572b4b6",
                "link": "https://www.aspirerewards.com/landing",
                "description": "Restylane Kysse ASPIRE Galderma Rewards: Join today and receive a $60 treatment certificate instantly; other valuable discounts and rebates with sign up; for additional information contact customer service at 844-527-7473.",
                "brand": "Restylane Kysse",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4b6"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae95",
                "link": "https://www.edarbi.com/edarbi-edarbyclor-patient-savings-support#savingsCard",
                "description": "Edarbyclor E-Z Rx Program: Eligible commercially insured patients pay $15 per prescription at participating independent pharmacies; for additional information contact the program at 877-264-2440.",
                "brand": "Edarbyclor",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae95"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae9a",
                "link": "https://www.effexorxr.com/savings-terms-and-conditions",
                "description": "Effexor XR Rebate: Eligible patients may receive a rebate if their pharmacy does not participate in the Savings Card Program by submitting their original pharmacy receipt with product name, date, and amount circled to the program by mail; for more information contact the program at 855-488-0750.",
                "brand": "Effexor XR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae9a"
            },
            {
                "_id": "6297bcace0e0db6c2572b26a",
                "link": "http://wcpharma.com/patient-savings/",
                "description": "Nestabs DHA Women's Choice Savings Coupon: Eligible commercially insured patients may pay no more than $35 per prescription; for additional information contact the program at 800-664-1490.",
                "brand": "Nestabs DHA",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b26a"
            },
            {
                "_id": "6297bcabe0e0db6c2572ae9f",
                "link": "https://eletonecream.com",
                "description": "Eletone Savings Card: Eligible commercially insured patients may pay as little as $20 per fill on each of up to 12 prescriptions; for additional information contact the program at 347-766-9883.",
                "brand": "Eletone",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572ae9f"
            },
            {
                "_id": "6297bcabe0e0db6c2572aea4",
                "link": "https://www.sanoficareassist.com/elitek/copay",
                "description": "Elitek CareASSIST Copay Program: Eligible commercially insured patients may pay as little as $0 for co-insurance, co-pay and deductibles; maximum savings of $25,000 per year;  for additional information contact the program at 833-930-2273, Option 1.",
                "brand": "Elitek",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aea4"
            },
            {
                "_id": "6297bcabe0e0db6c2572aea9",
                "link": "https://www.eloctate.com/resources/financial-assistance",
                "description": "Eloctate Free Trial Plus Program: Eligible patients may get a 30-day trial with a valid prescription; for additional information contact the program at 855-693-5628.",
                "brand": "Eloctate",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aea9"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeae",
                "link": "https://empaveli.com/apellisassist/",
                "description": "Empaveli Apellis Bridge Program: Eligible commercially insured patients may receive assistance with their prescriptions if experiencing a delay or change in insurance; for additional information contact the program 866-692-7527.",
                "brand": "Empaveli",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeae"
            },
            {
                "_id": "6297bcace0e0db6c2572b26f",
                "link": "https://amgenfirststep.com/register-card",
                "description": "Neulasta Amgen First Step Program: Eligible commercially insured patients may save on their out-of-pocket medication costs; for additional information contact the program at 888-427-7478.",
                "brand": "Neulasta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b26f"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeb3",
                "link": "https://www.emverm.com/savings-program/",
                "description": "Emverm eVoucherRx Program: Eligible commercially insured patients may receive an automatic co-pay reduction at participating pharmacies; for additional information contact the program at 877-264-2440.",
                "brand": "Emverm",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeb3"
            },
            {
                "_id": "6297bcace0e0db6c2572b274",
                "link": "https://amgenfirststep.com",
                "description": "Neupogen Amgen First Step Program Rebate: Eligible commercially insured patients who have already paid for treatment may request a rebate check; for additional information contact the program at 888-657-8371.",
                "brand": "Neupogen",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b274"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeb8",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Endometrin Patient Savings Card: Eligible cash-paying patients may save up to $30 every 2 weeks; for additional information contact the program at 833-226-3082.",
                "brand": "Endometrin",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": " OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 30,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeb8"
            },
            {
                "_id": "6297bcace0e0db6c2572b279",
                "link": "https://www.neutrogena.com/care-club.html",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neutrogena products with registration.",
                "brand": "Neutrogena Clear Pore Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b279"
            },
            {
                "_id": "6297bcabe0e0db6c2572aebd",
                "link": "https://enemeez.com/request-samples/",
                "description": "Enemeez FREE Samples: Eligible patients may receive FREE samples of any one Enemeez product; for additional information contact the program at 888-273-9734 ext 208.",
                "brand": "Enemeez Plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aebd"
            },
            {
                "_id": "6297bcabe0e0db6c2572aec2",
                "link": "https://www.enfamil.com/baby-formula-coupons-samples/",
                "description": "Join the Drive to Thrive Support Program and receive up to $70 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil EnfaCare",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aec2"
            },
            {
                "_id": "6297bcace0e0db6c2572b27e",
                "link": "https://www.neutrogena.com/offers.html",
                "description": "Sign up and receive 15% off 1st purchase plus free shipping on purchases $25 or more when you shop online for any one Neutrogena Product.",
                "brand": "Neutrogena Hydro Boost Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b27e"
            },
            {
                "_id": "6297bcabe0e0db6c2572aec7",
                "link": "https://www.enfamil.com/baby-formula-coupons-samples/",
                "description": "Join the Drive to Thrive Support Program and receive up to $70 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil NeuroPro EnfaCare Formula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aec7"
            },
            {
                "_id": "6297bcace0e0db6c2572b283",
                "link": "https://www.neutrogena.com/offers.html",
                "description": "Sign up and receive 15% off 1st purchase plus free shipping on purchases $25 or more when you shop online for any one Neutrogena Product.",
                "brand": "Neutrogena Oil Free Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b283"
            },
            {
                "_id": "6297bcabe0e0db6c2572aecc",
                "link": "https://www.enfamil.com/enfacare-drive-to-thrive-support-program/",
                "description": "Join the Drive to Thrive Support Program and receive up to $70 in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Premature High Protein 24",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aecc"
            },
            {
                "_id": "6297bcace0e0db6c2572b288",
                "link": "https://www.neutrogena.com/care-club.html",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neutrogena products with registration.",
                "brand": "Neutrogena Skin Balancing Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b288"
            },
            {
                "_id": "6297bcabe0e0db6c2572aed1",
                "link": "https://www.enfamil.com/baby-formula-coupons-samples/",
                "description": "Join Enfamil Family Beginnings and receive up to $400  in valuable offers, coupons and free gifts with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Enfamil Premium Infant",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aed1"
            },
            {
                "_id": "6297bcace0e0db6c2572b28d",
                "link": "https://newskinproducts.com/pages/coupons",
                "description": "Save $2 on any New Skin product; 1 coupon per purchase.",
                "brand": "New-Skin All-in-One Liquid Bandage",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b28d"
            },
            {
                "_id": "6297bcabe0e0db6c2572aed6",
                "link": "https://enspryngcopay.com",
                "description": "Enspryng Co-pay Program: Eligible commercially insured patients may pay as little as $5 per 30-day supply; maximum savings of $20,000 per calendar year; for additional information contact the program at 844-677-7964.",
                "brand": "Enspryng",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1666.67,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aed6"
            },
            {
                "_id": "6297bcace0e0db6c2572b292",
                "link": "https://www.nexavar-us.com/co-pay",
                "description": "Nexavar Co-Pay Program: Eligible commercially insured patients may pay $0 per prescription with savings of up to $25,000 per calendar year; for additional information contact the program at 866-581-4992.",
                "brand": "Nexavar",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b292"
            },
            {
                "_id": "6297bcabe0e0db6c2572aedb",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure Complete Shake Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aedb"
            },
            {
                "_id": "6297bcace0e0db6c2572b297",
                "link": "https://www.nexcare.com/3M/en_US/nexcare/deals-offers/",
                "description": "Sign-up with Nexcare Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "Nexcare Skin Crack Care",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b297"
            },
            {
                "_id": "6297bcabe0e0db6c2572aee0",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure Max Protein Shake Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aee0"
            },
            {
                "_id": "6297bcace0e0db6c2572b29c",
                "link": "https://www.purplepill.com/savings.html",
                "description": "Nexium Savings Card: Eligible commercially insured patient/RX not covered may pay as little as $15 per 30-day supply with a maximum savings of $125 per fill; for additional information contact the program at 855-687-2144.",
                "brand": "Nexium",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1500",
                "lifetime_uses": "12",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b29c"
            },
            {
                "_id": "6297bcabe0e0db6c2572aee5",
                "link": "https://ensure.com/coupons",
                "description": "Ensure Healthy Matters: Sign up and receive an instant $3 coupon plus receive up to $50 on Ensure products throughout the engagement of the program; for additional information contact the program at 800-986-8501.",
                "brand": "Ensure Pre Surgery Clear Carbohydrate",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aee5"
            },
            {
                "_id": "6297bcace0e0db6c2572b2a1",
                "link": "https://www.nexlizethcp.com/sample-request",
                "description": "Nexlizet Samples: Healthcare professionals may order free samples for their practice.",
                "brand": "Nexlizet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2a1"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeea",
                "link": "https://www.entresto.com/financial-support",
                "description": "Entresto Co-Pay Card: Eligible commercially patients may pay as little as $10 per prescription with savings of up to $3250 per calendar year; contact the program for additional information at 888-368-7378.",
                "brand": "Entresto",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3250",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeea"
            },
            {
                "_id": "6297bcace0e0db6c2572b2a6",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Nicaprin Artesa Labs Advantage Card: Eligible commercially insured patients may pay $0 per prescription; for additional information contact the program 877-264-2440.",
                "brand": "Nicaprin",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty\r\n",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2a6"
            },
            {
                "_id": "6297bcabe0e0db6c2572aeef",
                "link": "https://www.entyvio.com/copay-support",
                "description": "EntyvioConnect Co-Pay Program: Eligible patients commercially insured patients may pay no more than $5 per dose; for additional information contact the program at 844-368-9846.",
                "brand": "Entyvio",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aeef"
            },
            {
                "_id": "6297bcace0e0db6c2572b2ab",
                "link": "https://www.nicorette.com/coupons.html",
                "description": "Save $5 on any NicoDerm CQ Product (14 count or larger) when you sign-up online with your email address; 1 coupon per purchase.",
                "brand": "NicoDerm CQ Step 3",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2ab"
            },
            {
                "_id": "6297bcabe0e0db6c2572aef4",
                "link": "https://qv.trialcard.com/Asegua#/app/layout/home",
                "description": "Asegua Co-pay Coupon Program for Generic of Epclusa (Sofosbuvir/Velpatasvir): Eligible patients may pay as little as $5 per co-pay; for additional information contact the program at 800-445-3235.",
                "brand": "Epclusa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aef4"
            },
            {
                "_id": "6297bcabe0e0db6c2572aef9",
                "link": "https://www.galdermacc.com/patients",
                "description": "Epiduo Forte CareConnect Patient Savings Card: Eligible uninsured/cash-paying patients may pay no more than $105 per prescription; for additional information contact the program at 855-280-0543.",
                "brand": "Epiduo Forte",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aef9"
            },
            {
                "_id": "6297bcace0e0db6c2572b2b0",
                "link": "https://www.nicorette.com/coupons.html",
                "description": "Save $5 on any Nicorette Product (72 count or larger) when you sign-up online with your email address; 1 coupon per purchase.",
                "brand": "Nicorette Lozenge",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2b0"
            },
            {
                "_id": "6297bcabe0e0db6c2572aefe",
                "link": "https://www.activatethecard.com/viatrisadvocate/epipen/welcome.html?_ga=2.41511777.276121865.1579134457-1618483530.1579134457#tnc",
                "description": "EpiPen Savings Card: Eligible commercially insured patients may save up to $300 per prescription; maximum of 3 cartons per prescription; savings card can be used for a total of 6 cartons per calendar year; for additional information contact the program at 800-657-7613.",
                "brand": "EpiPen 2-Pak",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572aefe"
            },
            {
                "_id": "6297bcabe0e0db6c2572af03",
                "link": "https://www.activatethecard.com/viatrisadvocate/epipen/welcome.html?_ga=2.41511777.276121865.1579134457-1618483530.1579134457#tnc",
                "description": "EpiPen Savings Card: Eligible commercially insured patients without coverage for the RX may save up to $300 per prescription; maximum of 3 cartons per prescription; savings card can be used for a total of 6 cartons per calendar year; for additional information contact the program at 800-657-7613.",
                "brand": "EpiPen Jr 2-Pak",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af03"
            },
            {
                "_id": "6297bcabe0e0db6c2572af08",
                "link": "https://www.lillyoncologysupport.com/erbitux-financial-support",
                "description": "Erbitux Savings Card: Eligible commercially insured patients may pay as little as $25 per dose with a maximum savings of up to $25,000 during a 12-month enrollment period; for additional information contact the program at 866-472-8663.",
                "brand": "Erbitux",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.34,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af08"
            },
            {
                "_id": "6297bcabe0e0db6c2572af0d",
                "link": "https://www.janssencarepath.com/patient/erleada/cost-support",
                "description": "Erleada Janssen CarePath Savings Program: Most eligible commercially insured patients may pay $0 per month for a maximum savings of up to $15,000 per calendar year; for additional information contact the program at 833-375-3232.",
                "brand": "Erleada",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af0d"
            },
            {
                "_id": "6297bcabe0e0db6c2572af12",
                "link": "https://esbrietcopay.com/forms",
                "description": "Esbriet $5 Co-pay Program Reimbursement Request: Eligible commercially insured patients may submit a rebate request after paying their provider for treatment; for additional information contact the program at 877-780-4958.",
                "brand": "Esbriet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.34,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af12"
            },
            {
                "_id": "6297bcabe0e0db6c2572af17",
                "link": "https://estrogelsavings.com",
                "description": "EstroGel Instant Savings Coupon Rebate: Eligible patients may request a $35 rebate if the pharmacist was unable to process their Savings Coupon; for additional information contact the program at 877-204-1013.",
                "brand": "EstroGel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "420",
                "lifetime_uses": "12",
                "max_saving_transaction": 35,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af17"
            },
            {
                "_id": "6297bcace0e0db6c2572b4bb",
                "link": "https://rethymic.com",
                "description": "Rethymic Enzyvant Connect Copay Program: Eligible commercially patients may enroll in this program for assistance with their copays; patient and doctor must fill out enrollment form; for additional information contact the program at 844-369-2628.",
                "brand": "Rethymic",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4bb"
            },
            {
                "_id": "6297bcabe0e0db6c2572af1c",
                "link": "https://www.estroven.com/sign-up-for-offers/",
                "description": "Sign up to the mailing list and exclusive offers for Estroven products.",
                "brand": "Estroven Menopause Relief plus Sleep",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af1c"
            },
            {
                "_id": "6297bcabe0e0db6c2572af21",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Advanced Repair Cream Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af21"
            },
            {
                "_id": "6297bcace0e0db6c2572b2b5",
                "link": "https://www.takedaoncologycopay.com",
                "description": "Ninlaro Takeda Oncology Co-pay Assistance Program Rebate: If a commercially insured patient is unable to take advantage of the assistance program at their local pharmacy they may be eligible for a rebate; for additional information contact the program at 844-817-6468.",
                "brand": "Ninlaro",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2b5"
            },
            {
                "_id": "6297bcabe0e0db6c2572af26",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Baby Eczema Relief Cream Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af26"
            },
            {
                "_id": "6297bcace0e0db6c2572b4c0",
                "link": "https://myviivcard.com",
                "description": "Retrovir ViiVConnect Savings Card: Eligible patients pay $0 per prescription with savings of up to $4800 per year with no monthly limit; for additional information contact the program at 844-588-3288.",
                "brand": "Retrovir",
                "copay": "Yes",
                "bin": "610626",
                "rxpcn": "1016",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4c0"
            },
            {
                "_id": "6297bcabe0e0db6c2572af2b",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Daily Hydration Cream Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af2b"
            },
            {
                "_id": "6297bcabe0e0db6c2572af30",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Eczema Relief Cream Body Wash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af30"
            },
            {
                "_id": "6297bcace0e0db6c2572b2ba",
                "link": "https://www.nityr.us/patient/support/patient-liason-services",
                "description": "Nityr Quick Start Program: Eligible commercially insured patients may receive medication free of charge while waiting for insurance coverage; newborns receive a free 30-Day supply during their hospital stay and patients over 6 months old receive a free 14-Day supply; for additional information contact the program at 800-847-8714.",
                "brand": "Nityr",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2ba"
            },
            {
                "_id": "6297bcabe0e0db6c2572af35",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Save $3 (or $3.50 when you click & share) on any one Eucerin product; 1 coupon per purchase; excludes travel and trial sizes.",
                "brand": "Eucerin Itch Relief Intensive Calming Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af35"
            },
            {
                "_id": "6297bcace0e0db6c2572b4c5",
                "link": "https://www.rexulti.com/savings",
                "description": "Rexulti Savings Card Rebate: Eligible commercially insured patients request a rebate if they use a mail-order pharmacy or if they paid full price for their prescription; for additional information contact the program at 844-415-0674.",
                "brand": "Rexulti",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5800",
                "lifetime_uses": "12",
                "max_saving_transaction": 600,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4c5"
            },
            {
                "_id": "6297bcabe0e0db6c2572af3a",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Original Healing Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af3a"
            },
            {
                "_id": "6297bcabe0e0db6c2572af3f",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Roughness Relief Spot Treatment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af3f"
            },
            {
                "_id": "6297bcabe0e0db6c2572af44",
                "link": "https://www.eucerinus.com/coupons/coupon",
                "description": "Sign up for exclusive savings offers available from Eucerin.",
                "brand": "Eucerin Skin Calming Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af44"
            },
            {
                "_id": "6297bcabe0e0db6c2572af49",
                "link": "https://www.eucrisahcp.com",
                "description": "Eucrisa Samples: Your healthcare provider may order samples by contacting PfizerPro online or calling 800-505-4426.",
                "brand": "Eucrisa",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcabe0e0db6c2572af49"
            },
            {
                "_id": "6297bcace0e0db6c2572af4e",
                "link": "https://www.evekeo.com/",
                "description": "Evekeo Instant Savings Card: Eligible commercially insured patients pay may pay no more than $30 per 30-day prescription; for additional information contact the program at 855-558-1630.",
                "brand": "Evekeo",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af4e"
            },
            {
                "_id": "6297bcace0e0db6c2572af53",
                "link": "https://evkeeza.com/s/patient-support",
                "description": "Evkeeza myRARE Copay Card: Eligible commercially insured patients pay as little as $0 per fill; maximum savings of $25,000 per year; for additional information contact the program at 877-385-3392.",
                "brand": "Evkeeza",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af53"
            },
            {
                "_id": "6297bcace0e0db6c2572af58",
                "link": "https://www.excedrin.com",
                "description": "Sign-up and save $1 on any one Excedrin product; must click on an article to download coupon; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Excedrin Migraine",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af58"
            },
            {
                "_id": "6297bcace0e0db6c2572af5d",
                "link": "https://www.here2assist.com/patient/home",
                "description": "Exkivity Takeda Oncology Co-Pay Assistance Program: Eligible commercially insured patients may pay as little as $0 per prescription with a maximum savings of $25,000 per year; for additional information contact the program at 844-817-6468.\r\n\r\n",
                "brand": "Exkivity",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af5d"
            },
            {
                "_id": "6297bcace0e0db6c2572af62",
                "link": "https://www.myeysuvis.com/copay-help/#saving-on-eysuvis",
                "description": "Eysuvis I-Save Patient Access Program: Eligible commercially insured patients/RX not covered may pay as little as $60 per prescription; or additional information contact the program at 877-805-1873.",
                "brand": "Eysuvis",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af62"
            },
            {
                "_id": "6297bcace0e0db6c2572af67",
                "link": "https://www.fanaptpro.com",
                "description": "Fanapt Savings Card: Eligible cash-paying patients may save up to $200 per prescription per calendar year; for additional information contact the program at 866-318-1969.",
                "brand": "Fanapt",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "2400",
                "lifetime_uses": "12",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af67"
            },
            {
                "_id": "6297bcace0e0db6c2572b2bf",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Nolix Artesa Labs Advantage Card: Eligible commercially insured patients may pay $0 per prescription; for additional information contact the program 877-264-2440.",
                "brand": "Nolix",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2bf"
            },
            {
                "_id": "6297bcace0e0db6c2572b4ca",
                "link": "https://rocklatan.copaysavingsprogram.com",
                "description": "Rhopressa Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription on each of up to 12 fills; for additional information contact the program at 844-807-9706.",
                "brand": "Rhopressa",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4ca"
            },
            {
                "_id": "6297bcace0e0db6c2572b2c4",
                "link": "https://uspco.com/product_literature.aspx?pid=431&tab=Contact",
                "description": "Norel AD Samples: Healthcare providers may request samples for their practice by filling out a form online.",
                "brand": "Norel AD",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2c4"
            },
            {
                "_id": "6297bcace0e0db6c2572b4cf",
                "link": "https://ridlice.com/coupons/",
                "description": "Save $2 off any one RID product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "RID Lice Treatment Complete Kit",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4cf"
            },
            {
                "_id": "6297bcace0e0db6c2572b2c9",
                "link": "https://www.norvasc.com/savings",
                "description": "Norvasc Savings Card Program: Eligible patients may pay as little as $0 per 30-day prescription with savings of up to $900 per year; for additional information contact the program at 855-252-0283.",
                "brand": "Norvasc",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "900",
                "lifetime_uses": "12",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2c9"
            },
            {
                "_id": "6297bcace0e0db6c2572b4d4",
                "link": "https://www.riometer.com",
                "description": "Riomet ER Copay Card: Eligible commercially insured patients pay as little as $25 per monthly fill (no more than $95 per fill) with a maximum savings of $522 per fill; maximum yearly savings of $7308 (up to 14 fills per year); for additional information contact the program at 855-699-8695.",
                "brand": "Riomet ER",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "7308",
                "lifetime_uses": "14",
                "max_saving_transaction": 522,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4d4"
            },
            {
                "_id": "6297bcace0e0db6c2572b2ce",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Novarel Heart Beat Program: Eligible patients seeking fertility preservation prior to cancer treatment may receive fertility medications at no cost with program; please see website for full qualification requirements; for additional information contact the program at 888-347-3415.",
                "brand": "Novarel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2ce"
            },
            {
                "_id": "6297bcace0e0db6c2572b4d9",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Rituxan Hycela Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Rituxan Hycela",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4d9"
            },
            {
                "_id": "6297bcace0e0db6c2572b2d3",
                "link": "https://www.novoneedles.com/needle-offers.html",
                "description": "NovoFine Novo Nordisk Diabetes Savings Card: Eligible commercially insured patients receive up to $60 off one box of Novo Nordisk needles by activating the savings card; for additional information contact the program at 877-304-6855.",
                "brand": "NovoFine Needles",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 60,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2d3"
            },
            {
                "_id": "6297bcace0e0db6c2572b4de",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin 12 Hour Cough Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4de"
            },
            {
                "_id": "6297bcace0e0db6c2572b2d8",
                "link": "https://www.novocare.com/insulin/my99insulin.html?src=100001849",
                "description": "Novolin R My$99Insulin Card: Eligible patients pay $99 per monthly prescription (up to 3 vials or 2 packs of pens) of Novo Nordisk insulin products; offer may be used once a month during a calendar year; for additional information contact the program at 888-910-0632.",
                "brand": "Novolin R",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2d8"
            },
            {
                "_id": "6297bcace0e0db6c2572af6c",
                "link": "https://www.azmedcoupons.com/#farxiga",
                "description": "Farxiga Free Trial Offer: Eligible patients may receive a FREE 30-day supply of Farxiga; for more information contact the program directly at 844-631-3978.",
                "brand": "Farxiga",
                "copay": "No",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af6c"
            },
            {
                "_id": "6297bcace0e0db6c2572b4e3",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Childrens Extended Release 12 Hour Cough Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4e3"
            },
            {
                "_id": "6297bcace0e0db6c2572af71",
                "link": "https://farydak.com",
                "description": "Farydak Secura Care Co-Pay Program: Eligible commercially insured patients may pay no more than $5 per monthly prescription with a maximum savings of $25,000 per calendar year; for additional information contact the program at 844-973-2872.",
                "brand": "Farydak",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.33,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af71"
            },
            {
                "_id": "6297bcace0e0db6c2572af76",
                "link": "https://www.hematologysupport.com/financial-assistance#insurance",
                "description": "Feiba Takeda HSC CoPay Assistance Program: Eligible commercially insured patients' eligible out-of-pocket costs to be covered 100% by the program; for additional information contact the program at 888-229-8379.",
                "brand": "Feiba VH",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af76"
            },
            {
                "_id": "6297bcace0e0db6c2572af7b",
                "link": "https://www.us.kisqali.com/metastatic-breast-cancer/patient-support/financial-resources/",
                "description": "Femara 1 Free Treatment Cycle: Eligible patients may receive a 1-treatment cycle supply for free; for additional information contact the program at 800-282-7630.",
                "brand": "Femara",
                "copay": "No",
                "bin": "601341",
                "rxpcn": "OHS",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af7b"
            },
            {
                "_id": "6297bcace0e0db6c2572af80",
                "link": "https://feriva21-7.com/request-free-samples/",
                "description": "FeRiva 21/7 Samples: Your healthcare provider may request FREE samples  by filling out a request form online or by calling 888-612-8466.",
                "brand": "FeRiva 21-7",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af80"
            },
            {
                "_id": "6297bcace0e0db6c2572af85",
                "link": "https://ferriprox.com/totalcare",
                "description": "Ferriprox Copay Program: Eligible commercially insured patients may pay $0 per month with savings of up to $10,000 annually; patients must renew their eligibility by December 31st of each year; for additional information contact the program at 866-758-7071.",
                "brand": "Ferriprox",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af85"
            },
            {
                "_id": "6297bcace0e0db6c2572af8a",
                "link": "https://www.novomedlink.com/diabetes/samples.html",
                "description": "Fiasp Samples: Healthcare providers may submit a sample request every 30 days.",
                "brand": "Fiasp",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af8a"
            },
            {
                "_id": "6297bcace0e0db6c2572b2dd",
                "link": "https://www.novocare.com/diabetes-overview/let-us-help/novopenecho-savings.html",
                "description": "NovoPen Echo Savings Card Program: Eligible commercially insured patients may pay as little as $0 per device; maximum savings of $54 per device; for additional information contact the program at 888-910-1264.",
                "brand": "NovoPen Echo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 54,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2dd"
            },
            {
                "_id": "6297bcace0e0db6c2572af8f",
                "link": "https://www.fiberchoice.com",
                "description": "Register your email address with Fiber Choice to receive special offers, promotions, and more.",
                "brand": "Fiber Choice Metabolism and Energy Fruity Bites",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af8f"
            },
            {
                "_id": "6297bcace0e0db6c2572b4e8",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Cough plus Chest Congestion DM Liquid Filled Capsules",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4e8"
            },
            {
                "_id": "6297bcace0e0db6c2572af94",
                "link": "https://www.firazyr.com/onepath-support/onepath-co-pay-assistance-program",
                "description": "Firazyr OnePath Co-Pay Assistance Program: Eligible commercially insured patients may save up to 100% off co-pay expenses; for additional information contact the program at 866-888-0660.",
                "brand": "Firazyr",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af94"
            },
            {
                "_id": "6297bcace0e0db6c2572b2e2",
                "link": "https://www.nubeqacopayprogram.com/using-your-card",
                "description": "Nubeqa $0 Copay Program Reimbursement: Eligible commercially insured patients who paid out-of-pcoket for the entire cost of their prescription may fill out Co-pay Expenditure Form to be reimbursed; for additional information contact the program at 647-245-5642.",
                "brand": "Nubeqa",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2e2"
            },
            {
                "_id": "6297bcace0e0db6c2572af99",
                "link": "https://www.womenshealthcaresolutions.com/patient-material-request",
                "description": "First Response Product Free Samples: Your healthcare provider may request samples for you for any one listed Women's product; for additional information contact a representative at 800-487-6526.",
                "brand": "First Response Gold Digital Pregnancy Test",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af99"
            },
            {
                "_id": "6297bcace0e0db6c2572af9e",
                "link": "https://www.pfizermedicalinformation.com/en-us/flagyl?section=indications-usage",
                "description": "Flagyl Samples: Your healthcare provider may request samples by contacting PfizerPro online or by calling 800-505-4426.",
                "brand": "Flagyl",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572af9e"
            },
            {
                "_id": "6297bcace0e0db6c2572afa3",
                "link": "https://www.flector.com/patientsavings.html",
                "description": "Flector Direct Program: Patients may pay low out-of-pocket costs for their prescriptions when using a participating mail-order pharmacy in the program network; prescriptions are delievered directly to the patient's home; for additional information contact customer service at 844-247-0961.",
                "brand": "Flector",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afa3"
            },
            {
                "_id": "6297bcace0e0db6c2572afa8",
                "link": "https://www.flonase.com/coupons/",
                "description": "Flonase Program: Join the and receive a $2-5 coupon for any 1 product  and earn points for future savings; 1 coupon per purchase.",
                "brand": "Flonase Allergy Relief - Childrens",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afa8"
            },
            {
                "_id": "6297bcace0e0db6c2572afad",
                "link": "https://www.florastor.com/coupons.html",
                "description": "Save $4 on your next FlorastorKids product purchase; 1 coupon per purchase",
                "brand": "Florastor Kids",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afad"
            },
            {
                "_id": "6297bcace0e0db6c2572afb2",
                "link": "https://almiralladvantage.com/",
                "description": "Fluoroplex Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $35 per prescription when using an out-of-network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Fluoroplex",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afb2"
            },
            {
                "_id": "6297bcace0e0db6c2572b4ed",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Long-Acting CoughGels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4ed"
            },
            {
                "_id": "6297bcace0e0db6c2572b2e7",
                "link": "https://www.nucynta.com/patient/home/",
                "description": "Nucynta ER Savings Card: Eligible commercially insured patients may pay as little as $35 per fill with a maximum savings of up to $100 per prescription; for additional information contact the program at 844-807-0348.",
                "brand": "Nucynta ER",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2e7"
            },
            {
                "_id": "6297bcace0e0db6c2572afb7",
                "link": "https://www.folotyn.com/patient-resource-center/",
                "description": "Folotyn STAR Copay Assistance Program: Eligible commercially insured patients will pay $0 copay for the 1st date of service and a $25 copay for  subsequent dates of service; maximum savings of $10,000 per calendar year; for additional information contact the program at 888-537-8277.",
                "brand": "Folotyn",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afb7"
            },
            {
                "_id": "6297bcace0e0db6c2572b2ec",
                "link": "https://www.acadiaconnect.com/patient-caregivers/cost-insurance",
                "description": "Nuplazid Co-Pay Card Program: Eligible commercially insured patients may pay $0 per copay per prescription; for additional information contact the program 844-737-2223.",
                "brand": "Nuplazid",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2ec"
            },
            {
                "_id": "6297bcace0e0db6c2572b2f1",
                "link": "https://www.nutropin.com/patient/patient-support/nutropin-gps-co-pay-card-program.html",
                "description": "Nutropin AQ GPS Co-Pay Card Program: Eligible commercially insured patients pay as little as $5 per prescription with savings of up to $5000 per 12-month enrollment cycle; patient may re-enroll each year; for additional information contact the program at 866-688-7674.",
                "brand": "Nutropin AQ",
                "copay": "Yes",
                "bin": "21940",
                "rxpcn": "PYS",
                "copay_max": "5000",
                "lifetime_uses": "12",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2f1"
            },
            {
                "_id": "6297bcace0e0db6c2572b4f2",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Severe Multi-Symptom Cough Cold  Flu",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4f2"
            },
            {
                "_id": "6297bcace0e0db6c2572afbc",
                "link": "https://www.myfreestyle.com/offers#i_have_insurance",
                "description": "FreeStyle Promise Program: Receive a FREE FreeStyle Freedom Lite Meter and automatic savings on test strips; for additional information contact the program at 888-522-5226.",
                "brand": "FreeStyle Freedom Lite Blood Glucose Monitoring System",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afbc"
            },
            {
                "_id": "6297bcace0e0db6c2572afc1",
                "link": "https://www.myfreestyle.com/offers#i_dont_have_insurance",
                "description": "FreeStyle Precision Neo Program: Pay as little as $25 for 50 test strips; for additional information contact the program at 888-522-5226.",
                "brand": "FreeStyle Precision Neo Blood Glucose Monitoring System",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afc1"
            },
            {
                "_id": "6297bcace0e0db6c2572afc6",
                "link": "https://acrotechpatientaccess.com",
                "description": "Fusilev STAR Copay Assistance Program: Eligible commercially insured patients will pay $0 copay for the 1st date of service and a $25 copay for  subsequent dates of service; maximum savings of $10,000 per calendar year; for additional information contact the program at 888-537-8277.",
                "brand": "Fusilev",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afc6"
            },
            {
                "_id": "6297bcace0e0db6c2572afcb",
                "link": "https://uspco.com/product_literature.aspx?pid=435&tab=Voucher",
                "description": "Fusion Sprinkles Pharmacy Voucher: Eligible patients may save up to 80% at participating pharmacies; for additional information contact the program at  888-602-2978.",
                "brand": "Fusion Sprinkles",
                "copay": "No",
                "bin": "600428",
                "rxpcn": "5100000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afcb"
            },
            {
                "_id": "6297bcace0e0db6c2572b4f7",
                "link": "https://www.rogaine.com/products/rogaine-subscription-program",
                "description": "Rogaine Subscribe Program: Subscribe and save up to 20% per order and get free shipping; for additional information contact the program at 855-839-3838.",
                "brand": "Rogaine for Men Solution",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4f7"
            },
            {
                "_id": "6297bcace0e0db6c2572b2f6",
                "link": "https://www.nuvigil.com/",
                "description": "Nuvigil Prescription Savings Card: Eligible patients may pay as little as $10 per prescription with savings of up to $250 per fill (minimum 14 tablet prescription/limit 2 uses per month); for additional information contact the program at 833-378-7362.",
                "brand": "Nuvigil",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "6000",
                "lifetime_uses": "24",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2f6"
            },
            {
                "_id": "6297bcace0e0db6c2572afd0",
                "link": "https://www.futuro-usa.com/3M/en_US/futuro-us/offers/coupons/",
                "description": "Save $5 off any one Futuro Braces or Support products; one coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Futuro Support or Brace Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afd0"
            },
            {
                "_id": "6297bcace0e0db6c2572afd5",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Ganirelix Heart for Heroes Program: Eligible veterans may receive fertility medications at no cost with program; please see website for qualification requirements; for additional information contact the program at 800-515-3784.",
                "brand": "Ganirelix Acetate",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afd5"
            },
            {
                "_id": "6297bcace0e0db6c2572b2fb",
                "link": "https://obcomplete.com/healthcare-professionals/",
                "description": "OB Complete One Samples: Healthcare providers may request samples by completing the online form.",
                "brand": "OB Complete One",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2fb"
            },
            {
                "_id": "6297bcace0e0db6c2572b4fc",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Rogaine products with registration.",
                "brand": "Rogaine for Men Unscented Foam",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4fc"
            },
            {
                "_id": "6297bcace0e0db6c2572afda",
                "link": "https://www.gas-x.com/coupon/",
                "description": "Register your email address to receive a coupon for savings on any one Gas-X product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Gas-X Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afda"
            },
            {
                "_id": "6297bcace0e0db6c2572b300",
                "link": "https://www.ocaliva.com",
                "description": "Ocaliva Interconnect $0 Co-Pay Program: Eligible commercially patients may pay as little as $0 co-pay per fill; for additional information contact the program at 844-622-4278.",
                "brand": "Ocaliva",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b300"
            },
            {
                "_id": "6297bcace0e0db6c2572b501",
                "link": "https://www.rogaine.com",
                "description": "Register your e-mail address and receive 10% off your 1st purchase of Rogaine.",
                "brand": "Rogaine for Women Solution",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b501"
            },
            {
                "_id": "6297bcace0e0db6c2572b305",
                "link": "https://www.ocuvite.com/buy-now",
                "description": "Receive a $3 coupon off any one Ocuvite product; email address required; 1 coupon per purchase.",
                "brand": "Ocuvite Eye Health Gummies",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b305"
            },
            {
                "_id": "6297bcace0e0db6c2572b30a",
                "link": "https://www.ocuvite.com/buy-now",
                "description": "Receive a $3 coupon off any one Ocuvite product; email address required; 1 coupon per purchase.",
                "brand": "Ocuvite Lutien and Zeaxanthin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b30a"
            },
            {
                "_id": "6297bcace0e0db6c2572b506",
                "link": "https://www.rolaids.com/en-us/coupon/",
                "description": "Save $2 on any one Rolaids product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Rolaids Ultra Strength Chewable Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b506"
            },
            {
                "_id": "6297bcace0e0db6c2572b30f",
                "link": "https://www.gileadadvancingaccess.com/financial-support/gilead-copay-card",
                "description": "Odefsey Advancing Access Co-pay Program: Eligible commercially insured patients may save up to a maximum of $6000 per year with no monthly limits; for additional information contact the program at  800-226-2056.",
                "brand": "Odefsey",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "ACCESS",
                "copay_max": "6000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b30f"
            },
            {
                "_id": "6297bcace0e0db6c2572b50b",
                "link": "https://roszetrx.com/savings/",
                "description": "Roszet Walgreens Savings Program: Eligible patients may pay $33 per 30-day supply when filling their prescription at a participating Walgreens; for additional information contact your local Walgreens Pharmacy.",
                "brand": "Roszet",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b50b"
            },
            {
                "_id": "6297bcace0e0db6c2572b314",
                "link": "https://www.olumiant.com/savings",
                "description": "Olumiant Savings Card: Eligible commercially insured patients may pay only $5 for each 30-day prescription; for additional information contact the program at 844-658-6426.",
                "brand": "Olumiant",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b314"
            },
            {
                "_id": "6297bcace0e0db6c2572b510",
                "link": "https://www.ruconest.com/patient-support/",
                "description": "Ruconest Copay Patient Savings Program: Eligible commercially insured patients may save on copay costs associated with therapy; for additional information contact the program at 855-613-4423.",
                "brand": "Ruconest",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b510"
            },
            {
                "_id": "6297bcace0e0db6c2572b515",
                "link": "https://www.pfizercopay.com/",
                "description": "Ruxience Pfizer Oncology Together Co-Pay Savings Program for Injectables: Eligible commercially insured patients may pay as little as $0 per treatment; maximum savings limits per calendar year apply; for more information contact the program at 877-744-5675.",
                "brand": "Ruxience",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b515"
            },
            {
                "_id": "6297bcace0e0db6c2572b319",
                "link": "https://www.omnitrope.com/your-omnisource/co-pay-support",
                "description": "Omnitrope Co-Pay Savings Program: Eligible uninsured patients may receive co-pay support in the amount of up to $417 per month with savings of up to $5000 per year; for additional information contact the program at 877-456-6794.",
                "brand": "Omnitrope",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "12",
                "max_saving_transaction": 417,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b319"
            },
            {
                "_id": "6297bcace0e0db6c2572b51a",
                "link": "https://copay.novartisoncology.com/",
                "description": "Rydapt Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Rydapt",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b51a"
            },
            {
                "_id": "6297bcace0e0db6c2572b31e",
                "link": "https://www.oneaday.com/coupons",
                "description": "Save $2 on any one One-A-Day Prenatal product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "One-A-Day Prenatal Multivitamin products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b31e"
            },
            {
                "_id": "6297bcace0e0db6c2572b323",
                "link": "https://store.orderfront.com/OneTouch/Account/LogOn?ReturnUrl=%2fOneTouch",
                "description": "OneTouch Verio Samples: Healthcare providers may request FREE samples online.",
                "brand": "OneTouch Verio",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b323"
            },
            {
                "_id": "6297bcace0e0db6c2572b51f",
                "link": "https://www.otsukapatientsupport.com/samsca",
                "description": "Samsca Commercial Copay Savings Program: Eligible commercially insured patients may pay only the 1st $10 of their copay/coinsurance deductibles, up to a maximum of $5400; prescription must be filled at a specialty pharmacy to take advantage of the porgram;for additional assistance contact the program at 855-242-7787.",
                "brand": "Samsca",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5400",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b51f"
            },
            {
                "_id": "6297bcace0e0db6c2572b328",
                "link": "https://store.orderfront.com/OneTouch/Account/LogOn?ReturnUrl=%2fOneTouch",
                "description": "OneTouch Verio Samples: Healthcare providers may request FREE samples online.",
                "brand": "OneTouch Verio Reflect Meter",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b328"
            },
            {
                "_id": "6297bcace0e0db6c2572b32d",
                "link": "https://www.onfi.com/paying-for-onfi",
                "description": "Onfi Copay Savings Card: Eligible commercially insured patients may pay as little as $10 per monthly prescriptions with savings of $600 per fill; offer is valid for up to 12 prescription fills of a 30-day supply each; for additional information contact the program at 855-809-5818.",
                "brand": "Onfi",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "7200",
                "lifetime_uses": "12",
                "max_saving_transaction": 600,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b32d"
            },
            {
                "_id": "6297bcace0e0db6c2572b332",
                "link": "https://www.panfoundation.org/",
                "description": "PAN Foundation",
                "brand": "Onglyza",
                "copay": " No",
                "bin": " 0",
                "rxpcn": " 0",
                "copay_max": "0.00",
                "lifetime_uses": " 12",
                "max_saving_transaction": null,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b332"
            },
            {
                "_id": "6297bcace0e0db6c2572b337",
                "link": "https://www.bmsaccesssupport.bmscustomerconnect.com/patient/financial-support",
                "description": "Opdivo/Yervoy (Regimen) BMS Oncology Co-Pay Assistance Program: Enrolled patients who have commercial insurance may pay no more than $25 per infusion with savings of up to $25,000 per year; for additional information contact the program at 800-861-0048.",
                "brand": "Opdivo Yervoy Regimen",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b337"
            },
            {
                "_id": "6297bcace0e0db6c2572b33c",
                "link": "https://www.eyefile.com/theeyesolution/Register",
                "description": "Save $2 on your next purchase of any one Opti-Free PureMoist Contact Lens Solution (10 oz or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Opti-Free PureMoist Contact Lens Solution",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b33c"
            },
            {
                "_id": "6297bcace0e0db6c2572b341",
                "link": "https://www.oracea.com/patient-savings",
                "description": "Oracea Express Scripts: Eligible patients may recevie a 3-month supply of Oracea for the price of a 2-month supply plus FREE delivery when using Express Scripts Pharmacy; for additional information contact the program at 800-903-6228.",
                "brand": "Oracea",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "4",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b341"
            },
            {
                "_id": "6297bcace0e0db6c2572b346",
                "link": "https://www.orencia.com/support-savings/on-call",
                "description": "Orencia Co-pay Assistance Card (for Self-Injection Patients): Eligible commercially insured patients may pay as little as $5 per month with savings of up to $15,000 for up to 13 uses per calendar year; eligible patients must re-enroll each year; for additional information contact the program at 800-673-6242.",
                "brand": "Orencia",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "13",
                "max_saving_transaction": 1153.85,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b346"
            },
            {
                "_id": "6297bcace0e0db6c2572b34b",
                "link": "https://www.orgovyx.com/support-and-resources/#paying-for-orgovyx",
                "description": "Orgovym Copay Assistance Program: Eligible commercially insured patients may pay as little as $10 per month; for additional information contact the program at 833-674-6899.",
                "brand": "Orgovyx",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b34b"
            },
            {
                "_id": "6297bcace0e0db6c2572b350",
                "link": "https://www.oriahnn.com/savings-and-insurance",
                "description": "Oriahnn Complete Savings Card: Eligible commercially insured patients may pay as little as $5 per monthly fill; for more information contact the program at 800-674-2466.",
                "brand": "Oriahnn",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b350"
            },
            {
                "_id": "6297bcace0e0db6c2572b355",
                "link": "https://orphengesicforte.com/resources/",
                "description": "Orphengesic Forte GaltDirect Pharmacy Program: Eligible uninsured patients may pay as little as $30 per prescription through this program; for additional information contact the program at 855-965-2783.",
                "brand": "Orphengesic Forte",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b355"
            },
            {
                "_id": "6297bcace0e0db6c2572b35a",
                "link": "https://www.osphena.com/savings",
                "description": "Osphena Savings Card: Eligible cash-paying patients and commercially insured patients/RX not covered pay only $75 for 30 tablets or $190 for 90 tablets; for additional information contact the program at 866-339-0316.",
                "brand": "Osphena",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b35a"
            },
            {
                "_id": "6297bcace0e0db6c2572b35f",
                "link": "https://www.osteobiflex.com/ambassadors-club/",
                "description": "Osteo Bi-Flex Products: Join the Ambassador�s Club to receive coupons based on your past purchases.",
                "brand": "Osteo Bi-Flex Ease",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b35f"
            },
            {
                "_id": "6297bcace0e0db6c2572b524",
                "link": "https://santyl.com/card",
                "description": "Santyl Copay Assistance Program: Eligible patients may no more than $50 per prescription with savings of up to $250 on each of up to 6 fills with a $900 maximum savings per year; for additional information contact the program at 800-364-4767.",
                "brand": "Santyl",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "900",
                "lifetime_uses": "6",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b524"
            },
            {
                "_id": "6297bcace0e0db6c2572b364",
                "link": "https://www.otezla.com/supportplus/otezla-cost-and-copay",
                "description": "Otezla Bridge Program: Eligible commercially insured patients may receive FREE medication if they are experiencing a delay in coverage or waiting for prior authorization or appeals; may be eligible for free medication for up to 3 years; once medication is approved the patient may no longer receive free medication; for additional information contact the program at 844-468-3952.",
                "brand": "Otezla",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b364"
            },
            {
                "_id": "6297bcace0e0db6c2572b529",
                "link": "https://www.sanoficareassist.com/sarclisa/copay",
                "description": "Sarclisa CareASSIST Copay Program Reimbursement Program: Eligible commercially insured patients may submit a reimbursement form if their pharmacy or provider does not participate in the Savings Program; for additional information contact the program at 833-930-2273.",
                "brand": "Sarclisa",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b529"
            },
            {
                "_id": "6297bcace0e0db6c2572b369",
                "link": "https://wraser-samples.com/collections/all",
                "description": "Otovel FREE Samples: Your healthcare provider may request samples for their practice by creating an online account.",
                "brand": "Otovel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b369"
            },
            {
                "_id": "6297bcace0e0db6c2572b52e",
                "link": "https://www.allergansavingscard.com/savella",
                "description": "Savella Patient Savings Program: Eligible commercially insured patients may pay as little as $20 per 30-day supply; valid for up to 12 prescription fills; for additional information contact then program at 866-262-2709.",
                "brand": "Savella",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b52e"
            },
            {
                "_id": "6297bcace0e0db6c2572b36e",
                "link": "https://www.emdseronofertility.com/en/patient/affording-treatment/medication-savings.html",
                "description": "Ovidrel GO Direct Rebate Program: Eligible self-pay patients may be able to receive a 10% discount via rebate off your out-of-pocket costs; for additional information contact the program at 866-441-9463.",
                "brand": "Ovidrel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b36e"
            },
            {
                "_id": "6297bcace0e0db6c2572b533",
                "link": "https://www.aspirerewards.com/landing",
                "description": "Sculptra ASPIRE Galderma Rewards: Join today and receive a $60 treatment certificate instantly; other valuable discounts and rebates with sign up; for additional information contact customer service at 844-527-7473.",
                "brand": "Sculptra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b533"
            },
            {
                "_id": "6297bcace0e0db6c2572b373",
                "link": "https://oxycontin.com/patient/savings-program.html",
                "description": "OxyContin Savings Program: Eligible commercially insured patients may have their out-of-pocket costs reduced; for additional assistance contact the program at 855-227-0303.",
                "brand": "OxyContin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b373"
            },
            {
                "_id": "6297bcace0e0db6c2572b378",
                "link": "https://astellaspharmasupportsolutions.com/patient/padcev/padcev_copay_assistance_program.aspx",
                "description": "Padcev Copay Assistance Program: Eligible commercially insured may pay as little as $5 per dose with a maximum savings of $25,000 per calendar year; for additional information contact the program at 888-402-0627.",
                "brand": "Padcev",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b378"
            },
            {
                "_id": "6297bcace0e0db6c2572b538",
                "link": "https://www.segluromet.com",
                "description": "Segluromet Savings Coupon: Eligible commercially insured patients may pay as little as $0 on each of up to 12 prescriptions with savings of up to $583 per fill; offer can be redeemed every 21 days; for additional information contact the program at 877-264-2454.",
                "brand": "Segluromet",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "6996",
                "lifetime_uses": "12",
                "max_saving_transaction": 583,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b538"
            },
            {
                "_id": "6297bcace0e0db6c2572b37d",
                "link": "https://pancreazeengage.com",
                "description": "Pancreaze Advantage Co-pay Card: Eligible uninsured patients may save up to $200 on out-of-pocket costs per 30-day prescription; for additional information contact the program at 888-238-8059.",
                "brand": "Pancreaze",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "2400",
                "lifetime_uses": "12",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b37d"
            },
            {
                "_id": "6297bcace0e0db6c2572b382",
                "link": "https://www.panoxyl.com/coupons/",
                "description": "Save $2 on any one PanOxyl Acne Wash product; 1 coupon per purchase, coupon expires 1 month after printing.",
                "brand": "PanOxyl Acne Cleansing Bar",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b382"
            },
            {
                "_id": "6297bcace0e0db6c2572b387",
                "link": "https://www.parodontax.com/coupon/",
                "description": "Register your email address to receive a coupon for any parodontax toothpaste product.",
                "brand": "Parodontax Complete Protection Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b387"
            },
            {
                "_id": "6297bcace0e0db6c2572b38c",
                "link": "https://pediasure.com/resources-and-rewards/pediasure-coupons",
                "description": "Sign up for the PediaSure Support2Grow program and save up to $100 with receive special offers.",
                "brand": "PediaSure Enteral 1.0 Cal",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b38c"
            },
            {
                "_id": "6297bcace0e0db6c2572b391",
                "link": "https://pediasure.com/resources-and-rewards/pediasure-coupons",
                "description": "Sign up for the PediaSure Support2Grow program and save up to $100 with receive special offers.",
                "brand": "PediaSure Grow and Gain Organic",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b391"
            },
            {
                "_id": "6297bcace0e0db6c2572b396",
                "link": "https://pediasure.com/resources-and-rewards/pediasure-coupons",
                "description": "Sign up for the PediaSure Support2Grow program and save up to $100 with receive special offers.",
                "brand": "PediaSure Peptide 1.5 Cal",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b396"
            },
            {
                "_id": "6297bcace0e0db6c2572b39b",
                "link": "https://www.pennsaid.com/resources-and-support/patient-resources",
                "description": "Pennsaid 2% Co-pay Card: Eligible patients may pay as little as $0 per month with savings of up to $1200 per 1 bottle prescription; for additional information contact the program at 844-865-8694.",
                "brand": "Pennsaid",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b39b"
            },
            {
                "_id": "6297bcace0e0db6c2572b3a0",
                "link": "https://mycareclubrewards.com/pepcid/?utm_medium=partner&utm_source=pepcid.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1f8mxe6*_ga*MTc3MDUxNTg0OS4xNjMzMDE2OTQ4*_ga_13VEM6N66E*MTYzMzAxNjk0OC4xLjEuMTYzMzAxNzAzOC41OQ..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Pepcid products with registration.",
                "brand": "Pepcid Complete",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3a0"
            },
            {
                "_id": "6297bcace0e0db6c2572b3a5",
                "link": "https://www.insupport.com/specialty-product-2/copay-assistance",
                "description": "Perseris Insupport Copay Assistance Program: Eligible privately insured patients may pay as little as $5 per injection; program exhausts after 13 doses or $8000 whichever comes first; for additional information contact the program at 844-467-7778.",
                "brand": "Perseris",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "8000",
                "lifetime_uses": "13",
                "max_saving_transaction": 615.38,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3a5"
            },
            {
                "_id": "6297bcace0e0db6c2572b3aa",
                "link": "https://www.copayassistancenow.com",
                "description": "Phesgo Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 copay per prescription and receive savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Phesgo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3aa"
            },
            {
                "_id": "6297bcace0e0db6c2572b3af",
                "link": "https://www.phillipsdigestive.com/coupon/",
                "description": "Register your email address to receive a coupon for any one Phillips' product; 1 coupon per purchase.",
                "brand": "Phillips Laxative Caplets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3af"
            },
            {
                "_id": "6297bcace0e0db6c2572b3b4",
                "link": "https://www.pifeltro.com/",
                "description": "Pifeltro Savings Coupon Rebate: If a commercially insured patient is unable to redeem the savings card at their retail or mail-order pharmacy the patient should contact the program within 30 days to request a Direct Member Reimbursement form for a rebate; for additional information contact program at 877-264-2454.",
                "brand": "Pifeltro",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6800",
                "lifetime_uses": "12",
                "max_saving_transaction": 566.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3b4"
            },
            {
                "_id": "6297bcace0e0db6c2572b3b9",
                "link": "https://www.plegridy.com/en_us/home/financial-support.html?cid=PPC-GOOGLE-Plegridy_DTC_Branded_Exact~S~PH~BR~NER~DTC~BR-plegridy+copay+card-NA-p31574618043&&cid=PPC-GOOGLE-Plegridy_DTC_Branded_Exact~S~PH~BR~NER~DTC~BR-plegridy+copay+card-ST-p31574618043&gclid=EAIaIQobChMIwPu5t4qs3AIVAQAAAB0BAAAAEAAYACAAEgJVzfD_BwE&gclsrc=aw.ds",
                "description": "Plegridy Biogen Copay Program: Eligible commercially insured patients may have their medication cost lowered to as little as $0; for additional information contact the 800-456-2255.",
                "brand": "Plegridy",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3b9"
            },
            {
                "_id": "6297bcace0e0db6c2572b53d",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "SelRx Artesa Labs Advantage Program: Eligible commercially insured/Rx not covered patients may pay as little as $45 per prescription when filling their prescription at a participating pharmacy;  for additional information contact the program at 877-264-2440.",
                "brand": "SelRx Shampoo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b53d"
            },
            {
                "_id": "6297bcace0e0db6c2572b542",
                "link": "https://www.selsunblue.com/en-us/coupon/",
                "description": "Save $1 on any Selsun Blue product; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Selsun Blue Full and Thick Antidandruff Shampoo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b542"
            },
            {
                "_id": "6297bcace0e0db6c2572b3be",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Plexion Artesa Labs Advantage Program: Eligible commercially insured patients may pay as little as $0 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Plexion Products",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3be"
            },
            {
                "_id": "6297bcace0e0db6c2572b3c3",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/overview/?utm_source=google&utm_medium=cpc&utm_term=super%20poligrip&utm_campaign=GS%20-%20Branded_Poligrip%20EX_DP&gclid=Cj0KCQiA962BBhCzARIsAIpWEL0O5zEg5LMkWP-xbEDatBaEd896PMS8BoY0ynLITYi-fwgKKWPSrcUaAiBqEALw_wcB&gclsrc=aw.ds",
                "description": "Polident Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Polident Clean and Protect for Partials",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3c3"
            },
            {
                "_id": "6297bcace0e0db6c2572b3c8",
                "link": "https://www.polident.com/en-us/save-now/proguard-coupon/",
                "description": "Save $1 on your purchase of any Polident ProGuard & Retainer Cleanser product by registering your email address; one coupon per purchase.\r\n",
                "brand": "Polident Pro Guard and Retainer Anti-Bacterial Daily Cleanser Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3c8"
            },
            {
                "_id": "6297bcace0e0db6c2572b3cd",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/products/ortho-cleanser/",
                "description": "Polident ProGuard Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Polident Pro Guard and Retainer Paste Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3cd"
            },
            {
                "_id": "6297bcace0e0db6c2572b3d2",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/products/propartial/",
                "description": "Polident ProPartials Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Polident ProPartials Foam Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3d2"
            },
            {
                "_id": "6297bcace0e0db6c2572b3d7",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/products/propartial/",
                "description": "Polident ProPartials Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Polident ProPartials Toothpaste",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3d7"
            },
            {
                "_id": "6297bcace0e0db6c2572b3dc",
                "link": "https://www.copayassistancenow.com/patients",
                "description": "Polivy Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Polivy",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3dc"
            },
            {
                "_id": "6297bcace0e0db6c2572b3e1",
                "link": "https://www.kyowakirincares.com/poteligeo-patients/?gclid=EAIaIQobChMIuJ2eoLSp5wIVSz0MCh1OwwKfEAAYASAAEgJ15vD_BwE",
                "description": "Poteligeo Co-Pay Assistance Program: Eligible commercially insured patients may receive co-pay assistance; for additional information contact the program at 833-552-2737.",
                "brand": "Poteligeo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3e1"
            },
            {
                "_id": "6297bcace0e0db6c2572b3e6",
                "link": "https://sebelapharma.com/savings-programs/",
                "description": "Pramosone Co-Pay Savings Card: Eligible commercially insured patients may pay as little as $10 per prescription; for additional information contact the program at 844-728-3479.",
                "brand": "Pramosone",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3e6"
            },
            {
                "_id": "6297bcace0e0db6c2572b3eb",
                "link": "https://www.premarinvaginalcream.com/savings-and-support",
                "description": "Premarin Savings Card (Vaginal Cream): Eligible commercially insured patients may pay as little as $35 on 2 prescription fills with savings of up to $150 per fill; maximum savings of $300 per calendar year; for additional information contact the program at 866-879-4600.",
                "brand": "Premarin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "300",
                "lifetime_uses": "2",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3eb"
            },
            {
                "_id": "6297bcace0e0db6c2572b3f0",
                "link": "https://www.pfizermedicalinformation.com/en-us/prempro-and-premphase",
                "description": "Premphase Samples: Your healthcare provider may request samples by contacting PfizerPro by visiting the website or calling 800-505-4426.",
                "brand": "Premphase",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3f0"
            },
            {
                "_id": "6297bcace0e0db6c2572b3f5",
                "link": "https://avionrx.com/healthcare-professionals/",
                "description": "Prenate Chewable Samples: Healthcare providers may order samples for their practice by filling out a form online or calling 888-612-8466.",
                "brand": "Prenate Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3f5"
            },
            {
                "_id": "6297bcace0e0db6c2572b3fa",
                "link": "https://prenate.com/patient-savings/",
                "description": "Prenate Elite eVoucherRx Program: Eligible patients pay no more that $20 per prescription fill at participating pharmacies; for additional information contact the program at 888-612-8466.",
                "brand": "Prenate Elite",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3fa"
            },
            {
                "_id": "6297bcace0e0db6c2572b3ff",
                "link": "https://avionrx.com/healthcare-professionals/",
                "description": "Prenate Mini Samples: Healthcare providers may order samples for their practice by filling out a form online or calling 888-612-8466.",
                "brand": "Prenate Mini",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3ff"
            },
            {
                "_id": "6297bcace0e0db6c2572b404",
                "link": "https://avionrx.com/healthcare-professionals/",
                "description": "Prenate Restore Samples: Healthcare providers may order samples for their practice by filling out a form online or calling 888-612-8466.",
                "brand": "Prenate Restore",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b404"
            },
            {
                "_id": "6297bcace0e0db6c2572b409",
                "link": "https://www.preparationh.com/coupons-and-special-offers/",
                "description": "Register your email address to receive a coupon for any one Preparation H product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Preparation H Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b409"
            },
            {
                "_id": "6297bcace0e0db6c2572b40e",
                "link": "https://www.preparationh.com/coupons-and-special-offers/",
                "description": "Register your email address to receive a coupon for any one Preparation H product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Preparation H Soothing Relief Cooling Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b40e"
            },
            {
                "_id": "6297bcace0e0db6c2572b413",
                "link": "https://www.sightmatters.com/join?utm_campaign=preservision-new&utm_content=cta&utm_medium=preservision-new_landing&utm_source=preservision-new_redirect",
                "description": "PreserVision SightMatters: Sign-up and receive coupons for Preservision AREDS 2�formula vitamins products valued up to�$60 per year.",
                "brand": "PreserVision Eye Vitamin AREDS 2 Formula and Multivitamin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b413"
            },
            {
                "_id": "6297bcace0e0db6c2572b418",
                "link": "https://www.preservision.com/Get-Coupons/Get-My-Coupon",
                "description": "$4 coupon off any PreserVision Eye Vitamin and Mineral Supplement 60 count or larger; 1 coupon per purchase.",
                "brand": "PreserVision Eye Vitamin AREDS 2 Formula MiniGels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b418"
            },
            {
                "_id": "6297bcace0e0db6c2572afdf",
                "link": "https://yourblueprint.com/financial-assistance/",
                "description": "Gavreto QuickStart Program: Eligible commercially insured patients may be eligible to get a temporary supply at no cost if you are waiting for insurance to approve coverage; for additional information contact the program at 888-258-7768.",
                "brand": "Gavreto",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afdf"
            },
            {
                "_id": "6297bcace0e0db6c2572b41d",
                "link": "https://www.preservision.com/eyecare-professionals/samples-coupons",
                "description": "PreserVision Samples: Eyecare professionals may request samples of PreserVision Eye Vitamins for their practice by calling 844-258-4837.",
                "brand": "PreserVision Eye Vitamin AREDS Formula Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b41d"
            },
            {
                "_id": "6297bcace0e0db6c2572afe4",
                "link": "https://gelclair.com",
                "description": "Gelclair Samples: Healthcare providers may request samples for their patients or practices by completing a request form and returning it via fax (833-411-0268) or email (gelclairsamples@rxsinfo.com).",
                "brand": "Gelclair",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afe4"
            },
            {
                "_id": "6297bcace0e0db6c2572afe9",
                "link": "https://www.genotropin.com/savings-options-for-genotropin",
                "description": "Genotropin Savings Program: Eligible commercially insured patients may pay as low as $0 per month with a savings of up to $5,000 per year; for additional information contact the program at 800-645-1280.",
                "brand": "Genotropin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "12",
                "max_saving_transaction": 416.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afe9"
            },
            {
                "_id": "6297bcace0e0db6c2572b422",
                "link": "https://www.prevymis.com/savings-coupon/",
                "description": "Prevymis Savings Coupon: Eligible commercially insured patients may pay as little as $15 per prescription on each of up to 4 qualifying prescription with savings of up to $2500 per fill; for additional information contact the program at 877-264-2454.",
                "brand": "Prevymis",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "4",
                "max_saving_transaction": 2500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b422"
            },
            {
                "_id": "6297bcace0e0db6c2572afee",
                "link": "https://www.geritol.com/coupons",
                "description": "Save $1 on any one Geritol product; limit 1 coupon per purchase.",
                "brand": "Geritol Multivitamin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afee"
            },
            {
                "_id": "6297bcace0e0db6c2572aff3",
                "link": "https://www.gilotrif.com/",
                "description": "Gilotrif Co-pay Assistance Program: Eligible commercially insured patients may pay $0 per prescription with savings of up to $5500 per month; for additional information contact the program at 877-546-5349.",
                "brand": "Gilotrif",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 5500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572aff3"
            },
            {
                "_id": "6297bcace0e0db6c2572aff8",
                "link": "https://www.glatopa.com/#copay",
                "description": "Glatopa Co-Pay Program: Eligible commercially insured patients may pay $0 copay per month with savings of up to $9000 annually; for additional information contact the program at 855-452-8672.",
                "brand": "Glatopa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "9000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572aff8"
            },
            {
                "_id": "6297bcace0e0db6c2572b427",
                "link": "https://www.janssencarepath.com/patient/prezista/cost-support",
                "description": "Prezista Janssen CarePath Savings Program Rebate: Eligible commercially insured patients may submit a rebate request if the pharmacy does not accept the Savings Card; for additional assistance contact the program at 866-836-0114.",
                "brand": "Prezista",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "7500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b427"
            },
            {
                "_id": "6297bcace0e0db6c2572b547",
                "link": "https://myviivcard.com",
                "description": "Selzentry ViiVConnect Savings Card: Eligible commercially insured patients pay $0 per prescription with savings of up to $4800 per year with no monthly limit; for additional information contact the program at 844-588-3288.",
                "brand": "Selzentry",
                "copay": "Yes",
                "bin": "610626",
                "rxpcn": "1016",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b547"
            },
            {
                "_id": "6297bcace0e0db6c2572affd",
                "link": "https://glucerna.com/signup",
                "description": "Sign up with Glucerna Everyday Progress and receive savings of up to $24 on Glucerna products and much more.",
                "brand": "Glucerna Hunger Smart Shakes",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572affd"
            },
            {
                "_id": "6297bcace0e0db6c2572b42c",
                "link": "https://primacarerx.com/savings/",
                "description": "PrimaCare eVoucherRx Program: Eligible patients pay no more than $20; for additional information contact the program at 888-612-8466.",
                "brand": "PrimaCare",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b42c"
            },
            {
                "_id": "6297bcace0e0db6c2572b54c",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/sensodyne/overview/",
                "description": "Sensodyne Samples: Dental professionals may request FREE samples of Sensodyne products for their practice.",
                "brand": "Sensodyne Complete Protection Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b54c"
            },
            {
                "_id": "6297bcace0e0db6c2572b002",
                "link": "https://www.pfizermedicalinformation.com/en-us/glucotrol",
                "description": "Glucotrol Samples: Your healthcare provider may request samples by contacting PfizerPro online or calling 800-505-4426.",
                "brand": "Glucotrol",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b002"
            },
            {
                "_id": "6297bcace0e0db6c2572b007",
                "link": "https://www.gocovri.com/getting-gocovri#gocovri-onboard",
                "description": "Gocovri Co-Pay Program: Eligible commercially insured patients may pay no more than $20 per prescription; for additional information contact the program at 844-462-6874.",
                "brand": "Gocovri",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b007"
            },
            {
                "_id": "6297bcace0e0db6c2572b00c",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Extra Strength Body Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b00c"
            },
            {
                "_id": "6297bcace0e0db6c2572b551",
                "link": "https://www.sensodyne.com/en-us/coupon/",
                "description": "Save $1 on any one Sensodyne toothpaste product (excludes travel sizes) with registration of your name and email address; coupon expires 1 month after printing.",
                "brand": "Sensodyne ProNamel Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b551"
            },
            {
                "_id": "6297bcace0e0db6c2572b011",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond Ultimate lotions and cream; coupon expires 1 month after printing.",
                "brand": "Gold Bond Medicated Maximum Strength Foot Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b011"
            },
            {
                "_id": "6297bcace0e0db6c2572b431",
                "link": "https://www.cslbehring.com/patients/support-and-assistance",
                "description": "Privigen CSL Behring Assurance: Eligible commercially insured patients receive a point for every month of continuous medication use and may redeem points for free medication if there be a lapse in their insurance coverage; for additional information contact the program at 877-355-4447.",
                "brand": "Privigen",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b431"
            },
            {
                "_id": "6297bcace0e0db6c2572b016",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond No Mess Foot Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b016"
            },
            {
                "_id": "6297bcace0e0db6c2572b556",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/sensodyne/overview/",
                "description": "Sensodyne Samples: Dental professionals may request FREE samples of Sensodyne products for their practice.",
                "brand": "Sensodyne Repair and Protect Deep Repair Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b556"
            },
            {
                "_id": "6297bcace0e0db6c2572b436",
                "link": "https://www.prograf.com/savings-information",
                "description": "Prograf Astellas Copay Program: Eligible commercially insured patients may pay as little as $0 per prescription and save up to $3000 annually; card valid for up to 12 months from date of enrollment; must re-enroll each year for additional information contact the program at 866-790-7659.",
                "brand": "Prograf",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b436"
            },
            {
                "_id": "6297bcace0e0db6c2572b01b",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Therapeutic Foot Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b01b"
            },
            {
                "_id": "6297bcace0e0db6c2572b020",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Ultimate Diabetics Dry Skin Relief Foot Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b020"
            },
            {
                "_id": "6297bcace0e0db6c2572b43b",
                "link": "https://amgenfirststep.com/register-card",
                "description": "Prolia Amgen First Step Program:  Eligible commercially insured patients may save on their out-of-pocket medication costs; for additional information contact the program at 888-427-7478.",
                "brand": "Prolia",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b43b"
            },
            {
                "_id": "6297bcace0e0db6c2572b55b",
                "link": "https://products.coloplast.us/products/ostomy-care/#%26currentPage%3D1%26facetStrings%3DAllowSamplingFacet%3ATrue",
                "description": "Receive FREE samples for SenSura Colostomy products; sign up required; for additional information contact the program at 888-726-7872.",
                "brand": "SenSura-Colostomy Pouches",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b55b"
            },
            {
                "_id": "6297bcace0e0db6c2572b025",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Ultimate Mens Essentials Intensive Therapy Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b025"
            },
            {
                "_id": "6297bcace0e0db6c2572b440",
                "link": "https://www.pfizermedicalinformation.com/en-us/protonix?tab=pi&section=what-is-protonix",
                "description": "Protonix Samples: Your healthcare provider may request samples by logging into the PfizerPro website or calling 800-505-4426.",
                "brand": "Protonix",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b440"
            },
            {
                "_id": "6297bcace0e0db6c2572b02a",
                "link": "https://www.gralise.com/",
                "description": "Gralise Savings Card: Eligible commercially insured patients may pay as little as $28 per prescription fill; offer valid for 24 uses in a year; for additional information contact the program at 855-439-2821.",
                "brand": "Gralise",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "24",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b02a"
            },
            {
                "_id": "6297bcace0e0db6c2572b445",
                "link": "https://uspco.com/product_literature.aspx?pid=52&tab=Contact",
                "description": "Provida OB Samples: Healthcare providers may request samples for their practice by filling out a form online.",
                "brand": "Provida OB",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b445"
            },
            {
                "_id": "6297bcace0e0db6c2572b02f",
                "link": "https://www.alksavings.com/cb/access/crp/aLkYd2j78Vbb/fid.jsp?q=51G5OI5442CUDK4PLWTLX5&f=IMPX6IG-274EMWRGK6802#/app/layout/home",
                "description": "Grastek ALK Savings Card: Eligible commercially insured patients/RX not covered may pay as little as $75 per 30-day prescription if using an ALK Network Pharmacy; valid for 12 uses or $3,200 per year (whichever comes first); for additional information contact the program at 800-325-7354.",
                "brand": "Grastek",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3200",
                "lifetime_uses": "12",
                "max_saving_transaction": 265,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b02f"
            },
            {
                "_id": "6297bcace0e0db6c2572b44a",
                "link": "https://www.pulmicortflexhalertouchpoints.com/savings-card.html",
                "description": "Pulmicort Flexhaler Savings Card: Eligible uninsured/cash-paying patients may save $50 per 30-day supply; good for 12 uses; for additional information contact the program at 800-236-9933.",
                "brand": "Pulmicort Flexhaler",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "600",
                "lifetime_uses": "12",
                "max_saving_transaction": 50,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b44a"
            },
            {
                "_id": "6297bcace0e0db6c2572b034",
                "link": "https://www.haegarda.com/support-resources",
                "description": "Haegarda Connect Co-pay Support: Eligible commercially insured patients may receive co-pay support with enrollment; for additional information contact the program at 844-423-4273.",
                "brand": "Haegarda",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b034"
            },
            {
                "_id": "6297bcace0e0db6c2572b44f",
                "link": "https://www.bausch.com/our-products/contact-lenses/lenses-for-nearsighted-farsighted/purevision2-contact-lenses/free-trial",
                "description": "PureVision2 Contact Lenses Free Offer: Patients may print a Free Trial Lens Certificate to recevie a FREE pair of PureVision2 contact lenses from their eyecare professional; for additional information contact the program at 800-553-5340.",
                "brand": "PureVision2 for Presbyopia",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b44f"
            },
            {
                "_id": "6297bcace0e0db6c2572b039",
                "link": "https://qv.trialcard.com/Asegua#/app/layout/home",
                "description": "Asegua Co-pay Coupon Program for Generic of Harvoni (Ledipasvir/Sofosbuvir): Eligible patients may pay as little as $5 per co-pay; for additional information contact the program at 800-445-3235.",
                "brand": "Harvoni",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b039"
            },
            {
                "_id": "6297bcace0e0db6c2572b454",
                "link": "https://www.qelbree.com/adhd-support-and-savings",
                "description": "Qelbree Savings Card: Eligible patients may pay as little as $20 per 30-day prescription; for additional information contact the program at 866-398-0833.",
                "brand": "Qelbree",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b454"
            },
            {
                "_id": "6297bcace0e0db6c2572b03e",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Clinical Itch Relief Mist",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b03e"
            },
            {
                "_id": "6297bcace0e0db6c2572b459",
                "link": "https://www.activatethecard.com/6866/?utm_source=print&utm_medium=card&utm_campaign=offline2018&utm_content=saveonqsymia",
                "description": "Qsymia Savings Card: Eligible commercially insured patients may pay $70 per monthly prescription; for additional information contact the program at 855-264-9634.",
                "brand": "Qsymia",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "780",
                "lifetime_uses": "12",
                "max_saving_transaction": 65,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b459"
            },
            {
                "_id": "6297bcace0e0db6c2572b043",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Dry Scalp Care with Almond Oil Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b043"
            },
            {
                "_id": "6297bcace0e0db6c2572b45e",
                "link": "https://qudexyxr.com/savings-support/",
                "description": "Qudexy XR Blink Pharmacy Plus: Eligible commercially insured patients may pay the lowest possible out-of-pocket cost based on insurance coverage; for additional information contact the program at 844-976-3109.",
                "brand": "Qudexy XR",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b45e"
            },
            {
                "_id": "6297bcace0e0db6c2572b048",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Mens Refreshing Menthol Anti-Dandruff Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b048"
            },
            {
                "_id": "6297bcace0e0db6c2572b04d",
                "link": "https://www.pfizermedicalinformation.com/en-us/hemabate",
                "description": "Hemabate Samples: Your healthcare provider may request samples by contacting PfizerPro online or calling 800-505-4426.",
                "brand": "Hemabate",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b04d"
            },
            {
                "_id": "6297bcace0e0db6c2572b463",
                "link": "https://www.quliptahcp.com/?cid=ppc_ppd_ggl_qulipta_hcp_branded-hcp_qulipta_phrase_atp150165&gclid=Cj0KCQjwwNWKBhDAARIsAJ8HkhdngQXQ1pUx65dxCEGHSCA_nrs7J5gCj_nra4IVIvELexqpkgfnXa0aAn3lEALw_wcB&gclsrc=aw.ds",
                "description": "Qulipta Samples: Healthcare providers may order samples by faxing an order form to 877-477-1258.",
                "brand": "Qulipta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b463"
            },
            {
                "_id": "6297bcace0e0db6c2572b052",
                "link": "https://www.boironusa.com/hemcalm-coupon/",
                "description": "$2 coupon for any HemCalm product; 1 coupon per purchase.",
                "brand": "HemCalm Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b052"
            },
            {
                "_id": "6297bcace0e0db6c2572b468",
                "link": "https://www.qvar.com/redihaler/redihaler-cost-savings",
                "description": "QVAR RediHaler Savings Card: Eligible commercially insured patients may pay as little as $15 on each of up to 25 prescriptions fills; for additional information contact the program at 844-807-0061.",
                "brand": "QVAR RediHaler",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "300",
                "lifetime_uses": "12",
                "max_saving_transaction": 25,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b468"
            },
            {
                "_id": "6297bcace0e0db6c2572b057",
                "link": "https://www.hematologysupportpro.com/financial-assistance#coPayAssistance",
                "description": "Hemofil-M Takeda HSC CoPay Assistance Program: Eligible commercially insured patients' eligible out-of-pocket costs to be covered 100% by the program; for additional information contact the program at 888-229-8379.",
                "brand": "Hemofil-M",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b057"
            },
            {
                "_id": "6297bcace0e0db6c2572b46d",
                "link": "https://www.alksavings.com/cb/access/crp/aLkYd2j78Vbb/fid.jsp?q=51G5OI5442CUDK4PLWTLX5&f=IMPX6IG-274EMWRGK6802#/app/layout/home",
                "description": "Ragwitek ALK Savings Card Program: Eligible commercially insured patients/RX not covered may pay $100 per 30-day prescription if using a retail pharmacy; valid for 12 uses or $3,200 per year (whichever comes first); for additional information contact the program at 800-325-7354.",
                "brand": "Ragwitek",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3200",
                "lifetime_uses": "12",
                "max_saving_transaction": 265,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b46d"
            },
            {
                "_id": "6297bcace0e0db6c2572b05c",
                "link": "https://www.herzuma.com/resources-and-support",
                "description": "Herzuma Cost Support Program: Eligible commercially insured patients may pay as little as $0 per fill; maximum annual limit of $25,000; for additional information contact the program at 888-587-3263.",
                "brand": "Herzuma",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b05c"
            },
            {
                "_id": "6297bcace0e0db6c2572b472",
                "link": "https://www.rayosrx.com/save-on-rayos",
                "description": "Rayos Patient Savings Program: Eligible patients may pay as little as $0 per prescription with savings of up to $800 per fill (30 pills); for additional information contact the program at 855-226-4006.",
                "brand": "Rayos",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "9600",
                "lifetime_uses": "12",
                "max_saving_transaction": 800,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b472"
            },
            {
                "_id": "6297bcace0e0db6c2572b061",
                "link": "https://www.hollister.com/en/samplecheckoutpage",
                "description": "Hollister Critical Care Product Samples: Request FREE samples of Hollister Critical Care products by completing the online form; may request multiple product samples; for additional information contact the program at 888-808-7456.",
                "brand": "Hollister Critical Care Products",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b061"
            },
            {
                "_id": "6297bcace0e0db6c2572b477",
                "link": "https://www.recticare.com/coupons/",
                "description": "Receive an instant $3 coupon for any one RectiCare product by registering your email address online; 1 coupon per purchase.",
                "brand": "RectiCare Advanced Hemorrhoidal Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b477"
            },
            {
                "_id": "6297bcace0e0db6c2572b066",
                "link": "https://arborpatientdirect.com",
                "description": "Horizant Arbor Patient Direct: Eligible patients may pay as little as $40 plus shipping for a 30-day supply or $120 for a 90-day supply with FREE shipping by enrolling in the program; for additional information contact the program at 844-289-3981.",
                "brand": "Horizant",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b066"
            },
            {
                "_id": "6297bcace0e0db6c2572b06b",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog KwikPen: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog KwikPen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b06b"
            },
            {
                "_id": "6297bcace0e0db6c2572b070",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog Mix 75-25: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog Mix 75-25",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b070"
            },
            {
                "_id": "6297bcace0e0db6c2572b075",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog U-100: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog U-100",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b075"
            },
            {
                "_id": "6297bcace0e0db6c2572b07a",
                "link": "https://www.humira.com/humira-complete/cost-and-copay",
                "description": "Humira Complete Savings Card Rebate: If your pharmacy does not accept the Savings Card you may qualify for a rebate for your out-of-pocket cost; for additional information contact the program at 800-448-6472.",
                "brand": "Humira",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6000",
                "lifetime_uses": "12",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b07a"
            },
            {
                "_id": "6297bcace0e0db6c2572b07f",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humulin N KwikPen: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humulin N",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b07f"
            },
            {
                "_id": "6297bcace0e0db6c2572b47c",
                "link": "https://reditrex.com/coupon/",
                "description": "RediTrex Web Coupon: Eligible patients pay as little as $25 per one-month supply; valid for up to 13 uses per year; for additional information contact the program at 877-881-5702.",
                "brand": "RediTrex",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "900",
                "lifetime_uses": "12",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b47c"
            },
            {
                "_id": "6297bcace0e0db6c2572b481",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Lacri-Lube Eye Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b481"
            },
            {
                "_id": "6297bcace0e0db6c2572b486",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Optive Gel Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b486"
            },
            {
                "_id": "6297bcace0e0db6c2572b48b",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Relieva",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b48b"
            },
            {
                "_id": "6297bcace0e0db6c2572b490",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Tears",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b490"
            },
            {
                "_id": "6297bcace0e0db6c2572b495",
                "link": "https://ec4ea071-fbcb-424f-b1c2-cf62cecd02ee.filesusr.com/ugd/72e81f_ff8712ca24fb46138474f91660fcfdec.pdf",
                "description": "ReliOn Novolin R FlexPen Savings: Eligible patients may save from 58% to 75% off the cash price per�prescription; offer available only at Walmart; for additional information contact your nearest Walmart Pharmacy.",
                "brand": "ReliOn Novolin R Insulin FlexPen",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b495"
            },
            {
                "_id": "6297bcace0e0db6c2572b49a",
                "link": "https://relistor.copaysavingsprogram.com",
                "description": "Relistor (Injection) Savings Program: Eligible commercially insured patients may pay as little as $0 per prescription; card may be used 12 times before expiration date; for additional information contact the program at 855-202-3719.",
                "brand": "Relistor",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b49a"
            },
            {
                "_id": "6297bcace0e0db6c2572b49f",
                "link": "https://www.janssencarepath.com/patient/remicade/cost-support",
                "description": "Remicade Janssen CarePath Savings Program: Most eligible commercially insured patients pay just $5 per infusion with a savings of up to $20,000 per calendar year; for additional information contact the program at 877-227-3728.",
                "brand": "Remicade",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b49f"
            },
            {
                "_id": "6297bcace0e0db6c2572b4a4",
                "link": "http://www.renvalue.com",
                "description": "Renvela RenValue Mail-In Rebate: Patients who are eligible for the Copay Card using a mail-order pharmacy or a pharmacy that does not accept the Copay Card may receive a rebate by completing a rebate form; for additional information contact the program at 866-257-4605.",
                "brand": "Renvela",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3600",
                "lifetime_uses": "12",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4a4"
            },
            {
                "_id": "6297bcace0e0db6c2572b4a9",
                "link": "https://www.restasis.com/savings",
                "description": "Restasis My Tears, My Rewards Savings Card: Eligible patients may pay as little as $0 per 30-day prescription with a savings of $255 per fill; for additional information contact program at 844-469-8327.",
                "brand": "Restasis",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "3060",
                "lifetime_uses": "12",
                "max_saving_transaction": 255,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4a9"
            },
            {
                "_id": "6297bcace0e0db6c2572b4ae",
                "link": "https://uspco.com/product_literature.aspx?pid=380&tab=Contact",
                "description": "Restora Samples: Healthcare providers may request free samples for their practice by filling out a form online.",
                "brand": "Restora",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4ae"
            },
            {
                "_id": "6297bcace0e0db6c2572b4b3",
                "link": "https://www.aspirerewards.com/register",
                "description": "Restylane ASPIRE Galderma Rewards: Join today and receive a $60 treatment certificate instantly; other valuable discounts and rebates with sign up; for additional information contact customer service at 844-527-7473.",
                "brand": "Restylane",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4b3"
            },
            {
                "_id": "6297bcace0e0db6c2572b4b8",
                "link": "https://www.aspirerewards.com/landing",
                "description": "Restylane Refyne ASPIRE Galderma Rewards: Join today and receive a $60 treatment certificate instantly; other valuable discounts and rebates with sign up; for additional information contact customer service at 844-527-7473.",
                "brand": "Restylane Refyne",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4b8"
            },
            {
                "_id": "6297bcace0e0db6c2572b4bd",
                "link": "https://www.orthorxaccess.com/",
                "description": "Retin-A Micro Rx Access Program: Eligible commercially insured patients may pay $25 per prescription; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Retin-A MICRO",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4bd"
            },
            {
                "_id": "6297bcace0e0db6c2572b560",
                "link": "https://serostim.com/affording-treatment/",
                "description": "Serostim Copay Assistance Program: Eligible commercially insured patients may pay $0 copay with a maximum benefit of up to $1500 per fill; maximum annual benefit may not exceed $18000; offer may be used 13 times per year; for additional information contact the program at 877-714-2947.",
                "brand": "Serostim",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "18000",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b560"
            },
            {
                "_id": "6297bcace0e0db6c2572b4c2",
                "link": "https://www.revatio.com/savings",
                "description": "Revatio Savings Card: Eligible patients may pay as little as $0 per 30-day fill  with savings of up to $12,000 per year; for additional assistance contact the program at 866-732-4468.",
                "brand": "Revatio",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4c2"
            },
            {
                "_id": "6297bcace0e0db6c2572b4c7",
                "link": "https://www.boironusa.com/rhinallergy-coupon/",
                "description": "$2 coupon off RhinAllergy tablets with registration; 1 coupon per purchase.",
                "brand": "RhinAllergy",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4c7"
            },
            {
                "_id": "6297bcace0e0db6c2572b565",
                "link": "https://almiralladvantage.com/#pharmacies",
                "description": "Seysara Almirall Advantage Savings Card: Eligible commercially patients insured/RX not covered may pay no more than $45 per prescription when using an in-network pharmacy or $125 when using an out-of-network; for additional information contact the program at 888-591-9860.",
                "brand": "Seysara",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b565"
            },
            {
                "_id": "6297bcace0e0db6c2572b56a",
                "link": "https://www.siklosusa.com/savings",
                "description": "Siklos eVoucher Savings Program: Eligible commercially insured patients may pay as little as $0 per prescription at a participating Relay Health pharmacy; for additional information contact the program at 800-388-2316.",
                "brand": "Siklos",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b56a"
            },
            {
                "_id": "6297bcace0e0db6c2572b4cc",
                "link": "https://amgenfirststep.com",
                "description": "Riabni Amgen First Step Program Rebate: Eligible commercially insured patients who have already paid for treatment may request a rebate check; for additional information contact the program at 888-657-8371.",
                "brand": "Riabni",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4cc"
            },
            {
                "_id": "6297bcace0e0db6c2572b56f",
                "link": "https://simbrinza.myalcon.com/hcp/?gclid=CjwKCAiA7dKMBhBCEiwAO_crFObDSmK6QCzqlqwglPfWv4ZwaMVT00AqJe_wyFe51r-heUAPE5Z2uhoCc0kQAvD_BwE&gclsrc=aw.ds",
                "description": "Simbrinza Free Samples: Your healthcare provider may order samples online.",
                "brand": "Simbrinza",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b56f"
            },
            {
                "_id": "6297bcace0e0db6c2572b4d1",
                "link": "https://ridlice.com/coupons/",
                "description": "Save $2 off any one RID product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "RID SUPER MAX Lice Elimination Kit",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4d1"
            },
            {
                "_id": "6297bcace0e0db6c2572b574",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac for Supplementation",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b574"
            },
            {
                "_id": "6297bcace0e0db6c2572b4d6",
                "link": "https://www.janssencarepath.com/patient/risperdal-consta/cost-support",
                "description": "Risperdal Consta Janssen CarePath Savings Program: Eligible commercially insured patients may pay as little as $10 per does with an annual maximum savings of $5,500 or up to 26 doses per calendar year; for additional information contact the program at 877-227-3728.",
                "brand": "Risperdal Consta",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "5500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4d6"
            },
            {
                "_id": "6297bcace0e0db6c2572b579",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac NeoSure Infant Formula Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b579"
            },
            {
                "_id": "6297bcace0e0db6c2572b4db",
                "link": "https://www.rixubis.com/support-and-resources",
                "description": "Rixubis Takeda HSC CoPay Assistance Program: Eligible commercially insured patients' eligible out-of-pocket costs to be covered 100% by the program; for additional information contact the program at 888-229-8379.",
                "brand": "Rixubis",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4db"
            },
            {
                "_id": "6297bcace0e0db6c2572b57e",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac Pure Bilss Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b57e"
            },
            {
                "_id": "6297bcace0e0db6c2572b4e0",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Children's Cough and Cold CF",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4e0"
            },
            {
                "_id": "6297bcace0e0db6c2572b583",
                "link": "https://www.janssencarepath.com/patient/simponi-aria/cost-support?source=janssenprescriptionassistance",
                "description": "Simponi Aria Janssen CarePath Savings Program: Most commercially insured patients may pay $5 copay per infusion with savings of up to $20,000 per year on out-of-pocket costs; for additional information contact the program at 877-227-3728.",
                "brand": "Simponi Aria",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b583"
            },
            {
                "_id": "6297bcace0e0db6c2572b4e5",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Childrens Naturals Cough Relief Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4e5"
            },
            {
                "_id": "6297bcace0e0db6c2572b4ea",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Elderberry Cough plus Congestion DM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4ea"
            },
            {
                "_id": "6297bcace0e0db6c2572b588",
                "link": "https://www.pfizerpro.com/request-samples",
                "description": "Skelaxin Samples: Your healthcare provider may request samples by logging into the Pfizer Pro website or calling 800-505-4426.",
                "brand": "Skelaxin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b588"
            },
            {
                "_id": "6297bcace0e0db6c2572b4ef",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Naturals Cough Relief Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4ef"
            },
            {
                "_id": "6297bcace0e0db6c2572b58d",
                "link": "https://www.boironusa.com/sleepcalm-coupon/",
                "description": "$2 coupon for any SleepCalm product (2.6 oz or 4.1 oz); 1 coupon per purchase.",
                "brand": "SleepCalm",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b58d"
            },
            {
                "_id": "6297bcace0e0db6c2572b084",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humulin R U-100: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humulin R U-100",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b084"
            },
            {
                "_id": "6297bcace0e0db6c2572b4f4",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Severe Multi-Symptom Cough Cold  Flu Pack",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4f4"
            },
            {
                "_id": "6297bcace0e0db6c2572b592",
                "link": "https://slynd.com/resources/",
                "description": "Slynd Savings Program: Eligible uninsured/cash-pay patients pay $35 per 30-day prescription; for additional information contact the program at 844-865-8685.",
                "brand": "Slynd",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b592"
            },
            {
                "_id": "6297bcace0e0db6c2572b089",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humulin R U-500 KwikPen: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humulin R U-500 KwikPen",
                "copay": "No",
                "bin": "1884",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b089"
            },
            {
                "_id": "6297bcace0e0db6c2572b4f9",
                "link": "https://www.rogaine.com",
                "description": "Register your e-mail address and receive 10% off your 1st purchase of Rogaine.",
                "brand": "Rogaine for Men Solution",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4f9"
            },
            {
                "_id": "6297bcace0e0db6c2572b08e",
                "link": "https://www.eyefile.com/theeyesolution/register",
                "description": "Save $2 on your next purchase of any one I-Caps Eye Vitamin AREDS Product with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "I-Caps Eye Vitamin AREDS Formula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b08e"
            },
            {
                "_id": "6297bcace0e0db6c2572b093",
                "link": "https://www.takedaoncologycopay.com/",
                "description": "Iclusig Takeda Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay as little as $0 per prescription with savings of up to $25,000 per year; for additional information contact the program at 844-817-6468.",
                "brand": "Iclusig",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b093"
            },
            {
                "_id": "6297bcace0e0db6c2572b098",
                "link": "https://www.icyhot.com/coupons/",
                "description": "Save $1 on any one IcyHot product (excludes 1 count patch products and 1.25 oz cream products); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "IcyHot Balm",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b098"
            },
            {
                "_id": "6297bcace0e0db6c2572b4fe",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Rogaine products with registration.",
                "brand": "Rogaine for Woman Unscented Foam",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4fe"
            },
            {
                "_id": "6297bcace0e0db6c2572b09d",
                "link": "https://www.icyhot.com/coupons/",
                "description": "Save $1 on any one IcyHot product (excludes 1 count patch products and 1.25 oz cream products); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "IcyHot Lidocaine Dry Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b09d"
            },
            {
                "_id": "6297bcace0e0db6c2572b0a2",
                "link": "https://www.icyhot.com/coupons/",
                "description": "Save $1 on any one IcyHot product (excludes 1 count patch products and 1.25 oz cream products); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "IcyHot Vanishing Scent Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0a2"
            },
            {
                "_id": "6297bcace0e0db6c2572b503",
                "link": "https://www.rolaids.com/en-us/coupon/",
                "description": "Save $2 on any one Rolaids product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Rolaids Advanced Antacid Plus Anti Gas Chewable Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b503"
            },
            {
                "_id": "6297bcace0e0db6c2572b0a7",
                "link": "https://ilaris.com/info/sjia/ilaris-copay-assistance.jsp?usertrack.filter_applied=true&NovaId=3831502975509189036",
                "description": "Ilaris Co-pay Assistance Program: Eligible commercially insured patients may pay no more than $30 per prescription; for additional information contact the program at 866-972-8315.",
                "brand": "Ilaris",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0a7"
            },
            {
                "_id": "6297bcace0e0db6c2572b508",
                "link": "https://www.medimetriks.com/prescription-brands/rosadan-gel-kit-rosadan-gel",
                "description": "Rosadan Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Rosadan",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b508"
            },
            {
                "_id": "6297bcace0e0db6c2572b0ac",
                "link": "https://imcivree.com/support",
                "description": "Imcivree Co-pay Assistance Program: Eligible commercially insured patients may save on their out-of-pocket costs up to $8550 per year; for additional information contact the program at 844-968-7477.",
                "brand": "Imcivree",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "8550",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0ac"
            },
            {
                "_id": "6297bcace0e0db6c2572b50d",
                "link": "https://www.copayassistancenow.com/eligibility",
                "description": "Rozlytrek Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Rozlytrek",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b50d"
            },
            {
                "_id": "6297bcace0e0db6c2572b0b1",
                "link": "https://mycareclubrewards.com/imodium/?utm_medium=partner&utm_source=imodium.com&utm_content=footer&utm_campaign=cc_main&_gl=1*rj31n4*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjY1MDQ3OC41LjEuMTYzNjY1MDUzMC44*_fplc*SyUyQnh2V1hwcnlYTG1ONHJlN0g5NjE5OE93WG1hd21tWkI3RktEcGtsdTMlMkZtU3hsVGxjZ1VVMU92MUVzclZEakU5JTJGYXJPJTJGSXVqbkZBUWFNazRYa0dSZkE2YkdLWGxKdzdjNGhVaFplV3JzNTg3aVpJUEhOTmRYJTJGRmlId2RIdyUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Imodium A-D products with registration.",
                "brand": "Imodium A-D Children's",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0b1"
            },
            {
                "_id": "6297bcace0e0db6c2572b512",
                "link": "https://www.ruconest.com/patient-support/",
                "description": "Ruconest Bridge to Therapy Program: Commercially insured patients experiencing a delay in coverage and already used the StarterRx Program may be eligible in order to avoid a gap in coverage; for additional information contact the program at 855-613-4423.",
                "brand": "Ruconest",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b512"
            },
            {
                "_id": "6297bcace0e0db6c2572b0b6",
                "link": "https://savings.imvexxy.com/",
                "description": "Imvexxy Cash Pay Program: Eligible commercially insured RX/not covered and uninsured patients may pay as little as $75 on each of up to twelve (12) 30-day prescriptions; for additional information contact the program at 844-208-0002.",
                "brand": "Imvexxy",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0b6"
            },
            {
                "_id": "6297bcace0e0db6c2572b517",
                "link": "https://www.rybelsus.com/savings-and-support.html",
                "description": "Rybelsus Savings Card: Eligible commercially insured patients may pay as little as $10 per 30-day prescription with a maxium savings of $300 per fill; offer valid for 24 months after card activation; for additional information contact the program at 833-275-2233.",
                "brand": "Rybelsus",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "3600",
                "lifetime_uses": "12",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b517"
            },
            {
                "_id": "6297bcace0e0db6c2572b0bb",
                "link": "https://www.inderalxl.com",
                "description": "Inderal XL eVoucherRX $0 Copay Program: Eligible commercially insured patients pay $0 on out of pocket expenses up to a maximum savings of $300 per 30-day prescription; for additional information contact the program at 800-308-6755.",
                "brand": "Inderal XL",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3600",
                "lifetime_uses": "12",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0bb"
            },
            {
                "_id": "6297bcace0e0db6c2572b51c",
                "link": "https://www.myrytarycopay.com/Amnealflex/s/#/",
                "description": "Rytary Co-Pay Savings Card: Eligible patients may pay as little as $25 per 30-day prescription with a maximum benefit of $100 per fill; patient pays as little as $0 if written a second or third prescription within the same 30-day period; card is good for up to 3 prescription fills per 30-day period; for additional information contact the program at 844-467-2928.",
                "brand": "Rytary",
                "copay": "Yes",
                "bin": "6012",
                "rxpcn": "PDMI",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b51c"
            },
            {
                "_id": "6297bcace0e0db6c2572b521",
                "link": "https://sservices.trialcard.com/Coupon/Sancuso",
                "description": "Sancuso Copay Assistance Card Program: Eligible patients may pay only $20 per patch per month; maximum $1200 per month for 4 or more patches; for additional information contact the program at 877-251-4951.",
                "brand": "Sancuso",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "14400",
                "lifetime_uses": "12",
                "max_saving_transaction": 1200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b521"
            },
            {
                "_id": "6297bcace0e0db6c2572b597",
                "link": "https://www.soliqua100-33.com/savings-and-support",
                "description": "Soliqua Savings Card: Eligible uninsured patients pay as little as $99 per pack (up to 2 packs); maximum savings of $700 per pack; savings card is good for 12 months after 1st use then patient may re-apply for another savings card; for additional information contact the program at 855-262-5295.",
                "brand": "Soliqua",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "16800",
                "lifetime_uses": "12",
                "max_saving_transaction": 1400,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b597"
            },
            {
                "_id": "6297bcace0e0db6c2572b526",
                "link": "https://saphris.copaysavingsprogram.com",
                "description": "Saphris Savings Program: Eligible commercially insured patients may pay as little as $15 per prescription on each of up to 12 fills; for additional information contact the program at 855-439-2832.",
                "brand": "Saphris",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b526"
            },
            {
                "_id": "6297bcace0e0db6c2572b52b",
                "link": "https://sarnalotion.com/coupons/",
                "description": "Save $2 on any Sarna Anti-Itch Lotion; 1 coupon per purchase",
                "brand": "Sarna Sensitive Maximum Strength Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b52b"
            },
            {
                "_id": "6297bcace0e0db6c2572b59c",
                "link": "https://soltamox.com/patient-access/",
                "description": "Soltamox Mayne Pharma Patient Savings Card: Eligible commercially insured patients/RX not covered may pay $75 per prescription; for additional information contact the program at 347-442-7919.",
                "brand": "Soltamox",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b59c"
            },
            {
                "_id": "6297bcace0e0db6c2572b5a1",
                "link": "https://www.soriluxfoam.com/patient/mayne-pharma-patient-savings-card/",
                "description": "Sorilux Mayne Pharma Patient Savings Card: Eligible commercially insured patients may have a copay of $0 per prescription; for additional information contact the program at 347-442-7919.",
                "brand": "Sorilux",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5a1"
            },
            {
                "_id": "6297bcace0e0db6c2572b530",
                "link": "https://www.novocare.com/saxenda/savings-card.html",
                "description": "Saxenda Savings Card: Eligible commercially insured patients may pay as little as $25 per per 30-day supply; for additional information contact the program at 877-304-6894.",
                "brand": "Saxenda",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "2400",
                "lifetime_uses": "12",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b530"
            },
            {
                "_id": "6297bcace0e0db6c2572b535",
                "link": "https://www.secuado.com/noven-c-a-n-terms-and-conditions/",
                "description": "Secuado Bridge Program: Eligible commercially patients waiting for their insurance will receive a 30-day supply of the product at no charge; if a coverage decision has not been made within 30 calendar days of receiving the initial supply one additional 30-day supply may be received at no charge; for more information contact the program at 888-526-0132.",
                "brand": "Secuado",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b535"
            },
            {
                "_id": "6297bcace0e0db6c2572b5a6",
                "link": "https://products.coloplast.us/products/continence-care/#%26currentPage%3D1%26facetStrings%3D",
                "description": "Receive FREE samples of SpeediCath products for Men; sign up required; for additional information contact the program at 888-726-7872.",
                "brand": "SpeediCath Straight Tip for Men",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5a6"
            },
            {
                "_id": "6297bcace0e0db6c2572b5ab",
                "link": "https://www.spiriva.com/copd/savings-and-support/respipoints-rewards",
                "description": "Spiriva Respimat RespiPoints: Eligible patients may register with the program to earn points to redeem digital gift cards; for additional information contact the program at 203-957-3781.",
                "brand": "Spiriva Respimat",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5ab"
            },
            {
                "_id": "6297bcace0e0db6c2572b53a",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Select-OB+DHA Mail-In Rebate: If your pharmacy does not accept the Savings Card or you use a mail-order pharmacy you may submit your original receipt, a photocopy of your savings card (front and back) along with your date of birth to the program to receive your proper savings; for additional information contact the program directly at 855-881-3090.",
                "brand": "Select-OB DHA",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b53a"
            },
            {
                "_id": "6297bcace0e0db6c2572b5b0",
                "link": "https://www.prascosampling.com/aprecia/",
                "description": "Spritam Samples: Healthcare providers may request samples for their office by filling out the form online.",
                "brand": "Spritam",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5b0"
            },
            {
                "_id": "6297bcace0e0db6c2572b53f",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "SelRx Artesa Labs Advantage Program: Eligible commercially insured patients may pay as little as $0 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "SelRx Shampoo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b53f"
            },
            {
                "_id": "6297bcace0e0db6c2572b5b5",
                "link": "https://www.steglatro.com/savings-offers/",
                "description": "Steglatro Savings Coupon: Eligible commercially insured patients may pay as little as $0 on each of up to 12 prescriptions with savings of up to $583 per fill; offer can be redeemed every 21 days; for additional information contact the program at 877-264-2454.",
                "brand": "Steglatro",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "6996",
                "lifetime_uses": "12",
                "max_saving_transaction": 583,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5b5"
            },
            {
                "_id": "6297bcace0e0db6c2572b544",
                "link": "https://www.selsunblue.com/en-us/coupon/",
                "description": "Save $1 on any Selsun Blue product; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Selsun Blue Medicated Antidandruff Shampoo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b544"
            },
            {
                "_id": "6297bcace0e0db6c2572b5ba",
                "link": "https://stendra.com",
                "description": "Stendra Instant Savings Card: Eligible commercially insured patients may pay as little as $0 per prescription; for additional assistance contact the program at 844-274-2837.",
                "brand": "Stendra",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5ba"
            },
            {
                "_id": "6297bcace0e0db6c2572b549",
                "link": "https://www.activatethecard.com/viatrisadvocate/semglee/welcome.html",
                "description": "Semglee Savings Card: Eligible commercially insured patients may pay as little as $0 per 30-day prescription with a maximum savings of $75 per fill and $900 per calendar year; for more information contact the program at 800-657-7613.",
                "brand": "Semglee",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "900",
                "lifetime_uses": "12",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b549"
            },
            {
                "_id": "6297bcace0e0db6c2572b5bf",
                "link": "https://www.stiolto.com/savings-and-support/support-services",
                "description": "Stiolto Respimat Savings Card: Eligible commercially insured patients may pay a $0 copay on each of up to 12 prescriptions with savings of up to $250 per month; for additional information contact the program at 888-777-1919.",
                "brand": "Stiolto Respimat",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5bf"
            },
            {
                "_id": "6297bcace0e0db6c2572b54e",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/sensodyne/overview/",
                "description": "Sensodyne Samples: Dental professionals may request FREE samples of Sensodyne products for their practice.",
                "brand": "Sensodyne Essential Care Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b54e"
            },
            {
                "_id": "6297bcace0e0db6c2572b553",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/sensodyne/overview/",
                "description": "Sensodyne Samples: Dental professionals may request FREE samples of Sensodyne products for their practice.",
                "brand": "Sensodyne Rapid Relief Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b553"
            },
            {
                "_id": "6297bcace0e0db6c2572b5c4",
                "link": "https://www.stopain.com/where-to-buy/",
                "description": "Sign up to receive emails and updates from StopPain.",
                "brand": "Stopain Cold Pain Relieving Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5c4"
            },
            {
                "_id": "6297bcace0e0db6c2572b558",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/sensodyne/overview/",
                "description": "Sensodyne Samples: Dental professionals may request FREE samples of Sensodyne products for their practice.",
                "brand": "Sensodyne Sensitivity and Gum Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b558"
            },
            {
                "_id": "6297bcace0e0db6c2572b55d",
                "link": "https://products.coloplast.us/products/ostomy-care/#%26currentPage%3D1%26facetStrings%3D",
                "description": "Receive FREE samples of SenSura Urostomy products; sign up required; for additional information contact the program at 888-726-7872.",
                "brand": "SenSura-Urostomy Pouches",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b55d"
            },
            {
                "_id": "6297bcace0e0db6c2572b562",
                "link": "https://sevenfact.com/sevenfact-support/",
                "description": "Sevenfact Bridge Program: Eligible commercially insured patients experiencing a gap in coverage may be able to receive medication during this time under the program; for additional information contact the program at 855-718-4362.",
                "brand": "Sevenfact",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b562"
            },
            {
                "_id": "6297bcace0e0db6c2572b567",
                "link": "https://www.signiforlar.com/patient/cushings-disease/access-and-support/",
                "description": "Signifor LAR R.A.R.E. Patient Support Program: Copay assistance is available for those eligible to help to reduce out-of-pocket costs; for additional information contact the program at 888-855-7273.",
                "brand": "Signifor LAR",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b567"
            },
            {
                "_id": "6297bcace0e0db6c2572afba",
                "link": "https://www.fotivda.com/",
                "description": "Fotivda Bridge Program: Eligible commercially insured patients may receive medication if experiencing a lapse in insurance coverage; for more information contact the program at 833-368-4832.",
                "brand": "Fotivda",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afba"
            },
            {
                "_id": "6297bcace0e0db6c2572b56c",
                "link": "https://www.silenor.com/resources/#silenor-program",
                "description": "Silenor Prescription Savings Rebate: If the pharmacist is unable to apply the Savings Card when filling the prescription eligible patients may submit a mail-in rebate; for additional information contact the program at 800-793-2145.",
                "brand": "Silenor",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b56c"
            },
            {
                "_id": "6297bcace0e0db6c2572afbf",
                "link": "https://www.freestyle.abbott/us-en/myfreestyle.html",
                "description": "MyFreeStyle Program: Receive a FREE FreeStyle Libre Meter & sensor with sign-up; for additional information contact the program at 855-632-8658.",
                "brand": "FreeStyle Libre 2 System",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afbf"
            },
            {
                "_id": "6297bcace0e0db6c2572b571",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac Advance Infant Formula Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b571"
            },
            {
                "_id": "6297bcace0e0db6c2572b0c0",
                "link": "https://injectafercopay.com/",
                "description": "Injectafer Savings Program: Eligible commercially insured patients may pay no more than $50 per dose for two courses of treatment over a 12-month period; maximum savings limit of $500 per dose and a $1,000 program limit per course of treatment; offer is valid for 2 courses or 4 doses of the 750mg dose; for additional information contact the program at 866-437-4669.",
                "brand": "Injectafer",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1000",
                "lifetime_uses": "2",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0c0"
            },
            {
                "_id": "6297bcace0e0db6c2572b576",
                "link": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac Go and Grow Organic Toddler Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b576"
            },
            {
                "_id": "6297bcace0e0db6c2572afc4",
                "link": "https://www.alvaprofessional.com/skin-conditions",
                "description": "FungiCure Product Samples: Your healthcare provider may request samples by filling out a form online.",
                "brand": "FungiCURE Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afc4"
            },
            {
                "_id": "6297bcace0e0db6c2572b0c5",
                "link": "https://www.innopranxl.com/healthcare-providers/",
                "description": "InnoPran Samples: Healthcare providers may order samples for their office  by filling out an order form and faxing it to 844-896-5307.",
                "brand": "InnoPran XL",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0c5"
            },
            {
                "_id": "6297bcace0e0db6c2572b0ca",
                "link": "https://uspco.com/product_literature.aspx?pid=65&tab=Contact",
                "description": "Integra Samples: Healthcare providers may request samples for their practice by filling out a form online.",
                "brand": "Integra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0ca"
            },
            {
                "_id": "6297bcace0e0db6c2572b0cf",
                "link": "https://uspco.com/product_literature.aspx?pid=67&tab=Contact",
                "description": "Integra Plus Samples: Healthcare providers may request samples for their practice by filling out a form online.",
                "brand": "Integra Plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0cf"
            },
            {
                "_id": "6297bcace0e0db6c2572afc9",
                "link": "https://uspco.com/product_literature.aspx?pid=55&tab=Voucher",
                "description": "Fusion Plus Pharmacy Voucher: Eligible patients may save up to 80% at participating pharmacies; for additional information contact the program at  888-602-2978.",
                "brand": "Fusion Plus",
                "copay": "No",
                "bin": "600428",
                "rxpcn": "5100000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afc9"
            },
            {
                "_id": "6297bcace0e0db6c2572b57b",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac Pro-Advance Infant Formula Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b57b"
            },
            {
                "_id": "6297bcace0e0db6c2572b0d4",
                "link": "https://www.inpatientfreetrialprogram.com",
                "description": "Invega Sustenna Inpatient Hospital Free Trial Program: Designed for patients in hospital; your healthcare provider must register for program; offer good for up to 2 free trial units per calendar year per patient; contact the program for additional information at 855-463-1912.",
                "brand": "Invega Sustenna",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0d4"
            },
            {
                "_id": "6297bcace0e0db6c2572b0d9",
                "link": "https://www.janssencarepath.com/patient/invega-trinza/cost-support",
                "description": "Invega Trinza Janssen CarePath Savings Program Rebate: If the pharmacy or doctor cannot process the Savings Program Card commercially insured patients may still take advantage of the offer by submitting a rebate request; for additional information contact the program at 877-227-3728.",
                "brand": "Invega Trinza",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "8000",
                "lifetime_uses": "4",
                "max_saving_transaction": 2000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0d9"
            },
            {
                "_id": "6297bcace0e0db6c2572b0de",
                "link": "https://www.invokana.com/savings-and-cost-support",
                "description": "Invokana Janssen CarePath Savings Program: Most commercially insured patients may pay $0 copay per month with savings of up to $3000 per calendar year; for additional information contact the program at 877-468-6526.",
                "brand": "Invokana",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0de"
            },
            {
                "_id": "6297bcace0e0db6c2572b580",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac Soy Infant Formula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b580"
            },
            {
                "_id": "6297bcace0e0db6c2572afce",
                "link": "https://www.futuro-usa.com/3M/en_US/futuro-us/offers/coupons/?utm_medium=redirect&utm_source=vanity-url&utm_campaign=www.futuro-usa.com/3M/en_US/Futuro/Offers/Coupons/",
                "description": "Save $5 off any one Futuro Compression Legwear products; one coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Futuro Compression Socks or Hosiery Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afce"
            },
            {
                "_id": "6297bcace0e0db6c2572afd3",
                "link": "https://www.gammagard.com/primary-immunodeficiency/onepath",
                "description": "Gammagard OnePath Co-Pay Assistance Program: Eligible commercially insured patients may be able may save on certain out-of-pocket treatment costs; for additional information contact the program at 866-888-0660.",
                "brand": "Gammagard Liquid",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afd3"
            },
            {
                "_id": "6297bcace0e0db6c2572b585",
                "link": "https://epihealth.com/rebate/",
                "description": "Sitavig (50 mg) Patient Access Card: Eligible commercially insured patients may pay as little as $0 per fill; for additional information contact the program at 855-631-2485.",
                "brand": "Sitavig",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b585"
            },
            {
                "_id": "6297bcace0e0db6c2572afd8",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Ganirelix HEARTFELT Medication Replacement Program: Eligible cash-paying patients whose COS cycles were canceled due to COVID (between 3/1/20 - 12/31/21) or a natural disaster (2/1/21 - 12/31/21) may receive replacement medication; new cycles must be initiated between March 1 - December 31 2021; for additional information contact the program at 800-515-3784.",
                "brand": "Ganirelix Acetate",
                "copay": "",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afd8"
            },
            {
                "_id": "6297bcace0e0db6c2572b58a",
                "link": "https://www.skyrizi.com/skyrizi-complete/save-on-skyrizi-costs",
                "description": "Skyrizi Complete Savings Card Rebate: Commercially insured patients may submit a rebate request if unable to use the savings card at their pharmacy; for additional information contact the program at 866-759-7494.",
                "brand": "Skyrizi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6000",
                "lifetime_uses": "4",
                "max_saving_transaction": 1500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b58a"
            },
            {
                "_id": "6297bcace0e0db6c2572afdd",
                "link": "https://gattex.com/resources-and-support/",
                "description": "Gattex OnePath Co-Pay Assistance Program: Eligible commercially insured patients may be able may save on certain out-of-pocket treatment costs; for additional information contact the program at 866-888-0660.",
                "brand": "Gattex",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afdd"
            },
            {
                "_id": "6297bcace0e0db6c2572b58f",
                "link": "https://www.slowmag.com/healthcare-professionals/",
                "description": "SlowMag MG Samples: Healthcare professionals may receive free samples & coupons for their practice by logging onto the link.",
                "brand": "SlowMag MG Muscle plus Heart",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b58f"
            },
            {
                "_id": "6297bcace0e0db6c2572b594",
                "link": "https://slynd.com/healthcare-professionals/",
                "description": "Slynd Direct to Patient Sample: Your healthcare provider may order you a FREE 28-day supply; offer avaialbe to NEW patients; sample also comes with a Savings Card; for additional information contact the program at 888-795-5826.",
                "brand": "Slynd",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b594"
            },
            {
                "_id": "6297bcace0e0db6c2572afe2",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Gazyva Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Gazyva",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afe2"
            },
            {
                "_id": "6297bcace0e0db6c2572b599",
                "link": "https://www.solosec.com/bacterial-vaginosis/savings-card",
                "description": "Solosec Savings Card: Eligible cash-paying/Rx not covered patients may pay as little as $75 per prescription fill; for additional information contact the program at 833-500-6732.",
                "brand": "Solosec",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b599"
            },
            {
                "_id": "6297bcace0e0db6c2572b59e",
                "link": "https://www.ipsencares.com/somatuline-depot-patient-support/",
                "description": "Somatuline Depot Ipsen Cares Copay Assistance Program: Eligible cash-pay patients may save $1666.66 per injection with a maximum savings of $20,000 per calendar year; program resets every January 1st; patient must re-enroll each year; for additional information contact the program at 866-435-5677.",
                "brand": "Somatuline Depot",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "13",
                "max_saving_transaction": 1666.66,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b59e"
            },
            {
                "_id": "6297bcace0e0db6c2572b5a3",
                "link": "https://sotylize.com/patient-home.php",
                "description": "Sotylize Instant Savings Program: Eligible patients may pay as little as $25 per prescription; for additional information contact the program at 844-415-0675.",
                "brand": "Sotylize",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5a3"
            },
            {
                "_id": "6297bcace0e0db6c2572afe7",
                "link": "https://gemtesa.com/savings-program",
                "description": "Gemtesa Simple Savings Card: Eligible commercially insured patients may pay as little as $10 for a 30-day supply or $0 per 90-day supply between October 1, 2021 and March 31, 2022; for additional information contact the program at 833-876-8268.",
                "brand": "Gemtesa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afe7"
            },
            {
                "_id": "6297bcace0e0db6c2572b5a8",
                "link": "https://www.spiriva.com/copd/savings-and-support/sign-up-for-savings",
                "description": "Spiriva HandiHaler Savings Card: Eligible commercially insured patients may pay $0 per month with a maximum savings of up to $100 per 30-day fill; card valid for 12 uses per year; for additional information contact the program at 855-772-7223.",
                "brand": "Spiriva HandiHaler",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5a8"
            },
            {
                "_id": "6297bcace0e0db6c2572afec",
                "link": "https://www.geodon.com/savings",
                "description": "Geodon Savings Card: Eligible patients may pay as little as $4 per prescription with savings of up to $3000 per year; for additional information contact the program at 888-779-2134.",
                "brand": "Geodon",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afec"
            },
            {
                "_id": "6297bcace0e0db6c2572b5ad",
                "link": "https://www.janssencarepath.com/patient/spravato/cost-support",
                "description": "Spravato Janssen CarePath Savings Program Rebate: Eligible commercially insured patients may submit a rebate request if the pharmacy does not accept the Savings Card; for additional assistance contact the program at 877-227-3728.",
                "brand": "Spravato",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "7150",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5ad"
            },
            {
                "_id": "6297bcace0e0db6c2572aff1",
                "link": "https://gilenya.com/ms-pill/co-pay",
                "description": "Gilenya Prescription Co-Pay Support Program: Most eligible commercially insured patients may pay $0 per prescription with savings of up to $18000 per year; for additional information contact the program at 800-445-3692.",
                "brand": "Gilenya",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "18000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572aff1"
            },
            {
                "_id": "6297bcace0e0db6c2572b5b2",
                "link": "https://www.bmsaccesssupport.bmscustomerconnect.com/patient/financial-support",
                "description": "Sprycel One Card Free Trial: New eligible commercially insured patients may receive a 1-month FREE trial supply; for additional information contact the program at 800-861-0048.",
                "brand": "Sprycel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5b2"
            },
            {
                "_id": "6297bcace0e0db6c2572aff6",
                "link": "https://www.givlaari.com/getting-started#alnylam-assist",
                "description": "Givlaari Copay Program: Eligible commercially insured patients may receive assistance with drug-related out-of-pocket expenses; for additional information contact the program at 833-256-2748.",
                "brand": "Givlaari",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572aff6"
            },
            {
                "_id": "6297bcace0e0db6c2572b5b7",
                "link": "https://www.steglujan.com/savings-offers/",
                "description": "Steglujan Savings Coupon: Eligible privately patients may pay as little as $0 on each of up to 12 prescriptions with savings of up to $583 per fill; offer can be redeemed every 21 days; for additional information contact the program at 877-264-2454.",
                "brand": "Steglujan",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "6996",
                "lifetime_uses": "12",
                "max_saving_transaction": 583,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5b7"
            },
            {
                "_id": "6297bcace0e0db6c2572affb",
                "link": "https://glucerna.com/signup",
                "description": "Sign up with Glucerna Everyday Progress and receive savings of up to $24 on Glucerna products and much more.",
                "brand": "Glucerna 30g Protein Shake",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572affb"
            },
            {
                "_id": "6297bcace0e0db6c2572b000",
                "link": "https://glucerna.com/signup",
                "description": "Sign up with Glucerna Everyday Progress and receive savings of up to $24 on Glucerna products and much more.",
                "brand": "Glucerna Snack Bars",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b000"
            },
            {
                "_id": "6297bcace0e0db6c2572b5bc",
                "link": "https://sting-kill.com",
                "description": "Join the mailing list for Sting-Kill products by scrolling to the bottom of the homepage and typing your email address into the box; 1 coupon per purchase.",
                "brand": "Sting-Kill Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5bc"
            },
            {
                "_id": "6297bcace0e0db6c2572b005",
                "link": "https://www.glyxambi.com/support-and-savings",
                "description": "Glyxambi Savings Card: Eligible commercially insured patients may pay as little as $10 per 30-day supply with maximum savings of up to $175 per fill; for additional information contact the program at 866-279-8990.",
                "brand": "Glyxambi",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "2100",
                "lifetime_uses": "12",
                "max_saving_transaction": 175,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b005"
            },
            {
                "_id": "6297bcace0e0db6c2572b5c1",
                "link": "https://www.stopain.com/where-to-buy/",
                "description": "Sign up to receive emails and updates from StopPain.",
                "brand": "Stopain Cold Extra Strength Roll-On",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5c1"
            },
            {
                "_id": "6297bcace0e0db6c2572b00a",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Clear Body Powder Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b00a"
            },
            {
                "_id": "6297bcace0e0db6c2572b5c6",
                "link": "https://www.gileadadvancingaccess.com/financial-support/gilead-copay-card",
                "description": "Stribild Advancing Access Co-pay Program: Eligible commercially insured patients may save up to a maximum of $6000 per year with no monthly limits; for additional information contact the program at  800-226-2056.",
                "brand": "Stribild",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "ACCESS",
                "copay_max": "6000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5c6"
            },
            {
                "_id": "6297bcace0e0db6c2572b5cb",
                "link": "https://subvenite.com/savings-program/",
                "description": "Subvenite Savings Program: Eligible cash-paying patients may pay $50 per Subvenite Starter Kit; for more information contact the program at 800-273-6729.",
                "brand": "Subvenite",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5cb"
            },
            {
                "_id": "6297bcace0e0db6c2572b5d0",
                "link": "https://mycareclubrewards.com/Sudafed/?utm_medium=partner&utm_source=sudafed.com&utm_content=header&utm_campaign=cc_main&_gl=1*6y433b*_ga*NTAzNzk2MTQ2LjE2MzUxNzUyNDg.*_ga_13VEM6N66E*MTYzNTg3MjkzNi4zLjEuMTYzNTg3Mjk0OS40Nw..*_fplc*NTNqOUVYeU9heHpBaUFHZWRYaFQ4MHJlZDNaa0pIbHlHUWpaUmRtUHhlc0FEWkk0WWklMkJQV0laRU1wUGhMN2clMkZRQUlDbjRRY0tBaTVaYnVHcjN2VUNoSnc0elpLc2NSNEFDcktjNFRmUiUyRkp0QlE5cnlNWjE2NkpCQWUzWmJRJTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Sudafed products with registration.",
                "brand": "Sudafed PE Head Congestion and Pain Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5d0"
            },
            {
                "_id": "6297bcace0e0db6c2572b00f",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Medicated Eczema Relief Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b00f"
            },
            {
                "_id": "6297bcace0e0db6c2572b5d5",
                "link": "https://mycareclubrewards.com/Sudafed/?utm_medium=partner&utm_source=sudafed.com&utm_content=header&utm_campaign=cc_main&_gl=1*6y433b*_ga*NTAzNzk2MTQ2LjE2MzUxNzUyNDg.*_ga_13VEM6N66E*MTYzNTg3MjkzNi4zLjEuMTYzNTg3Mjk0OS40Nw..*_fplc*NTNqOUVYeU9heHpBaUFHZWRYaFQ4MHJlZDNaa0pIbHlHUWpaUmRtUHhlc0FEWkk0WWklMkJQV0laRU1wUGhMN2clMkZRQUlDbjRRY0tBaTVaYnVHcjN2VUNoSnc0elpLc2NSNEFDcktjNFRmUiUyRkp0QlE5cnlNWjE2NkpCQWUzWmJRJTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Sudafed products with registration.",
                "brand": "Sudafed PE Sinus Pressure Pain",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5d5"
            },
            {
                "_id": "6297bcace0e0db6c2572b5da",
                "link": "https://www.summerseve.com/healthcare-professionals",
                "description": "Summer's Eve Product samples: Healthcare providers may order sample kits for their practice.",
                "brand": "Summers Eve Cleansing Cloths",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5da"
            },
            {
                "_id": "6297bcace0e0db6c2572b014",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product; coupon expires 1 month after printing.",
                "brand": "Gold Bond Multi-Symptom Psoriasis Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b014"
            },
            {
                "_id": "6297bcace0e0db6c2572b019",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Rapid Relief Anti-Itch Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b019"
            },
            {
                "_id": "6297bcace0e0db6c2572b5df",
                "link": "https://www.sundownnutrition.com/coupons/",
                "description": "Register your email address to receive special offers on Sundown Vitamin & Supplement products.",
                "brand": "Sundown Vitamin and Supplement Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5df"
            },
            {
                "_id": "6297bcace0e0db6c2572b01e",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Ultimate Cracked Skin Relief Fill and Protect",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b01e"
            },
            {
                "_id": "6297bcace0e0db6c2572b5e4",
                "link": "https://www.sunosi.com/savings",
                "description": "Sunosi Savings Card: Eligible patients may pay as little as $9 on monthly prescriptions; limited to 15 uses per year; for additional information contact the program at 833-533-5299.",
                "brand": "Sunosi",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "15",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5e4"
            },
            {
                "_id": "6297bcace0e0db6c2572b023",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Ultimate Eczema Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b023"
            },
            {
                "_id": "6297bcace0e0db6c2572b5e9",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/overview/?utm_source=google&utm_medium=cpc&utm_term=super%20poligrip&utm_campaign=GS%20-%20Branded_Poligrip%20EX_DP&gclid=Cj0KCQiA962BBhCzARIsAIpWEL0O5zEg5LMkWP-xbEDatBaEd896PMS8BoY0ynLITYi-fwgKKWPSrcUaAiBqEALw_wcB&gclsrc=aw.ds",
                "description": "Super Poligrip Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Super Poligrip Cushion and Comfort",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5e9"
            },
            {
                "_id": "6297bcace0e0db6c2572b5ee",
                "link": "https://www.polident.com/en-us/save-now/poligrip-coupon/",
                "description": "Save $1 on any one Super Poligrip product with registration; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Super Poligrip Free",
                "copay": "N",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5ee"
            },
            {
                "_id": "6297bcace0e0db6c2572b028",
                "link": "https://www.emdseronofertility.com/en/patient/affording-treatment/medication-savings.html",
                "description": "Gonal-F GO Direct Rebate Program: Eligible self-pay patients may be able to receive a 10% discount via rebate off your out-of-pocket costs; for additional information contact the program at 866-441-9463.",
                "brand": "Gonal-F",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b028"
            },
            {
                "_id": "6297bcace0e0db6c2572b5f3",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/overview/?utm_source=google&utm_medium=cpc&utm_term=super%20poligrip&utm_campaign=GS%20-%20Branded_Poligrip%20EX_DP&gclid=Cj0KCQiA962BBhCzARIsAIpWEL0O5zEg5LMkWP-xbEDatBaEd896PMS8BoY0ynLITYi-fwgKKWPSrcUaAiBqEALw_wcB&gclsrc=aw.ds",
                "description": "Super Poligrip Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Super Poligrip Ultra Fresh",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5f3"
            },
            {
                "_id": "6297bcace0e0db6c2572b02d",
                "link": "https://www.alksavings.com/cb/access/crp/aLkYd2j78Vbb/fid.jsp?q=51G5OI5442CUDK4PLWTLX5&f=IMPX6IG-274EMWRGK6802#/app/layout/home",
                "description": "Grastek ALK Savings Card Program: Eligible commercially insured patients may pay as little as $50 per 30-day prescription if using a retail pharmacy; valid for 12 uses or $3,200 per year (whichever comes first); for additional information contact the program at 800-325-7354.",
                "brand": "Grastek",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3200",
                "lifetime_uses": "12",
                "max_saving_transaction": 265,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b02d"
            },
            {
                "_id": "6297bcace0e0db6c2572b5f8",
                "link": "https://www.eyeoncopay.com/",
                "description": "Susvimo Genentech Ophthalmology Co-pay Program: Eligible commercially insured patients may pay $5 on out-of-pocket costs per treatment with savings of up to $15,000 per year for drug costs and a savings of up to $1,000 for surgical procedure and refill co-pay; for additional information contact the program at 855-218-5307.",
                "brand": "Susvimo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "16000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5f8"
            },
            {
                "_id": "6297bcace0e0db6c2572b032",
                "link": "https://gynazole.com/samples.html/",
                "description": "Gynazole 1 Free Sample Request: Healthcare providers may order samples by filling out a form and faxing it to 888-320-0688 or emailing the form to samples@foundcare.com; for additional information contact Customer Service at 877-291-1122.",
                "brand": "Gynazole 1",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b032"
            },
            {
                "_id": "6297bcace0e0db6c2572b5fd",
                "link": "https://www.pfizeroncologytogether.com/patient/signup",
                "description": "Sutent Pfizer Oncology Together Co-Pay Savings Program (oral products): Eligible commercially insured patients may pay as little as $0 per prescription with savings of up to $25,000 per calendar year; for additional information contact the program at 877-744-5675.",
                "brand": "Sutent",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5fd"
            },
            {
                "_id": "6297bcace0e0db6c2572b037",
                "link": "https://halogrx.com/savings/",
                "description": "Halog Co-Pay Card: Eligible commercially insured patients may pay as little as $0 per prescription; for additional information contact the program at 855-820-9077.",
                "brand": "Halog Products",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b037"
            },
            {
                "_id": "6297bcace0e0db6c2572b602",
                "link": "https://www.symjepi.com/symjepi_savings_program",
                "description": "Sympeji Savings Program: Eligible commercially insured patients may pay as little as $0 per prescription fill; maximum benefit of $1000 per year; for additional information contact the program at 877-264-2440.",
                "brand": "Symjepi",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1000",
                "lifetime_uses": "0",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b602"
            },
            {
                "_id": "6297bcace0e0db6c2572b03c",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Classic Clean Anti-Dandruff Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b03c"
            },
            {
                "_id": "6297bcace0e0db6c2572b041",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Coconut Anti-Dandruff Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b041"
            },
            {
                "_id": "6297bcace0e0db6c2572b607",
                "link": "https://www.sympazan.com/register.html",
                "description": "",
                "brand": "Sympazan",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b607"
            },
            {
                "_id": "6297bcace0e0db6c2572b5c9",
                "link": "https://www.insupport.com/suboxone/savings",
                "description": "Suboxone Copay Card: Eligible commercially insured patients may pay no more than $5 per month with savings of up to $75 per month; new patients may qualify for 2 refills 1st month; for additional information contact the program at 877-678-7493.",
                "brand": "Suboxone",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "900",
                "lifetime_uses": "12",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5c9"
            },
            {
                "_id": "6297bcace0e0db6c2572b60c",
                "link": "https://synagis.com/patient-support.html",
                "description": "Synagis Copay Program: Eligible commercially insured patients may pay as little as $0 per dose with a maximum savings of $6,000 per season (July 1st to June 30th); for additional information contact the program at 833-796-2447.",
                "brand": "Synagis",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "6000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b60c"
            },
            {
                "_id": "6297bcace0e0db6c2572b046",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Mens Advanced Series Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b046"
            },
            {
                "_id": "6297bcace0e0db6c2572b5ce",
                "link": "https://mycareclubrewards.com/Sudafed/?utm_medium=partner&utm_source=sudafed.com&utm_content=header&utm_campaign=cc_main&_gl=1*6y433b*_ga*NTAzNzk2MTQ2LjE2MzUxNzUyNDg.*_ga_13VEM6N66E*MTYzNTg3MjkzNi4zLjEuMTYzNTg3Mjk0OS40Nw..*_fplc*NTNqOUVYeU9heHpBaUFHZWRYaFQ4MHJlZDNaa0pIbHlHUWpaUmRtUHhlc0FEWkk0WWklMkJQV0laRU1wUGhMN2clMkZRQUlDbjRRY0tBaTVaYnVHcjN2VUNoSnc0elpLc2NSNEFDcktjNFRmUiUyRkp0QlE5cnlNWjE2NkpCQWUzWmJRJTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Sudafed products with registration.",
                "brand": "Sudafed Childrens PE Nasal Decongestant",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5ce"
            },
            {
                "_id": "6297bcace0e0db6c2572b04b",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders White Charcoal Anti-Dandruff Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b04b"
            },
            {
                "_id": "6297bcace0e0db6c2572b611",
                "link": "https://syndros.com/co-pay-program/",
                "description": "Syndros Cares Discount Card: Eligible cash-paying patients may save up to $400 off each prescription; for additional assistance contact the program at 877-369-5158.",
                "brand": "Syndros",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 400,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b611"
            },
            {
                "_id": "6297bcace0e0db6c2572b5d3",
                "link": "https://mycareclubrewards.com/Sudafed/?utm_medium=partner&utm_source=sudafed.com&utm_content=header&utm_campaign=cc_main&_gl=1*6y433b*_ga*NTAzNzk2MTQ2LjE2MzUxNzUyNDg.*_ga_13VEM6N66E*MTYzNTg3MjkzNi4zLjEuMTYzNTg3Mjk0OS40Nw..*_fplc*NTNqOUVYeU9heHpBaUFHZWRYaFQ4MHJlZDNaa0pIbHlHUWpaUmRtUHhlc0FEWkk0WWklMkJQV0laRU1wUGhMN2clMkZRQUlDbjRRY0tBaTVaYnVHcjN2VUNoSnc0elpLc2NSNEFDcktjNFRmUiUyRkp0QlE5cnlNWjE2NkpCQWUzWmJRJTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Sudafed products with registration.",
                "brand": "Sudafed PE Sinus Congestion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5d3"
            },
            {
                "_id": "6297bcace0e0db6c2572b050",
                "link": "https://www.boironusa.com/hemcalm-coupon/",
                "description": "$2 coupon for any HemCalm product; 1 coupon per purchase.",
                "brand": "HemCalm Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b050"
            },
            {
                "_id": "6297bcace0e0db6c2572b616",
                "link": "https://www.synthroid.com/support/before-breakfast-club",
                "description": "Synthroid Before Breakfast Club Co-Pay Card: Eligible commercially insured patients pay no more than $25 per 30-day prescription or $75 per 90-day prescription; for additional information contact the program at 866-627-4980.",
                "brand": "Synthroid",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b616"
            },
            {
                "_id": "6297bcace0e0db6c2572b5d8",
                "link": "https://www.medimetriks.com/prescription-brands/sumadan-xlt",
                "description": "Sumadan XLT Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Sumadan XLT",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5d8"
            },
            {
                "_id": "6297bcace0e0db6c2572b055",
                "link": "https://uspco.com/product_literature.aspx?pid=220&tab=Contact",
                "description": "Hemocyte F Samples: Healthcare providers may request samples for their practice by filling out information online.",
                "brand": "Hemocyte F",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b055"
            },
            {
                "_id": "6297bcace0e0db6c2572b61b",
                "link": "https://www.eyefile.com/theeyesolution/Register",
                "description": "Save $3 on your next purchase of any one Systane Eye Drops Product (10 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Systane Contacts Lubricant Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b61b"
            },
            {
                "_id": "6297bcace0e0db6c2572b5dd",
                "link": "https://www.sundownnutrition.com/coupons/",
                "description": "Register your email address to receive special offers on Sundown Vitamin & Supplement products.",
                "brand": "Sundown Vitamin and Supplement Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5dd"
            },
            {
                "_id": "6297bcace0e0db6c2572b5e2",
                "link": "https://www.sundownnutrition.com/coupons/",
                "description": "Register your email address to receive special offers on Sundown Vitamin & Supplement products.",
                "brand": "Sundown Vitamin and Supplement Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5e2"
            },
            {
                "_id": "6297bcace0e0db6c2572b5e7",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/overview/?utm_source=google&utm_medium=cpc&utm_term=super%20poligrip&utm_campaign=GS%20-%20Branded_Poligrip%20EX_DP&gclid=Cj0KCQiA962BBhCzARIsAIpWEL0O5zEg5LMkWP-xbEDatBaEd896PMS8BoY0ynLITYi-fwgKKWPSrcUaAiBqEALw_wcB&gclsrc=aw.ds",
                "description": "Super Poligrip Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Super Poligrip Comfort Seal Strip",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5e7"
            },
            {
                "_id": "6297bcace0e0db6c2572b05a",
                "link": "https://www.copayassistancenow.com/eligibility",
                "description": "Herceptin Hylecta Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Herceptin Hylecta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b05a"
            },
            {
                "_id": "6297bcace0e0db6c2572b620",
                "link": "https://www.eyefile.com/theeyesolution/Register",
                "description": "Save $3 on your next purchase of any one Systane Eye Drops Product (10 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Systane Preservative-Free Formula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b620"
            },
            {
                "_id": "6297bcace0e0db6c2572b5ec",
                "link": "https://www.polident.com/en-us/save-now/poligrip-coupon/",
                "description": "Save $1 on any one Super Poligrip product with registration; 1 coupon per purchase.",
                "brand": "Super Poligrip Extra Strength Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5ec"
            },
            {
                "_id": "6297bcace0e0db6c2572b5f1",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/overview/?utm_source=google&utm_medium=cpc&utm_term=super%20poligrip&utm_campaign=GS%20-%20Branded_Poligrip%20EX_DP&gclid=Cj0KCQiA962BBhCzARIsAIpWEL0O5zEg5LMkWP-xbEDatBaEd896PMS8BoY0ynLITYi-fwgKKWPSrcUaAiBqEALw_wcB&gclsrc=aw.ds",
                "description": "Super Poligrip Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Super Poligrip Original",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5f1"
            },
            {
                "_id": "6297bcace0e0db6c2572b625",
                "link": "https://www.myaccess360.com/patient/tagrisso-osimertinib/patient-affordability.html",
                "description": "Tagrisso Access 360 Co-pay Savings Program: Eligible commercially insured patients may pay $0 per 28-day supply with savings of up to $26,000 per year; for additional information contact the program at 844-275-2360.",
                "brand": "Tagrisso",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "26000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2166.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b625"
            },
            {
                "_id": "6297bcace0e0db6c2572b05f",
                "link": "https://www.hizentra.com/insurance-loss-protection",
                "description": "Hizentra Assurance Program: Eligible commercially insured patients may earn points for each consecutive monthly prescription; points may be used in the event of a lapse in coverage; for additional information contact the program at 877-355-4447.",
                "brand": "Hizentra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b05f"
            },
            {
                "_id": "6297bcace0e0db6c2572b5f6",
                "link": "https://www.heronconnect.com/SUSTOL/Overview",
                "description": "Sustol Heron Connect Copay Assistance Program: Eligible cash-paying  patients will save $150 per treatment; maximum patient savings per calendar year is $1800; your healthcare provider must complete enrollment form; for additional information contact the program at 844-437-6611.",
                "brand": "Sustol",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5f6"
            },
            {
                "_id": "6297bcace0e0db6c2572b064",
                "link": "https://www.horizant.com/savings",
                "description": "Horizant Copay Savings Card: Eligible commercially insured patients may pay $0 for their 1st prescription and then pay no more than $25 per fill for (300/600 mg) their remaining prescriptions; for additional information contact the program at 855-700-2990.",
                "brand": "Horizant",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b064"
            },
            {
                "_id": "6297bcace0e0db6c2572b62a",
                "link": "https://www.pfizeroncologytogether.com/patient/signup",
                "description": "Talzenna Pfizer Oncology Together Co-Pay Savings Program (oral products): Eligible commercially insured patients may pay as little as $0 per prescription with savings of up to $25,000 per calendar year; for additional information contact the program at 877-744-5675.",
                "brand": "Talzenna",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b62a"
            },
            {
                "_id": "6297bcace0e0db6c2572b5fb",
                "link": "https://sutab.com/Savings",
                "description": "Sutab Savings Copay Card: Eligible commercially insured patients may pay as little as $40 per prescription; for additional information contact the program at 844-926-4140.",
                "brand": "Sutab",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5fb"
            },
            {
                "_id": "6297bcace0e0db6c2572b600",
                "link": "https://www.eusapatientconnect.com/patient",
                "description": "Sylvant Patient Connect Co-Pay Program: Eligible commercially insured patients may pay $5 copay per infusion with savings of up to $26,000 per calendar year; for additional information contact the program at 855-299-8844.",
                "brand": "Sylvant",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "26000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b600"
            },
            {
                "_id": "6297bcace0e0db6c2572b069",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog Jr KwikPen: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog Junior KwikPen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b069"
            },
            {
                "_id": "6297bcace0e0db6c2572b62f",
                "link": "https://taperdex.com/",
                "description": "TaperDex Co-pay Savings Voucher: Eligible commercially insured patients/Rx not covered may pay no more than $10 per prescription; for additional information contact the program at 601-990-9497.",
                "brand": "TaperDex",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b62f"
            },
            {
                "_id": "6297bcace0e0db6c2572b605",
                "link": "https://www.symlinhcp.com/access-and-savings-card.html#patient-savings",
                "description": "SymlinPen MySavingsRx Card: Eligible commercially insured patients/RX not covered may pay as low as $25 on each 30-day supply prescription; for additional information contact the program at 855-292-5968.",
                "brand": "Symlin",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b605"
            },
            {
                "_id": "6297bcace0e0db6c2572b60a",
                "link": "https://www.janssencarepath.com/patient/symtuza/cost-support",
                "description": "Symtuza Janssen CarePath Savings Program Rebate: Eligible commercially insured patients may submit a rebate request if the pharmacy does not accept the Savings Card; for additional assistance contact the program at 866-836-0114.",
                "brand": "Symtuza",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12500",
                "lifetime_uses": "12",
                "max_saving_transaction": 1041.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b60a"
            },
            {
                "_id": "6297bcace0e0db6c2572b60f",
                "link": "https://www.medimetriks.com/prescription-brands/synalar-ts-kit-synalar-topical-solution",
                "description": "Synalar (topical solution) Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Synalar",
                "copay": "No",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b60f"
            },
            {
                "_id": "6297bcace0e0db6c2572b634",
                "link": "https://tavalissecopay.com/enrollment/rigeltavalisse/home.page",
                "description": "Tavalisse Copay Assistance Program: Eligible commercially insured patients may pay as little as $15 per prescription with savings of up to $25,000 per year; for additional information contact the program at 833-744-3562.",
                "brand": "Tavalisse",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b634"
            },
            {
                "_id": "6297bcace0e0db6c2572b614",
                "link": "https://www.synjardy.com/support-and-savings",
                "description": "Synjardy Simple Savings Card: Eligible commercially insured patients may pay as little as $10 per prescription with savings of up to $175 per 30-day supply; for additional information contact the program at 866-279-8990.",
                "brand": "Synjardy",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "2100",
                "lifetime_uses": "12",
                "max_saving_transaction": 175,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b614"
            },
            {
                "_id": "6297bcace0e0db6c2572b06e",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog Mix 50-50 KwikPen: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog Mix 50-50 KwikPen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b06e"
            },
            {
                "_id": "6297bcace0e0db6c2572b619",
                "link": "https://www.eyefile.com/TheEyeSolution/Register/0",
                "description": "Save $3 on your next purchase of any one Systane Eye Drops Product (10 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Systane Balance",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b619"
            },
            {
                "_id": "6297bcace0e0db6c2572b61e",
                "link": "https://systane.myalcon.com/eye-care/systane/coupon/",
                "description": "Save $3 on your next purchase of any one Systane Eye Drops Product (10 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Systane Hydration Preservative-Free",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b61e"
            },
            {
                "_id": "6297bcace0e0db6c2572b623",
                "link": "https://copay.novartisoncology.com/?name=tabrecta",
                "description": "Tabrecta Novartis Universal Co-Pay Card:Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Tabrecta",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b623"
            },
            {
                "_id": "6297bcace0e0db6c2572b628",
                "link": "https://www.taltz.com/patient-support",
                "description": "Taltz Savings Card: Eligible commercially insured patients/Rx not covered may pay as little as $5 or up to $25 per monthly fill for up to 36 months; for additional information contact the program at 844-825-8966.",
                "brand": "Taltz",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b628"
            },
            {
                "_id": "6297bcace0e0db6c2572b62d",
                "link": "https://uspco.com/product_literature.aspx?pid=110&tab=Contact",
                "description": "Tandem pH Samples: Healthcare providers may request samples for their practice by going online.",
                "brand": "Tandem",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b62d"
            },
            {
                "_id": "6297bcace0e0db6c2572b639",
                "link": "https://www.copayassistancenow.com/eligibility",
                "description": "Tecentriq Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Tecentriq",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b639"
            },
            {
                "_id": "6297bcace0e0db6c2572b632",
                "link": "https://www.targretin.com/coupon-eligibility",
                "description": "Targretin Savings Card: Eligible commercially insured patients may pay a $0 co-pay per prescription; patient must present coupon along with prescription; for additional information and to activate coupon offer contact the program at 888-201-1385.",
                "brand": "Targretin",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b632"
            },
            {
                "_id": "6297bcace0e0db6c2572b073",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog Mix 75-25 KwikPen: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog Mix 75-25 Pen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b073"
            },
            {
                "_id": "6297bcace0e0db6c2572b637",
                "link": "https://almiralladvantage.com/",
                "description": "Tazorac Almirall Advantage Savings Card: Eligible commercially insured/Rx not covered patients may pay no more than $125 per prescription; for additional information contact the program at 888-591-9860.",
                "brand": "Tazorac",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b637"
            },
            {
                "_id": "6297bcace0e0db6c2572b63e",
                "link": "https://www.tena.us/special-offers/special-offers,en_US,pg.html",
                "description": "Receive special offers for Tena products with registration; 1 coupon per purchase.",
                "brand": "Tena Personal Care Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b63e"
            },
            {
                "_id": "6297bcace0e0db6c2572b63c",
                "link": "http://temixys.com/GetSupport/SupportCopayPrint",
                "description": "Temixys Savings Program: Eligible commercially insured patients may pay as little as $5 per prescription; offer is good for 12 uses or until the program expires whichever comes first; for additional information contact the program at 800-388-2316.",
                "brand": "Temixys",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b63c"
            },
            {
                "_id": "6297bcace0e0db6c2572b078",
                "link": "https://www.humate-p.com/support-and-resources",
                "description": "Humate-P CSL Behring Assurance: Eligible commercially insured patients may earn points for each monthly prescription; points may be used in the event of a lapse in coverage; for additional information contact the program at 800-676-4266.",
                "brand": "Humate-P",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b078"
            },
            {
                "_id": "6297bcace0e0db6c2572b643",
                "link": "https://www.pfizermedicalinformation.com/en-us/tessalon",
                "description": "Tessalon Samples: Your healthcare provider may request samples by contacting PfizerPro online or calling 800-505-4426.",
                "brand": "Tessalon",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b643"
            },
            {
                "_id": "6297bcace0e0db6c2572b641",
                "link": "https://www.oncnavigationcenter.com/en/Bridge_Program.html",
                "description": "Tepmetko Bridge Program: Patients who are experiencing an insurance delay of at least 5 business days may receive their initial prescription for FREE; for additional information contact the program at 844-662-3631.",
                "brand": "Tepmetko",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b641"
            },
            {
                "_id": "6297bcace0e0db6c2572b07d",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humulin 70-30: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humulin 70-30",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b07d"
            },
            {
                "_id": "6297bcace0e0db6c2572b648",
                "link": "https://www.theraflu.com/coupon/",
                "description": "Register your email address and receive a $1.00 coupon off of any Theraflu product; limit 1 coupon per purchase; coupon expires 1�month after printing.",
                "brand": "Theraflu ExpressMax Day/Night Value Pack",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b648"
            },
            {
                "_id": "6297bcace0e0db6c2572b646",
                "link": "https://www.theraflu.com/coupon/",
                "description": "Register your email address and receive a $1.00 coupon off of any Theraflu product; limit 1 coupon per purchase; coupon expires 1�month after printing.",
                "brand": "Theraflu Day/Night Hot Liquid Powder Value Pack",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b646"
            },
            {
                "_id": "6297bcace0e0db6c2572b082",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humulin N KwikPen: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humulin N",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b082"
            },
            {
                "_id": "6297bcace0e0db6c2572b64d",
                "link": "https://www.theraflu.com/coupon/",
                "description": "Register your email address and receive a $1.00 coupon off of any Theraflu product; limit 1 coupon per purchase; coupon expires 1�month after printing.",
                "brand": "Theraflu Flu/Sore Throat",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b64d"
            },
            {
                "_id": "6297bcace0e0db6c2572b64b",
                "link": "https://www.theraflu.com/coupon/",
                "description": "Register your email address and receive a $1.00 coupon off of any Theraflu product; limit 1 coupon per purchase; coupon expires 1�month after printing.",
                "brand": "Theraflu ExpressMax Severe Cold / Cough Value Pack",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b64b"
            },
            {
                "_id": "6297bcace0e0db6c2572b650",
                "link": "https://www.theraflu.com/coupon/",
                "description": "Register your email address and receive a $1.00 coupon off of any Theraflu product; limit 1 coupon per purchase; coupon expires 1�month after printing.",
                "brand": "Theraflu Nighttime Severe Cold / Cough",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b650"
            },
            {
                "_id": "6297bcace0e0db6c2572b652",
                "link": "https://www.theratears.com/products/",
                "description": "Save $1 on TheraTears products; trial sizes not included in offer; one coupon per purchase.",
                "brand": "TheraTears Dry Eye Therapy Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b652"
            },
            {
                "_id": "6297bcace0e0db6c2572b087",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humulin R U-500: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humulin R U-500",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b087"
            },
            {
                "_id": "6297bcace0e0db6c2572b08c",
                "link": "https://hysinglaer.com/patient/patient-savings-program.html",
                "description": "Hysingla ER Savings Program: Eligible commercially insured patients may receive help to reduce out-of-pocket prescription costs; for additional assistance contact the program at 855-396-2622.",
                "brand": "Hysingla ER",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b08c"
            },
            {
                "_id": "6297bcace0e0db6c2572b657",
                "link": "https://www.theratears.com/products/",
                "description": "Save $1 on TheraTears products; trial sizes not included in offer; one coupon per purchase.",
                "brand": "TheraTears Liquid Gel Nighttime Dry Eye Therapy",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b657"
            },
            {
                "_id": "6297bcace0e0db6c2572b65c",
                "link": "https://thyquiditycoupon.com/card/terms",
                "description": "Thyquidity Savings card: Eligible commercially insured patients may pay as little as $0 for their 1st prescription fill and $20 for each subsequent fill; maximum annual savings of $2750; offer may be used 12 times annually; for additional information contact the program at 844-903-2661.",
                "brand": "Thyquidity",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "2750",
                "lifetime_uses": "12",
                "max_saving_transaction": 226,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b65c"
            },
            {
                "_id": "6297bcace0e0db6c2572b091",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Ibrance Pfizer Oncology Together Co-Pay Savings Program Rebate (oral products): Eligible commercially insured patients may submit a request for a rebate in connection with this offer if their pharmacy does not accept the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Ibrance",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b091"
            },
            {
                "_id": "6297bcace0e0db6c2572b661",
                "link": "https://www.pfizerpro.com/request-samples",
                "description": "Tikosyn Samples: Your healthcare provider may request samples by logging into the PfizerPro website or calling 800-505-4426.",
                "brand": "Tikosyn",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b661"
            },
            {
                "_id": "6297bcace0e0db6c2572b096",
                "link": "https://www.icyhot.com/coupons/",
                "description": "Save $1 on any one IcyHot product (excludes 1 count patch products and 1.25 oz cream products); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "IcyHot Advanced Patch",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b096"
            },
            {
                "_id": "6297bcace0e0db6c2572b666",
                "link": "https://www.tirosint.com/healthcare-professional-resources/",
                "description": "Tirosint Samples: Healthcare providers may request samples for their practice by faxing an order form to 973-644-2379.",
                "brand": "Tirosint",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b666"
            },
            {
                "_id": "6297bcace0e0db6c2572b09b",
                "link": "https://www.icyhot.com/coupons/",
                "description": "Save $1 on any one IcyHot product (excludes 1 count patch products and 1.25 oz cream products); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "IcyHot Foam",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b09b"
            },
            {
                "_id": "6297bcace0e0db6c2572b0a0",
                "link": "https://www.icyhot.com/coupons/",
                "description": "Save $1 on any one IcyHot product (excludes 1 count patch products and 1.25 oz cream products); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "IcyHot No Mess Roll-On",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0a0"
            },
            {
                "_id": "6297bcace0e0db6c2572b66b",
                "link": "https://myviivcard.com",
                "description": "Tivicay ViiVConnect Savings Card: Eligible commercially insured patients pay $0 per prescription with savings of up to $5000 per year with no monthly limit; for additional information contact the program at 844-588-3288.",
                "brand": "Tivicay",
                "copay": "Yes",
                "bin": "610626",
                "rxpcn": "1016",
                "copay_max": "5000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b66b"
            },
            {
                "_id": "6297bcace0e0db6c2572b670",
                "link": "https://www.tobipodhaler.com/en/getting-started-with-tobi-podhaler/what-is-podcare",
                "description": "Tobi Podhaler PodCare+ Co-pay Program:Eligible commercially insured patients may pay a $0 prescription co-pay with savings of up to $14,000 per calendar year; for additional information contact the program at 877-999-8624.",
                "brand": "Tobi Podhaler",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "14000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b670"
            },
            {
                "_id": "6297bcace0e0db6c2572b0a5",
                "link": "https://www.idelvion.com/savings-support/other-financial-support",
                "description": "Idelvion CSL Behring Assurance: Eligible commercially insured patients may earn points for each monthly prescription; points may be used in the event of a lapse in coverage; for additional information contact the program at 800-676-4266.",
                "brand": "Idelvion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0a5"
            },
            {
                "_id": "6297bcace0e0db6c2572b0aa",
                "link": "https://iluvien.com/support/financial-assistance/",
                "description": "Iluvien CoPay Program: Eligible commercially insured patients may pay no more than $25 on out-of-pocket costs per injection; applies only to injection costs not the injection procedure, diagnostic tests or other costs associated with the administration of Iluvien; for more information contact the program at 844-445-8843 Option 3.",
                "brand": "Iluvien",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0aa"
            },
            {
                "_id": "6297bcace0e0db6c2572b675",
                "link": "https://qudexyxr.com/authorized-generic/",
                "description": "Topiramate Blink Pharmacy Plus: Eligible commercially insured patients may pay the lowest possible out-of-pocket cost based on insurance coverage; for additional information contact the program at 844-976-3109.",
                "brand": "Topiramate",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b675"
            },
            {
                "_id": "6297bcace0e0db6c2572b0af",
                "link": "https://amgenfirststep.com",
                "description": "Imlygic Amgen First Step Program Rebate: Eligible commercially insured patients who have already paid for treatment may request a rebate check; for additional information contact the program at 888-657-8371.",
                "brand": "Imlygic",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0af"
            },
            {
                "_id": "6297bcace0e0db6c2572b67a",
                "link": "https://www.patientrebateonline.com/patientrebate/welcome.html",
                "description": "Toujeo Sanofi Savings Card Rebate: Eligible commercially patients using a mail-order pharmacy can submit a request for a rebate up to the amount of savings earned with the Savings Card; or additional information contact the program at 866-390-5622.",
                "brand": "Toujeo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b67a"
            },
            {
                "_id": "6297bcace0e0db6c2572b67f",
                "link": "https://www.medimetriks.com/prescription-brands/tovet-kit-tovet-foam",
                "description": "Tovet Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Tovet",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b67f"
            },
            {
                "_id": "6297bcace0e0db6c2572b0b4",
                "link": "https://mycareclubrewards.com/imodium/?utm_medium=partner&utm_source=imodium.com&utm_content=footer&utm_campaign=cc_main&_gl=1*rj31n4*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjY1MDQ3OC41LjEuMTYzNjY1MDUzMC44*_fplc*SyUyQnh2V1hwcnlYTG1ONHJlN0g5NjE5OE93WG1hd21tWkI3RktEcGtsdTMlMkZtU3hsVGxjZ1VVMU92MUVzclZEakU5JTJGYXJPJTJGSXVqbkZBUWFNazRYa0dSZkE2YkdLWGxKdzdjNGhVaFplV3JzNTg3aVpJUEhOTmRYJTJGRmlId2RIdyUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Imodium A-D products with registration.",
                "brand": "Imodium Multi-Symptom Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0b4"
            },
            {
                "_id": "6297bcace0e0db6c2572b684",
                "link": "https://www.pfizerpro.com/request-samples",
                "description": "Trecator Samples: Your healthcare provider may request samples by contacting PfizerPro online or calling 800-505-4426.",
                "brand": "Trecator",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b684"
            },
            {
                "_id": "6297bcace0e0db6c2572b0b9",
                "link": "https://www.ipsencares.com/increlex-patient-support/",
                "description": "Increlex Ipsen Cares Copay Assistance Program: Eligible cash-pay patients may save $1000 per prescription with a maximum savings of $12,000 per calendar year; for additional information contact the program at 866-435-5677.",
                "brand": "Increlex",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 1000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0b9"
            },
            {
                "_id": "6297bcace0e0db6c2572b689",
                "link": "https://www.janssencarepath.com/patient/tremfya/cost-support",
                "description": "Tremfya Janssen CarePath Savings Card: Most commercially insured patients may pay $5 copay per injection with savings of up to $20,000 per year on out-of-pocket costs; for additional information contact the program at 877-227-3728.",
                "brand": "Tremfya",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b689"
            },
            {
                "_id": "6297bcace0e0db6c2572b0be",
                "link": "https://www.ingrezza.com/savings-resources",
                "description": "Ingrezza Start Program: Eligible NEW patients may receive a FREE, one-time, one-month prescription supply; for additional information contact the program at 844-647-3992.",
                "brand": "Ingrezza",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0be"
            },
            {
                "_id": "6297bcace0e0db6c2572b68e",
                "link": "https://www.novomedlink.com/diabetes/samples.html",
                "description": "Tresiba Samples: Healthcare providers may submit a sample request every 30 days.",
                "brand": "Tresiba U-100 FlexTouch",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b68e"
            },
            {
                "_id": "6297bcace0e0db6c2572b693",
                "link": "http://www.xspirerx.com/patient-assistance/",
                "description": "Trezix Specialty Instant Rebate: Patients may receive instant savings by printing the page and providing it to their pharmacy; for more information contact the program at 601-990-9497.",
                "brand": "Trezix",
                "copay": "No",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "420",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b693"
            },
            {
                "_id": "6297bcace0e0db6c2572b0c3",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Inlyta Pfizer Oncology Together Mail-In Rebate: If a pharmacy does not accept the Savings Card the patient may submit a request for a rebate in connection with the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Inlyta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0c3"
            },
            {
                "_id": "6297bcace0e0db6c2572b698",
                "link": "http://www.triaminic.com/signup",
                "description": "Register for special offers, latest news and updates for Triaminic products.",
                "brand": "Triaminic Day Time Cold and Cough",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b698"
            },
            {
                "_id": "6297bcace0e0db6c2572b0c8",
                "link": "https://www.novocare.com/insulin/my99insulin.html?src=100001849",
                "description": "Insulin�Aspart Injection My$99Insulin Card:Eligible patients pay $99 per monthly prescription (up to 3 vials or 2 packs of pens) of Novo Nordisk insulin products; offer may be used once a month during a calendar year; for additional information contact the program at 888-910-0632.",
                "brand": "Insulin Aspart",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0c8"
            },
            {
                "_id": "6297bcace0e0db6c2572b69d",
                "link": "https://www.trijardyxr.com/support-and-savings/savings-eligibility",
                "description": "Trijardy XR Savings Card: Eligible commercially insured patients may pay as little as $10 per 30 or 90-day fill; maximum savings of up to $175 per 30-day supply; for additional onformation contact the program at 855-779-5658.",
                "brand": "Trijardy XR",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "2100",
                "lifetime_uses": "12",
                "max_saving_transaction": 175,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b69d"
            },
            {
                "_id": "6297bcace0e0db6c2572b0cd",
                "link": "https://uspco.com/product_literature.aspx?pid=66&tab=Voucher",
                "description": "Integra F Pharmacy Voucher: Eligible patients may save up to 80% at participating pharmacies; for additional information contact the program at 888-602-2978.",
                "brand": "Integra F",
                "copay": "No",
                "bin": "600428",
                "rxpcn": "5100000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0cd"
            },
            {
                "_id": "6297bcace0e0db6c2572b6a2",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Tritocin Artesa Labs Advantage Program: Eligible commercially insured/Rx not covered patients may pay as little as $45 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Tritocin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b6a2"
            },
            {
                "_id": "6297bcace0e0db6c2572b0d2",
                "link": "https://us.intrarosa.com/savings-and-support",
                "description": "Intrarosa Savings Program: Eligible commercially insured patients may pay as little as $35 per 28-day supply for up to 12 months; for additional information contact the program at 844-492-9898.",
                "brand": "Intrarosa",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0d2"
            },
            {
                "_id": "6297bcace0e0db6c2572b6a7",
                "link": "https://myviivcard.com/rebate-form-instructions.html",
                "description": "Trizivir ViiVConnect Rebate: Eligible commercially insured patients may be eligible for a rebate for the amount paid out of pocket per prescription if the pharmacy does not accept the savings card; rebate may be completed online or by downloading and mailing in rebate form; for additional information contact the program at 844-588-3288.",
                "brand": "Trizivir",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b6a7"
            },
            {
                "_id": "6297bcace0e0db6c2572b0d7",
                "link": "https://www.janssencarepath.com/patient/invega-sustenna/cost-support",
                "description": "Invega Sustenna Janssen CarePath Savings Program: Eligible private/commercially insured patients may pay no more than $10 per dose; $8,000 maximum program benefit per calendar year or 13 doses whichever comes first; for additional information contact the program at 877-227-3728.",
                "brand": "Invega Sustenna",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "8000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0d7"
            },
            {
                "_id": "6297bcade0e0db6c2572b6ac",
                "link": "https://www.trokendixr.com/co-pay-savings-program-support",
                "description": "Trokendi XR Patient Savings Program: Eligible cash-paying patients may pay $0 co-pay per prescription; for additional information contact the program at 866-398-0833.",
                "brand": "Trokendi XR",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6ac"
            },
            {
                "_id": "6297bcace0e0db6c2572b0dc",
                "link": "https://www.invokana.com/savings-and-cost-support",
                "description": "Invokamet Janssen CarePath Savings Program: Most commercially insured patients may pay $0 copay per month with savings of up to $3000 per calendar year on out-of-pocket costs; for additional information contact the program at 877-468-6526.",
                "brand": "Invokamet",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0dc"
            },
            {
                "_id": "6297bcade0e0db6c2572b6b1",
                "link": "https://www.truseltiq.com/forging-bridges-affordability-options",
                "description": "Truseltiq Copay Assistance Program: Eligible commercially insured patients may pay as little as $0 per prescription; maximum benefit of up to $40,000 per year; for additional information contact the program at 888-552-7434.",
                "brand": "Truseltiq",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "40000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6b1"
            },
            {
                "_id": "6297bcace0e0db6c2572b0e1",
                "link": "http://wcpharma.com/patient-savings/",
                "description": "Irospan 24/6 Women's Choice Savings Coupon: Eligible uninsured/cash-paying patients may pay no more than $50 per prescription; for additional information contact the program at 800-664-1490.",
                "brand": "Irospan 24-6",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0e1"
            },
            {
                "_id": "6297bcade0e0db6c2572b6b6",
                "link": "https://dsiaccesscentral.com/patient/turalio/financial-help",
                "description": "Turalio Copay Program: Eligible commercially insured patients may save up to $25,000 per year on copays; for additional information contact the program at 800-850-4306.",
                "brand": "Turalio",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6b6"
            },
            {
                "_id": "6297bcace0e0db6c2572b0e6",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Istalol Bausch + Lomb Access Program: Eligible commercially insured patients may pay no more than $25 for each prescription; for additional information contact the program 866-693-4880.",
                "brand": "Istalol",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0e6"
            },
            {
                "_id": "6297bcade0e0db6c2572b6bb",
                "link": "https://copay.novartisoncology.com/",
                "description": "Tykerb Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Tykerb",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6bb"
            },
            {
                "_id": "6297bcace0e0db6c2572b0eb",
                "link": "https://copay.novartisoncology.com/",
                "description": "Jadenu Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Jadenu",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0eb"
            },
            {
                "_id": "6297bcade0e0db6c2572b6c0",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol Cold plus Head Congestion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6c0"
            },
            {
                "_id": "6297bcace0e0db6c2572b0f0",
                "link": "https://www.janumetxr.com/",
                "description": "Janumet Savings Coupon Rebate: If a commercially insured patient is unable to redeem the savings coupon at their retail or mail-order pharmacy they can contact the program for a Direct Member Reimbursement form; contact must be made within 30 days of purchase; for additional information contact the program at 877-264-2454.",
                "brand": "Janumet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0f0"
            },
            {
                "_id": "6297bcade0e0db6c2572b6c5",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol Extra Strength Cold and Flu Multi Action Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6c5"
            },
            {
                "_id": "6297bcace0e0db6c2572b0f5",
                "link": "https://www.merckconnect.com/januvia/coupons-samples/",
                "description": "Januvia Free Samples: Your healthcare provider may request samples by signing into the Merck Connect website.",
                "brand": "Januvia",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0f5"
            },
            {
                "_id": "6297bcade0e0db6c2572b6ca",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol Rapid Release Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6ca"
            },
            {
                "_id": "6297bcace0e0db6c2572b0fa",
                "link": "https://www.togetherwithgskoncology.com/patient-information/jemperli/",
                "description": "Jemperli GSK Co-pay Program: Eligible patients may receive financial assistance up to $100 per medication cost & administration; maximum annual savings of $26,000; for more information contact the program at 844-447-5662.",
                "brand": "Jemperli",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "26000",
                "lifetime_uses": "0",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0fa"
            },
            {
                "_id": "6297bcade0e0db6c2572b6cf",
                "link": "https://www.tymlos.com/savings-support",
                "description": "Tymlos Savings Card: Eligible commercially insured patients may pay as little as $0 per month in out-of-pocket costs for their prescription with savings of up to $6500 per calendar year; for additional information contact the program at 855-243-6222.",
                "brand": "Tymlos",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "6500",
                "lifetime_uses": "12",
                "max_saving_transaction": 541.66,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6cf"
            },
            {
                "_id": "6297bcace0e0db6c2572b0ff",
                "link": "https://www.jivi-us.com/copay-support/",
                "description": "Jivi Free Trial Program: Eligible patients may receive up a one-month supply (maximum of 40,000 IU); available to new patients 12 and up; form to be filled out by both patient & doctor; for additional information contact the program at 800-288-8374.",
                "brand": "Jivi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0ff"
            },
            {
                "_id": "6297bcade0e0db6c2572b6d4",
                "link": "https://www.tyvaso.com/pah/starting-tyvaso/cost-assistance/",
                "description": "Tyvaso Copay Assistance Program: Eligible commercially insured patients may pay only $5 per prescription with savings of up to $8000 per year; for additional information contact the program at 877-864-8437.",
                "brand": "Tyvaso",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "8000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6d4"
            },
            {
                "_id": "6297bcade0e0db6c2572b6d9",
                "link": "https://www.coheruscomplete.com/financial-assistance/commercial",
                "description": "Udenyca Coherus Complete Co-Pay Assistance Program: Eligible commercially insured patients may pay $0 on out-of-pocket costs per dose and save up to $15,000 per 12-month enrollment period; for additional information contact the program at 844-483-3692.",
                "brand": "Udenyca",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6d9"
            },
            {
                "_id": "6297bcace0e0db6c2572b104",
                "link": "https://www.jornaypm.com/savings",
                "description": "Jornay Copay Savings Program: Eligible commercially insured patients pay$0 for their 1st prescription of 30 capsules and then as little as $25 per each subsequent fills; valid for 13 uses per year; for additional information at 877-938-4766.",
                "brand": "Jornay PM",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b104"
            },
            {
                "_id": "6297bcade0e0db6c2572b6de",
                "link": "https://ultravatelotion.com/save-page/",
                "description": "Ultravate Co-Pay Card: Eligible commercially insured patients may pay as little as $0 per prescription; for additional information contact the program at 855-820-9077.",
                "brand": "Ultravate",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6de"
            },
            {
                "_id": "6297bcace0e0db6c2572b655",
                "link": "https://www.theratears.com/products/",
                "description": "Save $1 on TheraTears products; trial sizes not included in offer; one coupon per purchase.",
                "brand": "TheraTears Extra Dry Eye Therapy Single Use Vials",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b655"
            },
            {
                "_id": "6297bcace0e0db6c2572b65a",
                "link": "https://www.boironusa.com/throatcalm-coupon/",
                "description": "$2 coupon off ThroatCalm with registration; 1 coupon per purchase.",
                "brand": "ThroatCalm",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b65a"
            },
            {
                "_id": "6297bcace0e0db6c2572b65f",
                "link": "https://servierone.com/s/patient/tibsovo",
                "description": "Tibsovo myAgios Co-Pay Program: Eligible commercially insured patients may pay no more than $25 per prescription with savings of up to $25,000 per year; for additional information contact the program at 844-409-1141.",
                "brand": "Tibsovo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b65f"
            },
            {
                "_id": "6297bcace0e0db6c2572b109",
                "link": "https://myviivcard.com/rebate-form-instructions.html",
                "description": "Juluca ViiVConnect Rebate: Eligible commercially insured patients may be eligible for a rebate for the amount paid out of pocket per prescription if the pharmacy does not accept the savings card; rebate may be completed online or by downloading and mailing in rebate form; for additional information contact the program at 844-588-3288.",
                "brand": "Juluca",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6250",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b109"
            },
            {
                "_id": "6297bcade0e0db6c2572b6e3",
                "link": "https://www.unisom.com/en-us/coupons",
                "description": "Save $1 on any Unisom product (24 count or larger); one coupon per purchase; expires one month after printing",
                "brand": "Unisom SleepMinis",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6e3"
            },
            {
                "_id": "6297bcace0e0db6c2572b664",
                "link": "https://www.tirosint.com/patient-savings-resources/ways-to-save/",
                "description": "Tirosint Copay Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription with a savings of up to $85 per fill; for additional information contact the program at 833-666-2501.",
                "brand": "Tirosint",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1020",
                "lifetime_uses": "12",
                "max_saving_transaction": 85,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b664"
            },
            {
                "_id": "6297bcace0e0db6c2572b10e",
                "link": "https://www.alle.com/upgrade",
                "description": "Juvederm Vollure XC Alle Points Program: Register and earn points redeemable for dollars OFF certain in-office treatments; also receive personalized special offers; for additional information contact the program at 888-912-1572.",
                "brand": "Juvederm Vollure XC",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b10e"
            },
            {
                "_id": "6297bcade0e0db6c2572b6e8",
                "link": "https://pah.janssencarepath.com/patient/uptravi/patient-support.html",
                "description": "Uptravi Janssen CarePath Oral PAH Savings Program: Eligible commercially insured patients may pay $5 per prescription fill; maximum savings benefit of $20,000 per calendar year; for additional information contact the program at 866-228-3546.",
                "brand": "Uptravi",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6e8"
            },
            {
                "_id": "6297bcace0e0db6c2572b669",
                "link": "https://www.tissueblue.com/#section-sample",
                "description": "TissueBlue Free Sample: Healthcare professionals may request a free sample for their patients upon completing the online request form; for additional information contact the program at 603-778-6929.",
                "brand": "TissueBlue",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b669"
            },
            {
                "_id": "6297bcade0e0db6c2572b6ed",
                "link": "https://www.alva-amco.com/contact",
                "description": "Consumers may fill out a request form for Uricalm product coupons.",
                "brand": "Uricalm Max",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6ed"
            },
            {
                "_id": "6297bcace0e0db6c2572b113",
                "link": "https://www.kalbitor.com/copay-assistance",
                "description": "Kalbitor OnePath Co-Pay Assistance Program: Eligible commercially insured patients may pay $0 for eligible deductibles, co-pays, and co-insurance; for additional information contact the program at 866-888-0660.",
                "brand": "Kalbitor",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b113"
            },
            {
                "_id": "6297bcace0e0db6c2572b118",
                "link": "https://kanuma.com",
                "description": "Kanuma Alexion Commercial Co-pay Program: Eligible commercially insured patients may pay as little as $5 per out-of-pocket expense; for more information contact the program at 888-765-4747.",
                "brand": "Kanuma",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b118"
            },
            {
                "_id": "6297bcade0e0db6c2572b6f2",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Utopic Artesa Labs Advantage Program: Eligible commercially insured/Rx not covered patients may pay as little as $45 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Utopic Cream",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6f2"
            },
            {
                "_id": "6297bcace0e0db6c2572b11d",
                "link": "https://karbinaler.com",
                "description": "Karbinal ER Aytu RxConnect Pharmacy Network Program: Eligible commercially insured patients may pay $0 per 30-day prescription when using a Aytu RxConnect Pharmacy; for additional information contact the program at 877-675-6590.",
                "brand": "Karbinal ER",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b11d"
            },
            {
                "_id": "6297bcade0e0db6c2572b6f7",
                "link": "https://www.activatethecard.com/7774/",
                "description": "Varubi Co-pay Assistance Program: Eligible commercially insured patients may pay $0 per prescription with savings of up to $200 per fill and $2000 per calendar year; for additional information contact the program at 844-864-3014.",
                "brand": "Varubi",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "2000",
                "lifetime_uses": "0",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6f7"
            },
            {
                "_id": "6297bcade0e0db6c2572b6fc",
                "link": "https://amgenfirststep.com/register-card",
                "description": "Vectibix Amgen First Step Program: Eligible commercially insured patients may save on their out-of-pocket medication costs; for additional information contact the program at 888-427-7478.",
                "brand": "Vectibix",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6fc"
            },
            {
                "_id": "6297bcace0e0db6c2572b122",
                "link": "https://www.kerasal.com/pages/coupons",
                "description": "Save $3 on Kerasal Multi-Purpose Nail Repair; 1 coupon per purchase.",
                "brand": "Kerasal Multi-Purpose Nail Repair",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b122"
            },
            {
                "_id": "6297bcace0e0db6c2572b127",
                "link": "https://www.keveyis.com/patient/support-program/",
                "description": "Keveyis Co-Pay Program: Eligible patients may pay as little as $0 per prescriptions with a maximum annual savings of $10,000; for additional information contact the program at 844-538-3947.",
                "brand": "Keveyis",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b127"
            },
            {
                "_id": "6297bcade0e0db6c2572b701",
                "link": "https://www.venclexta.com/previously-untreated-cll/resources-and-support/financial-and-treatment-support",
                "description": "Venclexta Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Venclexta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b701"
            },
            {
                "_id": "6297bcade0e0db6c2572b706",
                "link": "https://www.veregen.com/savings-and-support",
                "description": "Veregen eVoucherRx Program: Eligible patients may pay as little as $0 per prescription with a maximum savings of $326 per prescription; for additional information contact 844-396-8097.",
                "brand": "Veregen",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3912",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b706"
            },
            {
                "_id": "6297bcace0e0db6c2572b12c",
                "link": "https://kimyrsa.com/support-programs/",
                "description": "Kimyrsa Copay Savings Program: Eligible commercially insured patients may pay as little $50 per treatment; for additional information contact the program at 844-546-9772.",
                "brand": "Kimyrsa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b12c"
            },
            {
                "_id": "6297bcade0e0db6c2572b70b",
                "link": "https://www.go-vgo.com/co-pay/",
                "description": "V-Go Cost Savings Card: Eligible commercially insured patients may pay as little as $50 per 30-day fill; for additional information contact the program at 866-881-1209.",
                "brand": "VGo",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b70b"
            },
            {
                "_id": "6297bcace0e0db6c2572b131",
                "link": "https://www.us.kisqali.com/metastatic-breast-cancer/patient-support/financial-resources/",
                "description": "Kisqali Femara Co-Pack 1 Free Treatment Cycle: Eligible patients may receive a 1-treatment cycle supply for free; for additional information contact the program at 800-282-7630.",
                "brand": "Kisqali and Femara",
                "copay": "No",
                "bin": "601341",
                "rxpcn": "OHS",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b131"
            },
            {
                "_id": "6297bcace0e0db6c2572b136",
                "link": "https://almiralladvantage.com",
                "description": "Klisyri Savings Offer: Eligible commercially insured patients may pay as little as $25 per fill; for additional information contact the program at 888-591-9860.",
                "brand": "Klisyri",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b136"
            },
            {
                "_id": "6297bcace0e0db6c2572b13b",
                "link": "https://www.kombiglyzexr.com/savings-support/kombiglyze-coupon.html",
                "description": "Kombiglyze XR Instant Savings Card: Eligible commercially insured patients may pay as little as $0 per 30-day supply; for additional information contact the program at 855-907-3197.",
                "brand": "Kombiglyze XR",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b13b"
            },
            {
                "_id": "6297bcace0e0db6c2572b140",
                "link": "https://www.kovaltry-us.com/access-services-by-bayer",
                "description": "Kovaltry Loyalty Program: Eligible commercially insured patients may receive medication at no cost for up to 4 months if they are experiencing gaps or changes in their insurance coverage; the patients physician must fill out a form for enrollment; for additional information contact the program at 800-288-8374.",
                "brand": "Kovaltry",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b140"
            },
            {
                "_id": "6297bcace0e0db6c2572b145",
                "link": "https://www.kristalose.com",
                "description": "Kristalose Free Sample: Your healthcare provider may request a free sample by downloading, completing and submitting the sample request form; for additional information contact the program at 855-584-6194.",
                "brand": "Kristalose",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b145"
            },
            {
                "_id": "6297bcace0e0db6c2572b14a",
                "link": "https://www.kynmobi.com/savings/",
                "description": "Kynmobi Copay Savings Card: Eligible commercially insured patients may pay as little as $15 per 30-count carton; for additional information contact the program at 844-596-6624.",
                "brand": "Kynmobi",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b14a"
            },
            {
                "_id": "6297bcace0e0db6c2572b14f",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Lacrisert Bausch + Lomb Access Program: Eligible cash-paying and commercially insured/RX not covered patients may pay no more than $60 per prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Lacrisert",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b14f"
            },
            {
                "_id": "6297bcace0e0db6c2572b154",
                "link": "https://www.patientrebateonline.com/patientrebate/welcome.html",
                "description": "Lantus Valyou Savings Rebate: Eligible uninsured cash-paying patients may be able to submit a request for a rebate up to the amount of savings earned with the Savings Card if their pharmacy does not accept the Savings Card; or additional information contact the program at 866-390-5622.",
                "brand": "Lantus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b154"
            },
            {
                "_id": "6297bcace0e0db6c2572b159",
                "link": "https://www.lantus.com/sign-up-for-savings",
                "description": "Lantus Valyou Savings Program: Eligible uninsured cash-paying patients will pay $99 per monthly supply of up to 10 vials or packs of SoloStar pens per fill or up to 5 packs of Max SoloStar pens per fill; for additional information contact the program at 833-813-0190.",
                "brand": "Lantus SoloSTAR Pen",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b159"
            },
            {
                "_id": "6297bcace0e0db6c2572b15e",
                "link": "https://www.lantus.com/sign-up-for-savings",
                "description": "Lantus Valyou Savings Program: Eligible uninsured cash-paying patients will pay $99 per monthly supply of up to 10 vials or packs of SoloStar pens per fill or up to 5 packs of Max SoloStar pens per fill; for additional information contact the program at 833-813-0190.",
                "brand": "Lantus U-100",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b15e"
            },
            {
                "_id": "6297bcace0e0db6c2572b163",
                "link": "https://www.sunovionprofile.com/latuda-bpd/resources/resources-and-savings/",
                "description": "Latuda Samples: Your healthcare provider may request samples by logging onto the website.",
                "brand": "Latuda",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b163"
            },
            {
                "_id": "6297bcace0e0db6c2572b168",
                "link": "https://www.letairis.com/patients/financial-resources?gclid=CjwKCAjwpMOIBhBAEiwAy5M6YNs0E1ehikYCSXgBG7y9fiH5inrLvU65HoqW0YunJznobaiEwW1Y1xoC6hwQAvD_BwE",
                "description": "Letairis Co-pay Coupon Program: Eligible commercially insured patients may pay as little as $5 per monthly fill; maximum savings of $20,000 per year; for additional information contact the program at 866-664-5327.",
                "brand": "Letairis",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1666.66,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b168"
            },
            {
                "_id": "6297bcace0e0db6c2572b16d",
                "link": "http://lexette.com/savings",
                "description": "Lexette Mayne Pharma Patient Savings Card: Eligible commercially insured patients/RX not covered may pay as little as $65 per prescription; for additional information contact the program at 347-442-7919.",
                "brand": "Lexette",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b16d"
            },
            {
                "_id": "6297bcace0e0db6c2572b172",
                "link": "https://www.lilettacard.com/",
                "description": "Liletta Patient Savings Card Rebate: If a commercially eligible patient already paid for their prescription they may apply for a rebate; for additional information contact the program at 855-706-4508.",
                "brand": "Liletta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 750,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b172"
            },
            {
                "_id": "6297bcace0e0db6c2572b177",
                "link": "https://www.lipitor.com/savings",
                "description": "Lipitor Savings Card: Eligible patients may pay as little as $4 per 30-day fill with savings of up to $1800 per year; for additional information contact the program at 800-314-7957.",
                "brand": "Lipitor",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b177"
            },
            {
                "_id": "6297bcace0e0db6c2572b17c",
                "link": "https://mycareclubrewards.com/listerine/?utm_medium=partner&utm_source=listerine.com&utm_content=banner&utm_campaign=cc_main&_gl=1*1srhw77*_ga*MjAwOTYxNTY1My4xNjM2OTk3MjUw*_ga_13VEM6N66E*MTYzNzA4NTM0NS41LjEuMTYzNzA4NjM4MC41OA..*_fplc*NklTRGQ1UjNacSUyQkh0aCUyRlVLJTJGelFxeUZEZVJvWSUyQk5pd1RDcWVwakRqbmZURSUyQndhcyUyRkZmV2ltZ0c5R3pqOFNBR0gxRmRZQ25oSEk2eWhMNUhXNHVyRkliTDE5bWFZcyUyRnNiRTVSZldXaDJ2MDh6d1RlRHlCVzFHblphJTJCa2dkdyUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Listerine products with registration.",
                "brand": "Listerine Ultra Clean Mouthwash Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b17c"
            },
            {
                "_id": "6297bcace0e0db6c2572b181",
                "link": "https://www.takedapatientsupport.com",
                "description": "Livtencity Takeda Patient Support Co-Pay Assistance Program: Eligible commercially insured patients pay as little as $0 per prescription; for additional information contact the program at 855-268-1825.",
                "brand": "Livtencity",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b181"
            },
            {
                "_id": "6297bcace0e0db6c2572b186",
                "link": "https://www.myaccess360.com/patient/lokelma/patient-affordability.html",
                "description": "Lokelma Access 360 Co-pay Savings Card: Eligible cash-paying patients may save up to $250 per 30-packet supply; offer may be used once a month for up to 12 months; for additional information contact the program at 844-565-3562.",
                "brand": "Lokelma",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b186"
            },
            {
                "_id": "6297bcace0e0db6c2572b18b",
                "link": "https://www.pfizermedicalinformation.com/en-us/lopid",
                "description": "Lopid Samples: Healthcare providers may request samples by logging onto the PfizerPro website or calling 800-505-4426.",
                "brand": "Lopid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b18b"
            },
            {
                "_id": "6297bcace0e0db6c2572b190",
                "link": "https://www.loreevxr.com",
                "description": "Loreev XR Copay Card: Eligible commercially insured patients/RX not covered may pay as little as $75 per 30 capsules; for additional information contact the program at 844-240-3657.",
                "brand": "Loreev XR",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b190"
            },
            {
                "_id": "6297bcace0e0db6c2572b195",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Lotemax (Ointment) Bausch + Lomb Access Program: Eligible cash-paying patients may pay no more than $60 per  prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Lotemax Ointment",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b195"
            },
            {
                "_id": "6297bcace0e0db6c2572b19a",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Lotemax SM Bausch + Lomb Access Program: Eligible commercially insured patients may pay no more than $25 for each prescription; for additional information contact the program 866-693-4880.",
                "brand": "Lotemax SM",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b19a"
            },
            {
                "_id": "6297bcace0e0db6c2572b19f",
                "link": "https://www.lotrimin.com/coupon",
                "description": "Sign up and save on any one Lotrimin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Lotrimin AF Athletes Foot Daily Prevention Medicated Foot Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b19f"
            },
            {
                "_id": "6297bcace0e0db6c2572b1a4",
                "link": "https://www.lotrimin.com/coupon",
                "description": "Sign up and save on any one Lotrimin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Lotrimin Ultra Jock Itch Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1a4"
            },
            {
                "_id": "6297bcace0e0db6c2572b1a9",
                "link": "https://mycareclubrewards.com/lubriderm/?utm_medium=partner&utm_source=lubriderm.com&utm_content=header&utm_campaign=cc_main&_gl=1*10t4k0d*_ga*MTMzNjU1MjM2My4xNjIxMjg5NDk4*_ga_13VEM6N66E*MTYyMTI4OTQ5Ny4xLjEuMTYyMTI4OTg5My43",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Lubriderm products with registration.",
                "brand": "Lubriderm Mens Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1a9"
            },
            {
                "_id": "6297bcace0e0db6c2572b1ae",
                "link": "https://www.eyeoncopay.com/forms",
                "description": "Lucentis Genentech Ophthalmology Co-pay Program Rebate: Eligible commercially insured patients may request a rebate if they paid their provider directly for treatment before they enroled in the program; for additional information contact the program at 855-218-5307.",
                "brand": "Lucentis",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "16000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1ae"
            },
            {
                "_id": "6297bcace0e0db6c2572b1b3",
                "link": "https://www.savewithays.com/",
                "description": "Lumigan Savings Card: Eligible commercially insured patients pay as little as $30 per 30-day prescription; offer may be used for 13 fills; for additional information contact the program at 833-342-5297.",
                "brand": "Lumigan",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1b3"
            },
            {
                "_id": "6297bcace0e0db6c2572b1b8",
                "link": "https://www.lupronped.com/lupron-support-plus.html#savings-card",
                "description": "Lupron Depot-Ped Instant Savings Rebate: Eligible commercially insured patients may be able to submit a request for a rebate if unable to use the Savings Card when filling their prescription; for additional information contact the program at 877-832-9755.",
                "brand": "Lupron Depot-PED",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1b8"
            },
            {
                "_id": "6297bcace0e0db6c2572b1bd",
                "link": "https://www.pfizerpro.com/product/lyrica/hcp/support/copay-savings",
                "description": "Lyrica Co-Pay Savings Card Mail-In Rebate: If the eligible commercially insured patient uses a mail-order pharmacy or a retail pharmacy that does not accept the savings card the patient may be eligible for a rebate; for additional information contact the program at 866-954-1475.",
                "brand": "Lyrica",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1bd"
            },
            {
                "_id": "6297bcace0e0db6c2572b1c2",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program Lyumjev U-100 KwikPen: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Lyumjev U-100 KwikPen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1c2"
            },
            {
                "_id": "6297bcace0e0db6c2572b1c7",
                "link": "https://makena.com/makena-care-connection-support/",
                "description": "Makena Copay Assistance Program: Eligible commercially insured patients may pay lower out-of-pocket costs for their prescriptions, copays, coinsurance and deductibles; maximum assistance of up to $5000; based on a sliding scale from $0-$35 per injection; for additional information contact the program at 800-847-3418.",
                "brand": "Makena",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1c7"
            },
            {
                "_id": "6297bcace0e0db6c2572b1cc",
                "link": "https://www.mayzent.com/financial-support",
                "description": "Mayzent Co-Pay Card: Eligible commercially insured patients may pay $0 co-pay per prescription fill with an annual limit of $18,000; for additional information contact the program at 877-629-9368.",
                "brand": "Mayzent",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "18000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1cc"
            },
            {
                "_id": "6297bcace0e0db6c2572b1d1",
                "link": "https://www.mederma.com/coupons/",
                "description": "Save $3 off any Mederma Scar Care product; one coupon per purchase.",
                "brand": "Mederma PM Intensive Overnight Scar Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1d1"
            },
            {
                "_id": "6297bcace0e0db6c2572b1d6",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Mektovi Pfizer Oncology Together Mail-In Rebate: If a pharmacy does not accept the Savings Card the patient may submit a request for a rebate in connection with the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Mektovi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 208.33,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1d6"
            },
            {
                "_id": "6297bcace0e0db6c2572b1db",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Menopur HEARTFELT Medication Replacement Program: Eligible cash-paying patients whose COS cycles were canceled due to COVID (between 3/1/20 - 12/31/21) or a natural disaster (2/1/21 - 12/31/21) may receive replacement medication; new cycles must be initiated between March 1 - December 31 2021; for additional information contact the program at 800-515-3784.",
                "brand": "Menopur",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1db"
            },
            {
                "_id": "6297bcace0e0db6c2572b1e0",
                "link": "https://www.ultracaresupport.com/patient-services/",
                "description": "Mepsevii UltraCare Copay Assistance Program: Eligible commercially insured patients may be able to receive financial assistance to cover out-of-pockets costs for medicine and the cost of administration; for additional information contact the program at 888-756-8657.",
                "brand": "Mepsevii",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1e0"
            },
            {
                "_id": "6297bcace0e0db6c2572b1e5",
                "link": "https://www.metamucil.com/en-us/two-week-challenge",
                "description": "Save $2 off any Metamucil Fiber Product by registering your email; one coupon per purchase.",
                "brand": "Metamucil Fiber MultiGrain Wafers",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1e5"
            },
            {
                "_id": "6297bcace0e0db6c2572b1ea",
                "link": "https://www.metamucil.com/en-us/two-week-challenge",
                "description": "Save $2 off any Metamucil Fiber Product by registering your email; one coupon per purchase.",
                "brand": "Metamucil Fiber Singles",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1ea"
            },
            {
                "_id": "6297bcace0e0db6c2572b1ef",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "MimyX Artesa Labs Advantage Card: Eligible commercially insured patients may pay $0 per prescription; for additional information contact the program 877-264-2440.",
                "brand": "MimyX",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1ef"
            },
            {
                "_id": "6297bcace0e0db6c2572b1f4",
                "link": "https://www.miralax.com",
                "description": "Register your email address to receive a coupon for any one MiraLAX product; coupons expires 1 month after printing; 1 coupon per purchase.",
                "brand": "MiraLAX",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1f4"
            },
            {
                "_id": "6297bcace0e0db6c2572b1f9",
                "link": "https://hcp.monistat.com/coupons",
                "description": "Monistat Product Coupons: Healthcare providers may order coupons for their practice.",
                "brand": "Monistat 3-Day Treatment Combination Pack with Ovule",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1f9"
            },
            {
                "_id": "6297bcace0e0db6c2572b1fe",
                "link": "https://hcp.monistat.com/coupons",
                "description": "Monistat Product Coupons: Healthcare providers may order coupons for their practice.",
                "brand": "Monistat 7-Day Treatment Combination Pack",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1fe"
            },
            {
                "_id": "6297bcace0e0db6c2572b203",
                "link": "https://www.motegrityhcp.com/savings-support",
                "description": "Motegrity Samples: Healthcare professionals may request samples for their office",
                "brand": "Motegrity",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b203"
            },
            {
                "_id": "6297bcace0e0db6c2572b208",
                "link": "https://mycareclubrewards.com/motrin/?utm_medium=partner&utm_source=motrin.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1sghl7t*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjY0NzMxOC40LjEuMTYzNjY0NzMzMy40NQ..*_fplc*WWt2VDMxc2E1JTJCbWhGdlZndzlCTmt6TENXRFQxc0JuWkglMkY2TDRFQnl0SUdXYzc4QmEySTM4dnJhRmE3ak1nSTRqR0RrWHk5bjQzWWFRVDFKSmc0blc5ZVVyVHI2UUVkZURxenBQalBMZllaRjlLSnUxeXduUzdxMFF5Y1VWQSUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Motrin products with registration.",
                "brand": "Motrin IB Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b208"
            },
            {
                "_id": "6297bcace0e0db6c2572b20d",
                "link": "https://movantik.com/savings/",
                "description": "Movantik Savings Card: Eligible cash-paying patients may save up to $100 on out-of-pocket costs on each of up to 12 uses; for additional information contact the program at 984-444-7010.",
                "brand": "Movantik",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b20d"
            },
            {
                "_id": "6297bcace0e0db6c2572b212",
                "link": "https://www.mucinex.com/pages/savings",
                "description": "Register your email address to receive a $5 coupon for any Mucinex product and future savings offers.",
                "brand": "Mucinex Childrens FreeFrom Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b212"
            },
            {
                "_id": "6297bcace0e0db6c2572b217",
                "link": "https://www.mucinex.com/pages/savings",
                "description": "Register your email address to receive a $5 coupon for any Mucinex product and future savings offers.",
                "brand": "Mucinex Extended-Release Bi-Layer Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b217"
            },
            {
                "_id": "6297bcace0e0db6c2572b21c",
                "link": "https://www.mucinex.com/pages/savings",
                "description": "Register your email address to receive a $5 coupon for any Mucinex product and future savings offers.",
                "brand": "Mucinex Fast-Max Liqui-Gels Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b21c"
            },
            {
                "_id": "6297bcace0e0db6c2572b221",
                "link": "https://www.rbhcprofessional.com/s/",
                "description": "Mucinex Samples: Healthcare providers may register to order samples for their practice.",
                "brand": "Mucinex Nightshift Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b221"
            },
            {
                "_id": "6297bcace0e0db6c2572b226",
                "link": "https://www.mucinex.com/pages/savings",
                "description": "Register your email address to receive a $5 coupon for any Mucinex product and future savings offers.",
                "brand": "Mucinex Sinus-Max Liqui-Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b226"
            },
            {
                "_id": "6297bcace0e0db6c2572b22b",
                "link": "https://multibetic.com/",
                "description": "Save $1 off Multi-Betic Diabetes Vitamins; limit one coupon per purchase.",
                "brand": "Multi-Betic Diabetes Multi-Vitamin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b22b"
            },
            {
                "_id": "6297bcace0e0db6c2572b230",
                "link": "https://www.pfizermedicalinformation.com/en-us/mycobutin",
                "description": "Mycobutin Samples: Your healthcare provider may request samples by contacting PfizerPro at 800-505-4426 or online.",
                "brand": "Mycobutin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b230"
            },
            {
                "_id": "6297bcace0e0db6c2572b235",
                "link": "https://www.saveonmyprescription.com/copay.jsp",
                "description": "Myfortic $0 Co-Pay Card: Eligible commercially insured patients may pay $0 per prescription with maximum annual savings of up to $7,200; for additional information contact the program at 877-952-1000.",
                "brand": "Myfortic",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "7200",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b235"
            },
            {
                "_id": "6297bcace0e0db6c2572b23a",
                "link": "https://mytesi.com/napocares/",
                "description": "Mytesi Copay Savings Card: Eligible commercially insured patients may pay no more than $25 per prescription; for additional information contact the program at 888-527-6276.",
                "brand": "Mytesi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b23a"
            },
            {
                "_id": "6297bcace0e0db6c2572b23f",
                "link": "http://www.xspirerx.com/patient-assistance/",
                "description": "Nalfon Specialty Instant Rebate: Patients may receive instant savings by printing the page and providing it to their pharmacy; for more information contact the program at 601-990-9497.",
                "brand": "Nalfon",
                "copay": "No",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "420",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b23f"
            },
            {
                "_id": "6297bcace0e0db6c2572b244",
                "link": "https://www.eyefile.com/TheEyeSolution/Register/0?sid=CLRCR0513",
                "description": "Save $2 on your next purchase of Naphcon-A Eye Drops (5 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Naphcon A",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b244"
            },
            {
                "_id": "6297bcace0e0db6c2572b249",
                "link": "https://www.nasacort.com/for-adult-allergies/coupons/",
                "description": "Save $3 on any one Nasacort product; 1 coupon per purchase.",
                "brand": "Nasacort Childrens Allergy 24HR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b249"
            },
            {
                "_id": "6297bcace0e0db6c2572b24e",
                "link": "https://www.natesto.com/support-and-resources/#save-on-natesto",
                "description": "Natesto Cash Option Program: Eligible commercially insured patients/RX not covered may pay $140 per prescription of no less than 20 days (2 dispensers) and not to exceed 30 days (3 dispensers) per prescription dispensed for up to 12 refills; for additional information contact the program at 855-390-0162.",
                "brand": "Natesto",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b24e"
            },
            {
                "_id": "6297bcace0e0db6c2572b253",
                "link": "https://www.alva-amco.com/contact",
                "description": "Consumers may fill out a request form for Nauzene product coupons.",
                "brand": "Nauzene Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b253"
            },
            {
                "_id": "6297bcace0e0db6c2572b258",
                "link": "https://www.neevodha.com/ordering-information",
                "description": "NeevoDHA Brand Direct Health Program: Eligible patients may have low monthly payments on 90-day prescription quantities; for additional information contact the program at 866-331-6440.",
                "brand": "NeevoDHA",
                "copay": "",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b258"
            },
            {
                "_id": "6297bcace0e0db6c2572b25d",
                "link": "https://mycareclubrewards.com/neosporin/?utm_medium=partner&utm_source=neosporin.com&utm_content=header&utm_campaign=cc_main&_gl=1*1lx5xvo*_ga*MjA5MDY2NzQ5OS4xNjMzMzU4MTUw*_ga_13VEM6N66E*MTYzMzM1ODE1MC4xLjAuMTYzMzM1ODE1MC42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neosporin products with registration.",
                "brand": "Neosporin Burn Relief and First Aid Antibiotic Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b25d"
            },
            {
                "_id": "6297bcace0e0db6c2572b262",
                "link": "https://mycareclubrewards.com/neosporin/?utm_medium=partner&utm_source=neosporin.com&utm_content=header&utm_campaign=cc_main&_gl=1*1lx5xvo*_ga*MjA5MDY2NzQ5OS4xNjMzMzU4MTUw*_ga_13VEM6N66E*MTYzMzM1ODE1MC4xLjAuMTYzMzM1ODE1MC42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neosporin products with registration.",
                "brand": "Neosporin Plus Pain Relief Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b262"
            },
            {
                "_id": "6297bcace0e0db6c2572b267",
                "link": "https://nerlynx.com/access-and-support/access-programs",
                "description": "Nerlynx Supportive Care Voucher: Eligible commercially insured patients may receive a voucher good for a FREE 3-month supply of anti-diarrhea medicine;  for additional information contact the program at 855-816-5421.",
                "brand": "Nerlynx",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b267"
            },
            {
                "_id": "6297bcace0e0db6c2572b26c",
                "link": "http://wcpharma.com/patient-savings/",
                "description": "Nestabs ONE Women's Choice Savings Coupon: Eligible uninsured/cash-paying patients may pay no more than $50 per prescription; for additional information contact the program at 800-664-1490.",
                "brand": "Nestabs ONE",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b26c"
            },
            {
                "_id": "6297bcace0e0db6c2572b271",
                "link": "https://amgenfirststep.com/register-card",
                "description": "Neulasta Onpro Kit Amgen First Step Program: Eligible commercially insured patients may save on their out-of-pocket medication costs; for additional information contact the program at 888-427-7478.",
                "brand": "Neulasta Onpro Kit",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b271"
            },
            {
                "_id": "6297bcace0e0db6c2572b276",
                "link": "https://www.neutrogena.com/care-club.html",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neutrogena products with registration.",
                "brand": "Neutrogena Body Clear Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b276"
            },
            {
                "_id": "6297bcace0e0db6c2572b27b",
                "link": "https://www.neutrogena.com/care-club.html",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neutrogena products with registration.",
                "brand": "Neutrogena Deep Moisture Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b27b"
            },
            {
                "_id": "6297bcace0e0db6c2572b280",
                "link": "https://www.neutrogena.com/care-club.html",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neutrogena products with registration.",
                "brand": "Neutrogena Hydro Boost Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b280"
            },
            {
                "_id": "6297bcace0e0db6c2572b285",
                "link": "https://www.neutrogena.com/offers.html",
                "description": "Sign up and receive 15% off 1st purchase plus free shipping on purchases $25 or more when you shop online for any one Neutrogena Product.",
                "brand": "Neutrogena Rapid Clear Acne Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b285"
            },
            {
                "_id": "6297bcace0e0db6c2572b28a",
                "link": "https://www.neutrogena.com/care-club.html",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neutrogena products with registration.",
                "brand": "Neutrogena Stubborn Acne Treatment Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b28a"
            },
            {
                "_id": "6297bcace0e0db6c2572b28f",
                "link": "https://newskinproducts.com/pages/coupons",
                "description": "Save $2 on any New Skin product; 1 coupon per purchase.",
                "brand": "New-Skin Liquid Bandage Botanicals",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b28f"
            },
            {
                "_id": "6297bcace0e0db6c2572b294",
                "link": "https://www.nexcare.com/3M/en_US/nexcare/deals-offers/",
                "description": "Sign-up with Nexcare Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "Nexcare Bandages",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b294"
            },
            {
                "_id": "6297bcace0e0db6c2572b299",
                "link": "https://www.nexcare.com/3M/en_US/nexcare/deals-offers/",
                "description": "Sign-up with Nexcare Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "Nexcare Wound and Dressing Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b299"
            },
            {
                "_id": "6297bcace0e0db6c2572b29e",
                "link": "https://www.nexium24hr.com/us/coupons-for-nexium/",
                "description": "Receive a coupon for savings on any Nexium 24hr product with registration; 1 coupon per purchase.",
                "brand": "Nexium 24HR Clear Minis",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b29e"
            },
            {
                "_id": "6297bcace0e0db6c2572b2a3",
                "link": "https://www.nextstellis.com/patient#savings-card",
                "description": "Nextstellis Mayne Pharma Patient Savings Card: Eligible commercially insured patients may pay as little as $25 for each one-month prescription; offer is valid for 12 fills; for additional information contact the program at 347-442-7919.",
                "brand": "Nextstellis",
                "copay": "No",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2a3"
            },
            {
                "_id": "6297bcace0e0db6c2572b2a8",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Nicaprin Artesa Labs Advantage Program: Eligible commercially insured patients may pay as little as $0 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Nicaprin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2a8"
            },
            {
                "_id": "6297bcace0e0db6c2572b2ad",
                "link": "https://www.nicomide.com/savings",
                "description": "Nicomide eVoucherRx Program: Eligible patients may pay no more than $25 per prescription; for additional information contact the program at 800-364-4767.",
                "brand": "Nicomide",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2ad"
            },
            {
                "_id": "6297bcace0e0db6c2572b2b2",
                "link": "https://www.pfizermedicalinformation.com/en-us/nicotrol-inhaler",
                "description": "Nicotrol Samples: Your healthcare provider may request samples by logging into the PfizerPro website or calling 800-505-4426.",
                "brand": "Nicotrol",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2b2"
            },
            {
                "_id": "6297bcace0e0db6c2572b2b7",
                "link": "https://www.nitromist.com/",
                "description": "NitroMist Savings Card: Eligible patients may pay $0 per prescription on each of up to 12 fills; savings of $100 per prescription; for additional information contact the program at 800-433-4893.",
                "brand": "NitroMist",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2b7"
            },
            {
                "_id": "6297bcace0e0db6c2572b2bc",
                "link": "https://www.nocdurna.com/savings-and-support/#co-pay",
                "description": "Nocdurna Co-Pay Card: Eligible commercially insured patients may pay as little as $0 per 30-day prescription with a maximum savings of $125 per fill; for additional information contact the program at 833-608-2647.",
                "brand": "Nocdurna",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "1500",
                "lifetime_uses": "12",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2bc"
            },
            {
                "_id": "6297bcace0e0db6c2572b2c1",
                "link": "https://www.norditropin.com/how-we-can-help/product-support.html",
                "description": "Norditropin NordiSure Co-Pay Assistance Program: Eligible commercially insured patients may pay as little as $0 per fill with an annual maximum benefit limit of $3000; for additional information contact then program at 888-668-6444.",
                "brand": "Norditropin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2c1"
            },
            {
                "_id": "6297bcace0e0db6c2572b2c6",
                "link": "https://www.orthorxaccess.com/",
                "description": "Noritate Rx Access Program: Eligible commercially insured patients pay no more than $25 per prescription; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Noritate",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2c6"
            },
            {
                "_id": "6297bcace0e0db6c2572b2cb",
                "link": "https://www.norvir.com/savings",
                "description": "Norvir Savings Card: Eligible commercially insured patients may pay as little as $0 per month with savings of up to $100 per 30-day supply; for additional information contact the program at 800-364-4767.",
                "brand": "Norvir",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2cb"
            },
            {
                "_id": "6297bcace0e0db6c2572b2d0",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Novarel Heart for Heroes Program: Eligible veterans may receive fertility medications at no cost with program; please see website for qualification requirements; for additional information contact the program at 800-515-3784.",
                "brand": "Novarel",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2d0"
            },
            {
                "_id": "6297bcace0e0db6c2572b2d5",
                "link": "https://www.novocare.com/insulin/my99insulin.html?src=100001849",
                "description": "Novolin 70/30 vial My$99Insulin Card: Eligible patients pay $99 per monthly prescription (up to 3 vials or 2 packs of pens) of Novo Nordisk insulin products; offer may be used once a month during a calendar year; for additional information contact the program at 888-910-0632.",
                "brand": "Novolin 70-30",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2d5"
            },
            {
                "_id": "6297bcace0e0db6c2572b2da",
                "link": "https://www.novocare.com/novolog/savings-card.html",
                "description": "NovoLog Novo Nordisk Instant Savings Card: Eligible commercially insured patients may pay no more than $25 per 30-day prescription with savings of up to $100 per fill; offer valid 24 times after card activation; for additional information contact the program at 877-304-6855.",
                "brand": "NovoLog",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2da"
            },
            {
                "_id": "6297bcace0e0db6c2572b2df",
                "link": "https://www.novoneedles.com/needle-offers.html",
                "description": "Novo Nordisk Diabetes Savings Card: Eligible commercially insured patients receive up to $60 off one box of Novo Nordisk needles by activating the savings card; for additional information contact the program at 877-304-6855.",
                "brand": "NovoTwist 32G",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 60,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2df"
            },
            {
                "_id": "6297bcace0e0db6c2572b2e4",
                "link": "https://www.nubeqa-us.com/support",
                "description": "Nubeqa Free Trial: Eligible patients NEW to Nubeqa may be eligible to receive a 1-month free trial; for additional information contact the program at 800-288-8374.",
                "brand": "Nubeqa",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2e4"
            },
            {
                "_id": "6297bcace0e0db6c2572b2e9",
                "link": "https://www.nuedextahcp.com/treatment-access",
                "description": "Nuedexta Samples: Healthcare providers may request a FREE 10-day sample for their patients by filling out a form online or faxing the form to 866-329-7771.",
                "brand": "Nuedexta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2e9"
            },
            {
                "_id": "6297bcace0e0db6c2572b2ee",
                "link": "https://www.nurtec.com/savings",
                "description": "Nurtec ODT Savings Program: Eligible commercially insured patients may pay $0 per fill; for additional information contact the program at 800-761-1568.",
                "brand": "Nurtec ODT",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "EL40401017",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2ee"
            },
            {
                "_id": "6297bcace0e0db6c2572b2f3",
                "link": "https://epihealth.com/rebate/",
                "description": "Nuvail Patient Access Card: Eligible commercially insured patients may pay as little as $0 per fill; for additional information contact the program at 855-631-2485.",
                "brand": "Nuvail",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2f3"
            },
            {
                "_id": "6297bcace0e0db6c2572b2f8",
                "link": "https://www.nuwiqusa.com/patient-support/co-pay-assistance-reimbursement/",
                "description": "Nuwiq Factor My Way Co-Pay Assistance Program: Eligible commercially insured patients may save up to $12,000 per year on out-of-pocket costs associated with the patients' therapy; for additional information contact the program at 866-830-6541.",
                "brand": "Nuwiq",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2f8"
            },
            {
                "_id": "6297bcace0e0db6c2572b2fd",
                "link": "https://obcomplete.com/healthcare-professionals/",
                "description": "OB Complete Petite Samples: Healthcare providers may request samples by completing the online form.",
                "brand": "OB Complete Petite",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2fd"
            },
            {
                "_id": "6297bcace0e0db6c2572b302",
                "link": "https://www.ocuvite.com/buy-now",
                "description": "Receive a $3 coupon off any one Ocuvite product; email address required; 1 coupon per purchase.",
                "brand": "Ocuvite Adult 50 Eye Vitamins",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b302"
            },
            {
                "_id": "6297bcace0e0db6c2572b307",
                "link": "https://www.ocuvite.com/buy-now",
                "description": "Receive a $3 coupon off any one Ocuvite product; email address required; 1 coupon per purchase.",
                "brand": "Ocuvite Eye+Multi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b307"
            },
            {
                "_id": "6297bcace0e0db6c2572b30c",
                "link": "https://www.alksavings.com/cb/access/crp/aLkYd2j78Vbb/fid.jsp?q=51G5OI5442CUDK4PLWTLX5&f=IMPX6IG-274EMWRGK6802#/app/layout/home",
                "description": "Odactra ALK Savings Card Program: Eligible commercially insured patients may pay as little as $50 per 30-day prescription if using a retail pharmacy; valid for 12 uses or $3,200 per year (whichever comes first); for additional information contact the program at 800-325-7354.",
                "brand": "Odactra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3200",
                "lifetime_uses": "12",
                "max_saving_transaction": 265,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b30c"
            },
            {
                "_id": "6297bcace0e0db6c2572b311",
                "link": "https://www.ofev.com/financial-assistance/prescription-savings#TheOfevCommercialCopayProgram",
                "description": "Ofev Commercial Copay Program: Eligible commercially insured patients may pay as little as $0 per prescription with savings of up to $11,500 per month; subject to a maximum of 16 benefits per enrollment year or $60,000 per enrollment year whichever comes first; for additional information contact the program at 866-673-6366.",
                "brand": "Ofev",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "60000",
                "lifetime_uses": "16",
                "max_saving_transaction": 11500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b311"
            },
            {
                "_id": "6297bcace0e0db6c2572b316",
                "link": "http://www.omidriahcp.comreimbursement/omidriassure/",
                "description": "Omidria We Pay The Difference Patient Reimbursement Program: Eligible commercially insured patients with insufficient coverage may receive  reimbursement support services; for additional information contact the program at 877-664-3742.",
                "brand": "Omidria",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b316"
            },
            {
                "_id": "6297bcace0e0db6c2572b31b",
                "link": "https://www.oneaday.com/coupons",
                "description": "Save $1 on any one One-A-Day product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "One-A-Day Adult Multivitamin products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b31b"
            },
            {
                "_id": "6297bcace0e0db6c2572b320",
                "link": "https://www.oneaday.com/coupons",
                "description": "Save $1 on any one One-A-Day product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "One-A-Day Teen Multivitamin products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b320"
            },
            {
                "_id": "6297bcace0e0db6c2572b325",
                "link": "https://www.onetouch.com/offers",
                "description": "OneTouch Verio Flex FREE Offer: Can only be redeemed where OneTouch products are sold and prescriptions can be processed; requires a valid prescription; offer valid for one meter per patient every 12 months; for additional information contact the program at 800-227-8862.",
                "brand": "OneTouch Verio Flex",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b325"
            },
            {
                "_id": "6297bcace0e0db6c2572b32a",
                "link": "https://www.orthorxaccess.com/",
                "description": "Onexton Rx Access Program: Eligible commercially insured patients may pay $25 per prescription; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Onexton",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b32a"
            },
            {
                "_id": "6297bcace0e0db6c2572b32f",
                "link": "https://portal.trialcard.com/ongentyssavingsprogram/",
                "description": "Ongentys Savings Program: Eligible commercially insured patients may pay as little as $0 per prescription; for more information contact the program at 866-378-3159.",
                "brand": "Ongentys",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b32f"
            },
            {
                "_id": "6297bcace0e0db6c2572b334",
                "link": "https://www.ipsencares.com/onivyde-patient-support/",
                "description": "Onivyde Ipsen Cares Copay Assistance Program: Eligible cash-pay patients may pay save $1666.66 per prescription with a maximum savings of $20,000 per calendar year; program resets every January 1st; for additional information contact the program at 866-435-5677.",
                "brand": "Onivyde",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 1666.66,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b334"
            },
            {
                "_id": "6297bcace0e0db6c2572b339",
                "link": "https://pah.janssencarepath.com/patient/opsumit/patient-support.html",
                "description": "Opsumit Janssen CarePath Oral PAH Savings Program: Eligible commercially insured patients may pay $5 per prescription fill; maximum savings of $20,000 per calendar year; for additional information contact the program at 866-228-3546.",
                "brand": "Opsumit",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b339"
            },
            {
                "_id": "6297bcace0e0db6c2572b33e",
                "link": "https://www.eyefile.com/theeyesolution/Register",
                "description": "Save $2 on your next purchase of any one Opti-Free Replenish Contact Lens Solution (10 oz or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Opti-Free Replenish Contact Lens Solution",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b33e"
            },
            {
                "_id": "6297bcace0e0db6c2572b343",
                "link": "https://www.galdermacc.com/patients",
                "description": "Oracea CareConnect Patient Savings Card: Eligible commercially insured patients may pay no more than $20 per prescription; for additional information contact the program at 855-280-0543.",
                "brand": "Oracea",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b343"
            },
            {
                "_id": "6297bcace0e0db6c2572b348",
                "link": "https://www.orencia.com/support-savings/on-call",
                "description": "Orencia Co-pay Assistance Card (for IV Infusion Patients): Eligible commercially insured patients may pay as little as $5 per month with savings of up to $15,000 for up to 13 uses per calendar year; patient or provider must submit paperwork to the program to enroll; eligible patients must re-enroll each year; for additional information contact the program at 800-673-6242.",
                "brand": "Orencia",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "13",
                "max_saving_transaction": 1153.85,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b348"
            },
            {
                "_id": "6297bcace0e0db6c2572b34d",
                "link": "https://www.orgovyx.com/support-and-resources/",
                "description": "Orgovyx Copay Assistance Program: Eligible commercially insured patients may pay as little as $10 per month; for additional information contact the program at 833-674-6899.",
                "brand": "Orgovyx",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b34d"
            },
            {
                "_id": "6297bcace0e0db6c2572b352",
                "link": "https://www.orilissa.com/insurance-and-savings/co-pay-card",
                "description": "Orilissa Savings Card: Eligible commercially insured patients may pay as little as $5 per monthly prescription; for additional information contact the program at 800-674-5477.",
                "brand": "Orilissa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b352"
            },
            {
                "_id": "6297bcace0e0db6c2572b357",
                "link": "https://www.ortikos.com",
                "description": "Ortikos Co-Pay Savings: Eligible commercially insured patients may pay as little as $10 per fill with a maximum savings of $100 per prescription fill; for additional information contact the program at 833-277-7538.",
                "brand": "Ortikos",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b357"
            },
            {
                "_id": "6297bcace0e0db6c2572b35c",
                "link": "https://www.osphena.com/savings",
                "description": "Osphena At Home Delivery Program: Eligible cash-paying patients, Medicare patients who elect to pay cash and commercially insured patients/RX not covered pay only $75 for 30 tablets or $190 for 90 tablets; for additional information contact the program at 844-716-4663.",
                "brand": "Osphena",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b35c"
            },
            {
                "_id": "6297bcace0e0db6c2572b361",
                "link": "https://www.osteobiflex.com/ambassadors-club/",
                "description": "Osteo Bi-Flex Products: Join the Ambassador�s Club to receive coupons based on your past purchases.",
                "brand": "Osteo Bi-Flex Pain Relieving Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b361"
            },
            {
                "_id": "6297bcace0e0db6c2572b366",
                "link": "https://www.otezla.com/supportplus/otezla-cost-and-copay",
                "description": "Otezla Co-Pay Program: Eligible commercially insured patients pay as little as $0 per monthly prescription; for additional information contact the program at 844-468-3952.",
                "brand": "Otezla",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b366"
            },
            {
                "_id": "6297bcace0e0db6c2572b36b",
                "link": "https://www.otrexup.com/starting-otrexup/activating-your-savings-card",
                "description": "Otrexup Total Care Co-pay Assistance: Eligible commercially insured patients may pay $0 co-pay on each of up to 13 prescriptions per calendar year with savings of up to $250 per fill; for additional information contact the program at 800-422-5604.",
                "brand": "Otrexup",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "CN",
                "copay_max": "3250",
                "lifetime_uses": "13",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b36b"
            },
            {
                "_id": "6297bcace0e0db6c2572b370",
                "link": "https://www.oxistat.com/hcp/",
                "description": "Oxistate Samples: Healthcare providers may fax a sample request form to 844-896-5307.",
                "brand": "Oxistat",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b370"
            },
            {
                "_id": "6297bcace0e0db6c2572b375",
                "link": "https://www.novomedlink.com/diabetes/samples.html",
                "description": "Ozempic Samples: Healthcare providers may submit a sample request every 30 days.",
                "brand": "Ozempic 0.25 mg",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b375"
            },
            {
                "_id": "6297bcace0e0db6c2572b37a",
                "link": "https://www.palforzia.com/support/",
                "description": "Palforzia Pathway Co-Pay Savings Program Rebate: Eligible commercially insured patients who were unable to use the Savings Card at the pharmacy may be able to submit a rebate request for the amount they paid over $20; for more information contact the program at 844-725-3679.",
                "brand": "Palforzia",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "4000",
                "lifetime_uses": "12",
                "max_saving_transaction": 333.33,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b37a"
            },
            {
                "_id": "6297bcace0e0db6c2572b37f",
                "link": "https://www.anipharmaceuticals.com/products-detail.php?group=Pandel�+%28hydrocortisone+probutate%29+Cream&",
                "description": "Pandel Samples: Healthcare providers may fax a sample request form to 844-896-5307.",
                "brand": "Pandel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b37f"
            },
            {
                "_id": "6297bcace0e0db6c2572b384",
                "link": "https://www.panoxyl.com/coupons/",
                "description": "Save $2 on any one PanOxyl Acne Wash product; 1 coupon per purchase, coupon expires 1 month after printing.",
                "brand": "PanOxyl Acne Foaming Wash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b384"
            },
            {
                "_id": "6297bcace0e0db6c2572b389",
                "link": "https://www.eyefile.com/TheEyeSolution/Register/0?sid=CLRCR0513",
                "description": "Save $5 on your next purchase of Pataday products with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Pataday Extra Strength Once Daily Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b389"
            },
            {
                "_id": "6297bcace0e0db6c2572b38e",
                "link": "https://pediasure.com/resources-and-rewards/pediasure-coupons",
                "description": "Sign up for the PediaSure Support2Grow program and save up to $100 with receive special offers.",
                "brand": "PediaSure Enteral 1.5 Cal",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b38e"
            },
            {
                "_id": "6297bcace0e0db6c2572b393",
                "link": "https://pediasure.com/resources-and-rewards/pediasure-coupons",
                "description": "Sign up for the PediaSure Support2Grow program and save up to $100 with receive special offers.",
                "brand": "PediaSure Grow and Gain with Fiber",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b393"
            },
            {
                "_id": "6297bcace0e0db6c2572b398",
                "link": "https://pediasure.com/resources-and-rewards/pediasure-coupons",
                "description": "Sign up for the PediaSure Support2Grow program and save up to $100 with receive special offers.",
                "brand": "PediaSure SideKicks",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b398"
            },
            {
                "_id": "6297bcace0e0db6c2572b39d",
                "link": "https://www.oncopeptides-us.com/en/media-center/important-information-regarding-pepaxto-in-the-united-states",
                "description": "Pepaxto Copay Assistance: Eligible commercially insured patients currently taking Pepaxto and have a Copay Card should contact their doctor who can contact the manufacturer so patients can continue receiving their medication at a savings; for additional information contact the program directly at 844-300-6626.",
                "brand": "Pepaxto",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 8000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b39d"
            },
            {
                "_id": "6297bcace0e0db6c2572b3a2",
                "link": "https://www.activatethecard.com/perforomist/?_ga=2.2745587.276121865.1579134457-1618483530.1579134457",
                "description": "Perforomist Savings Card: Eligible commercially insured patients may pay as little as $0 per 30-day prescription with savings of up to $550 per fill; maximum savings per calendar year is $6600; offer may be used 12 times per calendar year; for additional information contact the program at 800-657-7613.",
                "brand": "Perforomist",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "6600",
                "lifetime_uses": "12",
                "max_saving_transaction": 550,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3a2"
            },
            {
                "_id": "6297bcace0e0db6c2572b3a7",
                "link": "https://pertzyecares.com/chiesi-caredirect/",
                "description": "Pertzye $0 Copay Assistance Program: Eligible commercially insured patients pay $0 on out-of-pocket cost for their prescriptions with a monthly maximum savings of $1440 per fill; patient must use a network pharmacy to fill their prescription; for additional information contact the program at 855-883-1461.",
                "brand": "Pertzye",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1440",
                "lifetime_uses": "12",
                "max_saving_transaction": 120,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3a7"
            },
            {
                "_id": "6297bcace0e0db6c2572b3ac",
                "link": "https://phexxi.copaysavingsprogram.com",
                "description": "Phexxi Co-pay Card: Eligible commercially insured patients will pay $0 copay on their first fill and as little as $30 on each subsequent fill; for more information contact the program at 855-358-6583.",
                "brand": "Phexxi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3ac"
            },
            {
                "_id": "6297bcace0e0db6c2572b3b1",
                "link": "https://www.phillipsdigestive.com/coupon/",
                "description": "Register your email address to receive a coupon for any one Phillips' product; 1 coupon per purchase.",
                "brand": "Phillips Pro-Regularity",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3b1"
            },
            {
                "_id": "6297bcace0e0db6c2572b3b6",
                "link": "https://www.planbonestep.com/how-to-save-on-plan-b/",
                "description": "Save $10 with the Plan B One-Step manufacturer's coupon; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Plan B One-Step",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 10,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3b6"
            },
            {
                "_id": "6297bcace0e0db6c2572b3bb",
                "link": "https://plenvu.copaysavingsprogram.com",
                "description": "Plenvu Co-pay Assistance Card: Eligible commercially insured patients/RX not covered pay as little as $50 per fill; for additional information contact the program at 855-202-3208.",
                "brand": "Plenvu",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3bb"
            },
            {
                "_id": "6297bcace0e0db6c2572b3c0",
                "link": "https://www.poise.com/en-us/samples-and-offers/free-samples",
                "description": "Register your email address to receive a FREE sample kit from Poise.",
                "brand": "Poise Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3c0"
            },
            {
                "_id": "6297bcace0e0db6c2572b3c5",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/overview/?utm_source=google&utm_medium=cpc&utm_term=super%20poligrip&utm_campaign=GS%20-%20Branded_Poligrip%20EX_DP&gclid=Cj0KCQiA962BBhCzARIsAIpWEL0O5zEg5LMkWP-xbEDatBaEd896PMS8BoY0ynLITYi-fwgKKWPSrcUaAiBqEALw_wcB&gclsrc=aw.ds",
                "description": "Polident Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Polident Dentu-Creme Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3c5"
            },
            {
                "_id": "6297bcace0e0db6c2572b3ca",
                "link": "https://www.polident.com/en-us/save-now/proguard-coupon/",
                "description": "Save $1 on your purchase of any Polident ProGuard & Retainer Cleanser product by registering your email address; one coupon per purchase.",
                "brand": "Polident Pro Guard and Retainer Foam Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3ca"
            },
            {
                "_id": "6297bcace0e0db6c2572b3cf",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/products/ortho-cleanser/",
                "description": "Polident ProGuard Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Polident Pro Guard and Retainer Starter Kit",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3cf"
            },
            {
                "_id": "6297bcace0e0db6c2572b3d4",
                "link": "https://www.polident.com/en-us/save-now/propartial-options/",
                "description": "Save $3.50 on your purchase when you buy all 3 Polident ProPartial products in a single transaction; must register your email address on the website; one coupon per purchase.",
                "brand": "Polident ProPartials Mouthwash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3.5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3d4"
            },
            {
                "_id": "6297bcace0e0db6c2572b3d9",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/overview/?utm_source=google&utm_medium=cpc&utm_term=super%20poligrip&utm_campaign=GS%20-%20Branded_Poligrip%20EX_DP&gclid=Cj0KCQiA962BBhCzARIsAIpWEL0O5zEg5LMkWP-xbEDatBaEd896PMS8BoY0ynLITYi-fwgKKWPSrcUaAiBqEALw_wcB&gclsrc=aw.ds",
                "description": "Polident Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Polident Smokers",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3d9"
            },
            {
                "_id": "6297bcace0e0db6c2572b3de",
                "link": "https://www.ponvory.com/cost-and-savings#private-commercial-insurance",
                "description": "Ponvory Janssen CarePath Savings Program: Eligible commercially insured patients may pay $0 per fill up to a maximum of $18,000 per calendar year; for more information contact the program at 877-227-3728.",
                "brand": "Ponvory",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "18000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3de"
            },
            {
                "_id": "6297bcace0e0db6c2572b3e3",
                "link": "https://www.pradaxa.com/pradaxa-savings",
                "description": "Pradaxa Savings Card: Government-Funded Insurance/Cash-Paying Patients - Sign up and receive one free 30-day supply; for further assistance contact 877-481-5332.",
                "brand": "Pradaxa",
                "copay": "No",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3e3"
            },
            {
                "_id": "6297bcace0e0db6c2572b3e8",
                "link": "https://sebelapharma.com/savings-programs/",
                "description": "Pramosone Co-Pay Savings Card Rebate: If the pharmacy is unable to process the Savings Card commercially insured patients may submit a rebate request in order to reimbursed their out-of-pocket cost over $10; rebate instructions are found at the bottom of the Savings Card; for additional information contact the program at 844-728-3479.",
                "brand": "Pramosone",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3e8"
            },
            {
                "_id": "6297bcace0e0db6c2572b3ed",
                "link": "https://premarin.pfizerpro.com/samples",
                "description": "Premarin (Tablets) Samples: Your healthcare provider may be able to order samples for their practice.",
                "brand": "Premarin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3ed"
            },
            {
                "_id": "6297bcace0e0db6c2572b3f2",
                "link": "https://savings.vitamedmdrx.com/",
                "description": "vitaMedMD Prena1 Pearl Savings Program: Eligible commercially insured patients may pay $25 or less per 30-day prescription; for additional information contact the program at 855-993-2665.",
                "brand": "Prena1 Pearl",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3f2"
            },
            {
                "_id": "6297bcace0e0db6c2572b3f7",
                "link": "https://avionrx.com/healthcare-professionals/",
                "description": "Prenate DHA Samples: Healthcare providers may order samples for their practice by filling out a form online or calling 888-612-8466.",
                "brand": "Prenate DHA",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3f7"
            },
            {
                "_id": "6297bcace0e0db6c2572b3fc",
                "link": "https://avionrx.com/healthcare-professionals/",
                "description": "Prenate Enhance Samples: Healthcare providers may order samples for their practice by filling out a form online or calling 888-612-8466.",
                "brand": "Prenate Enhance",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3fc"
            },
            {
                "_id": "6297bcace0e0db6c2572b401",
                "link": "https://prenate.com/patient-savings/",
                "description": "Prenate Pixie eVoucherRx Program: Eligible patients pay no more than $20 per prescription fill at participating pharmacies; for additional information contact the program at 888-612-8466.",
                "brand": "Prenate Pixie",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b401"
            },
            {
                "_id": "6297bcace0e0db6c2572b406",
                "link": "https://www.preparationh.com/coupons-and-special-offers/",
                "description": "Register your email address to receive a coupon for any one Preparation H product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Preparation H Medicated Wipes",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b406"
            },
            {
                "_id": "6297bcace0e0db6c2572b40b",
                "link": "https://www.preparationh.com/coupons-and-special-offers/",
                "description": "Register your email address to receive a coupon for any one Preparation H product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Preparation H Rapid Relief with Lidocaine Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b40b"
            },
            {
                "_id": "6297bcace0e0db6c2572b410",
                "link": "https://www.preparationh.com/coupons-and-special-offers/",
                "description": "Register your email address to receive a coupon for any one Preparation H product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Preparation H Totables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b410"
            },
            {
                "_id": "6297bcace0e0db6c2572b415",
                "link": "https://www.sightmatters.com/join?utm_campaign=preservision-new&utm_content=cta&utm_medium=preservision-new_landing&utm_source=preservision-new_redirect",
                "description": "PreserVision SightMatters: Sign-up and receive coupons for Preservision AREDS 2�formula vitamins products valued up to�$60 per year.",
                "brand": "PreserVision Eye Vitamin AREDS 2 Formula Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b415"
            },
            {
                "_id": "6297bcace0e0db6c2572b41a",
                "link": "https://www.preservision.com/Get-Coupons/Get-My-Coupon",
                "description": "$4 coupon off any PreserVision Eye Vitamin and Mineral Supplement 60 count or larger; 1 coupon per purchase.",
                "brand": "PreserVision Eye Vitamin AREDS Formula Soft Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b41a"
            },
            {
                "_id": "6297bcace0e0db6c2572b41f",
                "link": "https://www.preservision.com/eyecare-professionals/samples-coupons",
                "description": "PreserVision Samples: Eyecare professionals may request samples of PreserVision Eye Vitamins for their practice by calling 844-258-4837.",
                "brand": "PreserVision Eye Vitamin AREDS Lutien Formula Soft Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b41f"
            },
            {
                "_id": "6297bcace0e0db6c2572b424",
                "link": "https://www.janssencarepath.com/patient/prezcobix/cost-support",
                "description": "Prezcobix Janssen CarePath Savings Program Rebate: Eligible commercially insured patients may submit a rebate request if the pharmacy does not accept the Savings Card; for additional assistance contact the program at 866-836-0114.",
                "brand": "Prezcobix",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "7500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b424"
            },
            {
                "_id": "6297bcace0e0db6c2572b429",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings on Prilosec OTC products with registration.",
                "brand": "Prilosec OTC",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b429"
            },
            {
                "_id": "6297bcace0e0db6c2572b42e",
                "link": "https://www.primlev.com/",
                "description": "Primlev Savings Coupon: Eligible patients may pay $0 per prescription for co-pays of up to $400 on each of up to 12 fills; for additional information contact the program at 844-205-3612.",
                "brand": "Primlev",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 400,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b42e"
            },
            {
                "_id": "6297bcace0e0db6c2572b433",
                "link": "https://independencepharma.com/patient-coupon/",
                "description": "ProCentra Patient Coupon: Eligible patients may pay no more than $10 per prescription with a maximum savings of $100 per prescription; for additional information contact the program at 844-728-3479.",
                "brand": "ProCentra",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b433"
            },
            {
                "_id": "6297bcace0e0db6c2572b438",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Prolensa Bausch + Lomb Access Program: Eligible commercially insured patients may pay no more than $25 for each prescription; for additional information contact the program 866-693-4880.",
                "brand": "Prolensa",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b438"
            },
            {
                "_id": "6297bcace0e0db6c2572b43d",
                "link": "https://copay.novartisoncology.com/",
                "description": "Promacta Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Promacta",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b43d"
            },
            {
                "_id": "6297bcace0e0db6c2572b442",
                "link": "https://www.pfizermedicalinformation.com/en-us/provera",
                "description": "Provera Samples: Your healthcare provider may request samples by contacting PfizerPro online or by calling 800-505-4426.",
                "brand": "Provera",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b442"
            },
            {
                "_id": "6297bcace0e0db6c2572b447",
                "link": "https://www.alva-amco.com/contact",
                "description": "Consumers may fill out a request form for Psoriasin product coupons.",
                "brand": "Psoriasin Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b447"
            },
            {
                "_id": "6297bcace0e0db6c2572b44c",
                "link": "https://www.pulmozymesupport.com/",
                "description": "Pulmozyme Co-Pay Card Program: Eligible commercially insured patients pay as little as $30 per 30-day supply with savings of up to $10,000 per year; for additional information contact the program at 877-794-8723.",
                "brand": "Pulmozyme",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "12",
                "max_saving_transaction": 833.33,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b44c"
            },
            {
                "_id": "6297bcace0e0db6c2572b451",
                "link": "https://www.qbrexza.com/savingscard",
                "description": "Qbrexza Copay Card: Eligible commercially insured patients may pay as little as $0 per prescription; for additional information contact the program at 888-786-5876.",
                "brand": "Qbrexza",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b451"
            },
            {
                "_id": "6297bcace0e0db6c2572b456",
                "link": "https://www.qnasl.com/Savings",
                "description": "QNASL Savings Program: Eligible commercially insured/Rx not covered patients may pay $75 per device for their prescription; for additional information contact the program at 844-492-9703.",
                "brand": "QNASL",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b456"
            },
            {
                "_id": "6297bcace0e0db6c2572b45b",
                "link": "https://www.qtern.com/savings-coupon.html",
                "description": "Qtern Savings Card: Eligible commercially insured patients may pay $0 per 30-day supply; maximum savings of $175 per 30-day supply; for additional information contact the program at 844-846-2750.",
                "brand": "Qtern",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "2100",
                "lifetime_uses": "12",
                "max_saving_transaction": 175,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b45b"
            },
            {
                "_id": "6297bcace0e0db6c2572b460",
                "link": "https://www.trisadhd.com/adhd-resources/",
                "description": "QuilliChew ER Co-Pay Card: Eligible commercially insured patients may pay as little as $20 on each of up to 12 prescription fills; for additional information contact the program at 888-840-7006.",
                "brand": "Quillichew ER",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b460"
            },
            {
                "_id": "6297bcace0e0db6c2572b465",
                "link": "https://www.qulipta.com/savings-support/qulipta-complete-savings-program",
                "description": "Qulipta Complete Prescription Program: Eligible commercially insured patients who are experiencing issues with their insurance may receive medication at no charge for up to 2 years or until they receive insurance coverage approval whichever occurs earlier; for additional information contact the program at 855-785-4782.",
                "brand": "Qulipta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b465"
            },
            {
                "_id": "6297bcace0e0db6c2572b46a",
                "link": "https://www.radicava.com/patient/support/searchlight-support/#cost-support-options",
                "description": "Radicava Out-of-Pocket Assistance Program: Eligible commercially insured patients may pay $0 per infusion with maximum benefit of $20,000 per calendar year; for additional information contact the program at 844-772-4548.",
                "brand": "Radicava",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b46a"
            },
            {
                "_id": "6297bcace0e0db6c2572b46f",
                "link": "https://www.pfizermedicalinformation.com/en-us/rapamune",
                "description": "Rapamune Samples: Your healthcare provider may request samples by contacting Pfizer Pro online or calling 800-505-4426.",
                "brand": "Rapamune",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b46f"
            },
            {
                "_id": "6297bcace0e0db6c2572b474",
                "link": "https://www.bmsaccesssupport.bmscustomerconnect.com/patient/financial-support",
                "description": "Reblozyl BMS Oncology Co-pay Assistance Program: Eligible commercially insured patients' co-pay may be reduced to $0 per dose with savings of up to $10,000 per calendar year; for additional information contact the program at 800-861-0048.",
                "brand": "Reblozyl",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b474"
            },
            {
                "_id": "6297bcace0e0db6c2572b479",
                "link": "https://www.recticare.com/doctors/",
                "description": "RectiCare Product Samples: Healthcare providers may order samples for their practice by filling out the online form.",
                "brand": "RectiCare Anorectal Cream Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b479"
            },
            {
                "_id": "6297bcace0e0db6c2572b47e",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Classic",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b47e"
            },
            {
                "_id": "6297bcace0e0db6c2572b483",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Optive Advanced",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b483"
            },
            {
                "_id": "6297bcace0e0db6c2572b488",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Optive Preservative Free",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b488"
            },
            {
                "_id": "6297bcace0e0db6c2572b48d",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Relieva for Contacts",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b48d"
            },
            {
                "_id": "6297bcace0e0db6c2572b492",
                "link": "https://ec4ea071-fbcb-424f-b1c2-cf62cecd02ee.filesusr.com/ugd/72e81f_ff8712ca24fb46138474f91660fcfdec.pdf",
                "description": "ReliOn Novolin 70/30 Savings: Eligible patients may save from 58% to 75% off the cash price per�prescription; offer available only at Walmart; for additional information contact your nearest Walmart Pharmacy.",
                "brand": "ReliOn Novolin 70/30 Insulin Vial",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b492"
            },
            {
                "_id": "6297bcace0e0db6c2572b497",
                "link": "https://corporate.walmart.com/newsroom/2021/06/29/walmart-revolutionizes-insulin-access-affordability-for-patients-with-diabetes-with-the-launch-of-the-first-and-only-private-brand-analog-insulin",
                "description": "ReliOn Novolog Savings: Eligible patients may save from 58% to 75% off the cash price per�prescription; offer available only at Walmart; for additional information contact your nearest Walmart Pharmacy.",
                "brand": "ReliOn Novolog",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b497"
            },
            {
                "_id": "6297bcace0e0db6c2572b49c",
                "link": "https://www.relpax.com/savings-terms",
                "description": "Relpax Savings Card Rebate: If the pharmacy does not accept the Savings card eligible commercially insured patients may submit a request for a rebate in connection will the savings found in this offer; for additional information contact the program at 800-926-5334.",
                "brand": "Relpax",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b49c"
            },
            {
                "_id": "6297bcace0e0db6c2572b4a1",
                "link": "https://www.organonaccessprogram-renflexis.com/hcc/infusion-copay-cost-assistance/",
                "description": "Renflexis Organon Co-Pay Assistance Program: Eligible commercially insured patients pay $5 per prescription with savings of up to $20,000 per calendar year; for additional information contact the program at 866-847-3539.",
                "brand": "Renflexis",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4a1"
            },
            {
                "_id": "6297bcace0e0db6c2572b4a6",
                "link": "https://www.womenshealthcaresolutions.com/patient-material-request",
                "description": "RepHresh Free Samples: Your healthcare provider may request FREE Feminine Health Kit with product samples, patient coupons and valuable patient materials.",
                "brand": "RepHresh",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4a6"
            },
            {
                "_id": "6297bcace0e0db6c2572b4ab",
                "link": "https://www.restasis.com/termsofuse/termsandconditions5",
                "description": "Restasis Multidose My Tears, My Rewards Program for Mail-Order/Manual Claims (Mail-Order): If your pharmacy does not accept the savings card you may complete and submit the mail-order form to receive your proper savings; for additional information contact the program at 844-469-8327.",
                "brand": "Restasis Multidose",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4ab"
            },
            {
                "_id": "6297bcace0e0db6c2572b4b0",
                "link": "https://uspco.com/product_literature.aspx?pid=390&tab=Contact",
                "description": "Restora RX Samples: Healthcare providers may request free samples for their practice by filling out a form online.",
                "brand": "Restora RX",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4b0"
            },
            {
                "_id": "6297bcace0e0db6c2572b4b5",
                "link": "https://www.aspirerewards.com/register",
                "description": "Restylane Defyne ASPIRE Galderma Rewards: Join today and receive a $60 treatment certificate instantly; other valuable discounts and rebates with sign up; for additional information contact customer service at 844-527-7473.",
                "brand": "Restylane Defyne",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4b5"
            },
            {
                "_id": "6297bcace0e0db6c2572b4ba",
                "link": "https://www.retevmo.com/savings-support",
                "description": "Retevmo Savings Card: Eligible commercially patients may pay as little as $0 for a 30-day supply; contact the program for additional information at 866-472-8663.",
                "brand": "Retevmo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.33,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4ba"
            },
            {
                "_id": "6297bcace0e0db6c2572b4bf",
                "link": "https://myviivcard.com/rebate-form-instructions.html",
                "description": "Retrovir ViiVConnect Rebate: Eligible commercially insured patients may be eligible for a rebate for the amount paid out of pocket per prescription if the pharmacy does not accept the savings card; rebate may be completed online or by downloading and mailing in rebate form; for additional information contact the program at 844-588-3288.",
                "brand": "Retrovir",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4bf"
            },
            {
                "_id": "6297bcace0e0db6c2572b4c4",
                "link": "https://www.rexulti.com/savings",
                "description": "Rexulti Savings Card: Eligible commercially insured patients pay $0 copay for the first 2 months then pay as little as $15 per refill; maxium savings per fill is $600; offer valid for 12 prescriptions; for additional information contact the program at 844-415-0674.",
                "brand": "Rexulti",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "5800",
                "lifetime_uses": "12",
                "max_saving_transaction": 600,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4c4"
            },
            {
                "_id": "6297bcace0e0db6c2572b4c9",
                "link": "https://epihealth.com/rebate/",
                "description": "Rhofade Patient Access Card: Eligible commercially insured patients may pay as little as $0 per fill; for additional information contact the program at 855-631-2485.",
                "brand": "Rhofade",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4c9"
            },
            {
                "_id": "6297bcace0e0db6c2572b4ce",
                "link": "https://ridlice.com/coupons/",
                "description": "Save $2 off any one RID product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "RID Home Lice Bedbug and Dust Mite Spray Kit",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4ce"
            },
            {
                "_id": "6297bcace0e0db6c2572b4d3",
                "link": "https://www.rinvoq.com/resources/save-on-rinvoq-costs",
                "description": "Rinvoq Complete Savings Card: Eligible commercially insured patients may pay as little as $5 per month per prescription; savings of $6,000 per calendar year; for additional information contact the program at 800-274-6867.",
                "brand": "Rinvoq",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6000",
                "lifetime_uses": "12",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4d3"
            },
            {
                "_id": "6297bcace0e0db6c2572b4d8",
                "link": "https://www.copayassistancenow.com/eligibility",
                "description": "Rituxan Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Rituxan",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4d8"
            },
            {
                "_id": "6297bcace0e0db6c2572b4dd",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin 12 Hour Cough and Mucus Extended Release Tablet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4dd"
            },
            {
                "_id": "6297bcace0e0db6c2572b4e2",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Childrens Elderberry Cough plus Chest Congestion DM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4e2"
            },
            {
                "_id": "6297bcace0e0db6c2572b4e7",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Cough plus Chest Congestion DM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4e7"
            },
            {
                "_id": "6297bcace0e0db6c2572b4ec",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Honey Nighttime Cough DM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4ec"
            },
            {
                "_id": "6297bcace0e0db6c2572b4f1",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Severe Cough plus Sore Throat",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4f1"
            },
            {
                "_id": "6297bcace0e0db6c2572b4f6",
                "link": "https://rocklatan.copaysavingsprogram.com/",
                "description": "",
                "brand": "Rocklatan",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4f6"
            },
            {
                "_id": "6297bcace0e0db6c2572b4fb",
                "link": "https://www.rogaine.com/products/rogaine-subscription-program",
                "description": "Rogaine Subscribe Program: Subscribe and save up to 20% per order and get free shipping; for additional information contact the program at 855-839-3838.",
                "brand": "Rogaine for Men Unscented Foam",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4fb"
            },
            {
                "_id": "6297bcace0e0db6c2572b500",
                "link": "https://www.rogaine.com/products/rogaine-subscription-program",
                "description": "Rogaine Subscribe Program: Subscribe and save up to 20% per order and get free shipping; for additional information contact the program at 855-839-3838.",
                "brand": "Rogaine for Women Solution",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b500"
            },
            {
                "_id": "6297bcace0e0db6c2572b505",
                "link": "https://www.rolaids.com/en-us/coupon/",
                "description": "Save $2 on any one Rolaids product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Rolaids Extra Strength Chewable Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b505"
            },
            {
                "_id": "6297bcace0e0db6c2572b50a",
                "link": "https://roszetrx.com/savings/",
                "description": "Roszet Savings Program: Eligible commercially insured patients may pay $45 per 30 day supply; for additional information contact the program by using the Contact Us link on the website.",
                "brand": "Roszet",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b50a"
            },
            {
                "_id": "6297bcace0e0db6c2572b50f",
                "link": "https://rubracaconnections.com/pages/cg_affording_treatment.html",
                "description": "Rubraca Connections $0 Co-Pay Program: Eligible commercially insured patients may pay $0 per prescription with maximum savings of up to $30,000 per calendar year; for additional information contact the program at 844-779-7707.",
                "brand": "Rubraca",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "30000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b50f"
            },
            {
                "_id": "6297bcace0e0db6c2572b514",
                "link": "https://myviivcard.com/rebate-form-instructions.html",
                "description": "Rukobia ViiVConnect Rebate: Eligible commercially insured patients may be eligible for a rebate for the amount paid out of pocket per prescription if the pharmacy does not accept the savings card; rebate may be completed online or by downloading and mailing in rebate form; for additional information contact the program at 844-588-3288.",
                "brand": "Rukobia",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b514"
            },
            {
                "_id": "6297bcace0e0db6c2572b519",
                "link": "https://www.janssencarepath.com/patient/rybrevant/cost-support",
                "description": "Rybrevant Janssen CarePath Savings Program: Eligible commercially insured patients pay $5 for each infusion with a $26,000 maximum program benefit per calendar year; for additional information contact the program at 833-792-7382.",
                "brand": "Rybrevant",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "26000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b519"
            },
            {
                "_id": "6297bcace0e0db6c2572b51e",
                "link": "https://www.emdserono.com/us-en/expertise/endocrinology.html#anchor-2",
                "description": "Saizen Self-Pay Support Program: Eligible cash-paying patients & commercially insured patients/RX not covered patients may purchase their medication at a reduced price by registering; for additional information contact the program at 800-582-7989",
                "brand": "Saizen",
                "copay": "",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b51e"
            },
            {
                "_id": "6297bcace0e0db6c2572b523",
                "link": "https://copay.novartisoncology.com/?name=sandostatin",
                "description": "Sandostatin LAR Depot Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Sandostatin LAR Depot",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b523"
            },
            {
                "_id": "6297bcace0e0db6c2572b528",
                "link": "https://www.sanoficareassist.com/sarclisa/copay",
                "description": "Sarclisa CareASSIST Copay Program: Eligible commercially insured patients may pay as little as $0 for co-insurance, co-pay and deductibles; maxium savings of $25,000 per year; for additional information contact the program at 833-930-2273, Option 1.",
                "brand": "Sarclisa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b528"
            },
            {
                "_id": "6297bcace0e0db6c2572b52d",
                "link": "https://savaysa.com/savaysa-support",
                "description": "Savaysa Savings Card: Eligible patients may pay no more than $4 per 30-day supply and $12 per 90-day supply; for additional information contact the program at 877-264-2440.",
                "brand": "Savaysa",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "3240",
                "lifetime_uses": "12",
                "max_saving_transaction": 270,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b52d"
            },
            {
                "_id": "6297bcace0e0db6c2572b532",
                "link": "https://www.us.scemblix.com",
                "description": "Scemblix Novartis Oncology Universal Co-pay Program: Eligible commercially insured patients may pay $0 per monthly prescription; maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Scemblix",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b532"
            },
            {
                "_id": "6297bcace0e0db6c2572b537",
                "link": "https://www.merckconnect.com/steglatro/coupons-samples/",
                "description": "Segluromet FREE Trial Voucher: Eligible patients may receive a FREE 30-day supply by obtaining a voucher and a valid prescription from their healthcare provider; vouchers must be ordered by your healthcare provider; for additional information contact the program at 888-776-8364.",
                "brand": "Segluromet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b537"
            },
            {
                "_id": "6297bcace0e0db6c2572b53c",
                "link": "https://vitafol.com/gummies/",
                "description": "Select OB+DHA Samples: Your healthcare provider can request samples by completing a request form and faxing the form to 614-652-8275 or via email to ExeltisSamples@cardinalhealth.com.",
                "brand": "Select-OB DHA",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b53c"
            },
            {
                "_id": "6297bcace0e0db6c2572b541",
                "link": "https://www.selsunblue.com/en-us/coupon/",
                "description": "Save $1 on any Selsun Blue product; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Selsun Blue 3 in 1 Antidandruff Shampoo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b541"
            },
            {
                "_id": "6297bcace0e0db6c2572b546",
                "link": "https://www.selsunblue.com/en-us/coupon/",
                "description": "Save $1 on any Selsun Blue product; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Selsun Blue Naturals Antidandruff Shampoo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b546"
            },
            {
                "_id": "6297bcade0e0db6c2572b710",
                "link": "https://www.viberzi.com/savings-program",
                "description": "Viberzi Savings Program: Eligible commercially insured patients may pay as little as $30 per prescription (30, 60, or 90-day) depending on insurance; offer valid for 12 prescription fills; for additional information contact the program at 844-453-3487.",
                "brand": "Viberzi",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b710"
            },
            {
                "_id": "6297bcade0e0db6c2572b715",
                "link": "https://www.pggoodeveryday.com/signup/",
                "description": "Join P&G Everyday to receive coupons, samples and savings on VICKS products with registration.",
                "brand": "Vicks Non-Drowsy FluTherapy SEVERE Cold and Flu - Day Time",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b715"
            },
            {
                "_id": "6297bcade0e0db6c2572b71a",
                "link": "https://www.pggoodeveryday.com/signup/",
                "description": "Join P&G Everyday to receive coupons, samples and savings on VICKS products with registration.",
                "brand": "VICKS VapoCOOL Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b71a"
            },
            {
                "_id": "6297bcace0e0db6c2572b66e",
                "link": "https://www.tivose.com/?_ga=2.147676215.1454991677.1632748304-1662241270.1632748304#buy-tivose",
                "description": "Save 15% on Tivose when ordering the product on the website.",
                "brand": "Tivose",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b66e"
            },
            {
                "_id": "6297bcace0e0db6c2572b673",
                "link": "https://tolsura.com/savings/",
                "description": "Tolsura Mayne Pharma Patient Savings Card: Eligible commercially insured patients may pay $0 copay per prescription; for additional information contact the program at 347-442-7919.",
                "brand": "Tolsura",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b673"
            },
            {
                "_id": "6297bcace0e0db6c2572b678",
                "link": "https://www.toujeo.com/toujeo-savings-card-coupon-and-support",
                "description": "Toujeo Valyou Savings Program: Eligible uninsured and cash-paying patients will pay $99 per monthly supply of up to 10 vials or packs of SoloStar pens per fill or up to 5 packs of Max SoloStar pens per fill; for additional information contact the program at 833-813-0190.",
                "brand": "Toujeo",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b678"
            },
            {
                "_id": "6297bcace0e0db6c2572b67d",
                "link": "https://www.toujeo.com/toujeo-savings-card-coupon-and-support",
                "description": "Toujeo Solostar Sanofi Savings Card: Eligible commercially insured patients may pay as low as $0 and no more than $99 per 30-day supply; valid up to 10 packs per fill; for additional information contact the program at 866-390-5622.",
                "brand": "Toujeo Solostar",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b67d"
            },
            {
                "_id": "6297bcace0e0db6c2572b682",
                "link": "https://tranquilityproducts.com/take-our-survey-for-a-free-sample/",
                "description": "Tranquility Products FREE Samples: Receive a FREE 2-pack product sample when you submit your email then take a short survey; for additional information contact 866-865-6101.",
                "brand": "Tranquility Premium Protection Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b682"
            },
            {
                "_id": "6297bcade0e0db6c2572b71f",
                "link": "https://www.vimpat.com/savings-support",
                "description": "Vimpat Patient Savings Card: Eligible commercially insured patients may pay as little as $20 on each 30-day supply with a maximum savings of up to $1300 per calendar year; for additional information contact the program at 888-786-5879.",
                "brand": "Vimpat",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "1300",
                "lifetime_uses": "12",
                "max_saving_transaction": 108.34,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b71f"
            },
            {
                "_id": "6297bcade0e0db6c2572b724",
                "link": "https://www.visine.com/coupon-offers",
                "description": "$1 coupon for any one Visine product (0.5 oz or larger); 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Visine AC Itchy Eye Relief Astringent/Redness Reliever Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b724"
            },
            {
                "_id": "6297bcade0e0db6c2572b729",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Visine products with registration.",
                "brand": "Visine Dry Eye Relief Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b729"
            },
            {
                "_id": "6297bcade0e0db6c2572b72e",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Visine products with registration.",
                "brand": "Visine Maximum Strength Redness Relief Formula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b72e"
            },
            {
                "_id": "6297bcace0e0db6c2572b687",
                "link": "https://gskpro.com/en-us/request-samples-login-page/?resource=%2Fcontent%2Fcf-pharma%2Fhealth-hcpportal%2Fen_US%2Fhcp%2Fhome%2Fqpharma-saml-page.html",
                "description": "Trelegy Ellipta FREE Samples: Your healthcare provider may be able to order free samples by visiting GSK Pro and creating an account or by calling 888-593-5977.",
                "brand": "Trelegy Ellipta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b687"
            },
            {
                "_id": "6297bcade0e0db6c2572b733",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Visine products with registration.",
                "brand": "Visine Red Eye Hydrating Comfort Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b733"
            },
            {
                "_id": "6297bcace0e0db6c2572b68c",
                "link": "https://www.novocare.com/insulin/my99insulin.html?src=100001849",
                "description": "Tresiba My$99Insulin Card: Eligible patients pay $99 per monthly prescription (up to 3 vials or 2 packs of pens) of Novo Nordisk insulin products; offer may be used once a month during a calendar year; for additional information contact the program at 888-910-0632.",
                "brand": "Tresiba",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b68c"
            },
            {
                "_id": "6297bcade0e0db6c2572b738",
                "link": "https://www.visine.com/coupon-offers",
                "description": "$1 coupon for any one Visine product (0.5 oz or larger); 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Visine Totality",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b738"
            },
            {
                "_id": "6297bcace0e0db6c2572b691",
                "link": "https://www.treximet.com/Patient/",
                "description": "Treximet Savings Card: Eligible patients may pay as little as $25 per prescription; for additional information contact the program at 855-830-9254.",
                "brand": "Treximet",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b691"
            },
            {
                "_id": "6297bcade0e0db6c2572b73d",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Fe plus Instant Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Vitafol Fe plus",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b73d"
            },
            {
                "_id": "6297bcace0e0db6c2572b696",
                "link": "https://www.triaminic.com/signup",
                "description": "Register for special offers, latest news and updates for Triaminic products.",
                "brand": "Triaminic Cough and Congestion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b696"
            },
            {
                "_id": "6297bcade0e0db6c2572b742",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Gummies Mail-In Rebate: If your pharmacy does not accept the Savings Card or you use a mail-order pharmacy you may submit your original receipt, a photocopy of your savings card (front and back) along with your date of birth to the program to receive your proper savings; for additional information contact the program directly at 855-881-3090.",
                "brand": "Vitafol Gummies",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b742"
            },
            {
                "_id": "6297bcace0e0db6c2572b69b",
                "link": "https://tribenzor.com/savings",
                "description": "Tribenzor Pre-activated Savings Card: Eligible commercially insured patients may pay as little as $5 per month; for additional information contact the program 877-264-2440.",
                "brand": "Tribenzor",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b69b"
            },
            {
                "_id": "6297bcade0e0db6c2572b747",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Nano Instant Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Vitafol Nano",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b747"
            },
            {
                "_id": "6297bcade0e0db6c2572b74c",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Ultra Instant Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Vitafol Ultra",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b74c"
            },
            {
                "_id": "6297bcade0e0db6c2572b751",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol-OB DHA Instant Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Vitafol-OB DHA",
                "copay": "No",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b751"
            },
            {
                "_id": "6297bcade0e0db6c2572b756",
                "link": "https://www.gummyvites.com/en/Coupons2",
                "description": "Save $2 off any Vitafusion product when you signup online with your email address.",
                "brand": "Vitafusion Gummy Mens Multivitamin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b756"
            },
            {
                "_id": "6297bcade0e0db6c2572b75b",
                "link": "https://savings.vitamedmdrx.com/activate/index.html",
                "description": "vitaMedMD One Rx Savings Program: Eligible commercially insured patients may pay $25 or less per 30-day prescription; for additional information contact the program at 855-993-2665.",
                "brand": "vitaMedMD - One Rx",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b75b"
            },
            {
                "_id": "6297bcade0e0db6c2572b760",
                "link": "https://www.vivarin.com/en/where-to-buy",
                "description": "Save $2 on any one Vivarin 40-count product; 1 coupon per purchase; 1 coupon per purchase.",
                "brand": "Vivarin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b760"
            },
            {
                "_id": "6297bcade0e0db6c2572b765",
                "link": "https://www.voltarengel.com/samples-and-coupons/",
                "description": "Register your email address to receive future offers for Voltaren products.",
                "brand": "Voltaren Arthritis Pain",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b765"
            },
            {
                "_id": "6297bcade0e0db6c2572b76a",
                "link": "https://www.vosevi.com/co-pay-coupon-registration",
                "description": "Vosevi Co-Pay Coupon: Eligible commercially insured patients may pay no more than $5 per prescription fill; offer is valid for 6 months from the time of first use; for additional information contact the program at 855-769-7284.",
                "brand": "Vosevi",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "VOSEVI",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b76a"
            },
            {
                "_id": "6297bcade0e0db6c2572b76f",
                "link": "https://www.allergansavingscard.com/vraylar",
                "description": "Vraylar Savings Program: Eligible commercially insured patients may pay  as little as $15 on each of up to 12 (30-day) prescriptions; patients who have not previously registered for a Savings Card may pay $0 for their 1st 2 fills; for additional information contact the program at 800-761-0436.",
                "brand": "Vraylar",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b76f"
            },
            {
                "_id": "6297bcade0e0db6c2572b774",
                "link": "https://www.vyleesi.com/getting-started/",
                "description": "Vyleesi Copay Assistance: Eligible patients may pay $0 copay on 1st prescription, then pay no more that $99 for each 4-single dose refills; offer applies to 2 fills every 30 days; for additional information contact the program at 609-495-2200.",
                "brand": "Vyleesi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b774"
            },
            {
                "_id": "6297bcade0e0db6c2572b779",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Vytone Cream Artesa Labs Advantage Program: Eligible commercially insured patients may pay as little as $0 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Vytone Cream",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b779"
            },
            {
                "_id": "6297bcade0e0db6c2572b77e",
                "link": "https://www.wegovy.com/saving-and-support/save-and-support.html",
                "description": "Wegovy Savings Offer: Eligible commercially patients may pay as little as $0 per 28-day supply; offer good for 6 fills; Cash patients limit $500, Covered Commercial $225 per fill for 13 months for additional information contact the program at 833-493-4689.",
                "brand": "Wegovy",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0.00",
                "lifetime_uses": "6",
                "max_saving_transaction": null,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b77e"
            },
            {
                "_id": "6297bcade0e0db6c2572b783",
                "link": "https://www.wilateusa.com/vwd/factor-my-way/free-trial-program/",
                "description": "Wilate Factor My Way Free Trial Program: Patients considering treatment may receive up to 5,000 IUs (or 1-3 doses) at no cost shipped directly to your home; for additional information contact the program 866-526-4109.",
                "brand": "Wilate",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b783"
            },
            {
                "_id": "6297bcade0e0db6c2572b788",
                "link": "https://www.xalatan.com/savings",
                "description": "Xalatan Savings Card: Eligible patients may pay as little as $0 per month with savings of up to $1500 per calendar year; for additional information contact the program at 866-562-6147.",
                "brand": "Xalatan",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1500",
                "lifetime_uses": "12",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b788"
            },
            {
                "_id": "6297bcade0e0db6c2572b78d",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Xalkori Pfizer Oncology Together Mail-In Rebate: If a pharmacy does not accept the Savings Card the patient may submit a request for a rebate in connection with the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Xalkori",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.34,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b78d"
            },
            {
                "_id": "6297bcade0e0db6c2572b792",
                "link": "https://azuritysolutions.com/patients/xatmep/#product",
                "description": "Xatmep eVocherRx Program: Eligible commercially insured patients may pay no more than $5 per prescription; maximum savings per fill is $230 at a participating pharmacy; for additional information contact the program at 844-472-2032.",
                "brand": "Xatmep",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 230,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b792"
            },
            {
                "_id": "6297bcade0e0db6c2572b797",
                "link": "https://www.xeljanz.com/savings-and-support?ind=ra",
                "description": "Xeljanz XR Interim Care Rx Program: Eligible commercially insured patients may receive a 30-day supply of their medication when there is a delay during the prior authorization or appeals process; refills are subject to limitations; for additional information contact the program at 844-935-5269.",
                "brand": "Xeljanz XR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b797"
            },
            {
                "_id": "6297bcade0e0db6c2572b79c",
                "link": "https://www.xembify.com/en/hcp/xembify-connexions",
                "description": "Xembify Connexions Copay Assistance Program: Eligible commercially insured patients pay $0 per prescription with savings of up to $10,000 per calendar year; for additional information contact the program at 844-699-3624.",
                "brand": "Xembify",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b79c"
            },
            {
                "_id": "6297bcade0e0db6c2572b7a1",
                "link": "https://www.xepicream.com/",
                "description": "Xepi Copay Program: Eligible commercially insured patients may pay no more than $35 per prescription on up to 4 fills per year; for additional information contact the program at 844-780-8152.",
                "brand": "Xepi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "4",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7a1"
            },
            {
                "_id": "6297bcade0e0db6c2572b7a6",
                "link": "https://xhancecopay.com/download",
                "description": "Xhance Savings Card: Most eligible commercially insured patients will pay $0 for the initial prescription and $25 or less for refills; for additional information contact the program at 833-942-6231.",
                "brand": "Xhance",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "13000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7a6"
            },
            {
                "_id": "6297bcace0e0db6c2572b6a0",
                "link": "https://us.trintellix.com/savings-support",
                "description": "Trintellix Savings Card: Eligible commercially insured patients may pay no more than $10 per prescription with savings of up to $100 per 30-day supply per month; for additional information contact the program at 866-279-0287.",
                "brand": "Trintellix",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b6a0"
            },
            {
                "_id": "6297bcace0e0db6c2572b6a5",
                "link": "https://myviivcard.com",
                "description": "Triumeq ViiVConnect Savings Card: Eligible commercially insured patients pay $0 per prescription with savings of up to $7500 per year with no monthly limit; for additional information contact the program at 844-588-3288.",
                "brand": "Triumeq",
                "copay": "Yes",
                "bin": "610626",
                "rxpcn": "1016",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b6a5"
            },
            {
                "_id": "6297bcade0e0db6c2572b6aa",
                "link": "https://www.trokendixrhcp.com/",
                "description": "Trokendi XR Samples: Healthcare professionals may request samples on the website; for additional information contact the program at 973-656-2771.",
                "brand": "Trokendi XR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6aa"
            },
            {
                "_id": "6297bcade0e0db6c2572b6af",
                "link": "https://www.trulance.com/savings",
                "description": "Trulance Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription on each of up to 12 fills per year; for additional information contact the program at 855-846-2745.",
                "brand": "Trulance",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "TL",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6af"
            },
            {
                "_id": "6297bcade0e0db6c2572b6b4",
                "link": "https://www.tudorza.com",
                "description": "Tudorza Pressair Savings Card: Eligible cash-paying and commercially insured patients/RX not covered may save up to $100 per 30-day supply (1 inhaler); to obtain information for use of the offer contact the program at 800-236-9933.",
                "brand": "Tudorza Pressair",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6b4"
            },
            {
                "_id": "6297bcade0e0db6c2572b6b9",
                "link": "https://www.gileadadvancingaccess.com/financial-support/gilead-copay-card",
                "description": "Tybost Advancing Access Co-pay Program: Eligible commercially insured patients may save up to $50 per month with a maximum savings of $600 per year; for additional information contact the program at 800-226-2056.",
                "brand": "Tybost",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "ACCESS",
                "copay_max": "600",
                "lifetime_uses": "12",
                "max_saving_transaction": 50,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6b9"
            },
            {
                "_id": "6297bcade0e0db6c2572b6be",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol Cold plus Flu Severe Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6be"
            },
            {
                "_id": "6297bcade0e0db6c2572b6c3",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol Extra Strength Caplets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6c3"
            },
            {
                "_id": "6297bcade0e0db6c2572b6c8",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol PM Extra Strength Liquid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6c8"
            },
            {
                "_id": "6297bcace0e0db6c2572b0e3",
                "link": "https://www.isentress.com/what-is-isentress/?src=google&med=cpc&camp=Isentress_Isentress_BRND_NA_ENGM_EXCT_TEXT_MULTI&adgrp=Isentress+General&kw=isentress&gclid=Cj0KCQiA3NX_BRDQARIsALA3fIIBXZLcgb7RcfPfKMiP3VR1PcAUqC1LiCyN0iowIKXPA43w5VnnHFYaAgGzEALw_wcB&gclsrc=aw.ds",
                "description": "Isentress Savings Coupon Rebate: If the pharmacy does not accept the savings coupon eligible commercially insured patients may be able to request a rebate by contacting the program at 877-264-2454.",
                "brand": "Isentress",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6800",
                "lifetime_uses": "12",
                "max_saving_transaction": 566.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0e3"
            },
            {
                "_id": "6297bcace0e0db6c2572b0e8",
                "link": "https://www.isturisa.com/patient/savings-support/",
                "description": "Isturisa R.A.R.E Patient Support Program: Eligible commercially insured patients may pay as little as $20 per monthly fill; for more information contact the program at 888-855-7273.",
                "brand": "Isturisa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0e8"
            },
            {
                "_id": "6297bcace0e0db6c2572b0ed",
                "link": "https://www.jakafi.com/polycythemia-vera/incytecares-support",
                "description": "Jakafi Temporary Coverage: Eligible commercially insured patients may receive a supply of medication for FREE if they are experiencing a delay in their insurance coverage; for additional information contact the program at 855-452-5234.",
                "brand": "Jakafi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0ed"
            },
            {
                "_id": "6297bcade0e0db6c2572b7ab",
                "link": "https://xifaxan.copaysavingsprogram.com",
                "description": "Xifaxan Savings Card: Eligible commercially insured patients may pay as little as $0 per prescription; contact the program for more information at 866-943-2926.",
                "brand": "Xifaxan",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7ab"
            },
            {
                "_id": "6297bcace0e0db6c2572b0f2",
                "link": "https://www.janumetxr.com/",
                "description": "Janumet XR Savings Coupon Rebate: If a commercially insured patient is unable to redeem the savings coupon at their retail or mail-order pharmacy they can contact the program for a Direct Member Reimbursement form; contact must be made within 30 days of purchase; for additional information contact the program at 877-264-2454.",
                "brand": "Janumet XR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0f2"
            },
            {
                "_id": "6297bcade0e0db6c2572b7b0",
                "link": "https://www.xigduoxr.com/savings-and-support/rx-savings.html",
                "description": "Xigduo XR SavingsRx Card Mail-in Rebate: Eligible patients may submit a request for a rebate if their mail order pharmacy does not accept the Savings Card; for additional information contact the program 844-631-3978.",
                "brand": "Xigduo XR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7b0"
            },
            {
                "_id": "6297bcade0e0db6c2572b7b5",
                "link": "https://www.xofluza.com/coupon.html",
                "description": "Xofluza Savings Coupon: Eligible patients may pay as little as $30 per prescription with savings of up to $60 per fill; for additional information contact the program at 855-963-5892.",
                "brand": "Xofluza",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "120",
                "lifetime_uses": "2",
                "max_saving_transaction": 60,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7b5"
            },
            {
                "_id": "6297bcace0e0db6c2572b0f7",
                "link": "https://www.jardiance.com/type-2-diabetes/support-and-savings/savings",
                "description": "Jardiance Savings Card: Eligible commercially insured patients may pay as little as $10 with a maximum savings of up to $175 per 30-day supply; eligible patients will automatically be re-enrolled for an additional 12-month savings; for additional information contact the program at 866-279-8990.",
                "brand": "Jardiance",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "2100",
                "lifetime_uses": "12",
                "max_saving_transaction": 175,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0f7"
            },
            {
                "_id": "6297bcade0e0db6c2572b7ba",
                "link": "https://almiralladvantage.com/",
                "description": "Xolegel Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $35 per prescription when using a retail pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Xolegel",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7ba"
            },
            {
                "_id": "6297bcace0e0db6c2572b0fc",
                "link": "https://www.jentadueto.com/savings-card",
                "description": "Jentadueto XR Savings Card:Eligible commercially insured patients may pay as little as $10 per month with a maximum savings of $150 per fill; for additional information contact the program at 888-879-0466.",
                "brand": "Jentadueto XR",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0fc"
            },
            {
                "_id": "6297bcade0e0db6c2572b7bf",
                "link": "https://www.xtampzaer.com/savings/",
                "description": "Xtampza ER Co-pay Card: Eligible commercially insured patients may pay as little as $35 per prescription; for additional information contact the program at 844-247-4753.",
                "brand": "Xtampza ER",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7bf"
            },
            {
                "_id": "6297bcace0e0db6c2572b101",
                "link": "https://www.jivi-us.com/copay-support/",
                "description": "Jivi Reimbursement for Lab Testing: Eligible commercially insured patients may qualify to receive up to $250 per year towards lab monitoring out-of-pocket costs; for additional information contact the program at 800-288-8374",
                "brand": "Jivi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "250",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b101"
            },
            {
                "_id": "6297bcade0e0db6c2572b6cd",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol Simply Sleep",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6cd"
            },
            {
                "_id": "6297bcace0e0db6c2572b106",
                "link": "https://www.orthorxaccess.com/",
                "description": "Jublia Rx Access Program: Eligible commercially insured patients pay $0 per prescription; valid for 12 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Jublia",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b106"
            },
            {
                "_id": "6297bcace0e0db6c2572b10b",
                "link": "https://www.alle.com/upgrade",
                "description": "Juvederm Ultra Plus XC Alle Points Program: Register and earn points redeemable for dollars OFF certain in-office treatments; also receive personalized special offers; for additional information contact the program at 888-912-1572.",
                "brand": "Juvederm Ultra Plus XC",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b10b"
            },
            {
                "_id": "6297bcade0e0db6c2572b7c4",
                "link": "https://www.xyntha.com/free-trial-program",
                "description": "Xyntha Solofuse FREE Trial Prescription Offer: Receive a 1-month supply of  up to 20,000 IU at no cost; available only for patients' first-time use; for additional information contact the program at 844-989-4366.",
                "brand": "Xyntha Solofuse",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7c4"
            },
            {
                "_id": "6297bcace0e0db6c2572b110",
                "link": "https://www.jynarque.com/copay-assistance",
                "description": "Jynarque Copay Savings Program: Eligible commercially insured patients may pay no more than $10 per month; for additional information contact the program at 833-569-7277.",
                "brand": "Jynarque",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b110"
            },
            {
                "_id": "6297bcade0e0db6c2572b6d2",
                "link": "https://www.tyrvaya.com",
                "description": "Tyrvay Bridge to Coverage: Eligible commercially insured patients may pay $10 per prescription while waiting for insurance coverage to be processed for additional information contact the program at 844-201-3331.",
                "brand": "Tyrvaya",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6d2"
            },
            {
                "_id": "6297bcace0e0db6c2572b115",
                "link": "https://www.vertexgps.com/about-vertex-gps#overviewVid",
                "description": "Kalydeco Vertex GPS Financial Assistance Program: Eligible commercially insured patients may pay as little as $15 per prescription; for additional information contact the program at 877-752-5933 (press 2).",
                "brand": "Kalydeco",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b115"
            },
            {
                "_id": "6297bcade0e0db6c2572b7c9",
                "link": "https://jazzcares.com/patients/xyrem/",
                "description": "Xyrem Coupon Program: Eligible commercially insured patients may pay as little as $35 per 30-day fill; for additional information contact the program at 866-997-3688.",
                "brand": "Xyrem",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7c9"
            },
            {
                "_id": "6297bcace0e0db6c2572b11a",
                "link": "https://karbinaler.com",
                "description": "Karbinal ER Savings Offer: Eligible commercially insured patients may pay as little as $15 per prescription; for additional information contact the program at 888-298-8792.",
                "brand": "Karbinal ER",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b11a"
            },
            {
                "_id": "6297bcade0e0db6c2572b6d7",
                "link": "https://www.ucerisfoam.com",
                "description": "Uceris (Foam) Savings Card: Eligible commercially insured patients may pay as little as $25 per fill; for additional information contact the program at 855-558-1669.",
                "brand": "Uceris",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6d7"
            },
            {
                "_id": "6297bcace0e0db6c2572b11f",
                "link": "https://www.kerasal.com/pages/coupons",
                "description": "Save $3 on Kerasal Fungal Nail Renewal; 1 coupon per purchase.",
                "brand": "Kerasal Fungal Nail Renewal Treatment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b11f"
            },
            {
                "_id": "6297bcade0e0db6c2572b7ce",
                "link": "https://www.bmsaccesssupport.bmscustomerconnect.com/patient/financial-support",
                "description": "Yervoy BMS Oncology Co-Pay Assistance Program: Enrolled patients who have commercial insurance may pay no more than $25 per infusion with savings of up to $25,000 per year; for additional information contact the program at 800-861-0048.",
                "brand": "Yervoy",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7ce"
            },
            {
                "_id": "6297bcace0e0db6c2572b124",
                "link": "https://www.kesimpta.com/patient-support/financial-resources/",
                "description": "Kesimpta Access Bridge Program: Eligible commercially insured patients who receive an initial denial for coverage may receive up to 12 months of free product while coverage is pursued; for more information contact the program at 855-537-4678.",
                "brand": "Kesimpta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b124"
            },
            {
                "_id": "6297bcade0e0db6c2572b6dc",
                "link": "https://ukoniq.com/patient/patientsupport",
                "description": "Ukoniq Bridge Program: Eligible commercially insured patients who are experiencing a continued insurance coverage delay may receive free medication; insurance delay must be at least 5 business days; if the delay is extended additional free medication may be provided; for additional information contact the program directly at 877-848-9777.",
                "brand": "Ukoniq",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6dc"
            },
            {
                "_id": "6297bcace0e0db6c2572b129",
                "link": "https://www.kevzara.com/starting-kevzara/kevzaraconnect-copay-card/#copay-t-and-c",
                "description": "Kevzara Connect Copay Card Rebate: Eligible commercially insured patients may request a rebate if they have paid out of pocket for their prescription; for additional information contact the program at 844-218-0444.",
                "brand": "Kevzara",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b129"
            },
            {
                "_id": "6297bcace0e0db6c2572b12e",
                "link": "https://www.us.kisqali.com/metastatic-breast-cancer/patient-support/financial-resources/",
                "description": "Kisqali 1 Free Treatment Cycle: Eligible patients may receive a 1-treatment cycle supply for free; for additional information contact the program at 800-282-7630.",
                "brand": "Kisqali",
                "copay": "No",
                "bin": "601341",
                "rxpcn": "OHS",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b12e"
            },
            {
                "_id": "6297bcade0e0db6c2572b7d3",
                "link": "https://www.eyefile.com/TheEyeSolution/Register/0",
                "description": "Save $2 on your next purchase of Zaditor with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Zaditor",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7d3"
            },
            {
                "_id": "6297bcace0e0db6c2572b133",
                "link": "https://copay.novartisoncology.com/",
                "description": "Kisqali & Femara Novartis Universal Co-Pay Card: Eligible patients may pay no more than $25 for each of your prescriptions with a maximum savings of $15,000 per year; for additional information contact the program at 877-577-7756.",
                "brand": "Kisqali and Femara",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b133"
            },
            {
                "_id": "6297bcade0e0db6c2572b6e1",
                "link": "https://www.unisom.com/en-us/coupons",
                "description": "Save $1 on any Unisom product (24 count or larger); one coupon per purchase; expires one month after printing",
                "brand": "Unisom SleepGels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6e1"
            },
            {
                "_id": "6297bcace0e0db6c2572b138",
                "link": "https://www.kogenatefs.com/accessing-kogenate",
                "description": "Kogenate FS $0 Co-Pay Program: Eligible commercially insured patients may receive up to $12,000 in savings per year; for additional information contact the program at 647-245-5619.",
                "brand": "Kogenate FS",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b138"
            },
            {
                "_id": "6297bcade0e0db6c2572b7d8",
                "link": "https://gskpro.com/en-us/request-samples-login-page/?resource=%2Fcontent%2Fcf-pharma%2Fhealth-hcpportal%2Fen_US%2Fhcp%2Fhome%2Fqpharma-saml-page.html",
                "description": "Zejula Free Samples: Healthcare providers may request samples by registering online.",
                "brand": "Zejula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7d8"
            },
            {
                "_id": "6297bcace0e0db6c2572b13d",
                "link": "https://www.kombiglyzexr.com/savings-support/kombiglyze-coupon.html#email",
                "description": "Kombiglyze XR Instant Savings Card Rebate: If an eligible patient's mail-order pharmacy does not accept the Savings Card, the patient may submit a rebate request; or additional information contact the program at 855-907-3197.",
                "brand": "Kombiglyze XR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b13d"
            },
            {
                "_id": "6297bcade0e0db6c2572b6e6",
                "link": "https://www.activatethecard.com/7194/",
                "description": "Unithroid Instant Savings Card: Eligible uninsured patients may pay no more than $35 per 30-day supply or no more than $70 per 60-day supply or no more than $105 per 90-day supply; for additional information contact the program at 877-264-2440.",
                "brand": "Unithroid",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "900",
                "lifetime_uses": "12",
                "max_saving_transaction": 50,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6e6"
            },
            {
                "_id": "6297bcace0e0db6c2572b142",
                "link": "https://www.kovaltry-us.com/access-services-by-bayer",
                "description": "Kovaltry Free Trial Program: Newly diagnosed patients may receive a FREE 1 month supply; for more additional information contact the program at 800-288-8374.",
                "brand": "Kovaltry",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b142"
            },
            {
                "_id": "6297bcade0e0db6c2572b7dd",
                "link": "https://www.zemaira.com/support",
                "description": "Zemaira Connect Co-pay Program: Eligible commercially insured patients may qualify for co-pay support; for additional information contact the program at 866-936-2472.",
                "brand": "Zemaira",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7dd"
            },
            {
                "_id": "6297bcace0e0db6c2572b147",
                "link": "https://www.krystexxa.com/cost-savings",
                "description": "Krystexxa Connect Co-Pay Reduction Program: Eligible commercially insured patients may qualify for a co-pay reduction; for further additional information contact the program at 833-469-4688.",
                "brand": "Krystexxa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b147"
            },
            {
                "_id": "6297bcade0e0db6c2572b6eb",
                "link": "https://www.alva-amco.com/contact",
                "description": "Consumers may fill out a request form for Uricalm product coupons.",
                "brand": "Uricalm",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6eb"
            },
            {
                "_id": "6297bcace0e0db6c2572b14c",
                "link": "https://amgenfirststep.com/register-card",
                "description": "Kyprolis Amgen First Step Program: Eligible commercially insured patients may save on their out-of-pocket medication costs; for additional information contact the program at 888-427-7478.",
                "brand": "Kyprolis",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b14c"
            },
            {
                "_id": "6297bcade0e0db6c2572b7e2",
                "link": "https://www.zenzedi.com/savings.php",
                "description": "Zenzedi Copay Savings Program: Eligible commercially insured patients may pay as little as $30 per monthly prescription; for additional information contact the program at 855-558-1630.",
                "brand": "Zenzedi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7e2"
            },
            {
                "_id": "6297bcace0e0db6c2572b151",
                "link": "https://mycareclubrewards.com/lactaid/?utm_medium=partner&utm_source=lactaid.com&utm_content=tout&utm_campaign=cc_main&_gl=1*1jjrjz9*_ga*MjAwOTYxNTY1My4xNjM2OTk3MjUw*_ga_13VEM6N66E*MTYzNzE2ODg0Ny43LjEuMTYzNzE2ODg5MS4xNg..*_fplc*JTJGRSUyRmRGbFkxdmElMkZPQ0FTRDE3Y2JCOFhieW1QaXp2ekFkSFRHWjZ4NzE1Q2p0JTJGeTU4d2JuM1lFVmcxQjVranklMkJuRXBWcGkyUzdqQVpqTVpnRjhFZlJzb3N6YTRlRGJGSlNTZHQ3R0p5aFVoMjFlRGZjaHJNY1pjS1VUdnB4QSUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Lactaid products with registration.",
                "brand": "Lactaid Fast Act Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b151"
            },
            {
                "_id": "6297bcade0e0db6c2572b6f0",
                "link": "http://wcpharma.com/patient-savings/",
                "description": "Urogesic Blue Women's Choice Savings Coupon: Eligible commercially insured patients may pay no more than $30 per 30-pill prescription; for additional information contact the program at 800-664-1490.",
                "brand": "Urogesic Blue",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6f0"
            },
            {
                "_id": "6297bcace0e0db6c2572b156",
                "link": "https://www.lantus.com/sign-up-for-savings",
                "description": "Lantus Sanofi Copay Program: Eligible commercially insured patients may pay as little as $0 and no more than $99 for a 30-day supply; valid up to 10 packs per fill; for additional information contact the program at 866-251-4750.",
                "brand": "Lantus",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b156"
            },
            {
                "_id": "6297bcace0e0db6c2572b15b",
                "link": "https://www.patientrebateonline.com/patientrebate/welcome.html",
                "description": "Lantus Valyou Savings Rebate: Eligible uninsured cash-paying patients may be able to submit a request for a rebate up to the amount of savings earned with the Savings Card if their pharmacy does not accept the Savings Card; or additional information contact the program at 866-390-5622.",
                "brand": "Lantus SoloSTAR Pen",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b15b"
            },
            {
                "_id": "6297bcade0e0db6c2572b7e7",
                "link": "https://myzerviate.com/copay/",
                "description": "Zerviate Copay Savings Program: Eligible commercially insured patients pay $49 per fill; for additional information contact the program at 866-747-0976.",
                "brand": "Zerviate",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "4",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7e7"
            },
            {
                "_id": "6297bcace0e0db6c2572b160",
                "link": "https://www.patientrebateonline.com/patientrebate/welcome.html",
                "description": "Lantus Valyou Savings Rebate: Eligible uninsured cash-paying patients may be able to submit a request for a rebate up to the amount of savings earned with the Savings Card if their pharmacy does not accept the Savings Card; or additional information contact the program at 866-390-5622.",
                "brand": "Lantus U-100",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b160"
            },
            {
                "_id": "6297bcade0e0db6c2572b7ec",
                "link": "https://www.orthorxaccess.com/",
                "description": "Ziana Rx Access Program: Eligible commercially insured patients pay no more than $25 per prescription; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Ziana",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7ec"
            },
            {
                "_id": "6297bcace0e0db6c2572b165",
                "link": "https://lazanda.com",
                "description": "Lazanda Savings Card Rebate: If a commercially eligible patient's pharmacy does not accept the accept the Savings Card the patient may submit a rebate request; the patient should pay for the prescription and send a copy of the itemized pharmacy receipt to: pnuvamsa@alpahscrip.com or fax 602-840-9508; for additional information contact the program at 844-309-3835.",
                "brand": "Lazanda",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "14000",
                "lifetime_uses": "14",
                "max_saving_transaction": 1000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b165"
            },
            {
                "_id": "6297bcade0e0db6c2572b7f1",
                "link": "https://www.zioptan.com",
                "description": "Zioptan Patient Savings Card: Eligible patients pay as little as $20 with a savings of $65 per prescription; offer valid for 36 fills; for more information contact the program at 877-505-6985.",
                "brand": "Zioptan",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 65,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7f1"
            },
            {
                "_id": "6297bcace0e0db6c2572b16a",
                "link": "https://www.novocare.com/insulin/my99insulin.html?src=100001849",
                "description": "Levemir My$99Insulin Card: Eligible patients pay $99 per monthly prescription (up to 3 vials or 2 packs of pens) of Novo Nordisk insulin products; offer may be used once a month during a calendar year; for additional information contact the program at 888-910-0632.",
                "brand": "Levemir",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b16a"
            },
            {
                "_id": "6297bcade0e0db6c2572b7f6",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Zirgan Bausch + Lomb Access Program: Eligible cash-paying and commercially insured/RX not covered patients may pay no more than $60 per prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Zirgan",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7f6"
            },
            {
                "_id": "6297bcace0e0db6c2572b16f",
                "link": "https://myviivcard.com/rebate-form-instructions.html",
                "description": "Lexiva ViiVConnect Rebate: Eligible commercially insured patients may be eligible for a rebate for the amount paid out of pocket per prescription if the pharmacy does not accept the savings card; rebate may be completed online or by downloading and mailing in rebate form; for additional information contact the program at 844-588-3288.",
                "brand": "Lexiva",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b16f"
            },
            {
                "_id": "6297bcade0e0db6c2572b7fb",
                "link": "https://www.zoloft.com/savings",
                "description": "Zoloft Savings Card: Eligible patients may pay as little as $4 per 30-day prescription fill with savings of up to $150 per fill and $1800 per year; for additional information contact the program at 855-220-9547.",
                "brand": "Zoloft",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7fb"
            },
            {
                "_id": "6297bcade0e0db6c2572b800",
                "link": "https://myzolpimist.com/#rxconnect-section",
                "description": "ZolpiMist Aytu RxConnect Pharmacy Network Program: Eligible commercially insured patients may pay $0 per 30-day prescription when using a Aytu RxConnect Pharmacy; for additional information contact the program at 877-675-6590.",
                "brand": "ZolpiMist",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b800"
            },
            {
                "_id": "6297bcade0e0db6c2572b805",
                "link": "https://zontivity.com",
                "description": "Zontivity Co-pay Card: Eligible commercially insured patients may pay as little as $25 per fill; for additional information contact the program at 844-847-7738.",
                "brand": "Zontivity",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b805"
            },
            {
                "_id": "6297bcade0e0db6c2572b80a",
                "link": "https://www.orthorxaccess.com/",
                "description": "Zovirax Rx Access Program: Eligible commercially insured patients pay no more than $25 per prescription; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Zovirax",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b80a"
            },
            {
                "_id": "6297bcade0e0db6c2572b6f5",
                "link": "https://www.myneurelis.com/services",
                "description": "Valtoco Copay Card: Eligible commercially insured patients may pay as little as $20 per prescription; for more information contact the program at 866-696-3873.",
                "brand": "Valtoco",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6f5"
            },
            {
                "_id": "6297bcade0e0db6c2572b80f",
                "link": "https://www.sagecentralsupport.com/zulressoforpatients/",
                "description": "Zulresso Drug Copay Assistance Program: Eligible commercially insured patients may save up to $15,000 on their out-of-pocket, drug-related costs; for additional information contact the program at 844-472-4379.",
                "brand": "Zulresso",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b80f"
            },
            {
                "_id": "6297bcade0e0db6c2572b6fa",
                "link": "https://www.vasculera.com/samples.html",
                "description": "Vasculera Samples: Your healthcare provider may request samples by completing a request online; for additional information contact the program at 888-546-2735.",
                "brand": "Vasculera",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6fa"
            },
            {
                "_id": "6297bcade0e0db6c2572b814",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Zylet Bausch + Lomb Access Program: Eligible cash-paying and commercially insured/RX not covered patients may pay no more than $60 per 5 mL bottle/$90 per 10 mL bottle prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Zylet",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b814"
            },
            {
                "_id": "6297bcade0e0db6c2572b6ff",
                "link": "https://assets.needymeds.org/CouponPDFs/6170.pdf",
                "description": "Veltin Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $35 per prescription; for additional information contact the program at 888-591-9860.",
                "brand": "Veltin",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0.00",
                "lifetime_uses": "0",
                "max_saving_transaction": null,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6ff"
            },
            {
                "_id": "6297bcade0e0db6c2572b819",
                "link": "https://patient.zypitamag.com/dual-savings-card",
                "description": "Zypitamag Dual Savings Card: Eligible cash-paying patients may save up to $183 per 30-day supply; for additional information contact the program at 866-643-1524.",
                "brand": "Zypitamag",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "2196",
                "lifetime_uses": "12",
                "max_saving_transaction": 183,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b819"
            },
            {
                "_id": "6297bcade0e0db6c2572b704",
                "link": "https://almiralladvantage.com",
                "description": "Verdeso (Foam) Almirall Advantage Savings Card: Eligible commercially insured patients/RX not covered may pay no more than $50 per prescription when using a network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Verdeso",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b704"
            },
            {
                "_id": "6297bcade0e0db6c2572b81e",
                "link": "https://mycareclubrewards.com/zyrtec/?utm_medium=partner&utm_source=zyrtec.com&utm_content=footer&utm_campaign=cc_main&_gl=1*i86ng6*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjQ3ODA2OS4zLjEuMTYzNjQ3ODE3Ny4xNQ..*_fplc*aTZTRSUyRktpOGJCeFFqdzlkb0ZtTmwlMkI1VXE4VkhJSERDdmI1Q1d6TklLMW1nOFVyaUJOQUdscnpCJTJGd1ZBRXFSSUlOaDVLSW5sMVoxSGtXOENrTFV2bUVGUk11YjRFS3JqZGNDN0VWR3JNMGFpMW9GNjJGZU9VWkdER1ZTa2x3JTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Zyrtec products with registration.",
                "brand": "Zyrtec Allergy-Children's",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b81e"
            },
            {
                "_id": "6297bcade0e0db6c2572b709",
                "link": "https://www.verquvo-us.com/special-offers/",
                "description": "Verquvo Free Trial Offer: Eligible patients may receive either one 30-day trial supply of a single dosage form or two 14-day trial supplies of two dosage forms; for more information contact the program directly at 877-264-2454.",
                "brand": "Verquvo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b709"
            },
            {
                "_id": "6297bcade0e0db6c2572b823",
                "link": "https://www.zyrtecprofessional.com/order",
                "description": "Zyrtec Samples: Healthcare providers may order samples for their practice by visiting the website.",
                "brand": "Zyrtec Dissolve Tabs",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b823"
            },
            {
                "_id": "6297bcade0e0db6c2572b70e",
                "link": "https://www.viagra.com/savings-terms",
                "description": "Viagra Savings Offer Rebate: Eligible patients may submit a rebate request if their pharmacy was unable to process the Savings Card; for additional information contact the program at 855-842-4722.",
                "brand": "Viagra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "4200",
                "lifetime_uses": "12",
                "max_saving_transaction": 350,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b70e"
            },
            {
                "_id": "6297bcade0e0db6c2572b828",
                "link": "https://mycareclubrewards.com/zyrtec/?utm_medium=partner&utm_source=zyrtec.com&utm_content=footer&utm_campaign=cc_main&_gl=1*i86ng6*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjQ3ODA2OS4zLjEuMTYzNjQ3ODE3Ny4xNQ..*_fplc*aTZTRSUyRktpOGJCeFFqdzlkb0ZtTmwlMkI1VXE4VkhJSERDdmI1Q1d6TklLMW1nOFVyaUJOQUdscnpCJTJGd1ZBRXFSSUlOaDVLSW5sMVoxSGtXOENrTFV2bUVGUk11YjRFS3JqZGNDN0VWR3JNMGFpMW9GNjJGZU9VWkdER1ZTa2x3JTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Zyrtec products with registration.",
                "brand": "Zyrtec Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b828"
            },
            {
                "_id": "6297bcade0e0db6c2572b713",
                "link": "https://www.pggoodeveryday.com/signup/",
                "description": "Join P&G Everyday to receive coupons, samples and savings on VICKS products with registration.",
                "brand": "VICKS DayQuil Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b713"
            },
            {
                "_id": "6297bcade0e0db6c2572b82d",
                "link": "https://www.zyrtec.com/?action=sign-up",
                "description": "Receive a coupon for any one Zyrtec product and other exclusive offers  with sign up; 1 coupon per purchase.",
                "brand": "Zyrtec-D",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b82d"
            },
            {
                "_id": "6297bcade0e0db6c2572b718",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings on VICKS products with registration.",
                "brand": "VICKS Sinex Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b718"
            },
            {
                "_id": "6297bcade0e0db6c2572b832",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for ZzzQuil Products!",
                "brand": "ZzzQuil LiquiCaps",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b832"
            },
            {
                "_id": "6297bcade0e0db6c2572b71d",
                "link": "https://www.viltepso.com/support",
                "description": "Viltepso Co-Pay Assistance Program: Eligible commercially insured patients may pay as little as $0 per infusion; program covers the cost of the medication not the cost of administering the infusion; maximum savings of $20,000 per calendar year; patients are automatically re-enrolled each calendar year; for additional information contact the program at 833-677-8778.",
                "brand": "Viltepso",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b71d"
            },
            {
                "_id": "6297bcade0e0db6c2572b722",
                "link": "https://www.gileadadvancingaccess.com/financial-support/gilead-copay-card",
                "description": "Viread Advancing Access Co-pay Program: Eligible commercially insured patients may save up to $300 per month with a maximum savings of $3600 per year; for additional information contact the program at 800-226-2056.",
                "brand": "Viread",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "ACCESS",
                "copay_max": "3600",
                "lifetime_uses": "12",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b722"
            },
            {
                "_id": "6297bcade0e0db6c2572b727",
                "link": "https://www.visine.com/coupon-offers",
                "description": "$1 coupon for any one Visine product (0.5 oz or larger); 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Visine Allergy Eye Relief Multi-Action Antihistamine and Redness Reliever",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b727"
            },
            {
                "_id": "6297bcade0e0db6c2572b72c",
                "link": "https://www.visine.com/coupon-offers",
                "description": "$1 coupon for any one Visine product (0.5 oz or larger); 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Visine For Contacts Lubricating plus Rewetting Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b72c"
            },
            {
                "_id": "6297bcade0e0db6c2572b731",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Visine products with registration.",
                "brand": "Visine Red Eye Comfort Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b731"
            },
            {
                "_id": "6297bcace0e0db6c2572affa",
                "link": "https://www.lillyglucagon.com",
                "description": "Glucagon Savings Card: Eligible commercially insured patients pay as little as $30 for a maximum of 3 Lilly Glucagon emergency kits per prescription fill with a maximum monthly savings of $100; maximum annual savings of $1200; for additional information contact the program at 866-568-8942.",
                "brand": "Glucagon",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572affa"
            },
            {
                "_id": "6297bcade0e0db6c2572b736",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Visine products with registration.",
                "brand": "Visine Red Eye Total Comfort Multi-Symptom Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b736"
            },
            {
                "_id": "6297bcace0e0db6c2572afff",
                "link": "https://glucerna.com/signup",
                "description": "Sign up with Glucerna Everyday Progress and receive savings of up to $24 on Glucerna products and much more.",
                "brand": "Glucerna Shakes",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572afff"
            },
            {
                "_id": "6297bcace0e0db6c2572b004",
                "link": "https://www.pfizerpro.com/request-samples",
                "description": "Glyset Samples: Your healthcare provider may order samples by visiting the PfizerPro website or calling 800-505-4426.",
                "brand": "Glyset",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b004"
            },
            {
                "_id": "6297bcade0e0db6c2572b73b",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Ultra Instant Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Vitafol",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b73b"
            },
            {
                "_id": "6297bcace0e0db6c2572b009",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Anti-Itch Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b009"
            },
            {
                "_id": "6297bcade0e0db6c2572b740",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Gummies Instant Savings Card: Eligible cash-paying patients may pay approximately $65 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Vitafol Gummies",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b740"
            },
            {
                "_id": "6297bcace0e0db6c2572b00e",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product; coupon expires 1 month after printing.",
                "brand": "Gold Bond Friction Defense",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b00e"
            },
            {
                "_id": "6297bcade0e0db6c2572b745",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Nano Mail-In Rebate: If your pharmacy does not accept the Savings Card or you use a mail-order pharmacy you may submit your original receipt, a photocopy of your savings card (front and back) along with your date of birth to the program to receive your proper savings; for additional information contact the program directly at 855-881-3090.",
                "brand": "Vitafol Nano",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b745"
            },
            {
                "_id": "6297bcace0e0db6c2572b013",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Medicated Original Strength Body Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b013"
            },
            {
                "_id": "6297bcace0e0db6c2572b018",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Pain and Itch Relief Cream with Lidocaine",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b018"
            },
            {
                "_id": "6297bcade0e0db6c2572b74a",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol One Instant Savings Card: Eligible cash-paying patients may pay approximately $65 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Vitafol One",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b74a"
            },
            {
                "_id": "6297bcace0e0db6c2572b01d",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Ultimate Cracked Skin Relief Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b01d"
            },
            {
                "_id": "6297bcade0e0db6c2572b74f",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Ultra Mail-In Rebate: If your pharmacy does not accept the Savings Card or you use a mail-order pharmacy you may submit your original receipt, a photocopy of your savings card (front and back) along with your date of birth to the program to receive your proper savings; for additional information contact the program directly at 855-881-3090.",
                "brand": "Vitafol Ultra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b74f"
            },
            {
                "_id": "6297bcade0e0db6c2572b754",
                "link": "https://www.gummyvites.com/en/Coupons2",
                "description": "Save $2 off any Vitafusion product when you signup online with your email address.",
                "brand": "Vitafusion Gummy Immune Support Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b754"
            },
            {
                "_id": "6297bcade0e0db6c2572b759",
                "link": "https://www.gummyvites.com/en/Coupons2",
                "description": "Save $2 off any Vitafusion product when you signup online with your email address.",
                "brand": "Vitafusion Gummy Vitamin Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b759"
            },
            {
                "_id": "6297bcace0e0db6c2572b022",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Ultimate Diabetics Dry Skin Relief Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b022"
            },
            {
                "_id": "6297bcace0e0db6c2572b027",
                "link": "https://www.goldbond.com/en-us/coupons",
                "description": "Save $1 on any one Gold Bond product (excluding trial & travel sizes); coupon expires 1 month after printing.",
                "brand": "Gold Bond Ultimate Rough and Bumpy Skin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b027"
            },
            {
                "_id": "6297bcade0e0db6c2572b75e",
                "link": "https://savings.vitamedmdrx.com/activate/index.html",
                "description": "vitaTrue vitaMedMD Savings ProgramEligible commercially insured patients may pay $25 or less per 30-day prescription; for additional information contact the program at 855-993-2665.",
                "brand": "vitaTrue",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b75e"
            },
            {
                "_id": "6297bcace0e0db6c2572b02c",
                "link": "https://www.alksavings.com/cb/access/crp/aLkYd2j78Vbb/fid.jsp?q=51G5OI5442CUDK4PLWTLX5&f=IMPX6IG-274EMWRGK6802#/app/layout/home",
                "description": "Grastek ALK Savings Card Program: Eligible commercially insured patients/RX not covered may pay $100 per 30-day prescription if using a retail pharmacy; valid for 12 uses or $3,200 per year (whichever comes first); for additional information contact the program at 800-325-7354.",
                "brand": "Grastek",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3200",
                "lifetime_uses": "12",
                "max_saving_transaction": 265,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b02c"
            },
            {
                "_id": "6297bcace0e0db6c2572b031",
                "link": "https://www.gvokeglucagon.com/savings-and-support",
                "description": "Gvoke PFS Copay Card: Eligible commercially insured patients may pay as little as $0 per prescription; for additional information contact the program at 877-694-8653.",
                "brand": "Gvoke PFS",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b031"
            },
            {
                "_id": "6297bcade0e0db6c2572b763",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Vizimpro Pfizer Oncology Together Mail-In Rebate: If a pharmacy does not accept the Savings Card the patient may submit a request for a rebate in connection with the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Vizimpro",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "2500",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.34,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b763"
            },
            {
                "_id": "6297bcace0e0db6c2572b036",
                "link": "https://www.pfizermedicalinformation.com/en-us/halcion",
                "description": "Halcion Samples: Your healthcare provider may request samples from PfizerPro by visiting the website or calling 800-505-4426.",
                "brand": "Halcion",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b036"
            },
            {
                "_id": "6297bcace0e0db6c2572b03b",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Apple Cider Vinegar Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b03b"
            },
            {
                "_id": "6297bcace0e0db6c2572b040",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Clinical Strength Dandruff Defense plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b040"
            },
            {
                "_id": "6297bcace0e0db6c2572b045",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Itchy Scalp Care Anti-Dandruff Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b045"
            },
            {
                "_id": "6297bcace0e0db6c2572b04a",
                "link": "https://www.pggoodeveryday.com/signup/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Head and Shoulders Products!",
                "brand": "Head and Shoulders Volume Boost Anti-Dandruff Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b04a"
            },
            {
                "_id": "6297bcace0e0db6c2572b04f",
                "link": "https://www.hemangeol.com/parent/how-to-get-pierre-fabre",
                "description": "Hemangeol Co-Pay Savings Program: Eligible commercially insured patients may pay no more than $47 per bottle; offer valid for 12 fills; for additional information contact the program at 844-728-3479.",
                "brand": "Hemangeol",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b04f"
            },
            {
                "_id": "6297bcace0e0db6c2572b054",
                "link": "https://hemlibracopay.com/",
                "description": "Hemlibra Co-pay Program: Eligible commercially insured patients may pay $5 per copay with savings of up to $15,000 per year; for additional information contact the program at 844-436-2672.",
                "brand": "Hemlibra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b054"
            },
            {
                "_id": "6297bcade0e0db6c2572b768",
                "link": "https://www.vonvendi.com/vonvendi-support",
                "description": "Vonvendi Takeda HSC CoPay Assistance Program: Eligible commercially insured patients' eligible out-of-pocket costs to be covered 100% by the program; for additional information contact the program at 888-229-8379.",
                "brand": "Vonvendi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b768"
            },
            {
                "_id": "6297bcace0e0db6c2572b059",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Herceptin Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Herceptin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b059"
            },
            {
                "_id": "6297bcade0e0db6c2572b76d",
                "link": "https://www.vpriv.com/patient-support/onepath-product-support",
                "description": "VPRIV OnePath Co-Pay Assistance Program: Eligible commercially insured patients may be able may save on certain out-of-pocket treatment costs; for additional information contact the program at 866-888-0660.",
                "brand": "VPRIV",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b76d"
            },
            {
                "_id": "6297bcace0e0db6c2572b05e",
                "link": "https://www.hizentra.com/copay-assistance",
                "description": "Hizentra Co-Pay Assistance Program: Eligible commercially insured patients may pay $0 on monthly out-of-pocket expenses; for additional information contact the program at 877-355-4447.",
                "brand": "Hizentra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b05e"
            },
            {
                "_id": "6297bcade0e0db6c2572b772",
                "link": "https://www.vumerityhcp.com/en_us/home/access/quickstart.html",
                "description": "Vumerity QuickStart Program: Eligible commercially insured patients may be able to receive up to a 4-month supply of doses while insurance is confirmed; for more information contact the program at 800-456-2255.",
                "brand": "Vumerity",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "4",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b772"
            },
            {
                "_id": "6297bcace0e0db6c2572b063",
                "link": "https://www.hollister.com/en/samplecheckoutpage",
                "description": "Hollister Wound Product Samples: Request FREE samples of Hollister Wound products by completing the online form; may request multiple product samples; for additional information contact the program at 888-808-7456.",
                "brand": "Hollister Wound Care Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b063"
            },
            {
                "_id": "6297bcade0e0db6c2572b777",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Vytone Artesa Labs Advantage Program: Eligible commercially insured/Rx not covered patients may pay as little as $45 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Vytone Cream",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b777"
            },
            {
                "_id": "6297bcace0e0db6c2572b068",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog Jr KwikPen: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog Junior KwikPen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b068"
            },
            {
                "_id": "6297bcade0e0db6c2572b77c",
                "link": "https://www.bauschaccessprogram.com",
                "description": "Vyzulta Bausch + Lomb Access Program: Eligible commercially insured patients may pay no more than $25 per prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Vyzulta",
                "copay": "",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b77c"
            },
            {
                "_id": "6297bcace0e0db6c2572b06d",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog Mix 50-50: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog Mix 50-50",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b06d"
            },
            {
                "_id": "6297bcade0e0db6c2572b781",
                "link": "https://wellbutrin.copaysavingsprogram.com/",
                "description": "Wellbutrin XL Co-Pay Savings Card: Eligible commercially insured patients may pay as low as $5 for each 30-day prescription; offer may be used 12 times; for additional information contact the program at 855-859-2966.",
                "brand": "Wellbutrin XL",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b781"
            },
            {
                "_id": "6297bcace0e0db6c2572b072",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog Mix 75-25 KwikPen: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog Mix 75-25 Pen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b072"
            },
            {
                "_id": "6297bcace0e0db6c2572b077",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humalog U-200 KwikPen: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humalog U-200 KwikPen",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b077"
            },
            {
                "_id": "6297bcade0e0db6c2572b786",
                "link": "https://epihealth.com/rebate/",
                "description": "Wynzora Patient Access Card: Eligible commercially insured patients may pay as little as $0 per fill; for additional information contact the program at 855-631-2485.",
                "brand": "Wynzora",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b786"
            },
            {
                "_id": "6297bcade0e0db6c2572b78b",
                "link": "https://www.pfizeroncologytogether.com/patient/signup",
                "description": "Xalkori Pfizer Oncology Together Co-Pay Savings Program (oral products): Eligible commercially insured patients may pay as little as $0 per prescription with savings of up to $25,000 per calendar year; for additional information contact the program at 877-744-5675.",
                "brand": "Xalkori",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b78b"
            },
            {
                "_id": "6297bcade0e0db6c2572b790",
                "link": "https://www.xarelto-us.com/xarelto-cost#savings-registration",
                "description": "Xarelto Janssen Select: Eligible commercial or government-insured,  including Medicare Part D patients may pay $85 per month (plus sales tax if applicable); Ships directly to patients home; for additional assistance contact the program at 888-927-3586.",
                "brand": "Xarelto",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b790"
            },
            {
                "_id": "6297bcade0e0db6c2572b795",
                "link": "https://www.xeljanz.com/savings-and-support/savings-faqs",
                "description": "Xeljanz Co-Pay Savings Card Rebate: Eligible commercially insured patients may submit a rebate if their pharmacy does not accept the Savings Card; for additional information contact the program at 844-935-5269.",
                "brand": "Xeljanz",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b795"
            },
            {
                "_id": "6297bcade0e0db6c2572b79a",
                "link": "https://www.xeljanz.com/savings-and-support?ind=ra",
                "description": "Xeljanz XR Co-Pay Savings Card: Eligible commercially patients may pay $0 out-of-pocket costs per month with a maximum benefit of $15,000 per calendar year; for additional information contact the program at 844-935-5269.",
                "brand": "Xeljanz XR",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b79a"
            },
            {
                "_id": "6297bcade0e0db6c2572b79f",
                "link": "https://www.xenleta.com/request-rep",
                "description": "Xenleta Samples: Healthcare providers may request samples for their practice by filling out a form online or contact a representative.",
                "brand": "Xenleta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b79f"
            },
            {
                "_id": "6297bcade0e0db6c2572b7a4",
                "link": "https://amgenfirststep.com/register-card",
                "description": "Xgeva Amgen First Step Program: Eligible commercially insured patients may save on their out-of-pocket medication costs; for additional information contact the program at 888-427-7478.",
                "brand": "Xgeva",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7a4"
            },
            {
                "_id": "6297bcade0e0db6c2572b7a9",
                "link": "https://peyronies-disease.xiaflex.com/patient/paying-for-xiaflex/",
                "description": "Xiaflex Copay Assistance Program (patients with Peyronies Disease): Most eligible patients may pay $0 per injection with savings of up to $1200; for additional information contact the program at 800-743-2382.",
                "brand": "Xiaflex",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1200,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7a9"
            },
            {
                "_id": "6297bcace0e0db6c2572b07c",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humulin 70-30: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humulin 70-30",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b07c"
            },
            {
                "_id": "6297bcace0e0db6c2572b081",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humulin N: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humulin N",
                "copay": "Yes",
                "bin": "18884",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b081"
            },
            {
                "_id": "6297bcade0e0db6c2572b7ae",
                "link": "https://www.azmedcoupons.com",
                "description": "Xigduo XR Free Trial Offer: Eligible patients may receive a 30-day supply for FREE; for additional information contact the program at 844-631-3978.",
                "brand": "Xigduo XR",
                "copay": "No",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7ae"
            },
            {
                "_id": "6297bcade0e0db6c2572b7b3",
                "link": "https://ximinorx.com/rebate-card/",
                "description": "Ximino Copay Savings Program: Eligible commercially insured patients may pay as little as $7 per fill; card can be used 12 times; for additional information contact the program at 888-786-5876.",
                "brand": "Ximino",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7b3"
            },
            {
                "_id": "6297bcace0e0db6c2572b086",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Humulin R U-500: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Humulin R U-500",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b086"
            },
            {
                "_id": "6297bcade0e0db6c2572b7b8",
                "link": "https://xolaircopay.com",
                "description": "Xolair (Injection) Co-Pay Card Program: Eligible commercially insured patients may pay as little as $5 per injection out-of-pocket cost with savings of up to $1,000 per 12-month period; for further assistance contact the program at 855-965-2472.",
                "brand": "Xolair",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1000",
                "lifetime_uses": "12",
                "max_saving_transaction": 84,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7b8"
            },
            {
                "_id": "6297bcace0e0db6c2572b08b",
                "link": "https://www.hyqvia.com/support-resources/onepath",
                "description": "Hyqvia OnePath Co-Pay Assistance Program: Eligible commercially insured patients may be able to save on certain out-of-pocket treatment costs; for additional information contact the program at 866-888-0660.",
                "brand": "HyQvia",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b08b"
            },
            {
                "_id": "6297bcade0e0db6c2572b7bd",
                "link": "https://xospata.com/patient-assistance",
                "description": "Xospata Patient Savings Program: Eligible commercially insured patients may pay as little as $0 per prescription; for additional information contact the program at 844-632-9272.",
                "brand": "Xospata",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7bd"
            },
            {
                "_id": "6297bcace0e0db6c2572b090",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Ibrance Pfizer Oncology Together Mail-In Rebate: If a pharmacy does not accept the Savings Card the patient may submit a request for a rebate in connection with the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Ibrance",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b090"
            },
            {
                "_id": "6297bcace0e0db6c2572b095",
                "link": "https://www.icyhot.com/coupons/",
                "description": "Save $1 on any one IcyHot product (excludes 1 count patch products and 1.25 oz cream products); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "IcyHot Advanced Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b095"
            },
            {
                "_id": "6297bcade0e0db6c2572b7c2",
                "link": "https://www.xulane.com/en/hcp/savings-and-resources",
                "description": "Xulane Samples: Healthcare providers may request samples for their practice by filling out an order form and faxing it to 304-285-6418.",
                "brand": "Xulane",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7c2"
            },
            {
                "_id": "6297bcade0e0db6c2572b7c7",
                "link": "https://jazzcares.com/patients/xyrem/",
                "description": "Xyrem Quick Start Voucher: Eligible commercially insured patients who are experiencing an insurance delay longer than 10 calendar days may receive a 1 month supply of medication for free; for additional information contact the program at 833-533-5299.",
                "brand": "Xyrem",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7c7"
            },
            {
                "_id": "6297bcace0e0db6c2572b09a",
                "link": "https://www.icyhot.com/coupons/",
                "description": "Save $1 on any one IcyHot product (excludes 1 count patch products and 1.25 oz cream products); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "IcyHot Dry Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b09a"
            },
            {
                "_id": "6297bcade0e0db6c2572b7cc",
                "link": "https://jazzcares.com/patients/xywav/",
                "description": "Xywav Quick Start Voucher: Eligible commercially insured patients who are experiencing an insurance delay longer than 10 calendar days may receive a 1 month supply of medication for free; for additional information contact the program at 833-533-5299.",
                "brand": "Xywav",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7cc"
            },
            {
                "_id": "6297bcace0e0db6c2572b09f",
                "link": "https://www.icyhot.com/coupons/",
                "description": "Save $1 on any one IcyHot product (excludes 1 count patch products and 1.25 oz cream products); coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "IcyHot Lidocaine Patch",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b09f"
            },
            {
                "_id": "6297bcade0e0db6c2572b7d1",
                "link": "https://www.activatethecard.com/7702/",
                "description": "Yonsa Co-pay Savings Program: Eligible commercially insured patients may pay as little as $10 per prescription fill with a maximum savings of $5000 per fill; maximum savings of $12,000 per calendar year allowed under the program; for additional information contact the program at 855-984-6307.",
                "brand": "Yonsa",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 5000,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7d1"
            },
            {
                "_id": "6297bcace0e0db6c2572b0a4",
                "link": "https://www.idelvion.com/savings-support/free-trial",
                "description": "Idelvion FREE Trial Program: Eligible insured patients (including patients with Medicare & Medicad) may receive a 30-day supply at no cost; for additional information contact the program at 800-676-4266.",
                "brand": "Idelvion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0a4"
            },
            {
                "_id": "6297bcade0e0db6c2572b7d6",
                "link": "https://www.zegeridotc.com/coupon-interstitial",
                "description": "Register your email address and receive a coupon for savings on any Zegerid OTC product 14ct. or larger; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Zegerid OTC",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7d6"
            },
            {
                "_id": "6297bcace0e0db6c2572b0a9",
                "link": "https://www.ilumya.com/help-from-our-team/",
                "description": "",
                "brand": "Ilumya",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0a9"
            },
            {
                "_id": "6297bcace0e0db6c2572b0ae",
                "link": "https://amgenfirststep.com/register-card",
                "description": "Imlygic Amgen First Step Program: Eligible commercially insured patients may save on their out-of-pocket medication costs; for additional information contact the program at 888-427-7478.",
                "brand": "Imlygic",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0ae"
            },
            {
                "_id": "6297bcace0e0db6c2572b0b3",
                "link": "https://mycareclubrewards.com/imodium/?utm_medium=partner&utm_source=imodium.com&utm_content=footer&utm_campaign=cc_main&_gl=1*rj31n4*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjY1MDQ3OC41LjEuMTYzNjY1MDUzMC44*_fplc*SyUyQnh2V1hwcnlYTG1ONHJlN0g5NjE5OE93WG1hd21tWkI3RktEcGtsdTMlMkZtU3hsVGxjZ1VVMU92MUVzclZEakU5JTJGYXJPJTJGSXVqbkZBUWFNazRYa0dSZkE2YkdLWGxKdzdjNGhVaFplV3JzNTg3aVpJUEhOTmRYJTJGRmlId2RIdyUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Imodium A-D products with registration.",
                "brand": "Imodium A-D Softgel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0b3"
            },
            {
                "_id": "6297bcace0e0db6c2572b0b8",
                "link": "https://www.ipsencares.com/increlex-patient-support/",
                "description": "Increlex Ipsen Cares Copay Assistance Program: Eligible commercially insured patients may pay as little as $0 per prescription with a maximum savings of $12,000 per calendar year; for additional information contact the program at 866-435-5677.",
                "brand": "Increlex",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0b8"
            },
            {
                "_id": "6297bcace0e0db6c2572b0bd",
                "link": "https://www.pfizerencompass.com/patient/inflectra/right-patient-support",
                "description": "Inflectra enCompass Co-Pay Assistance Program: Eligible commercially insured patients may pay $0 co-pay per prescription with savings of up to $20,000 per calendar year; for additional information contact the program at 844-722-6672.",
                "brand": "Inflectra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0bd"
            },
            {
                "_id": "6297bcace0e0db6c2572b0c2",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Inlyta Pfizer Oncology Together Co-Pay Savings Program Rebate (oral products): Eligible commercially insured patients may submit a request for a rebate in connection with this offer if their pharmacy does not accept the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Inlyta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0c2"
            },
            {
                "_id": "6297bcade0e0db6c2572b7db",
                "link": "https://www.copayassistancenow.com/eligibility",
                "description": "Zelboraf Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Zelboraf",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7db"
            },
            {
                "_id": "6297bcade0e0db6c2572b7e0",
                "link": "https://www.zenpep.com/savings-programs?__cf_chl_jschl_tk__=pmd_c3133cd67e8afc9608cdc85375f2f29035dcf7e0-1627573646-0-gqNtZGzNAeKjcnBszQdi",
                "description": "Zenpep Savings Card: Eligible commercially insured patients may pay as low as $0 per prescription fill; nutritional supplements included with program; for additional information contact the program at 855-706-8717.",
                "brand": "Zenpep",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7e0"
            },
            {
                "_id": "6297bcade0e0db6c2572b7e5",
                "link": "https://jazzcares.com/patients/zepzelca/",
                "description": "Zepzelca Savings Card: Eligible commercially insured patients may pay as little as $10 per prescription; for additional information contact the program at 833-533-5299.",
                "brand": "Zepzelca",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7e5"
            },
            {
                "_id": "6297bcade0e0db6c2572b7ea",
                "link": "https://myviivcard.com/rebate-form-instructions.html",
                "description": "Ziagen ViiVConnect Rebate: Eligible commercially insured patients may be eligible for a rebate for the amount paid out of pocket per prescription if the pharmacy does not accept the savings card; rebate may be completed online or by downloading and mailing in rebate form; for additional information contact the program at 844-588-3288.",
                "brand": "Ziagen",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7ea"
            },
            {
                "_id": "6297bcade0e0db6c2572b7ef",
                "link": "https://www.merckaccessprogram-zinplava.com/hcc/prescription-copay-assistance-program/",
                "description": "Zinplava Merck Co-Pay Assistance Program: Eligible commercially insured patients will pay $100 per infusion and with a maximum benefit of $3,700 for one vial per calendar year; if 2 vials are required for one infusion then the maximum benefit is $7,500 per calendar year; for additional information contact the program at 877-709-4455.",
                "brand": "Zinplava",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7ef"
            },
            {
                "_id": "6297bcade0e0db6c2572b7f4",
                "link": "https://www.pfizercopay.com/",
                "description": "Zirabev Pfizer Oncology Together Co-Pay Savings Program for Injectables: Eligible commercially insured patients may pay as little as $0 per treatment; maximum savings limits per calendar year apply; for more information contact the program at 877-744-5675.",
                "brand": "Zirabev",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7f4"
            },
            {
                "_id": "6297bcade0e0db6c2572b7f9",
                "link": "https://www.activatethecard.com/7526/?#",
                "description": "Zoladex Co-pay Card: Eligible cash-paying patients may save up to $300 per monthly prescription; for additional information contact the program at 844-864-3014.",
                "brand": "Zoladex",
                "copay": "Yes",
                "bin": "61054",
                "rxpcn": "Loyalty",
                "copay_max": "3600",
                "lifetime_uses": "12",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7f9"
            },
            {
                "_id": "6297bcade0e0db6c2572b7fe",
                "link": "https://myzolpimist.com/#rxconnect-section",
                "description": "ZolpiMist Aytu RxConnect Pharmacy Network Program: Eligible commercially insured patients/RX not covered may pay as little as $10 per 30-day prescription when using a Aytu RxConnect Pharmacy; for additional information contact the program at 877-675-6590.",
                "brand": "ZolpiMist",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7fe"
            },
            {
                "_id": "6297bcade0e0db6c2572b803",
                "link": "https://www.zomigsavings.com/",
                "description": "Zomig (Nasal Spray) Savings Card: Eligible patients may pay as little as $25 per prescription; for additional information contact the program at 866-293-5817.",
                "brand": "Zomig",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b803"
            },
            {
                "_id": "6297bcade0e0db6c2572b808",
                "link": "https://www.saveonmyprescription.com/copay.jsp",
                "description": "Zortress Co-Pay Card: Eligible commercially insured patients may pay $0 per prescription with maximum annual savings of up to $7200; for additional information contact the program at 877-952-1000.",
                "brand": "Zortress",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "7200",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b808"
            },
            {
                "_id": "6297bcade0e0db6c2572b80d",
                "link": "https://www.zubsolv.com/resources/patient-savings/",
                "description": "Zubsolv FREE Trial Offer: Eligible patients are able to redeem up to 2 15-tablet vouchers per lifetime; new patients can redeem the 2 vouchers at the same time for a 30-count fill or 1 voucher now for a 15-count fill and a second voucher later for an additional 15-count fill; for additional information contact the program at 877-264-2440.",
                "brand": "Zubsolv",
                "copay": "No",
                "bin": "610524",
                "rxpcn": "1016",
                "copay_max": "0",
                "lifetime_uses": "2",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b80d"
            },
            {
                "_id": "6297bcade0e0db6c2572b812",
                "link": "https://copay.novartisoncology.com/",
                "description": "Zykadia Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Zykadia",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b812"
            },
            {
                "_id": "6297bcace0e0db6c2572b0c7",
                "link": "https://www.bmsaccesssupport.bmscustomerconnect.com/patient/financial-support",
                "description": "Inrebic BMS Oncology Co-pay Assistance Program: Eligible commercially insured patients' may pay a $10 copay per 30-day prescription with savings of up to $15,000 per calendar year; for additional information contact the program at 800-861-0048.",
                "brand": "Inrebic",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0c7"
            },
            {
                "_id": "6297bcace0e0db6c2572b0cc",
                "link": "https://uspco.com/product_literature.aspx?pid=66&tab=Contact",
                "description": "Integra F Samples: Healthcare providers may request samples for their practice by filling out a form online.",
                "brand": "Integra F",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0cc"
            },
            {
                "_id": "6297bcace0e0db6c2572b0d1",
                "link": "https://www.janssencarepath.com/patient/intelence/cost-support",
                "description": "Intelence Janssen CarePath Savings Program: Most eligible commercially insured patients may pay $0 per fill on out-of-pocket costs with savings of up to $7500 per calendar year; for additional assistance contact the program at 866-836-0114.",
                "brand": "Intelence",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "7500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0d1"
            },
            {
                "_id": "6297bcace0e0db6c2572b0d6",
                "link": "https://www.janssencarepath.com/patient/invega-sustenna/cost-support",
                "description": "Invega Sustenna Janssen CarePath Savings Program Rebate: If the pharmacy or doctor cannot process the Savings Program Card commercially insured patients may still take advantage of the offer by submitting a rebate request; for additional information contact the program at 877-227-3728.",
                "brand": "Invega Sustenna",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "8000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0d6"
            },
            {
                "_id": "6297bcace0e0db6c2572b0db",
                "link": "https://portal.trialcard.com/kala/inveltys/",
                "description": "Inveltys Copay Card: Eligible commercially insured patients may pay as little as $40 per prescription; offer valid for up to 6 prescriptions; additional information contact the program at  877-454-8063.",
                "brand": "Inveltys",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0db"
            },
            {
                "_id": "6297bcace0e0db6c2572b0e0",
                "link": "http://wcpharma.com/patient-savings/",
                "description": "Irospan 24/6 Women's Choice Savings Coupon: Eligible commercially insured patients may pay no more than $35 per prescription; for additional information contact the program at 800-664-1490.",
                "brand": "Irospan 24-6",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0e0"
            },
            {
                "_id": "6297bcace0e0db6c2572b0e5",
                "link": "https://www.isentress.com/what-is-isentress/?src=google&med=cpc&camp=Isentress_Isentress_BRND_NA_ENGM_EXCT_TEXT_MULTI&adgrp=Isentress+General&kw=isentress+hd&gclid=CjwKCAiA_9r_BRBZEiwAHZ_v1yuPUl1AXfqO8Zk5R0rm4dAMfMIiCjTX4q9oAt_Z3B5Uv4IltAnFXxoCZ44QAvD_BwE&gclsrc=aw.ds",
                "description": "Isentress HD Savings Coupon Rebate: If a commercially insured patient is unable to redeem the savings card at their retail or mail-order pharmacy the patient should contact the program within 30 days to request a Direct Member Reimbursement form for a rebate; for additional information contact program at 877-264-2454.",
                "brand": "Isentress HD",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6800",
                "lifetime_uses": "12",
                "max_saving_transaction": 566.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0e5"
            },
            {
                "_id": "6297bcace0e0db6c2572b0ea",
                "link": "https://www.ixinity.com/starting-ixinity/free-trial-of-ixinity/",
                "description": "Ixinity Free Trial Program: Eligible patients will receive a 1-month supply treatment up to 20,000 IU for FREE; for additional information contact the program at 855-494-6489.",
                "brand": "Ixinity",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0ea"
            },
            {
                "_id": "6297bcace0e0db6c2572b0ef",
                "link": "https://www.janumetxr.com/",
                "description": "Janumet Savings Coupon: Eligible commercially insured patients may pay as little as $5 on each of up to 12 prescriptions with savings of up to $150 per month; for additional information contact the program at 877-264-2454.",
                "brand": "Janumet",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0ef"
            },
            {
                "_id": "6297bcace0e0db6c2572b0f4",
                "link": "https://www.janumetxr.com/",
                "description": "Janumet XR Savings Coupon: Eligible commercially insured patients may pay as little as $5 on each of up to 12 prescriptions with savings of up to $150 per month; for additional information contact the program at 877-264-2454.",
                "brand": "Janumet XR",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0f4"
            },
            {
                "_id": "6297bcace0e0db6c2572b0f9",
                "link": "https://www.jelmyto.com/patient/resources/",
                "description": "Jelmyto UroGen Support CoPay Program: Eligible commercially insured patients may pay no more than $50 per dose; for additional information contact the program directly at 855-535-6986.",
                "brand": "Jelmyto",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0f9"
            },
            {
                "_id": "6297bcace0e0db6c2572b0fe",
                "link": "https://www.sanoficareassist.com/jevtana/copay",
                "description": "Jevtana CareASSIST Copay Program Reimbursement Program: Eligible commercially insured patients may submit a reimbursement form if their pharmacy or provider does not participate in the Savings Program; for additional information contact the program at 833-930-2273.",
                "brand": "Jevtana",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b0fe"
            },
            {
                "_id": "6297bcade0e0db6c2572b817",
                "link": "https://patient.zypitamag.com/dual-savings-card",
                "description": "Zypitamag Dual Savings Card: Eligible commercially insured patients may receive their 1st fill for FREE and then pay as little as $10 per 30-day supply with savings of up to $85 per month; for additional information contact the program at 866-643-1524.",
                "brand": "Zypitamag",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "1020",
                "lifetime_uses": "12",
                "max_saving_transaction": 85,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b817"
            },
            {
                "_id": "6297bcade0e0db6c2572b81c",
                "link": "https://www.zyrtec.com/?action=sign-up",
                "description": "Receive a coupon for any one Zyrtec product and other exclusive offers  with sign up; 1 coupon per purchase.",
                "brand": "Zyrtec",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b81c"
            },
            {
                "_id": "6297bcade0e0db6c2572b821",
                "link": "https://www.zyrtecprofessional.com/order",
                "description": "Zyrtec Samples: Healthcare providers may order samples for their practice by visiting the website.",
                "brand": "Zyrtec Childrens Dissolve Tabs",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b821"
            },
            {
                "_id": "6297bcace0e0db6c2572b103",
                "link": "https://copaysupport.bayer.com/faqs",
                "description": "Jivi $0 Product Co-Pay Program Rebate: If the pharmacy does not accept the offer eligible commercially insured patients may receive a rebate for their out-of-pocket cost; for additional information contact the program at 647-245-5619.",
                "brand": "Jivi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b103"
            },
            {
                "_id": "6297bcace0e0db6c2572b108",
                "link": "https://www.orthorxaccess.com/",
                "description": "Jublia Rx Access Program: Eligible commercially insured patients/RX not covered  may pay no more than $65 per prescription; valid for 12 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Jublia",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b108"
            },
            {
                "_id": "6297bcace0e0db6c2572b10d",
                "link": "https://www.alle.com/upgrade",
                "description": "Juvederm Volbella XC Alle Points Program: Register and earn points redeemable for dollars OFF certain in-office treatments; also receive personalized special offers; for additional information contact the program at 888-912-1572.",
                "brand": "Juvederm Volbella XC",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b10d"
            },
            {
                "_id": "6297bcace0e0db6c2572b112",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Kadcyla Genentech Oncology Co-pay Assistance Program Rebate:Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Kadcyla",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b112"
            },
            {
                "_id": "6297bcace0e0db6c2572b117",
                "link": "https://amgenfirststep.com",
                "description": "Kanjiniti Amgen First Step Program Rebate: Eligible commercially insured patients who have already paid for treatment may request a rebate check; for additional information contact the program at 888-657-8371.",
                "brand": "Kanjinti",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b117"
            },
            {
                "_id": "6297bcace0e0db6c2572b11c",
                "link": "https://karbinaler.com",
                "description": "Karbinal ER  Aytu RxConnect Pharmacy Network Program: Eligible uninsured/cash-paying patients may save $100 on each 30-day prescription when using a Aytu RxConnect Pharmacy; for additional information contact the program at 877-675-6590.",
                "brand": "Karbinal ER",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b11c"
            },
            {
                "_id": "6297bcace0e0db6c2572b121",
                "link": "https://www.kerasal.com/pages/hcp",
                "description": "Healthcare professionals may request samples of Kerasal Intensive Foot Repair for their office by filling out an online form.",
                "brand": "Kerasal Intensive Foot Repair",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b121"
            },
            {
                "_id": "6297bcade0e0db6c2572b826",
                "link": "https://www.zyrtec.com/?action=sign-up",
                "description": "Receive a coupon for any one Zyrtec product and other exclusive offers  with sign up; 1 coupon per purchase.",
                "brand": "Zyrtec Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b826"
            },
            {
                "_id": "6297bcade0e0db6c2572b82b",
                "link": "https://www.zyrtec.com/?action=sign-up",
                "description": "Receive a coupon for any one Zyrtec product and other exclusive offers  with sign up; 1 coupon per purchase.",
                "brand": "Zyrtec Soothing Non-Medicated Face Wipes",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b82b"
            },
            {
                "_id": "6297bcade0e0db6c2572b830",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for ZzzQuil Products!",
                "brand": "ZzzQuil Alcohol Free Liquid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b830"
            },
            {
                "_id": "6297bcade0e0db6c2572b835",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for ZzzQuil Products!",
                "brand": "ZzzQuil Ultra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b835"
            },
            {
                "_id": "6297bcace0e0db6c2572b126",
                "link": "https://www.medimetriks.com/prescription-brands/ketodan-kit-ketodan-foam",
                "description": "Ketodan Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Ketodan",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b126"
            },
            {
                "_id": "6297bcace0e0db6c2572b12b",
                "link": "https://acrotechpatientaccess.com",
                "description": "Khapzory STAR Copay Assistance Program: Eligible commercially insured patients will pay $0 copay for the 1st date of service and a $25 copay for  subsequent dates of service; maximum savings of $10,000 per calendar year; for additional information contact the program at 888-537-8277.",
                "brand": "Khapzory",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b12b"
            },
            {
                "_id": "6297bcace0e0db6c2572b130",
                "link": "https://copay.novartisoncology.com/",
                "description": "Kisqali Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Kisqali",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b130"
            },
            {
                "_id": "6297bcace0e0db6c2572b135",
                "link": "https://almiralladvantage.com",
                "description": "Klisyri Savings Offer: Eligible commercially insured patients/RX not covered may pay as little as $60 per fill when using an in-network pharmacy and pay as little as $100 per fill when using an out-of-network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Klisyri",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b135"
            },
            {
                "_id": "6297bcace0e0db6c2572b13a",
                "link": "https://copaysupport.bayer.com/faqs",
                "description": "Kogenate FS $0 Co-Pay Program Rebate: If the pharmacy does not accept the offer eligible commercially insured patients may receive a rebate for their out-of-pocket cost; for additional information contact the program at 647-245-5619.",
                "brand": "Kogenate FS",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b13a"
            },
            {
                "_id": "6297bcace0e0db6c2572b13f",
                "link": "https://www.myaccess360.com/patient/koselugo-selumetinib/patient-affordability.html",
                "description": "Koselugo Access 360 Co-pay Savings Program: Eligible commercially insured patients may pay $0 per 30-day prescription with a maximum savings of up to $26,000 per year; for additional information contact the program at 844-275-2360.",
                "brand": "Koselugo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "26000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2166.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b13f"
            },
            {
                "_id": "6297bcace0e0db6c2572b144",
                "link": "https://www.kristalose.com",
                "description": "Kristalose Instant Savings Program: Eligible commercially insured/cash-paying patients may pay no more than $5 per fill with savings of up to $125 per 30-day prescription; patients may download and redeem a new savings offer each month; for additional information contact the program at 855-584-6194.",
                "brand": "Kristalose",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "1500",
                "lifetime_uses": "12",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b144"
            },
            {
                "_id": "6297bcace0e0db6c2572b149",
                "link": "https://www.alle.com/upgrade",
                "description": "Kybella Alle Points Program: Register and earn points redeemable for dollars OFF certain in-office treatments; also receive personalized special offers; for additional information contact the program at 888-912-1572.",
                "brand": "Kybella",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b149"
            },
            {
                "_id": "6297bcace0e0db6c2572b14e",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Lacrisert Bausch + Lomb Access Program: Eligible commercially insured patients may pay no more than $25 for each prescription fill; for additional information contact the program 866-693-4880.",
                "brand": "Lacrisert",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b14e"
            },
            {
                "_id": "6297bcace0e0db6c2572b153",
                "link": "https://www.savingscard.bayer.com",
                "description": "Lampit Bayer Savings Card Program: Eligible commercially insured patients may pay as little as $0 co-pay per prescription with savings of up to $1000 per fill; for additional assistance contact the program at 866-203-3503.",
                "brand": "Lampit",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b153"
            },
            {
                "_id": "6297bcace0e0db6c2572b158",
                "link": "https://www.lantus.com/sign-up-for-savings",
                "description": "Lantus Sanofi Copay Program: Eligible commercially insured patients may pay as little as $0 and no more than $99 for a 30-day supply; valid up to 10 packs per fill; for additional information contact the program at 866-251-4750.",
                "brand": "Lantus SoloSTAR Pen",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b158"
            },
            {
                "_id": "6297bcace0e0db6c2572b15d",
                "link": "https://www.lantus.com/sign-up-for-savings",
                "description": "Lantus Sanofi Copay Program: Eligible commercially insured patients may pay as little as $0 and no more than $99 for a 30-day supply; valid up to 10 packs per fill; for additional information contact the program at 866-251-4750.",
                "brand": "Lantus U-100",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b15d"
            },
            {
                "_id": "6297bcace0e0db6c2572b162",
                "link": "https://www.latuda.com/bpd/latuda-savings-and-support.html#signup",
                "description": "Latuda Copay Savings Card: Eligible commercially insured patients may pay as little as $0 for the first 30-day prescription fill and then $10 per 30-day refills with savings up to $400 on each fill; offer valid 12 times per calendar year; for additional information contact the program at 855-552-8832.",
                "brand": "Latuda",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 400,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b162"
            },
            {
                "_id": "6297bcace0e0db6c2572b167",
                "link": "https://www.lenvima.com/financial-help",
                "description": "Lenvima $0 Co-pay Program: Eligible commercially insured patients may pay as little as $0 per prescription with a maximum savings of $40,000 per year on out-of-pocket costs; for additional information contact the program at 866-613-4724.",
                "brand": "Lenvima",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "40000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b167"
            },
            {
                "_id": "6297bcace0e0db6c2572b16c",
                "link": "http://lexette.com/savings",
                "description": "Lexette Mayne Pharma Patient Savings Card: Eligible commercially insured patients may have a copay of $0 per prescription; for additional information contact the program at 347-442-7919.",
                "brand": "Lexette",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b16c"
            },
            {
                "_id": "6297bcace0e0db6c2572b171",
                "link": "https://www.gummyvites.com/en/Coupons2",
                "description": "Save $2 off any L'il Critters product when you signup online with your email address.",
                "brand": "Lil Critters Gummie Vitamin Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b171"
            },
            {
                "_id": "6297bcace0e0db6c2572b176",
                "link": "https://www.lipitor.com/savings-terms",
                "description": "Lipitor Savings Card Rebate: Eligible patients may submit a request for a rebate if their pharmacy does not accept the Savings Card; for additional information contact the program at 800-314-7957.",
                "brand": "Lipitor",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b176"
            },
            {
                "_id": "6297bcace0e0db6c2572b17b",
                "link": "https://mycareclubrewards.com/listerine/?utm_medium=partner&utm_source=listerine.com&utm_content=banner&utm_campaign=cc_main&_gl=1*1srhw77*_ga*MjAwOTYxNTY1My4xNjM2OTk3MjUw*_ga_13VEM6N66E*MTYzNzA4NTM0NS41LjEuMTYzNzA4NjM4MC41OA..*_fplc*NklTRGQ1UjNacSUyQkh0aCUyRlVLJTJGelFxeUZEZVJvWSUyQk5pd1RDcWVwakRqbmZURSUyQndhcyUyRkZmV2ltZ0c5R3pqOFNBR0gxRmRZQ25oSEk2eWhMNUhXNHVyRkliTDE5bWFZcyUyRnNiRTVSZldXaDJ2MDh6d1RlRHlCVzFHblphJTJCa2dkdyUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Listerine products with registration.",
                "brand": "Listerine Sensitivity Mouthwash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b17b"
            },
            {
                "_id": "6297bcace0e0db6c2572b180",
                "link": "https://livmarlihcp.com",
                "description": "Livmarli MAP Savings Program: Eligible commercially insured patients may pay as little as $10 per fill; for additional information contact the program at 855-676-4968.",
                "brand": "Livmarli",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b180"
            },
            {
                "_id": "6297bcace0e0db6c2572b185",
                "link": "https://www.myaccess360.com/patient/lokelma/patient-affordability.html",
                "description": "Lokelma Access 360 Co-pay Savings Card: Eligible commercially insured patients may pay as little as $0 per 30-packet supply with a maximum savings of $350; offer may be used once a month for up to 12 months; for additional information contact the program at 844-565-3562.",
                "brand": "Lokelma",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "4200",
                "lifetime_uses": "12",
                "max_saving_transaction": 350,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b185"
            },
            {
                "_id": "6297bcace0e0db6c2572b18a",
                "link": "https://www.taihopatientsupport.com/financial-support",
                "description": "Lonsurf Taiho Oncology Patient Support Co-pay Assistance Program: Eligible commercially insured patients may pay $0 per treatment cycle; for additional information contact the program at 844-824-4648.",
                "brand": "Lonsurf",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b18a"
            },
            {
                "_id": "6297bcace0e0db6c2572b18f",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Lorbrena Pfizer Oncology Together Co-Pay Savings Program Rebate (oral products): Eligible commercially insured patients may submit a request for a rebate in connection with this offer if their pharmacy does not accept the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Lorbrena",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b18f"
            },
            {
                "_id": "6297bcace0e0db6c2572b194",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Lotemax (Gel) Bausch + Lomb Access Program: Eligible commercially insured/RX not covered and cash-paying patients may pay no more than $60 per prescription; for additional information contact the program 866-693-4880.",
                "brand": "Lotemax Gel",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b194"
            },
            {
                "_id": "6297bcace0e0db6c2572b199",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Lotemax SM Bausch + Lomb Access Program: Eligible cash-paying and commercially insured/RX not covered patients may pay no more than $60 per prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Lotemax SM",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b199"
            },
            {
                "_id": "6297bcace0e0db6c2572b19e",
                "link": "https://www.lotrimin.com/coupon",
                "description": "Sign up and save on any one Lotrimin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Lotrimin AF Athletes Foot Antifungal Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b19e"
            },
            {
                "_id": "6297bcace0e0db6c2572b1a3",
                "link": "https://www.lotrimin.com/coupon",
                "description": "Sign up and save on any one Lotrimin product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Lotrimin Ultra Athletes Foot Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1a3"
            },
            {
                "_id": "6297bcace0e0db6c2572b1a8",
                "link": "https://mycareclubrewards.com/lubriderm/?utm_medium=partner&utm_source=lubriderm.com&utm_content=header&utm_campaign=cc_main&_gl=1*10t4k0d*_ga*MTMzNjU1MjM2My4xNjIxMjg5NDk4*_ga_13VEM6N66E*MTYyMTI4OTQ5Ny4xLjEuMTYyMTI4OTg5My43",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Lubriderm products with registration.",
                "brand": "Lubriderm Intense Skin Repair Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1a8"
            },
            {
                "_id": "6297bcace0e0db6c2572b1ad",
                "link": "https://www.eyeoncopay.com/",
                "description": "Lucentis Genentech Ophthalmology Co-pay Program: Eligible commercially insured patients may pay $5 on out-of-pocket costs per treatment with savings of up to $15,000 per year for drug costs and a savings of up to $1,000 for injection costs; for additional information contact the program at 855-218-5307.",
                "brand": "Lucentis",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "16000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1ad"
            },
            {
                "_id": "6297bcace0e0db6c2572b1b2",
                "link": "https://www.lumifydrops.com/professional/ready-to-use-resources",
                "description": "Lumify Samples: Healthcare professionals may request samples for their office by calling 833-458-6439.",
                "brand": "Lumify",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1b2"
            },
            {
                "_id": "6297bcace0e0db6c2572b1b7",
                "link": "https://www.luprongyn.com/savings-and-support",
                "description": "Lupron Depot Savings Card (Patients with Anemia prior to Fibriod Surgery): Eligible commercially insured patients with Anemia prior to Fibriod Surgery may pay as little as $10 per dose with savings up to $125 per 3.75 mg dose; for additional assistance contact the program at 855-587-7663.",
                "brand": "Lupron Depot",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1b7"
            },
            {
                "_id": "6297bcace0e0db6c2572b1bc",
                "link": "https://www.myaccess360.com/patient/lynparza-olaparib/patient-affordability.html",
                "description": "Lynparza Access 360 Co-pay Savings Program: Eligible commercially insured patients may pay $0 per 30-day supply with out-of-pocket savings of up to $26,000 per year; for additional information contact the program at 844-275-2360.",
                "brand": "Lynparza",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "26000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2166.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1bc"
            },
            {
                "_id": "6297bcace0e0db6c2572b1c1",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Lyumjev: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Lyumjev",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1c1"
            },
            {
                "_id": "6297bcace0e0db6c2572b1c6",
                "link": "https://www.macrilencopay.com/login",
                "description": "Macrilen Co-Pay Savings Program: Eligible commercially insured patients may pay as little as $0 with savings of up to $1000 per year; for additional information contact the program at 844-622-2443.",
                "brand": "Macrilen",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1c6"
            },
            {
                "_id": "6297bcace0e0db6c2572b1cb",
                "link": "https://www.mavyret.com/savings-card",
                "description": "Mavyret Savings Card: Eligible commercially insured patients may pay as little as $5 per fill; for additional information contact the program at 877-628-9738.",
                "brand": "Mavyret",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1cb"
            },
            {
                "_id": "6297bcace0e0db6c2572b1d0",
                "link": "https://www.mederma.com/coupons/",
                "description": "Save $3 off any Mederma Scar Care product; one coupon per purchase.",
                "brand": "Mederma for Kids",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1d0"
            },
            {
                "_id": "6297bcace0e0db6c2572b1d5",
                "link": "https://copay.novartisoncology.com/",
                "description": "Mekinist Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Mekinist",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1d5"
            },
            {
                "_id": "6297bcace0e0db6c2572b1da",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Menopur OneHeart Program: Eligible commercially insured & cash-paying patients may be eligible to receive a $200 rebate for out-of-pocket costs incurred when using Menopur for their COS cycle between June 1, 2020 � December 31, 2021; for additional information contact the program at 888-337-7464.",
                "brand": "Menopur",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1da"
            },
            {
                "_id": "6297bcace0e0db6c2572b1df",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Menopur HEARTFELT Co-Pay Assistance Program: Eligible commercially insured patients whose COS cycles were canceled due to COVID or a natural disaster may qualify for a $250 rebate towards their copay costs already paid; timeframes for canceled cycles may be found on the application as well as timeframes for new cycles to begin; for additional information contact the program at 800-515-3784.",
                "brand": "Menopur",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "250",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1df"
            },
            {
                "_id": "6297bcace0e0db6c2572b1e4",
                "link": "https://www.metamucil.com/en-us/offers",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Metamucil Products!",
                "brand": "Metamucil Fiber Gummies",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1e4"
            },
            {
                "_id": "6297bcace0e0db6c2572b1e9",
                "link": "https://www.metamucil.com/en-us/offers",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Metamucil Products!",
                "brand": "Metamucil Fiber Singles",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1e9"
            },
            {
                "_id": "6297bcace0e0db6c2572b1ee",
                "link": "https://www.midol.com/coupon",
                "description": "$1 coupon off any Midol product; 1 coupon per purchase.",
                "brand": "Midol Long Lasting Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1ee"
            },
            {
                "_id": "6297bcace0e0db6c2572b1f3",
                "link": "https://hcp-samples.bayer.com/product-samples/aleve",
                "description": "MiraLAX Samples: Healthcare providers can register online to order free samples for their practice.",
                "brand": "MiraLAX",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1f3"
            },
            {
                "_id": "6297bcace0e0db6c2572b1f8",
                "link": "https://hcp.monistat.com/coupons",
                "description": "Monistat Product Coupons: Healthcare providers may order coupons for their practice.",
                "brand": "Monistat 1 Pre-filled ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1f8"
            },
            {
                "_id": "6297bcace0e0db6c2572b1fd",
                "link": "https://hcp.monistat.com/coupons",
                "description": "Monistat Product Coupons: Healthcare providers may order coupons for their practice.",
                "brand": "Monistat 7-Day Treatment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1fd"
            },
            {
                "_id": "6297bcace0e0db6c2572b202",
                "link": "https://www.motegrity.com/savings-support",
                "description": "Motegrity Savings Card: Eligible commercially insured patients may pay as little as $15 per 30-day supply with savings of up to $90; for additional information contact the program at 833-666-2499.",
                "brand": "Motegrity",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1080",
                "lifetime_uses": "12",
                "max_saving_transaction": 90,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b202"
            },
            {
                "_id": "6297bcace0e0db6c2572b207",
                "link": "https://mycareclubrewards.com/motrin/?utm_medium=partner&utm_source=motrin.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1sghl7t*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjY0NzMxOC40LjEuMTYzNjY0NzMzMy40NQ..*_fplc*WWt2VDMxc2E1JTJCbWhGdlZndzlCTmt6TENXRFQxc0JuWkglMkY2TDRFQnl0SUdXYzc4QmEySTM4dnJhRmE3ak1nSTRqR0RrWHk5bjQzWWFRVDFKSmc0blc5ZVVyVHI2UUVkZURxenBQalBMZllaRjlLSnUxeXduUzdxMFF5Y1VWQSUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Motrin products with registration.",
                "brand": "Motrin IB Caplet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b207"
            },
            {
                "_id": "6297bcace0e0db6c2572b20c",
                "link": "https://movantik.com/savings/",
                "description": "Movantik Savings Card: Eligible commercially insured patients may pay no more than $0 per 30-day supply with a maximum monthly savings of up to $100; offer can be used once per month for 12 months; for additional information contact the program at 984-444-7010.",
                "brand": "Movantik",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b20c"
            },
            {
                "_id": "6297bcace0e0db6c2572b211",
                "link": "https://www.rbhcprofessional.com/s/",
                "description": "Mucinex Samples: Healthcare providers may register to order samples for their practice.",
                "brand": "Mucinex Childrens Cough Mini-Melts",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b211"
            },
            {
                "_id": "6297bcace0e0db6c2572b216",
                "link": "https://www.rbhcprofessional.com/s/",
                "description": "Mucinex Samples: Healthcare providers may register to order samples for their practice.",
                "brand": "Mucinex Extended-Release Bi-Layer Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b216"
            },
            {
                "_id": "6297bcace0e0db6c2572b21b",
                "link": "https://www.rbhcprofessional.com/s/",
                "description": "Mucinex Samples: Healthcare providers may register to order samples for their practice.",
                "brand": "Mucinex Fast-Max DM MAX Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b21b"
            },
            {
                "_id": "6297bcace0e0db6c2572b220",
                "link": "https://www.mucinex.com/pages/savings",
                "description": "Register your email address to receive a $5 coupon for any Mucinex product and future savings offers.",
                "brand": "Mucinex Nightshift Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b220"
            },
            {
                "_id": "6297bcace0e0db6c2572b225",
                "link": "https://www.rbhcprofessional.com/s/",
                "description": "Mucinex Samples: Healthcare providers may register to order samples for their practice.",
                "brand": "Mucinex Sinus-Max Caplet Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b225"
            },
            {
                "_id": "6297bcace0e0db6c2572b22a",
                "link": "https://www.multaq.com/hcp",
                "description": "Multaq Samples: Healthcare providers may submit a request online for free samples.",
                "brand": "Multaq",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b22a"
            },
            {
                "_id": "6297bcace0e0db6c2572b22f",
                "link": "https://mycapssa.com/getting-started/",
                "description": "Mycapssa CAPS Program: Eligible commercially insured patients may pay as little as $0 per fill; for additional information contact the program at 833-346-2277.",
                "brand": "Mycapssa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b22f"
            },
            {
                "_id": "6297bcace0e0db6c2572b234",
                "link": "https://www.saveonmyprescription.com/copay.jsp",
                "description": "Myfortic Free Trial Offer: Eligible patients may receive a 30-day supply free; valid for one-time use only; for additional information contact the program at 877-952-1000.",
                "brand": "Myfortic",
                "copay": "No",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b234"
            },
            {
                "_id": "6297bcace0e0db6c2572b239",
                "link": "https://www.myrbetriqhcp.com/?utm_medium=referral&utm_source=myrbetriq%2ecom&utm_campaign=15+consumer+site&utm_content=offsite+link&utm_term=patient",
                "description": "Myrbetriq Samples: Healthcare providers may request samples for their practice by logging onto the website.",
                "brand": "Myrbetriq",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b239"
            },
            {
                "_id": "6297bcace0e0db6c2572b23e",
                "link": "https://naftin.com/hcp-index.html",
                "description": "Naftin Samples: Healthcare providers may order samples for their practice by filling out an order form online or faxing to 781-843-7932; order forms may also be emailed to Naftinsamples@sebelapharma.com.",
                "brand": "Naftin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b23e"
            },
            {
                "_id": "6297bcace0e0db6c2572b243",
                "link": "https://www.abbvieaccess.com/namzaric",
                "description": "Namzaric Samples: Healthcare providers may order samples for their office by logging onto the website or faxing the order form to 877-477-1258.",
                "brand": "Namzaric",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b243"
            },
            {
                "_id": "6297bcace0e0db6c2572b248",
                "link": "https://www.nasacort.com/for-adult-allergies/coupons/",
                "description": "Save $3 on any one Nasacort product; 1 coupon per purchase.",
                "brand": "Nasacort Allergy 24 HR Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b248"
            },
            {
                "_id": "6297bcace0e0db6c2572b24d",
                "link": "https://www.natazia.com/save-natazia",
                "description": "Natazia Bayer Savings Card: Eligible cash-paying patients may save up to $75 per prescription; for additional assistance contact the program at 866-203-3503.",
                "brand": "Natazia",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b24d"
            },
            {
                "_id": "6297bcace0e0db6c2572b252",
                "link": "https://www.natestohcp.com/support-and-resources/",
                "description": "Natesto Samples: Heaslthcare professionals may order samples by online for their practice.",
                "brand": "Natesto",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b252"
            },
            {
                "_id": "6297bcace0e0db6c2572b257",
                "link": "https://www.nayzilam.com/savings",
                "description": "Nayzilam Patient Savings Card: Eligible patients may pay as little as $20 per box with savings of up to $1200 per calendar year; for additional information contact the program at 888-786-5879.",
                "brand": "Nayzilam",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b257"
            },
            {
                "_id": "6297bcace0e0db6c2572b25c",
                "link": "https://www.saveonmyprescription.com/copay.jsp",
                "description": "Neoral $0 Co-Pay Card: Eligible commercially insured patients may pay $0 per prescription with maximum annual savings of up to $7,200; for additional information contact the program at 877-952-1000.",
                "brand": "Neoral",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "7200",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b25c"
            },
            {
                "_id": "6297bcace0e0db6c2572b261",
                "link": "https://mycareclubrewards.com/neosporin/?utm_medium=partner&utm_source=neosporin.com&utm_content=header&utm_campaign=cc_main&_gl=1*1lx5xvo*_ga*MjA5MDY2NzQ5OS4xNjMzMzU4MTUw*_ga_13VEM6N66E*MTYzMzM1ODE1MC4xLjAuMTYzMzM1ODE1MC42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neosporin products with registration.",
                "brand": "Neosporin plus Pain Itch and Scar",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b261"
            },
            {
                "_id": "6297bcace0e0db6c2572b266",
                "link": "https://nerlynx.com/access-and-support/access-programs",
                "description": "Nerlynx Quick Start Program: Eligible commercially insured patients may receive a 3-week supply of medication when access to their medication is delayed; for additional information contact the program at 855-816-5421.",
                "brand": "Nerlynx",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b266"
            },
            {
                "_id": "6297bcace0e0db6c2572b26b",
                "link": "http://wcpharma.com/patient-savings/",
                "description": "Nestabs DHA Women's Choice Savings Coupon: Eligible uninsured/cash-paying patients may pay no more than $50 per prescription; for additional information contact the program at 800-664-1490.",
                "brand": "Nestabs DHA",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b26b"
            },
            {
                "_id": "6297bcace0e0db6c2572b270",
                "link": "https://amgenfirststep.com",
                "description": "Neulasta Amgen First Step Program Rebate: Eligible commercially insured patients who have already paid for treatment may request a rebate check; for additional information contact the program at 888-657-8371.",
                "brand": "Neulasta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b270"
            },
            {
                "_id": "6297bcace0e0db6c2572b275",
                "link": "https://www.neupro.com/parkinsons-disease/neupro-savings-card",
                "description": "Neupro Patient Savings Card: Eligible commercially insured patients may pay as little as $10 per 30-day prescription; maximum annual benefit is $1500 per calendar year; for additional information contact the program at 855-841-0263.",
                "brand": "Neupro",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1500",
                "lifetime_uses": "12",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b275"
            },
            {
                "_id": "6297bcace0e0db6c2572b27a",
                "link": "https://www.neutrogena.com/offers.html",
                "description": "Sign up and receive 15% off 1st purchase plus free shipping on purchases $25 or more when you shop online for any one Neutrogena Product.",
                "brand": "Neutrogena Deep Moisture Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b27a"
            },
            {
                "_id": "6297bcace0e0db6c2572b27f",
                "link": "https://www.neutrogena.com/care-club.html",
                "description": "",
                "brand": "Neutrogena Hydro Boost Products",
                "copay": "",
                "bin": "",
                "rxpcn": "",
                "copay_max": "0",
                "lifetime_uses": "",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b27f"
            },
            {
                "_id": "6297bcace0e0db6c2572b284",
                "link": "https://www.neutrogena.com/care-club.html",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neutrogena products with registration.",
                "brand": "Neutrogena Oil Free Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b284"
            },
            {
                "_id": "6297bcace0e0db6c2572b289",
                "link": "https://www.neutrogena.com/offers.html",
                "description": "Sign up and receive 15% off 1st purchase plus free shipping on purchases $25 or more when you shop online for any one Neutrogena Product.",
                "brand": "Neutrogena Stubborn Acne Treatment Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b289"
            },
            {
                "_id": "6297bcace0e0db6c2572b28e",
                "link": "https://newskinproducts.com/pages/coupons",
                "description": "Save $2 on any New Skin product; 1 coupon per purchase.",
                "brand": "New-Skin All-in-One Liquid Spray Bandage",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b28e"
            },
            {
                "_id": "6297bcace0e0db6c2572b293",
                "link": "https://www.nexcare.com/3M/en_US/nexcare/deals-offers/",
                "description": "Sign-up with Nexcare Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "Nexcare Acne Blemish Covers",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b293"
            },
            {
                "_id": "6297bcace0e0db6c2572b298",
                "link": "https://www.nexcare.com/3M/en_US/nexcare/deals-offers/",
                "description": "Sign-up with Nexcare Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "Nexcare Tape and Wrap Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b298"
            },
            {
                "_id": "6297bcace0e0db6c2572b29d",
                "link": "https://www.nexium24hr.com/us/coupons-for-nexium/",
                "description": "Receive a coupon for savings on any Nexium 24hr product with registration; 1 coupon per purchase.",
                "brand": "Nexium 24HR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b29d"
            },
            {
                "_id": "6297bcace0e0db6c2572b2a2",
                "link": "https://www.activatethecard.com/7883/request",
                "description": "Nexlizet Co-Pay Card: Eligible commercially insured patients may pay as little as $10 per fill for up to a 3-month supply; for additional information contact the program at 855-699-8814.",
                "brand": "Nexlizet",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "4",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2a2"
            },
            {
                "_id": "6297bcace0e0db6c2572b2a7",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Nicaprin Artesa Labs Advantage Program: Eligible commercially insured/Rx not covered patients may pay as little as $45 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Nicaprin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2a7"
            },
            {
                "_id": "6297bcace0e0db6c2572b2ac",
                "link": "https://www.nicomide.com/healthcare-providers",
                "description": "Nicomide Samples: Healthcare providers may order samples for their practice by filling out a form online or calling 888-612-8466.",
                "brand": "Nicomide",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2ac"
            },
            {
                "_id": "6297bcace0e0db6c2572b2b1",
                "link": "https://www.nicorette.com/coupons.html",
                "description": "Save $5 on any Nicorette Product (72 count or larger) when you sign-up online with your email address; 1 coupon per purchase.",
                "brand": "Nicorette Mini Lozenge",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2b1"
            },
            {
                "_id": "6297bcace0e0db6c2572b2b6",
                "link": "https://www.takedaoncologycopay.com",
                "description": "Ninlaro Takeda Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay as little as $0 per month with savings of up to $25,000 per prescription; for additional information contact the program at 844-817-6468.",
                "brand": "Ninlaro",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2b6"
            },
            {
                "_id": "6297bcace0e0db6c2572b2bb",
                "link": "https://www.pfizercopay.com/",
                "description": "Nivestym Pfizer Oncology Together Co-Pay Savings Program for Injectables: Eligible commercially insured patients may pay as little as $0 per treatment; maximum savings limits per calendar year apply; for more information contact the program at 877-744-5675.",
                "brand": "Nivestym",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2bb"
            },
            {
                "_id": "6297bcace0e0db6c2572b2c0",
                "link": "https://www.nonyx.com/rebate",
                "description": "$5 mail-in rebate offer for NonyXNail Gel; for more information contact 800-368-6003.",
                "brand": "NonyX Nail Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2c0"
            },
            {
                "_id": "6297bcace0e0db6c2572b2c5",
                "link": "https://www.luprongyn.com/savings-and-support",
                "description": "Norethindrone Acetate Savings Card (Endometriosis Patients): Eligible commercially insured patients with Endometriosis may pay as little as $5 per dose with savings up to $25 per 5 mg dose; for additional assistance contact the program at 855-587-7663.",
                "brand": "Norethindrone Acetate",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 25,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2c5"
            },
            {
                "_id": "6297bcace0e0db6c2572b2ca",
                "link": "https://www.norvasc.com/savings-terms",
                "description": "Norvasc Savings Card Rebate: If the pharmacy does not accept the Savings Card eligible patients may be able to submit a request for a rebate; for additional information contact the program at 855-252-0283.",
                "brand": "Norvasc",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "900",
                "lifetime_uses": "12",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2ca"
            },
            {
                "_id": "6297bcace0e0db6c2572b2cf",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Novarel OneHeart Program: Eligible commercially insured & cash-paying patients may be eligible to receive a $200 rebate for out-of-pocket costs incurred when using Novarel for their COS cycle between June 1, 2020 � December 31, 2021; for additional information contact the program at 888-337-7464.",
                "brand": "Novarel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2cf"
            },
            {
                "_id": "6297bcace0e0db6c2572b2d4",
                "link": "https://www.novoneedles.com/needle-offers.html",
                "description": "NovoFine Plus Novo Nordisk Diabetes Savings Card: Eligible commercially insured patients receive up to $60 off one box of Novo Nordisk needles by activating the savings card; for additional information contact the program at 877-304-6855.",
                "brand": "NovoFine Plus 32G",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 60,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2d4"
            },
            {
                "_id": "6297bcace0e0db6c2572b2d9",
                "link": "https://www.novocare.com/insulin/my99insulin.html?src=100001849",
                "description": "NovoLog My$99Insulin Card: Eligible patients pay $99 per monthly prescription (up to 3 vials or 2 packs of pens) of Novo Nordisk insulin products; offer may be used once a month during a calendar year; for additional information contact the program at 888-910-0632.",
                "brand": "NovoLog",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2d9"
            },
            {
                "_id": "6297bcace0e0db6c2572b2de",
                "link": "https://www.mynovosecure.com/copayassistance.html",
                "description": "NovoSeven RT Novo Nordisk Hemophilia & Rare Bleeding Disorders (RBD) Co-pay Assistance Program: Eligible commercially insured patients may receive up to $12,000 per year towards their co-pay; for additional information please contact the program at 844-668-6732.",
                "brand": "NovoSeven RT",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2de"
            },
            {
                "_id": "6297bcace0e0db6c2572b2e3",
                "link": "https://www.nubeqacopayprogram.com",
                "description": "Nubeqa $0 Copay Program: Eligible commercially insured patients may qualify for $0 copay per prescription; maximum savings of $25,000 per year; for additional information contact the program at 647-245-5642.",
                "brand": "Nubeqa",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2e3"
            },
            {
                "_id": "6297bcace0e0db6c2572b2e8",
                "link": "https://www.nuedexta.com/savings-and-support",
                "description": "Nuedexta Co-pay Savings Card: Eligible commercially insured patients may pay as little as $0 per 90-day prescription and refills or $20 per 30-day prescription; for additional information contact the program at 855-468-3339.",
                "brand": "Nuedexta",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2e8"
            },
            {
                "_id": "6297bcace0e0db6c2572b2ed",
                "link": "https://www.nuplazidhcp.com",
                "description": "Nuplazid Free 14-Day Supply: Patients may be able to receive a FREE 14-day supply of medication; for additional information contact the program 844-737-2223.",
                "brand": "Nuplazid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2ed"
            },
            {
                "_id": "6297bcace0e0db6c2572b2f2",
                "link": "https://www.nutropin.com/patient/patient-support/nutropin-gps-co-pay-card-program.html",
                "description": "Nutropin AQ NuSpin GPS Co-Pay Card Program: Eligible commercially insured patients pay no more that $10 per prescription with savings of up to $5,000 annually; for additional information contact the program at 866-688-7674.",
                "brand": "Nutropin AQ NuSpin",
                "copay": "Yes",
                "bin": "6012",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "12",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2f2"
            },
            {
                "_id": "6297bcace0e0db6c2572b2f7",
                "link": "https://www.nuwiqusa.com/patient-support/free-trial-program/",
                "description": "Nuwiq Factor My Way Free Trial Program: Eligible patients may receive free trial treatment at no cost (not to exceed 6 doses or not exceed approximately 20,000 IUs); for additional information contact the program at 866-830-6541.",
                "brand": "Nuwiq",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2f7"
            },
            {
                "_id": "6297bcace0e0db6c2572b2fc",
                "link": "http://obcomplete.com/coupons/",
                "description": "OB Complete Savings Card: Eligible patients may pay $10 per 30-day prescription with savings of up to $50 per fill; for additional information contact the program at 844-727-5540.",
                "brand": "OB Complete Petite",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "600",
                "lifetime_uses": "12",
                "max_saving_transaction": 50,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2fc"
            },
            {
                "_id": "6297bcace0e0db6c2572b301",
                "link": "https://www.ocrevuscopay.com/eligibility",
                "description": "Ocrevus Co-Pay Program: Eligible commercially insured patients may pay as little as $5 copay per drug/infusion per month for up to 12 months; for additional information 844-672-6729.",
                "brand": "Ocrevus",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b301"
            },
            {
                "_id": "6297bcace0e0db6c2572b306",
                "link": "https://www.ocuvite.com/buy-now",
                "description": "Receive a $3 coupon off any one Ocuvite product; email address required; 1 coupon per purchase.",
                "brand": "Ocuvite Eye Performance",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b306"
            },
            {
                "_id": "6297bcace0e0db6c2572b30b",
                "link": "https://www.alksavings.com/cb/access/crp/aLkYd2j78Vbb/fid.jsp?q=51G5OI5442CUDK4PLWTLX5&f=IMPX6IG-274EMWRGK6802#/app/layout/home",
                "description": "Odactra ALK Savings Card Program: Eligible commercially insured patients may pay as little as $25 per 30-day prescription if using an ALK Network Pharmacy; valid for 12 uses or $3,200 per year (whichever comes first); for additional information contact the program at 800-325-7354.",
                "brand": "Odactra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3200",
                "lifetime_uses": "12",
                "max_saving_transaction": 265,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b30b"
            },
            {
                "_id": "6297bcace0e0db6c2572b310",
                "link": "https://www.activatethecard.com/7436/",
                "description": "Odomzo Copay Card: Eligible commercially insured patients may pay as little as $10 per month; maximum annual savings of $15,000; for additional information contact the program at 877-636-6961.",
                "brand": "Odomzo",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b310"
            },
            {
                "_id": "6297bcace0e0db6c2572b315",
                "link": "https://www.omeclamox.com/request-samples/download/",
                "description": "Omeclamox-Pak Sample Request Form: Healthcare providers may request a free sample by completing and submitting form via fax to 800-801-6789; for additional information contact the program at 877-274-7200.",
                "brand": "Omeclamox-Pak",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b315"
            },
            {
                "_id": "6297bcace0e0db6c2572b31a",
                "link": "https://www.omnitrope.com/your-omnisource/co-pay-support",
                "description": "Omnitrope Co-Pay Savings Program: Eligible commercially insured patients may save up to $5000 annually off copays; for additional information contact the program at 877-456-6794.",
                "brand": "Omnitrope",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "12",
                "max_saving_transaction": 416.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b31a"
            },
            {
                "_id": "6297bcace0e0db6c2572b31f",
                "link": "https://www.oneaday.com/healthcare-professionals",
                "description": "One-A-Day Prenatal Product Samples: Healthcare providers can register online to order free samples for their practice.",
                "brand": "One-A-Day Prenatal Multivitamin products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b31f"
            },
            {
                "_id": "6297bcace0e0db6c2572b324",
                "link": "https://www.onetouch.com/offers",
                "description": "OneTouch Verio FREE meter Offer: Can only be redeemed where OneTouch products are sold and prescriptions can be processed; requires a valid prescription; offer valid for one meter per patient every 12 months; for additional information contact the program at 800-227-8862.",
                "brand": "OneTouch Verio",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b324"
            },
            {
                "_id": "6297bcace0e0db6c2572b329",
                "link": "https://www.onetouch.com/offers",
                "description": "OneTouch Verio Reflect FREE Offer: Can only be redeemed where OneTouch products are sold and prescriptions can be processed; requires a valid prescription; offer valid for one meter per patient every 12 months; for additional information contact the program at 800-227-8862.",
                "brand": "OneTouch Verio Reflect Meter",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b329"
            },
            {
                "_id": "6297bcace0e0db6c2572b32e",
                "link": "https://www.onfi.com/paying-for-onfi",
                "description": "Onfi FREE 14-day trial: Eligible commercially insured patients may receive a 14-day trial; complete online form to access your free trial voucher; must present the free voucher with valid prescription; for additional information contact the program at 855-809-5818.",
                "brand": "Onfi",
                "copay": "No",
                "bin": "610524",
                "rxpcn": "1016",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b32e"
            },
            {
                "_id": "6297bcace0e0db6c2572b333",
                "link": "https://www.ipsencares.com/onivyde-patient-support/",
                "description": "Onivyde Ipsen Cares Copay Assistance Program: Eligible commercially insured patients may pay as little as $0 per prescription with a maximum savings of $20,000 per calendar year; program resets every January 1st; for additional information contact the program at 866-435-5677.",
                "brand": "Onivyde",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b333"
            },
            {
                "_id": "6297bcace0e0db6c2572b338",
                "link": "https://pah.janssencarepath.com/patient/opsumit/patient-support.html",
                "description": "Opsumit Voucher Program: Eligible patients over 18 may request a FREE trial offer for 30-day supply; for additional information contact the program at 866-228-3546.",
                "brand": "Opsumit",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b338"
            },
            {
                "_id": "6297bcace0e0db6c2572b33d",
                "link": "https://www.eyefile.com/theeyesolution/Register",
                "description": "Save $2 on your next purchase of any one Opti-Free PureMoist Rewetting Drops (10 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Opti-Free PureMoist Rewetting Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b33d"
            },
            {
                "_id": "6297bcace0e0db6c2572b342",
                "link": "https://www.galdermacc.com/patients",
                "description": "Oracea CareConnect Savings Card: Eligible uninsured/cash-paying  patients may pay no more than $105 per prescription; for additional information contact the program at 855-280-0543.",
                "brand": "Oracea",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b342"
            },
            {
                "_id": "6297bcace0e0db6c2572b347",
                "link": "https://www.orencia.com/support-savings/orencia-faqs",
                "description": "Orencia Rebate (for Self-Injection Patients): If your pharmacy does not accept the Savings Card you may contact the program to find out how to receive your proper savings;  for additional information contact the program directly at 800-673-6242.",
                "brand": "Orencia",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "13",
                "max_saving_transaction": 1153.85,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b347"
            },
            {
                "_id": "6297bcace0e0db6c2572b34c",
                "link": "https://www.orgovyx.com/support-and-resources/",
                "description": "Orgovyx Free Trial: Eligible NEW may receive a 2-month supply of medication for FREE; for additional information contact the program at 833-674-6899.",
                "brand": "Orgovyx",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b34c"
            },
            {
                "_id": "6297bcace0e0db6c2572b351",
                "link": "https://www.orilissa.com/insurance-and-savings/co-pay-card",
                "description": "Orilissa Savings Card Rebate: If a commercially insured patient paid out-of-pocket for their prescription they can apply for a refund; for additional information contact the program at 800-674-5477.",
                "brand": "Orilissa",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b351"
            },
            {
                "_id": "6297bcace0e0db6c2572b356",
                "link": "https://orphengesicforte.com/resources/",
                "description": "Orphengesic Forte Cash-Pay Program: Eligible uninsured patients or commercially insured patients/RX not covered may pay as little as $30 per prescription when using a network pharmacy; for additional information contact the program at 855-965-2783.",
                "brand": "Orphengesic Forte",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b356"
            },
            {
                "_id": "6297bcace0e0db6c2572b35b",
                "link": "https://www.osphena.com/savings",
                "description": "Osphena Savings Card: Eligible commercially insured patients may pay as little as $35 for 30 tablets or $90 for 90 tablets; for additional information contact the program at 866-339-0316.",
                "brand": "Osphena",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b35b"
            },
            {
                "_id": "6297bcace0e0db6c2572b360",
                "link": "https://www.osteobiflex.com/ambassadors-club/",
                "description": "Osteo Bi-Flex Products: Join the Ambassador�s Club to receive coupons based on your past purchases.",
                "brand": "Osteo Bi-Flex One Per Day",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b360"
            },
            {
                "_id": "6297bcace0e0db6c2572b365",
                "link": "https://www.otezla.com/supportplus/otezla-cost-and-copay",
                "description": "Otezla Bridge Program: Eligible commercially insured/RX not covered patients may receive FREE medication; may be eligible for free medication for up to 3 years; for additional information contact the program at 844-468-3952.",
                "brand": "Otezla",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b365"
            },
            {
                "_id": "6297bcace0e0db6c2572b36a",
                "link": "https://www.otrexup.com/starting-otrexup/activating-your-savings-card",
                "description": "Otrexup Total Care Co-pay Assistance Mail-in Rebate: Eligible commercially insured patients may take advantage of the mail-in rebate program if the pharmacy is unable to process the co-pay assistance card; for additional information contact the program at 800-422-5604.",
                "brand": "Otrexup",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3250",
                "lifetime_uses": "13",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b36a"
            },
            {
                "_id": "6297bcace0e0db6c2572b36f",
                "link": "https://www.oxistat.com/instant-savings/",
                "description": "Oxistat ANI Pharmaceuticals Derm Copay Savings Program: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 844-396-8097.",
                "brand": "Oxistat",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b36f"
            },
            {
                "_id": "6297bcace0e0db6c2572b374",
                "link": "https://www.novocare.com/ozempic/savings-card.html",
                "description": "Ozempic Savings Card: Eligible commercially insured patients may pay no more than $25 per 30-day prescription with savings of up to $150 per fill; offer valid 24 times after card activation; for additional information contact the program at 877-304-6855.",
                "brand": "Ozempic",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b374"
            },
            {
                "_id": "6297bcace0e0db6c2572b379",
                "link": "https://www.palforzia.com/support/",
                "description": "Palforzia Pathway Co-Pay Savings Program: Eligible commercially insured patients may pay as little as $20 per monthly fill; maximum savings of $5800 per calendar year; must re-enroll each year; for more information contact the program at 844-725-3679.",
                "brand": "Palforzia",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5800",
                "lifetime_uses": "12",
                "max_saving_transaction": 483.33,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b379"
            },
            {
                "_id": "6297bcace0e0db6c2572b37e",
                "link": "https://pancreazeengage.com",
                "description": "Pancreaze Advantage Co-pay Card: Eligible commercially insured patients may pay a minimum of $0 per 30-day prescription with savings of up to $100 per fill; for additional information contact the program at 888-238-8059.",
                "brand": "Pancreaze",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b37e"
            },
            {
                "_id": "6297bcace0e0db6c2572b383",
                "link": "https://www.panoxyl.com/coupons/",
                "description": "Save $2 on any one PanOxyl Acne Wash product; 1 coupon per purchase, coupon expires 1 month after printing.",
                "brand": "PanOxyl Acne Creamy Wash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b383"
            },
            {
                "_id": "6297bcace0e0db6c2572b388",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/parodontax/overview/",
                "description": "Parodontax Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Parodontax Complete Protection Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b388"
            },
            {
                "_id": "6297bcace0e0db6c2572b38d",
                "link": "https://pediasure.com/resources-and-rewards/pediasure-coupons",
                "description": "Sign up for the PediaSure Support2Grow program and save up to $100 with receive special offers.",
                "brand": "PediaSure Enteral 1.0 Cal w-Fiber",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b38d"
            },
            {
                "_id": "6297bcace0e0db6c2572b392",
                "link": "https://pediasure.com/resources-and-rewards/pediasure-coupons",
                "description": "Sign up for the PediaSure Support2Grow program and save up to $100 with receive special offers.",
                "brand": "PediaSure Grow and Gain Shake Mix",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b392"
            },
            {
                "_id": "6297bcace0e0db6c2572b397",
                "link": "https://pediasure.com/resources-and-rewards/pediasure-coupons",
                "description": "Sign up for the PediaSure Support2Grow program and save up to $100 with receive special offers.",
                "brand": "PediaSure Pro",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b397"
            },
            {
                "_id": "6297bcace0e0db6c2572b39c",
                "link": "https://www.pennsaid.com/resources-and-support/patient-resources",
                "description": "Pennsaid 2% HorizonCares Program: Eligible patients may have their doctor send their prescription to a participating pharmacy; if their prescription is covered by insurance then the patient pays $0 per fill; if medication is not covered by insurance then the patient pays a low copay per fill; for additional information contact the program at 866-323-1490.",
                "brand": "Pennsaid",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b39c"
            },
            {
                "_id": "6297bcace0e0db6c2572b3a1",
                "link": "https://mycareclubrewards.com/pepcid/?utm_medium=partner&utm_source=pepcid.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1f8mxe6*_ga*MTc3MDUxNTg0OS4xNjMzMDE2OTQ4*_ga_13VEM6N66E*MTYzMzAxNjk0OC4xLjEuMTYzMzAxNzAzOC41OQ..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Pepcid products with registration.",
                "brand": "Pepcid Complete On-The-Go",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3a1"
            },
            {
                "_id": "6297bcace0e0db6c2572b3a6",
                "link": "https://pertzyecares.com/chiesi-caredirect/",
                "description": "Pertzye $20 Copay Card Program: Eligible commercially insured patients & cash-paying patients may pay as little as $20 per prescription on each of up to 12 refills; monthly maximum savings of $500 per fill; for additional information contact the program at 855-883-1461.",
                "brand": "Pertzye",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6000",
                "lifetime_uses": "12",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3a6"
            },
            {
                "_id": "6297bcace0e0db6c2572b3ab",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Phesgo Genentech Oncology Co-pay Assistance Program Rebate:  Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Phesgo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3ab"
            },
            {
                "_id": "6297bcace0e0db6c2572b3b0",
                "link": "https://www.phillipsdigestive.com/coupon/",
                "description": "Register your email address to receive a coupon for any one Phillips' product; 1 coupon per purchase.",
                "brand": "Phillips Milk of Magnesia Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3b0"
            },
            {
                "_id": "6297bcace0e0db6c2572b3b5",
                "link": "https://copay.novartisoncology.com/",
                "description": "Piqray Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Piqray",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3b5"
            },
            {
                "_id": "6297bcace0e0db6c2572b3ba",
                "link": "https://plenvu.copaysavingsprogram.com",
                "description": "Plenvu Co-pay Assistance Card: Eligible commercially insured patients pay as little as $50 per fill; for additional information contact the program at 855-202-3208.",
                "brand": "Plenvu",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3ba"
            },
            {
                "_id": "6297bcace0e0db6c2572b3bf",
                "link": "https://www.poise.com/en-us/samples-and-offers/coupons",
                "description": "Register and receive a valuable coupon for any one Poise product; 1 coupon per purchase.",
                "brand": "Poise Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3bf"
            },
            {
                "_id": "6297bcace0e0db6c2572b3c4",
                "link": "https://www.polident.com/en-us/save-now/polident-coupon/",
                "description": "Save $1 on any one Polident product with registration; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Polident Dentu-Creme Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3c4"
            },
            {
                "_id": "6297bcace0e0db6c2572b3c9",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/products/ortho-cleanser/",
                "description": "Polident ProGuard Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Polident Pro Guard and Retainer Anti-Bacterial Daily Cleanser Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3c9"
            },
            {
                "_id": "6297bcace0e0db6c2572b3ce",
                "link": "https://www.polident.com/en-us/save-now/proguard-coupon/",
                "description": "Save $1 on your purchase of any Polident ProGuard & Retainer Cleanser product by registering your email address; one coupon per purchase.",
                "brand": "Polident Pro Guard and Retainer Starter Kit",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3ce"
            },
            {
                "_id": "6297bcace0e0db6c2572b3d3",
                "link": "https://www.polident.com/en-us/save-now/propartial-options/",
                "description": "Save $1 on your purchase of any Polident ProPartial product by registering your email address; one coupon per purchase.",
                "brand": "Polident ProPartials Mouthwash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3d3"
            },
            {
                "_id": "6297bcace0e0db6c2572b3d8",
                "link": "https://www.polident.com/en-us/save-now/propartial-options/",
                "description": "Save $3.50 on your purchase when you buy all 3 Polident ProPartial products in a single transaction; must register your email address on the website; one coupon per purchase.",
                "brand": "Polident ProPartials Toothpaste",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3.5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3d8"
            },
            {
                "_id": "6297bcace0e0db6c2572b3dd",
                "link": "https://mycareclubrewards.com/neosporin/?utm_medium=partner&utm_source=neosporin.com&utm_content=header&utm_campaign=cc_main&_gl=1*1lx5xvo*_ga*MjA5MDY2NzQ5OS4xNjMzMzU4MTUw*_ga_13VEM6N66E*MTYzMzM1ODE1MC4xLjAuMTYzMzM1ODE1MC42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Polysporin products with registration.",
                "brand": "Polysporin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3dd"
            },
            {
                "_id": "6297bcace0e0db6c2572b3e2",
                "link": "https://www.pradaxa.com/pradaxa-savings",
                "description": "Pradaxa Savings Card: Eligible commercially insured patients may pay as little as $0 per month with a savings of up to $2400 annually; valid for 12 uses or up to the program maximum whichever comes first; for additional information contact the program at 877-481-5332.",
                "brand": "Pradaxa",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "2400",
                "lifetime_uses": "12",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3e2"
            },
            {
                "_id": "6297bcace0e0db6c2572b3e7",
                "link": "https://sebelapharma.com/savings-programs/",
                "description": "Pramosone Co-Pay Savings Card: Eligible cash-paying patients may save up to $75 per prescription; for additional information contact the program at 844-728-3479.",
                "brand": "Pramosone",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3e7"
            },
            {
                "_id": "6297bcace0e0db6c2572b3ec",
                "link": "https://www.premarin.com/premarin-savings",
                "description": "Premarin Savings Card (Tablets): Eligible commercially insured patients will pay as little as $30 per prescription with a savings of up to $55 per fill; maximum savings of $660 per calendar year; for additional information contact the program at 866-410-3700.",
                "brand": "Premarin",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "660",
                "lifetime_uses": "12",
                "max_saving_transaction": 55,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3ec"
            },
            {
                "_id": "6297bcace0e0db6c2572b3f1",
                "link": "https://www.pfizermedicalinformation.com/en-us/prempro-and-premphase",
                "description": "Prempro Samples: Your healthcare provider may order samples by visiting the PfizerPro website or calling 800-505-4426.",
                "brand": "Prempro",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3f1"
            },
            {
                "_id": "6297bcace0e0db6c2572b3f6",
                "link": "https://prenate.com/patient-savings/",
                "description": "Prenate DHA eVoucherRx Program: Eligible patients pay no more that $20 per prescription fill at participating pharmacies; for additional information contact the program at 888-612-8466.",
                "brand": "Prenate DHA",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3f6"
            },
            {
                "_id": "6297bcace0e0db6c2572b3fb",
                "link": "https://prenate.com/patient-savings/",
                "description": "Prenate Enhance eVoucherRx Program: Eligible patients pay no more than$20 per prescription fill at participating pharmacies; for additional information contact the program at 888-612-8466.",
                "brand": "Prenate Enhance",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3fb"
            },
            {
                "_id": "6297bcace0e0db6c2572b400",
                "link": "https://prenate.com/patient-savings/",
                "description": "Prenate Mini�eVoucherRx Program: Eligible patients pay no more than $20 per prescription fill at participating pharmacies; for additional information contact the program at 888-612-8466.",
                "brand": "Prenate Mini",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b400"
            },
            {
                "_id": "6297bcace0e0db6c2572b405",
                "link": "https://www.preparationh.com/coupons-and-special-offers/",
                "description": "Register your email address to receive a coupon for any one Preparation H product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Preparation H Cooling Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b405"
            },
            {
                "_id": "6297bcace0e0db6c2572b40a",
                "link": "https://www.preparationh.com/coupons-and-special-offers/",
                "description": "Register your email address to receive a coupon for any one Preparation H product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Preparation H Rapid Relief Totables Wipes",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b40a"
            },
            {
                "_id": "6297bcace0e0db6c2572b40f",
                "link": "https://www.preparationh.com/coupons-and-special-offers/",
                "description": "Register your email address to receive a coupon for any one Preparation H product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Preparation H Suppositories",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b40f"
            },
            {
                "_id": "6297bcace0e0db6c2572b414",
                "link": "https://www.preservision.com/Get-Coupons/Get-My-Coupon",
                "description": "$4 coupon off any PreserVision Eye Vitamin and Mineral Supplement 60 count or larger; 1 coupon per purchase.",
                "brand": "PreserVision Eye Vitamin AREDS 2 Formula Chewables",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b414"
            },
            {
                "_id": "6297bcace0e0db6c2572b419",
                "link": "https://www.sightmatters.com/join?utm_campaign=preservision-new&utm_content=cta&utm_medium=preservision-new_landing&utm_source=preservision-new_redirect",
                "description": "PreserVision SightMatters: Sign-up and receive coupons for Preservision AREDS 2�formula vitamins products valued up to�$60 per year.",
                "brand": "PreserVision Eye Vitamin AREDS 2 Formula MiniGels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b419"
            },
            {
                "_id": "6297bcace0e0db6c2572b41e",
                "link": "https://www.preservision.com/Get-Coupons/Get-My-Coupon",
                "description": "$4 coupon off any PreserVision Eye Vitamin and Mineral Supplement 60 count or larger; 1 coupon per purchase.",
                "brand": "PreserVision Eye Vitamin AREDS Lutien Formula Soft Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b41e"
            },
            {
                "_id": "6297bcace0e0db6c2572b423",
                "link": "https://www.prevymis.com/savings-coupon/",
                "description": "Prevymis Merck Co-Pay Assistance Program: Eligible commercially insured patients may pay as little as $15 per prescription on each of up to 4 qualifying prescriptions; maximum savings of $2,500 per prescription;for additional information contact the program at 877-264-2454.",
                "brand": "Prevymis",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "4",
                "max_saving_transaction": 2500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b423"
            },
            {
                "_id": "6297bcace0e0db6c2572b428",
                "link": "https://www.prialt.com/patients-starting-on-prialt/assistance-program/",
                "description": "Prialt Access & Support Program: Eligible commercially insured patients may pay as little as $20 per prescription with savings of up to $8000 per year; for additional information contact the program at 855-774-2581.",
                "brand": "Prialt",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "8000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b428"
            },
            {
                "_id": "6297bcace0e0db6c2572b42d",
                "link": "https://primacarerx.com/healthcare-professionals/",
                "description": "PrimaCare Samples: Healthcare providers may order samples for their practice by filling out an online form or calling 888-612-8466.",
                "brand": "PrimaCare",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b42d"
            },
            {
                "_id": "6297bcace0e0db6c2572b432",
                "link": "https://www.digihaler.com/#savings",
                "description": "ProAir Digihaler Savings Program: Eligible commercially insured patients may pay as little as $20 per prescription; for more information contact the program at 866-955-9463.",
                "brand": "ProAir Digihaler",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b432"
            },
            {
                "_id": "6297bcace0e0db6c2572b437",
                "link": "https://www.prograf.com/savings-information",
                "description": "Prograf Astellas Copay Program Mail-In Rebate: If your pharmacy does not accept the savings card or you use a mail order pharmacy you may complete and submit the mail-order form to receive your proper savings; click on the Access Mail-Order Rebate Portal to complete your rebaste online;  for additional information contact the program at 866-790-7659.",
                "brand": "Prograf",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b437"
            },
            {
                "_id": "6297bcace0e0db6c2572b43c",
                "link": "https://amgenfirststep.com",
                "description": "Prolia Amgen First Step Program Rebate: Eligible commercially insured patients who have already paid for treatment may request a rebate check; for additional information contact the program at 888-657-8371.",
                "brand": "Prolia",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b43c"
            },
            {
                "_id": "6297bcace0e0db6c2572b441",
                "link": "https://www.dendreononcall.com/benefits-verification",
                "description": "Provenge PROvide Commercial Co-pay Program: Eligible commercially insured patients may qualify for financial assistance towards co-pays, co-insurance, and deductible costs; up to $6000 in coverage over 3 treatments; for more information contact the program at 877-336-3736.",
                "brand": "Provenge",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b441"
            },
            {
                "_id": "6297bcace0e0db6c2572b446",
                "link": "https://uspco.com/product_literature.aspx?pid=52&tab=Voucher",
                "description": "Provida OB Pharmacy Voucher: Eligible patients may save up to 80% at participating pharmacies; for additional information contact the program at  888-602-2978.",
                "brand": "Provida OB",
                "copay": "No",
                "bin": "600428",
                "rxpcn": "5100000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b446"
            },
            {
                "_id": "6297bcace0e0db6c2572b44b",
                "link": "https://www.pulmozymesupport.com",
                "description": "Pulmozyme Voucher Program: New eligible patients may receive a FREE 30-day supply; for additional information contact the program at 877-794-8723.",
                "brand": "Pulmozyme",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b44b"
            },
            {
                "_id": "6297bcace0e0db6c2572b450",
                "link": "https://azuritysolutions.com/patients/qbrelis/#product",
                "description": "Qbrelis eVoucherRx Program: Eligible commercially insured patients may pay no more than $30 per prescription with savings of up to $230 per fill at a participating pharmacy; for additional information contact the program at 844-472-2032.",
                "brand": "Qbrelis",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 230,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b450"
            },
            {
                "_id": "6297bcace0e0db6c2572b455",
                "link": "https://www.qnasl.com/Savings",
                "description": "QNASL Savings Program: Eligible uninsured/cash-paying patients may save up to $98.10 per device for their prescription; for additional information contact the program at 844-492-9703.",
                "brand": "QNASL",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 98.1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b455"
            },
            {
                "_id": "6297bcace0e0db6c2572b45a",
                "link": "https://qsymiaengage.com/?_ga=2.108652930.1283159311.1636035592-1596284036.1636035592&_gac=1.87289450.1636037427.CjwKCAjwiY6MBhBqEiwARFSCPgOQ4H_izUz-uD_6rVCxghsj1TFRk7CFUdJR-gkLlfq9KM4FP7eXQRoChEAQAvD_BwE",
                "description": "Qsymia Engage Home Delivery Pharmacy Program: Patients may receive free shipping on all 90-day prescription orders placed under the program as well as exclusive pricing on their medication; for additional information contact the program at 844-777-9642.",
                "brand": "Qsymia",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b45a"
            },
            {
                "_id": "6297bcace0e0db6c2572b45f",
                "link": "https://coupon.trisadhd.com",
                "description": "QuilliChew ER Co-pay Card: Eligible cash-paying patients may save on their monthly prescriptions; for additional information contact the program at 888-840-7006.",
                "brand": "Quillichew ER",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b45f"
            },
            {
                "_id": "6297bcace0e0db6c2572b464",
                "link": "https://www.qulipta.com/savings-support/qulipta-complete-savings-program",
                "description": "Qulipta Complete Savings Card: Eligible commercially insured patients may pay as little as $0 per month; for additional information contact the program at 855-785-4782.",
                "brand": "Qulipta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b464"
            },
            {
                "_id": "6297bcace0e0db6c2572b469",
                "link": "https://www.qvar.com/redihaler/redihaler-cost-savings",
                "description": "QVAR RediHaler Savings Card: Eligible cash-paying patients may save up to $25 on each of up to 25 prescriptions; for additional information contact the program at 844-807-0061.",
                "brand": "QVAR RediHaler",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "300",
                "lifetime_uses": "12",
                "max_saving_transaction": 25,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b469"
            },
            {
                "_id": "6297bcace0e0db6c2572b46e",
                "link": "https://www.alksavings.com/cb/access/crp/aLkYd2j78Vbb/fid.jsp?q=51G5OI5442CUDK4PLWTLX5&f=IMPX6IG-274EMWRGK6802#/app/layout/home",
                "description": "Ragwitek ALK Savings Card: Eligible commercially insured patients/RX not covered may pay as little as $75 per 30-day prescription if using an ALK Network Pharmacy; valid for 12 uses or $3,200 per year (whichever comes first); for additional information contact the program at 800-325-7354.",
                "brand": "Ragwitek",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3200",
                "lifetime_uses": "12",
                "max_saving_transaction": 265,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b46e"
            },
            {
                "_id": "6297bcace0e0db6c2572b473",
                "link": "https://www.rebif.com/home/1-on-1-support/Financial-support-specialists.html",
                "description": "Rebif MS LifeLines Program: Eligible patients may have $0 co-pay for prescriptions; contact the Financial Support Specialist at 877-447-3243 for further assistance.",
                "brand": "Rebif",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b473"
            },
            {
                "_id": "6297bcace0e0db6c2572b478",
                "link": "https://www.recticare.com/coupons/",
                "description": "Receive an instant $3 coupon for any one RectiCare product by registering your email address online; 1 coupon per purchase.",
                "brand": "RectiCare Anorectal Cream Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b478"
            },
            {
                "_id": "6297bcace0e0db6c2572b47d",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Celluvisc",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b47d"
            },
            {
                "_id": "6297bcace0e0db6c2572b482",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Liquigel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b482"
            },
            {
                "_id": "6297bcace0e0db6c2572b487",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Optive Mega-3 Preservative Free",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b487"
            },
            {
                "_id": "6297bcace0e0db6c2572b48c",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Relieva for Contacts",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b48c"
            },
            {
                "_id": "6297bcace0e0db6c2572b491",
                "link": "https://ec4ea071-fbcb-424f-b1c2-cf62cecd02ee.filesusr.com/ugd/72e81f_ff8712ca24fb46138474f91660fcfdec.pdf",
                "description": "ReliOn Novolin 70/30 FlexPen Savings: Eligible patients may save from 58% to 75% off the cash price per�prescription; offer available only at Walmart; for additional information contact your nearest Walmart Pharmacy.",
                "brand": "ReliOn Novolin 70/30 Insulin FlexPen",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b491"
            },
            {
                "_id": "6297bcace0e0db6c2572b496",
                "link": "https://ec4ea071-fbcb-424f-b1c2-cf62cecd02ee.filesusr.com/ugd/72e81f_ff8712ca24fb46138474f91660fcfdec.pdf",
                "description": "ReliOn Novolin R Savings: Eligible patients may save from 58% to 75% off the cash price per�prescription; offer available only at Walmart; for additional information contact your nearest Walmart Pharmacy.",
                "brand": "ReliOn Novolin R Insulin Vial",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b496"
            },
            {
                "_id": "6297bcace0e0db6c2572b49b",
                "link": "https://www.relizorb.com/patient/financial-assistance",
                "description": "RELiZORB Out-of-Pocket Assistance Program: Eligible commercially insured patients will pay no deductible, co-pay or co-insurance for two 30-count boxes per month; enrollment period is for 12 months after patients will be re-evaluated for continued eligibility in the program; for more information contact the program at 844-233-3146.",
                "brand": "Relizorb",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b49b"
            },
            {
                "_id": "6297bcace0e0db6c2572b4a0",
                "link": "https://www.remodulin.com/support-resources/remodulin-cost/",
                "description": "Remodulin Copayment Assistance Program: Eligible commercially insured patients may pay as little as $5 per prescription with savings of up to $8000 per year; for additional information contact the program at 877-864-8437.",
                "brand": "Remodulin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "8000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4a0"
            },
            {
                "_id": "6297bcace0e0db6c2572b4a5",
                "link": "https://www.repatha.com/repatha-cost",
                "description": "Repatha Copay Card: Eligible commercially insured patients may pay no more than $5 per prescription; for additional information contact the program at 844-737-2842.",
                "brand": "Repatha",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4a5"
            },
            {
                "_id": "6297bcace0e0db6c2572b4aa",
                "link": "https://www.restasis.com/termsofuse/termsandconditions5",
                "description": "Restasis My Tears, My Rewards Program for Mail-Order/Manual Claims (Mail-Order): If your pharmacy does not accept the savings card you may complete and submit the mail-order form to receive your proper savings; for additional information contact the program at 844-469-8327.",
                "brand": "Restasis",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4aa"
            },
            {
                "_id": "6297bcace0e0db6c2572b4af",
                "link": "https://uspco.com/product_literature.aspx?pid=390&tab=Voucher",
                "description": "Restora RX Pharmacy Voucher: Eligible patients may save up to 80% at participating pharmacies; for additional information contact the program at 888-602-2978.",
                "brand": "Restora RX",
                "copay": "No",
                "bin": "600428",
                "rxpcn": "5100000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4af"
            },
            {
                "_id": "6297bcace0e0db6c2572b4b4",
                "link": "https://www.aspirerewards.com/landing",
                "description": "Restylane Contour ASPIRE Galderma Rewards: Join today and receive a $60 treatment certificate instantly; other valuable discounts and rebates with sign up; for additional information contact customer service at 844-527-7473.",
                "brand": "Restylane Contour",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4b4"
            },
            {
                "_id": "6297bcace0e0db6c2572b4b9",
                "link": "https://www.aspirerewards.com/landing",
                "description": "Restylane Silk ASPIRE Galderma Rewards: Join today and receive a $60 treatment certificate instantly; other valuable discounts and rebates with sign up; for additional information contact customer service at 844-527-7473.",
                "brand": "Restylane Silk",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4b9"
            },
            {
                "_id": "6297bcace0e0db6c2572b4be",
                "link": "https://www.orthorxaccess.com/",
                "description": "Retin-A Micro Rx Access Program: Eligible commercially insured patients/Rx not covered may pay no more than $65 per fill; valid for 2 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Retin-A MICRO",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "2",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4be"
            },
            {
                "_id": "6297bcace0e0db6c2572b4c3",
                "link": "https://chiesitotalcare.com/revcovi/patients-caregivers/#copay-program",
                "description": "Revcovi Copay Program: Eligible commercially insured patients may pay as little as $0 per prescription; for additional information contact the program at 866-272-7078.",
                "brand": "Revcovi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4c3"
            },
            {
                "_id": "6297bcace0e0db6c2572b4c8",
                "link": "https://mycareclubrewards.com/Rhinocort/?utm_medium=partner&utm_source=rhinocort.com&utm_content=footer&utm_campaign=cc_main&_gl=1*gszgd4*_ga*MjAwOTYxNTY1My4xNjM2OTk3MjUw*_ga_13VEM6N66E*MTYzNzE2NDI1OC42LjEuMTYzNzE2NDMxNC40*_fplc*OE14YnVzeWJOMCUyQk96SnduNmxaZ1RxVXFPakFib0JodGlMZklVTVV4JTJCYlJMdG1ReTliV1p0NiUyRjZCTjJxb1NtTzNvJTJGNGtJMmdFV25NM0xndzg4Z1h6JTJCTUNvRm9Na2pKaCUyRkNVVVBybXlOV3pYVVlqTzM4aWR2MlZDejJCZVBnJTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Rhinocort products with registration.",
                "brand": "Rhinocort",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4c8"
            },
            {
                "_id": "6297bcace0e0db6c2572b4cd",
                "link": "https://ridlice.com/coupons/",
                "description": "Save $2 off any one RID product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "RID Daily Defense Shampoo and Conditioner",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4cd"
            },
            {
                "_id": "6297bcace0e0db6c2572b4d2",
                "link": "https://www.rinvoq.com/resources/save-on-rinvoq-costs",
                "description": "Rinvoq Rebate Program: Eligible commercially insured patients may submit prescription receipts for assistance; for additional information contact the program at 800-274-6867.",
                "brand": "Rinvoq",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6000",
                "lifetime_uses": "12",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4d2"
            },
            {
                "_id": "6297bcace0e0db6c2572b4d7",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Rituxan Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Rituxan",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4d7"
            },
            {
                "_id": "6297bcace0e0db6c2572b4dc",
                "link": "https://www.rixubis.com/support-and-resources#free-trial",
                "description": "Rixubis Free Trial Program: Eligible NEW patients may receive up to 4 Free doses of medications by enrolling in the Freedom of Choice Free Trial Program; for additional information contact the program at 888-229-8379.",
                "brand": "Rixubis",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4dc"
            },
            {
                "_id": "6297bcace0e0db6c2572b4e1",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Childrens DM Day Night Pack",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4e1"
            },
            {
                "_id": "6297bcace0e0db6c2572b4e6",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Cough and Chest Congestion DM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4e6"
            },
            {
                "_id": "6297bcace0e0db6c2572b4eb",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Honey Cough and Chest Congestion DM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4eb"
            },
            {
                "_id": "6297bcace0e0db6c2572b4f0",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Nighttime Cough DM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4f0"
            },
            {
                "_id": "6297bcace0e0db6c2572b4f5",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Sugar-Free Cough+Chest Congestion DM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4f5"
            },
            {
                "_id": "6297bcace0e0db6c2572b4fa",
                "link": "https://www.rogaine.com",
                "description": "Register your e-mail address and receive 10% off your 1st purchase of Rogaine.",
                "brand": "Rogaine for Men Unscented Foam",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4fa"
            },
            {
                "_id": "6297bcace0e0db6c2572b4ff",
                "link": "https://www.rogaine.com/products/rogaine-subscription-program",
                "description": "Rogaine Subscribe Program: Subscribe and save up to 20% per order and get free shipping; for additional information contact the program at 855-839-3838.",
                "brand": "Rogaine for Woman Unscented Foam",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4ff"
            },
            {
                "_id": "6297bcace0e0db6c2572b504",
                "link": "https://www.rolaids.com/en-us/coupon/",
                "description": "Save $2 on any one Rolaids product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Rolaids Advanced Antacid Plus Anti Gas Softchews",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b504"
            },
            {
                "_id": "6297bcace0e0db6c2572b509",
                "link": "https://www.medimetriks.com/prescription-brands/rosadan-cream-kit-rosadan-cream",
                "description": "Rosadan Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Rosadan",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b509"
            },
            {
                "_id": "6297bcace0e0db6c2572b50e",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Rozlytrek Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Rozlytrek",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b50e"
            },
            {
                "_id": "6297bcace0e0db6c2572b513",
                "link": "https://myviivcard.com",
                "description": "Rukobia ViiVConnect Savings Card: Eligible commercially insured patients pay $0 per prescription with savings of up to $7500 per year with no monthly limit; to obtain a new card or for additional information contact the program at 844-588-3288.",
                "brand": "Rukobia",
                "copay": "Yes",
                "bin": "610626",
                "rxpcn": "1016",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b513"
            },
            {
                "_id": "6297bcace0e0db6c2572b518",
                "link": "https://www.novomedlink.com/diabetes/samples.html",
                "description": "Rybelsus Samples: Healthcare providers may submit a sample request every 30 days.",
                "brand": "Rybelsus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b518"
            },
            {
                "_id": "6297bcace0e0db6c2572b51d",
                "link": "https://www.emdserono.com/us-en/expertise/endocrinology.html#anchor-2",
                "description": "Saizen Savings Co-Pay Program: Eligible commercially insured patients may save up to $2,400 a year; for additional information contact the program at 800-582-7989.",
                "brand": "Saizen",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "2400",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b51d"
            },
            {
                "_id": "6297bcace0e0db6c2572b522",
                "link": "https://www.saveonmyprescription.com/copay.jsp",
                "description": "Sandimmune $0 Co-Pay Card: Eligible commercially insured patients may pay $0 per prescription with maximum annual savings of up to $7,200; for additional information contact the program at 877-952-1000.",
                "brand": "Sandimmune",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "7200",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b522"
            },
            {
                "_id": "6297bcace0e0db6c2572b527",
                "link": "https://www.abbvieaccess.com/saphris",
                "description": "Saphris Samples: Healthcare providers may order free samples for their practice by logging in on the website or faxing an order form to 877-477-1258.",
                "brand": "Saphris",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b527"
            },
            {
                "_id": "6297bcace0e0db6c2572b52c",
                "link": "https://savaysa.com/savaysa-support",
                "description": "Savaysa Rebate: If your pharmacy does not accept the Savaysa Savings Card you may be able obtain a rebate for the savings amount; for additional information contact the program at 877-264-2440",
                "brand": "Savaysa",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3240",
                "lifetime_uses": "12",
                "max_saving_transaction": 270,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b52c"
            },
            {
                "_id": "6297bcace0e0db6c2572b531",
                "link": "https://www.us.scemblix.com",
                "description": "Scemblix Free Trial Offer: NEW commercially insured patients may be eligible to receive up to a 30-day free supply for FREE to allow them to begin treatment; for additional information contact the program at 877-577-7756.",
                "brand": "Scemblix",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b531"
            },
            {
                "_id": "6297bcace0e0db6c2572b536",
                "link": "https://www.secuado.com/secuado-co-pay-program/?_sp=b49802a7-9e4a-4a3a-94ed-b65b889f1b28.1631636587070",
                "description": "Secuado Co-payment Assistance Program: Eligible cash-paying patients may pay as little as $15 per prescription for up to 12 prescriptions; for more information contact the program at 888-526-0132.",
                "brand": "Secuado",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "8400",
                "lifetime_uses": "12",
                "max_saving_transaction": 600,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b536"
            },
            {
                "_id": "6297bcace0e0db6c2572b53b",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Select-OB+DHA Instant Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Select-OB DHA",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b53b"
            },
            {
                "_id": "6297bcace0e0db6c2572b540",
                "link": "https://www.selsunblue.com/en-us/coupon/",
                "description": "Save $1 on any Selsun Blue product; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Selsun Blue 2 in 1 Antidandruff Shampoo and Conditioner",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b540"
            },
            {
                "_id": "6297bcace0e0db6c2572b545",
                "link": "https://www.selsunblue.com/en-us/coupon/",
                "description": "Save $1 on any Selsun Blue product; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Selsun Blue Moisturizing Antidandruff Shampoo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b545"
            },
            {
                "_id": "6297bcace0e0db6c2572b54a",
                "link": "https://www.activatethecard.com/viatrisadvocate/semglee/welcome.html",
                "description": "Semglee Savings Card: Eligible commercially insured patients/RX not covered may pay as little as $0 per 30-day prescription with a maximum savings of $75; for more information contact the program at 800-657-7613.",
                "brand": "Semglee",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "900",
                "lifetime_uses": "12",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b54a"
            },
            {
                "_id": "6297bcace0e0db6c2572b54f",
                "link": "https://www.sensodyne.com/en-us/coupon/",
                "description": "Save $1 on any one Sensodyne toothpaste product (excludes travel sizes) with registration of your name and email address; coupon expires 1 month after printing.",
                "brand": "Sensodyne Full Protection",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b54f"
            },
            {
                "_id": "6297bcace0e0db6c2572b554",
                "link": "https://www.sensodyne.com/en-us/coupon/",
                "description": "Save $1 on any one Sensodyne toothpaste product (excludes travel sizes) with registration of your name and email address; coupon expires 1 month after printing.",
                "brand": "Sensodyne Rapid Relief Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b554"
            },
            {
                "_id": "6297bcace0e0db6c2572b559",
                "link": "https://www.sensodyne.com/en-us/coupon/",
                "description": "Save $1 on any one Sensodyne toothpaste product (excludes travel sizes) with registration of your name and email address; coupon expires 1 month after printing.",
                "brand": "Sensodyne Tartar Control",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b559"
            },
            {
                "_id": "6297bcace0e0db6c2572b55e",
                "link": "https://www.seroquelxr.com/coupon.html",
                "description": "Seroquel XR Savings Card: Eligible cash-paying patients may save up to $185 per 30-day supply; for additional information contact the program 888-547-8054.",
                "brand": "Seroquel XR",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "2220",
                "lifetime_uses": "12",
                "max_saving_transaction": 185,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b55e"
            },
            {
                "_id": "6297bcace0e0db6c2572b563",
                "link": "https://sevenfact.com/sevenfact-support/",
                "description": "Sevenfact Quick Start Program: Eligible commercially insured patients who need medication immediately but is delayed due to a a Prior Authorization may be able to receive medication from the program; for additional information contact the program at 855-718-4362.",
                "brand": "Sevenfact",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b563"
            },
            {
                "_id": "6297bcace0e0db6c2572b568",
                "link": "https://www.siklosusa.com/savings#siklosathome",
                "description": "Siklos At Home - Mail Order Pharmacy: Eligible insured/RX not covered and cash-paying patients pay as little as $99 for 60 tablets or $149 for 90 tablets through the program; or additional information contact the program at 844-716-4663.",
                "brand": "Siklos",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b568"
            },
            {
                "_id": "6297bcace0e0db6c2572b56d",
                "link": "https://siliqsavings.com",
                "description": "Siliq Solutions Instant Savings Program: Eligible commercially insured patients without coverage for RX or if insurance coverage is denied or delayed the patient may pay as little as $100 per month until coverage is approved or up to 12 months; for additional information contact the program at 844-357-2013.",
                "brand": "Siliq",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1666.66,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b56d"
            },
            {
                "_id": "6297bcace0e0db6c2572b572",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac Alimentum Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b572"
            },
            {
                "_id": "6297bcace0e0db6c2572b577",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac Go and Grow Sensitive Toddler Drink Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b577"
            },
            {
                "_id": "6297bcace0e0db6c2572b57c",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac Pro-Sensitive Infant Formula Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b57c"
            },
            {
                "_id": "6297bcace0e0db6c2572b581",
                "link": "https://www.janssencarepath.com/patient/simponi/cost-support",
                "description": "Simponi Janssen CarePath Savings Program Rebate: Eligible commercially insured patients may submit a rebate request if the pharmacy does not accept the Savings Card; for additional assistance contact the program at 877-227-3728.",
                "brand": "Simponi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b581"
            },
            {
                "_id": "6297bcace0e0db6c2572b586",
                "link": "https://www.activatethecard.com/7978/",
                "description": "Sivextro Savings Coupon: Eligible commercially insured patients may pay as little as $15 on 2 qualifying prescriptions (6 tablets) for a maximum savings of $1500 per fill; for additional information contact the program at 877-264-2454.",
                "brand": "Sivextro",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "2",
                "max_saving_transaction": 1500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b586"
            },
            {
                "_id": "6297bcace0e0db6c2572b58b",
                "link": "https://www.skyrizi.com/skyrizi-complete/save-on-skyrizi-costs",
                "description": "Skyrizi Medicare Part D Extra Help Subsidy: Patients who have Medicare Part D coverage may be eligible to apply for the Part D Extra Help Subsidy and once accepted to the program may pay $9.20 or less per monthly prescription; contact the program directly for questions or to sign-up.",
                "brand": "Skyrizi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b58b"
            },
            {
                "_id": "6297bcace0e0db6c2572b590",
                "link": "https://slynd.com/resources/",
                "description": "Slynd Savings Program: Eligible commercially insured patients pay as little as $25 per 30-day prescription; for additional information contact the program at 844-865-8685.",
                "brand": "Slynd",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b590"
            },
            {
                "_id": "6297bcace0e0db6c2572b595",
                "link": "https://www.bausch.com/our-products/contact-lenses/lenses-for-nearsighted-farsighted/soflens-daily-disposable-contact-lenses/soflens-free-trial-offer",
                "description": "SofLens Contact Products: Receive a FREE Trial Contact Lens Certificate; eye exam may be required; for additional information contact the program at 800-553-5340.",
                "brand": "SofLens Daily Disposables by Bausch and Lomb",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b595"
            },
            {
                "_id": "6297bcace0e0db6c2572b59a",
                "link": "https://www.solosec.com/bacterial-vaginosis/savings-card",
                "description": "Solosec Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription fill; for additional information contact the program at 833-500-6732.",
                "brand": "Solosec",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b59a"
            },
            {
                "_id": "6297bcace0e0db6c2572b59f",
                "link": "https://www.galdermacc.com/patients",
                "description": "Soolantra Cream CareConnect Savings Card: Eligible commercially insured patients may pay $20 for 45g prescription; for additional information contact the program at 855-280-0543.",
                "brand": "Soolantra",
                "copay": "Yes",
                "bin": "61054",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b59f"
            },
            {
                "_id": "6297bcace0e0db6c2572b5a4",
                "link": "https://qv.trialcard.com/sovaldi#/app/layout/home",
                "description": "Sovaldi Co-pay Coupon Program: Eligible commercially insured patients may pay no more than $5 per prescription; offer is valid for 6 months from the time of the first prescription fill; for additional information and to sign-up for the offer contact the program at 855-769-7284.",
                "brand": "Sovaldi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5a4"
            },
            {
                "_id": "6297bcace0e0db6c2572b5a9",
                "link": "https://www.spiriva.com/copd/savings-and-support/sign-up-for-savings",
                "description": "Spiriva Respimat Savings Card:  Eligible commercially insured patients may pay $0 per month with a maximum savings of up to $100 per 30-day fill; card valid for 12 uses per year; for additional information contact the program at 855-772-7223.",
                "brand": "Spiriva Respimat",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5a9"
            },
            {
                "_id": "6297bcace0e0db6c2572b5ae",
                "link": "https://www.spritam.com/#/patient/savings-and-support/savings-program",
                "description": "Spritam Serve Program: Eligible commercially insured/RX not covered and uninsured patients may pay $75 per 30 day supply with maximum savings of $5,000 per calendar year; for additional information contact the program at 844-777-4826.",
                "brand": "Spritam",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "12",
                "max_saving_transaction": 416.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5ae"
            },
            {
                "_id": "6297bcace0e0db6c2572b5b3",
                "link": "https://www.bmsaccesssupport.bmscustomerconnect.com/patient/financial-support",
                "description": "Sprycel BMS Access Support Co-pay Assistance Program: Eligible commercially insured patients may pay no more than $0 per month with savings of up to $15,000 per calendar year; for additional information contact the program at 800-861-0048.",
                "brand": "Sprycel",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5b3"
            },
            {
                "_id": "6297bcace0e0db6c2572b5b8",
                "link": "https://www.janssencarepath.com/patient/stelara/cost-support",
                "description": "Stelara Janssen CarePath Savings Program Rebate: Eligible commercially insured patients may submit a rebate request if the pharmacy does not accept the Savings Card; for additional assistance contact the program at 877-227-3728.",
                "brand": "Stelara",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5b8"
            },
            {
                "_id": "6297bcace0e0db6c2572b5bd",
                "link": "https://www.stiolto.com/savings-and-support/support-services",
                "description": "Stiolto Respimat Mail-Order Pharmacy Rebate: Eligible commercially insured patients using a mail-order pharmacy that does not accept the Savings Card may request a rebate online; for additional information contact the program at 888-777-1919.",
                "brand": "Stiolto Respimat",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5bd"
            },
            {
                "_id": "6297bcace0e0db6c2572b5c2",
                "link": "https://www.stopain.com/where-to-buy/",
                "description": "Sign up to receive emails and updates from StopPain.",
                "brand": "Stopain Cold Extra Strength Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5c2"
            },
            {
                "_id": "6297bcace0e0db6c2572b5c7",
                "link": "https://www.insupport.com/specialty-product/copay",
                "description": "Sublocade INSUPPORT Copay Assistance Program: Eligible commercially insured patients may pay as little as $5 per injection; offer valid for a maximum of 14 injections per calendar year; for additional information contact the program at 844-467-7778.",
                "brand": "Sublocade",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "13083.9",
                "lifetime_uses": "14",
                "max_saving_transaction": 1741.95,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5c7"
            },
            {
                "_id": "6297bcace0e0db6c2572b5cc",
                "link": "https://subvenite.com/savings-program/",
                "description": "Subvenite Savings Program: Eligible commercially insured patients may pay as little as $5 for any Subvenite Starter Kit; for more information contact the program at 800-273-6729.",
                "brand": "Subvenite",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5cc"
            },
            {
                "_id": "6297bcace0e0db6c2572b5d1",
                "link": "https://mycareclubrewards.com/Sudafed/?utm_medium=partner&utm_source=sudafed.com&utm_content=header&utm_campaign=cc_main&_gl=1*6y433b*_ga*NTAzNzk2MTQ2LjE2MzUxNzUyNDg.*_ga_13VEM6N66E*MTYzNTg3MjkzNi4zLjEuMTYzNTg3Mjk0OS40Nw..*_fplc*NTNqOUVYeU9heHpBaUFHZWRYaFQ4MHJlZDNaa0pIbHlHUWpaUmRtUHhlc0FEWkk0WWklMkJQV0laRU1wUGhMN2clMkZRQUlDbjRRY0tBaTVaYnVHcjN2VUNoSnc0elpLc2NSNEFDcktjNFRmUiUyRkp0QlE5cnlNWjE2NkpCQWUzWmJRJTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Sudafed products with registration.",
                "brand": "Sudafed PE Head Congestion plus Flu Severe",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5d1"
            },
            {
                "_id": "6297bcace0e0db6c2572b5d6",
                "link": "https://mycareclubrewards.com/Sudafed/?utm_medium=partner&utm_source=sudafed.com&utm_content=header&utm_campaign=cc_main&_gl=1*6y433b*_ga*NTAzNzk2MTQ2LjE2MzUxNzUyNDg.*_ga_13VEM6N66E*MTYzNTg3MjkzNi4zLjEuMTYzNTg3Mjk0OS40Nw..*_fplc*NTNqOUVYeU9heHpBaUFHZWRYaFQ4MHJlZDNaa0pIbHlHUWpaUmRtUHhlc0FEWkk0WWklMkJQV0laRU1wUGhMN2clMkZRQUlDbjRRY0tBaTVaYnVHcjN2VUNoSnc0elpLc2NSNEFDcktjNFRmUiUyRkp0QlE5cnlNWjE2NkpCQWUzWmJRJTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Sudafed products with registration.",
                "brand": "Sudafed Sinus 12 Hour Pressure plus Pain",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5d6"
            },
            {
                "_id": "6297bcace0e0db6c2572b5db",
                "link": "https://www.sundownnutrition.com/coupons/",
                "description": "Register your email address to receive special offers on Sundown Vitamin & Supplement products.",
                "brand": "Sundown Vitamin and Supplement Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5db"
            },
            {
                "_id": "6297bcace0e0db6c2572b5e0",
                "link": "https://www.sundownnutrition.com/coupons/",
                "description": "Register your email address to receive special offers on Sundown Vitamin & Supplement products.",
                "brand": "Sundown Vitamin and Supplement Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5e0"
            },
            {
                "_id": "6297bcace0e0db6c2572b5e5",
                "link": "https://www.oakneepainrelief.com/reimbursement-services/",
                "description": "Supartz FX BV360 Reimbursement Solution: Your healthcare provider may enroll you in the program by calling 833-692-8360 or by logging into the program portal website.",
                "brand": "Supartz FX",
                "copay": "",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5e5"
            },
            {
                "_id": "6297bcace0e0db6c2572b5ea",
                "link": "https://www.polident.com/en-us/save-now/poligrip-coupon/",
                "description": "Save $1 on any one Super Poligrip product with registration; 1 coupon per purchase.",
                "brand": "Super Poligrip Extra Care",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5ea"
            },
            {
                "_id": "6297bcace0e0db6c2572b5ef",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/overview/?utm_source=google&utm_medium=cpc&utm_term=super%20poligrip&utm_campaign=GS%20-%20Branded_Poligrip%20EX_DP&gclid=Cj0KCQiA962BBhCzARIsAIpWEL0O5zEg5LMkWP-xbEDatBaEd896PMS8BoY0ynLITYi-fwgKKWPSrcUaAiBqEALw_wcB&gclsrc=aw.ds",
                "description": "Super Poligrip Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Super Poligrip Free",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5ef"
            },
            {
                "_id": "6297bcace0e0db6c2572b5f4",
                "link": "https://www.supprelinla.com/patients/shares-savings-program/",
                "description": "Supprelin LA SHARES Copay Assistance Program: Eligible patients may pay as little as $10 with savings of up to $2000 off each prescription; for additional information contact the program at 855-270-0123.",
                "brand": "Supprelin LA",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5f4"
            },
            {
                "_id": "6297bcace0e0db6c2572b5f9",
                "link": "https://www.eyeoncopay.com/forms",
                "description": "Susvimo Genentech Ophthalmology Co-pay Program Rebate: Eligible commercially insured patients may request a rebate if they paid their provider directly for treatment before they enroled in the program; for additional information contact the program at 855-218-5307.",
                "brand": "Susvimo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "16000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5f9"
            },
            {
                "_id": "6297bcace0e0db6c2572b5fe",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Sutent Pfizer Oncology Together Mail-In Rebate: If a pharmacy does not accept the Savings Card the patient may submit a request for a rebate in connection with the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Sutent",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "2500",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.34,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5fe"
            },
            {
                "_id": "6297bcace0e0db6c2572b603",
                "link": "https://www.symjepi.com/symjepi_savings_program",
                "description": "Sympeji Savings Program: Eligible cash-paying/uninsured patients may  receive up to $100 off per prescription fill; for additional information contact the program at 877-264-2440.",
                "brand": "Symjepi",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b603"
            },
            {
                "_id": "6297bcace0e0db6c2572b608",
                "link": "https://www.sympazan.com/savings-and-support.html",
                "description": "Sympazan Savings Card: Eligible commercially insured patients may pay as little as $0 per 30-day supply prescription with no monthly or yearly maximum limits; for additional information contact the program at 844-320-8059.",
                "brand": "Sympazan",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b608"
            },
            {
                "_id": "6297bcace0e0db6c2572b60d",
                "link": "https://www.medimetriks.com/prescription-brands/synalar-ointment-kit-synalar-ointment",
                "description": "Synalar (ointment) Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Synalar",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b60d"
            },
            {
                "_id": "6297bcace0e0db6c2572b612",
                "link": "https://syndros.com/co-pay-program/",
                "description": "Syndros Cares Trial Voucher Program: Eligible patients may receive their 1st prescription bottle at no cost; for additional information contact the program at 877-369-5158.",
                "brand": "Syndros",
                "copay": "No",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b612"
            },
            {
                "_id": "6297bcace0e0db6c2572b617",
                "link": "https://www.synthroiddeliversprogram.com/cost?icid=inc_synthroid_dtc_delivers_learn-more-pricing",
                "description": "Synthroid Delivers Program: Eligible patients may pay as little as $29.50 per 30-day prescription with FREE home delivery; for additional information contact the program at 888-920-0527.",
                "brand": "Synthroid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b617"
            },
            {
                "_id": "6297bcace0e0db6c2572b61c",
                "link": "https://www.eyefile.com/TheEyeSolution/Register/0",
                "description": "Save $3 on your next purchase of any one Systane Eye Drops Product (10 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Systane Day and Night Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b61c"
            },
            {
                "_id": "6297bcace0e0db6c2572b621",
                "link": "https://www.eyefile.com/theeyesolution/Register",
                "description": "Save $3 on your next purchase of any one Systane Eye Drops Product (10 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Systane Ultra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b621"
            },
            {
                "_id": "6297bcace0e0db6c2572b626",
                "link": "https://www.takhzyro.com/copay-assistance",
                "description": "Takhzyro OnePath Co-Pay Assistance Program: Eligible commercially insured patients may save up to 100% off co-pay expenses up to the program maxium; for additional information contact the program at 866-888-0660.",
                "brand": "Takhzyro",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b626"
            },
            {
                "_id": "6297bcace0e0db6c2572b62b",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Talzenna Pfizer Oncology Together Mail-In Rebate: If a pharmacy does not accept the Savings Card the patient may submit a request for a rebate in connection with the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Talzenna",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.34,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b62b"
            },
            {
                "_id": "6297bcace0e0db6c2572b630",
                "link": "https://taperdex.com/",
                "description": "TaperDex Co-pay Savings Voucher: Eligible commercially insured patients may pay $0 on prescription fills; for additional information contact the program at 601-990-9497.",
                "brand": "TaperDex",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b630"
            },
            {
                "_id": "6297bcace0e0db6c2572b635",
                "link": "https://tavneos.com/support/",
                "description": "Tavneos Connect Copay Program: Eligible commercially insured patients may pay as little as $10 per prescription; for additional information and to enroll contact the program at 833-828-6367 option 2.",
                "brand": "Tavneos",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b635"
            },
            {
                "_id": "6297bcace0e0db6c2572b63a",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Tecentriq Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Tecentriq",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b63a"
            },
            {
                "_id": "6297bcace0e0db6c2572b63f",
                "link": "https://www.tena.us/subscription/auto-buy-walkthrough,en_US,pg.html",
                "description": "Tena Autobuy & Save Program: Products delivered on a recurring basis will receive a discount (5% discount on the first order, 10% on the second and 15% on all orders thereafter); all orders over $50 will receive free shipping; for additional information contact the program at 866-275-8362.",
                "brand": "Tena Personal Care Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b63f"
            },
            {
                "_id": "6297bcace0e0db6c2572b644",
                "link": "https://texacort.com",
                "description": "Texacort Savings Card: Eligible commercially insured patients may pay as little as $20 per fill on each of up to 12 prescriptions; for additional information contact the program at 347-766-9883.",
                "brand": "Texacort",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b644"
            },
            {
                "_id": "6297bcace0e0db6c2572b649",
                "link": "https://www.theraflu.com/coupon/",
                "description": "Register your email address and receive a $1.00 coupon off of any Theraflu product; limit 1 coupon per purchase; coupon expires 1�month after printing.",
                "brand": "Theraflu ExpressMax Daytime Severe Cold / Cough",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b649"
            },
            {
                "_id": "6297bcace0e0db6c2572b64e",
                "link": "https://www.theraflu.com/coupon/",
                "description": "Register your email address and receive a $1.00 coupon off of any Theraflu product; limit 1 coupon per purchase; coupon expires 1�month after printing.",
                "brand": "Theraflu Multi-Symptom Severe Cold",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b64e"
            },
            {
                "_id": "6297bcace0e0db6c2572b653",
                "link": "https://www.theratears.com/products/",
                "description": "Save $1 on TheraTears products; trial sizes not included in offer; one coupon per purchase.",
                "brand": "TheraTears Dry Eye Therapy Single Use Vials",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b653"
            },
            {
                "_id": "6297bcace0e0db6c2572b658",
                "link": "https://www.theratears.com/products/",
                "description": "Save $1 on TheraTears Eye Drops; trial sizes not included in offer; one coupon per purchase.",
                "brand": "TheraTears Sterilid Antimicrobial",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b658"
            },
            {
                "_id": "6297bcace0e0db6c2572b65d",
                "link": "https://www.thyrogen.com/patients/financial-assistance-programs",
                "description": "Thyrogen Co-Pay Assistance Program: Eligible commercially insured patients pay $0 copay per prescription with a maximum savings of up to $1000 per calendar year; for additional information contact the program 888-497-6436.",
                "brand": "Thyrogen",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b65d"
            },
            {
                "_id": "6297bcace0e0db6c2572b662",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Timoptic Bausch + Lomb Access Program: Eligible commercially insured patients may pay no more than $25 for each prescription; for additional information contact the program 866-693-4880.",
                "brand": "Timoptic in Ocudose",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b662"
            },
            {
                "_id": "6297bcace0e0db6c2572b667",
                "link": "https://www.tirosint.com/patient-savings-resources/tirosint-direct-program/",
                "description": "Tirosint-Sol Direct Program: Eligible patients ordering through the Tirosint Direct Program may pay as little as $40 per 90-day prescription; for additional information contact the program at 888-664-5427.",
                "brand": "Tirosint-SOL",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "4",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b667"
            },
            {
                "_id": "6297bcace0e0db6c2572b66c",
                "link": "https://myviivcard.com",
                "description": "Tivicay PD ViiVConnect Savings Card: Eligible commercially insured  patients pay $0 per prescription with savings of up to $5000 per year with no monthly limit; for additional information contact the program at 844-588-3288.",
                "brand": "Tivicay PD",
                "copay": "Yes",
                "bin": "610626",
                "rxpcn": "1016",
                "copay_max": "5000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b66c"
            },
            {
                "_id": "6297bcace0e0db6c2572b671",
                "link": "https://mytobradexst.com/#copay-program",
                "description": "Tobradex ST Copay Savings Program: Eligible Medicare patients, cash-paying patients and commercially insured patients/RX not covered pay $59 per fill; for additional information contact the program at 866-747-0976.",
                "brand": "TobraDex ST",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b671"
            },
            {
                "_id": "6297bcace0e0db6c2572b676",
                "link": "https://www.tosymra.com/savings/#savings-form",
                "description": "Tosymra Access Pathways Savings: Eligible commercially insured patients may pay $0 per monthly fill plus get FREE home delivery when using Blink Pharmacy Plus; for additional information contact the program at 844-976-3109.",
                "brand": "Tosymra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b676"
            },
            {
                "_id": "6297bcace0e0db6c2572b67b",
                "link": "https://www.patientrebateonline.com/patientrebate/welcome.html",
                "description": "Toujeo Solostar Valyou Savings Rebate: Eligible uninsured and cash-paying patients using a mail-order pharmacy can submit a request for a rebate up to the amount of savings earned with the Savings Card; or additional information contact the program at 866-390-5622.",
                "brand": "Toujeo Solostar",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b67b"
            },
            {
                "_id": "6297bcace0e0db6c2572b680",
                "link": "https://pah.janssencarepath.com/patient/tracleer/patient-support.html",
                "description": "Tracleer Janssen CarePath Oral PAH Savings Program: Eligible commercially insured patients may pay $5 per prescription fill; maximum savings of $20,000 per calendar year; for additional information contact the program at 866-228-3546.",
                "brand": "Tracleer",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b680"
            },
            {
                "_id": "6297bcace0e0db6c2572b685",
                "link": "https://www.trelegy.com/savings-and-coupons/",
                "description": "Trelegy Ellipta Co-pay Coupon: Eligible commercially insured patients may pay no more than $0 per 30/60/90-day supply with savings of up to $2400 per year; for additional information contact the program at 888-825-5249.",
                "brand": "Trelegy Ellipta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "2400",
                "lifetime_uses": "12",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b685"
            },
            {
                "_id": "6297bcace0e0db6c2572b68a",
                "link": "https://www.novocare.com/tresiba/savings-card.html",
                "description": "Tresiba Instant Savings Card: Eligible commercially insured patients may pay no more than $25 per 30-day prescription with savings of up to $150 per fill; offer valid for 24 months from date of savings card activation; for additional information contact the program at 833-992-3299.",
                "brand": "Tresiba",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b68a"
            },
            {
                "_id": "6297bcace0e0db6c2572b68f",
                "link": "https://www.novomedlink.com/diabetes/samples.html",
                "description": "Tresiba Samples: Healthcare providers may submit a sample request every 30 days.",
                "brand": "Tresiba U-200 FlexTouch",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b68f"
            },
            {
                "_id": "6297bcace0e0db6c2572b694",
                "link": "https://www.galdermacc.com/patients",
                "description": "Tri-Luma CareConnect Patient Savings Card: Eligible uninsured/cash-paying patients may pay no more than $105 per prescription; for additional information contact the program at 855-280-0543.",
                "brand": "Tri-Luma",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b694"
            },
            {
                "_id": "6297bcace0e0db6c2572b699",
                "link": "http://www.triaminic.com/signup",
                "description": "Register for special offers, latest news and updates for Triaminic products.",
                "brand": "Triaminic Multi-Symptom Fever",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b699"
            },
            {
                "_id": "6297bcace0e0db6c2572b69e",
                "link": "https://www.vertexgps.com/about-vertex-gps#overviewVid",
                "description": "Trikafta Vertex GPS Financial Assistance Program: Eligible commercially insured patients may pay as little as $15 per prescription; for additional information contact the program at 877-752-5933 (press 2).",
                "brand": "Trikafta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b69e"
            },
            {
                "_id": "6297bcace0e0db6c2572b6a3",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Tritocin Artesa Labs Advantage Card: Eligible commercially insured patients may pay $0 per prescription; for additional information contact the program 877-264-2440.",
                "brand": "Tritocin",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b6a3"
            },
            {
                "_id": "6297bcace0e0db6c2572b6a8",
                "link": "https://myviivcard.com",
                "description": "Trizivir ViiVConnect Savings Card: Eligible commercially insured patients pay $0 per prescription with savings of up to $4800 per year with no monthly limit; to obtain a new card or for additional information contact the program at 844-588-3288.",
                "brand": "Trizivir",
                "copay": "Yes",
                "bin": "610626",
                "rxpcn": "1016",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b6a8"
            },
            {
                "_id": "6297bcade0e0db6c2572b6ad",
                "link": "https://www.trubiotics.com/coupons/",
                "description": "Save $2 on any one TruBiotics Product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "TruBiotics Daily Probiotic Supplement",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6ad"
            },
            {
                "_id": "6297bcade0e0db6c2572b6b2",
                "link": "https://www.gileadadvancingaccess.com/financial-support/gilead-copay-card",
                "description": "Truvada Advancing Access Co-pay Program: Eligible commercially insured patients may save up to a maximum of $7200 per year with no monthly limit; for additional information contact the program at 800-226-2056.",
                "brand": "Truvada",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "ACCESS",
                "copay_max": "7200",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6b2"
            },
            {
                "_id": "6297bcade0e0db6c2572b6b7",
                "link": "https://www.tuzistraxr.com/",
                "description": "Tuzistra XR Pharmacy Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 855-847-6728.",
                "brand": "Tuzistra XR",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6b7"
            },
            {
                "_id": "6297bcade0e0db6c2572b6bc",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol 8 Hr Arthritis Pain",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6bc"
            },
            {
                "_id": "6297bcade0e0db6c2572b6c1",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol Cold Plus Liquid Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6c1"
            },
            {
                "_id": "6297bcade0e0db6c2572b6c6",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol Extra Strength Dissolve Packs for Adults",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6c6"
            },
            {
                "_id": "6297bcade0e0db6c2572b6cb",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol Regular Strength Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6cb"
            },
            {
                "_id": "6297bcade0e0db6c2572b6d0",
                "link": "https://www.tymlos.com/savings-support",
                "description": "Tymlos Mail-In Rebate: Eligible commercially insured patients may submit a rebate form for their out-of-pocket costs per prescription if their pharmacy does not accept the Savings Card; for additional information contact the program at 800-364-4767.",
                "brand": "Tymlos",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6500",
                "lifetime_uses": "12",
                "max_saving_transaction": 541.66,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6d0"
            },
            {
                "_id": "6297bcade0e0db6c2572b6d5",
                "link": "https://www.abbvieaccess.com/ubrelvy",
                "description": "Ubrelvy Samples: Healthcare providers may request samples for their office by logging on the website or faxing the order form to 877-477-1258.",
                "brand": "Ubrelvy",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6d5"
            },
            {
                "_id": "6297bcade0e0db6c2572b6da",
                "link": "https://ukoniq.com/patient/patientsupport",
                "description": "Ukoniq Commercial Co-Pay Program: Eligible commercially insured patients pay as little as $5 per prescription; for additional information contact the program directly at 877-848-9777.",
                "brand": "Ukoniq",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6da"
            },
            {
                "_id": "6297bcade0e0db6c2572b6df",
                "link": "https://www.unisom.com/en-us/coupons",
                "description": "Save $1 on any Unisom product (24 count or larger); one coupon per purchase; expires one month after printing",
                "brand": "Unisom PM Pain",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6df"
            },
            {
                "_id": "6297bcade0e0db6c2572b6e4",
                "link": "https://www.unisom.com/en-us/coupons",
                "description": "Save $1 on any Unisom product (24 count or larger); one coupon per purchase; expires one month after printing",
                "brand": "Unisom SleepTabs",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6e4"
            },
            {
                "_id": "6297bcade0e0db6c2572b6e9",
                "link": "https://uribelinfo.com/",
                "description": "Uribel Savings Card: Eligible commercially insured patients may pay as little as $20 per prescription; for additional information contact the program at 347-380-8119.",
                "brand": "Uribel",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6e9"
            },
            {
                "_id": "6297bcade0e0db6c2572b6ee",
                "link": "https://www.alvaprofessional.com/request-samples",
                "description": "Uricalm Product Samples: Your healthcare provider may request samples by filling out a form online.",
                "brand": "Uricalm Max",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6ee"
            },
            {
                "_id": "6297bcade0e0db6c2572b6f3",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Utopic Cream Artesa Labs Advantage Program: Eligible commercially insured patients may pay as little as $0 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Utopic Cream",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6f3"
            },
            {
                "_id": "6297bcade0e0db6c2572b6f8",
                "link": "https://vascepa.copaysavingsprogram.com",
                "description": "Vascepa Savings Card Program: Eligible patients pay as little as $9 with a savings of $150 per fill; for additional information contact the program at 855-497-8462.",
                "brand": "Vascepa",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6f8"
            },
            {
                "_id": "6297bcade0e0db6c2572b6fd",
                "link": "https://amgenfirststep.com",
                "description": "Vectibix Amgen First Step Program Rebate: Eligible commercially insured patients who have already paid for treatment may request a rebate check; for additional information contact the program at 888-657-8371.",
                "brand": "Vectibix",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6fd"
            },
            {
                "_id": "6297bcade0e0db6c2572b702",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Venclexta Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Venclexta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b702"
            },
            {
                "_id": "6297bcade0e0db6c2572b707",
                "link": "https://www.veregen.com/savings-and-support",
                "description": "Veregen Derm Copay Savings Program: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact 844-396-8097.",
                "brand": "Veregen",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b707"
            },
            {
                "_id": "6297bcade0e0db6c2572b70c",
                "link": "https://www.go-vgo.com/co-pay/",
                "description": "V-Go Cost Savings Card Rebate: Eligible commercially insured patients may request a rebate if their pharmacy or medical supply company is unable to process the savings card; the original pharmacy receipt and a copy of the savings card needs to be included with the rebate form; for additional information contact the program at 855-236-2128.",
                "brand": "VGo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b70c"
            },
            {
                "_id": "6297bcade0e0db6c2572b711",
                "link": "https://www.abbvieaccess.com/viberzi",
                "description": "Viberzi Free Trial Offer: Healthcare providers may obtain a FREE trial offer for their patients by calling 800-678-1605.",
                "brand": "Viberzi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b711"
            },
            {
                "_id": "6297bcade0e0db6c2572b716",
                "link": "https://www.pggoodeveryday.com/signup/",
                "description": "Join P&G Everyday to receive coupons, samples and savings on VICKS products with registration.",
                "brand": "VICKS NyQuil Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b716"
            },
            {
                "_id": "6297bcade0e0db6c2572b71b",
                "link": "https://www.abbvieaccess.com/viibryd",
                "description": "Viibryd Samples: Healthcare providers may order samples for their practice by visiting the website or faxing an order form to 877-477-1258.",
                "brand": "Viibryd",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b71b"
            },
            {
                "_id": "6297bcade0e0db6c2572b720",
                "link": "https://myviivcard.com/rebate-form-instructions.html",
                "description": "Viracept ViiVConnect Rebate: Eligible commercially insured patients may be eligible for a rebate for the amount paid out of pocket per prescription if the pharmacy does not accept the savings card; rebate may be completed online or by downloading and mailing in rebate form; for additional information contact the program at 844-588-3288.",
                "brand": "Viracept",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b720"
            },
            {
                "_id": "6297bcade0e0db6c2572b725",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Visine products with registration.",
                "brand": "Visine Advanced Redness plus Irritation Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b725"
            },
            {
                "_id": "6297bcade0e0db6c2572b72a",
                "link": "https://www.visine.com/coupon-offers",
                "description": "$1 coupon for any one Visine product (0.5 oz or larger); 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Visine Dry Eye Relief Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b72a"
            },
            {
                "_id": "6297bcade0e0db6c2572b72f",
                "link": "https://www.visine.com/coupon-offers",
                "description": "$1 coupon for any one Visine product (0.5 oz or larger); 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Visine Original Redness Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b72f"
            },
            {
                "_id": "6297bcade0e0db6c2572b734",
                "link": "https://www.visine.com/coupon-offers",
                "description": "$1 coupon for any one Visine product (0.5 oz or larger); 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Visine Red Eye Hydrating Comfort Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b734"
            },
            {
                "_id": "6297bcade0e0db6c2572b739",
                "link": "https://www.pfizermedicalinformation.com/en-us/vistaril",
                "description": "Vistaril Samples: Your healthcare provider may request samples by visiting the PfizerPro website or calling 800-505-4426.",
                "brand": "Vistaril",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b739"
            },
            {
                "_id": "6297bcade0e0db6c2572b73e",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Fe plus Instant Savings Card: Eligible cash-paying patients may pay approximately $65 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Vitafol Fe plus",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b73e"
            },
            {
                "_id": "6297bcade0e0db6c2572b743",
                "link": "https://vitafol.com/gummies/",
                "description": "Vitafol Gummies Samples: Your healthcare provider can request samples by completing a request form and faxing the form to 614-652-8275 or via email to ExeltisSamples@cardinalhealth.com.",
                "brand": "Vitafol Gummies",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b743"
            },
            {
                "_id": "6297bcade0e0db6c2572b748",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol One Mail-In Rebate: If your pharmacy does not accept the Savings Card or you use a mail-order pharmacy you may submit your original receipt, a photocopy of your savings card (front and back) along with your date of birth to the program to receive your proper savings; for additional information contact the program directly at 855-881-3090.",
                "brand": "Vitafol One",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b748"
            },
            {
                "_id": "6297bcade0e0db6c2572b74d",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Ultra Instant Savings Card: Eligible cash-paying patients may pay approximately $65 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Vitafol Ultra",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b74d"
            },
            {
                "_id": "6297bcade0e0db6c2572b752",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol-OB DHA Mail-In Rebate: If your pharmacy does not accept the Savings Card or you use a mail-order pharmacy you may submit your original receipt, a photocopy of your savings card (front and back) along with your date of birth to the program to receive your proper savings; for additional information contact the program directly at 855-881-3090.",
                "brand": "Vitafol-OB DHA",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b752"
            },
            {
                "_id": "6297bcade0e0db6c2572b757",
                "link": "https://www.gummyvites.com/en/Coupons2",
                "description": "Save $2 off any Vitafusion product when you signup online with your email address.",
                "brand": "Vitafusion Gummy MultiVites",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b757"
            },
            {
                "_id": "6297bcade0e0db6c2572b75c",
                "link": "https://savings.vitamedmdrx.com/activate/index.html",
                "description": "vitaMedMD RediChew Rx Savings Program: Eligible commercially insured patients may pay $25 or less per 30-day prescription; for additional information contact the program at 855-993-2665.",
                "brand": "vitaMedMD - RediChew Rx",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b75c"
            },
            {
                "_id": "6297bcade0e0db6c2572b761",
                "link": "https://od.vivitrol.com/co-pay-savings-program",
                "description": "Vivitrol Co-pay Savings Program: Eligible commercially insured and cash-paying patients may save up on their monthly co-pays; for additional information contact the program at 800-848-4876.",
                "brand": "Vivitrol",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b761"
            },
            {
                "_id": "6297bcade0e0db6c2572b766",
                "link": "https://www.voltarengel.com/samples-and-coupons/",
                "description": "Save $2 off any one Voltaren product (3.5 oz or larger) with sign up; 1 coupon per purchase.",
                "brand": "Voltaren Arthritis Pain",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b766"
            },
            {
                "_id": "6297bcade0e0db6c2572b76b",
                "link": "https://copay.novartisoncology.com/",
                "description": "Votrient Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Votrient",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b76b"
            },
            {
                "_id": "6297bcade0e0db6c2572b770",
                "link": "https://www.abbvieaccess.com/vraylar",
                "description": "Vraylar Samples: Healthcare providers may request samples for their practice by visting the website or faxing an order form to 877-477-1258.",
                "brand": "Vraylar",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b770"
            },
            {
                "_id": "6297bcade0e0db6c2572b775",
                "link": "https://www.vyndalink.com/patient",
                "description": "Vyndamax Co-Pay Savings Program: Eligible commercially insured patients may pay as little as $0 per monthly prescription with savings of up to $60,000 per calendar year; for additional information contact the program at 888-222-8475.",
                "brand": "Vyndamax",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "60000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b775"
            },
            {
                "_id": "6297bcade0e0db6c2572b77a",
                "link": "https://www.vyvanse.com/coupon",
                "description": "Vyvanse Savings Card: Eligible patients may pay as little as $30 per  prescription with savings of up to $60 per month; for additional information contact the program at 866-441-3469.",
                "brand": "Vyvanse",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 60,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b77a"
            },
            {
                "_id": "6297bcade0e0db6c2572b77f",
                "link": "https://welchol.com/savings-and-support",
                "description": "Welchol Savings Card: Eligible patients may pay no more than $0 for each 3-month supply and pay no more than $10 for a 1-month prescription; for additional assistance contact the program at 877-264-2440.",
                "brand": "Welchol",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b77f"
            },
            {
                "_id": "6297bcade0e0db6c2572b784",
                "link": "https://www.winlevi.com/",
                "description": "Winlevi Co-Pay Program: Eligible commercially insured patients may pay as little as $25 per 1 tube prescription with a maximum savings of $7,231 for 14 tubes; offer valid for a 12-month period;  for additional information contact the program at 877-264-2440.",
                "brand": "Winlevi",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "7231",
                "lifetime_uses": "14",
                "max_saving_transaction": 516.5,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b784"
            },
            {
                "_id": "6297bcade0e0db6c2572b789",
                "link": "https://www.xalatan.com/savings-terms",
                "description": "Xalatan Savings Card Rebate: Eligible patients may submit a request for a rebate if their pharmacy does not accept the Savings Card; for additional information contact the program at 866-562-6147.",
                "brand": "Xalatan",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1500",
                "lifetime_uses": "12",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b789"
            },
            {
                "_id": "6297bcade0e0db6c2572b78e",
                "link": "https://www.xanax.com/savings-terms",
                "description": "Xanax Mail-In Rebate: Eligible patients may take advantage of the mail-in rebate program if they use a mail-order pharmacy; for additional information contact the program at 855-854-4535.",
                "brand": "Xanax",
                "copay": "",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1500",
                "lifetime_uses": "12",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b78e"
            },
            {
                "_id": "6297bcade0e0db6c2572b793",
                "link": "https://www.xeljanz.com/savings-and-support?ind=ra",
                "description": "Xeljanz Co-Pay Savings Card: Eligible commercially insured patients may pay $0 out-of-pocket costs per month with a maximum benefit of $15,000 per calendar year; for additional information contact the program at 844-935-5269.",
                "brand": "Xeljanz",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1250,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b793"
            },
            {
                "_id": "6297bcade0e0db6c2572b798",
                "link": "https://www.xeljanz.com/savings-and-support/savings-faqs",
                "description": "Xeljanz XR Co-Pay Savings Card Rebate: Eligible commercially insured patients may submit a rebate if their pharmacy does not accept the Savings Card; for additional information contact the program at 844-935-5269.",
                "brand": "Xeljanz XR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b798"
            },
            {
                "_id": "6297bcade0e0db6c2572b79d",
                "link": "https://www.xenazineusa.com/",
                "description": "Xenazine Co-Pay Assistance Program: Eligible commercially insured patients may pay no more than $10 per 30-day supply prescription; for additional information contact the program at 888-882-6013.",
                "brand": "Xenazine",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b79d"
            },
            {
                "_id": "6297bcade0e0db6c2572b7a2",
                "link": "https://www.xeresesavings.com",
                "description": "Xerese Savings Co-Pay Card: Eligible commercially insured and uninsured cash-paying patients may save on their co-pays using the savings card at Walgreens; for additional information contact the program at 866-686-0122.",
                "brand": "Xerese",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7a2"
            },
            {
                "_id": "6297bcade0e0db6c2572b7a7",
                "link": "https://www.xhance.com/terms-and-conditions/",
                "description": "Xhance Preferred Pharmacy Network: Eligible commercially insured patients may pay $0 for their initial fill (up to a 90-day supply); if the patient is enrolled in the qualifying refill program with the pharmacy then refills will cost $0, if the patient is not enrolled then refills will cost $25; for additional information contact the program at 833-494-2623.",
                "brand": "Xhance",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1130,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7a7"
            },
            {
                "_id": "6297bcade0e0db6c2572b7ac",
                "link": "https://xifaxan.copaysavingsprogram.com",
                "description": "Xifaxan Savings Card:Eligible commercially insured patients/RX not covered may pay as little as $50 per prescription; contact the program for more information at 866-943-2926.",
                "brand": "Xifaxan",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7ac"
            },
            {
                "_id": "6297bcade0e0db6c2572b7b1",
                "link": "https://www.xiidra.com/getting-started-on-xiidra",
                "description": "Xiidra iinsider Card: Eligible commercially insured patients may pay as little as $0 per 30-day prescription with savings of up to $250 per month; for additional information contact the program at 877-494-4372.",
                "brand": "Xiidra",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7b1"
            },
            {
                "_id": "6297bcade0e0db6c2572b7b6",
                "link": "https://xolaircopay.com/using-the-card",
                "description": "Xolair (Drug) Co-Pay Card Reimbursement Request: If an�eligible commercially insured patients' provider or pharmacy requires that the patient pay upfront for treatment then the patient may submit a reimbursement request form; for further assistance contact the program at 855-965-2472.",
                "brand": "Xolair",
                "copay": "No",
                "bin": "",
                "rxpcn": "",
                "copay_max": "10000",
                "lifetime_uses": "12",
                "max_saving_transaction": 834,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7b6"
            },
            {
                "_id": "6297bcade0e0db6c2572b7bb",
                "link": "https://almiralladvantage.com/",
                "description": "Xolegel Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $15 per prescription when using a network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Xolegel",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7bb"
            },
            {
                "_id": "6297bcade0e0db6c2572b7c0",
                "link": "https://www.xtandi.com/financial-support",
                "description": "Xtandi Patient Savings Program: Eligible commercially insured patients may pay as little as $0 per prescription with savings of up to $25,000 per calendar year; for additional information contact the program at 855-898-2634.",
                "brand": "Xtandi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7c0"
            },
            {
                "_id": "6297bcade0e0db6c2572b7c5",
                "link": "https://www.xyosted.com/index.php/steadycare-support-program",
                "description": "Xyosted STEADYCare Co-pay Assistance Program: Eligible commercially insured patients may pay as low as $0 per prescription; offer valid for up to 13 fills per calendar year; for additional information contact the program at 844-996-7833.",
                "brand": "Xyosted",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1625",
                "lifetime_uses": "13",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7c5"
            },
            {
                "_id": "6297bcade0e0db6c2572b7ca",
                "link": "https://jazzcares.com/patients/xywav/",
                "description": "Xywav Coupon Program: Eligible commercially insured patients can pay as little as $5 for a 30-day fill; for additional information contact the program at 833-533-5299.",
                "brand": "Xywav",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7ca"
            },
            {
                "_id": "6297bcade0e0db6c2572b7cf",
                "link": "https://www.janssencarepath.com/patient/yondelis/cost-support",
                "description": "Yondelis Janssen CarePath Savings Program Rebate: Most eligible commercially insured patients may submit a request for rebate if the provider or pharmacy does not accept the Savings Program card; for additional information contact the program at 844-966-3354.",
                "brand": "Yondelis",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7cf"
            },
            {
                "_id": "6297bcade0e0db6c2572b7d4",
                "link": "https://www.zarxio.com/patient/support-services",
                "description": "Zarxio Sandoz One Source Co-Pay Program: Eligible commercially insured patients pay $0 out-of-pocket for 1st prescription and $0 for subsequent fills; annual savings of up to $10,000; for additional information contact the program at 844-726-3691.",
                "brand": "Zarxio",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7d4"
            },
            {
                "_id": "6297bcade0e0db6c2572b7d9",
                "link": "https://www.togetherwithgskoncology.com/patient-information/zejula/",
                "description": "Zejula Commercial Co-pay Assistance Program: Eligible patients may have $0 co-pay with savings of up to $26,000 per year; for additional information contact your healthcare provider or the program at 844-447-5662.",
                "brand": "Zejula",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "26000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7d9"
            },
            {
                "_id": "6297bcade0e0db6c2572b7de",
                "link": "https://www.zemaira.com/support",
                "description": "Zemaira Connect Program:  Eligible commercially insured patients may qualify for FREE medication if experiencing a lapse in insurance coverage; for additional information contact the program at 866-936-2472.",
                "brand": "Zemaira",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7de"
            },
            {
                "_id": "6297bcade0e0db6c2572b7e3",
                "link": "https://www.zeposia.com/multiple-sclerosis/copay/",
                "description": "Zeposia $0 Copay offer: Eligible commercially insured patients may pay as little as $0 per monthly prescription; maximum savings $18,000 per calendar year; for more information contact the program at 833-937-6742.",
                "brand": "Zeposia",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "18000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1500,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7e3"
            },
            {
                "_id": "6297bcade0e0db6c2572b7e8",
                "link": "http://www.zestril.com/patient-savings-program.html",
                "description": "Zestril Savings Program: Eligible commercially insured patients may pay as low as $25 per prescription with a maximum savings of $150 per fill of up to 59 tablets; for additional information contact the program at 844-728-3479.",
                "brand": "Zestril",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7e8"
            },
            {
                "_id": "6297bcade0e0db6c2572b7ed",
                "link": "https://www.ziextenzo.com/patient/support-services",
                "description": "Ziexrenzo Co-Pay Program: Eligible commercially insured patients may pay $0 per prescription with savings of up to $10,000 per year; for additional information contact the program at 844-726-3691.",
                "brand": "Ziextenzo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7ed"
            },
            {
                "_id": "6297bcade0e0db6c2572b7f2",
                "link": "https://www.zipsor.com/",
                "description": "Zipsor Savings Card: Eligible commercially insured patients may pay $0 copay per prescription fill; for additional information contact the program at 855-439-2820.",
                "brand": "Zipsor",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7f2"
            },
            {
                "_id": "6297bcade0e0db6c2572b7f7",
                "link": "https://eloracpharma.com/elorac-direct-savings-program/",
                "description": "Elorac Direct Savings Program (Zithranol): Healthcare professionals may contact Elorac for information about their Direct Savings Programs for patients by�filling out the form online; for additional information contact the program at 847-362-8200.",
                "brand": "Zithranol Shampoo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7f7"
            },
            {
                "_id": "6297bcade0e0db6c2572b7fc",
                "link": "https://www.zoloft.com/savings-terms",
                "description": "Zoloft Savings Card Rebate: Eligible patients may submit a request for a rebate if their pharmacy does not accept the Savings Card; for additional information contact the program at 855-220-9547.",
                "brand": "Zoloft",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7fc"
            },
            {
                "_id": "6297bcade0e0db6c2572b801",
                "link": "https://myzolpimist.com/#rxconnect-section",
                "description": "ZolpiMist Aytu RxConnect Pharmacy Network Program: Eligible uninsured/cash-paying patients may save $100 on each 30-day prescription when using a Aytu RxConnect Pharmacy; for additional information contact the program at 877-675-6590.",
                "brand": "ZolpiMist",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b801"
            },
            {
                "_id": "6297bcade0e0db6c2572b806",
                "link": "https://wraser-samples.com/collections/all",
                "description": "Zontivity FREE Samples: Your healthcare provider may request samples for their practice by creating an online account.",
                "brand": "Zontivity",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b806"
            },
            {
                "_id": "6297bcade0e0db6c2572b80b",
                "link": "https://www.ztlido.com/#savings-and-support",
                "description": "ZTlido Copay Savings Card: Eligible patients may as little as $0 per monthly prescription; for additional information contact the program at 800-433-4893.",
                "brand": "ZTlido",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b80b"
            },
            {
                "_id": "6297bcade0e0db6c2572b810",
                "link": "https://zuplenz.com/patient-resources-for-coping-with-nausea-during-chemotherapy/",
                "description": "Zuplenz Patient Assistance Program (Fortovia Direct Rx): Eligible patients may pay $0 on out-of-pocket costs per monthly prescription; for additional information contact the program at 855-273-0468.",
                "brand": "Zuplenz",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b810"
            },
            {
                "_id": "6297bcade0e0db6c2572b815",
                "link": "https://bauschlombpartd.copaysavingsprogram.com",
                "description": "Zylet Part D Coupon Program: Eligible patients may pay no more than $60 for each prescription fill; patients must have Medicare Part D or a Medicare Advantage prescription drug plan; for additional information contact the program at 800-795-1091.",
                "brand": "Zylet",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b815"
            },
            {
                "_id": "6297bcade0e0db6c2572b81a",
                "link": "https://mycareclubrewards.com/zyrtec/?utm_medium=partner&utm_source=zyrtec.com&utm_content=footer&utm_campaign=cc_main&_gl=1*i86ng6*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjQ3ODA2OS4zLjEuMTYzNjQ3ODE3Ny4xNQ..*_fplc*aTZTRSUyRktpOGJCeFFqdzlkb0ZtTmwlMkI1VXE4VkhJSERDdmI1Q1d6TklLMW1nOFVyaUJOQUdscnpCJTJGd1ZBRXFSSUlOaDVLSW5sMVoxSGtXOENrTFV2bUVGUk11YjRFS3JqZGNDN0VWR3JNMGFpMW9GNjJGZU9VWkdER1ZTa2x3JTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Zyrtec products with registration.",
                "brand": "Zyrtec",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b81a"
            },
            {
                "_id": "6297bcade0e0db6c2572b81f",
                "link": "https://www.zyrtecprofessional.com/order",
                "description": "Zyrtec Samples: Healthcare providers may order samples for their practice by visiting the website.",
                "brand": "Zyrtec Allergy-Children's",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b81f"
            },
            {
                "_id": "6297bcade0e0db6c2572b824",
                "link": "https://www.zyrtec.com/?action=sign-up",
                "description": "Receive a coupon for any one Zyrtec product and other exclusive offers  with sign up; 1 coupon per purchase.",
                "brand": "Zyrtec Dissolve Tabs",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b824"
            },
            {
                "_id": "6297bcade0e0db6c2572b829",
                "link": "https://www.zyrtecprofessional.com/order",
                "description": "Zyrtec Samples: Healthcare providers may order samples for their practice by visiting the website.",
                "brand": "Zyrtec Soothing Non-Medicated Face Wipes",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b829"
            },
            {
                "_id": "6297bcade0e0db6c2572b82e",
                "link": "https://mycareclubrewards.com/zyrtec/?utm_medium=partner&utm_source=zyrtec.com&utm_content=footer&utm_campaign=cc_main&_gl=1*i86ng6*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjQ3ODA2OS4zLjEuMTYzNjQ3ODE3Ny4xNQ..*_fplc*aTZTRSUyRktpOGJCeFFqdzlkb0ZtTmwlMkI1VXE4VkhJSERDdmI1Q1d6TklLMW1nOFVyaUJOQUdscnpCJTJGd1ZBRXFSSUlOaDVLSW5sMVoxSGtXOENrTFV2bUVGUk11YjRFS3JqZGNDN0VWR3JNMGFpMW9GNjJGZU9VWkdER1ZTa2x3JTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Zyrtec products with registration.",
                "brand": "Zyrtec-D",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b82e"
            },
            {
                "_id": "6297bcade0e0db6c2572b833",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for ZzzQuil Products!",
                "brand": "ZzzQuil Night Pain GelTabs",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b833"
            },
            {
                "_id": "6297bcace0e0db6c2572b174",
                "link": "https://www.linzess.com/savings-and-support/linzess-savings-program",
                "description": "Linzess Instant Savings Program: Eligible commercially insured patients may pay as little as $30 per 30-day prescription for up to 12 fills; for additional information contact the program at 855-226-3937.",
                "brand": "Linzess",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b174"
            },
            {
                "_id": "6297bcace0e0db6c2572b179",
                "link": "https://mycareclubrewards.com/listerine/?utm_medium=partner&utm_source=listerine.com&utm_content=banner&utm_campaign=cc_main&_gl=1*1srhw77*_ga*MjAwOTYxNTY1My4xNjM2OTk3MjUw*_ga_13VEM6N66E*MTYzNzA4NTM0NS41LjEuMTYzNzA4NjM4MC41OA..*_fplc*NklTRGQ1UjNacSUyQkh0aCUyRlVLJTJGelFxeUZEZVJvWSUyQk5pd1RDcWVwakRqbmZURSUyQndhcyUyRkZmV2ltZ0c5R3pqOFNBR0gxRmRZQ25oSEk2eWhMNUhXNHVyRkliTDE5bWFZcyUyRnNiRTVSZldXaDJ2MDh6d1RlRHlCVzFHblphJTJCa2dkdyUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Listerine products with registration.",
                "brand": "Listerine Naturals Fluoride Free Antiseptic Mouthwash",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b179"
            },
            {
                "_id": "6297bcace0e0db6c2572b17e",
                "link": "https://www.livalorx.com/savings/coupon/",
                "description": "Livalo Savings Card: Eligible cash-paying patients may pay as little as $18 per 30-day supply with savings of up to $75 per prescription fill; for additional information contact the program at 844-567-9504.",
                "brand": "Livalo",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "900",
                "lifetime_uses": "12",
                "max_saving_transaction": 75,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b17e"
            },
            {
                "_id": "6297bcace0e0db6c2572b183",
                "link": "https://www.abbvieaccess.com/loloestrin",
                "description": "Lo Loestrin Fe Samples: Healthcare providers may order samples for their practice by logging onto the website or faxing an order form to 877-477-1258.",
                "brand": "Lo Loestrin Fe",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b183"
            },
            {
                "_id": "6297bcace0e0db6c2572b188",
                "link": "https://www.lonhalamagnair.com/patient-resources/cost.html",
                "description": "Lonhala Magnair Savings Card: Eligible patients may pay as little as $0 on each of up to 12 (30-day supply) prescriptions with savings of up to $500 per fill; for additional information contact the program at 844-276-8262.",
                "brand": "Lonhala Magnair",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "6000",
                "lifetime_uses": "12",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b188"
            },
            {
                "_id": "6297bcace0e0db6c2572b18d",
                "link": "https://www.pfizeroncologytogether.com/patient/signup",
                "description": "Lorbrena Pfizer Oncology Together Co-Pay Savings Program (oral products): Eligible commercially insured patients may pay $0 per prescription with a maximum savings of up to $25,000 per calendar year; for additional information contact the program at 877-744-5675.",
                "brand": "Lorbrena",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b18d"
            },
            {
                "_id": "6297bcace0e0db6c2572b192",
                "link": "https://bauschlombpartd.copaysavingsprogram.com",
                "description": "Lotemax (Gel) Part D Coupon Program: Eligible patients may pay no more than $60 for each prescription fill; patients must have Medicare Part D or a Medicare Advantage prescription drug plan; for additional information contact the program at 800-795-1091.",
                "brand": "Lotemax Gel",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b192"
            },
            {
                "_id": "6297bcace0e0db6c2572b197",
                "link": "https://bauschlombpartd.copaysavingsprogram.com",
                "description": "Lotemax (Ointment)  Part D Coupon Program: Eligible patients may pay no more than $60 for each prescription fill; patients must have Medicare Part D or a Medicare Advantage prescription drug plan; for additional information contact the program at 800-795-1091.",
                "brand": "Lotemax Ointment",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b197"
            },
            {
                "_id": "6297bcace0e0db6c2572b19c",
                "link": "https://www.bauschaccessprogram.com",
                "description": "Lotemax Suspension Bausch + Lomb Access Program: Eligible cash-paying and commercially insured/RX not covered patients may pay no more than $60 per 5 mL bottle/$90 per 10 mL bottle/$120 per 15 mL  bottle prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Lotemax Suspension",
                "copay": "Yes",
                "bin": "1958",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b19c"
            },
            {
                "_id": "6297bcace0e0db6c2572b1a1",
                "link": "https://www.lotrimin.com/coupon",
                "description": "Sign up and save on any one Lotrimin; product coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Lotrimin AF Ringworm Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1a1"
            },
            {
                "_id": "6297bcace0e0db6c2572b1a6",
                "link": "https://mycareclubrewards.com/lubriderm/?utm_medium=partner&utm_source=lubriderm.com&utm_content=header&utm_campaign=cc_main&_gl=1*10t4k0d*_ga*MTMzNjU1MjM2My4xNjIxMjg5NDk4*_ga_13VEM6N66E*MTYyMTI4OTQ5Ny4xLjEuMTYyMTI4OTg5My43",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Lubriderm products with registration.",
                "brand": "Lubriderm Advanced Therapy Lotion Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1a6"
            },
            {
                "_id": "6297bcace0e0db6c2572b1ab",
                "link": "https://lucemyra.com/luminate-support-and-savings/#savings",
                "description": "Lucemyra Prescription Savings Co-Pay Assistance Program: Eligible commercially insured patients may pay as little as $0 per prescription; patient is able to have 3 fills every 6 months; for additional information contact the program at 877-264-2440.",
                "brand": "Lucemyra",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "2",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1ab"
            },
            {
                "_id": "6297bcace0e0db6c2572b1b0",
                "link": "https://www.amgenfirststep.com/?referID=AA360",
                "description": "Lumakras Amgen First Step Program: Eligible commercially insured patients may save on their out-of-pocket medication costs; for additional information contact the program at 888-427-7478.",
                "brand": "Lumakras",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1b0"
            },
            {
                "_id": "6297bcace0e0db6c2572b1b5",
                "link": "https://www.luprongyn.com/savings-and-support",
                "description": "Lupron Depot Savings Card Rebate: Eligible commercially insured patients and cash-paying patients may pay as little as $10 per dose by submitting a rebate request for the amount paid out of pocket for the prescription; for additional assistance contact the program at 855-587-7663.",
                "brand": "Lupron Depot",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1b5"
            },
            {
                "_id": "6297bcace0e0db6c2572b1ba",
                "link": "https://www.orthorxaccess.com/",
                "description": "Luzu Rx Access Program: Eligible commercially insured patients pay no more than $25 per prescription; valid for 6 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Luzu",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1ba"
            },
            {
                "_id": "6297bcace0e0db6c2572b1bf",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Lyumjev: Eligible commercially insured patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Lyumjev",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1bf"
            },
            {
                "_id": "6297bcace0e0db6c2572b1c4",
                "link": "https://www.insulinaffordability.com",
                "description": "Lilly Insulin Value Program for Lyumjev U-200 KwikPen: Eligible uninsured/cash-paying patients may pay as little as $35 per 30-day prescription; offer valid for up to 12 calendar months; annual maximum savings of $7500; for more information contact the program at 833-808-1234.",
                "brand": "Lyumjev U-200 KwikPen",
                "copay": "Yes",
                "bin": "1884",
                "rxpcn": "3F",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1c4"
            },
            {
                "_id": "6297bcace0e0db6c2572b1c9",
                "link": "https://acrotechpatientaccess.com",
                "description": "Marqibo STAR Copay Assistance Program: Eligible commercially insured patients will pay $0 copay for the 1st date of service and a $25 copay for  subsequent dates of service; maximum savings of $10,000 per calendar year; for additional information contact the program at 888-537-8277.",
                "brand": "Marqibo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1c9"
            },
            {
                "_id": "6297bcace0e0db6c2572b1ce",
                "link": "https://www.mederma.com/coupons/",
                "description": "Save $3 off any Mederma Scar Care product; one coupon per purchase.",
                "brand": "Mederma Advanced Scar Gel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1ce"
            },
            {
                "_id": "6297bcace0e0db6c2572b1d3",
                "link": "https://www.mederma.com/coupons/",
                "description": "Save $3 off any Mederma Scar Care product; one coupon per purchase.",
                "brand": "Mederma Scar Cream Plus SPF 30",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1d3"
            },
            {
                "_id": "6297bcace0e0db6c2572b1d8",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Mektovi Pfizer Oncology Together Co-Pay Savings Program Rebate (oral products): Eligible commercially insured patients may submit a request for a rebate in connection with this offer if their pharmacy does not accept the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Mektovi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1d8"
            },
            {
                "_id": "6297bcace0e0db6c2572b1dd",
                "link": "https://www.ferringfertility.com/patient-resources/",
                "description": "Menopur HeartTomorrow Program: Eligible cash-paying patients may pay $68 per vial of Menopur; patient must be undergoing a COS elective egg freezing cycle; for additional information contact the program at 888-347-3415.",
                "brand": "Menopur",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1dd"
            },
            {
                "_id": "6297bcace0e0db6c2572b1e2",
                "link": "https://www.metamucil.com/en-us/two-week-challenge",
                "description": "Save $2 off any Metamucil Fiber Product by registering your email; one coupon per purchase.",
                "brand": "Metamucil Fiber Capsules",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1e2"
            },
            {
                "_id": "6297bcace0e0db6c2572b1e7",
                "link": "https://www.metamucil.com/en-us/offers",
                "description": "Join P&G Everyday to receive coupons, samples and savings for Metamucil Products!",
                "brand": "Metamucil Fiber Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1e7"
            },
            {
                "_id": "6297bcace0e0db6c2572b1ec",
                "link": "http://cymedostomy.com/samples.html",
                "description": "MicroSkin Samples: Request a free sample of your choice ostomy product; contact program the program for additional information at 800-582-0707",
                "brand": "MicroSkin Ostomy Pouching Systems",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1ec"
            },
            {
                "_id": "6297bcace0e0db6c2572b1f1",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "MimyX Artesa Labs Advantage Program: Eligible commercially insured patients may pay as little as $0 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "MimyX",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1f1"
            },
            {
                "_id": "6297bcace0e0db6c2572b1f6",
                "link": "https://mitigare.triplefin.com/copay/download_card.aspx",
                "description": "Mitigare True Blue Savings Card: Eligible patients receive 1st prescription FREE and then pay as little as $5 per fill with a savings of $50 per fill; for additional information contact the program at 877-259-6893.",
                "brand": "Mitigare",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "600",
                "lifetime_uses": "12",
                "max_saving_transaction": 50,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1f6"
            },
            {
                "_id": "6297bcace0e0db6c2572b1fb",
                "link": "https://hcp.monistat.com/coupons",
                "description": "Monistat Product Coupons: Healthcare providers may order coupons for their practice.",
                "brand": "Monistat 3-Day Treatment Combination Pack with suppositories",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b1fb"
            },
            {
                "_id": "6297bcace0e0db6c2572b200",
                "link": "https://monoferric-patient-solutions.com/patient",
                "description": "Monoferric Patient Solutions Copay Program: Eligible commercially insured patients may pay as little as $0 per dose; maximum annual savings of $2000 on a total of 2 doses; for more information contact the program at 800-922-9022.",
                "brand": "Monoferric",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "2000",
                "lifetime_uses": "2",
                "max_saving_transaction": 1000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b200"
            },
            {
                "_id": "6297bcace0e0db6c2572b205",
                "link": "https://mycareclubrewards.com/motrin/?utm_medium=partner&utm_source=motrin.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1sghl7t*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjY0NzMxOC40LjEuMTYzNjY0NzMzMy40NQ..*_fplc*WWt2VDMxc2E1JTJCbWhGdlZndzlCTmt6TENXRFQxc0JuWkglMkY2TDRFQnl0SUdXYzc4QmEySTM4dnJhRmE3ak1nSTRqR0RrWHk5bjQzWWFRVDFKSmc0blc5ZVVyVHI2UUVkZURxenBQalBMZllaRjlLSnUxeXduUzdxMFF5Y1VWQSUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Motrin products with registration.",
                "brand": "Motrin Childrens Chewable Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b205"
            },
            {
                "_id": "6297bcace0e0db6c2572b20a",
                "link": "https://mycareclubrewards.com/motrin/?utm_medium=partner&utm_source=motrin.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1sghl7t*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjY0NzMxOC40LjEuMTYzNjY0NzMzMy40NQ..*_fplc*WWt2VDMxc2E1JTJCbWhGdlZndzlCTmt6TENXRFQxc0JuWkglMkY2TDRFQnl0SUdXYzc4QmEySTM4dnJhRmE3ak1nSTRqR0RrWHk5bjQzWWFRVDFKSmc0blc5ZVVyVHI2UUVkZURxenBQalBMZllaRjlLSnUxeXduUzdxMFF5Y1VWQSUzRCUzRA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Motrin products with registration.",
                "brand": "Motrin Infants",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b20a"
            },
            {
                "_id": "6297bcace0e0db6c2572b20f",
                "link": "https://www.mucinex.com/pages/savings",
                "description": "Register your email address to receive a $5 coupon for any Mucinex product and future savings offers.",
                "brand": "Mucinex Childrens Cold and Flu",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b20f"
            },
            {
                "_id": "6297bcace0e0db6c2572b214",
                "link": "https://www.rbhcprofessional.com/s/",
                "description": "Mucinex Samples: Healthcare providers may register to order samples for their practice.",
                "brand": "Mucinex Childrens Multi-Symptom",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b214"
            },
            {
                "_id": "6297bcace0e0db6c2572b219",
                "link": "https://www.rbhcprofessional.com/s/",
                "description": "Mucinex Samples: Healthcare providers may register to order samples for their practice.",
                "brand": "Mucinex Fast-Max Caplets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b219"
            },
            {
                "_id": "6297bcace0e0db6c2572b21e",
                "link": "https://www.mucinex.com/pages/savings",
                "description": "Register your email address to receive a $5 coupon for any Mucinex product and future savings offers.",
                "brand": "Mucinex Fast-Max Liquid Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b21e"
            },
            {
                "_id": "6297bcace0e0db6c2572b223",
                "link": "https://www.mucinex.com/pages/savings",
                "description": "Register your email address to receive a $5 coupon for any Mucinex product and future savings offers.",
                "brand": "Mucinex Severe Congestion Relief Nasal Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b223"
            },
            {
                "_id": "6297bcace0e0db6c2572b228",
                "link": "https://www.multaq.com/savings",
                "description": "Multaq Savings Card: Eligible commercially insured patients may pay $0 per monthly fill on 13 fills; for additional information contact the program at 855-699-8685.",
                "brand": "Multaq",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "3000",
                "lifetime_uses": "13",
                "max_saving_transaction": 400,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b228"
            },
            {
                "_id": "6297bcace0e0db6c2572b22d",
                "link": "https://amgenfirststep.com/register-card",
                "description": "Mvasi Amgen First Step Program: Eligible commercially insured patients may save on their out-of-pocket medication costs; for additional information contact the program at 888-427-7478.",
                "brand": "Mvasi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b22d"
            },
            {
                "_id": "6297bcace0e0db6c2572b232",
                "link": "https://www.myfembree.com/commercial-insurance/",
                "description": "Myfembree Bridge Program: Eligible commercially insured patients may receive a free supply of medication for up to 4 months if their insurance coverage is delayed or interrupted; for additional information contact the program at 833-693-3627.",
                "brand": "Myfembree",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b232"
            },
            {
                "_id": "6297bcace0e0db6c2572b237",
                "link": "https://www.myrbetriq.com/cost-savings-and-support/",
                "description": "Myrbetriq Momentum Savings Card: Eligible cash-paying patients may pay as little as $20 for each subsequent prescription; maximum savings of $840 for 12 uses; for additional information contact the program at 800-477-6472.",
                "brand": "Myrbetriq",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "840",
                "lifetime_uses": "12",
                "max_saving_transaction": 70,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b237"
            },
            {
                "_id": "6297bcace0e0db6c2572b23c",
                "link": "https://naftin.com/patient-co-pay-assistance.html",
                "description": "Naftin Co-Pay Savings Program: Eligible cash-paying patients may pay $75 per prescription; savings card is good for 6 uses; for additional information contact the program at 888-296-1852.",
                "brand": "Naftin",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "2688",
                "lifetime_uses": "6",
                "max_saving_transaction": 448,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b23c"
            },
            {
                "_id": "6297bcace0e0db6c2572b241",
                "link": "https://www.namzaric.com/trial-offer",
                "description": "Namzaric FREE Trial Offer: Eligible patients may receive a 30-day trial supply for $0 out-of-pocket costs; for additional information contact the program 855-618-0302.",
                "brand": "Namzaric",
                "copay": "No",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b241"
            },
            {
                "_id": "6297bcace0e0db6c2572b246",
                "link": "https://www.narcan.com/patients/how-to-get-narcan/",
                "description": "Narcan Co-pay Savings: Eligible commercially insured patients may have a co-pay of $0-$20 per prescription depending on insurance; for additional information contact the program at 844-462-7226.",
                "brand": "Narcan",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b246"
            },
            {
                "_id": "6297bcace0e0db6c2572b24b",
                "link": "https://www.nascobal.com/nutritiondirect/#why_nutrition_direct",
                "description": "Nascobal Nutrition Direct Program: Eligible patients may pay as little as $0  per month for supplements; maximum savings of $150; program delivers supplements to your home; for additional information contact the program at 866-439-3685.",
                "brand": "Nascobal",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b24b"
            },
            {
                "_id": "6297bcace0e0db6c2572b250",
                "link": "https://www.natesto.com/support-and-resources/#save-on-natesto",
                "description": "Natesto Cash Option Program: Eligible commercially insured patients who have exceeded the $3500 annual benefit  for the Natesto Savings Card benefit program and have a co-pay or co-insurance which exceeds $140 per 30-day prescription in a 12-month benefit may use the Natesto Cash Option Program until the new benefit year begins;  for additional information contact the program at 855-390-0162.",
                "brand": "Natesto",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b250"
            },
            {
                "_id": "6297bcace0e0db6c2572b255",
                "link": "https://www.alvaprofessional.com/stomach-discomfort",
                "description": "Nauzene Product Samples: Your healthcare provider may request samples by filling out a form online.",
                "brand": "Nauzene Kids",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b255"
            },
            {
                "_id": "6297bcace0e0db6c2572b25a",
                "link": "https://www.medimetriks.com/prescription-brands/neo-synalar-neo-synalar-kit",
                "description": "Neo-Synalar Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Neo-Synalar",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b25a"
            },
            {
                "_id": "6297bcace0e0db6c2572b25f",
                "link": "https://mycareclubrewards.com/neosporin/?utm_medium=partner&utm_source=neosporin.com&utm_content=header&utm_campaign=cc_main&_gl=1*1lx5xvo*_ga*MjA5MDY2NzQ5OS4xNjMzMzU4MTUw*_ga_13VEM6N66E*MTYzMzM1ODE1MC4xLjAuMTYzMzM1ODE1MC42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neosporin products with registration.",
                "brand": "Neosporin Neo To Go Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b25f"
            },
            {
                "_id": "6297bcace0e0db6c2572b264",
                "link": "https://mycareclubrewards.com/neosporin/?utm_medium=partner&utm_source=neosporin.com&utm_content=header&utm_campaign=cc_main&_gl=1*1lx5xvo*_ga*MjA5MDY2NzQ5OS4xNjMzMzU4MTUw*_ga_13VEM6N66E*MTYzMzM1ODE1MC4xLjAuMTYzMzM1ODE1MC42MA..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neosporin products with registration.",
                "brand": "Neosporin Three Ingredient Antibiotic Ointment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b264"
            },
            {
                "_id": "6297bcace0e0db6c2572b269",
                "link": "http://wcpharma.com/patient-savings/",
                "description": "Nestabs Women's Choice Savings Coupon: Eligible uninsured/cash-paying patients may pay no more than $50 per prescription; for additional information contact the program at 800-664-1490.",
                "brand": "Nestabs",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b269"
            },
            {
                "_id": "6297bcace0e0db6c2572b26e",
                "link": "https://www.medimetriks.com/prescription-brands/neuac-kit-neuac",
                "description": "Neuac Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Neuac",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b26e"
            },
            {
                "_id": "6297bcace0e0db6c2572b273",
                "link": "https://amgenfirststep.com/register-card",
                "description": "Neupogen Amgen First Step Program: Eligible commercially insured patients may save on their out-of-pocket medication costs; for additional information contact the program at 888-427-7478.",
                "brand": "Neupogen",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b273"
            },
            {
                "_id": "6297bcace0e0db6c2572b278",
                "link": "https://www.neutrogena.com/offers.html",
                "description": "Sign up and receive 15% off 1st purchase plus free shipping on purchases $25 or more when you shop online for any one Neutrogena Product.",
                "brand": "Neutrogena Clear Pore Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b278"
            },
            {
                "_id": "6297bcace0e0db6c2572b27d",
                "link": "https://www.neutrogena.com/care-club.html",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neutrogena products with registration.",
                "brand": "Neutrogena Gentle Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b27d"
            },
            {
                "_id": "6297bcace0e0db6c2572b282",
                "link": "https://www.neutrogena.com/care-club.html",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neutrogena products with registration.",
                "brand": "Neutrogena Naturals Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b282"
            },
            {
                "_id": "6297bcace0e0db6c2572b287",
                "link": "https://www.neutrogena.com/offers.html",
                "description": "Sign up and receive 15% off 1st purchase plus free shipping on purchases $25 or more when you shop online for any one Neutrogena Product.",
                "brand": "Neutrogena Skin Balancing Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b287"
            },
            {
                "_id": "6297bcace0e0db6c2572b28c",
                "link": "https://www.neutrogena.com/care-club.html",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Neutrogena products with registration.",
                "brand": "Neutrogena T-Gel Hair and Scalp Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b28c"
            },
            {
                "_id": "6297bcace0e0db6c2572b291",
                "link": "https://newskinproducts.com/pages/coupons",
                "description": "Save $2 on any New Skin product; 1 coupon per purchase.",
                "brand": "New-Skin Scar Fade",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b291"
            },
            {
                "_id": "6297bcace0e0db6c2572b296",
                "link": "https://www.nexcare.com/3M/en_US/nexcare/deals-offers/",
                "description": "Sign-up with Nexcare Brand Products for special offers and coupons sent directly to your email address.",
                "brand": "Nexcare Hot and Cold Compress Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b296"
            },
            {
                "_id": "6297bcace0e0db6c2572b29b",
                "link": "https://www.purplepill.com/savings.html",
                "description": "Nexium Savings Card: Eligible commercially insured patient may pay as little as $15 per 30-day supply with a maximum savings of $125 per fill; for additional information contact the program at 855-687-2144.",
                "brand": "Nexium",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1500",
                "lifetime_uses": "12",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b29b"
            },
            {
                "_id": "6297bcace0e0db6c2572b2a0",
                "link": "https://www.nexlizethcp.com/sample-request",
                "description": "Nexletol Samples: Healthcare professionals may order free samples for their practice.",
                "brand": "Nexletol",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2a0"
            },
            {
                "_id": "6297bcace0e0db6c2572b2a5",
                "link": "https://www.medimetriks.com/prescription-brands/nicadan",
                "description": "Nicadan Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Nicadan",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2a5"
            },
            {
                "_id": "6297bcace0e0db6c2572b2aa",
                "link": "https://www.nicorette.com/coupons.html",
                "description": "Save $5 on any NicoDerm CQ Product (14 count or larger) when you sign-up online with your email address; 1 coupon per purchase.",
                "brand": "NicoDerm CQ Step 2",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2aa"
            },
            {
                "_id": "6297bcace0e0db6c2572b2af",
                "link": "https://www.nicorette.com/coupons.html",
                "description": "Save $5 on any Nicorette Product (72 count or larger) when you sign-up online with your email address; 1 coupon per purchase.",
                "brand": "Nicorette Gum",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2af"
            },
            {
                "_id": "6297bcace0e0db6c2572b2b4",
                "link": "https://niferex.com/savings/",
                "description": "Niferex eVoucherRx Program: Eligible patients pay no more than $30 per prescription; for additional information contact the program at 888-612-8466.",
                "brand": "Niferex",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2b4"
            },
            {
                "_id": "6297bcace0e0db6c2572b2b9",
                "link": "https://www.nityr.us/patient/why-nityr/free-nityr",
                "description": "Nityr 30 Day Free Trial Offer: Eligible patients may receive a 30 day supply of tablets for FREE with a prescription from healthcare provider; for additional information contact the program at 800-847-8714.",
                "brand": "Nityr",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2b9"
            },
            {
                "_id": "6297bcace0e0db6c2572b2be",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Nolix Artesa Labs Advantage Program: Eligible commercially insured patients may pay as little as $0 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Nolix",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2be"
            },
            {
                "_id": "6297bcace0e0db6c2572b2c3",
                "link": "https://uspco.com/product_literature.aspx?pid=431&tab=Voucher",
                "description": "Norel AD Pharmacy Voucher: Eligible patients may save up to 80% at participating pharmacies; for additional information contact the program at  888-602-2978.",
                "brand": "Norel AD",
                "copay": "No",
                "bin": "600428",
                "rxpcn": "5100000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2c3"
            },
            {
                "_id": "6297bcace0e0db6c2572b2c8",
                "link": "https://www.northera.com/patient-support/paying-for-northera",
                "description": "Northera Commercial Copay Assistance Program: Eligible commercially insured patients may pay no more than $10 per 30-day prescription; for additional information contact the program at 844-601-0101.",
                "brand": "Northera",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2c8"
            },
            {
                "_id": "6297bcace0e0db6c2572b2cd",
                "link": "https://www.kyowakirincares.com/nourianz-patients/",
                "description": "Nourianz Co-Pay Card Program: Eligible commercially insured patients may pay the 1st $20 per 30-day supply with savings of up to $5000 per calendar year; for additional information contact the program at 833-552-2737.",
                "brand": "Nourianz",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "12",
                "max_saving_transaction": 417,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2cd"
            },
            {
                "_id": "6297bcace0e0db6c2572b2d2",
                "link": "https://www.novoeight.com/how-we-can-help/hemophilia-education/novoeight-trial-prescription-program.html",
                "description": "Novoeight Free Trial Prescription Program: Eligible patients may receive a trial prescription of up to 6 doses; for additional information contact the program at 844-668-6732.",
                "brand": "Novoeight",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2d2"
            },
            {
                "_id": "6297bcace0e0db6c2572b2d7",
                "link": "https://www.novocare.com/insulin/my99insulin.html?src=100001849",
                "description": "Novolin N My$99Insulin Card: Eligible patients pay $99 per monthly prescription (up to 3 vials or 2 packs of pens) of Novo Nordisk insulin products; offer may be used once a month during a calendar year; for additional information contact the program at 888-910-0632.",
                "brand": "Novolin N",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2d7"
            },
            {
                "_id": "6297bcace0e0db6c2572b2dc",
                "link": "https://www.novocare.com/insulin/my99insulin.html?src=100001849",
                "description": "NovoLog Mix 70/30 My$99Insulin Card: Eligible patients pay $99 per monthly prescription (up to 3 vials or 2 packs of pens) of Novo Nordisk insulin products; offer may be used once a month during a calendar year; for additional information contact the program at 888-910-0632.",
                "brand": "NovoLog Mix 70-30",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2dc"
            },
            {
                "_id": "6297bcace0e0db6c2572b2e1",
                "link": "https://amgenfirststep.com",
                "description": "Nplate Amgen First Step Program Rebate: Eligible commercially insured patients who have already paid for treatment may request a rebate check; for additional information contact the program at 888-657-8371.",
                "brand": "Nplate",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2e1"
            },
            {
                "_id": "6297bcace0e0db6c2572b2e6",
                "link": "https://www.nucynta.com/patient/home/",
                "description": "Nucynta Savings Card: Eligible commercially insured patients may pay as little as $35 per fill with a maximum savings of up to $100 per prescription; for additional information contact the program at 844-807-0348.",
                "brand": "Nucynta",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2e6"
            },
            {
                "_id": "6297bcace0e0db6c2572b2eb",
                "link": "https://www.bmsaccesssupport.bmscustomerconnect.com/patient/financial-support",
                "description": "Nulojix BMS Kidney Transplant Co-Pay Assistance Program: Eligible commercially insured patients may pay as little as $50 per each outpatient dose with savings of up to $7000 per calendar year; for additional information contact the program at 800-861-0048.",
                "brand": "Nulojix",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "7000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2eb"
            },
            {
                "_id": "6297bcace0e0db6c2572b2f0",
                "link": "https://www.enfamil.com/nutramigen-savings-and-support-program/",
                "description": "Join Nutramigen Savings and Support and save up to $130 with valuable offers, coupons and free samples with registration; for additional information contact the program at 800-222-9123.",
                "brand": "Nutramigen with Enflora LGG Toddler",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2f0"
            },
            {
                "_id": "6297bcace0e0db6c2572b2f5",
                "link": "https://www.nuvessa.com",
                "description": "Nuvessa Copay Card: Eligible uninsured/cash-paying patients may pay  only $55 per prescription; for additional information contact the program at 844-558-2650.",
                "brand": "Nuvessa",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2f5"
            },
            {
                "_id": "6297bcace0e0db6c2572b2fa",
                "link": "http://obcomplete.com/coupons/",
                "description": "OB Complete Savings Card: Eligible patients may pay $10 per 30-day prescription with savings of up to $50 per fill; for additional information contact the program at 844-727-5540.",
                "brand": "OB Complete One",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "600",
                "lifetime_uses": "12",
                "max_saving_transaction": 50,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2fa"
            },
            {
                "_id": "6297bcace0e0db6c2572b2ff",
                "link": "https://www.hematologysupport.com/financial-assistance",
                "description": "Obizur Takeda HSC CoPay Assistance Program: Eligible commercially insured patients' eligible out-of-pocket costs to be covered 100% by the program; for additional information contact the program at 888-229-8379.",
                "brand": "Obizur",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b2ff"
            },
            {
                "_id": "6297bcace0e0db6c2572b304",
                "link": "https://www.ocuvite.com/buy-now",
                "description": "Receive a $3 coupon off any one Ocuvite product; email address required; 1 coupon per purchase.",
                "brand": "Ocuvite Eye Health Formula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b304"
            },
            {
                "_id": "6297bcace0e0db6c2572b309",
                "link": "https://www.ocuvite.com/buy-now",
                "description": "Receive a $3 coupon off any one Ocuvite product; email address required; 1 coupon per purchase.",
                "brand": "Ocuvite Lutien",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b309"
            },
            {
                "_id": "6297bcace0e0db6c2572b30e",
                "link": "https://www.alksavings.com/cb/access/crp/aLkYd2j78Vbb/fid.jsp?q=51G5OI5442CUDK4PLWTLX5&f=IMPX6IG-274EMWRGK6802#/app/layout/home",
                "description": "Odactra ALK Savings Card: Eligible commercially insured patients/RX not covered may pay as little as $75 per 30-day prescription if using an ALK Network Pharmacy; valid for 12 uses or $3,200 per year (whichever comes first); for additional information contact the program at 800-325-7354.",
                "brand": "Odactra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3200",
                "lifetime_uses": "12",
                "max_saving_transaction": 265,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b30e"
            },
            {
                "_id": "6297bcace0e0db6c2572b313",
                "link": "https://www.olumiant.com/savings",
                "description": "Olumiant Savings Card: Eligible commercially insured/Rx not covered patients may pay only $25 for each 30-day prescription; for additional information contact the program at 844-658-6426.",
                "brand": "Olumiant",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b313"
            },
            {
                "_id": "6297bcace0e0db6c2572b318",
                "link": "https://www.omnipod.com/is-omnipod-right-for-me/coverage/financial-assistance",
                "description": "Omnipod Insulin Management System Financial Assistance Program: Eligible patients may receive financial support for their prescription through the program; for more information contact the program at 800-591-3455, option 3.",
                "brand": "Omnipod",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b318"
            },
            {
                "_id": "6297bcace0e0db6c2572b31d",
                "link": "https://www.oneaday.com/coupons",
                "description": "Save $1 on any one One-A-Day product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "One-A-Day Mens Multivitamin products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b31d"
            },
            {
                "_id": "6297bcace0e0db6c2572b322",
                "link": "https://www.onetouch.com/support/rxfinder",
                "description": "OneTouch Ultra Test Strip Automatic Savings Program: Eligible commercially insured/RX not covered and cash-paying patients may pay as little as $25 per 100 count of OneTouch Ultra Test Strips; can only be redeemed where OneTouch products are sold and prescriptions can be processed; requires a valid prescription; for additional information contact the program at 800-227-8862.",
                "brand": "OneTouch Ultra",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b322"
            },
            {
                "_id": "6297bcace0e0db6c2572b327",
                "link": "https://www.onetouch.com/support/rxfinder",
                "description": "OneTouch Ultra Test Strip Automatic Savings Program: Eligible commercially insured/RX not covered and cash-paying patients may pay as little as $25 per 100 count of OneTouch Ultra Test Strips; can only be redeemed where OneTouch products are sold and prescriptions can be processed; requires a valid prescription; for additional information contact the program at 800-227-8862.",
                "brand": "OneTouch Verio Gold",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b327"
            },
            {
                "_id": "6297bcace0e0db6c2572b32c",
                "link": "https://www.orthorxaccess.com/",
                "description": "Onexton Rx Access Program: Eligible commercially insured patients/RX not covered may pay no more than $65 per prescription; valid for 2 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Onexton",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "2",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b32c"
            },
            {
                "_id": "6297bcace0e0db6c2572b331",
                "link": "https://www.panfoundation.org/",
                "description": "PAN Foundation",
                "brand": "Onglyza",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0.00",
                "lifetime_uses": "12",
                "max_saving_transaction": null,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b331"
            },
            {
                "_id": "6297bcace0e0db6c2572b336",
                "link": "https://www.bmsaccesssupport.bmscustomerconnect.com/patient/financial-support",
                "description": "Opdivo BMS Oncology Co-Pay Assistance Program: Eligible commercially insured patients may pay no more than $25 per infusion with savings of up to $25,000 per year; for additional information contact the program at 800-861-0048.",
                "brand": "Opdivo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b336"
            },
            {
                "_id": "6297bcace0e0db6c2572b33b",
                "link": "https://www.eyefile.com/theeyesolution/Register",
                "description": "Save $2 on your next purchase of any one Opti-Free Express Rewetting Drops (10 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Opti-Free Express Rewetting Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b33b"
            },
            {
                "_id": "6297bcace0e0db6c2572b340",
                "link": "https://www.incytecares.com/dermatology/opzelura/home.aspx",
                "description": "Opzelura Copay Savings Card: Eligible commercially insured patients may pay as little as $10 per tube with a savings of $10,000 per calendar year; for additional information contact the program at 800-583-6964.",
                "brand": "Opzelura",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "10000.00",
                "lifetime_uses": "0",
                "max_saving_transaction": 2076.5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b340"
            },
            {
                "_id": "6297bcace0e0db6c2572b345",
                "link": "https://copay.oralair.com/",
                "description": "Oralair Co-Pay Assistance Program: Eligible cash-paying patients may save up to $100 per 30-day prescription; card valid up to 10 times; for additional information contact the program at 866-885-7520.",
                "brand": "Oralair",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1000",
                "lifetime_uses": "10",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b345"
            },
            {
                "_id": "6297bcace0e0db6c2572b34a",
                "link": "https://www.orfadin.com/support#enroll-in-orfadin4u",
                "description": "Orfadin4U Support Program: Eligible commercially patients may pay $0 copay per prescription with savings of up to $10,000 per year; program also provides additional valuable services; for additional information contact the program at 877-473-3179.",
                "brand": "Orfadin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b34a"
            },
            {
                "_id": "6297bcace0e0db6c2572b34f",
                "link": "https://www.orgovyxhcp.com/support/#bridge-program",
                "description": "Orgovym Bridge Program: Eligible commercially insured patients may receive medication at no cost for a limited period of time (up to 4 months) if they are experiencing a delay in their insurance coverage or are waiting for approval from their insurance company; for additional information contact the program at 833-674-6899.",
                "brand": "Orgovyx",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "4",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b34f"
            },
            {
                "_id": "6297bcace0e0db6c2572b354",
                "link": "https://orphengesicforte.com/resources/",
                "description": "Orphengesic Forte Copay Program: Eligible commercially insured patients may pay as little as $0 per fill with a savings of $150; for additional information contact the program at 855-965-2783.",
                "brand": "Orphengesic Forte",
                "copay": "",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b354"
            },
            {
                "_id": "6297bcace0e0db6c2572b359",
                "link": "https://www.oscillo.com/coupon/",
                "description": "$2 coupon for any Oscillococcinum product (6 or 12 dose) with registration; 1 coupon per purchase.",
                "brand": "Oscillococcinum",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b359"
            },
            {
                "_id": "6297bcace0e0db6c2572b35e",
                "link": "http://bagitaway.com/ostomy_supplies_sample.asp",
                "description": "Ostaway x-Bag Sample: Receive a FREE Ostomy Device Disposal Bag; submit request form; for additional information contact the program at 800-774-6097.",
                "brand": "Ostaway x-Bag",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b35e"
            },
            {
                "_id": "6297bcace0e0db6c2572b363",
                "link": "https://www.osteobiflex.com/ambassadors-club/",
                "description": "Osteo Bi-Flex Products: Join the Ambassador�s Club to receive coupons based on your past purchases.",
                "brand": "Osteo Bi-Flex Triple Strength Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b363"
            },
            {
                "_id": "6297bcace0e0db6c2572b368",
                "link": "https://www.wraser-direct.com/",
                "description": "Otovel WraSer-Direct Universal Coupon Program: Eligible commercially insured patients/RX not covered may pay no more than $20 per prescription when using a WraSer Pharmacy Partner; for additional information contact the program at 601-605-0664.",
                "brand": "Otovel",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 135,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b368"
            },
            {
                "_id": "6297bcace0e0db6c2572b36d",
                "link": "https://www.emdseronofertility.com/en/patient/affording-treatment/medication-savings.html",
                "description": "Ovidrel Compassionate Care Program: Eligible patients may save up to 75% off the available self-pay price for their medication at a participating pharmacy; patients may use the program for multiple cycles each year;  patients must reapply each year; for additional information contact the program at 855-541-5926.",
                "brand": "Ovidrel",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b36d"
            },
            {
                "_id": "6297bcace0e0db6c2572b372",
                "link": "https://www.oxtellarxrhcp.com",
                "description": "Oxtellar XR Samples: Healthcare professionals may request samples for their practice by visiting the website.",
                "brand": "Oxtellar XR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b372"
            },
            {
                "_id": "6297bcace0e0db6c2572b377",
                "link": "https://www.ozurdexsavingsprogram.com",
                "description": "Ozurdex Savings Program: Eligible commercially insured patients may pay as little as $0 per treatment with an annual savings limit of $5000 for dates of service after March 1, 2021; your healthcare provider must complete the enrollment process; healthcare providers must re-enroll patients annually; for additional information your healthcare provider should contact the program at 866-698-7339.",
                "brand": "Ozurdex",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "5000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b377"
            },
            {
                "_id": "6297bcace0e0db6c2572b37c",
                "link": "https://www.palynziq.com/find-pku-support#copay",
                "description": "Palunziq Co-Pay Assistance Program: Eligible commercially insured patients may pay $0 per prescription; for additional information contact the program at 833-758-2273.",
                "brand": "Palynziq",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b37c"
            },
            {
                "_id": "6297bcace0e0db6c2572b381",
                "link": "https://www.panhematin.com/support",
                "description": "Panhematin Patient Support Copay Assistance Program: Eligible patients may receive financial assistance when they receive their infusions in an outpatient setting; for more information contact the program at 866-209-7604.",
                "brand": "Panhematin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b381"
            },
            {
                "_id": "6297bcace0e0db6c2572b386",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/parodontax/overview/",
                "description": "Parodontax Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Parodontax Active Gum Repair",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b386"
            },
            {
                "_id": "6297bcace0e0db6c2572b38b",
                "link": "https://www.eyefile.com/TheEyeSolution/Register/0?sid=CLRCR0513",
                "description": "Save $5 on your next purchase of Pataday products with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Pataday Twice Daily Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b38b"
            },
            {
                "_id": "6297bcace0e0db6c2572b390",
                "link": "https://pediasure.com/resources-and-rewards/pediasure-coupons",
                "description": "Sign up for the PediaSure Support2Grow program and save up to $100 with receive special offers.",
                "brand": "PediaSure Grow and Gain",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b390"
            },
            {
                "_id": "6297bcace0e0db6c2572b395",
                "link": "https://pediasure.com/resources-and-rewards/pediasure-coupons",
                "description": "Sign up for the PediaSure Support2Grow program and save up to $100 with receive special offers.",
                "brand": "PediaSure Peptide 1.0 Cal",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b395"
            },
            {
                "_id": "6297bcace0e0db6c2572b39a",
                "link": "https://www.incytecares.com/pemazyre/home.aspx?_ga=2.223391707.1747778669.1587394937-2123654978.1587394937",
                "description": "Pemazyre IncyteCARES Copay/Coinsurance Program: Eligible commercially insured patients may pay as little as $0 copay with a savings of up to $25,000 per year; for additional information contact the program at 855-452-5234.",
                "brand": "Pemazyre",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 9000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b39a"
            },
            {
                "_id": "6297bcace0e0db6c2572b39f",
                "link": "https://mycareclubrewards.com/pepcid/?utm_medium=partner&utm_source=pepcid.com&utm_content=footer&utm_campaign=cc_main&_gl=1*1f8mxe6*_ga*MTc3MDUxNTg0OS4xNjMzMDE2OTQ4*_ga_13VEM6N66E*MTYzMzAxNjk0OC4xLjEuMTYzMzAxNzAzOC41OQ..",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Pepcid products with registration.",
                "brand": "Pepcid AC Original Strength",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b39f"
            },
            {
                "_id": "6297bcace0e0db6c2572b3a4",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Perjeta Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Perjeta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3a4"
            },
            {
                "_id": "6297bcace0e0db6c2572b3a9",
                "link": "https://www.pfizermedicalinformation.com/en-us/pfizerpen",
                "description": "Pfizerpen�Samples: Your healthcare provider may request samples by contacting PfizerPro online or calling 800-505-4426.",
                "brand": "Pfizerpen",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3a9"
            },
            {
                "_id": "6297bcace0e0db6c2572b3ae",
                "link": "https://www.phillipsdigestive.com/coupon/",
                "description": "Register your email address to receive a coupon for any one Phillips' product; 1 coupon per purchase.",
                "brand": "Phillips Fiber Good Gummies",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3ae"
            },
            {
                "_id": "6297bcace0e0db6c2572b3b3",
                "link": "https://www.pifeltro.com/",
                "description": "Pifeltro Savings Coupon: Eligible commercially insured patients may save on their out-of-pocket�costs such as co-pays, co-insurance and deductibles; maximum savings of $6800; coupon may be redeemed once every 21 days; for additional information contact program at 800-672-6372.",
                "brand": "Pifeltro",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "6800",
                "lifetime_uses": "12",
                "max_saving_transaction": 566.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3b3"
            },
            {
                "_id": "6297bcace0e0db6c2572b3b8",
                "link": "https://www.plegridy.com/en_us/home/financial-support.html",
                "description": "Plegridy Biogen Support Services: Eligible uninsured patients in need may qualify for financial support and other services with this program; for additional information contact the program at 800-456-2255.",
                "brand": "Plegridy",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3b8"
            },
            {
                "_id": "6297bcace0e0db6c2572b3bd",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Plexion Artesa Labs Advantage Card: Eligible commercially insured patients may pay $0 per prescription; for additional information contact the program 877-264-2440.",
                "brand": "Plexion Products",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3bd"
            },
            {
                "_id": "6297bcace0e0db6c2572b3c2",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/overview/?utm_source=google&utm_medium=cpc&utm_term=super%20poligrip&utm_campaign=GS%20-%20Branded_Poligrip%20EX_DP&gclid=Cj0KCQiA962BBhCzARIsAIpWEL0O5zEg5LMkWP-xbEDatBaEd896PMS8BoY0ynLITYi-fwgKKWPSrcUaAiBqEALw_wcB&gclsrc=aw.ds",
                "description": "Polident Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Polident 3-Minute Denture Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3c2"
            },
            {
                "_id": "6297bcace0e0db6c2572b3c7",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/overview/?utm_source=google&utm_medium=cpc&utm_term=super%20poligrip&utm_campaign=GS%20-%20Branded_Poligrip%20EX_DP&gclid=Cj0KCQiA962BBhCzARIsAIpWEL0O5zEg5LMkWP-xbEDatBaEd896PMS8BoY0ynLITYi-fwgKKWPSrcUaAiBqEALw_wcB&gclsrc=aw.ds",
                "description": "Polident Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Polident Overnight Whitening",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3c7"
            },
            {
                "_id": "6297bcace0e0db6c2572b3cc",
                "link": "https://www.polident.com/en-us/save-now/proguard-coupon/",
                "description": "Save $1 on your purchase of any Polident ProGuard & Retainer Cleanser product by registering your email address; one coupon per purchase.",
                "brand": "Polident Pro Guard and Retainer Paste Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3cc"
            },
            {
                "_id": "6297bcace0e0db6c2572b3d1",
                "link": "https://www.polident.com/en-us/save-now/propartial-options/",
                "description": "Save $3.50 on your purchase when you buy all 3 Polident ProPartial products in a single transaction; must register your email address on the website; one coupon per purchase.",
                "brand": "Polident ProPartials Foam Cleanser",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3.5,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3d1"
            },
            {
                "_id": "6297bcace0e0db6c2572b3d6",
                "link": "https://www.polident.com/en-us/save-now/propartial-options/",
                "description": "Save $1 on your purchase of any Polident ProPartial product by registering your email address; one coupon per purchase.",
                "brand": "Polident ProPartials Toothpaste",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3d6"
            },
            {
                "_id": "6297bcace0e0db6c2572b3db",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Polivy Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Polivy",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3db"
            },
            {
                "_id": "6297bcace0e0db6c2572b3e0",
                "link": "https://www.lillyoncologysupport.com/portrazza-financial-support",
                "description": "Portrazza Savings Card: Eligible commercially insured patients may pay as little as $25 per dose with a maximum savings of up to $25,000 during a 12-month enrollment period;  for additional information contact the program at 866-472-8663.",
                "brand": "Portrazza",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.34,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3e0"
            },
            {
                "_id": "6297bcace0e0db6c2572b3e5",
                "link": "https://www.praluent.com/starting-and-paying-for-praluent-rx/",
                "description": "Praluent MyPraluent Copay Card: Eligible commercially insured patients may pay as little as $25 per month for deductibles, copays and coinsurance up to $3500 per year; for more information contact the program at 844-772-5836.",
                "brand": "Praluent",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "3500",
                "lifetime_uses": "12",
                "max_saving_transaction": 291.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3e5"
            },
            {
                "_id": "6297bcace0e0db6c2572b3ea",
                "link": "https://www.predforte.com",
                "description": "Pred Forte Savings Program: Eligible commercially insured patients and patients who have Medicare Part D may pay as little as $50 for up to 6 fills; for additional information contact the program at 877-737-0629.",
                "brand": "Pred Forte",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3ea"
            },
            {
                "_id": "6297bcace0e0db6c2572b3ef",
                "link": "https://www.premarinvaginalcream.com/savings-and-support",
                "description": "Premarin Savings Card Rebate (Vaginal Cream): If using a mail-order pharmacy or a pharmacy does not accept the Savings Card eligible commercially insured patients may submit a rebate; click on Terms & Conditions to review the rebate instructions; for additional information contact the program at 866-879-4600.",
                "brand": "Premarin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "300",
                "lifetime_uses": "2",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3ef"
            },
            {
                "_id": "6297bcace0e0db6c2572b3f4",
                "link": "https://prenate.com/patient-savings/",
                "description": "Prenate Chewables eVoucherRx Program: Eligible patients pay no more that $20 per prescription fill at participating pharmacies; for additional information contact the program at 888-612-8466.",
                "brand": "Prenate Chewables",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3f4"
            },
            {
                "_id": "6297bcace0e0db6c2572b3f9",
                "link": "https://avionrx.com/healthcare-professionals/",
                "description": "Prenate Elite Samples: Healthcare providers may order samples for their practice by filling out a form online or calling 888-612-8466.",
                "brand": "Prenate Elite",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3f9"
            },
            {
                "_id": "6297bcace0e0db6c2572b3fe",
                "link": "https://avionrx.com/healthcare-professionals/",
                "description": "Prenate Essential Samples: Healthcare providers may order samples for their practice by filling out a form online or calling 888-612-8466.",
                "brand": "Prenate Essential with Quatrefolic",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b3fe"
            },
            {
                "_id": "6297bcace0e0db6c2572b403",
                "link": "https://prenate.com/patient-savings/",
                "description": "Prenate Restore eVoucherRx Program: Eligible patients pay no more than $20 per prescription fill at participating pharmacies; for additional information contact the program at 888-612-8466.",
                "brand": "Prenate Restore",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b403"
            },
            {
                "_id": "6297bcace0e0db6c2572b408",
                "link": "https://www.preparationh.com/coupons-and-special-offers/",
                "description": "Register your email address to receive a coupon for any one Preparation H product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Preparation H Multi-Symptom Pain Relief Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b408"
            },
            {
                "_id": "6297bcace0e0db6c2572b40d",
                "link": "https://www.preparationh.com/coupons-and-special-offers/",
                "description": "Register your email address to receive a coupon for any one Preparation H product; 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Preparation H Soothing Relief Cleansing and Cooling Wipes",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b40d"
            },
            {
                "_id": "6297bcace0e0db6c2572b412",
                "link": "https://www.preservision.com/eyecare-professionals/samples-coupons",
                "description": "PreserVision Samples: Eyecare professionals may request samples of PreserVision Eye Vitamins for their practice by calling 844-258-4837.",
                "brand": "PreserVision Eye Vitamin AREDS 2 Formula and Multivitamin",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b412"
            },
            {
                "_id": "6297bcace0e0db6c2572b417",
                "link": "https://www.preservision.com/eyecare-professionals/samples-coupons",
                "description": "PreserVision Samples: Eyecare professionals may request samples of PreserVision Eye Vitamins for their practice by calling 844-258-4837.",
                "brand": "PreserVision Eye Vitamin AREDS 2 Formula MiniGels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b417"
            },
            {
                "_id": "6297bcace0e0db6c2572b41c",
                "link": "https://www.preservision.com/Get-Coupons/Get-My-Coupon",
                "description": "$4 coupon off any PreserVision Eye Vitamin and Mineral Supplement 60 count or larger; 1 coupon per purchase.",
                "brand": "PreserVision Eye Vitamin AREDS Formula Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b41c"
            },
            {
                "_id": "6297bcace0e0db6c2572b421",
                "link": "https://www.prevail.com/try-prevail/free-samples",
                "description": "Prevail Incontinence Products Free Sample Kit: Sample Kits are available to order for both men & women by using the online order form; for additional information contact the program at 800-227-3551.",
                "brand": "Prevail Incontinence Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b421"
            },
            {
                "_id": "6297bcace0e0db6c2572b426",
                "link": "https://www.janssencarepath.com/patient/prezista/cost-support",
                "description": "Prezista Janssen CarePath Savings Program: Commercially Insured Patients - May pay $0 per fill on out-of-pocket costs with savings of up to $7500 per calendar year; for additional assistance contact the program at 866-836-0114.",
                "brand": "Prezista",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "7500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b426"
            },
            {
                "_id": "6297bcace0e0db6c2572b42b",
                "link": "https://www.prilosecpackets.com/Savings-Card",
                "description": "Prilosec Packets Co-Pay Savings Card: Eligible patients may pay as little as $5 per month up to 12 times in a calendar year; for additional information contact the program at 877-264-2440.",
                "brand": "Prilosec Packets",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b42b"
            },
            {
                "_id": "6297bcace0e0db6c2572b430",
                "link": "https://www.pristiq.com/savings-terms",
                "description": "Pristiq Savings Rebate: If the pharmacy does not accept the Savings Card eligible patients may submit a request for a refund; for additional information contact the program at 855-440-6852.",
                "brand": "Pristiq",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1080",
                "lifetime_uses": "12",
                "max_saving_transaction": 90,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b430"
            },
            {
                "_id": "6297bcace0e0db6c2572b435",
                "link": "http://wcpharma.com/patient-savings/",
                "description": "ProCort Women's Choice Savings Coupon: Eligible commercially insured patients may pay no more than $50 per month; for additional information contact the program at 800-664-1490.",
                "brand": "ProCort",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b435"
            },
            {
                "_id": "6297bcace0e0db6c2572b43a",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Prolensa Bausch + Lomb Access Program: Eligible cash-paying and commercially insured/RX not covered patients may pay no more than $60 per prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Prolensa",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b43a"
            },
            {
                "_id": "6297bcace0e0db6c2572b43f",
                "link": "https://www.alva-amco.com/contact",
                "description": "Consumers may fill out a request form for Prosacea product coupons.",
                "brand": "Prosacea",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b43f"
            },
            {
                "_id": "6297bcace0e0db6c2572b444",
                "link": "https://uspco.com/product_literature.aspx?pid=53&tab=Voucher",
                "description": "Provida DHA Pharmacy Voucher: Eligible patients may save up to 80% at participating pharmacies; for additional information contact the program at  888-602-2978.",
                "brand": "Provida DHA",
                "copay": "No",
                "bin": "600428",
                "rxpcn": "5100000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b444"
            },
            {
                "_id": "6297bcace0e0db6c2572b449",
                "link": "https://www.pulmicortflexhalertouchpoints.com/savings-card.html",
                "description": "Pulmicort Flexhaler Savings Card: Eligible commercially insured may pay $20 per 30-day supply with a maximum savings of $50; good for 12 uses; for additional information contact the program at 800-236-9933.",
                "brand": "Pulmicort Flexhaler",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "600",
                "lifetime_uses": "12",
                "max_saving_transaction": 50,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b449"
            },
            {
                "_id": "6297bcace0e0db6c2572b44e",
                "link": "https://www.bausch.com/our-products/contact-lenses/lenses-for-nearsighted-farsighted/purevision2-contact-lenses/free-trial",
                "description": "PureVision Contact Lenses Free Offer: Patients may print a Free Trial Lens Certificate to receive a FREE pair of PureVision2 contact lenses from their eyecare professional; for additional information contact the program at 800-553-5340.",
                "brand": "PureVision2 for Astigmatism",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b44e"
            },
            {
                "_id": "6297bcace0e0db6c2572b453",
                "link": "https://www.qelbreehcp.com/adhd-resources/cost-support#request-samples",
                "description": "Qelbree Samples: Healthcare providers may request samples to be sent to their office or patients' homes.",
                "brand": "Qelbree",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b453"
            },
            {
                "_id": "6297bcace0e0db6c2572b458",
                "link": "https://www.activatethecard.com/6866/?utm_source=print&utm_medium=card&utm_campaign=offline2018&utm_content=saveonqsymia",
                "description": "Qsymia Savings Card: Eligible cash-paying patients may save up to $65 per monthly prescription; for additional information contact the program at 855-264-9634.",
                "brand": "Qsymia",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "780",
                "lifetime_uses": "12",
                "max_saving_transaction": 65,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b458"
            },
            {
                "_id": "6297bcace0e0db6c2572b45d",
                "link": "https://www.qtern.com/savings-coupon.html",
                "description": "Qtern Savings Card: Eligible cash-paying patients may save up to $150 per prescription fill; for additional information contact the program at 844-846-2750.",
                "brand": "Qtern",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b45d"
            },
            {
                "_id": "6297bcace0e0db6c2572b462",
                "link": "https://www.trisadhd.com/adhd-resources/",
                "description": "Quillivant XR Co-Pay Card: Eligible commercially insured patients may pay as little as $20 on each of up to 12 prescription fills; for additional information contact the program at 888-840-7006.",
                "brand": "Quillivant XR",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b462"
            },
            {
                "_id": "6297bcace0e0db6c2572b467",
                "link": "https://www.activatethecard.com/7890/",
                "description": "Quzyttir Savings Program Reimbursement Request: Eligible commercially insured patients may request a rebate if paying for prescription and unable to use the Savings Program Card; for additional information contact the program at 844-864-3014.",
                "brand": "Quzyttir",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "4",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b467"
            },
            {
                "_id": "6297bcace0e0db6c2572b46c",
                "link": "https://www.alksavings.com/cb/access/crp/aLkYd2j78Vbb/fid.jsp?q=51G5OI5442CUDK4PLWTLX5&f=IMPX6IG-274EMWRGK6802#/app/layout/home",
                "description": "Ragwitek ALK Savings Card Program: Eligible commercially insured patients may pay as little as $25 per 30-day prescription if using an ALK Network Pharmacy; valid for 12 uses or $3,200 per year (whichever comes first); for additional information contact the program at 800-325-7354.",
                "brand": "Ragwitek",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3200",
                "lifetime_uses": "12",
                "max_saving_transaction": 265,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b46c"
            },
            {
                "_id": "6297bcace0e0db6c2572b471",
                "link": "https://www.rasuvo.com/patient-resources/prescription-savings/",
                "description": "Rasuvo Core Connections Copay Assistance Card: Eligible commercially insured patients may save up to $125 on each of up to 14 prescriptions (30-day supply); for additional information contact the program at 855-336-3322.",
                "brand": "Rasuvo",
                "copay": "Yes",
                "bin": "21940",
                "rxpcn": "PYS",
                "copay_max": "1750",
                "lifetime_uses": "14",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b471"
            },
            {
                "_id": "6297bcace0e0db6c2572b476",
                "link": "https://www.recticare.com/doctors/",
                "description": "RectiCare Product Samples: Healthcare providers may order samples for their practice by filling out the online form.",
                "brand": "RectiCare Advanced Hemorrhoidal Cream",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b476"
            },
            {
                "_id": "6297bcace0e0db6c2572b47b",
                "link": "https://www.recticare.com/coupons/",
                "description": "Receive an instant $3 coupon for any one RectiCare product by registering your email address online; 1 coupon per purchase.",
                "brand": "RectiCare Medicated Anorectal Wipes",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b47b"
            },
            {
                "_id": "6297bcace0e0db6c2572b480",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Digital Preservative Free",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b480"
            },
            {
                "_id": "6297bcace0e0db6c2572b485",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Optive Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b485"
            },
            {
                "_id": "6297bcace0e0db6c2572b48a",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b48a"
            },
            {
                "_id": "6297bcace0e0db6c2572b48f",
                "link": "https://www.refreshrewards.com/",
                "description": "Sign up with Refresh Rewards and receive valuable coupons and exclusive offers sent to you throughout the year; 1 coupon per purchase.",
                "brand": "Refresh Relieva Preservative Free",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b48f"
            },
            {
                "_id": "6297bcace0e0db6c2572b494",
                "link": "https://ec4ea071-fbcb-424f-b1c2-cf62cecd02ee.filesusr.com/ugd/72e81f_ff8712ca24fb46138474f91660fcfdec.pdf",
                "description": "ReliOn Novolin N Savings: Eligible patients may save from 58% to 75% off the cash price per�prescription; offer available only at Walmart; for additional information contact your nearest Walmart Pharmacy.",
                "brand": "ReliOn Novolin N Insulin Vial",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b494"
            },
            {
                "_id": "6297bcace0e0db6c2572b499",
                "link": "https://relistor.copaysavingsprogram.com",
                "description": "Relistor (Tablets) Savings Program: Eligible commercially insured patients may pay as little as $0 per prescription; card may be used 12 times before expiration date; for additional information contact the program at 855-202-3719.",
                "brand": "Relistor",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b499"
            },
            {
                "_id": "6297bcace0e0db6c2572b49e",
                "link": "https://www.janssencarepath.com/patient/remicade/cost-support",
                "description": "Remicade Janssen CarePath Savings Program Rebate: Eligible commercially insured patients may submit a rebate request if the pharmacy does not accept the Savings Card; for additional assistance contact the program at 877-227-3728.",
                "brand": "Remicade",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b49e"
            },
            {
                "_id": "6297bcace0e0db6c2572b4a3",
                "link": "http://www.renvalue.com",
                "description": "Renvela RenValue Copay Card: Eligible commercially insured patients may pay only $5 for each of up to 12 prescriptions with a savings of up to $300 per fill; for additional information contact the program at 866-257-4605.",
                "brand": "Renvela",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3600",
                "lifetime_uses": "12",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4a3"
            },
            {
                "_id": "6297bcace0e0db6c2572b4a8",
                "link": "https://www.womenshealthcaresolutions.com/patient-material-request",
                "description": "Replens Free Samples: Your healthcare provider may request FREE Feminine Health Kit with product samples, patient coupons and valuable patient materials.",
                "brand": "Replens",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4a8"
            },
            {
                "_id": "6297bcace0e0db6c2572b4ad",
                "link": "https://uspco.com/product_literature.aspx?pid=380&tab=Purchase",
                "description": "Restora Pharmacy Voucher: Eligible patients may save up to 80% at participating pharmacies; for additional information contact the program at 888-602-2978.",
                "brand": "Restora",
                "copay": "No",
                "bin": "600428",
                "rxpcn": "1500000",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4ad"
            },
            {
                "_id": "6297bcace0e0db6c2572b4b2",
                "link": "https://uspco.com/product_literature.aspx?pid=434&tab=Contact",
                "description": "Restora Sprinkles Samples: Healthcare providers may request free samples for their practice by filling out a form online.",
                "brand": "Restora Sprinkles",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4b2"
            },
            {
                "_id": "6297bcace0e0db6c2572b4b7",
                "link": "https://www.aspirerewards.com/landing",
                "description": "Restylane Lyft ASPIRE Galderma Rewards: Join today and receive a $60 treatment certificate instantly; other valuable discounts and rebates with sign up; for additional information contact customer service at 844-527-7473.",
                "brand": "Restylane Lyft",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4b7"
            },
            {
                "_id": "6297bcace0e0db6c2572b4bc",
                "link": "https://www.orthorxaccess.com/",
                "description": "Retin-A Micro Rx Access Program: Eligible uninsured/cash-pay patients may pay $65 per fill; valid for 2 prescription fills; offer exclusively at Walgreens and participating independent pharmacies; for additional information contact the program at 855-202-3279.",
                "brand": "Retin-A MICRO",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "2",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4bc"
            },
            {
                "_id": "6297bcace0e0db6c2572b4c1",
                "link": "https://www.revatio.com/savings-terms",
                "description": "Revatio Savings Card Rebate: Eligible commercially insured patients whose pharmacy does not accept the Savings Card may request a rebate; for additional assistance contact the program at 866-732-4468.",
                "brand": "Revatio",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4c1"
            },
            {
                "_id": "6297bcace0e0db6c2572b4c6",
                "link": "https://www.reyvow.com/savings-support",
                "description": "Reyvow Savings Card: Eligible commercially insured patients may pay as little as $0 per fill with a $3400 maximum annual savings; for additional information contact the program at 833-739-8691.",
                "brand": "Reyvow",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3400",
                "lifetime_uses": "12",
                "max_saving_transaction": 283.34,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4c6"
            },
            {
                "_id": "6297bcace0e0db6c2572b4cb",
                "link": "https://www.riabni.com/rituximab-biosimilar-resources",
                "description": "Riabni Amgen First Step Program: Eligible commercially insured patients may save on their out-of-pocket medication costs; for additional information contact the program at 888-427-7478.",
                "brand": "Riabni",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4cb"
            },
            {
                "_id": "6297bcace0e0db6c2572b4d0",
                "link": "https://ridlice.com/coupons/",
                "description": "Save $2 off any one RID product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "RID SUPER MAX Complete Lice Elimination Kit",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4d0"
            },
            {
                "_id": "6297bcace0e0db6c2572b4d5",
                "link": "https://www.janssencarepath.com/patient/risperdal-consta/cost-support",
                "description": "Risperdal Consta Janssen CarePath Savings Program Rebate: If the pharmacy or doctor cannot process the Savings Program Card commercially insured patients may still take advantage of the offer by submitting a rebate request; for additional information contact the program at 877-227-3728.",
                "brand": "Risperdal Consta",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5500",
                "lifetime_uses": "26",
                "max_saving_transaction": 211.54,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4d5"
            },
            {
                "_id": "6297bcace0e0db6c2572b4da",
                "link": "https://www.copayassistancenow.com/eligibility",
                "description": "Rituxan Hycela Genentech Oncology Co-pay Assistance Program: Eligible commercially insured patients may pay $5 in out-of-pocket costs for the prescribed product; savings of up to $25,000 per year; for additional information contact the program at 855-692-6729.",
                "brand": "Rituxan Hycela",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4da"
            },
            {
                "_id": "6297bcace0e0db6c2572b4df",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Children's Cough and Chest Congestion DM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4df"
            },
            {
                "_id": "6297bcace0e0db6c2572b4e4",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Childrens Honey Cough and Chest Congestion DM",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4e4"
            },
            {
                "_id": "6297bcace0e0db6c2572b4e9",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin DM Day Night Pack",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4e9"
            },
            {
                "_id": "6297bcace0e0db6c2572b4ee",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Multi-Symptom Cold CF",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4ee"
            },
            {
                "_id": "6297bcace0e0db6c2572b4f3",
                "link": "https://www.robitussin.com/get-robitussin-coupons/",
                "description": "Receive a printable coupon for your next purchase of any one Robitussin product when you sign-up with your email address; must complete online form; 1 coupon per purchase.",
                "brand": "Robitussin Severe Multi-Symptom Cough Cold  Flu Nighttime",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4f3"
            },
            {
                "_id": "6297bcace0e0db6c2572b4f8",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Rogaine products with registration.",
                "brand": "Rogaine for Men Solution",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4f8"
            },
            {
                "_id": "6297bcace0e0db6c2572b4fd",
                "link": "https://www.rogaine.com",
                "description": "Register your e-mail address and receive 10% off your 1st purchase of Rogaine.",
                "brand": "Rogaine for Woman Unscented Foam",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b4fd"
            },
            {
                "_id": "6297bcace0e0db6c2572b502",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Rogaine products with registration.",
                "brand": "Rogaine for Women Solution",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b502"
            },
            {
                "_id": "6297bcace0e0db6c2572b507",
                "link": "https://www.rolaids.com/en-us/coupon/",
                "description": "Save $2 on any one Rolaids product; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Rolaids Ultra Strength Softchews",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b507"
            },
            {
                "_id": "6297bcace0e0db6c2572b50c",
                "link": "https://roszet.com/samples/",
                "description": "Roszet Samples: Healthcare providers may request samples for their practice by filling out a form online.",
                "brand": "Roszet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b50c"
            },
            {
                "_id": "6297bcace0e0db6c2572b511",
                "link": "https://www.ruconest.com/resources-and-support/",
                "description": "Ruconest StarterRx Program: Eligible new patients to therapy may receive up 4 vials or 2 doses free to begin treatment; for additional information contact the program at 855-613-4423.",
                "brand": "Ruconest",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b511"
            },
            {
                "_id": "6297bcace0e0db6c2572b516",
                "link": "https://www.ruzurgi.com",
                "description": "Ruzurgi Copay Assistance: Eligible commercially insured patients can receive information about affordability, out-of-pocket expenses, and  assistance programs available to those patients who qualify; for additional information contact the program at 844-789-8744.\r\n",
                "brand": "Ruzurgi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b516"
            },
            {
                "_id": "6297bcace0e0db6c2572b51b",
                "link": "https://jazzcares.com/patients/rylaze/",
                "description": "Rylaze Savings Card: Eligible commercially insured patients may pay as little as $10 per fill; for additional information contact the program at 833-533-5299.",
                "brand": "Rylaze",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b51b"
            },
            {
                "_id": "6297bcace0e0db6c2572b520",
                "link": "https://www.otsukapatientsupport.com/samsca",
                "description": "Samsca BridgeRx Program: Eligible commercially insured patients may receive their medication for FREE if experiencing a delay in insurance coverage; prescriptiob must be filled at a specialty pharmacy to take advantage of the program; for additional assistance contact the program at 855-242-7787.",
                "brand": "Samsca",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b520"
            },
            {
                "_id": "6297bcace0e0db6c2572b525",
                "link": "https://www.myaccess360.com/saphnelo-anifrolumab/patient-affordability.html",
                "description": "Saphnelo Access 360 Co-pay Savings Program: Eligible commercially insured patients may $0 per infusion with a maximum savings of $16,500 per year; out-of-pocket costs covered can include the cost of the product itself and/or the cost of infusion of the product (program maximum of $100 per infusion administration) in certain states; for additional information contact the program at 866-727-4635.",
                "brand": "Saphnelo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "16500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b525"
            },
            {
                "_id": "6297bcace0e0db6c2572b52a",
                "link": "https://sarnalotion.com/coupons/",
                "description": "Save $2 on any Sarna Anti-Itch Lotion; 1 coupon per purchase",
                "brand": "Sarna Original Lotion",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b52a"
            },
            {
                "_id": "6297bcace0e0db6c2572b52f",
                "link": "https://www.novocare.com/saxenda/savings-card.html",
                "description": "Saxenda Savings Card: Eligible cash-paying patients and commercially insured patients/RX not covered may save up to $200 per 30-day fill; for additional information contact the program at 877-304-6894.",
                "brand": "Saxenda",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "2400",
                "lifetime_uses": "12",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b52f"
            },
            {
                "_id": "6297bcace0e0db6c2572b534",
                "link": "https://www.secuado.com/secuado-co-pay-program/?_sp=b49802a7-9e4a-4a3a-94ed-b65b889f1b28.1631636587070",
                "description": "Secuado Co-payment Assistance Program: Eligible commercially insured patients may pay as little as $15 per prescription for up to 12 prescriptions; for more information contact the program at 888-526-0132.",
                "brand": "Secuado",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "8400",
                "lifetime_uses": "12",
                "max_saving_transaction": 600,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b534"
            },
            {
                "_id": "6297bcace0e0db6c2572b539",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Select-OB+DHA Instant Savings Card: Eligible cash-paying patients may pay approximately $65 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Select-OB DHA",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b539"
            },
            {
                "_id": "6297bcace0e0db6c2572b53e",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "SelRx Artesa Labs Advantage Card: Eligible commercially insured patients may pay as little as $20 per prescription; for additional information contact the program 877-264-2440.",
                "brand": "SelRx Shampoo",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b53e"
            },
            {
                "_id": "6297bcace0e0db6c2572b543",
                "link": "https://www.selsunblue.com/en-us/coupon/",
                "description": "Save $1 on any Selsun Blue product; one coupon per purchase; coupon expires one month after printing.",
                "brand": "Selsun Blue Itchy Dry Scalp Antidandruff Shampoo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b543"
            },
            {
                "_id": "6297bcace0e0db6c2572b548",
                "link": "https://myviivcard.com/rebate-form-instructions.html",
                "description": "Selzentry ViiVConnect Rebate: Eligible commercially insured patients may be eligible for a rebate for the amount paid out of pocket per prescription if the pharmacy does not accept the savings card; rebate may be completed online or by downloading and mailing in rebate form; for additional information contact the program at 844-588-3288.",
                "brand": "Selzentry",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b548"
            },
            {
                "_id": "6297bcace0e0db6c2572b54d",
                "link": "https://www.sensodyne.com/en-us/coupon/",
                "description": "Save $1 on any one Sensodyne toothpaste product (excludes travel sizes) with registration of your name and email address; coupon expires 1 month after printing.",
                "brand": "Sensodyne Essential Care Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b54d"
            },
            {
                "_id": "6297bcace0e0db6c2572b552",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/sensodyne/overview/",
                "description": "Sensodyne Samples: Dental professionals may request FREE samples of Sensodyne products for their practice.",
                "brand": "Sensodyne ProNamel Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b552"
            },
            {
                "_id": "6297bcace0e0db6c2572b557",
                "link": "https://www.sensodyne.com/en-us/coupon/",
                "description": "Save $1 on any one Sensodyne toothpaste product (excludes travel sizes) with registration of your name and email address; coupon expires 1 month after printing.",
                "brand": "Sensodyne Sensitivity and Gum Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b557"
            },
            {
                "_id": "6297bcace0e0db6c2572b55c",
                "link": "https://products.coloplast.us/products/ostomy-care/#currentPage%3D1%26facetStrings%3D",
                "description": "Receive FREE samples for SenSura Ileostomy products; sign up required; for additional information contact the program at 888-726-7872.",
                "brand": "SenSura-Ileostomy Pouches",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b55c"
            },
            {
                "_id": "6297bcace0e0db6c2572b561",
                "link": "https://sevenfact.com/sevenfact-support/",
                "description": "Sevenfact Co-pay Program: Eligible commercially insured patients pay as little as $0 per fill with a maximum savings of $12,000 per calendar year; for additional information contact the program at 855-718-4362.",
                "brand": "Sevenfact",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b561"
            },
            {
                "_id": "6297bcace0e0db6c2572b566",
                "link": "https://www.signiforlar.com/patient/cushings-disease/access-and-support/",
                "description": "Signifor LAR R.A.R.E. Coapy Assistance Program: Eligible patients be eligible for help to reduce their out-of-pocket costs; for additional information contact the program at 888-855-7273.",
                "brand": "Signifor LAR",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b566"
            },
            {
                "_id": "6297bcace0e0db6c2572b56b",
                "link": "https://www.silenor.com/resources/#silenor-program",
                "description": "Silenor Prescription Savings Program: Eligible patients may pay as little as $25 per prescription; minimum 30 tablet prescription; for additional information contact the program at 800-793-2145.",
                "brand": "Silenor",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b56b"
            },
            {
                "_id": "6297bcace0e0db6c2572b570",
                "link": "https://www.activatethecard.com/8013/",
                "description": "Simbrinza Co-pay Card Savings Program: Eligible commercially insured patients may pay as little as $30 per prescription with a maximum savings of $2000 per calendar year; for additional information contact the program at 866-972-3008.",
                "brand": "Simbrinza",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "2000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b570"
            },
            {
                "_id": "6297bcace0e0db6c2572b575",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac Go and Grow Milk Based Toddler Drink Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b575"
            },
            {
                "_id": "6297bcace0e0db6c2572b57a",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac Organic Infant Formula Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b57a"
            },
            {
                "_id": "6297bcace0e0db6c2572b57f",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac Sensitive Infant Formula Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b57f"
            },
            {
                "_id": "6297bcace0e0db6c2572b584",
                "link": "https://www.janssencarepath.com/patient/simponi-aria/cost-support?source=janssenprescriptionassistance",
                "description": "Simponi Aria Janssen CarePath Treatment Administration Rebate Program: Eligible commercially or privately insured patients may submit a rebate request per infusion with a maximum savings of $20,000 per calendar year if they have not created an online savings account or if their pharmacy did not accept their savings card; for additional information contact the program at 877-227-3728.",
                "brand": "Simponi Aria",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b584"
            },
            {
                "_id": "6297bcace0e0db6c2572b589",
                "link": "https://www.skyrizi.com/skyrizi-complete/save-on-skyrizi-costs",
                "description": "Skyrizi Complete Savings Card: Eligible commercially insured patients may pay as little as $5 per quarterly dose; for additional information contact the program at 866-759-7494.",
                "brand": "Skyrizi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6000",
                "lifetime_uses": "4",
                "max_saving_transaction": 1500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b589"
            },
            {
                "_id": "6297bcace0e0db6c2572b58e",
                "link": "https://www.slowmag.com/healthcare-professionals/",
                "description": "SlowMag Mg Samples: Your healthcare provider may log into the portal to request free samples and coupons.",
                "brand": "SlowMag MG Calm plus Sleep",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b58e"
            },
            {
                "_id": "6297bcace0e0db6c2572b593",
                "link": "https://slynd.com/healthcare-professionals/",
                "description": "Slynd Samples: Healthcare professionals may ofer samples for their office by faxing an order form to 614-652-8275 or sending the form via email to  ExeltisSamples@cardinalhealth.com.",
                "brand": "Slynd",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b593"
            },
            {
                "_id": "6297bcace0e0db6c2572b598",
                "link": "https://alexiononesource.com/#financial-assistance",
                "description": "Soliris Alexion OneSource CoPay Program: Eligible commercially insured patients may pay as little as $0 in out-of-pocket costs up to a maximum savings of $15,000 per calendar year; for additional information contact the program at 888-765-4747.",
                "brand": "Soliris",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b598"
            },
            {
                "_id": "6297bcace0e0db6c2572b59d",
                "link": "https://www.ipsencares.com/somatuline-depot-patient-support/",
                "description": "Somatuline Depot Ipsen Cares Copay Assistance Program: Eligible commercially insured patients may pay $0 per injection with a maximum savings of $20,000 per calendar year; program resets every January 1st; patient must re-enroll each year; for additional information contact the program at 866-435-5677.",
                "brand": "Somatuline Depot",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b59d"
            },
            {
                "_id": "6297bcace0e0db6c2572b5a2",
                "link": "https://www.soriluxfoam.com/patient/mayne-pharma-patient-savings-card/",
                "description": "Sorilux Mayne Pharma Patient Savings Card: Eligible commercially insured patients RX not covered will pay $65 per prescription; for additional information contact the program at 347-442-7919.",
                "brand": "Sorilux",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5a2"
            },
            {
                "_id": "6297bcace0e0db6c2572b5a7",
                "link": "https://www.spinraza.com/en_us/home/support/sma360.html?cid=PPC-MICROSOFT-Health+Concern+-+DTC-+Phrase%257ES%257EPH%257EUB%257ENER%257EDTC%257ECON-spinal+muscular+atrophy-NA-p50157613330&msclkid=bb120c04b28a14ff143a5338d90e015d&utm_source=bing&utm_medium=cpc&utm_campaign=Health%2520Concern%2520-%2520DTC-%2520Phrase~S~PH~UB~NER~DTC~CON&utm_term=spinal%2520muscular%2520atrophy&utm_content=General%2520-%2520SMA",
                "description": "Spinraza SMA360 $0 Drug Copay Program and $0 Procedure Copay Program: Eligible commercially insured patients may pay as little as $0 copay per prescription or procedure; for additional information contact the program at 844-477-4672.",
                "brand": "Spinraza",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5a7"
            },
            {
                "_id": "6297bcace0e0db6c2572b5ac",
                "link": "https://www.janssencarepath.com/patient/spravato/cost-support",
                "description": "Spravato Janssen CarePath Savings Program: Most eligible commercially insured patients may pay $10 per treatment with savings of up to $7150 per calendar year; for additional information contact the program at 877-227-3728.",
                "brand": "Spravato",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "7150",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5ac"
            },
            {
                "_id": "6297bcace0e0db6c2572b5b1",
                "link": "https://www.sprix.com/patient/sprix-direct/",
                "description": "Sprix Co-Pay Card: Eligible commercially insured patients may pay $0 per prescription and have their product delivered to their home; for additional information contact the program 844-977-7749.",
                "brand": "Sprix",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5b1"
            },
            {
                "_id": "6297bcace0e0db6c2572b5b6",
                "link": "https://www.merckconnect.com/steglujan/coupons-samples/",
                "description": "Steglujan FREE Trial Voucher: Healthcare providers may be able to provide their patients with FREE Trial vouchers; vouchers may be order by contacting 866-237-4286 or CSR.Mailbox@SymmetryRx.com.",
                "brand": "Steglujan",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5b6"
            },
            {
                "_id": "6297bcace0e0db6c2572b5bb",
                "link": "https://stendra.com",
                "description": "Stendra Instant Savings Card: Eligible cash-paying patients may save up to $30 per tablet; for additional information contact the program at 844-274-2837.",
                "brand": "Stendra",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 30,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5bb"
            },
            {
                "_id": "6297bcace0e0db6c2572b5c0",
                "link": "https://www.stivarga-us.com/co-pay",
                "description": "Stivarga Co-Pay Program: Eligible commercially insured patients may pay $0 per prescription with savings of up to $25,000 per year; for additional information contact the program at 866-581-4992.",
                "brand": "Stivarga",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5c0"
            },
            {
                "_id": "6297bcace0e0db6c2572b5c5",
                "link": "https://alexiononesource.com/#financial-assistance",
                "description": "Strensiq Alexion OneSource CoPay Program: Eligible commercially insured patients may pay as little as $0 in out-of-pocket costs up to a maximum savings of $15,000 per calendar year; for additional information contact the program at 888-765-4747.",
                "brand": "Strensiq",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5c5"
            },
            {
                "_id": "6297bcace0e0db6c2572b5ca",
                "link": "https://subsys.com",
                "description": "Subsys Cares Card: Eligible commercially insured patients may receive the 1st prescription of 10 units for FREE and up to 30 units of any dose for the 2nd fill; beginning with the 3rd fill the patient may pay as little as $0 per fill; for additional information contact the program at 844-309-3835.",
                "brand": "Subsys",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "56000",
                "lifetime_uses": "0",
                "max_saving_transaction": 4000,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5ca"
            },
            {
                "_id": "6297bcace0e0db6c2572b5cf",
                "link": "https://mycareclubrewards.com/Sudafed/?utm_medium=partner&utm_source=sudafed.com&utm_content=header&utm_campaign=cc_main&_gl=1*6y433b*_ga*NTAzNzk2MTQ2LjE2MzUxNzUyNDg.*_ga_13VEM6N66E*MTYzNTg3MjkzNi4zLjEuMTYzNTg3Mjk0OS40Nw..*_fplc*NTNqOUVYeU9heHpBaUFHZWRYaFQ4MHJlZDNaa0pIbHlHUWpaUmRtUHhlc0FEWkk0WWklMkJQV0laRU1wUGhMN2clMkZRQUlDbjRRY0tBaTVaYnVHcjN2VUNoSnc0elpLc2NSNEFDcktjNFRmUiUyRkp0QlE5cnlNWjE2NkpCQWUzWmJRJTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Sudafed products with registration.",
                "brand": "Sudafed Congestion Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5cf"
            },
            {
                "_id": "6297bcace0e0db6c2572b5d4",
                "link": "https://mycareclubrewards.com/Sudafed/?utm_medium=partner&utm_source=sudafed.com&utm_content=header&utm_campaign=cc_main&_gl=1*6y433b*_ga*NTAzNzk2MTQ2LjE2MzUxNzUyNDg.*_ga_13VEM6N66E*MTYzNTg3MjkzNi4zLjEuMTYzNTg3Mjk0OS40Nw..*_fplc*NTNqOUVYeU9heHpBaUFHZWRYaFQ4MHJlZDNaa0pIbHlHUWpaUmRtUHhlc0FEWkk0WWklMkJQV0laRU1wUGhMN2clMkZRQUlDbjRRY0tBaTVaYnVHcjN2VUNoSnc0elpLc2NSNEFDcktjNFRmUiUyRkp0QlE5cnlNWjE2NkpCQWUzWmJRJTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Sudafed products with registration.",
                "brand": "Sudafed PE Sinus Congestion Day - Night",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5d4"
            },
            {
                "_id": "6297bcace0e0db6c2572b5d9",
                "link": "https://www.medimetriks.com/prescription-brands/sumaxin-cp-kit-sumaxin-cleansing-pads",
                "description": "Sumaxin Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Sumaxin",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5d9"
            },
            {
                "_id": "6297bcace0e0db6c2572b5de",
                "link": "https://www.sundownnutrition.com/coupons/",
                "description": "Register your email address to receive special offers on Sundown Vitamin & Supplement products.",
                "brand": "Sundown Vitamin and Supplement Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5de"
            },
            {
                "_id": "6297bcace0e0db6c2572b5e3",
                "link": "https://jazzhcpsamples.qpharmacorp.com",
                "description": "Sunosi Samples: Healthcare professionals may request free samples for this office by logging onto the website.",
                "brand": "Sunosi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5e3"
            },
            {
                "_id": "6297bcace0e0db6c2572b5e8",
                "link": "https://www.polident.com/en-us/save-now/poligrip-coupon/",
                "description": "Save $1 on any one Super Poligrip product with registration; 1 coupon per purchase.",
                "brand": "Super Poligrip Cushion and Comfort",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5e8"
            },
            {
                "_id": "6297bcace0e0db6c2572b5ed",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/overview/?utm_source=google&utm_medium=cpc&utm_term=super%20poligrip&utm_campaign=GS%20-%20Branded_Poligrip%20EX_DP&gclid=Cj0KCQiA962BBhCzARIsAIpWEL0O5zEg5LMkWP-xbEDatBaEd896PMS8BoY0ynLITYi-fwgKKWPSrcUaAiBqEALw_wcB&gclsrc=aw.ds",
                "description": "Super Poligrip Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Super Poligrip Extra Strength Powder",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5ed"
            },
            {
                "_id": "6297bcace0e0db6c2572b5f2",
                "link": "https://www.polident.com/en-us/save-now/poligrip-coupon/",
                "description": "Save $1 on any one Super Poligrip product with registration; coupon expires 1 month after printing; 1 coupon per purchase.",
                "brand": "Super Poligrip Ultra Fresh",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5f2"
            },
            {
                "_id": "6297bcace0e0db6c2572b5f7",
                "link": "https://www.sustol.com/patient/patient-support-services",
                "description": "Sustol Heron Connect Copay Assistance Program: Eligible commercially insured patients may pay $0 copay per treatment; your healthcare provider must complete enrollment form; for additional information contact the program at 844-437-6611.",
                "brand": "Sustol",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 200,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5f7"
            },
            {
                "_id": "6297bcace0e0db6c2572b5fc",
                "link": "https://sutab.com/Savings",
                "description": "Sutab Savings Copay Card: Eligible uninsured/cash-paying patients may pay as little as $50 per prescription; for additional information contact the program at 844-926-4140.",
                "brand": "Sutab",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5fc"
            },
            {
                "_id": "6297bcace0e0db6c2572b601",
                "link": "https://www.vertexgps.com/about-vertex-gps#overviewVid",
                "description": "Symdeko Vertex GPS Financial Assistance Program: Eligible commercially insured patients may pay as little as $15 per prescription; for additional information contact the program at 877-752-5933 (press 2).",
                "brand": "Symdeko",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b601"
            },
            {
                "_id": "6297bcace0e0db6c2572b606",
                "link": "https://www.symlinhcp.com/access-and-savings-card.html#patient-savings",
                "description": "SymlinPen MySavingsRx Card: Eligible commercially insured patients may pay as low as $25 on each 30-day supply prescription; for additional information contact the program at 855-292-5968.",
                "brand": "Symlin",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b606"
            },
            {
                "_id": "6297bcace0e0db6c2572b60b",
                "link": "https://www.symtuza.com/support-program",
                "description": "Symtuza Janssen CarePath Savings Program: Most eligible commercially insured patients may pay $0 per fill on out-of-pocket costs with savings of up to $12,500 per calendar year; for additional assistance contact the program at 866-836-0114.",
                "brand": "Symtuza",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "12500",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b60b"
            },
            {
                "_id": "6297bcace0e0db6c2572b610",
                "link": "https://www.pfizermedicalinformation.com/en-us/synarel",
                "description": "Synarel Samples: Your healthcare provider may request samples; for additional information contact the program at 866-706-2400.",
                "brand": "Synarel",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b610"
            },
            {
                "_id": "6297bcace0e0db6c2572b615",
                "link": "https://www.synjardy.com/support-and-savings",
                "description": "Synjardy XR Simple Savings Card: Eligible commercially insured patients may pay as little as $10 per prescription with savings of up to $175 per 30-day supply; for additional information contact the program at 866-279-8990.",
                "brand": "Synjardy XR",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "2100",
                "lifetime_uses": "12",
                "max_saving_transaction": 175,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b615"
            },
            {
                "_id": "6297bcace0e0db6c2572b61a",
                "link": "https://www.eyefile.com/theeyesolution/Register",
                "description": "Save $3 on your next purchase of any one Systane Eye Drops Product (10 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Systane Contacts Lubricant Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b61a"
            },
            {
                "_id": "6297bcace0e0db6c2572b61f",
                "link": "https://www.eyefile.com/theeyesolution/Register",
                "description": "Save $3 on your next purchase of any one Systane Eye Drops Product (10 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Systane Lubricant Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b61f"
            },
            {
                "_id": "6297bcace0e0db6c2572b624",
                "link": "https://copay.novartisoncology.com/",
                "description": "Tafinlar Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Tafinlar",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b624"
            },
            {
                "_id": "6297bcace0e0db6c2572b629",
                "link": "https://www.taltz.com/patient-support",
                "description": "Taltz Savings Card: Eligible commercially insured patients may pay as little as $5 per monthly fill; program is good for up to 36 months; for additional information contact the program at 844-825-8966.",
                "brand": "Taltz",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b629"
            },
            {
                "_id": "6297bcace0e0db6c2572b62e",
                "link": "https://uspco.com/product_literature.aspx?pid=130&tab=Contact",
                "description": "Tandem Plus Samples: Healthcare providers may request samples for their practice by filling out information online.",
                "brand": "Tandem Plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b62e"
            },
            {
                "_id": "6297bcace0e0db6c2572b633",
                "link": "https://copay.novartisoncology.com/",
                "description": "Tasigna Novartis Universal Co-Pay Card: Eligible commercially insured patients may pay no more than $25 for each prescription with a maximum savings of $15,000 per calendar year; for additional information contact the program at 877-577-7756.",
                "brand": "Tasigna",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b633"
            },
            {
                "_id": "6297bcace0e0db6c2572b638",
                "link": "https://almiralladvantage.com/",
                "description": "Tazorac Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $35 per prescription; for additional information contact the program at 888-591-9860.",
                "brand": "Tazorac",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b638"
            },
            {
                "_id": "6297bcace0e0db6c2572b63d",
                "link": "https://www.tena.us/on/demandware.store/Sites-Tena_US-Site/en_US/TrialRequest-Start",
                "description": "Receive a FREE Trial Kit of Tena Underwear products with registration; for additional information contact the program at 866-275-8362.",
                "brand": "Tena Personal Care Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b63d"
            },
            {
                "_id": "6297bcace0e0db6c2572b642",
                "link": "https://www.oncnavigationcenter.com/en/Co-Pay-Assistance.html",
                "description": "Tepmetko ONC Co-Pay Assistance Program: Eligible commercially insured patients may pay as little as $0 per prescription; maximum savings of $15000 per year; for additional information contact the program at 844-662-3631.",
                "brand": "Tepmetko",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b642"
            },
            {
                "_id": "6297bcace0e0db6c2572b647",
                "link": "https://www.theraflu.com/coupon/",
                "description": "Register your email address and receive a $1.00 coupon off of any Theraflu product; limit 1 coupon per purchase; coupon expires 1�month after printing.",
                "brand": "Theraflu Daytime Severe Cold / Cough",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b647"
            },
            {
                "_id": "6297bcace0e0db6c2572b64c",
                "link": "https://www.theraflu.com/coupon/",
                "description": "Register your email address and receive a $1.00 coupon off of any Theraflu product; limit 1 coupon per purchase; coupon expires 1�month after printing.",
                "brand": "Theraflu ExpressMax Severe Cold / Flu",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b64c"
            },
            {
                "_id": "6297bcace0e0db6c2572b651",
                "link": "https://www.theraflu.com/coupon/",
                "description": "Register your email address and receive a $1.00 coupon off of any Theraflu product; limit 1 coupon per purchase; coupon expires 1�month after printing.",
                "brand": "Theraflu Sinus / Pain",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b651"
            },
            {
                "_id": "6297bcace0e0db6c2572b656",
                "link": "https://www.theratears.com/products/",
                "description": "Save $1 on TheraTears products; trial sizes not included in offer; one coupon per purchase.",
                "brand": "TheraTears Eye Nutrition Supplement",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b656"
            },
            {
                "_id": "6297bcace0e0db6c2572b65b",
                "link": "https://thyquiditycoupon.com/card/terms",
                "description": "Thyquidity Savings Card: Eligible uninsured/cash-paying patients will save on their prescriptions; amount saved depends on the pharmacy; maximum annual savings limit is $1750; for additional information contact the program at 844-903-2661.",
                "brand": "Thyquidity",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1750",
                "lifetime_uses": "12",
                "max_saving_transaction": 145.83,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b65b"
            },
            {
                "_id": "6297bcace0e0db6c2572b660",
                "link": "https://tiglutik.com/what-does-tiglutik-cost/",
                "description": "Tiglutik Co-Pay Support Program: Eligible commercially insured patients may pay as little as $50 per filled prescription; for additional information contact the program at 844-763-1198.",
                "brand": "Tiglutik",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b660"
            },
            {
                "_id": "6297bcace0e0db6c2572b665",
                "link": "https://www.tirosint.com/patient-savings-resources/tirosint-direct-program/",
                "description": "Tirosint Direct Program: Eligible patients ordering through the Tirosint Direct Program may pay as little as $40 per 90-day prescription; for additional information contact the program at 888-664-5427.",
                "brand": "Tirosint",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "4",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b665"
            },
            {
                "_id": "6297bcace0e0db6c2572b66a",
                "link": "https://myviivcard.com/rebate-form-instructions.html",
                "description": "Tivicay ViiVConnect Rebate: Eligible commercially insured patients may be eligible for a rebate for the amount paid out of pocket per prescription if the pharmacy does not accept the savings card; rebate may be completed online or by downloading and mailing in rebate form; for additional information contact the program at 844-588-3288.",
                "brand": "Tivicay",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b66a"
            },
            {
                "_id": "6297bcace0e0db6c2572b66f",
                "link": "https://www.tobipodhaler.com/en/getting-started-with-tobi-podhaler/what-is-podcare",
                "description": "Tobi PodCare+ Co-pay Program: Eligible commercially insured patients may pay a $0 prescription co-pay with savings of up to $14,000 per calendar year; for additional information contact the program at 877-999-8624.",
                "brand": "Tobi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "14000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b66f"
            },
            {
                "_id": "6297bcace0e0db6c2572b674",
                "link": "https://www.topamax.com/coupon.html",
                "description": "Topamax Savings Program Card: Eligible commercially insured patients may pay $4 per fill; maximum savings of $1800 per calendar year or 12 fills per calendar year whichever comes first; for additional information contact the program at 800-526-7736.",
                "brand": "Topamax",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b674"
            },
            {
                "_id": "6297bcace0e0db6c2572b679",
                "link": "https://www.patientrebateonline.com/patientrebate/welcome.html",
                "description": "Toujeo Valyou Savings Rebate: Eligible uninsured and cash-paying patients using a mail-order pharmacy can submit a request for a rebate up to the amount of savings earned with the Savings Card; or additional information contact the program at 866-390-5622.",
                "brand": "Toujeo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b679"
            },
            {
                "_id": "6297bcace0e0db6c2572b67e",
                "link": "https://www.patientrebateonline.com/patientrebate/welcome.html",
                "description": "Toujeo Solostar Sanofi Savings Card Rebate: Eligible commercially patients using a mail-order pharmacy can submit a request for a rebate up to the amount of savings earned with the Savings Card; or additional information contact the program at 866-390-5622.",
                "brand": "Toujeo Solostar",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b67e"
            },
            {
                "_id": "6297bcace0e0db6c2572b683",
                "link": "https://www.pfizercopay.com/",
                "description": "Trazimera Pfizer Oncology Together Co-Pay Savings Program for Injectables: Eligible commercially insured patients may pay as little as $0 per treatment; maximum savings limits per calendar year apply; for more information contact the program at 877-744-5675.",
                "brand": "Trazimera",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b683"
            },
            {
                "_id": "6297bcace0e0db6c2572b688",
                "link": "https://www.janssencarepath.com/patient/tremfya/cost-support",
                "description": "Tremfya Janssen CarePath Savings Program Rebate: Eligible commercially insured patients may submit a rebate request if the pharmacy does not accept the Savings Card; for additional assistance contact the program at 877-227-3728.",
                "brand": "Tremfya",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1666.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b688"
            },
            {
                "_id": "6297bcace0e0db6c2572b68d",
                "link": "https://www.novomedlink.com/diabetes/samples.html",
                "description": "Tresiba Samples: Healthcare providers may submit a sample request every 30 days.",
                "brand": "Tresiba U-100",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b68d"
            },
            {
                "_id": "6297bcace0e0db6c2572b692",
                "link": "https://www.treximet.com/Patient/",
                "description": "Treximet Mail-In Rebate: Eligible patients may receive a rebate if their pharmacy is unable to process the Savings Card; rebate instructions are found on the Savings Card;  for additional information contact the program at 855-830-9254.",
                "brand": "Treximet",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b692"
            },
            {
                "_id": "6297bcace0e0db6c2572b697",
                "link": "https://www.triaminic.com/signup",
                "description": "Register for special offers, latest news and updates for Triaminic products.",
                "brand": "Triaminic Cough and Sore Throat",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b697"
            },
            {
                "_id": "6297bcace0e0db6c2572b69c",
                "link": "https://tribenzor.com/home",
                "description": "Tribenzor Pre-activated Savings Card: Eligible uninsured patients may receive $25 off the retail price for each prescription fill; for additional information contact the program at 877-264-2440.",
                "brand": "Tribenzor",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "300",
                "lifetime_uses": "12",
                "max_saving_transaction": 25,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b69c"
            },
            {
                "_id": "6297bcace0e0db6c2572b6a1",
                "link": "https://triptodur.com/care-program.php",
                "description": "Triptodur Copay Assistance Program: Eligible commercially insured patients may pay as little as $5 per fill; maximum savings of $10,000 per calendar year; for additional information contact the program at 833-401-2273.",
                "brand": "Triptodur",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b6a1"
            },
            {
                "_id": "6297bcace0e0db6c2572b6a6",
                "link": "https://myviivcard.com/rebate-form-instructions.html",
                "description": "Triumeq ViiVConnect Rebate: Eligible commercially insured patients may be eligible for a rebate for the amount paid out of pocket per prescription if the pharmacy does not accept the savings card; rebate may be completed online or by downloading and mailing in rebate form; for additional information contact the program at 844-588-3288.",
                "brand": "Triumeq",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "7500",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b6a6"
            },
            {
                "_id": "6297bcade0e0db6c2572b6ab",
                "link": "https://www.trokendixr.com/co-pay-savings-program-support",
                "description": "Trokendi XR Co-pay Savings Card: Eligible commercially insured patients may pay $0 co-pay per prescription; for additional information contact the program at 866-398-0833.",
                "brand": "Trokendi XR",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6ab"
            },
            {
                "_id": "6297bcade0e0db6c2572b6b0",
                "link": "https://www.trulicity.com/savings-resources",
                "description": "Trulicity Savings Card: Eligible commercially insured patients may pay no more than $25 per prescription (4 pens) with savings of up to $150 per month; for additional information contact the program at 844-878-4636.",
                "brand": "Trulicity",
                "copay": "Yes",
                "bin": "18844",
                "rxpcn": "3F",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6b0"
            },
            {
                "_id": "6297bcade0e0db6c2572b6b5",
                "link": "https://www.tudorza.com",
                "description": "Tudorza Pressair Savings Card: Eligible commercially insured patients may pay $0 for each 30-day supply (1 inhaler); to obtain information for use of the offer contact the program at 800-236-9933.",
                "brand": "Tudorza Pressair",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "480",
                "lifetime_uses": "12",
                "max_saving_transaction": 450,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6b5"
            },
            {
                "_id": "6297bcade0e0db6c2572b6ba",
                "link": "https://www.pfizermedicalinformation.com/en-us/tygacil/dosage-admin",
                "description": "Tygacil Samples: Your healthcare provider may request samples by logging into the Pfizer Pro website or calling 800-505-4426.",
                "brand": "Tygacil",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6ba"
            },
            {
                "_id": "6297bcade0e0db6c2572b6bf",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol Cold plus Flu Severe Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6bf"
            },
            {
                "_id": "6297bcade0e0db6c2572b6c4",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol Extra Strength Coated Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6c4"
            },
            {
                "_id": "6297bcade0e0db6c2572b6c9",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol PM Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6c9"
            },
            {
                "_id": "6297bcade0e0db6c2572b6ce",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol Sinus Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6ce"
            },
            {
                "_id": "6297bcade0e0db6c2572b6d3",
                "link": "https://www.tysabri.com/en_us/home/join-biogen-support/financial-support.html",
                "description": "Tysabri Biogen Copay Program: Eligible commercially insured patients will pay $0 Copay with enrollment in the program; for additional information contact the 800-456-2255.",
                "brand": "Tysabri",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6d3"
            },
            {
                "_id": "6297bcade0e0db6c2572b6d8",
                "link": "https://www.ucerisfoam.com/savings#formcontent",
                "description": "Uceris (Foam) Savings Card Rebate: Eligible commercially insured patients may be eligible for a rebate if their use a mail-order pharmacy & their mail-order pharmacy is unable to process the Savings Card; patients can visitwww.patientrebateonline.com for instructions; for additional information contact the program at 855-558-1669.",
                "brand": "Uceris",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6d8"
            },
            {
                "_id": "6297bcade0e0db6c2572b6dd",
                "link": "https://ultomiris.com/ahus/resources-and-support/copay",
                "description": "Ultomiris Alexion OneSource CoPay Program: Eligible commercially insured patients may pay as little as $0 in out-of-pocket costs up to a maximum savings of $15,000 per calendar year; for additional information contact the program at 888-765-4747.",
                "brand": "Ultomiris",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "15000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6dd"
            },
            {
                "_id": "6297bcade0e0db6c2572b6e2",
                "link": "https://www.unisom.com/en-us/coupons",
                "description": "Save $1 on any Unisom product (24 count or larger); one coupon per purchase; expires one month after printing",
                "brand": "Unisom SleepMelts",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6e2"
            },
            {
                "_id": "6297bcade0e0db6c2572b6e7",
                "link": "https://www.unithroid.com/#help-patients-save",
                "description": "Unithroid Co-Pay Coupon: Eligible commercially patients may pay no more than $3 per 30-day supply or no more than $6 per 60-day supply or no more than $8 per 90-day supply; for additional information contact the program at 877�-264�-2440.",
                "brand": "Unithroid",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "576",
                "lifetime_uses": "12",
                "max_saving_transaction": 32,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6e7"
            },
            {
                "_id": "6297bcade0e0db6c2572b6ec",
                "link": "https://www.alvaprofessional.com/request-samples",
                "description": "Uricalm Product Samples: Your healthcare provider may request samples by filling out a form online.",
                "brand": "Uricalm",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6ec"
            },
            {
                "_id": "6297bcade0e0db6c2572b6f1",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Utopic Artesa Labs Advantage Card: Eligible commercially insured patients may pay $20 per prescription; for additional information contact the program 877-264-2440.",
                "brand": "Utopic Cream",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6f1"
            },
            {
                "_id": "6297bcade0e0db6c2572b6f6",
                "link": "https://www.varubirx.com/",
                "description": "Varubi Free Samples: Healthcare professionals may request samples by calling 877-827-8241.",
                "brand": "Varubi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6f6"
            },
            {
                "_id": "6297bcade0e0db6c2572b6fb",
                "link": "https://www.vazalore.com/coupon/",
                "description": "Save $1 on any Vazalore product (81mg or 325mg); one coupon per purchase; coupon expires one month after printing.",
                "brand": "Vazalore",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6fb"
            },
            {
                "_id": "6297bcade0e0db6c2572b700",
                "link": "https://www.vemlidy.com/savings",
                "description": "Vemlidy Co-pay Coupon Program: Eligible commercially insured patients may pay as little as a $0 co-pay for out-of-pocket costs with a savings of up to $5000 per year with no monthly limit; for additional information contact the program at 877-627-0415.",
                "brand": "Vemlidy",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b700"
            },
            {
                "_id": "6297bcade0e0db6c2572b705",
                "link": "https://almiralladvantage.com",
                "description": "Verdeso (Foam) Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $15 per prescription when using a network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Verdeso",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b705"
            },
            {
                "_id": "6297bcade0e0db6c2572b70a",
                "link": "https://www.verzenio.com/savings-support#continuous-care",
                "description": "Verzenio Savings Card: Eligible commercially insured patients pay no more than $0 per month with savings of up to $25,000 per year; for additional information contact the program at 844-837-9364.",
                "brand": "Verzenio",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 2083.33,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b70a"
            },
            {
                "_id": "6297bcade0e0db6c2572b70f",
                "link": "https://www.abbvieaccess.com/viberzi",
                "description": "Viberzi Samples: Healthcare providers may order samples for their practice  by logging on the website or faxing the order form to 877-477-1258.",
                "brand": "Viberzi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b70f"
            },
            {
                "_id": "6297bcade0e0db6c2572b714",
                "link": "https://www.pggoodeveryday.com/signup/",
                "description": "Join P&G Everyday to receive coupons, samples and savings on VICKS products with registration.",
                "brand": "Vicks Night Time FluTherapy SEVERE Cold and Flu Treatment",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b714"
            },
            {
                "_id": "6297bcade0e0db6c2572b719",
                "link": "https://www.pggoodeveryday.com/signup/",
                "description": "Join P&G Everyday to receive coupons, samples and savings on VICKS products with registration.",
                "brand": "VICKS Vapo Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b719"
            },
            {
                "_id": "6297bcade0e0db6c2572b71e",
                "link": "https://www.vimovo.com",
                "description": "Vimovo Savings Card Program: Eligible commercially insured patients pay as little as $0 with savings of up to $1200 30-day prescription (60 pills); for additional information contact the program at 855-881-3093.",
                "brand": "Vimovo",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "14400",
                "lifetime_uses": "12",
                "max_saving_transaction": 1200,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b71e"
            },
            {
                "_id": "6297bcade0e0db6c2572b723",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Visine products with registration.",
                "brand": "Visine AC Itchy Eye Relief Astringent/Redness Reliever Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b723"
            },
            {
                "_id": "6297bcade0e0db6c2572b728",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Visine products with registration.",
                "brand": "Visine Allergy Eye Relief Multi-Action Antihistamine and Redness Reliever",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b728"
            },
            {
                "_id": "6297bcade0e0db6c2572b72d",
                "link": "https://www.visine.com/coupon-offers",
                "description": "$1 coupon for any one Visine product (0.5 oz or larger); 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Visine Maximum Strength Redness Relief Formula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b72d"
            },
            {
                "_id": "6297bcade0e0db6c2572b732",
                "link": "https://www.visine.com/coupon-offers",
                "description": "$1 coupon for any one Visine product (0.5 oz or larger); 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Visine Red Eye Comfort Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b732"
            },
            {
                "_id": "6297bcade0e0db6c2572b737",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Visine products with registration.",
                "brand": "Visine Totality",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b737"
            },
            {
                "_id": "6297bcade0e0db6c2572b73c",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Mail-In Rebate: If your pharmacy does not accept the Savings Card or you use a mail-order pharmacy you may submit your original receipt, a photocopy of your savings card (front and back) along with your date of birth to the program to receive your proper savings; for additional information contact the program directly at 855-881-3090.",
                "brand": "Vitafol",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b73c"
            },
            {
                "_id": "6297bcade0e0db6c2572b741",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Gummies Instant Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Vitafol Gummies",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b741"
            },
            {
                "_id": "6297bcade0e0db6c2572b746",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Nano Instant Savings Card: Eligible cash-paying patients may pay approximately $65 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Vitafol Nano",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b746"
            },
            {
                "_id": "6297bcade0e0db6c2572b74b",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol One Instant Savings Card: Eligible commercially insured patients may pay as little as $25 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Vitafol One",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b74b"
            },
            {
                "_id": "6297bcade0e0db6c2572b750",
                "link": "https://vitafol.com/sample-kit/",
                "description": "Vitafol Free Offer: You may receive a FREE sample offer by completing the sign-up information found on the website.",
                "brand": "Vitafol ULTRA-FirstStep",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b750"
            },
            {
                "_id": "6297bcade0e0db6c2572b755",
                "link": "https://www.gummyvites.com/en/Coupons2",
                "description": "Save $2 off any Vitafusion product when you signup online with your email address.",
                "brand": "Vitafusion Gummy Kids Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b755"
            },
            {
                "_id": "6297bcade0e0db6c2572b75a",
                "link": "https://www.gummyvites.com/en/Coupons2",
                "description": "Save $2 off any Vitafusion product when you signup online with your email address.",
                "brand": "Vitafusion Gummy Womens Multivitamin Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b75a"
            },
            {
                "_id": "6297bcade0e0db6c2572b75f",
                "link": "https://www.vitrakvicopaysupport.com/",
                "description": "Vitrakvi TRAK Assist $0 Co-Pay Program: Eligible commercially insured patients may pay $0 co-pay per prescription with a maximum savings of $25,000 per year; for additional information contact the program at 647-245-5637.",
                "brand": "Vitrakvi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b75f"
            },
            {
                "_id": "6297bcade0e0db6c2572b764",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Vizimpro Pfizer Oncology Together Co-Pay Savings Program Rebate (oral products): Eligible commercially insured patients may submit a request for a rebate in connection with this offer if their pharmacy does not accept the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Vizimpro",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b764"
            },
            {
                "_id": "6297bcade0e0db6c2572b769",
                "link": "https://www.vonvendi.com/vonvendi-support",
                "description": "Vonvendi Freedom of Choice Free Trial: NEW patients may be eligible to recevie 3 FREE doses when their healthcare provider submits an application to the program; for additional information contact the program at 888-229-8379.",
                "brand": "Vonvendi",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b769"
            },
            {
                "_id": "6297bcade0e0db6c2572b76e",
                "link": "https://www.allergansavingscard.com/vraylar",
                "description": "Vraylar Savings Program: Eligible commercially insured patients/RX not covered may pay $75 on each of up to 12 (30-day) prescriptions; for additional information contact the program at 800-761-0436.",
                "brand": "Vraylar",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b76e"
            },
            {
                "_id": "6297bcade0e0db6c2572b773",
                "link": "https://www.vyepti.com/savings-and-support#copay-assistance-program",
                "description": "Vyepti Copay Assistance Program: Eligible commercially insured patients pay as little as $5 per treatment; savings of up to $4000 per calendar year; for additional information contact the program at 833-489-3784.",
                "brand": "Vyepti",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "4000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b773"
            },
            {
                "_id": "6297bcade0e0db6c2572b778",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Vytone Artesa Labs Advantage Card: Eligible commercially insured patients may pay as little as $0 per prescription; for additional information contact the program 877-264-2440.",
                "brand": "Vytone Cream",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b778"
            },
            {
                "_id": "6297bcade0e0db6c2572b77d",
                "link": "https://wakix.com/wakix-for-you/",
                "description": "Wakix for You Copay Program: Eligible commercially insured patients may pay as little as a $0 per prescription; for additional information contact the program at 855-925-4948.",
                "brand": "Wakix",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b77d"
            },
            {
                "_id": "6297bcade0e0db6c2572b782",
                "link": "https://www.wilateusa.com/vwd/factor-my-way/co-pay-assistance-reimbursement/",
                "description": "Wilate Factor My Way Co-Pay Assistance Program: Eligible patients may save up to $12,000 per year on co-pays, deductibles and co-insurance; for additional information contact the program at 866-526-4109 .",
                "brand": "Wilate",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b782"
            },
            {
                "_id": "6297bcade0e0db6c2572b787",
                "link": "https://www.xadago.com/savings-and-support",
                "description": "Xadago Copay Card: Eligible commercially insured patients pay no more than $15 per monthly prescription with a savings of up to $250 per fill at a participating pharmacy; for additional information contact the program at 888-492-3246.",
                "brand": "Xadago",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b787"
            },
            {
                "_id": "6297bcade0e0db6c2572b78c",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Xalkori Pfizer Oncology Together Co-Pay Savings Program Rebate (oral products): Eligible commercially insured patients may submit a request for a rebate in connection with this offer if their pharmacy does not accept the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Xalkori",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b78c"
            },
            {
                "_id": "6297bcade0e0db6c2572b791",
                "link": "https://www.xarelto-us.com/xarelto-cost#commercial-insurance",
                "description": "Xarelto Janssen CarePath Savings Card: Eligible commercially insured patients may pay no more than $10 per fill with savings of up to $3400 per calendar year; there is no limit to this benefit for the first three months; then $200 limit for each month thereafter; for additional assistance contact the program at 888-927-3586.",
                "brand": "Xarelto",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "3400",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b791"
            },
            {
                "_id": "6297bcade0e0db6c2572b796",
                "link": "https://www.xeljanz.com/savings-and-support?ind=ra",
                "description": "Xeljanz XR Interim Care Rx Program: Eligible commercially insured patients may receive a 30-day supply of their medication when there is a delay during the prior authorization or appeals process; refills are subject to limitations; for additional information contact the program at 844-935-5269.",
                "brand": "Xeljanz",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b796"
            },
            {
                "_id": "6297bcade0e0db6c2572b79b",
                "link": "https://www.myxelpros.com/savings/",
                "description": "Xelpros Xpress Program: Eligible patients may receive a 1-month supply for $55 or a 3-month supply for $110 (a 33% savings); for additional information contact the program at 800-818-4555.",
                "brand": "Xelpros",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b79b"
            },
            {
                "_id": "6297bcade0e0db6c2572b7a0",
                "link": "https://www.xeomin.com/patient-assistance#terms-and-conditions-and-program-limitations",
                "description": "Xeomin Patient Savings Program: Eligible commercially insured patients may pay as little as $0 out of pocket per prescription up to a maximum amount of $5000 per calendar year; for additional information contact the program at 844-469-6379.",
                "brand": "Xeomin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7a0"
            },
            {
                "_id": "6297bcade0e0db6c2572b7a5",
                "link": "https://amgenfirststep.com",
                "description": "Xgeva Amgen First Step Program Rebate: Eligible commercially insured patients who have already paid for treatment may request a rebate check; for additional information contact the program at 888-657-8371.",
                "brand": "Xgeva",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7a5"
            },
            {
                "_id": "6297bcade0e0db6c2572b7aa",
                "link": "https://dupuytrens-contracture.xiaflex.com/patient/savings/",
                "description": "Xiaflex Copay Assistance Program (patients with Dupuytren's Disease): Most eligible patients may pay $0 per prescription with savings of up to $1200 on out-of-pocket costs per vial; for additional information contact the program at 877-942-3539.",
                "brand": "Xiaflex",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1200,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7aa"
            },
            {
                "_id": "6297bcade0e0db6c2572b7af",
                "link": "https://www.xigduoxr.com/savings-and-support/rx-savings.html",
                "description": "Xigduo XR SavingsRx Card: Eligible cash-paying patients may save a maximum of up to $150 for each monthly prescription; for additional information contact the program at 844-631-3978.",
                "brand": "Xigduo XR",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7af"
            },
            {
                "_id": "6297bcade0e0db6c2572b7b4",
                "link": "https://www.xofigo-us.com/patient/xofigo-support",
                "description": "Xofigo Copay Assistance Access Services: Eligible commercially insured patients may have $0 copay per prescription; to enroll and for additional information contact the program at 855-696-3446.",
                "brand": "Xofigo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7b4"
            },
            {
                "_id": "6297bcade0e0db6c2572b7b9",
                "link": "https://xolaircopay.com/using-the-card",
                "description": "Xolair (Injection) Co-Pay Card Reimbursement Request: If an�eligible commercially insured patients' provider or pharmacy requires that the patient pay upfront for treatment then the patient may submit a reimbursement request form; for further assistance contact the program at 855-965-2472.",
                "brand": "Xolair",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1000",
                "lifetime_uses": "12",
                "max_saving_transaction": 84,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7b9"
            },
            {
                "_id": "6297bcade0e0db6c2572b7be",
                "link": "https://www.karyforward.com/#enroll-today",
                "description": "Xpovio Co-Pay Card Program: Eligible commercially insured patients may pay as little as $5 per 30-day prescription with a maximum of $8,000 per month and up to a maximum total of $25,000 per calendar year; for additional information contact the program at 855-820-3226.",
                "brand": "Xpovio",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 8000,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7be"
            },
            {
                "_id": "6297bcade0e0db6c2572b7c3",
                "link": "https://www.xyntha.com/resources",
                "description": "Xyntha Solofuse Pfizer Factor Savings Card: Eligible commercially insured patients may save up to $12,000 per calendar year toward co-pay, deductible or coinsurance costs; for additional information contact the program at 844-989-4366.",
                "brand": "Xyntha Solofuse",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7c3"
            },
            {
                "_id": "6297bcade0e0db6c2572b7c8",
                "link": "https://jazzcares.com/patients/xyrem/",
                "description": "Xyrem Bridge Program: Eligible commercially insured patients who are experiencing a continued or new delay in coverage may receive up to 4 months of medication free; for additional information contact the program at 833-533-5299.",
                "brand": "Xyrem",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7c8"
            },
            {
                "_id": "6297bcade0e0db6c2572b7cd",
                "link": "https://www.xyzal.com/coupons/",
                "description": "Save $3 on any one  XYZAL product; one coupon per purchase.",
                "brand": "Xyzal",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7cd"
            },
            {
                "_id": "6297bcade0e0db6c2572b7d2",
                "link": "https://www.yupelri.com/en/accessandcoverage?gclid=CjwKCAjwoP6LBhBlEiwAvCcthMOZ0DD4WcBTBzbuSUdrpEl6yqhll5HWsPMVQ_ekmGnKCDAytgmHcxoCAX4QAvD_BwE",
                "description": "Yupelri Savings Card: Eligible commercially insured patients may pay as little as $0 with a maximum savings of up to $550 per 30-day prescription; maximum savings of $6600 per calendar year; for additional information contact the program at 866-255-9018.",
                "brand": "Yupelri",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "6600",
                "lifetime_uses": "12",
                "max_saving_transaction": 550,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7d2"
            },
            {
                "_id": "6297bcade0e0db6c2572b7d7",
                "link": "https://www.togetherwithgskoncology.com/patient-information/zejula/",
                "description": "Zejula Quick Start Program: Eligible commercially insured patients may be provided with a 15-day supply of medication if experiencing a delay in coverage at first dispense; up to 4 refills may be provided under the program; for additional information contact your healthcare provider or the program at 844-447-5662.",
                "brand": "Zejula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7d7"
            },
            {
                "_id": "6297bcade0e0db6c2572b7dc",
                "link": "https://www.copayassistancenow.com/forms",
                "description": "Zelboraf Genentech Oncology Co-pay Assistance Program Rebate: Eligible commercially insured patients enrolled in the program may be entitled to a rebate for their out-of-pocket cost if they paid the provider directly for treatment; for additional information contact the program at 855-692-6729.",
                "brand": "Zelboraf",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7dc"
            },
            {
                "_id": "6297bcade0e0db6c2572b7e1",
                "link": "https://www.zenzedi.com/savings.php",
                "description": "Zenzedi Copay Savings Program: Eligible cash-paying patients may pay $75 per monthly prescription; for additional information contact the program at 855-558-1630.",
                "brand": "Zenzedi",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7e1"
            },
            {
                "_id": "6297bcade0e0db6c2572b7e6",
                "link": "https://myzerviate.com/copay/",
                "description": "Zerviate Copay Savings Program: Eligible Medicare patients, cash-paying patients and commercially insured patients/RX not covered pay $59 per fill; for additional information contact the program at 866-747-0976.",
                "brand": "Zerviate",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "4",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7e6"
            },
            {
                "_id": "6297bcade0e0db6c2572b7eb",
                "link": "https://myviivcard.com",
                "description": "Ziagen ViiVConnect Savings Card: Eligible commercially insured patients pay $0 per prescription with savings of up to $4800 per year with no monthly limit; for additional information contact the program at 844-588-3288.",
                "brand": "Ziagen",
                "copay": "Yes",
                "bin": "610626",
                "rxpcn": "1016",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7eb"
            },
            {
                "_id": "6297bcade0e0db6c2572b7f0",
                "link": "https://www.zioptan.com",
                "description": "Free Samples of Zioptan: Your healthcare provider may request Free Samples by registering; for additional information contact the program at 800-932-5676.",
                "brand": "Zioptan",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7f0"
            },
            {
                "_id": "6297bcade0e0db6c2572b7f5",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Zirgan Bausch + Lomb Access Program: Eligible commercially insured patients may pay no more than $25 for each prescription; for additional information contact the program 866-693-4880.",
                "brand": "Zirgan",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7f5"
            },
            {
                "_id": "6297bcade0e0db6c2572b7fa",
                "link": "https://zolgensmacopayassist.com/",
                "description": "Zolgensma CopayAssist Program: Eligible commercially insured patients may use this offer to pay for deductibles, co-pay, and co-insurance amounts for their prescriptions up to a certain maximum benefit; offer is valid for one year; for additional information contact the program at 855-441-4363.",
                "brand": "Zolgensma",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7fa"
            },
            {
                "_id": "6297bcade0e0db6c2572b7ff",
                "link": "https://myzolpimist.com/#rxconnect-section",
                "description": "ZolpiMist Savings Offer: Eligible commercially insured patients may pay as little as $15 per prescription; for additional information contact the program at 888-298-8792.",
                "brand": "ZolpiMist",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7ff"
            },
            {
                "_id": "6297bcade0e0db6c2572b804",
                "link": "https://www.zomigsavings.com/",
                "description": "Zomig-ZMT Savings Card: Eligible patients may pay as little as $25 per prescription; for additional information contact the program at 866-293-5817.",
                "brand": "Zomig-ZMT",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b804"
            },
            {
                "_id": "6297bcade0e0db6c2572b809",
                "link": "https://www.saveonmyprescription.com/copay.jsp",
                "description": "Zortress FREE trial offer: Eligible patients may receive a 30-day supply free; valid for one-time use only; for additional information contact the program at 877-952-1000.",
                "brand": "Zortress",
                "copay": "No",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b809"
            },
            {
                "_id": "6297bcade0e0db6c2572b80e",
                "link": "https://www.sagecentralsupport.com/zulressoforpatients/",
                "description": "Zulresso Infusion Copay Assistance Program: Eligible commercially insured patients may save up to $2000 for their out-of-pocket, drug-related costs; for additional information contact the program at 844-472-4379.",
                "brand": "Zulresso",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "2000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b80e"
            },
            {
                "_id": "6297bcade0e0db6c2572b813",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Zylet Bausch + Lomb Access Program: Eligible commercially insured patients may pay no more than $25 for each prescription; for additional information contact the program 866-693-4880.",
                "brand": "Zylet",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b813"
            },
            {
                "_id": "6297bcade0e0db6c2572b818",
                "link": "https://patient.zypitamag.com/one-dollar-per-day",
                "description": "Zypitamag $1 a day Program through Marley Drug: Patients pay a cash price of $1 per day for their prescription when enrolled in this program; patients cannot use insurance coverage when using this program; receive the 1st 30 days of medication FREE; open to ALL patients; FREE shipping on all orders; for additional information contact the program at 866-643-1524.",
                "brand": "Zypitamag",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b818"
            },
            {
                "_id": "6297bcade0e0db6c2572b81d",
                "link": "https://www.zyrtec.com/?action=sign-up",
                "description": "Receive a coupon for any one Zyrtec product and other exclusive offers  with sign up; 1 coupon per purchase.",
                "brand": "Zyrtec Allergy-Children's",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b81d"
            },
            {
                "_id": "6297bcade0e0db6c2572b822",
                "link": "https://mycareclubrewards.com/zyrtec/?utm_medium=partner&utm_source=zyrtec.com&utm_content=footer&utm_campaign=cc_main&_gl=1*i86ng6*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjQ3ODA2OS4zLjEuMTYzNjQ3ODE3Ny4xNQ..*_fplc*aTZTRSUyRktpOGJCeFFqdzlkb0ZtTmwlMkI1VXE4VkhJSERDdmI1Q1d6TklLMW1nOFVyaUJOQUdscnpCJTJGd1ZBRXFSSUlOaDVLSW5sMVoxSGtXOENrTFV2bUVGUk11YjRFS3JqZGNDN0VWR3JNMGFpMW9GNjJGZU9VWkdER1ZTa2x3JTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Zyrtec products with registration.",
                "brand": "Zyrtec Childrens Dissolve Tabs",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b822"
            },
            {
                "_id": "6297bcade0e0db6c2572b827",
                "link": "https://www.zyrtecprofessional.com/order",
                "description": "Zyrtec Samples: Healthcare providers may order samples for their practice by visiting the website.",
                "brand": "Zyrtec Liquid Gels",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b827"
            },
            {
                "_id": "6297bcade0e0db6c2572b82c",
                "link": "https://www.zyrtecprofessional.com/order",
                "description": "Zyrtec Samples: Healthcare providers may order samples for their practice by visiting the website.",
                "brand": "Zyrtec-D",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b82c"
            },
            {
                "_id": "6297bcade0e0db6c2572b831",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for ZzzQuil Products!",
                "brand": "ZzzQuil Calming Vanilla Cherry",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b831"
            },
            {
                "_id": "6297bcade0e0db6c2572b836",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for ZzzQuil Products!",
                "brand": "ZzzQuil Warming Berry Liquid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b836"
            },
            {
                "_id": "6297bcace0e0db6c2572b54b",
                "link": "https://www.sensodyne.com/en-us/coupon/",
                "description": "Save $1 on any one Sensodyne toothpaste product (excludes travel sizes) with registration of your name and email address; coupon expires 1 month after printing.",
                "brand": "Sensodyne Complete Protection Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b54b"
            },
            {
                "_id": "6297bcace0e0db6c2572b550",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/sensodyne/overview/",
                "description": "Sensodyne Samples: Dental professionals may request FREE samples of Sensodyne products for their practice.",
                "brand": "Sensodyne Full Protection",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b550"
            },
            {
                "_id": "6297bcace0e0db6c2572b555",
                "link": "https://www.sensodyne.com/en-us/coupon/",
                "description": "Save $1 on any one Sensodyne toothpaste product (excludes travel sizes) with registration of your name and email address; coupon expires 1 month after printing.",
                "brand": "Sensodyne Repair and Protect Deep Repair Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b555"
            },
            {
                "_id": "6297bcace0e0db6c2572b55a",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/sensodyne/overview/",
                "description": "Sensodyne Samples: Dental professionals may request FREE samples of Sensodyne products for their practice.",
                "brand": "Sensodyne Tartar Control",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b55a"
            },
            {
                "_id": "6297bcace0e0db6c2572b55f",
                "link": "https://www.seroquelxr.com/coupon.html",
                "description": "Seroquel XR Savings Card: Eligible commercially insured patients may pay no more than $3 per month with savings of up to $185 per 30-day supply; for additional information contact the program at 888-547-8054.",
                "brand": "Seroquel XR",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "2220",
                "lifetime_uses": "12",
                "max_saving_transaction": 185,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b55f"
            },
            {
                "_id": "6297bcace0e0db6c2572b564",
                "link": "https://almiralladvantage.com/",
                "description": "Seysara Almirall Advantage Savings Card: Eligible commercially insured patients may pay as little as $15 per prescription; for additional information contact the program at 866-665-2782.",
                "brand": "Seysara",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b564"
            },
            {
                "_id": "6297bcace0e0db6c2572b569",
                "link": "https://www.siklosusa.com/savings#siklosathome",
                "description": "Siklos At Home - Mail Order Pharmacy: Eligible commercially insured patients may pay as little as $0 per per prescription, plus receive additional benefits; for additional information contact the program at 844-716-4663.",
                "brand": "Siklos",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b569"
            },
            {
                "_id": "6297bcace0e0db6c2572b56e",
                "link": "https://siliqsavings.com",
                "description": "Siliq Solutions Instant Savings Program: Eligible commercially insured patients with coverage may pay only $25 per month with savings of up to $20,000 per year; for additional information contact the program at 844-357-2013.",
                "brand": "Siliq",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1666.66,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b56e"
            },
            {
                "_id": "6297bcace0e0db6c2572b573",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac for Spit-Up Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b573"
            },
            {
                "_id": "6297bcace0e0db6c2572b578",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac Go and Grow Toddler Drink Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b578"
            },
            {
                "_id": "6297bcace0e0db6c2572b57d",
                "link": "https://www.similac.com/formula-coupons-free-samples.html",
                "description": "Join Similac StrongMoms and receive up to $400 in membership benefits and special gold rewards that includes Free products; for additional information contact the program at 800-850-7677.",
                "brand": "Similac Pro-Total Comfort Infant Formula Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b57d"
            },
            {
                "_id": "6297bcace0e0db6c2572b582",
                "link": "https://www.janssencarepath.com/patient/simponi/cost-support",
                "description": "Simponi Janssen CarePath Savings Program: Most eligible commercially insured patients may pay only $5 per infusion with savings of up to $20,000 per calendar year; for additional information contact the program at 877-227-3728.",
                "brand": "Simponi",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b582"
            },
            {
                "_id": "6297bcace0e0db6c2572b587",
                "link": "https://www.sivextro.com/samples/",
                "description": "Sivextro Samples: Healthcare professionals may order samples for their practice by calling 866-342-5683.",
                "brand": "Sivextro",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b587"
            },
            {
                "_id": "6297bcace0e0db6c2572b58c",
                "link": "https://skytrofa.com",
                "description": "Skytrofa Co-Pay Program; Eligible commercially insured patients pay as little as $5 per monthly prescription with a maximum savings of $6000 per calendar year; for additional information contact the program at 844-442-7236.",
                "brand": "Skytrofa",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "6000",
                "lifetime_uses": "12",
                "max_saving_transaction": 500,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b58c"
            },
            {
                "_id": "6297bcace0e0db6c2572b591",
                "link": "https://slynd.com/resources/",
                "description": "Slynd Savings Program Rebate: If the patient uses a mail-order pharmacy or the retail pharmacy does not accept the savings card eligible patients may request a rebate; for additional information contact the program at 844-865-8685.",
                "brand": "Slynd",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b591"
            },
            {
                "_id": "6297bcace0e0db6c2572b596",
                "link": "https://www.soliqua100-33.com/savings-and-support",
                "description": "Soliqua Savings Card: Eligible commercially insured patients pay as little as $9 for a 30-day supply (up to 2 packs); maximum savings of $365 per pack; savings card is good for 12 months after 1st use then patient may re-apply for another savings card; for additional information contact the program at 855-262-5295.",
                "brand": "Soliqua",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "8760",
                "lifetime_uses": "12",
                "max_saving_transaction": 730,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b596"
            },
            {
                "_id": "6297bcace0e0db6c2572b59b",
                "link": "https://soltamox.com/patient-access/",
                "description": "Soltamox Mayne Pharma Patient Savings Card: Eligible commercially insured patients may pay $0 per prescription; for additional information contact the program at 347-442-7919.",
                "brand": "Soltamox",
                "copay": "",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b59b"
            },
            {
                "_id": "6297bcace0e0db6c2572b5a0",
                "link": "https://www.galdermacc.com/patients",
                "description": "Soolantra Cream CareConnect Savings Card: Eligible uninsured/cash-paying patients may pay no more than $105 for 45g prescription; for additional information contact the program at 855-280-0543.",
                "brand": "Soolantra",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5a0"
            },
            {
                "_id": "6297bcace0e0db6c2572b5a5",
                "link": "https://products.coloplast.us/products/continence-care/#%26currentPage%3D1%26facetStrings%3D",
                "description": "Receive FREE samples of SpeediCath Products for Women; sign up required; for additional information contact the program at 888-726-7872.",
                "brand": "SpeediCath Compact for Women",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5a5"
            },
            {
                "_id": "6297bcace0e0db6c2572b5aa",
                "link": "https://www.scripthero.com/coverage/medication/SPIRIVA%20RESPIMAT",
                "description": "Spiriva Respimat ScriptHero Program: Eligible patients may pay no more than $150 per 30-day prescription; for additional information contact the program at 866-747-4276.",
                "brand": "Spiriva Respimat",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5aa"
            },
            {
                "_id": "6297bcace0e0db6c2572b5af",
                "link": "https://www.spritam.com/#/patient/savings-and-support/savings-program",
                "description": "Spritam Serve Program: Eligible commercially insured patients may pay as little as $0 for the first 30 days and then $10 per refill; maximum savings of $5,000 per calendar year; for additional information contact the program at 844-777-4826.",
                "brand": "Spritam",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "12",
                "max_saving_transaction": 416.67,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5af"
            },
            {
                "_id": "6297bcace0e0db6c2572b5b4",
                "link": "https://www.merckconnect.com/steglatro/coupons-samples/",
                "description": "Steglatro FREE Trial Voucher: Eligible patients may receive a FREE 30-day supply by obtaining a voucher and a valid prescription from their healthcare professional.",
                "brand": "Steglatro",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5b4"
            },
            {
                "_id": "6297bcace0e0db6c2572b5b9",
                "link": "https://www.janssencarepath.com/patient/stelara/cost-support",
                "description": "Stelara Janssen CarePath Savings Program: Most commercially insured patients may pay $5 copay per dose with savings of up to $20,000 per year on out-of-pocket costs; for additional information contact the program at 877-227-3728.",
                "brand": "Stelara",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5b9"
            },
            {
                "_id": "6297bcace0e0db6c2572b5be",
                "link": "https://www.stiolto.com/savings-and-support/respipoints-rewards",
                "description": "Stiolto Respimat RespiPoints: Eligible patients may register with the program to earn points to redeem digital gift cards; for additional information contact the program at 203-957-3781.",
                "brand": "Stiolto Respimat",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5be"
            },
            {
                "_id": "6297bcace0e0db6c2572b5c3",
                "link": "https://www.stopain.com/where-to-buy/",
                "description": "Sign up to receive emails and updates from StopPain.",
                "brand": "Stopain Cold Extra Strength Spray",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5c3"
            },
            {
                "_id": "6297bcace0e0db6c2572b5c8",
                "link": "https://www.insupport.com/suboxone/savings",
                "description": "Suboxone Savings Card: Eligible uninsured/cash-paying patients may save up to or more than $170 or more per month per prescription; for additional information contact the program at 877-678-7493.",
                "brand": "Suboxone",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "2040",
                "lifetime_uses": "12",
                "max_saving_transaction": 170,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5c8"
            },
            {
                "_id": "6297bcace0e0db6c2572b5cd",
                "link": "https://mycareclubrewards.com/Sudafed/?utm_medium=partner&utm_source=sudafed.com&utm_content=header&utm_campaign=cc_main&_gl=1*6y433b*_ga*NTAzNzk2MTQ2LjE2MzUxNzUyNDg.*_ga_13VEM6N66E*MTYzNTg3MjkzNi4zLjEuMTYzNTg3Mjk0OS40Nw..*_fplc*NTNqOUVYeU9heHpBaUFHZWRYaFQ4MHJlZDNaa0pIbHlHUWpaUmRtUHhlc0FEWkk0WWklMkJQV0laRU1wUGhMN2clMkZRQUlDbjRRY0tBaTVaYnVHcjN2VUNoSnc0elpLc2NSNEFDcktjNFRmUiUyRkp0QlE5cnlNWjE2NkpCQWUzWmJRJTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Sudafed products with registration.",
                "brand": "Sudafed Childrens PE Cold and Cough",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5cd"
            },
            {
                "_id": "6297bcace0e0db6c2572b5d2",
                "link": "https://mycareclubrewards.com/Sudafed/?utm_medium=partner&utm_source=sudafed.com&utm_content=header&utm_campaign=cc_main&_gl=1*6y433b*_ga*NTAzNzk2MTQ2LjE2MzUxNzUyNDg.*_ga_13VEM6N66E*MTYzNTg3MjkzNi4zLjEuMTYzNTg3Mjk0OS40Nw..*_fplc*NTNqOUVYeU9heHpBaUFHZWRYaFQ4MHJlZDNaa0pIbHlHUWpaUmRtUHhlc0FEWkk0WWklMkJQV0laRU1wUGhMN2clMkZRQUlDbjRRY0tBaTVaYnVHcjN2VUNoSnc0elpLc2NSNEFDcktjNFRmUiUyRkp0QlE5cnlNWjE2NkpCQWUzWmJRJTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Sudafed products with registration.",
                "brand": "Sudafed PE Head Congestion plus Mucus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5d2"
            },
            {
                "_id": "6297bcace0e0db6c2572b5d7",
                "link": "https://www.medimetriks.com/prescription-brands/sumadan-kit-sumadan-wash",
                "description": "Sumadan Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Sumadan",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5d7"
            },
            {
                "_id": "6297bcace0e0db6c2572b5dc",
                "link": "https://www.sundownnutrition.com/coupons/",
                "description": "Register your email address to receive special offers on Sundown Vitamin & Supplement products.",
                "brand": "Sundown Vitamin and Supplement Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5dc"
            },
            {
                "_id": "6297bcace0e0db6c2572b5e1",
                "link": "https://www.sundownnutrition.com/coupons/",
                "description": "Register your email address to receive special offers on Sundown Vitamin & Supplement products.",
                "brand": "Sundown Vitamin and Supplement Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5e1"
            },
            {
                "_id": "6297bcace0e0db6c2572b5e6",
                "link": "https://www.polident.com/en-us/save-now/poligrip-coupon/",
                "description": "Save $1 on any one Super Poligrip product with registration; 1 coupon per purchase.",
                "brand": "Super Poligrip Comfort Seal Strip",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5e6"
            },
            {
                "_id": "6297bcace0e0db6c2572b5eb",
                "link": "https://www.gskhealthpartner.com/en-us/oral-health/brands/poligrip/overview/?utm_source=google&utm_medium=cpc&utm_term=super%20poligrip&utm_campaign=GS%20-%20Branded_Poligrip%20EX_DP&gclid=Cj0KCQiA962BBhCzARIsAIpWEL0O5zEg5LMkWP-xbEDatBaEd896PMS8BoY0ynLITYi-fwgKKWPSrcUaAiBqEALw_wcB&gclsrc=aw.ds",
                "description": "Super Poligrip Samples: Dental professionals may request samples for their practice by logging into the website.",
                "brand": "Super Poligrip Extra Care",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5eb"
            },
            {
                "_id": "6297bcace0e0db6c2572b5f0",
                "link": "https://www.polident.com/en-us/save-now/poligrip-coupon/",
                "description": "Save $1 on any one Super Poligrip product with registration; 1 coupon per purchase.",
                "brand": "Super Poligrip Original",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5f0"
            },
            {
                "_id": "6297bcace0e0db6c2572b5f5",
                "link": "http://www.suprepkit.com/Savings",
                "description": "Suprep Bowel Prep Kit Voucher: Eligible patients save up to $15 after paying the 1st $70 of their copay or cash price (if uninsured); must fill out online form to receive savings coupon; offer is limited to one use; for additional information contact the program at 833-844-6441.",
                "brand": "Suprep Bowel Prep Kit",
                "copay": "No",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "15",
                "lifetime_uses": "1",
                "max_saving_transaction": 15,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5f5"
            },
            {
                "_id": "6297bcace0e0db6c2572b5fa",
                "link": "https://sutab.com/Savings",
                "description": "Sutab Savings Copay Card: Eligible commercially insured patients/RX not covered may pay as little as $40 per prescription; for additional information contact the program at 844-926-4140.",
                "brand": "Sutab",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5fa"
            },
            {
                "_id": "6297bcace0e0db6c2572b5ff",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Sutent Pfizer Oncology Together Co-Pay Savings Program Rebate (oral products): Eligible commercially insured patients may submit a request for a rebate in connection with this offer if their pharmacy does not accept the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Sutent",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b5ff"
            },
            {
                "_id": "6297bcace0e0db6c2572b604",
                "link": "https://www.symlinhcp.com/access-and-savings-card.html#patient-savings",
                "description": "Symlin MySavingsRx Card: Eligible cash-paying patients may save up to $100 per 30-day supply prescription; for additional information contact the program at 855-292-5968.",
                "brand": "Symlin",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b604"
            },
            {
                "_id": "6297bcace0e0db6c2572b609",
                "link": "https://www.symproic.com/symproic-savings-program",
                "description": "Symproic Prescription Savings Card: Eligible commercially insured patients may pay as little as $0 per prescription; for additional information contact the program at 844-231-2396.",
                "brand": "Symproic",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b609"
            },
            {
                "_id": "6297bcace0e0db6c2572b60e",
                "link": "https://www.medimetriks.com/prescription-brands/synalar-cream-kit-synalar-cream",
                "description": "Synalar (cream) Medimetriks Cares Instant Rebate Card: Most commercially insured and cash-paying patients can save on their prescriptions; for additional information contact the program at 973-882-7512.",
                "brand": "Synalar",
                "copay": "Yes",
                "bin": "610600",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b60e"
            },
            {
                "_id": "6297bcace0e0db6c2572b613",
                "link": "https://syndros.com/co-pay-program/",
                "description": "Syndros Cares Discount Card: Eligible commercially insured patients may pay $0 copay per prescription; for additional assistance contact the program at 877-369-5158.",
                "brand": "Syndros",
                "copay": "Yes",
                "bin": "61066",
                "rxpcn": "AS",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b613"
            },
            {
                "_id": "6297bcace0e0db6c2572b618",
                "link": "https://www.wdrxaccess.com/get-started",
                "description": "Syprine WD Rx Access Program: Eligible commercially insured patients may pay as little as a $5 copay per monthly prescription; for additional information contact the program at 888-607-7267.",
                "brand": "Syprine",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b618"
            },
            {
                "_id": "6297bcace0e0db6c2572b61d",
                "link": "https://www.eyefile.com/theeyesolution/Register",
                "description": "Save $3 on your next purchase of any one Systane Eye Drops Product (10 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Systane Gel Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b61d"
            },
            {
                "_id": "6297bcace0e0db6c2572b622",
                "link": "https://www.eyefile.com/theeyesolution/Register",
                "description": "Save $3 on your next purchase of any one Systane Eye Drops Product (10 mL or larger) with registration to THEeyeSOLUTION; one coupon per purchase.",
                "brand": "Systane Ultra Preservative-Free",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 3,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b622"
            },
            {
                "_id": "6297bcace0e0db6c2572b627",
                "link": "https://savings.talicia.com",
                "description": "Talicia Savings Card: Eligible patients may pay as little as $35 per prescription; for additional information contact the program at 844-825-4242.",
                "brand": "Talicia",
                "copay": "",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b627"
            },
            {
                "_id": "6297bcace0e0db6c2572b62c",
                "link": "https://www.pfizeroncologytogether.com/terms",
                "description": "Talzenna Pfizer Oncology Together Co-Pay Savings Program Rebate (oral products): Eligible commercially insured patients may submit a request for a rebate in connection with this offer if their pharmacy does not accept the offer; for additional information contact the program at 877-744-5675.",
                "brand": "Talzenna",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b62c"
            },
            {
                "_id": "6297bcace0e0db6c2572b631",
                "link": "https://targadox.com/targadox-savings-card-verification/",
                "description": "Targadox Patient Savings Card: Eligible commercially insured patients may pay as little as $7 per prescription; offer may be used 12 times; for additional information contact the program at 888-786-5876.",
                "brand": "Targadox",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b631"
            },
            {
                "_id": "6297bcace0e0db6c2572b636",
                "link": "https://www.taytulla.com/savings-program",
                "description": "Taytulla Savings Card: Eligible commercially insured patients may pay no more than $25 per month for up to 13 fills (28-day supply); for additional information contact the program at 855-439-2817.",
                "brand": "Taytulla",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b636"
            },
            {
                "_id": "6297bcace0e0db6c2572b63b",
                "link": "https://www.tecfidera.com/en_us/home/frequently-asked-questions.html",
                "description": "Tecfidera Biogen Copay Program: Eligible commercially insured patients will pay $0 Copay with no income requirements and no enrollment time limit; for additional information contact the 800-456-2255.",
                "brand": "Tecfidera",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b63b"
            },
            {
                "_id": "6297bcace0e0db6c2572b640",
                "link": "http://www.tenorminus.com/patient-savings-program.html",
                "description": "Tenormin Savings Program: Eligible commercially insured patients may pay as low as $25 per prescription with a maximum savings of $150 per fill of up to 59 tablets; for additional information contact the program at 844-728-3479.",
                "brand": "Tenormin",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b640"
            },
            {
                "_id": "6297bcace0e0db6c2572b645",
                "link": "https://www.theraflu.com/coupon/",
                "description": "Register your email address and receive a $1.00 coupon off of any Theraflu product; limit 1 coupon per purchase; coupon expires 1�month after printing.",
                "brand": "Theraflu Cough Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b645"
            },
            {
                "_id": "6297bcace0e0db6c2572b64a",
                "link": "https://www.theraflu.com/coupon/",
                "description": "Register your email address and receive a $1.00 coupon off of any Theraflu product; limit 1 coupon per purchase; coupon expires 1�month after printing.",
                "brand": "Theraflu ExpressMax Nighttime Severe Cold / Cough",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b64a"
            },
            {
                "_id": "6297bcace0e0db6c2572b64f",
                "link": "https://www.theraflu.com/coupon/",
                "description": "Register your email address and receive a $1.00 coupon off of any Theraflu product; limit 1 coupon per purchase; coupon expires 1�month after printing.",
                "brand": "Theraflu Nighttime Multi-Symptom Severe Cold",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b64f"
            },
            {
                "_id": "6297bcace0e0db6c2572b654",
                "link": "https://www.theratears.com/products/",
                "description": "Save $1 on TheraTears products; trial sizes not included in offer; one coupon per purchase.",
                "brand": "TheraTears Extra Dry Eye Therapy Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b654"
            },
            {
                "_id": "6297bcace0e0db6c2572b659",
                "link": "https://www.thermacare.com",
                "description": "Consumers may contact Thermacare directly to request coupons by calling 800-323-3383.",
                "brand": "ThermaCare Heat Wraps",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b659"
            },
            {
                "_id": "6297bcace0e0db6c2572b65e",
                "link": "https://servierone.com/s/hcp",
                "description": "Tibsovo myAgios Quickstart Program: Eligible NEW patients with commercial and government insurance may receive a free 30-day prescription while waiting for coverage; coverage delay must be at least 5 days or more; program allows for 1 refill (total of 60 days); for additional information contact the program at 844-409-1141.",
                "brand": "Tibsovo",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "2",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b65e"
            },
            {
                "_id": "6297bcace0e0db6c2572b663",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Timoptic Bausch + Lomb Access Program: Eligible cash-paying and commercially insured/RX not covered  patients may pay no more than $60 per prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Timoptic in Ocudose",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b663"
            },
            {
                "_id": "6297bcace0e0db6c2572b668",
                "link": "https://tirosintsol.com/patient-savings-resources/ways-to-save/",
                "description": "Tirosint-Sol Copay Savings Card: Eligible cash-paying patients may pay as little as $25 per prescription with savings of up to $70 per fill; for additional information contact the program at 833-666-2501.",
                "brand": "Tirosint-SOL",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "840",
                "lifetime_uses": "12",
                "max_saving_transaction": 70,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b668"
            },
            {
                "_id": "6297bcace0e0db6c2572b66d",
                "link": "https://myviivcard.com/rebate-form-instructions.html",
                "description": "Tivicay PD ViiVConnect Rebate: Eligible commercially insured patients may be eligible for a rebate for the amount paid out of pocket per prescription if the pharmacy does not accept the savings card; rebate may be completed online or by downloading and mailing in rebate form; for additional information contact the program at 844-588-3288.",
                "brand": "Tivicay PD",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "5000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b66d"
            },
            {
                "_id": "6297bcace0e0db6c2572b672",
                "link": "https://mytobradexst.com/#copay-program",
                "description": "Tobradex ST Copay Savings Program: Eligible commercially insured patients pay $49 per fill; for additional information contact the program  at 866-747-0976.",
                "brand": "TobraDex ST",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b672"
            },
            {
                "_id": "6297bcace0e0db6c2572b677",
                "link": "https://www.toujeo.com/toujeo-savings-card-coupon-and-support",
                "description": "Toujeo Sanofi Savings Card: Eligible commercially insured patients may pay as low as $0 and no more than $99 per 30-day supply; valid up to 10 vials or packs of pens per fill; for additional information contact the program at 866-390-5622.",
                "brand": "Toujeo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b677"
            },
            {
                "_id": "6297bcace0e0db6c2572b67c",
                "link": "https://www.toujeo.com/toujeo-savings-card-coupon-and-support",
                "description": "Toujeo Solostar Valyou Savings Program: Eligible uninsured and cash-paying patients will pay $99 per monthly supply of up to 10 vials or packs of SoloStar pens per fill or up to 5 packs of Max SoloStar pens per fill; for additional information contact the program at 833-813-0190.",
                "brand": "Toujeo Solostar",
                "copay": "No",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b67c"
            },
            {
                "_id": "6297bcace0e0db6c2572b681",
                "link": "https://www.tradjenta.com/savings-and-support",
                "description": "Tradjenta Savings Card: Eligible commercially insured patients may pay as little as $10 per month with savings of up to $150 per prescription; for additional assistance contact the program at 888-879-0466.",
                "brand": "Tradjenta",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "1800",
                "lifetime_uses": "12",
                "max_saving_transaction": 150,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b681"
            },
            {
                "_id": "6297bcace0e0db6c2572b686",
                "link": "https://www.trelegy.com/savings-and-coupons/",
                "description": "Trelegy Ellipta Co-pay Coupon: Eligible uninsured patients/RX not covered may save up to $100 per 30-day prescription valid for up to 12 uses; for additional information contact then program at 888-825-5249.",
                "brand": "Trelegy Ellipta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b686"
            },
            {
                "_id": "6297bcace0e0db6c2572b68b",
                "link": "https://www.novocare.com/tresiba/savings-card.html",
                "description": "Tresiba Instant Savings Card: Eligible commercially insured/RX not covered patients may pay no more than $99 per 30-day supply for up to 24 months  from date of savings card activation; for additional information contact the program at 833-992-3299.",
                "brand": "Tresiba",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b68b"
            },
            {
                "_id": "6297bcace0e0db6c2572b690",
                "link": "https://www.tretten.com/support-and-resources.html",
                "description": "Tretten Novo Nordisk Co-pay Assistance Program: Eligible commercially insured patients may receive up to $12,000 per year in savings; for additional information contact the program at 844-668-6732.",
                "brand": "Tretten",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b690"
            },
            {
                "_id": "6297bcace0e0db6c2572b695",
                "link": "https://www.galdermacc.com/patients",
                "description": "Tri-Luma Cream CareConnect Patient Savings Card: Eligible commercially insured patients may pay no more than $20 per prescription; for additional information contact the program at 855-280-0543.",
                "brand": "Tri-Luma",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b695"
            },
            {
                "_id": "6297bcace0e0db6c2572b69a",
                "link": "https://www.triaminic.com/signup",
                "description": "Register for special offers, latest news and updates for Triaminic products.",
                "brand": "Triaminic Night Time Cold and Cough",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b69a"
            },
            {
                "_id": "6297bcace0e0db6c2572b69f",
                "link": "https://www.patientrebateonline.com/patientrebate/welcome.html",
                "description": "Trintellix Mail-in Rebate: Eligible commercially insured patients may receive a rebate for the cost of their medication; for additional information contact the program at 866-279-0287.",
                "brand": "Trintellix",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "1200",
                "lifetime_uses": "12",
                "max_saving_transaction": 100,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b69f"
            },
            {
                "_id": "6297bcace0e0db6c2572b6a4",
                "link": "https://www.ecksonlabs.com/savings",
                "description": "Tritocin Artesa Labs Advantage Program: Eligible commercially insured patients may pay as little as $0 per prescription when filling their prescription at a participating pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Tritocin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcace0e0db6c2572b6a4"
            },
            {
                "_id": "6297bcade0e0db6c2572b6a9",
                "link": "https://www.trodelvy.com/patient/mTNBC/access-support#trodSavings",
                "description": "Trodelvy Savings Program: Eligible commercially insured patients may pay $0 co-pay and co-insurance with an annual savings of $25,000; for additional information contact the program at 844-876-3358 option 2.",
                "brand": "Trodelvy",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6a9"
            },
            {
                "_id": "6297bcade0e0db6c2572b6ae",
                "link": "https://trudhesa.com/getting-trudhesa/",
                "description": "Trudhesa Direct Savings Program: Eligible commercially insured patients pay as little as $10 per prescription; for additional information contact the program at 833-878-3437.",
                "brand": "Trudhesa",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6ae"
            },
            {
                "_id": "6297bcade0e0db6c2572b6b3",
                "link": "https://www.truxima.com/rheumatoid-arthritis/resources-and-support",
                "description": "Truxima Teva CORE Program: Eligible commercially insured patients may pay as little as $0 per fill with a savings of up to $25,000 annually; for additional information contact the program at 888-587-3263.",
                "brand": "Truxima",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6b3"
            },
            {
                "_id": "6297bcade0e0db6c2572b6b8",
                "link": "https://www.twirla.com/save-on-twirla/",
                "description": "Twirla Savings Card: Eligible patients may pay as little as $0 for the 1st month prescription and then as little as $25 per month for up to 5 months of refills; or additional information contact the program at 866-747-7108.",
                "brand": "Twirla",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6b8"
            },
            {
                "_id": "6297bcade0e0db6c2572b6bd",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol 8 Hr Muscle Aches and Pain Caplets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6bd"
            },
            {
                "_id": "6297bcade0e0db6c2572b6c2",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol ColdMax Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6c2"
            },
            {
                "_id": "6297bcade0e0db6c2572b6c7",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol Liquid Medicine for Children and Adults",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6c7"
            },
            {
                "_id": "6297bcade0e0db6c2572b6cc",
                "link": "https://mycareclubrewards.com/tylenol/?utm_medium=partner&utm_source=tylenol.com&utm_content=header&utm_campaign=cc_main&_gl=1*9xoo0l*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjA0NDI3OC4xLjAuMTYzNjA0NDI3OC42MA..*_fplc*JTJGY3BTVXZSVTdjU3olMkZ4Y1BERUl2RkRzT2lzaEklMkZLYmMxQ25Sa0xlRmE4SUNGbnFOY0psNk83enZSaXUxN3BDb3dwaXJXbEJoMlQ2b1I3cEF0RFEyODklMkY1QTlXNHlrVHcxaDRITnZGOTluRmdFWVU4MW9wRmVlbDhnV2pwQVElM0QlM0Q.",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Tylenol products with registration.",
                "brand": "Tylenol Regular Strength Tablets",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6cc"
            },
            {
                "_id": "6297bcade0e0db6c2572b6d1",
                "link": "https://www.tyrvaya.com",
                "description": "Tyrvay Patient Savings Offer: Eligible commercially insured patients may pay as little as $0 per monthly prescription with a maximum savings of $275 per fill; for additional information contact the program at 844-201-3331.",
                "brand": "Tyrvaya",
                "copay": "Yes",
                "bin": "16151",
                "rxpcn": "ENRX",
                "copay_max": "3300",
                "lifetime_uses": "12",
                "max_saving_transaction": 275,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6d1"
            },
            {
                "_id": "6297bcade0e0db6c2572b6d6",
                "link": "https://www.ubrelvy.com/udemand",
                "description": "Ubrelvy U-Save Card: Eligible commercially insured patients may pay as little as $0 per prescription; offer valid for up to 13 prescription fills; for additional information contact the program at 844-577-6239.",
                "brand": "Ubrelvy",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "13",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6d6"
            },
            {
                "_id": "6297bcade0e0db6c2572b6db",
                "link": "https://ukoniq.com/patient/patientsupport",
                "description": "Ukoniq Quick Start Program: Eligible commercially insured patients who are experiencing a delay in coverage when beginning treatment may receive free medication; insurance delay must be at least 5 business days; for additional information contact the program directly at 877-848-9777.",
                "brand": "Ukoniq",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6db"
            },
            {
                "_id": "6297bcade0e0db6c2572b6e0",
                "link": "https://www.unisom.com/en-us/coupons",
                "description": "Save $4 on any Unisom Simple Slumbers product; one coupon per purchase; expires one month after printing",
                "brand": "Unisom Simple Slumbers",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 4,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6e0"
            },
            {
                "_id": "6297bcade0e0db6c2572b6e5",
                "link": "https://www.unithroid.com/#help-patients-save",
                "description": "Unithroid eVoucherRx Program: Eligible commercially patients may receive an automatic co-pay reduction at participating pharmacies when filling their prescription at that pharmacy; for additional information contact the program at 877-264-2440.",
                "brand": "Unithroid",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6e5"
            },
            {
                "_id": "6297bcade0e0db6c2572b6ea",
                "link": "https://www.alva-amco.com/contact",
                "description": "Consumers may fill out a request form for Uricalm product coupons.",
                "brand": "Uricalm",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6ea"
            },
            {
                "_id": "6297bcade0e0db6c2572b6ef",
                "link": "http://wcpharma.com/patient-savings/",
                "description": "Urogesic Blue Women's Choice Savings Coupon: Eligible uninsured/cash-paying patients may pay no more than $50 per 30-pill prescription; for additional information contact the program at 800-664-1490.",
                "brand": "Urogesic Blue",
                "copay": "Yes",
                "bin": "17290",
                "rxpcn": "55101202",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6ef"
            },
            {
                "_id": "6297bcade0e0db6c2572b6f4",
                "link": "https://www.valchlor.com/valchlor-support-patient-program",
                "description": "Valchlor Copay Program: Eligible commercially insured patients may pay $0 per prescription fill; for additional information contact the program at 855-482-5245.",
                "brand": "Valchlor",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6f4"
            },
            {
                "_id": "6297bcade0e0db6c2572b6f9",
                "link": "https://www.vasculera.com/patients.html",
                "description": "Vasculera Primus Care Direct: Eligible patients may pay no more than $49 per 30-day prescription; for additional information contact the program at 888-546-2735.",
                "brand": "Vasculera",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6f9"
            },
            {
                "_id": "6297bcade0e0db6c2572b6fe",
                "link": "https://www.velphoro.us/#velphoro-savings",
                "description": "Velphoro Savings Card: Most eligible patients pay $0 copay per monthly prescription with a maximum savings of up to $1500 per fill; for additional information contact the program at 877-774-6756.",
                "brand": "Velphoro",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "18000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1500,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b6fe"
            },
            {
                "_id": "6297bcade0e0db6c2572b703",
                "link": "https://almiralladvantage.com/",
                "description": "Verdeso (Foam) Almirall Advantage Savings Card: Eligible commercially insured patients may pay no more than $35 per prescription at a retail pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Verdeso",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b703"
            },
            {
                "_id": "6297bcade0e0db6c2572b708",
                "link": "https://www.verquvo-us.com/coupon/",
                "description": "Verquvo Coupon: Eligible commercially insured patients may pay as little as $10 per prescription; maximum savings of $2500; for more information contact the program directly at 877-264-2454.",
                "brand": "Verquvo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "2500",
                "lifetime_uses": "13",
                "max_saving_transaction": 192.3,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b708"
            },
            {
                "_id": "6297bcade0e0db6c2572b70d",
                "link": "https://www.viagra.com/savings#text-form",
                "description": "Viagra Savings Offer: Eligible patients may save up to 50% on 12 prescriptions per year; maximum savings of $4200 per year; for additional information contact the program at 855-842-4722.",
                "brand": "Viagra",
                "copay": "Yes",
                "bin": "600428",
                "rxpcn": "6780000",
                "copay_max": "4200",
                "lifetime_uses": "12",
                "max_saving_transaction": 350,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b70d"
            },
            {
                "_id": "6297bcade0e0db6c2572b712",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings on VICKS products with registration.",
                "brand": "VICKS Childrens Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b712"
            },
            {
                "_id": "6297bcade0e0db6c2572b717",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings on VICKS products with registration.",
                "brand": "VICKS Products",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b717"
            },
            {
                "_id": "6297bcade0e0db6c2572b71c",
                "link": "https://www.allergansavingscard.com/viibryd",
                "description": "Viibryd Savings Program: Eligible commercially insured patients may pay no more than $15 per 30-day,�60-day or 90-day prescription fill; for additional information contact the program at 877-271-9952.",
                "brand": "Viibryd",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b71c"
            },
            {
                "_id": "6297bcade0e0db6c2572b721",
                "link": "https://myviivcard.com",
                "description": "Viracept ViiVConnect Savings Card: Eligible commercially insured patients pay $0 per prescription with savings of up to $4800 per year with no monthly limit; for additional information contact the program at 844-588-3288.",
                "brand": "Viracept",
                "copay": "Yes",
                "bin": "610626",
                "rxpcn": "1016",
                "copay_max": "4800",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b721"
            },
            {
                "_id": "6297bcade0e0db6c2572b726",
                "link": "https://www.visine.com/coupon-offers",
                "description": "$1 coupon for any one Visine product (0.5 oz or larger); 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Visine Advanced Redness plus Irritation Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b726"
            },
            {
                "_id": "6297bcade0e0db6c2572b72b",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Visine products with registration.",
                "brand": "Visine For Contacts Lubricating plus Rewetting Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b72b"
            },
            {
                "_id": "6297bcade0e0db6c2572b730",
                "link": "https://mycareclubrewards.com",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Visine products with registration.",
                "brand": "Visine Original Redness Relief",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b730"
            },
            {
                "_id": "6297bcade0e0db6c2572b735",
                "link": "https://www.visine.com/coupon-offers",
                "description": "$1 coupon for any one Visine product (0.5 oz or larger); 1 coupon per purchase; coupon expires 1 month after printing.",
                "brand": "Visine Red Eye Total Comfort Multi-Symptom Eye Drops",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b735"
            },
            {
                "_id": "6297bcade0e0db6c2572b73a",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Instant Savings Card: Eligible cash-paying patients may pay approximately $65 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Vitafol",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b73a"
            },
            {
                "_id": "6297bcade0e0db6c2572b73f",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol Fe plus Mail-In Rebate: If your pharmacy does not accept the Savings Card or you use a mail-order pharmacy you may submit your original receipt, a photocopy of your savings card (front and back) along with your date of birth to the program to receive your proper savings; for additional information contact the program directly at 855-881-3090.",
                "brand": "Vitafol Fe plus",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b73f"
            },
            {
                "_id": "6297bcade0e0db6c2572b744",
                "link": "https://vitafol.com/sample-kit/",
                "description": "Vitafol Gummies-FirstStep Free Offer: You may receive a FREE sample offer by completing the sign-up information found on the website.",
                "brand": "Vitafol Gummies",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b744"
            },
            {
                "_id": "6297bcade0e0db6c2572b749",
                "link": "https://vitafol.com/one/",
                "description": "Vitafol One Samples: Your healthcare provider can request samples by completing a request form and faxing the form to 614-652-8275 or via email to ExeltisSamples@cardinalhealth.com.",
                "brand": "Vitafol One",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b749"
            },
            {
                "_id": "6297bcade0e0db6c2572b74e",
                "link": "https://vitafol.com/ultra/",
                "description": "Vitafol Ultra Samples: Your healthcare provider can request samples by completing a request form and faxing the form to 614-652-8275 or via email to ExeltisSamples@cardinalhealth.com.",
                "brand": "Vitafol Ultra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b74e"
            },
            {
                "_id": "6297bcade0e0db6c2572b753",
                "link": "https://vitafol.com/?gclid=EAIaIQobChMIwbegoK7j6QIVFLbICh1F4ge7EAAYASAAEgITJ_D_BwE",
                "description": "Vitafol-OB DHA Instant Savings Card: Eligible cash-paying patients may pay approximately $65 per prescription; for additional information contact the program at 855-881-3090.",
                "brand": "Vitafol-OB DHA",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b753"
            },
            {
                "_id": "6297bcade0e0db6c2572b758",
                "link": "https://www.gummyvites.com/en/Coupons2",
                "description": "Save $2 off any Vitafusion product when you signup online with your email address.",
                "brand": "Vitafusion Gummy Prenatal Vitamins",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 2,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b758"
            },
            {
                "_id": "6297bcade0e0db6c2572b75d",
                "link": "https://savings.vitamedmdrx.com/activate/index.html",
                "description": "vitaPearl vitaMedMD Savings Program:Eligible commercially insured patients may pay $25 or less per 30-day prescription; for additional information contact the program at 855-993-2665.",
                "brand": "vitaPearl",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b75d"
            },
            {
                "_id": "6297bcade0e0db6c2572b762",
                "link": "https://www.pfizeroncologytogether.com/patient/signup",
                "description": "Vizimpro Pfizer Oncology Together Co-Pay Savings Program (oral products): Eligible commercially insured patients may pay as little as $0 per prescription with savings of up to $25,000 per calendar year; for additional information contact the program at 877-744-5675.",
                "brand": "Vizimpro",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b762"
            },
            {
                "_id": "6297bcade0e0db6c2572b767",
                "link": "https://www.voltarengel.com/samples-and-coupons/",
                "description": "Save $1 off any one Voltaren product (1.8 oz) with sign up; 1 coupon per purchase.",
                "brand": "Voltaren Arthritis Pain",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 1,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b767"
            },
            {
                "_id": "6297bcade0e0db6c2572b76c",
                "link": "https://www.biomarin-rareconnections.com/voxzogo/enroll/",
                "description": "Voxzogo Co-Pay Assistance Program: Eligible commercially insured patients may pay as little as $0 per prescription up to $17,000 per calendar year; for additional information contact the program at 833-869-9646.",
                "brand": "Voxzogo",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "17000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b76c"
            },
            {
                "_id": "6297bcade0e0db6c2572b771",
                "link": "https://www.vumerity.com/en_us/home/biogen-support-services/financial-support.html?cid=PPC-GOOGLE-Vumerity_DTC_Unbranded_Pill/Oral+Treatment_Exact~S~PH~BR~NER~DTC~TRE-oral+medicine+for+ms-NA-p56723594868&gclid=EAIaIQobChMI1t3DhY_28wIVgb2GCh12DwhiEAAYASACEgIW2_D_BwE&gclsrc=aw.ds",
                "description": "Vumerity Biogen Copay Program: Eligible commercially insured patients may pay as little as $0 per prescription; for additional information contact the program at 800-456-2255.",
                "brand": "Vumerity",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b771"
            },
            {
                "_id": "6297bcade0e0db6c2572b776",
                "link": "https://www.vyndalink.com/patient",
                "description": "Vyndaqel Co-Pay Savings Program: Eligible commercially insured patients may pay as little as $0 per monthly prescription with savings of up to $60,000 per calendar year; for additional information contact the program at 888-222-8475.",
                "brand": "Vyndaqel",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "60000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b776"
            },
            {
                "_id": "6297bcade0e0db6c2572b77b",
                "link": "https://www.bauschaccessprogram.com/",
                "description": "Vyzulta Bausch + Lomb Access Program: Eligible cash-paying or commercially insured/RX not covered patients may pay no more than $60 per 2.5 mL bottle/$90 per 5 mL bottle prescription; for additional information contact the program at 866-693-4880.",
                "brand": "Vyzulta",
                "copay": "Yes",
                "bin": "19158",
                "rxpcn": "CNRX",
                "copay_max": "0",
                "lifetime_uses": "6",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b77b"
            },
            {
                "_id": "6297bcade0e0db6c2572b780",
                "link": "https://www.wellbutrinxl.com/prescribers#request-free-samples",
                "description": "Wellbutrin XL Samples: Healthcare providers may request samples for their office by submitting a request online.",
                "brand": "Wellbutrin XL",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b780"
            },
            {
                "_id": "6297bcade0e0db6c2572b785",
                "link": "https://www.activatethecard.com/viatrisadvocate/wixela/welcome.html",
                "description": "Wixela Inhub Savings Card Program: Eligible commercially insured patients may pay as little as $10 per 30-day prescription with savings of up to $600 per calendar year; for additional information contact the program at 800-395-3376.",
                "brand": "Wixela Inhub",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "600",
                "lifetime_uses": "12",
                "max_saving_transaction": 50,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b785"
            },
            {
                "_id": "6297bcade0e0db6c2572b78a",
                "link": "https://www.pfizerpro.com/request-samples",
                "description": "Xalkori Samples: Your healthcare provider may request samples by contacting PfizerPro online or by calling 800-505-4426.",
                "brand": "Xalkori",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b78a"
            },
            {
                "_id": "6297bcade0e0db6c2572b78f",
                "link": "https://www.xanax.com/savings",
                "description": "Xanax Savings Card: Eligible patients may pay no more than $4 per monthly prescription with savings of up to $1500 per year; for additional information contact the program at 855-854-4535.",
                "brand": "Xanax",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "1500",
                "lifetime_uses": "12",
                "max_saving_transaction": 125,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b78f"
            },
            {
                "_id": "6297bcade0e0db6c2572b794",
                "link": "https://www.xeljanz.com/savings-and-support?ind=ra",
                "description": "Xeljanz FREE Trial Offer: New patients may receive a one-time 30-day supply; for additional information contact the program at 844-935-5269.",
                "brand": "Xeljanz",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b794"
            },
            {
                "_id": "6297bcade0e0db6c2572b799",
                "link": "https://www.xeljanz.com/savings-and-support?ind=ra",
                "description": "Xeljanz XR FREE Trial Offer: New patients may receive a one-time 30-day supply; for additional information contact the program at 844-935-5269.",
                "brand": "Xeljanz XR",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b799"
            },
            {
                "_id": "6297bcade0e0db6c2572b79e",
                "link": "https://www.xenleta.com/access-support",
                "description": "Xenleta Copay Assistance: Eligible commercially insured patients may pay no more than $50 per prescription through designated specialty pharmacies; doctors must enroll their patients in this program; for additional information contact the program at the 855-562-2748.",
                "brand": "Xenleta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b79e"
            },
            {
                "_id": "6297bcade0e0db6c2572b7a3",
                "link": "https://copay.xermelo.com",
                "description": "Xermelo Copay Program: Eligible commercially insured patients may pay $0 copay per prescription with saving of up to $10,000 per year; for additional information contact the program at 800-761-5293.",
                "brand": "Xermelo",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7a3"
            },
            {
                "_id": "6297bcade0e0db6c2572b7a8",
                "link": "https://www.xhance.com/terms-and-conditions/",
                "description": "Xhance Preferred Pharmacy Network: Eligible commercially insured patients/RX not covered may pay $0 for their initial fill (up to 2 units) with a prior authorization or pay $25 without a prior authorization; refills will then cost $50 per unit (up to 2 units); for additional information contact the program at 833-494-2623.",
                "brand": "Xhance",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7a8"
            },
            {
                "_id": "6297bcade0e0db6c2572b7ad",
                "link": "https://www.azandmeapp.com/prescriptionsavings/?screenName=4waystoapply",
                "description": "Xigduo XR SavingsRx Card: Eligible commercially insured patients may pay $0 per 30-day supply; maximum savings limit of $175 per 30-day supply; for additional information contact the program 844-631-3978.",
                "brand": "Xigduo XR",
                "copay": "Yes",
                "bin": "4682",
                "rxpcn": "CN",
                "copay_max": "2100.00",
                "lifetime_uses": "12",
                "max_saving_transaction": 175,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7ad"
            },
            {
                "_id": "6297bcade0e0db6c2572b7b2",
                "link": "https://www.xiidra.com/getting-started-on-xiidra",
                "description": "Xiidra Insider Card Reimbursement Form: Eligible commercially insured patients may receive a rebate check in the mail if their pharmacy cannot process the Savings Card; for additional information contact the program at 844-247-4755.",
                "brand": "Xiidra",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "3000",
                "lifetime_uses": "12",
                "max_saving_transaction": 250,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7b2"
            },
            {
                "_id": "6297bcade0e0db6c2572b7b7",
                "link": "https://xolaircopay.com/",
                "description": "Xolair (Drug) Co-Pay Card Program: Eligible commercially insured patients may pay as little as $5 per drug prescription with savings of up to $10,000 per 12-month period; for further assistance contact the program at 855-965-2472.",
                "brand": "Xolair",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "12",
                "max_saving_transaction": 834,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7b7"
            },
            {
                "_id": "6297bcade0e0db6c2572b7bc",
                "link": "https://almiralladvantage.com/",
                "description": "Xolegel Almirall Advantage Savings Card: Eligible commercially insured patients/RX not covered may pay no more than $50 per prescription when using a network pharmacy; for additional information contact the program at 888-591-9860.",
                "brand": "Xolegel",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7bc"
            },
            {
                "_id": "6297bcade0e0db6c2572b7c1",
                "link": "https://www.xulane.com/en/get-xulane",
                "description": "Xulane Savings Card: Eligible commercially insured patients save $100 for their 1st 28-day prescription and then pay $15 for each subsequent fill; offer can be used 14 times per calendar year  with an maximum savings of $425 per calendar year; for additional information contact the program at 800-657-7613.",
                "brand": "Xulane",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "0",
                "copay_max": "425",
                "lifetime_uses": "14",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7c1"
            },
            {
                "_id": "6297bcade0e0db6c2572b7c6",
                "link": "https://www.xyosted.com/index.php/steadycare-support-program",
                "description": "Xyosted STEADYCare  Injection Support Program: Eligible commercially insured patients may qualify for 1 FREE 28-day supply of medication while their insurance coverage is being determined; for additional information contact the program at 844-996-7833.",
                "brand": "Xyosted",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "1",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7c6"
            },
            {
                "_id": "6297bcade0e0db6c2572b7cb",
                "link": "https://jazzcares.com/patients/xywav/",
                "description": "Xywav Bridge Program: Eligible commercially insured patients who are experiencing a continued or new delay in coverage may receive up to 4 months of medication free; for additional information contact the program at 833-533-5299.",
                "brand": "Xywav",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "4",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7cb"
            },
            {
                "_id": "6297bcade0e0db6c2572b7d0",
                "link": "https://www.janssencarepath.com/patient/yondelis/cost-support",
                "description": "Yondelis Janssen CarePath Savings Program: Most commercially insured patients may pay $5 copay for each infusion for your medication costs with savings of up to $20,000 per year on out-of-pocket costs; for additional information contact the program at 844-966-3354.",
                "brand": "Yondelis",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "20000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7d0"
            },
            {
                "_id": "6297bcade0e0db6c2572b7d5",
                "link": "https://www.zegalogue.com/savings-and-support/",
                "description": "Zegalogue Co-Pay Savings Card: Eligible commercially insured patient may pay as little as $25 for up to 2 devices per fill; 12 fills per calendar year; for additional information contact the program at 877-501-9342.",
                "brand": "Zegalogue",
                "copay": "Yes",
                "bin": "600426",
                "rxpcn": "54",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7d5"
            },
            {
                "_id": "6297bcade0e0db6c2572b7da",
                "link": "https://www.togetherwithgskoncology.com/patient-information/zejula/",
                "description": "Zejula Bridge Program: Eligible commercially insured patients may be provided with a 15-day supply of medication if experiencing a delay in insurance while the patient is currently taking their medication; up to 4 refills may be provided under the program; for additional information contact your healthcare provider or the program at 844-447-5662.",
                "brand": "Zejula",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7da"
            },
            {
                "_id": "6297bcade0e0db6c2572b7df",
                "link": "https://www.zembrace.com/savings-and-support/#savings-form",
                "description": "Zembrace Blink Pharmacy Plus: Eligible commercially insured patients may pay as little as $0 per monthly prescription with FREE home delivery; for additional information contact the program at 844-976-3109.",
                "brand": "Zembrace SymTouch",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7df"
            },
            {
                "_id": "6297bcade0e0db6c2572b7e4",
                "link": "https://www.zeposia.com/multiple-sclerosis/support-program-for-patients/",
                "description": "Zeposia Bridge Program: Eligible commercially insured patients experiencing a delay/issue with coverage may be able to receive free medication until the delay/issue is resolved for up to 24 months if needed; for additional information contact the program at 833-937-6742.",
                "brand": "Zeposia",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7e4"
            },
            {
                "_id": "6297bcade0e0db6c2572b7e9",
                "link": "https://acrotechpatientaccess.com",
                "description": "Zevalin STAR Copay Assistance Program: Eligible commercially insured patients will pay $0 copay for the 1st date of service and a $25 copay for  subsequent dates of service; maximum savings of $10,000 per calendar year; for additional information contact the program at 888-537-8277.",
                "brand": "Zevalin",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "10000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7e9"
            },
            {
                "_id": "6297bcade0e0db6c2572b7ee",
                "link": "https://zilxi.com/starting-zilxi#savings-card",
                "description": "Zilxi Savings Card: Eligible commercially insured patients may pay as little as $35 per prescription; for additional information contact the program at 347-334-6785.",
                "brand": "Zilxi",
                "copay": "Yes",
                "bin": "637765",
                "rxpcn": "CRX",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7ee"
            },
            {
                "_id": "6297bcade0e0db6c2572b7f3",
                "link": "https://www.zipsor.com/hcp/get-in-touch/",
                "description": "Zipsor Samples: Healthcare providers may fill out an online form to request samples.",
                "brand": "Zipsor",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7f3"
            },
            {
                "_id": "6297bcade0e0db6c2572b7f8",
                "link": "https://www.activatethecard.com/7526/?#",
                "description": "Zoladex Co-pay Card: Eligible commercially insured patients may pay as little as $0 per monthly prescription with savings of up to $2,000 per calendar year; for additional information contact the program at 844-864-3014.",
                "brand": "Zoladex",
                "copay": "Yes",
                "bin": "61054",
                "rxpcn": "Loyalty",
                "copay_max": "2000",
                "lifetime_uses": "12",
                "max_saving_transaction": 300,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7f8"
            },
            {
                "_id": "6297bcade0e0db6c2572b7fd",
                "link": "https://myzolpimist.com/#rxconnect-section",
                "description": "ZolpiMist Savings Offer: Eligible commercially insured patients/RX not covered pay $50 prescription; for additional information contact the program at 888-298-8792.",
                "brand": "ZolpiMist",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b7fd"
            },
            {
                "_id": "6297bcade0e0db6c2572b802",
                "link": "https://www.zomigsavings.com/",
                "description": "Zomig (Tablets) Savings Card: Eligible patients may pay as little as $25 per prescription; for additional information contact the program at 866-293-5817.",
                "brand": "Zomig",
                "copay": "Yes",
                "bin": "601341",
                "rxpcn": "OHCP",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b802"
            },
            {
                "_id": "6297bcade0e0db6c2572b807",
                "link": "https://zontivity.com",
                "description": "Zontivity Co-pay Card Rebate: Eligible commercially insured patients may recevie a rebate for their out-of-pocket cost if their pharmacy was unable to process the Co-pay Card; rebate instructions found on the co-pay card; for additional information contact the program at 844-847-7738.",
                "brand": "Zontivity",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b807"
            },
            {
                "_id": "6297bcade0e0db6c2572b80c",
                "link": "https://www.zubsolv.com/resources/patient-savings/",
                "description": "Zubsolv Savings Program: Eligible patients may save up to $225 (commercially insured patients pay the 1st $10) with no limit on number of uses or tablets for monthly prescriptions; for additional information contact the program at 877-264-2440.",
                "brand": "Zubsolv",
                "copay": "Yes",
                "bin": "610524",
                "rxpcn": "Loyalty",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 225,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b80c"
            },
            {
                "_id": "6297bcade0e0db6c2572b811",
                "link": "https://qv.trialcard.com/zydelig/#/app/layout/home",
                "description": "Zydelig Copay Coupon Card: Eligible commercially insured patients pay as little as $5 per 30-day supply; for additional information contact the program at 844-622-2377.",
                "brand": "Zydelig",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "ZYDELIG",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b811"
            },
            {
                "_id": "6297bcade0e0db6c2572b816",
                "link": "https://www.zynlonta.com/support",
                "description": "Zynlonta Advancing Patient Support Copay Assistance Program: Eligible commercially insured patients may pay $0 per dose up to an annual maximum benefit of $25,000; for additional information contact the program at 855-690-0340.",
                "brand": "Zynlonta",
                "copay": "Yes",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "25000",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b816"
            },
            {
                "_id": "6297bcade0e0db6c2572b81b",
                "link": "https://www.zyrtecprofessional.com/order",
                "description": "Zyrtec Samples: Healthcare providers may order samples for their practice by visiting the website.",
                "brand": "Zyrtec",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b81b"
            },
            {
                "_id": "6297bcade0e0db6c2572b820",
                "link": "https://www.zyrtec.com/?action=sign-up",
                "description": "Receive a coupon for any one Zyrtec product and other exclusive offers  with sign up; 1 coupon per purchase.",
                "brand": "Zyrtec Childrens Dissolve Tabs",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b820"
            },
            {
                "_id": "6297bcade0e0db6c2572b825",
                "link": "https://mycareclubrewards.com/zyrtec/?utm_medium=partner&utm_source=zyrtec.com&utm_content=footer&utm_campaign=cc_main&_gl=1*i86ng6*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjQ3ODA2OS4zLjEuMTYzNjQ3ODE3Ny4xNQ..*_fplc*aTZTRSUyRktpOGJCeFFqdzlkb0ZtTmwlMkI1VXE4VkhJSERDdmI1Q1d6TklLMW1nOFVyaUJOQUdscnpCJTJGd1ZBRXFSSUlOaDVLSW5sMVoxSGtXOENrTFV2bUVGUk11YjRFS3JqZGNDN0VWR3JNMGFpMW9GNjJGZU9VWkdER1ZTa2x3JTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Zyrtec products with registration.",
                "brand": "Zyrtec Dissolve Tabs",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b825"
            },
            {
                "_id": "6297bcade0e0db6c2572b82a",
                "link": "https://mycareclubrewards.com/zyrtec/?utm_medium=partner&utm_source=zyrtec.com&utm_content=footer&utm_campaign=cc_main&_gl=1*i86ng6*_ga*NzA1NDQ5NDY2LjE2MzYwNDQyNzk.*_ga_13VEM6N66E*MTYzNjQ3ODA2OS4zLjEuMTYzNjQ3ODE3Ny4xNQ..*_fplc*aTZTRSUyRktpOGJCeFFqdzlkb0ZtTmwlMkI1VXE4VkhJSERDdmI1Q1d6TklLMW1nOFVyaUJOQUdscnpCJTJGd1ZBRXFSSUlOaDVLSW5sMVoxSGtXOENrTFV2bUVGUk11YjRFS3JqZGNDN0VWR3JNMGFpMW9GNjJGZU9VWkdER1ZTa2x3JTNEJTNE",
                "description": "Join Care Club and Save: Receive special offers, promotions and valuable information on Zyrtec products with registration.",
                "brand": "Zyrtec Soothing Non-Medicated Face Wipes",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b82a"
            },
            {
                "_id": "6297bcade0e0db6c2572b82f",
                "link": "https://www.janssencarepath.com/patient/zytiga/cost-support",
                "description": "Zytiga Janssen CarePath Savings Card: Most eligible commercially insured patients may pay no more than $10 per month with a maximum savings of up to $12,000 per year; for additional information contact the program at 855-998-4421.",
                "brand": "Zytiga",
                "copay": "Yes",
                "bin": "610020",
                "rxpcn": "0",
                "copay_max": "12000",
                "lifetime_uses": "12",
                "max_saving_transaction": 1000,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b82f"
            },
            {
                "_id": "6297bcade0e0db6c2572b834",
                "link": "https://www.pggoodeveryday.com/rewards/",
                "description": "Join P&G Everyday to receive coupons, samples and savings for ZzzQuil Products!",
                "brand": "ZzzQuil Night Pain Liquid",
                "copay": "No",
                "bin": "0",
                "rxpcn": "0",
                "copay_max": "0",
                "lifetime_uses": "0",
                "max_saving_transaction": 0,
                "__v": 0,
                "id": "6297bcade0e0db6c2572b834"
            },
            {
                "_id": "6317dab2499f595d80253462",
                "brand": "Qutenza 8% Topical Patch",
                "copay": "Yes",
                "copay_max": "25.00",
                "lifetime_uses": "12",
                "max_saving_transaction": null,
                "bin": "",
                "rxpcn": "",
                "description": "Qutenza Savings Card: Eligible commercially insured patients may pay $25 per box ",
                "link": "https://www.qutenza.com/cost-savings/",
                "__v": 0,
                "id": "6317dab2499f595d80253462"
            },
            {
                "_id": "637a7bcafc50ea1ca53b3ca0",
                "brand": "Mounjaro Injection",
                "copay": "You may pay as little as $25 for a 1-month or 3-month prescription of Mounjaro",
                "copay_max": "500",
                "lifetime_uses": "12",
                "max_saving_transaction": 3000,
                "bin": "3F",
                "rxpcn": "018844",
                "description": "Commercial Coverage: Offer good until 12/31/2023 for up to 24 fills or whichever comes first. No covered: Offer good until 06/30/2023 for up to 12 fills or whichever comes first. savings of up to $500 off your monthly fill for 4 pens of Mounjaro. Offer subject to a monthly cap of up to $500 and a separate annual cap of up to $3,000. ",
                "link": "https://www.mounjaro.com/savings-resources#savings",
                "__v": 0,
                "id": "637a7bcafc50ea1ca53b3ca0"
            },
            {
                "_id": "63938ed91aa44fabe3b8c11b",
                "brand": "True Metrix BGM",
                "copay": "0",
                "copay_max": "0",
                "lifetime_uses": "12 months",
                "max_saving_transaction": 0,
                "bin": "015251",
                "rxpcn": "PRX2000",
                "description": "Free True Metrix or True Metrix Air ",
                "link": "https://www.caloptima.org/~/media/Files/CalOptimaOrg/508/Providers/Pharmacy/Medi-Cal/2021-06_TrueMetrixProcessingInfo_508.ashx",
                "__v": 0,
                "id": "63938ed91aa44fabe3b8c11b"
            },
            {
                "_id": "639a7a8b531da11cf47d7185",
                "brand": "Vtama 1% Cream",
                "copay": "0",
                "copay_max": "75",
                "lifetime_uses": "12",
                "max_saving_transaction": null,
                "bin": "610020",
                "rxpcn": "PDMI",
                "description": "Eligible commercially insured patients may pay as little as $0.* Commercially insured patients may pay as little as $75.",
                "link": "https://www.vtama.com/savings-card/",
                "__v": 0,
                "id": "639a7a8b531da11cf47d7185"
            },
            {
                "_id": "63a8a412b9502cbc11c0878e",
                "brand": "Spevigo Injection",
                "copay": "0",
                "copay_max": "7,500",
                "lifetime_uses": "12",
                "max_saving_transaction": null,
                "bin": "601341",
                "rxpcn": "OHCP",
                "description": "Financial Copay Reduction is Available",
                "link": "https://www.opushealth.com/secure/Login.aspx?ReturnUrl=%2fpharmacyPortal%2findex.aspx",
                "__v": 0,
                "id": "63a8a412b9502cbc11c0878e"
            },
            {
                "_id": "63cd424d09acb707f7e27bbe",
                "brand": "CIBINQO (abrocitinib)",
                "copay": "$0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 7850,
                "bin": "601341",
                "rxpcn": "OHCP",
                "description": "This is not health insurance. For CIBINQO, the maximum benefit per patient is $15,000 per calendar year. Only for use with commercial insurance. If you are enrolled in a state or federally funded prescription insurance program, you may not use the copay card.",
                "link": "https://www.cibinqo.com/savings-and-support-sign-up",
                "__v": 0,
                "id": "63cd424d09acb707f7e27bbe"
            },
            {
                "_id": "63cd42cf09acb707f7e27bc0",
                "brand": "Apretude 200mg/ml",
                "copay": "$0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 15000,
                "bin": "600429",
                "rxpcn": "",
                "description": "The APRETUDE Savings Program helps eligible, enrolled consumers with their out-of-pocket costs for APRETUDE for up to $7,850 every calendar year.",
                "link": "https://apretude.com/",
                "__v": 0,
                "id": "63cd42cf09acb707f7e27bc0"
            },
            {
                "_id": "63d18fc4526fd82d2eb26402",
                "brand": "Test 1mg",
                "copay": "0",
                "copay_max": "Varies",
                "lifetime_uses": "12",
                "max_saving_transaction": 1400,
                "bin": "601341",
                "rxpcn": "OHCP",
                "description": "this test",
                "link": "google.com",
                "__v": 0,
                "id": "63d18fc4526fd82d2eb26402"
            },
            {
                "_id": "63d543539d5b1c09839bb110",
                "brand": "Buprenorphine 2mg Tab",
                "copay": "Varies",
                "copay_max": "200.00",
                "lifetime_uses": "Unlimited",
                "max_saving_transaction": 200,
                "bin": "610711",
                "rxpcn": "SUN",
                "description": "Reduces cash price for patient",
                "link": "https://therxcard.net/hwjjuu/en/home",
                "__v": 0,
                "id": "63d543539d5b1c09839bb110"
            },
            {
                "_id": "63d5439e9d5b1c09839bb111",
                "brand": "Buprenorphine 8mg Tab",
                "copay": "Varies",
                "copay_max": "200",
                "lifetime_uses": "Unlimited",
                "max_saving_transaction": 200,
                "bin": "610711",
                "rxpcn": "SUN",
                "description": "Reduces cash price for patient",
                "link": "https://therxcard.net/hwjjuu/en/home",
                "__v": 0,
                "id": "63d5439e9d5b1c09839bb111"
            },
            {
                "_id": "63d5447d9d5b1c09839bb112",
                "brand": "Buprenorphine/Naloxone 2mg/0.5mg",
                "copay": "Varies",
                "copay_max": "200",
                "lifetime_uses": "Unlimited",
                "max_saving_transaction": 200,
                "bin": "610711",
                "rxpcn": "SUN",
                "description": "Reduces cash price for patient",
                "link": "https://therxcard.net/hwjjuu/en/home",
                "__v": 0,
                "id": "63d5447d9d5b1c09839bb112"
            },
            {
                "_id": "63d544b59d5b1c09839bb113",
                "brand": "Buprenorphine/Naloxone 8mg/2mg ",
                "copay": "Varies",
                "copay_max": "NaN",
                "lifetime_uses": "Unlimited",
                "max_saving_transaction": 200,
                "bin": "610711",
                "rxpcn": "SUN",
                "description": "Reduces cash price for patient",
                "link": "https://therxcard.net/hwjjuu/en/home",
                "__v": 0,
                "id": "63d544b59d5b1c09839bb113"
            },
            {
                "_id": "63f961bbdef71fe2e88c0a67",
                "brand": "Sotyku 6mg Tabs",
                "copay": "0",
                "copay_max": "Varies",
                "lifetime_uses": "36",
                "max_saving_transaction": 6400,
                "bin": "610020",
                "rxpcn": "",
                "description": "Eligible patients with an activated co-pay card and a valid prescription may pay as little as $0 per 30-day supply; monthly and annual maximum program benefits apply and may vary from patient to patient. FREE 30 DAY TRIAL",
                "link": "https://www.sotyktu.com/savings-and-resources/sotyktu-360-support/co-pay-sign-up",
                "__v": 0,
                "id": "63f961bbdef71fe2e88c0a67"
            },
            {
                "_id": "641c83628bd51781ce84340d",
                "brand": "Accu-Chek Guide Me Strips",
                "copay": "19.99",
                "copay_max": "19.99",
                "lifetime_uses": "12",
                "max_saving_transaction": null,
                "bin": "019158",
                "rxpcn": "07590000",
                "description": "Pay no more than $19.99 for 50",
                "link": "https://www.accu-chek.com/microsites/prescription-discount-program",
                "__v": 0,
                "id": "641c83628bd51781ce84340d"
            },
            {
                "_id": "64397d180f97c010ddfcfb66",
                "brand": "Victoza 1.2mg|1.8 Injection",
                "copay": "",
                "copay_max": "99",
                "lifetime_uses": "12",
                "max_saving_transaction": null,
                "bin": "600426",
                "rxpcn": "54",
                "description": "$99  Rx Novocare Program",
                "link": "https://www.novocare.com/diabetes/products/victoza.html",
                "__v": 0,
                "id": "64397d180f97c010ddfcfb66"
            },
            {
                "_id": "6447c5f1f60a1092ef2f8f45",
                "brand": "Embrace Talk BGM Kit",
                "copay": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "bin": "Call 877-979-5454 to get free meters",
                "rxpcn": "Not Required",
                "description": "Embrace Talk Monitor BGM",
                "link": "embracetalk.com",
                "__v": 0,
                "id": "6447c5f1f60a1092ef2f8f45"
            },
            {
                "_id": "6447c63ef60a1092ef2f8f46",
                "brand": "Embrace Talk BGM Strips",
                "copay": "5.00",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "bin": "Call 877-979-5454 to get free meters",
                "rxpcn": "Not Required",
                "description": "Embrace Talk Strips 50's",
                "link": "embracetalk.com",
                "__v": 0,
                "id": "6447c63ef60a1092ef2f8f46"
            },
            {
                "_id": "6447c68af60a1092ef2f8f47",
                "brand": "Embrace PRO Talk BGM Kit",
                "copay": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "bin": "Call 877-979-5454 to get free meters",
                "rxpcn": "Not Required",
                "description": "Embrace PRO Talk Monitor BGM",
                "link": "embracetalk.com",
                "__v": 0,
                "id": "6447c68af60a1092ef2f8f47"
            },
            {
                "_id": "6447c6bcf60a1092ef2f8f48",
                "brand": "Embrace PRO BGM Strips",
                "copay": "5.00",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 0,
                "bin": "Call 877-979-5454 to get free meters",
                "rxpcn": "Not Required",
                "description": "Embrace PRO Strips 50's",
                "link": "embracetalk.com",
                "__v": 0,
                "id": "6447c6bcf60a1092ef2f8f48"
            },
            {
                "_id": "64495602f60a1092ef2f8fb1",
                "brand": "Xanax",
                "copay": "4",
                "copay_max": "125",
                "lifetime_uses": "12",
                "max_saving_transaction": 125,
                "bin": "004682",
                "rxpcn": "CN",
                "description": "Eligible patients receive up to $125 off the copay for a 30 day supply",
                "link": "https://www.xanax.com/savings",
                "__v": 0,
                "id": "64495602f60a1092ef2f8fb1"
            },
            {
                "_id": "644956a0f60a1092ef2f8fb2",
                "brand": "Zimhi 5mg/0.5ml Inj (Naloxone)",
                "copay": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 1500,
                "bin": "",
                "rxpcn": "",
                "description": "Eligible patients may pay $0 for Zimhi",
                "link": "https://zimhi.com/pharmacists-patient-savings/",
                "__v": 0,
                "id": "644956a0f60a1092ef2f8fb2"
            },
            {
                "_id": "64495837f60a1092ef2f8fb3",
                "brand": "ZImhi 5mg/0.5ml Inj",
                "copay": "60",
                "copay_max": "200",
                "lifetime_uses": "12",
                "max_saving_transaction": 1500,
                "bin": "610524",
                "rxpcn": "Loyalty",
                "description": "Use ID # 1396065983  Group# 50778063  -  Receive the medication at a reduced cost to patient",
                "link": "https://www.kphanet.org/copay",
                "__v": 0,
                "id": "64495837f60a1092ef2f8fb3"
            },
            {
                "_id": "644ef7765a9606923a41a087",
                "brand": "Ambrisentan 5mg Tab ",
                "copay": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": null,
                "bin": "610600",
                "rxpcn": "AS",
                "description": "Reduces copay price for patient",
                "link": "https://zydususa.com/wp-content/uploads/2023/03/Zydus-Copay-Card-Program-Information-03_13_2023.pdf",
                "__v": 0,
                "id": "644ef7765a9606923a41a087"
            },
            {
                "_id": "644f03945a9606923a41a089",
                "brand": "Rebyota Rectal",
                "copay": "100",
                "copay_max": "100",
                "lifetime_uses": "12",
                "max_saving_transaction": null,
                "bin": "",
                "rxpcn": "",
                "description": "Commercially insured, eligible PATIENTS MAY PAY AS LITTLE AS $100a for each REBYOTA prescription. Available only for patients who meet eligibility requirements. The program does not cover the cost of administration, office visits, or any associated costs.",
                "link": "https://www.rebyota.com/rebyota-support-resources/",
                "__v": 0,
                "id": "644f03945a9606923a41a089"
            },
            {
                "_id": "645004205a9606923a41a0b0",
                "brand": "Spiriva Inhalers",
                "copay": "0",
                "copay_max": "6000.00",
                "lifetime_uses": "12",
                "max_saving_transaction": null,
                "bin": "610524",
                "rxpcn": "",
                "description": "Free Program Provides access to Hospital2Home",
                "link": "https://www.bihospital2home.com/",
                "__v": 0,
                "id": "645004205a9606923a41a0b0"
            },
            {
                "_id": "6498369b0cc7e72469cd4ddd",
                "brand": "TASCENSO ODT  orally disintegrating tablets",
                "copay": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 6000,
                "bin": "SUN",
                "rxpcn": "610711",
                "description": "If you have commercial insurance, you may be eligible for the Cycle Vita Co-pay Assistance Program. If you are commercially insured and eligible you may pay as little as $0.",
                "link": "https://tascenso.com/patient/cycle-vita/hub-support/",
                "__v": 0,
                "id": "6498369b0cc7e72469cd4ddd"
            },
            {
                "_id": "64aed3756c4bb41e62e8b86d",
                "brand": "Triluma",
                "copay": "",
                "copay_max": "",
                "lifetime_uses": "",
                "max_saving_transaction": null,
                "bin": "",
                "rxpcn": "",
                "description": "",
                "link": "https://www.triluma.com/savings",
                "__v": 0,
                "id": "64aed3756c4bb41e62e8b86d"
            },
            {
                "_id": "653c4411b771a1501ece4c22",
                "brand": "Airsupra Inhalation Aerosol",
                "copay": "0",
                "copay_max": "0",
                "lifetime_uses": "12",
                "max_saving_transaction": 498,
                "bin": "004682",
                "rxpcn": "CN",
                "description": "maximum savings limit of $130 for each inhaler; patient out-of-pocket expenses may vary. If you are insured and your insurance does not cover or has a managed care restriction on your prescription (step-edit, prior authorization, or NDC block), you will pay as low as $15 for each inhaler, subject to a maximum savings limit of $498 for each inhaler",
                "link": "https://www.airsupra.com/register-for-updates.html",
                "__v": 0,
                "id": "653c4411b771a1501ece4c22"
            },
            {
                "_id": "6553a4189895479f0c147aab",
                "brand": "Xultophy 100/3.6 Insulin",
                "copay": "Varies",
                "copay_max": "",
                "lifetime_uses": "12",
                "max_saving_transaction": null,
                "bin": "610711",
                "rxpcn": "SUN",
                "description": "Novo Nordisk Patient Assistance Program",
                "link": "https://www.xultophy10036pro.com/coverage-and-support/patient-support.html",
                "__v": 0,
                "id": "6553a4189895479f0c147aab"
            },
            {
                "_id": "6553a4ac9895479f0c147aac",
                "brand": "Xultophy 100/3.6 Insulin",
                "copay": "Varies",
                "copay_max": "",
                "lifetime_uses": "12",
                "max_saving_transaction": null,
                "bin": "610728",
                "rxpcn": "PANF",
                "description": "PAN FOUNDATION",
                "link": "https://www.panfoundation.org/",
                "__v": 0,
                "id": "6553a4ac9895479f0c147aac"
            },
            {
                "_id": "659ac0dc1a700c70ccc249d5",
                "brand": "BRENZAVVY (bexagliflozin) 20mg Tabs",
                "copay": "$60",
                "copay_max": "60.00",
                "lifetime_uses": "12",
                "max_saving_transaction": 600,
                "bin": "",
                "rxpcn": "",
                "description": "Available at independent pharmacies nationwide for convenient and immediate access to BRENZAVVY - Also Patient Access Network Foundation (PAN)",
                "link": "https://brenzavvy.com/",
                "__v": 0,
                "id": "659ac0dc1a700c70ccc249d5"
            },
            {
                "_id": "6642354f680a45b32cd174fd",
                "brand": "Clemastine Fumarate Syrup",
                "copay": "0",
                "copay_max": "525",
                "lifetime_uses": "12",
                "max_saving_transaction": 525,
                "bin": "600471",
                "rxpcn": "55101202",
                "description": "Max copay/Rx Use ID #10260954001  Group# X10260",
                "link": "http://solubiomix.net",
                "__v": 0,
                "id": "6642354f680a45b32cd174fd"
            },
            {
                "_id": "6642397f680a45b32cd17503",
                "brand": "Coxanto 300mg Capsules",
                "copay": "0",
                "copay_max": "525",
                "lifetime_uses": "12",
                "max_saving_transaction": 625,
                "bin": "600471",
                "rxpcn": "55101202",
                "description": "BIN# 600471 PCN# 55101202  ID#10260954001 GROUP# X10260",
                "link": "http://solubiomix.net/",
                "__v": 0,
                "id": "6642397f680a45b32cd17503"
            }
        ]
                let result = []
                let linkd = {}
                for (let i = 0; i < recrdData.length; i++) {
                    let each = recrdData[i]
                    if (each.link !== "") {
                       
                        linkd[each.brand] = each.link
                    }
                }

                this.setState({
                    records: result,
                    linkd,
                    loading: false
                })
            
    }

    editRecord(record) {
        this.setState({ currentRecord: record });
    }

    deleteRecord(record) {
        axios
            .post("/api/coupon-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                    this.setState({ brand: undefined })
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            });
        this.getData();
    }

    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            brand: undefined,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "dea", order: "asc" }
            },
            records: [],
            totalCount: undefined
        })
    }

    toggleChange = () => {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen,
        });
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    exportToCSV = () => {
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            "brand": "Brand",
            "copay": "copay",
            "bin": "BIN",
            "rxpcn": "RXPCN",
            "copay_max_orig": "Copay Max",
            "lifetime_uses": "Lifetime Uses",
            "max_saving_transaction_orig": "Max Transaction",
            "description": "Description",
        };
        let records = []
        for (let i in this.state.records) {
            let record = this.state.records[i]
            records.push(record);
        }
        if (headers) {
            records.unshift(headers);
        }
        // Convert Object to JSON
        // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
        // var universalBOM = "\uFEFF";
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
        // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
        var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'application/csv;charset=UTF-8' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    convertToCSV(objArray, headers) {
        const keys = Object.keys(headers);
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in keys) {
                line += array[i][keys[index]] === undefined ? "," : array[i][keys[index]] + ",";
                line.trim(",")


            }
            str += line + '\r\n';
        }
        return str;
    }

    render() {
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    <div className="container-fluid">

                        {Object.entries(json).map(([k, each]) => {
                            return (
                                <>
                                    <h5>{k}</h5>
                                    <ul>{
                                        each.map(e => {
                                            return (
                                                <li>
                                                    {this.state.linkd[e] ? e + " - " + this.state.linkd[e] : ""}
                                                </li>
                                            )
                                        })
                                    }</ul>
                                </>
                            )

                        })}
                        {/* {(this.state.records).map(each => {
                            console.log(each)
                            return (
                                <div>{each.brand}
                                </div>
                            )
                        })} */}
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(CouponsOpen);
