import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../utils/XPedicareRx.png";
import xpx from "../../utils/xpx.jpg";
import XpedicareVideo from "../partials/XpedicareVideo";

class Rxreach extends React.Component {
  render() {
    return (
      <div className="section">
        <div className="container">
          <XpedicareVideo />
          <div className="row">
            <div className="col-md-12">
              <div className="section-title m-3">
                <div className="row">
                  <div className="col-md-8">
                    {" "}
                    <img
                      src={
                        "https://rxreachanalytis.s3.amazonaws.com/logo/XPedicareRx-24.png"
                      }
                      height={60}
                      style={{ marginLeft: "20px" }}
                    />
                  </div>
                  <div className="col-md-4" style={{ textAlign: "right" }}>
                    <button
                      type="button"
                      style={{
                        marginBottom: "10px",
                        width: "205px",
                      }}
                      className="btn btn-success btn-lg "
                    >
                      <a
                        data-target="#how-it-works"
                        data-toggle="modal"
                        // href="https://app.xs
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        LOGIN/REGISTER
                      </a>
                    </button>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h1
                      className="card-title text-primary"
                      style={{ fontWeight: "bold", fontSize: "47px" }}
                    >
                      Virtual Care on Demand at Your Pharmacy
                    </h1>
                    <div className="row">
                      <div className="col-md-8">
                        <p className="card-text" style={{ fontSize: "20px" }}>
                          XPedicareRx-24 provides pharmacies with a versatile
                          platform to integrate an online clinic directly into
                          their website through a custom link. This innovative
                          tool enables pharmacies to expand their healthcare
                          services beyond physical locations, offering patients
                          convenient access to healthcare professionals
                          remotely. By embedding the online clinic into their
                          website, pharmacies can enhance patient engagement and
                          provide seamless healthcare consultations online.
                        </p>
                        <p className="card-text" style={{ fontSize: "20px" }}>
                          This integration supports consultations for a wide
                          range of acute conditions including ED (Erectile
                          Dysfunction), LDN (Low Dose Naltrexone), Continuous
                          Blood Glucose Monitoring (BGM), Oral Contraceptives,
                          Alopecia, Aging-related concerns, UTI (Urinary Tract
                          Infections), Diabetes management, Travel Medications,
                          COVID-19 consultations, Asthma, Smoking Cessation
                          aids, PreP (Pre-exposure Prophylaxis for HIV
                          prevention), EpiPen prescriptions, Cold Sores, Acne
                          treatments, Allergies, and even Compounded
                          Medications. This comprehensive approach allows
                          pharmacies to meet diverse patient needs efficiently
                          and securely online. Available in all states except
                          Alaska, Arkansas, Missouri and Hawaii.
                        </p>
                      </div>
                      <div className="col-md-4" style={{ textAlign: "center" }}>
                        {/* <button
                          type="button"
                          style={{ marginTop: "10px", width: "205px" }}
                          data-target="#how-it-works"
                          data-toggle="modal"
                          className="btn btn-success btn-lg"
                        >
                          <a style={{ color: "#fff", textDecoration: "none" }}>
                            How-to videos
                          </a>
                        </button> */}
                        <br></br>
                        <img
                          style={{ width: "70%", marginTop: "12px" }}
                          src={xpx}
                        />
                      </div>
                      <hr />
                      <div className="col-md-11">
                        <p className="card-text" style={{ fontSize: "14px" }}>
                          <span style={{ fontSize: "14px" }}>
                            <br></br>
                            <b>First time user? You must register</b>
                          </span>
                        </p>
                      </div>
                      <br></br>
                      <br></br>
                      <div className="col-md-11">
                        <br></br>

                        <img
                          src="https://rxreachanalytis.s3.us-east-1.amazonaws.com/xpedicare-video/XPedicareRx+available+states.png"
                          width={"100%"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rxreach;
