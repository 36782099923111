import axios from "axios";
import classnames from "classnames";
import { PDFDocument } from "pdf-lib";
import React, { useEffect, useRef, useState } from "react";
import isEmpty from "is-empty";
import $ from "jquery";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateFaxTemplate = (props) => {
  const formRef = useRef();
  const [template_file, setTemplate_File] = useState(null);
  const [template_name, setTemplate_Name] = useState("");
  const [pageCount, setPageCount] = useState(0);
  let [errors, setErrors] = useState({});

  const onVendorUpdate = (e) => {
    e.preventDefault();
    let abc = {};

    if (template_name === "") {
      abc.template_name = "This field is required";
    }
    setErrors(abc);
    if (isEmpty(abc)) {
      setErrors({ errors });
      const formData = new FormData();
      if (template_file !== "") formData.append("template_file", template_file);
      if (template_name !== "") formData.append("template_name", template_name);
      if (pageCount !== "") formData.append("page_count", pageCount);
      formData.append("_id", props.records.currentRecord.id);

      formData.append("pharmacy_account", props.auth.user.final_user);

      axios.post("/faxApi/faxtemplate-update", formData).then((res) => {
        $("#update-faxtemplate-modal").modal("hide");
        props.getData();

        toast(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    }
  };
  useEffect(() => {
    if (props.records.currentRecord !== undefined) {
      setTemplate_Name(props.records.currentRecord.template_name);
      setTemplate_File(props.records.currentRecord.template_file);
      setPageCount(props.records.currentRecord.page_count);
    }
  }, [props]);

  const onFileUpload = async (e) => {
    if (e.target.files) {
      if (e.target.files[0].type === "application/pdf") {
        setTemplate_File(e.target.files[0]);
        const numPages = await getPageCount(e.target.files[0]);
        setPageCount(numPages);
      } else {
        formRef.current.value = "";
        alert("Please upload PDF file");
      }
    }
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const getPageCount = async (file) => {
    const arrayBuffer = await readFile(file);
    const pdf = await PDFDocument.load(arrayBuffer);
    return pdf.getPageCount();
  };
  return (
    <div>
      <div
        className="modal fade"
        id="update-faxtemplate-modal"
        data-reset="false"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Update Fax Template</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form
                noValidate
                onSubmit={onVendorUpdate}
                id="update-faxtemplate"
                encType="multipart/form-data"
              >
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="name">Fax Template Name</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      onChange={(e) => {
                        setTemplate_Name(e.target.value);
                      }}
                      value={template_name}
                      id="name"
                      type="text"
                      className={classnames("form-control")}
                    />
                    <span className="text-danger">{errors.template_name}</span>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="name">
                      Fax Template File<br></br>(PDF only)
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      onChange={(e) => {
                        onFileUpload(e);
                      }}
                      id="template_file"
                      accept=".pdf"
                      type="file"
                      ref={formRef}
                      className={classnames("form-control")}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                form="update-faxtemplate"
                type="submit"
                className="btn btn-primary"
              >
                Update Fax Template
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(UpdateFaxTemplate));
