import React, { Component } from "react";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import axios from "axios";
import ReactAutocomplete from "react-autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import PulseLoader from "react-spinners/PulseLoader";
import { toast, ToastContainer } from "react-toastify";
import PhysicianMedication from "./View/PhysicianMedication";
import PrescriberRxDetail from "./View/PrescriberRxDetail";
import GroupFilter from "./Filter/GroupFilter.js";
import moment from "moment";
import dashboard from "../../utils/dashboard.png";
import { Link } from "react-router-dom";
import { logoutUser } from "../../actions/authActions";
class CashReport extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "_id",
        text: "NPI",
        className: "_id",
        align: "left",
        sortable: true,
        show_medication: true,
      },
      {
        key: "prescriberRx",
        text: "Prescriber RX",
        className: "prescriberRx",
        align: "left",
        show_rx: true,
      },
      {
        key: "first_name",
        text: "First Name",
        className: "first_name",
        align: "left",
        sortable: true,
      },
      {
        key: "last_name",
        text: "Last Name",
        className: "last_name",
        align: "left",
        sortable: true,
      },
      {
        key: "total_rx_sum",
        text: "RX $",
        className: "total_rx_sum",
        align: "left",
        sortable: true,
      },
      {
        key: "total_rx",
        text: "#RX",
        className: "total_rx",
        align: "left",
        sortable: true,
      },
      {
        key: "total_retail_sum",
        text: "Retail $",
        className: "total_retail_sum",
        align: "left",
        sortable: true,
      },
      {
        key: "total_rx_sum",
        text: "Paid $",
        className: "total_rx_sum",
        align: "left",
        sortable: true,
      },
      {
        key: "savings_percent",
        text: "Savings %",
        className: "savings_percent",
        align: "left",
        sortable: true,
      },
    ];

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100],
      filename: "Stats",
      no_data_text: "No result found!",
      button: {
        excel: false,
        print: false,
        csv: false,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "_id", order: "desc" },
      },
      currentRecord: {},
      name: undefined,
      from_date: moment().subtract(90, "days").startOf("day").toDate(),
      to_date: moment().endOf("day").toDate(),
      value: undefined,
      selectedNpi: undefined,
      allDoctorName: [],
      loading: false,
      npi: undefined,
      physicianMedication: undefined,
      rx_group_associated: undefined,
      prescriberRx: undefined,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
    // this.getSuggested()
  }

  componentDidUpdate(prevProps) {
    if (this.props.multistoreData.length !== prevProps.multistoreData.length) {
      this.getData();
    }
  }

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData())
  }

  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.from_date !== "" && { from_date: this.state.from_date }),
      ...(this.state.to_date !== "" && { to_date: this.state.to_date }),
      ...(this.state.npi !== "" && { npi: this.state.npi }),
      ...(this.props.multistoreData.length > 0 &&
        (this.state.rx_group_associated === undefined ||
          this.state.rx_group_associated === "") && {
        multiStoreEmails: this.props.multistoreData.map((a) => a.email),
      }),
    };
    if (this.state.value !== undefined && this.state.value !== "") {
      let selectedNpi = this.state.allDoctorName.find(
        (o) => o.name === this.state.value
      );
      if (selectedNpi !== undefined) {
        filterData.search.npi = selectedNpi.id;
      } else {
        filterData.search.npi = this.state.value;
      }
    }
    axios
      .post("/api/cash-report-npi", filterData)
      .then((res) => {
        let result = [];
        let doctorsArr = {};
        let allDoctorName = [];
        res.data.records.sort(
          (a, b) => Number(b.total_rx) - Number(a.total_rx)
        );
        for (let i = 0; i < res.data.doctors.length; i++) {
          doctorsArr[res.data.doctors[i].npi] = {
            first_name: res.data.doctors[i].first_name,
            last_name: res.data.doctors[i].last_name,
            account_name: res.data.doctors[i].account_name,
            address_telephone: res.data.doctors[i].address_telephone,
          };
          if (
            res.data.doctors[i].first_name !== undefined &&
            res.data.doctors[i].last_name !== undefined
          ) {
            allDoctorName.push({
              id: res.data.doctors[i].npi,
              name:
                res.data.doctors[i].first_name +
                " " +
                res.data.doctors[i].last_name,
            });
          }
        }
        for (let i = 0; i < res.data.records[0].data.length; i++) {
          let each = res.data.records[0].data[i];
          // if (doctorsArr[each._id] !== undefined) {
          // if (
          //   doctorsArr[each._id].first_name &&
          //   doctorsArr[each._id].last_name
          // ) {
          each.total_rx = each.total_rx !== null ? each.total_rx : 0;

          each.first_name =
            doctorsArr[each._id] !== undefined
              ? doctorsArr[each._id].first_name
              : "";
          each.last_name =
            doctorsArr[each._id] !== undefined
              ? doctorsArr[each._id].last_name
              : "";
          each.account_name =
            doctorsArr[each._id] !== undefined
              ? doctorsArr[each._id].account_name
              : "";
          each.address_telephone =
            doctorsArr[each._id] !== undefined
              ? doctorsArr[each._id].address_telephone
              : "";
          each.savings_percent =
            each.total_rx_sum !== 0 && each.total_retail_sum !== 0
              ? (
                ((each.total_retail_sum - each.total_rx_sum) /
                  each.total_retail_sum) *
                100
              ).toFixed(2)
              : 0;
          each.total_retail_sum =
            each.total_retail_sum !== null
              ? "$" + parseFloat(each.total_retail_sum).toFixed(2)
              : 0;
          each.total_rx_sum =
            each.total_rx_sum !== null
              ? "$" + parseFloat(each.total_rx_sum).toFixed(2)
              : 0;
          result.push(each);
          // }
          // }
        }
        console.log(result)
        this.setState({
          records: result,
          totalCount: res.data.records[0].metadata[0].total,
          // allDoctorName: allDoctorName,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  changeText = (value) => { };

  onSearch = (e) => {
    this.setState(
      {
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "retail", order: "desc" },
        },
      },
      () => this.getData()
    );
  };

  reset = () => {
    this.setState(
      {
        value: undefined,
        from_date: undefined,
        to_date: undefined,
        npi: "",
        rx_group_associated: "",
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "_id", order: "asc" },
        },
      },
      () => this.getData()
    );
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  setFromDate = (date) => {
    this.setState({ from_date: date });
  };
  setToDate = (date) => {
    this.setState({ to_date: date });
  };

  setReactAutocomplete = (e) => {
    let selectedNpi = this.state.records.find((o) => o.name === "string 1");
    this.setState({ value: e.target.value, selectedNpi: selectedNpi._id });
  };

  shouldItemRender = (item, value) => {
    return item.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
  };

  exportToCSV = () => {
    toast.success("Dowloading...Please Wait!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    var headers = {
      _id: "NPI",
      prescriberRx: "Prescriber RX",
      first_name: "First Name",
      last_name: "Last Name",
      total_rx_sum: "RX $",
      total_rx: "#RX",
      total_retail_sum: "Retail $",
      total_rx_sum: "Paid $",
      savings_percent: "Savings %",
    };
    // // add columns in sheet array

    var records = [];
    // add data rows in sheet array
    this.setState({ loading: false });
    for (let i in this.state.records) {
      let record = this.state.records[i];
      records.push(record);
    }
    if (headers) {
      records.unshift(headers);
    }
    // Convert Object to JSON
    let jsonObject = JSON.stringify(records);
    let csv = this.convertToCSV(jsonObject, headers);
    let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
    let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  convertToCSV(objArray, headers) {
    const keys = Object.keys(headers);

    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in keys) {
        line +=
          array[i][keys[index]] === undefined
            ? ","
            : array[i][keys[index]] + ",";
        line.trim(",");
      }
      str += line + "\r\n";
    }
    return str;
  }

  showMedication = (physicianMedication) => {
    let filterData = {
      search: {
        npi: physicianMedication._id,
        pharmacy_account: this.props.auth.user.final_user,
      },
    };
    axios
      .post("/api/prescriber-report-by-npi", filterData)
      .then((res) => {
        let result = [];
        for (let i = 0; i < res.data.records.length; i++) {
          let each = res.data.records[i];
          each.dispense_date = each.dispense_date
            ? moment(each.dispense_date).add(5, "hours").format("L")
            : "";
          each.patient_dob = each.patient_dob
            ? moment(each.patient_dob).add(5, "hours").format("L")
            : "";
          result.push(each);
        }
        physicianMedication.records = result;
        this.setState({ physicianMedication: physicianMedication });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  showMedicationRx = (physicianMedication) => {
    this.setState({ prescriberRx: physicianMedication._id });
  };

  openDatepicker = (e) => {
    if (e.target.id === "toD") {
      this.toDatePicker.setOpen(true);
    } else {
      this.fromDatePicker.setOpen(true);
    }
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  render() {
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/choose-plan?solution=TabulaRx";
    }
    let associatedRecords = [];
    for (let i = 0; i < this.props.groups.length; i++) {
      let each = this.props.groups[i];
      if (each.rx_group_associated !== undefined) {
        associatedRecords = [
          ...associatedRecords,
          ...each.rx_group_associated.split(","),
        ];
      }
    }
    let { totalCount } = this.state;
    console.log(totalCount)
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <PhysicianMedication data={this.state.physicianMedication} />
        <PrescriberRxDetail prescriberRx={this.state.prescriberRx} />
        <div id="page-content-wrapper">
          {this.props.auth.user.tabulaRx ||
            this.props.auth.user.superadmin ||
            this.props.auth.user.expiration_tabula === "" ||
            this.props.auth.user.expiration_tabula >= moment().format() ? (
            <div className="container-fluid">
              <h1 className="mt-2 text-primary">Prescriber Cash Report</h1>
              <button className="btn btn-warning">
                <FontAwesomeIcon icon={faBackward} />
                <Link to="/dashboard"> Go to Dashboard</Link>
              </button>
              <br></br>
              <br></br>
              <div>
                <form noValidate>
                  <div className="form-row">
                    <GroupFilter
                      onChangeSelect={this.onChangeSelect}
                      rx_group_associated={this.state.rx_group_associated}
                    />
                    {this.state.allDoctorName.length ? (
                      <div className="form-group col-md-3 autocomplete_box">
                        <label htmlFor="npi">Physician Name</label>
                        <ReactAutocomplete
                          items={this.state.allDoctorName || []}
                          shouldItemRender={this.shouldItemRender}
                          getItemValue={(item) => item.name}
                          renderItem={(item, highlighted) => (
                            <div
                              key={item.id}
                              style={{
                                backgroundColor: highlighted
                                  ? "#eee"
                                  : "transparent",
                              }}
                            >
                              {item.name}
                            </div>
                          )}
                          value={this.state.value}
                          onChange={(e) =>
                            this.setState({ value: e.target.value })
                          }
                          onSelect={(value) => this.setState({ value })}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-group col-md-3">
                      <label htmlFor="npi">NPI</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.npi}
                        id="npi"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <button
                    onClick={this.onSearch}
                    type="button"
                    className="btn btn-primary"
                  >
                    Search
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={this.reset}
                  >
                    Clear
                  </button>
                </form>
              </div>
              <br></br>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                total_record={totalCount}
                onChange={this.filter}
                loading={true}
                exportToCSV={this.exportToCSV}
                showMedication={this.showMedication}
                showMedicationRx={this.showMedicationRx}
              />
            </div>
          ) : (
            <div className="container-fluid static-card">
              <div className="card">
                <div className="card-body">
                  {this.props.auth.user.expiration_tabula <
                    moment().format() ? (
                    <h6 class="font-weight-bold text-danger">
                      Your Subscription is ended<br></br>
                      <Link to="/subscribe">
                        <button
                          className="btn btn-success"
                          onClick={this.Subscribe}
                        >
                          Subscribe Now
                        </button>
                      </Link>
                    </h6>
                  ) : !this.props.auth.user.tabulaRx ? (
                    <h6 class="font-weight-bold text-danger">
                      You accounts is inactive!! To activate you account please{" "}
                      <Link to="/support" className="">
                        Contact
                      </Link>{" "}
                      Us.
                    </h6>
                  ) : (
                    <h6 class="font-weight-bold text-danger">
                      You accounts is inactive!! To activate you account please{" "}
                      <Link to="/support" className="">
                        Contact
                      </Link>{" "}
                      Us.
                    </h6>
                  )}
                </div>
              </div>
              <img className="static-image" src={dashboard} />
            </div>
          )}
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
  multistoreData: state.multistoreData,
});

export default connect(mapStateToProps, { logoutUser })(CashReport);
