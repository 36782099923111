import React, { Component } from "react";
import { Helmet } from "react-helmet";

var x;
class ChatBotIframe extends Component {
  constructor() {
    super();
    this.state = {

    };
  }



  render() {
    return (
      <Helmet>
        <script
          src="https://cdn.apigateway.co/webchat-client..prod/sdk.js"
          data-widget-id="eee4bb40-84ed-11ef-a9bb-1a3da0fa9c8b"
          defer
        ></script>
      </Helmet>
    );
  }
}
export default ChatBotIframe
