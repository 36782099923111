import axios from "axios";
import $ from "jquery";
import moment from "moment";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import { ToastContainer } from "react-toastify";
import { logoutUser } from "../../actions/authActions";
import ReactDatatable from "../../utils/ReactDatatable";
import SavedSearchFax from "../partials/SendFax/SavedSearch";

class SavedSearch extends React.Component {
  constructor() {
    super();

    this.columns = [
      {
        key: "name",
        text: "Search Name",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "totalNpi",
        text: "Total NPI",
        className: "totalNpi",
        align: "left",
        sortable: true,
      },
      {
        key: "date",
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
      },
      {
        key: "last_used",
        text: "Last Used",
        className: "last_used",
        align: "left",
        sortable: true,
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <>
              <button
                data-toggle="modal"
                data-target="#update-doctor-modal"
                className="btn btn-success btn-sm"
                onClick={() => this.SendFax(record)}
                style={{ marginRight: "5px" }}
              >
                <i class="fa fa-paper-plane"></i>
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </>
          );
        },
      },
    ];

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100],
      filename: "Search",
      no_data_text: "No Details found!",
      button: {
        excel: false,
        print: false,
        csv: false,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      errors: {},
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "_id", order: "desc" },
      },
      fax_records: [],
      loading: false,
      fax_points: undefined,
      currentRecord: {},
    };
  }

  componentDidMount() {
    this.getData();
    this.getFaxCredits();
    this.getFaxTemplate();
  }

  getData() {
    this.setState({ loading: true });
    let filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "_id", order: "desc" },
    };
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post("/faxApi/saved-search", filterData)
      .then((res) => {
        let result = [];
        for (let i = 0; i < res.data.records.length; i++) {
          let each = res.data.records[i];
          each.totalNpi = (each.npi || []).length;
          each.date = each.date ? moment(each.date).format("L") : "";
          each.last_used = each.last_used
            ? moment(each.last_used).format("LLL")
            : "";
          result.push(each);
        }
        this.setState({
          loading: false,
          records: result,
          totalCount: res.data.records.length,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  getFaxCredits() {
    let filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "_id", order: "desc" },
    };
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        if (res.data.records.length) {
          this.setState({
            fax_points: res.data.records[0].fax_points || 0,
            address_postal: res.data.records[0].zipgroup,
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }
  getFaxTemplate() {
    axios
      .post("/faxApi/fax-template", {
        pharmacy_account: this.props.auth.user.email,
      })
      .then((res) => {
        let result = [];
        for (let i = 0; i < res.data.records.length; i++) {
          let each = res.data.records[i];
          if (each.id !== "") {
            result.push(each);
          }
        }
        this.setState({
          fax_records: result,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  SendFax = (record) => {
    if (parseInt(this.state.fax_points || 0) >= record.totalNpi) {
      this.setState({ currentRecord: record }, () =>
        $("#send-faxtemplate-modal").modal("show")
      );
    } else {
      confirmAlert({
        title: "Attention",
        message:
          "You need" +
          "    " +
          (parseInt(record.totalNpi) - parseInt(this.state.fax_points || 0)) +
          "  " +
          "More Credits to Send fax",
        buttons: [
          {
            label: "Ok",
            onClick: () =>
              (window.location.href = "/fax-credits?show-popup=true"),
          },
          {
            label: "Cancel",
            onClick: () => {},
          },
        ],
      });
    }
  };

  deleteRecord = (record) => {
    confirmAlert({
      title: "Warning",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.finalDelete(record),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  finalDelete = (record) => {
    axios
      .post("/faxApi/saved-search-delete", { _id: record._id })
      .then((res) => {
        this.getData();
      });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/choose-plan?solution=TabulaRx";
    }
    const { errors } = this.state;
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div id="page-content-wrapper" className="saved-search">
          <div className="container-fluid">
            <h1 className="mt-2 text-primary">Saved Search List</h1>
            <button
              className="btn btn-success mt-3 mr-2"
              onClick={() => window.history.go(-1)}
            >
              {"<"} Go to Prescriber List
            </button>
            <SavedSearchFax
              record={this.state.fax_records}
              email={this.props.auth.user.final_user}
              totalNpis={this.state.totalCount}
              npis={this.state.currentRecord.npi}
              searchId={this.state.currentRecord._id}
              apiUrl={"/faxApi/fax-send-savedSearch"}
              redirectUrl={"/saved-search"}
            />
            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={false}
              total_record={this.state.totalCount}
              loading={true}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  pharmacy: state.pharmacy,
  groups: state.groups,
});

export default connect(mapStateToProps, { logoutUser })(SavedSearch);
