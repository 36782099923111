import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import React, { Component } from "react";
import { Collapse } from "react-collapse";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import { toast, ToastContainer } from "react-toastify";
import ReactDatatable from "../../utils/ReactDatatable";
import usState from "../../utils/state.json";
import FaxTemplatePlan from "../partials/FaxTemplatePlan";
import SendFaxPrescriberRx from "../partials/SendFax/PrescriberRx";
import PrescriberRxDetail from "./View/PrescriberRxDetail";
import AddSavedSearch from "../partials/AddSavedSearch";
class PrescriberRxLocal extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        text: "Exclude",
        className: "_id",
        align: "left",
        sortable: false,
        checkBox: true,
        key: "_id",
      },
      {
        key: "brand_name",
        text: "Brand",
        className: "brand_name",
        align: "left",
        sortable: true,
        openLink: true,
      },
      {
        key: "generic_name",
        text: "Generic Name",
        className: "generic_name",
        align: "left",
        sortable: true,
      },

      {
        key: "npi",
        text: "NPI",
        className: "npi",
        align: "left",
        sortable: true,
        show_medication_rx: true,
      },
      {
        key: "type",
        text: "Doc Type",
        className: "type",
        align: "left",
        sortable: true,
      },
      {
        key: "first_name",
        text: "Doc First Name",
        className: "first_name",
        align: "left",
        sortable: true,
        currency: true,
      },
      {
        key: "last_name",
        text: "Doc Last Name",
        className: "last_name",
        align: "left",
        sortable: true,
      },
      {
        key: "city",
        text: "City",
        className: "city",
        align: "left",
        sortable: true,
      },
      {
        key: "state",
        text: "State",
        className: "state",
        align: "left",
        sortable: true,
      },
      {
        key: "total_ben",
        text: "Total Beneficiary",
        className: "total_ben",
        align: "left",
        sortable: true,
      },
      {
        key: "total_claims",
        text: "Total Claim",
        className: "total_claims",
        align: "left",
        sortable: true,
      },
      {
        key: "total_day_supply",
        text: "Total Day Supply",
        className: "total_day_supply",
        align: "left",
        sortable: true,
      },
      {
        key: "total_30days_fill",
        text: "Total 30 Days Fill",
        className: "total_30days_fill",
        align: "left",
        sortable: true,
      },
    ];

    this.config = {
      page_size: 50,
      length_menu: [50, 100, 200],
      filename: "PrescriberRx List",
      no_data_text: "No record found!",
      button: {
        csv: true,
        sendFax: true,
        print: false,
        savedList: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
    };

    if (this.props.auth.user.superadmin) {
      this.config.button.csv = true;
    }

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 50,
        sort_order: { column: "total_claims", order: "desc" },
      },
      currentRecord: {},
      brand: "",
      npi: undefined,
      first_name: undefined,
      last_name: undefined,
      state: undefined,
      city: undefined,
      loading: false,
      isFilterOpen: true,
      prescriberRx: undefined,
      fax_points: undefined,
      fax_records: [],
      seletecedCheckbox: [],
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    // this.getData();
    this.getFaxCredits();
    this.getFaxTemplate();
  }

  getData = () => {
    if (this.state.brand === "") {
      confirmAlert({
        title: "Attention",
        message: "Please enter Brand Name.",
        buttons: [
          {
            label: "Ok",
            onClick: () => { },
          },
        ],
      });
    } else {
      this.setState({ loading: true });
      let filterData = this.state.filterData;
      filterData.search = {
        ...(this.state.brand !== "" && { brand: this.state.brand }),
        ...(this.state.npi !== "" && { npi: this.state.npi }),
        ...(this.state.first_name !== "" && {
          first_name: this.state.first_name,
        }),
        ...(this.state.last_name !== "" && { last_name: this.state.last_name }),
        ...(this.state.state !== "" && { state: this.state.state }),
        ...(this.state.city !== "" && { city: this.state.city }),
      };
      axios
        .post("/api/prescriberrx-search", filterData)
        .then((res) => {
          this.setState({
            records: res.data.records,
            totalCount: res.data.count,
            loading: false,
          });
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    }
  };

  SendFax = () => {
    if (this.state.totalCount === 0) {
      confirmAlert({
        title: "Error",
        message: "No Prescriber found. Please search with valid filters",
        buttons: [
          {
            label: "OK",
            onClick: () => { },
          },
        ],
      });
    } else {
      if (
        this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()
      ) {
        confirmAlert({
          title: "Attention",
          message:
            "This feature is available for TabulaRx Subscribed User. Would you like to subscribe now?",
          buttons: [
            {
              label: "Yes",
              onClick: () =>
                (window.location.href = "/choose-plan?solution=TabulaRx"),
            },
            {
              label: "No",
              onClick: () => { },
            },
          ],
        });
      } else if (
        this.state.brand === undefined ||
        this.state.state === undefined
      ) {
        toast.error("Please Select State or Enter Brand", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        parseInt(this.state.fax_points || 0) >= this.state.totalCount
      ) {
        $("#send-faxtemplate-modal").modal("show");
      } else {
        confirmAlert({
          title: "Attention",
          message:
            "You need" +
            "    " +
            (parseInt(this.state.totalCount) -
              parseInt(this.state.fax_points || 0)) +
            "  " +
            "More Credits to Send fax",
          buttons: [
            {
              label: "Ok",
              onClick: () =>
                (window.location.href = "/fax-credits?show-popup=true"),
            },
            {
              label: "Cancel",
              onClick: () => { },
            },
          ],
        });
      }
    }
  };
  getFaxCredits() {
    let filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "_id", order: "desc" },
    };
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        if (res.data.records.length) {
          this.setState({
            fax_points: res.data.records[0].fax_points || 0,
            address_postal: res.data.records[0].zipgroup,
          });
        }

        // this.setState({
        //   taxonomies: taxonomies,
        // });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }
  getFaxTemplate() {
    axios
      .post("/faxApi/fax-template", {
        pharmacy_account: this.props.auth.user.email,
      })
      .then((res) => {
        let result = [];
        for (let i = 0; i < res.data.records.length; i++) {
          let each = res.data.records[i];
          if (each.id !== "") {
            result.push(each);
          }
        }
        this.setState({
          fax_records: result,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  showMedication = (physicianMedication) => {
    this.setState({ prescriberRx: physicianMedication.npi });
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  resetSearch = () => {
    this.setState({
      brand: "",
      npi: undefined,
      first_name: undefined,
      last_name: undefined,
      state: undefined,
      city: undefined,
      filterData: {
        page_number: 1,
        page_size: 50,
        sort_order: { column: "dea", order: "asc" },
      },
      records: [],
      totalCount: undefined,
    });
  };

  toggleChange = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  exportToCSV = () => {
    this.setState({ loading: true });
    let filterData = {
      page_number: 1,
      page_size: 2000000,
      sort_order: { column: "_id", order: "asc" },
    };
    filterData.search = {
      ...(this.state.brand !== "" && { brand: this.state.brand }),
      ...(this.state.npi !== "" && { npi: this.state.npi }),
      ...(this.state.first_name !== "" && {
        first_name: this.state.first_name,
      }),
      ...(this.state.last_name !== "" && { last_name: this.state.last_name }),
      ...(this.state.state !== "" && { state: this.state.state }),
      ...(this.state.city !== "" && { city: this.state.city }),
      pharmacy_account: this.props.auth.user.final_user,
      export: true,
    };
    axios
      .post("/api/prescriberrx-search", filterData)
      .then((res) => {
        this.setState({ loading: false });
        toast.success("Dowloading...Please Wait!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        var headers = {
          brand_name: "Brand",
          generic_name: "Generic Name",
          npi: "NPI",
          type: "Doc Type",
          first_name: "Doc First Name",
          last_name: "Doc Last Name",
          city: "City",
          state: "State",
          total_ben: "Total Beneficiary",
          total_claims: "Total Claim",
          total_day_supply: "Total Day Supply",
          total_30days_fill: "Total 30 Days Fill",
        };
        let npiData = {};
        if (this.props.auth.user.superadmin) {
          headers["practice_fax"] = "Fax";
          headers["practice_telephone"] = "Phone";

          for (let i in res.data.npi) {
            let eachNpi = res.data.npi[i];
            npiData[eachNpi.npi] = eachNpi;
          }
        }
        let records = [];
        for (let i in res.data.records) {
          let record = res.data.records[i];
          if (npiData[record.npi]) {
            record = { ...record, ...npiData[record.npi] };
          }
          records.push(record);
        }
        if (headers) {
          records.unshift(headers);
        }
        // Convert Object to JSON
        // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
        // var universalBOM = "\uFEFF";
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
        // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
        var blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
          type: "application/csv;charset=UTF-8",
        });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  convertToCSV(objArray, headers) {
    const keys = Object.keys(headers);
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in keys) {
        line +=
          array[i][keys[index]] === undefined
            ? ","
            : array[i][keys[index]] + ",";
        line.trim(",");
      }
      str += line + "\r\n";
    }
    return str;
  }

  toggleChangeMass = (e, record) => {
    if (e.target.checked) {
      this.setState({
        seletecedCheckbox: [...this.state.seletecedCheckbox, record._id],
      });
    } else {
      this.setState({
        seletecedCheckbox: this.state.seletecedCheckbox.filter(
          (each) => each !== record._id
        ),
      });
    }
  };

  saveSearch = () => {
    if (
      (this.state.npi !== "" && this.state.npi !== undefined) ||
      (this.state.brand !== "" && this.state.brand !== undefined) ||
      (this.state.first_name !== "" && this.state.first_name !== undefined) ||
      (this.state.last_name !== "" && this.state.last_name !== undefined) ||
      (this.state.state !== "" && this.state.state !== undefined) ||
      (this.state.city !== "" && this.state.city !== undefined)
    ) {
      $("#saved-search-modal").modal("show");
    } else {
      confirmAlert({
        title: "Error",
        message: "Please enter at least one filter to save search.",
        buttons: [
          {
            label: "OK",
            onClick: () => { },
          },
        ],
      });
    }
  };

  render() {
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <PrescriberRxDetail prescriberRx={this.state.prescriberRx} />
        <FaxTemplatePlan />
        <AddSavedSearch
          filters={{
            ...(this.state.npi !== "" &&
              this.state.npi !== undefined && { npi: this.state.npi }),
            ...(this.state.first_name !== "" &&
              this.state.first_name !== undefined && {
              first_name: {
                $regex: "^" + this.state.first_name,
                $options: "i",
              },
            }),
            ...(this.state.last_name !== "" &&
              this.state.last_name !== undefined && {
              last_name: {
                $regex: "^" + this.state.first_name,
                $options: "i",
              },
            }),
            ...(this.state.state !== "" &&
              this.state.state !== undefined && {
              state: this.state.state,
            }),
            ...(this.state.brand !== "" &&
              this.state.brand !== undefined && {
              brand_name: {
                $regex: "^" + this.state.brand,
                $options: "i",
              },
            }),
            ...(this.state.city !== "" &&
              this.state.city !== undefined && {
              city: {
                $regex: "^" + this.state.city,
                $options: "i",
              },
            }),
          }}
          excludes={this.state.seletecedCheckbox}
        />
        <SendFaxPrescriberRx
          record={this.state.fax_records}
          email={this.props.auth.user.final_user}
          totalNpis={this.state.totalCount}
          filters={{
            ...(this.state.npi !== "" &&
              this.state.npi !== undefined && { npi: this.state.npi }),
            ...(this.state.first_name !== "" && {
              first_name: this.state.first_name,
            }),
            ...(this.state.last_name !== "" &&
              this.state.last_name !== undefined && {
              last_name: this.state.last_name,
            }),
            ...(this.state.first_name !== "" &&
              this.state.first_name !== undefined && {
              first_name: this.state.first_name,
            }),
            ...(this.state.state !== "" &&
              this.state.state !== undefined && {
              state: this.state.state,
            }),
            ...(this.state.brand !== "" &&
              this.state.brand !== undefined && {
              brand: this.state.brand,
            }),
            ...(this.state.city !== "" &&
              this.state.city !== undefined && {
              city: this.state.city,
            }),
          }}
          apiUrl={"/faxApi/fax-send-prescriberrx-local"}
          redirectUrl={"/prescriberrx-local"}
          excludes={this.state.seletecedCheckbox}
        />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h1 className="mt-2 text-primary">PrescriberRx by Drugs</h1>
            <button className="btn btn-warning">
              <FontAwesomeIcon icon={faBackward} />
              <Link to="/dashboard"> Go to Dashboard</Link>
            </button>
            <br></br>
            <br></br>
            <div>
              <Collapse isOpened={this.state.isFilterOpen}>
                <form noValidate onSubmit={this.onSearch}>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="brand">Brand</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.brand}
                        id="brand"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="npi">NPI</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.npi}
                        id="npi"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="first_name">First Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.first_name}
                        id="first_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="last_name">Last Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.last_name}
                        id="last_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="city">City</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.city}
                        id="city"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="state">State</label>
                      <select
                        name="state"
                        onChange={this.onChangeSelect}
                        className="form-control"
                        id="state"
                      >
                        <option value="" selected="">
                          Please select
                        </option>
                        {Object.keys(usState).map((each, i) => (
                          <option
                            key={i}
                            value={each}
                            selected={this.state.state === each}
                          >
                            {usState[each]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                  <button
                    type="reset"
                    onClick={this.resetSearch}
                    className="btn btn-secordry"
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.saveSearch}
                  >
                    Save your Search
                  </button>
                </form>
              </Collapse>
            </div>
            <br></br>
            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={true}
              exportToCSV={this.exportToCSV}
              showMedication={this.showMedication}
              sendFax={this.SendFax}
              fax_points={this.state.fax_points}
              toggleChangeMass={this.toggleChangeMass}
              seletecedCheckbox={this.state.seletecedCheckbox}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps)(PrescriberRxLocal);
