/**
 * This is the React Component for ReactDatatabsle
 *
 * @package        ReactDatatable
 * @author         Ashvin Patel
 * @date           14 Dec, 2018
 */

import _ from "lodash";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import dollar from "./ReactDatatable/dollar.png";
import downArrow from "./ReactDatatable/down-arrow.png";
import "./ReactDatatable/style.css";
import upArrow from "./ReactDatatable/up-arrow.png";
import rxl from "./rxl.png";

let style = {
  table_body: {
    marginTop: "16px",
  },
  table_size: {
    background: "none",
    border: "none",
    padding: 0,
  },
  table_size_dropdown: {
    width: "80px",
    flex: "none",
    margin: "0px 10px",
    display: "inline-block",
    float: "none",
  },
  table_filter: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "5px",
    width: "250px",
  },
  table_tool: {
    display: "inline-block",
    verticalAlign: "top",
    float: "right",
  },
  table_tool_btn: {
    marginRight: "5px",
  },
  sort_asc: {
    backgroundImage: `url(${upArrow})`,
  },
  sort_desc: {
    backgroundImage: `url(${downArrow})`,
  },
};

let prev = 0;
let curr = 0;

class ReactDatatable extends Component {
  constructor(props) {
    super(props);
    this.exportExcelRef = React.createRef();
    this.sortColumn = this.sortColumn.bind(this);
    this.numPages = this.numPages.bind(this);
    this.exportToExcel = this.exportToExcel.bind(this);
    this.exportToPDF = this.exportToPDF.bind(this);
    this.exportToCSV = this.exportToCSV.bind(this);
    this.onChange = this.onChange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.filterData = this.filterData.bind(this);
    this.config = {
      button: {
        excel:
          props.config && props.config.button && props.config.button.excel
            ? props.config.button.excel
            : false,
        print:
          props.config && props.config.button && props.config.button.print
            ? props.config.button.print
            : false,
        csv:
          props.config && props.config.button && props.config.button.csv
            ? props.config.button.csv
            : false,
        sendFax:
          props.config && props.config.button && props.config.button.sendFax
            ? props.config.button.sendFax
            : false,
        savedList:
          props.config && props.config.button && props.config.button.savedList
            ? props.config.button.savedList
            : false,
        range:
          props.config && props.config.button && props.config.button.range
            ? props.config.button.range
            : false,
        zipcode:
          props.config && props.config.button && props.config.button.zipcode
            ? props.config.button.zipcode
            : false,
        advanceSearch:
          props.config &&
          props.config.button &&
          props.config.button.advanceSearch
            ? props.config.button.advanceSearch
            : false,
      },
      filename:
        props.config && props.config.filename ? props.config.filename : "table",
      name: props.config && props.config.name ? props.config.name : "table",
      language: {
        length_menu:
          props.config &&
          props.config.language &&
          props.config.language.length_menu
            ? props.config.language.length_menu
            : "Show _MENU_ records per page",
        filter:
          props.config && props.config.language && props.config.language.filter
            ? props.config.language.filter
            : "Search in records...",
        info:
          props.config && props.config.language && props.config.language.info
            ? props.config.language.info
            : "Showing _START_ to _END_ of _TOTAL_ entries",
        pagination: {
          first:
            props.config &&
            props.config.language &&
            props.config.language.pagination &&
            props.config.language.pagination.first
              ? props.config.language.pagination.first
              : "First",
          previous:
            props.config &&
            props.config.language &&
            props.config.language.pagination &&
            props.config.language.pagination.previous
              ? props.config.language.pagination.previous
              : "Previous",
          next:
            props.config &&
            props.config.language &&
            props.config.language.pagination &&
            props.config.language.pagination.next
              ? props.config.language.pagination.next
              : "Next",
          last:
            props.config &&
            props.config.language &&
            props.config.language.pagination &&
            props.config.language.pagination.last
              ? props.config.language.pagination.last
              : "Last",
        },
      },
      length_menu:
        props.config && props.config.length_menu
          ? props.config.length_menu
          : [10, 25, 50, 75, 100],
      no_data_text:
        props.config && props.config.no_data_text
          ? props.config.no_data_text
          : "No rows found",
      show_length_menu:
        props.config.show_length_menu != undefined
          ? props.config.show_length_menu
          : true,
      show_filter:
        props.config.show_filter != undefined ? props.config.show_filter : true,
      show_pagination:
        props.config.show_pagination != undefined
          ? props.config.show_pagination
          : true,
      show_info:
        props.config.show_info != undefined ? props.config.show_info : true,
      show_first:
        props.config.show_first != undefined ? props.config.show_first : true,
      show_last:
        props.config.show_last != undefined ? props.config.show_last : true,
    };
    this.state = {
      filter_value: "",
      page_size: props.config.page_size ? props.config.page_size : 10,
      page_number: 1,
      sort:
        props.config && props.config.sort
          ? props.config.sort
          : { column: props.columns[0].key, order: "asc" },
      range_from: undefined,
      range_to: undefined,
      filter_zip: undefined,
      advanceSearch: undefined,
    };
  }

  filterRecords(e) {
    let value = e.target.value;
    this.setState(
      {
        filter_value: value,
      },
      () => {
        this.onChange();
      }
    );
  }

  changePageSize(e) {
    let value = e.target.value;
    this.setState(
      {
        page_size: value,
      },
      () => {
        this.onChange();
      }
    );
  }

  sortColumn(column, sortOrder) {
    if (!column.sortable) return false;
    let newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    this.setState(
      {
        sort: { column: column.key, order: newSortOrder },
      },
      () => {
        this.onChange();
      }
    );
  }

  paginate(records) {
    let page_size = this.state.page_size;
    let page_number = this.state.page_number;
    --page_number; // because pages logically start with 1, but technically with 0
    return records.slice(
      page_number * page_size,
      (page_number + 1) * page_size
    );
  }

  numPages(totalRecord) {
    return Math.ceil(totalRecord / this.state.page_size);
  }

  previousPage(e) {
    e.preventDefault();
    let nextPage = this.state.page_number - 1,
      pageState = {
        previous_page: this.state.page_number,
        current_page: nextPage,
      };
    if (this.isFirst()) return false;
    this.setState(
      {
        page_number: nextPage,
      },
      () => {
        this.props.onPageChange(pageState);
        this.onChange();
      }
    );
  }

  nextPage(e) {
    e.preventDefault();
    let nextPage = this.state.page_number + 1,
      pageState = {
        previous_page: this.state.page_number,
        current_page: nextPage,
      };
    if (this.isLast()) return false;
    this.setState(
      {
        page_number: nextPage,
      },
      () => {
        this.props.onPageChange(pageState);
        this.onChange();
      }
    );
  }

  firstPage(e) {
    e.preventDefault();
    let pageState = {
      previous_page: this.state.page_number,
      current_page: 1,
    };
    if (this.isFirst()) return false;
    this.setState(
      {
        page_number: 1,
      },
      () => {
        this.props.onPageChange(pageState);
        this.onChange();
      }
    );
  }

  lastPage(e) {
    e.preventDefault();
    let pageState = {
      previous_page: this.state.page_number,
      current_page: this.pages,
    };
    if (this.isLast()) return false;
    this.setState(
      {
        page_number: this.pages,
      },
      () => {
        this.props.onPageChange(pageState);
        this.onChange();
      }
    );
  }

  isLast() {
    if (this.state.page_number === this.pages) {
      return true;
    } else {
      return false;
    }
  }

  isFirst() {
    if (this.state.page_number === 1) {
      return true;
    } else {
      return false;
    }
  }

  exportToExcel() {
    let sTable = "<table>";
    sTable += "<thead>";
    sTable += "<tr>";
    for (let column of this.props.columns) {
      sTable += "<th>" + column.text + "</th>";
    }
    sTable += "</tr>";
    sTable += "</thead>";
    sTable += "<tbody>";
    for (let i in this.props.records) {
      let record = this.props.records[i];
      sTable += "<tr>";
      for (let column of this.props.columns) {
        if (column.cell && typeof column.cell === "function") {
          sTable += "<td></td>";
        } else if (record[column.key]) {
          sTable += "<td>" + record[column.key] + "</td>";
        } else {
          sTable += "<td></td>";
        }
      }
      sTable += "</tr>";
    }
    sTable += "</tbody>";
    sTable += "</table>";
    let uri = "data:application/vnd.ms-excel;base64,",
      template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
      base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };
    let ctx = {
        worksheet: this.config.filename || "Worksheet",
        table: sTable,
      },
      href = uri + base64(format(template, ctx));
    let anc = document.createElement("a");
    anc.setAttribute("href", href);
    anc.setAttribute("download", this.config.filename + ".xlsx");
    anc.click();
  }

  exportToPDF() {
    if (
      (this.props.auth.user.subscriptionId !== "" &&
        this.props.auth.user.subscriptionId !== undefined) ||
      this.props.auth.user.superadmin
    ) {
      this.props.exportToPDF(this.state.range_from, this.state.range_to);
    } else {
      confirmAlert({
        title: "This is only avaiable for paid subscriber",
        message: "Would you like to subscribe now",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.subscribeConfirm(),
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    }
  }

  convertToCSV(objArray) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToCSV() {
    if (
      (this.props.auth.user.subscriptionId !== "" &&
        this.props.auth.user.subscriptionId !== undefined) ||
      this.props.auth.user.superadmin
    ) {
      if (this.state.filter_zip) {
        this.props.exportToCSV(this.state.filter_zip);
      } else {
        this.props.exportToCSV(this.state.range_from, this.state.range_to);
      }
    } else {
      confirmAlert({
        title: "This is only avaiable for paid subscriber",
        message: "Would you like to subscribe now",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.subscribeConfirm(),
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    }
  }

  subscribeConfirm = () => {
    window.location.href = "/subscribe";
  };

  onChange() {
    let tableData = {
      filter_value: this.state.filter_value,
      page_number: this.state.page_number,
      page_size: this.state.page_size,
      sort_order: this.state.sort,
    };
    this.props.onChange(tableData);
  }

  filterData(records) {
    let filterValue = this.state.filter_value;
    return records.filter((record) => {
      let allow = false;
      _.each(this.props.columns, (column, key) => {
        if (record[column.key]) {
          allow = _.includes(
            record[column.key].toString().toLowerCase(),
            filterValue.toString().toLowerCase()
          )
            ? true
            : allow;
        }
      });
      return allow;
    });
  }

  setFromDate = (date) => {
    this.setState({ range_from: date });
  };
  setToDate = (date) => {
    this.setState({ range_to: date });
  };

  onCustomChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  advanceSearch = () => {
    this.props.advanceSearch(this.state.advanceSearch);
  };

  scrollElement = () => {
    window.requestAnimationFrame(function () {
      if (
        window.location.pathname === "/users" ||
        window.location.pathname === "/purchases" ||
        window.location.pathname === "/dispense"
      ) {
        // document.body.style.minWidth = 1000 + 'px';
        document.body.style.overflow = "scroll";
      } else if (window.location.pathname === "/reports") {
        // document.body.style.minWidth = 204 + 2670 + 'px';
        document.body.style.overflow = "scroll";
      } else {
        document.body.style.minWidth = "auto";
      }

      let diff =
        parseInt(document.body.style.minWidth) -
        parseInt(window.innerWidth) -
        10;
      // if(document.getElementById("table_tools"))
      //   document.getElementById("table_tools").style.marginRight = diff + 'px';
      // if(document.querySelector("button.btn.btn-outline-primary.float-right.mt-3.mr-2"))
      //   document.querySelector("button.btn.btn-outline-primary.float-right.mt-3.mr-2").setAttribute('style', 'margin-right:' + diff + 'px !important');
      // document.querySelector(".form-row").style.width = (parseInt(window.innerWidth)-170)+'px';
    });
  };

  clearNpi = () => {
    this.props.advanceSearch();
    this.setState({ advanceSearch: "" });
  };

  render() {
    let filterRecords, totalRecords, pages, isFirst, isLast;
    if (this.props.dynamic === false) {
      let obj = this.props.columns.find(
        (o) => o.key === this.state.sort.column
      );
      let records = this.props.records;
      if (obj !== undefined && obj.currency) {
        if (this.state.sort.order === "asc") {
          records.sort(
            (a, b) =>
              Number(a[this.state.sort.column].replace(/[^0-9.-]+/g, "")) -
              Number(b[this.state.sort.column].replace(/[^0-9.-]+/g, ""))
          );
        } else {
          records.sort(
            (a, b) =>
              Number(b[this.state.sort.column].replace(/[^0-9.-]+/g, "")) -
              Number(a[this.state.sort.column].replace(/[^0-9.-]+/g, ""))
          );
        }
      } else if (obj !== undefined && obj.number) {
        if (this.state.sort.order === "asc") {
          records.sort(
            (a, b) =>
              Number(a[this.state.sort.column]) -
              Number(b[this.state.sort.column])
          );
        } else {
          records.sort(
            (a, b) =>
              Number(b[this.state.sort.column]) -
              Number(a[this.state.sort.column])
          );
        }
      } else {
        const columnN = this.state.sort.column;
        records = _.orderBy(
          this.props.records,
          [
            (o) => {
              return o[columnN] || "";
            },
          ],
          [this.state.sort.order]
        );
      }
      let filterValue = this.state.filter_value;
      filterRecords = records;
      if (filterValue) {
        filterRecords = this.filterData(records);
      }
      totalRecords = filterRecords.length;
      pages = this.pages = this.numPages(totalRecords);
      isFirst = this.isFirst();
      isLast = this.isLast();
      filterRecords = this.paginate(filterRecords);
    } else {
      filterRecords = this.props.records;
      totalRecords = this.props.total_record;
      pages = this.pages = this.numPages(totalRecords);
      isFirst = this.isFirst();
      isLast = this.isLast();
    }
    let startRecords =
      this.state.page_number * this.state.page_size -
      (this.state.page_size - 1);
    let endRecords = this.state.page_size * this.state.page_number;
    endRecords = endRecords > totalRecords ? totalRecords : endRecords;

    let lengthMenuText = this.config.language.length_menu;
    lengthMenuText = lengthMenuText.split("_MENU_");
    let paginationInfo = this.config.language.info;
    paginationInfo = paginationInfo.replace(
      "_START_",
      this.state.page_number === 1 ? 1 : startRecords
    );
    paginationInfo = paginationInfo.replace("_END_", endRecords);
    paginationInfo = paginationInfo.replace("_TOTAL_", totalRecords);
    this.scrollElement();
    let i = 0;
    return (
      <div
        className="as-react-table"
        id={this.props.id ? this.props.id + "-container" : ""}
      >
        <div
          className="row table-head asrt-table-head"
          id={this.props.id ? this.props.id + "-table-head" : ""}
        >
          <div className="col-md-12">
            {this.config.show_length_menu ? (
              <div className="input-group asrt-page-length">
                <div className="input-group-addon input-group-prepend">
                  <span className="input-group-text" style={style.table_size}>
                    {lengthMenuText[0] ? lengthMenuText[0] : ""}
                  </span>
                </div>
                {_.includes(this.config.language.length_menu, "_MENU_") ? (
                  <select
                    type="text"
                    className="form-control p-1"
                    style={style.table_size_dropdown}
                    onChange={this.changePageSize.bind(this)}
                  >
                    {this.config.length_menu.map((value, key) => {
                      return <option key={value}>{value}</option>;
                    })}
                  </select>
                ) : null}
                <div className="input-group-addon input-group-prepend ">
                  <span className="input-group-text " style={style.table_size}>
                    {lengthMenuText[1] ? lengthMenuText[1] : ""}
                  </span>
                </div>
              </div>
            ) : null}
            {this.config.button.advanceSearch ? (
              <div
                className="table_filter advanceSearch"
                style={style.table_filter}
              >
                <input
                  type="search"
                  id="advanceSearch"
                  className="form-control col-md-6"
                  placeholder={this.config.button.advanceSearch}
                  value={this.state.advanceSearch}
                  onChange={this.onCustomChange}
                />
                <button1
                  className="btn btn-primary buttons-excel"
                  tabIndex="0"
                  aria-controls="configuration_tbl"
                  style={style.table_tool_btn}
                  onClick={this.advanceSearch}
                >
                  Quick Search
                </button1>
                <button
                  className="btn btn-primary buttons-excel "
                  tabIndex="0"
                  aria-controls="configuration_tbl"
                  style={style.table_tool_btn}
                  onClick={this.clearNpi}
                >
                  Clear
                </button>
              </div>
            ) : null}
            {this.config.show_filter ? (
              <div className="table_filter" style={style.table_filter}>
                <input
                  type="search"
                  className="form-control"
                  placeholder={this.config.language.filter}
                  onChange={this.filterRecords.bind(this)}
                />
              </div>
            ) : null}
            <div
              className="table_tools"
              id="table_tools"
              style={style.table_tool}
            >
              {this.config.button.excel ? (
                <button
                  className="btn btn-primary buttons-excel"
                  tabIndex="0"
                  aria-controls="configuration_tbl"
                  title="Export to Excel"
                  style={style.table_tool_btn}
                  onClick={this.exportToExcel}
                >
                  <span>
                    <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                  </span>
                </button>
              ) : null}
              {this.config.button.sendFax && (
                <p
                  style={{
                    fontSize: "20px",
                    color: "green",
                    marginBottom: 0,
                  }}
                >
                  SEARCH AND SEND ON-DEMAND FAX
                </p>
              )}
              {this.config.button.csv && this.config.button.range ? (
                <div className="row">
                  <div class="form-group col-md-4">
                    <label for="prescription_npi">From Date</label>
                    <DatePicker
                      selected={this.state.range_from}
                      onChange={(date) => this.setFromDate(date)}
                      placeholderText="MM/DD/YYYY"
                      className="form-control"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="prescription_npi">To Date</label>
                    <DatePicker
                      selected={this.state.range_to}
                      onChange={(date) => this.setToDate(date)}
                      className="form-control"
                      placeholderText="MM/DD/YYYY"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <button
                      className="btn btn-primary buttons-csv"
                      tabIndex="0"
                      aria-controls="configuration_tbl"
                      title="CSV"
                      style={style.table_tool_btn}
                      onClick={this.exportToCSV}
                    >
                      <span>
                        {/* <i className="fa fa-file-text-o" aria-hidden="true"></i> */}
                        CSV
                      </span>
                    </button>
                    <button
                      className="btn btn-primary buttons-csv"
                      tabIndex="0"
                      aria-controls="configuration_tbl"
                      title="Export to PDF"
                      style={style.table_tool_btn}
                      onClick={this.exportToPDF}
                    >
                      <span>
                        {/* <i className="fa fa-file-text-o" aria-hidden="true"></i> */}
                        PDF
                      </span>
                    </button>
                  </div>
                </div>
              ) : this.config.button.csv && this.config.button.zipcode ? (
                <div className="row">
                  <div class="form-group col-md-8">
                    <input
                      onChange={this.onCustomChange}
                      value={this.state.filter_zip}
                      id="filter_zip"
                      type="text"
                      placeholder="Zip Code"
                      className="form-control"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <button
                      className="btn btn-primary buttons-csv"
                      tabIndex="0"
                      aria-controls="configuration_tbl"
                      title="Export to CSV"
                      style={style.table_tool_btn}
                      onClick={this.exportToCSV}
                    >
                      CSV
                    </button>
                  </div>
                </div>
              ) : this.config.button.csv ? (
                <button
                  className="btn btn-primary buttons-csv"
                  tabIndex="0"
                  aria-controls="configuration_tbl"
                  title="Export to CSV"
                  style={style.table_tool_btn}
                  onClick={this.exportToCSV}
                >
                  <span>
                    {/* <i className="fa fa-file-text-o" aria-hidden="true"></i> */}
                    CSV
                  </span>
                </button>
              ) : null}
              {this.config.button.sendFax && (
                <>
                  <button
                    className="btn btn-success buttons-csv"
                    tabIndex="0"
                    aria-controls="configuration_tbl"
                    title="Send Fax"
                    style={{ ...style.table_tool_btn, width: "142px" }}
                    onClick={this.props.sendFax}
                  >
                    Send Fax
                  </button>
                  <button
                    style={{ ...style.table_tool_btn, width: "142px" }}
                    className="btn btn-success buttons-csv"
                    tabIndex="0"
                  >
                    <Link
                      to="/fax-template"
                      style={{ color: "#fff", textDecoration: "none" }}
                    >
                      {" "}
                      Fax Templates
                    </Link>
                  </button>
                  <button
                    style={{ ...style.table_tool_btn, width: "142px" }}
                    className="btn btn-success buttons-csv"
                    tabIndex="0"
                  >
                    <Link
                      to="/fax-logs"
                      style={{ color: "#fff", textDecoration: "none" }}
                    >
                      {" "}
                      Fax Logs
                    </Link>
                  </button>
                  <button
                    style={{ ...style.table_tool_btn }}
                    className="btn btn-success buttons-csv"
                    tabIndex="0"
                  >
                    <Link
                      to="/fax-credits"
                      style={{ color: "#fff", textDecoration: "none" }}
                    >
                      {" "}
                      Fax Credits: {this.props.fax_points}
                    </Link>
                  </button>
                  {this.config.button.savedList ? (
                    <button
                      style={{ ...style.table_tool_btn }}
                      className="btn btn-success buttons-csv"
                      tabIndex="0"
                    >
                      <Link
                        to="/saved-search"
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        {" "}
                        Saved Search
                      </Link>
                    </button>
                  ) : null}
                </>
              )}
              {this.config.button.print ? (
                <button
                  className="btn btn-primary buttons-pdf"
                  tabIndex="0"
                  aria-controls="configuration_tbl"
                  title="Export to PDF"
                  style={style.table_tool_btn}
                  onClick={this.exportToPDF}
                >
                  PDF
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="row table-body asrt-table-body"
          style={style.table_body}
          id={this.props.id ? this.props.id + "-table-body" : ""}
        >
          <div className="col-md-6">
            {this.config.show_info ? paginationInfo : null}
          </div>
          <div className="col-md-12">
            {/* <table className={this.props.className} id="data-table" style={{ display: "inline-block;table-layout: fixed;" }}> */}
            <table className={this.props.className} id="data-table">
              <thead>
                <tr>
                  {this.props.columns.map((column, index) => {
                    let classText = column.sortable
                        ? "sortable "
                        : "non-sortable",
                      width = column.width ? column.width : "",
                      align = column.align ? column.align : "",
                      sortOrder = "",
                      columnStyle = {};
                    if (this.state.sort.column === column.key) {
                      sortOrder = this.state.sort.order;
                      classText += sortOrder ? " " + sortOrder : "";
                      columnStyle =
                        sortOrder === "asc" ? style.sort_asc : style.sort_desc;
                    }

                    classText += " text-" + align;
                    if (column.className) classText += " " + column.className;

                    return (
                      <th
                        key={column.key ? column.key : column.text}
                        className={classText}
                        width={width}
                        style={columnStyle}
                        onClick={() => this.sortColumn(column, sortOrder)}
                      >
                        {column.text}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {filterRecords.length ? (
                  filterRecords.map((record, rowIndex) => {
                    rowIndex = _.indexOf(this.props.records, record);
                    i = i + 1;
                    let trClass = "";
                    if (
                      this.props.columns.length > 7 &&
                      this.props.columns[7].mme
                    ) {
                      trClass =
                        record.mme >= 90
                          ? "tr-red"
                          : record.mme >= 50
                          ? "tr-orange"
                          : record.mme < 50
                          ? "tr-green"
                          : "";
                    }
                    if (this.props.columns[0].faxLog) {
                      trClass = [
                        "NoFaxDevice",
                        "SendingFailed",
                        "Busy",
                        "NoAnswer",
                        "Failed",
                      ].includes(record.status)
                        ? "tr-red"
                        : record.status === "In Progress"
                        ? "tr-purple"
                        : record.status === "Sent"
                        ? "tr-green"
                        : "";
                    }
                    if (
                      this.props.columns.length === 4 &&
                      this.props.columns[0].color
                    ) {
                      trClass =
                        record.type === "Used"
                          ? "tr-red"
                          : record.type === "Credit"
                          ? "tr-green"
                          : "";
                    }
                    if (this.props.config.name === "dispense") {
                      if (
                        record.is_reverse_distribution ||
                        record.delete_requested
                      ) {
                        trClass = "tr-red";
                      }
                    } else if (this.props.config.name === "purchase") {
                      trClass = record.delete_requested ? "tr-red" : "";
                    } else if (this.props.config.name === "inventoryRecon") {
                      if (record.balance <= 0) {
                        trClass = "tr-red";
                      } else if (
                        record.balance > 0 &&
                        record.balance > record.avg_dispense
                      ) {
                        trClass = "tr-orange";
                      } else if (
                        record.balance > 0 &&
                        record.balance < record.avg_dispense
                      ) {
                        trClass = "tr-green";
                      }
                    }
                    return (
                      <tr
                        key={i}
                        onClick={(e) =>
                          this.props.onRowClicked(e, record, rowIndex)
                        }
                        className={trClass}
                      >
                        {this.props.columns.map((column, colIndex) => {
                          if (
                            column.cell &&
                            typeof column.cell === "function"
                          ) {
                            return (
                              <td
                                className={column.className}
                                key={column.key ? column.key : column.text}
                              >
                                {column.cell(record, rowIndex)}
                              </td>
                            );
                          } else if (record[column.key]) {
                            return (
                              <td
                                className={column.className}
                                key={column.key ? column.key : column.text}
                              >
                                {column.link ? (
                                  <Link to={column.link + `/${record.id}`}>
                                    {record[column.key]}
                                  </Link>
                                ) : column.coupon ? (
                                  <a href={record[column.key]} target="_blank">
                                    <img src={dollar} width="50"></img>
                                  </a>
                                ) : column.show_medication ? (
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#show-physician-medication"
                                    onClick={() => {
                                      this.props.showMedication(record);
                                      return false;
                                    }}
                                  >
                                    {record[column.key]}
                                  </a>
                                ) : column.show_medication_rx ? (
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#show-physician-rx"
                                    onClick={() => {
                                      this.props.showMedication(record);
                                      return false;
                                    }}
                                  >
                                    {record[column.key]}
                                  </a>
                                ) : column.view ? (
                                  <a href={record[column.key]} target="_blank">
                                    View
                                  </a>
                                ) : column.openLink ? (
                                  <a href={record["link"]} target="_blank">
                                    {record[column.key]}
                                  </a>
                                ) : column.html ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: record[column.key],
                                    }}
                                  ></div>
                                ) : column.downloadable ? (
                                  <a href={record[column.key]} target="_blank">
                                    {"Download"}
                                  </a>
                                ) : column.userDetail1 ? (
                                  this.props.getuserDetail1(record)
                                ) : column.checkBox ? (
                                  <input
                                    type="checkbox"
                                    className="table-check-input"
                                    checked={this.props.seletecedCheckbox.includes(
                                      record[column.key]
                                    )}
                                    onChange={(e) =>
                                      this.props.toggleChangeMass(e, record)
                                    }
                                  />
                                ) : (
                                  record[column.key]
                                )}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                className={column.className}
                                key={column.key ? column.key : column.text}
                              >
                                {column.mmeIcon ? (
                                  <>
                                    <button
                                      type="button"
                                      style={{ padding: 0 }}
                                      class="btn"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={
                                        record["mme"] >= 90
                                          ? "Consider naloxone prescription, especially if previous overdose is documented"
                                          : record["mme"] >= 50
                                          ? "Consider naloxone prescription"
                                          : record["mme"] < 50
                                          ? "Consider other sources of risk beyond PDMP data."
                                          : ""
                                      }
                                    >
                                      <img
                                        src={rxl}
                                        style={{ width: "35px" }}
                                      />
                                    </button>
                                  </>
                                ) : (
                                  ""
                                )}
                                {column.show_rx ? (
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#show-physician-rx"
                                    onClick={() => {
                                      this.props.showMedicationRx(record);
                                      return false;
                                    }}
                                  >
                                    <img src={rxl} style={{ width: "35px" }} />
                                  </a>
                                ) : (
                                  ""
                                )}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={this.props.columns.length} align="center">
                      {this.config.no_data_text}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="row table-foot asrt-table-foot"
          id={this.props.id ? this.props.id + "-table-foot" : ""}
        >
          <div className="col-md-6">
            {this.config.show_info ? paginationInfo : null}
          </div>
          <div className="col-md-6 pull-right text-right">
            {this.config.show_pagination ? (
              <nav aria-label="Page navigation" className="pull-right">
                <ul className="pagination justify-content-end asrt-pagination">
                  {this.config.show_first ? (
                    <li className={(isFirst ? "disabled " : "") + "page-item"}>
                      <a
                        href="#"
                        className="page-link"
                        tabIndex="-1"
                        onClick={this.firstPage.bind(this)}
                      >
                        {this.config.language.pagination.first}
                      </a>
                    </li>
                  ) : null}
                  <li className={(isFirst ? "disabled " : "") + "page-item"}>
                    <a
                      href="#"
                      className="page-link"
                      tabIndex="-1"
                      onClick={this.previousPage.bind(this)}
                    >
                      {this.config.language.pagination.previous}
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link">{this.state.page_number}</a>
                  </li>
                  <li className={(isLast ? "disabled " : "") + "page-item"}>
                    <a
                      href="#"
                      className="page-link"
                      onClick={this.nextPage.bind(this)}
                    >
                      {this.config.language.pagination.next}
                    </a>
                  </li>
                  {this.config.show_last ? (
                    <li className={(isLast ? "disabled " : "") + "page-item"}>
                      <a
                        href="#"
                        className="page-link"
                        tabIndex="-1"
                        onClick={this.lastPage.bind(this)}
                      >
                        {this.config.language.pagination.last}
                      </a>
                    </li>
                  ) : null}
                </ul>
              </nav>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Define component display name
 */
ReactDatatable.displayName = "ReactDatatable";

/**
 * Define defaultProps for this component
 */
ReactDatatable.defaultProps = {
  id: "as-react-datatable",
  className: "table table-bordered table-striped",
  columns: [],
  config: {
    button: {
      excel: false,
      print: false,
      csv: false,
    },
    filename: "table",
    language: {
      length_menu: "Show _MENU_ records per page",
      filter: "Search in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ entries",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    length_menu: [10, 25, 50, 75, 100],
    no_data_text: "No rows found",
    page_size: 10,
    sort: {
      column: "test",
      order: "asc",
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
    show_first: true,
    show_last: true,
  },
  dynamic: false,
  records: [],
  total_record: 0,
  onChange: () => {},
  onPageChange: () => {},
  onRowClicked: () => {},
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(ReactDatatable);
