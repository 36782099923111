import classnames from "classnames";
import React from "react";

import axios from "axios";
import isEmpty from "is-empty";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "../../actions/userActions";

import "react-toastify/dist/ReactToastify.css";
import { availableSolutions as solutions } from "./../../utils/constants";
import AlereRx from "./UserUpdate/AlereRx";
import Audit from "./UserUpdate/Audit";
import BonaRx from "./UserUpdate/BonaRx";
import CompareRx from "./UserUpdate/CompareRx";
import Compliance from "./UserUpdate/Compliance";
import DoorstepRx from "./UserUpdate/DoorstepRx";
import Marketing from "./UserUpdate/Marketing";
import MarketingP from "./UserUpdate/MarketingP";
import MentoRx from "./UserUpdate/MentoRx";
import RxCreator from "./UserUpdate/RxCreator";
import RxMinder from "./UserUpdate/RxMinder";
import RxPodcast from "./UserUpdate/RxPodcast";
import RxReach from "./UserUpdate/RxReach";
import TabulaRx from "./UserUpdate/TabulaRx";
import XPedicareRx from "./UserUpdate/XPedicare";
import XPedicareRxcustomPage from "./UserUpdate/XPedicareRxcustomPage";

const softwares = [
  "AbacusRx",
  "AdvanceNet",
  "BestRx",
  "CarePoint",
  "Cerner",
  "ComputerRx",
  "DigitalRx",
  "DRx",
  "ePostRx",
  "Framework LTC",
  "FSI Foundation",
  "Integra",
  "Intelligent Pharmacy Software(IPS)",
  "Liberty",
  "Micromerchant(PrimeRx)",
  "PioneerRx",
  "QS1",
  "Rx30",
  "RxBlu",
  "RxDispense",
  "RxSystem",
  "ScriptPro",
  "SRS System",
  "SuiteRx-IPS",
  "Visual Superscript",
  "Others- Type Response",
];

class UserAddModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      email: "",
      password: "",
      errors: {},
      bonaRx: false,
      alereRx: false,
      rxReach: false,
      tabulaRx: false,
      rxConnexion: false,
      child: false,
      expiration_bonarx: undefined,
      multistore: false,
      rx_group: "",
      software: "",
      rx_bin: "",
      upline: "",
      assigned_date: "",
      first_name: "",
      last_name: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      website: "",
      phone: "",
      phone1: "",
      fax: "",
      npi: "",
      allow_dispensing_sheet: false,
      allow_dispensing_sheet_before: false,
      logo: undefined,
      rxminder_button_text: "",
      rxminder_button_link: "",
      rxminder_button_text1: "",
      rxminder_button_link1: "",
      rxminder_button_text2: "",
      rxminder_button_link2: "",
      custom_rxminder: false,
      gdrive: undefined,
      other_software: "",
      subscriptionId: "",
      rxminder_subscriptionId: "",
      doorstep_subscriptionId: "",
      rx_group_associated: "",
      rows: [],
      xpedicareRxcustom_page_name: "",
    };
  }

  setDate = (date, type) => {
    this.setState({ [type]: date });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onUserUpdate = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.name === "" || this.state.name === undefined) {
      errors.name = "This field is required";
    } else {
      delete errors.name;
    }
    if (this.state.email === "" || this.state.email === undefined) {
      errors.email = "This field is required";
    } else {
      delete errors.email;
    }
    if (this.state.first_name === "" || this.state.first_name === undefined) {
      errors.first_name = "This field is required";
    } else {
      delete errors.first_name;
    }
    if (this.state.last_name === "" || this.state.last_name === undefined) {
      errors.last_name = "This field is required";
    } else {
      delete errors.last_name;
    }
    if (this.state.zip === "" || this.state.zip === undefined) {
      errors.zip = "This field is required";
    } else {
      delete errors.zip;
    }
    if (this.state.phone === "" || this.state.phone === undefined) {
      errors.phone = "This field is required";
    } else {
      delete errors.phone;
    }
    if (this.state.phone1 === "" || this.state.phone1 === undefined) {
      errors.phone1 = "This field is required";
    } else {
      delete errors.phone1;
    }

    if (isEmpty(errors)) {
      let formData = new FormData();
      formData.append("password", this.state.password);
      formData.append("email", this.state.email);
      formData.append("name", this.state.name);
      if (this.state.rx_bin !== undefined && this.state.rx_bin !== "") {
        formData.append("rx_bin", this.state.rx_bin);
      }
      if (this.state.rx_group !== undefined && this.state.rx_group !== "") {
        formData.append("rx_group", this.state.rx_group);
      }
      if (this.state.upline !== undefined && this.state.upline !== "") {
        formData.append("upline", this.state.upline);
      }
      if (this.state.website !== undefined && this.state.website !== "") {
        formData.append("website", this.state.website);
      }
      if (this.state.street !== undefined && this.state.street !== "") {
        formData.append("street", this.state.street);
      }
      if (this.state.city !== undefined && this.state.city !== "") {
        formData.append("city", this.state.city);
      }
      if (this.state.state !== undefined && this.state.state !== "") {
        formData.append("state", this.state.state);
      }
      if (this.state.fax !== undefined && this.state.fax !== "") {
        formData.append("fax", this.state.fax);
      }
      if (this.state.npi !== undefined && this.state.npi !== "") {
        formData.append("npi", this.state.npi);
      }
      if (
        this.state.rx_group_associated !== undefined &&
        this.state.rx_group_associated !== ""
      ) {
        formData.append("rx_group_associated", this.state.rx_group_associated);
      }

      formData.append("first_name", this.state.first_name);
      formData.append("last_name", this.state.last_name);
      formData.append("zip", this.state.zip);
      formData.append("phone", this.state.phone);
      formData.append("phone1", this.state.phone1);
      formData.append("child", this.state.child);
      formData.append("multistore", this.state.multistore);
      formData.append(
        "xpedicareRxcustomArray",
        JSON.stringify(this.state.rows)
      );
      formData.append(
        "xpedicareRxcustom_page_name",
        this.state.xpedicareRxcustom_page_name
      );

      solutions.map((each) => {
        if (this.state[each.subId] !== undefined) {
          formData.append(each.subId, this.state[each.subId]);
        }
        if (this.state[each.expiry] !== undefined) {
          formData.append(each.expiry, this.state[each.expiry]);
        }
      });

      formData.append(
        "software",
        this.state.software === "Others- Type Response"
          ? this.state.other_software
          : this.state.software
      );
      formData.append(
        "allow_dispensing_sheet",
        this.state.allow_dispensing_sheet
      );
      formData.append(
        "allow_dispensing_sheet_before",
        this.state.allow_dispensing_sheet_before
      );
      formData.append("rxminder_button_link", this.state.rxminder_button_link);
      formData.append("rxminder_button_text", this.state.rxminder_button_text);
      formData.append(
        "rxminder_button_link1",
        this.state.rxminder_button_link1
      );
      formData.append(
        "rxminder_button_text1",
        this.state.rxminder_button_text1
      );
      formData.append(
        "rxminder_button_link2",
        this.state.rxminder_button_link2
      );
      formData.append(
        "rxminder_button_text2",
        this.state.rxminder_button_text2
      );
      formData.append("custom_rxminder", this.state.custom_rxminder);
      formData.append("gdrive", this.state.gdrive);
      if (this.state.logo !== "" && this.state.logo !== undefined) {
        formData.append("logo", this.state.logo);
      }
      console.log(formData);
      console.log(this.state);

      axios
        .post("/api/user-add", formData, {})
        .then((res) => {
          this.props.getData();
          $("#add-user-modal").modal("hide");
        })
        .catch((err) => console.log(err));
    } else {
      this.setState({ errors });
    }
  };

  toggleChange = (e) => {
    this.setState({ [e.target.id]: !this.state[e.target.id] });
  };

  onLogoChange = (event) => {
    this.setState({
      [event.target.id]: event.target.files[0],
    });
  };
  handleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];

    rows[idx] = {
      ...rows[idx],
      [name]: value,
    };

    this.setState({
      rows,
    });
  };
  handleAddRow = () => {
    const item = {
      name: "",
      description: "",
      link: "",
    };

    this.setState({
      rows: [...this.state.rows, item],
    });
  };
  handleRemoveRow = (idx, e) => {
    this.setState({
      rows: this.state.rows.filter((v, i) => i !== idx),
    });
  };

  render() {
    const {
      errors,
      expiration_bonarx,
      rx_group,
      rxminder_button_text,
      rxminder_button_text1,
      rxminder_button_text2,
      rxminder_button_link,
      rxminder_button_link1,
      rxminder_button_link2,
      rx_group_associated,
    } = this.state;
    return (
      <div>
        <div className="modal fade" id="add-user-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add User</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form
                  noValidate
                  onSubmit={this.onUserUpdate}
                  id="add-user"
                  encType="multipart/form-data"
                >
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name"> Account Name</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.name}
                        id="name"
                        type="text"
                        error={errors.name}
                        className={classnames("form-control", {
                          invalid: errors.name,
                        })}
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="email">Email</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.email}
                        error={errors.email}
                        id="email"
                        type="email"
                        // disabled
                        className={classnames("form-control", {
                          invalid: errors.email,
                        })}
                      />
                      <span className="text-danger">{errors.email}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">NPI</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.npi}
                        id="npi"
                        type="text"
                        error={errors.npi}
                        className={classnames("form-control", {
                          invalid: errors.npi,
                        })}
                      />
                      <span className="text-danger">{errors.npi}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">Rx Bin</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.rx_bin}
                        id="rx_bin"
                        type="text"
                        error={errors.rx_bin}
                        className={classnames("form-control", {
                          invalid: errors.rx_bin,
                        })}
                      />
                      <span className="text-danger">{errors.rx_bin}</span>
                    </div>
                  </div>
                  <div id="accordion">
                    <TabulaRx
                      {...{
                        errors,
                        expiration_tabula: this.state.expiration_tabula,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        subscriptionId: this.state.subscriptionId,
                        allow_dispensing_sheet: this.state
                          .allow_dispensing_sheet,
                        toggleChange: this.toggleChange,
                        softwares: softwares,
                        software: this.state.software,
                        multistore: this.state.multistore,
                      }}
                    />
                    <BonaRx
                      {...{
                        errors,
                        expiration_bonarx,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        bonarx_subscriptionId: this.state.bonarx_subscriptionId,
                        upline: this.state.upline,
                        rx_group_associated,
                        rx_group,
                        toggleChange: this.toggleChange,
                        child: this.state.child,
                      }}
                    />

                    <AlereRx
                      {...{
                        errors,
                        expiration_alererx: this.state.expiration_alererx,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        alererx_subscriptionId: this.state
                          .alererx_subscriptionId,
                      }}
                    />
                    <RxReach
                      {...{
                        errors,
                        expiration_rxreach: this.state.expiration_rxreach,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        rxreach_subscriptionId: this.state
                          .rxreach_subscriptionId,
                      }}
                    />
                    <XPedicareRx
                      {...{
                        errors,
                        expiration_xpedicare: this.state.expiration_xpedicare,
                        expiration_xpedicare24: this.state
                          .expiration_xpedicare24,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        xpedicare_subscriptionId: this.state
                          .xpedicare_subscriptionId,
                        xpedicare24_subscriptionId: this.state
                          .xpedicare24_subscriptionId,
                      }}
                    />
                    <RxMinder
                      {...{
                        errors,
                        expiration_rxminder: this.state.expiration_rxminder,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        custom_rxminder: this.state.custom_rxminder,
                        rxminder_subscriptionId: this.state
                          .rxminder_subscriptionId,
                        rxminder_button_link,
                        rxminder_button_link1,
                        rxminder_button_link2,
                        rxminder_button_text,
                        rxminder_button_text1,
                        rxminder_button_text2,
                      }}
                    />
                    <RxPodcast
                      {...{
                        errors,
                        expiration_rxpodcast: this.state.expiration_rxpodcast,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        rxpodcast_subscriptionId: this.state
                          .rxpodcast_subscriptionId,
                      }}
                    />
                    <RxCreator
                      {...{
                        errors,
                        expiration_rxcreator: this.state.expiration_rxcreator,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        rxcreator_subscriptionId: this.state
                          .rxcreator_subscriptionId,
                      }}
                    />
                    <DoorstepRx
                      {...{
                        errors,
                        expiration_doorstep: this.state.expiration_doorstep,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        doorstep_subscriptionId: this.state
                          .doorstep_subscriptionId,
                      }}
                    />
                    <CompareRx
                      {...{
                        errors,
                        expiration_comparerx: this.state.expiration_comparerx,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        comparerx_subscriptionId: this.state
                          .comparerx_subscriptionId,
                      }}
                    />
                    <Compliance
                      {...{
                        errors,
                        expiration_compliance: this.state.expiration_compliance,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        compliance_subscriptionId: this.state
                          .compliance_subscriptionId,
                      }}
                    />
                    <Audit
                      {...{
                        errors,
                        expiration_audit: this.state.expiration_audit,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        audit_subscriptionId: this.state.audit_subscriptionId,
                      }}
                    />
                    <Marketing
                      {...{
                        errors,
                        expiration_marketing: this.state.expiration_marketing,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        marketing_subscriptionId: this.state
                          .marketing_subscriptionId,
                      }}
                    />
                    <MarketingP
                      {...{
                        errors,
                        expiration_marketingp: this.state.expiration_marketingp,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        marketingp_subscriptionId: this.state
                          .marketingp_subscriptionId,
                      }}
                    />
                    <MentoRx
                      {...{
                        errors,
                        expiration_mentorx: this.state.expiration_mentorx,
                        setDate: this.setDate,
                        onChange: this.onChange,
                        mentorx_subscriptionId: this.state
                          .mentorx_subscriptionId,
                      }}
                    />
                    <XPedicareRxcustomPage
                      {...{
                        errors,
                        xpedicareRxcustom_page_name: this.state
                          .xpedicareRxcustom_page_name,
                        handleChange: this.handleChange,
                        handleAddRow: this.handleAddRow,
                        handleRemoveRow: this.handleRemoveRow,
                        onChange: this.onChange,
                        rows: this.state.rows,
                      }}
                    />
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="first_name">First Name</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.first_name}
                        id="first_name"
                        type="text"
                        error={errors.first_name}
                        className={classnames("form-control", {
                          invalid: errors.first_name,
                        })}
                      />
                      <span className="text-danger">{errors.first_name}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">Last Name</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.last_name}
                        id="last_name"
                        type="text"
                        error={errors.last_name}
                        className={classnames("form-control", {
                          invalid: errors.last_name,
                        })}
                      />
                      <span className="text-danger">{errors.last_name}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="street">Street</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.street}
                        id="street"
                        type="text"
                        error={errors.street}
                        className={classnames("form-control", {
                          invalid: errors.street,
                        })}
                      />
                      <span className="text-danger">{errors.street}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="city">City</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.city}
                        id="city"
                        type="text"
                        error={errors.city}
                        className={classnames("form-control", {
                          invalid: errors.city,
                        })}
                      />
                      <span className="text-danger">{errors.city}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="state">State</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.state}
                        id="state"
                        type="text"
                        error={errors.state}
                        className={classnames("form-control", {
                          invalid: errors.state,
                        })}
                      />
                      <span className="text-danger">{errors.state}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="zip">Zip</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.zip}
                        id="zip"
                        type="text"
                        error={errors.zip}
                        className={classnames("form-control", {
                          invalid: errors.zip,
                        })}
                      />
                      <span className="text-danger">{errors.zip}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="website">Website</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.website}
                        id="website"
                        type="text"
                        error={errors.website}
                        className={classnames("form-control", {
                          invalid: errors.website,
                        })}
                      />
                      <span className="text-danger">{errors.website}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="phone">OTP Phone</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.phone}
                        id="phone"
                        type="text"
                        error={errors.phone}
                        className={classnames("form-control", {
                          invalid: errors.phone,
                        })}
                      />
                      <span className="text-danger">{errors.phone}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="phone1">Pharmacy Phone</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.phone1}
                        id="phone1"
                        type="text"
                        error={errors.phone1}
                        className={classnames("form-control", {
                          invalid: errors.phone1,
                        })}
                      />
                      <span className="text-danger">{errors.phone1}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="fax">Fax</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.fax}
                        id="fax"
                        type="text"
                        error={errors.fax}
                        className={classnames("form-control", {
                          invalid: errors.fax,
                        })}
                      />
                      <span className="text-danger">{errors.fax}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="logo">Logo</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onLogoChange}
                        id="logo"
                        type="file"
                        error={errors.logo}
                        className={classnames("form-control", {
                          invalid: errors.logo,
                        })}
                      />
                      <span className="text-danger">{errors.logo}</span>
                      {this.state.logo && (
                        <img src={this.state.logo} width={80} />
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="add-user"
                  type="submit"
                  className="btn btn-primary"
                >
                  Update User
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateUser })(
  withRouter(UserAddModal)
);
