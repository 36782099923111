import axios from "axios";
import classnames from "classnames";
import isEmpty from "is-empty";
import $ from "jquery";
import React from "react";
import { connect } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class AddSavedSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      errors: {},
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSave = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.name === "") {
      errors.name = "This field is required";
    } else {
      delete errors.name;
    }

    if (isEmpty(errors)) {
      this.setState({ loading: true });
      const newData = {
        name: this.state.name,
        searchData: this.props.filters,
        excludes: this.props.excludes,
        pharmacy_account: this.props.auth.user.final_user,
      };
      axios.post("/faxApi/saved-search-add", newData).then(
        (response) => {
          $("#saved-search-modal").modal("hide");
          toast.success("Search saved successfully");
          this.setState({
            name: "",
          });
          this.setState({ loading: false });
        },
        (error) => {
          toast.error(error.message);
          this.setState({ loading: false });
        }
      );
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div className="modal fade" id="saved-search-modal" data-reset="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add New Search</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <form noValidate onSubmit={this.onSave} id="saved-search">
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">Name</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.name}
                        id="name"
                        type="text"
                        error={errors.name}
                        className={classnames("form-control", {
                          invalid: errors.name,
                        })}
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="saved-search"
                  type="submit"
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(AddSavedSearch);
